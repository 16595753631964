/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../TableGridWrapper/TableDataGridWrapper";
import { ShipmentsSummaryHeaders } from "../../../static/tablesHeaders";
import { setChosenShipmentId } from "../../../slices/shipmentSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomChip from "../../CustomChip/CustomChip";
import {
  shipmentStatus,
  shipmentStatusChipStyles,
} from "../../../static/static";
import { dateFormatView } from "../../../static/functions";
import ShipmentInventory from "./ShipmentInventory";
import DetailsButton from "../../DetailsButton/DetailsButton";
import authService from "../../../auth/authService";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

const CustomTypography = styled(Typography)(() => ({
  fontSize: "0.875em",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "0.5rem",
}));

const ShipmentSummaryDesktop = ({
  orderNumber,
  shipment,
  setIsStatusChangeToDeliveredModalVisible,
  setIsStatusChangeToInProgressModalVisible,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    id: shipmentId,
    sendDate,
    letterNumber,
    status,
    baseStorage,
    destinationStorage,
    shipment_units: shipmentUnits,
  } = shipment;

  const { user_id: userId, role } = authService.getDecodedAccessToken();

  const navigateToItem = () => {
    dispatch(setChosenShipmentId(shipmentId));
    navigate(`/shipments/${shipmentId}`);
  };

  return (
    <TableDataGridWrapper
      headers={ShipmentsSummaryHeaders}
      styles={{ height: "5rem" }}
      gap="1%"
    >
      <CustomTypography>{orderNumber}</CustomTypography>
      <CustomTypography>
        {sendDate ? dateFormatView(sendDate) : "Brak"}
      </CustomTypography>
      <CustomTypography>{letterNumber}</CustomTypography>
      <CustomChip
        label={shipmentStatus[status]}
        sx={shipmentStatusChipStyles(status, "")}
        width="80%"
      />
      <CustomTypography>{baseStorage.name}</CustomTypography>
      <CustomTypography>{destinationStorage.name}</CustomTypography>
      <ShipmentInventory shipmentUnits={shipmentUnits} />
      <ButtonWrapper>
        {status === "In progress" &&
          (userId === destinationStorage.user.id ||
            (destinationStorage.type === "main" &&
              (role === "admin" || role === "warehouseman"))) && (
            <DetailsButton
              onClick={() => setIsStatusChangeToDeliveredModalVisible(true)}
              text="Odbierz"
              startIcon={<ArrowDownward />}
            />
          )}
        {status === "Created" &&
          (userId === baseStorage.user.id ||
            role === "admin" ||
            role === "warehouseman") && (
            <DetailsButton
              onClick={() => setIsStatusChangeToInProgressModalVisible(true)}
              text="Wyślij"
              startIcon={<ArrowUpward />}
            />
          )}
        <DetailsButton
          onClick={() => navigateToItem()}
          text="szczegóły"
          width="100%"
        />
      </ButtonWrapper>
    </TableDataGridWrapper>
  );
};

export default ShipmentSummaryDesktop;
