/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearProducts,
  getPaginatedProducts,
  getProductsCount,
} from "../../../slices/productSlice";
import ProductItem from "../ProductItem/ProductItem";
import { Dialog } from "@mui/material";
import ProductForm from "../../Product/ProductForm/ProductForm";
import Pagination from "../../Pagination/Pagination";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../../../static/static";
import { Add, Inventory, Warning } from "@mui/icons-material";
import Loading from "../../Loading/Loading";
import PageHeader from "../../PageHeader/PageHeader";
import RefreshButton from "../../RefreshButton/RefreshButton";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import TableHeader from "../../TableHeader/TableHeader";
import { productsListHeaders } from "../../../static/tablesHeaders";
import EmptyListError from "../../EmptyListError/EmptyListError";
import BlankSpace from "../../BlankSpace/BlankSpace";
import PageWrapper from "../../PageWrapper/PageWrapper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import MobileDataSorter from "../../MobileDataSorter/MobileDataSorter";
import MultipleChipSelect from "../../MultipleChipSelect/MultipleChipSelect";
import {
  clearProducers,
  getFilteredProducers,
} from "../../../slices/producerSlice";
import {
  clearCategories,
  getFilteredCategories,
} from "../../../slices/categorySlice";
import TableWrapper from "../../TableWrapper/TableWrapper";
import ButtonWrapper from "../../ButtonWrapper/ButtonWrapper";
import ReturnButton from "../../ReturnButton/ReturnButton";

const ProductsList = ({ refresh, setRefresh }) => {
  const dispatch = useDispatch();
  const { products, productsCount } = useSelector((slice) => slice.product);
  const searchingRef = useRef("");
  const [searchingValue, setSearchingValue] = useState("");
  const [displaySearchingValue, setDisplaySearchingValue] = useState("");
  const [sorter, setSorter] = useState("partNumber");
  const [sorting, setSorting] = useState("ascending");
  const [isAddProductFormVisible, setAddProductFormVisible] = useState(false);
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(DEFAULT_DISPLAY_ITEMS_QUANTITY);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProducers, setSelectedProducers] = useState([]);

  const loadDataCount = useCallback(async () => {
    await dispatch(
      getProductsCount({
        searchingValue,
        categories: selectedCategories.map((item) => item.name),
        producers: selectedProducers.map((item) => item.name),
      })
    );
  }, [refresh, searchingValue, selectedCategories, selectedProducers]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedProducts({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        categories: selectedCategories.map((item) => item.name),
        producers: selectedProducers.map((item) => item.name),
      })
    );
    setIsDataLoading(false);
  }, [
    refresh,
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    selectedCategories,
    selectedProducers,
  ]);

  useEffect(() => {
    loadDataCount();
  }, [loadDataCount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearProducts());
  }, []);

  const closeAddForm = () => {
    setAddProductFormVisible(false);
  };

  return (
    <PageWrapper width="80%">
      <PageHeader
        titleText="Karty produktów"
        icon={<Inventory />}
        onRightComponent={
          <ButtonWrapper>
            <DetailsButton
              startIcon={<Add />}
              text="Dodaj kartę produktu"
              onClick={() => setAddProductFormVisible(true)}
            />
            <ReturnButton />
          </ButtonWrapper>
        }
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <BlankSpace />
      <SectionWrapper>
        <AdditionalFunctionalitiesWrapper>
          <CustomSearchField
            displaySearchingValue={displaySearchingValue}
            setDisplaySearchingValue={setDisplaySearchingValue}
            searchingValue={searchingValue}
            setSearchingValue={setSearchingValue}
            searchingRef={searchingRef}
            placeholder="szukaj kart produktów..."
          />
          <MultipleChipSelect
            name="Kategorie:"
            sliceName="category"
            collectionName="categories"
            getDataFunction={getFilteredCategories}
            cleanDataFunction={clearCategories}
            setFunction={setSelectedCategories}
            settingCollection={selectedCategories}
          />
          <MultipleChipSelect
            name="Producenci:"
            sliceName="producer"
            collectionName="producers"
            getDataFunction={getFilteredProducers}
            cleanDataFunction={clearProducers}
            setFunction={setSelectedProducers}
            settingCollection={selectedProducers}
          />
          <MobileDataSorter
            tableHeader={productsListHeaders}
            setOuterSorter={setSorter}
            setOuterSorting={setSorting}
            defaultSorter="partNumber"
          />
        </AdditionalFunctionalitiesWrapper>
        <TableWrapper>
          <TableHeader
            headers={productsListHeaders}
            setSorter={setSorter}
            setSorting={setSorting}
            gap="1%"
          />
          <Pagination
            collectionSize={productsCount}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            setLeftIndex={setLeftIndex}
            setRightIndex={setRightIndex}
          >
            {isDataLoading ? (
              <Loading />
            ) : (
              <>
                {products !== null &&
                  products.map((product, id) => (
                    <ProductItem
                      orderNumber={leftIndex + id + 1}
                      key={product.id}
                      product={product}
                      setRefresh={setRefresh}
                    />
                  ))}
              </>
            )}
            {products !== null && products.length === 0 && (
              <EmptyListError icon={<Warning />} message="Brak produktów" />
            )}
          </Pagination>
        </TableWrapper>

        <Dialog open={isAddProductFormVisible} onClose={() => closeAddForm()}>
          <ProductForm
            closeAddForm={closeAddForm}
            setRefresh={() => setRefresh((state) => !state)}
          />
        </Dialog>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default ProductsList;
