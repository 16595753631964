/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from '../../../TableGridWrapper/TableDataGridWrapper';
import { commissionListHeaders } from '../../../../static/tablesHeaders';
import CustomChip from '../../../CustomChip/CustomChip';
import { dateFormatView } from '../../../../static/functions';
import DetailsButton from '../../../DetailsButton/DetailsButton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { commissionStatus, commissionStatusChipStyles } from '../../../../static/static';
import { setChosenCommissionId } from '../../../../slices/commissionSlice';
import { setChosenDeviceId } from '../../../../slices/deviceSlice';

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));


const CommissionItemDesktop = ({ orderNumber, commission }) => {
  const {
    id,
    company: { name: companyName },
    creationDate,
    finishDate,
    status,
    device: { id: deviceId, location: locationCode, name: deviceName },
    createdBy: { name, surname },
  } = commission;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <TableDataGridWrapper headers={commissionListHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{id}</CustomDataDisplay>
      <CustomDataDisplay>{companyName}</CustomDataDisplay>
      <CustomDataDisplay>{locationCode}</CustomDataDisplay>
      <CustomDataDisplay>{dateFormatView(creationDate)}</CustomDataDisplay>
      <CustomDataDisplay>
        {status === "Closed" ? dateFormatView(finishDate) : "Brak"}
      </CustomDataDisplay>
      <CustomChip
        label={commissionStatus[status]}
        sx={commissionStatusChipStyles(status, "")}
      />

      <CustomDataDisplay>{deviceName}</CustomDataDisplay>
      <CustomDataDisplay>{`${name} ${surname}`}</CustomDataDisplay>
      <DetailsButton
        onClick={() => {
          dispatch(setChosenCommissionId(id));
          dispatch(setChosenDeviceId(deviceId));
          navigate(`/commissions/${id}`);
        }}
        width='80%'
      />
    </TableDataGridWrapper>
  );
};

export default CommissionItemDesktop;