import { Box, Typography } from "@mui/material";
import React from "react";

const ChangeLog = () => {
  return (
    <Box
      sx={{
        bgcolor: "rgba(225, 225, 225, 0.5)",
      }}
    >
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
        }}
      >
        <Typography sx={{ fontSize: "1.2rem", color: "blue" }}>
          Wersja 1.0.0.13 (02.10.2023r.):
        </Typography>
        <Typography>
          - raporty magazynowe
          <br />
          - poprawki
        </Typography>
        <Typography sx={{ fontSize: "1.2rem", color: "blue" }}>
          Wersja 1.0.0.9 (08.08.2023r.):
        </Typography>
        <Typography>
          - dodanie widoku przesyłek przychodzących do danego serwisanta
          <br />
          - dodany komponent zarządzania sekcjami w widoku
          <br />
          - drobne napotkane poprawki
        </Typography>
        <Typography sx={{ fontSize: "1.2rem", color: "blue" }}>
          Wersja 1.0.0.8 (17.07.2023r.):
        </Typography>
        <Typography>
          - dodany komponent weryfikujący zawartość paczek
          <br />
          - poprawiony formularz zleceń
          <br />
          - dodanie danych serwisantów i ich magazynów
          <br />
          - drobne napotkane poprawki
        </Typography>
        <Typography sx={{ fontSize: "1.2rem", color: "blue" }}>
          Wersja 1.0.0.7 (11.07.2023r.):
        </Typography>
        <Typography>
          - poprawiony i ulepszony panel logowania
          <br />
          - dodanie responsywności do lokalizacji, firm, urządzeń,
          <br />
          - dodanie modułu raportów utylizacyjnych - wgranie danych
          dotyczących kart produktów
        </Typography>
        <Typography sx={{ fontSize: "1.2rem", color: "blue" }}>
          Wersja 1.0.0.6 (16.06.2023r.):
        </Typography>
        <Typography>
          - w pełni responsywne widoki magazyny, zapotrzebowania, przesyłki,
          zadania, zlecenia
          <br />
          <span style={{ color: "red", fontWeight: 700 }}>
            (prócz formularza tworzenia zleceń !!!!)
          </span>
          + komponenty pokrewne
          <br />
          - Załadowanie danych firm z lokalizacjami i urządzeniami z bazy JTI
          (poprawione - jeden tenant)
          <br />
          - naprawa błędów z ostatnich testów
          <br />
          -naprawa drobnych napotkanych błędów
        </Typography>
        <Typography sx={{ fontSize: "1.2rem", color: "blue" }}>
          Wersja 1.0.0.5 (27.04.2023r.):
        </Typography>
        <Typography>
          - Optymalizacja komponentów tasków, użytkowników, firm, lokalizacji
          oraz urządzeń, wraz z komponentami pokrewnymi
          <br />
          - Załadowanie danych firm z lokalizacjami i urządzeniami z bazy
          JTI
        </Typography>
        <Typography sx={{ fontSize: "1.2rem", color: "blue" }}>
          Wersja 1.0.0.4.1 (11.04.2023r.):
        </Typography>
        <Typography>- Naprawa bugów API</Typography>
        <Typography sx={{ fontSize: "1.2rem", color: "blue" }}>
          Wersja 1.0.0.4 (15.03.2023r.):
        </Typography>
        <Typography>
          - Poprawa i dodanie funkcjonalności związanych z komponentami:
          Dashboard wraz ze wszystkimi pokrewnymi, widok magazynu
          głównego/lokalnego wraz ze wszystkimi pokrewnymi oraz listy magazynów.
          Optymalizacja kodu ma na celu atomizację komponentów, ich
          standaryzację oraz ujednolicanie widoków i funkcjonalności.
          <br />
          - Przeniesie kodu związanego ze stylizowaniem komponentów do osobnych
          plików - dla każdego przerabianego komponentu.
          <br />
          - Wydzielenie w stanie globalnym sliceów odpowiedzialnych za dany byt
          - idea na podstawie backendu. Również wydzielnie wszystkich bytów
          powiązanych z wcześniej wspomnianymi komponentami w celu lepszej
          czytelności kodu i przyszłych modyfikacji.
          <br />
          - Poprawy i ujednolicanie metod na backendzie.
        </Typography>
      </Box>
    </Box>
  );
};

export default ChangeLog;
