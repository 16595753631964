/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { setHeaderSize } from "../../static/functions";

const CustomBox = styled(Box)(() => ({
  display: "grid",
  alignItems: "center",
}));

const TableGridWrapper = ({ children, styles, headers, gap }) => {
  const [currentHeaderSize, setCurrentHeaderSize] = useState("");
  const [windowSize, setWindowSize] = useState("");

  const handleWindowResize = useCallback(() => {
    setWindowSize(window.innerWidth);
  }, []);

  const calculateHeaderSize = useCallback(() => {
    const size = setHeaderSize(headers);
    setCurrentHeaderSize(size);
  }, [windowSize, headers]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    calculateHeaderSize();
  }, [calculateHeaderSize]);

  return (
    <CustomBox
      sx={{ ...styles, gridTemplateColumns: currentHeaderSize }}
      gap={gap}
    >
      {children}
    </CustomBox>
  );
};

export default TableGridWrapper;
