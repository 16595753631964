import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function useMediaQ() {
  const { breakpoints } = useTheme();
  const isPhoneDisplay = useMediaQuery(breakpoints.only("mobile"));
  const isTabletDisplay = useMediaQuery(breakpoints.only("tablet"));
  const isLaptopDisplay = useMediaQuery(breakpoints.only("laptop"));
  const isDesktopDisplay = useMediaQuery(breakpoints.only("desktop"));
  const isMobileDisplay = useMediaQuery(breakpoints.between("mobile", "laptop"));
  const isNotMobileDisplay = useMediaQuery(breakpoints.up("laptop"));

  return {
    isPhoneDisplay,
    isTabletDisplay,
    isLaptopDisplay,
    isDesktopDisplay,
    isMobileDisplay,
    isNotMobileDisplay,
  };
}
