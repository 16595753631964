/* eslint-disable react/prop-types */
import React from 'react';
import TableDataGridWrapper from '../../../../../TableGridWrapper/TableDataGridWrapper';
import { commissionTasksViewHeaders } from '../../../../../../static/tablesHeaders';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { dateFormatView } from '../../../../../../static/functions';
import CustomChip from '../../../../../CustomChip/CustomChip';
import { taskStatus, taskStatusChipStyles, taskType, taskTypeChipStyles } from '../../../../../../static/static';
import DetailsButton from '../../../../../DetailsButton/DetailsButton';
import { useNavigate } from "react-router-dom";
import LongerTextModal from '../../../../../LongerTextModal/LongerTextModal';
import TaskExecution from '../../../../../TaskView/TaskExecution/TaskExecution';
  
const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "0.1rem 1rem",
}));

const CommissionTaskItemDesktop = ({
  orderNumber,
  task,
  chosenUser,
  setRefresh,
}) => {
  const navigate = useNavigate();

  const {
    id,
    creationDate,
    planningExecutionDate,
    executionDate,
    description,
    executor,
    proposedExecutor,
    assignedExecutor,
    status,
    type,
  } = task;

  return (
    <TableDataGridWrapper headers={commissionTasksViewHeaders} gap="1%">
      <Typography>{orderNumber}</Typography>
      <Typography>{dateFormatView(creationDate)}</Typography>
      <CustomChip label={taskType[type]} sx={taskTypeChipStyles(type, "")} />
      <CustomChip
        label={taskStatus[status]}
        sx={taskStatusChipStyles(status, "")}
      />
      <Typography>
        {planningExecutionDate ? dateFormatView(planningExecutionDate) : "Brak"}
      </Typography>
      <Typography>
        {executionDate ? dateFormatView(executionDate) : "Brak"}
      </Typography>
      <Typography>
        {proposedExecutor
          ? `${proposedExecutor.name} ${proposedExecutor.surname}`
          : ""}
      </Typography>
      <Typography>
        {assignedExecutor
          ? `${assignedExecutor.name} ${assignedExecutor.surname}`
          : ""}
      </Typography>
      <Typography>
        {executor ? `${executor.name} ${executor.surname}` : ""}
      </Typography>
      <LongerTextModal
        text={description ? description : "Brak"}
        quantity={20}
      />
      <ButtonWrapper>
        <DetailsButton onClick={() => navigate(`/tasks/${id}`)} width="70%" />
        <TaskExecution
          task={task}
          setRefresh={setRefresh}
          chosenUser={chosenUser}
        />
      </ButtonWrapper>
    </TableDataGridWrapper>
  );
};

export default CommissionTaskItemDesktop;