/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { deleteCategory } from "../../../slices/categorySlice";
import AcceptButton from "../../AcceptButton/AcceptButton";
import DeleteButton from "../../DeleteButton/DeleteButton";
import { Clear } from "@mui/icons-material";
import PageHeader from "../../PageHeader/PageHeader";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  height: "8rem",
  width: "26rem",
  [theme.breakpoints.down("tablet")]: {
    width: "18rem",
  },
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "1rem",
}));

// eslint-disable-next-line react/prop-types
const CategoryDeleteForm = ({
  categoryId,
  categoryName,
  setVisibility,
  setRefresh,
}) => {
  const dispatch = useDispatch();

  const deleteItem = async () => {
    await dispatch(deleteCategory(categoryId));
    setRefresh((state) => !state);
    setVisibility(false);
  };

  return (
    <Wrapper>
      <PageHeader
        titleText={`Czy usunąć kategorię ${categoryName} ?`}
        icon={<Clear />}
        styleProps={{ margin: "0 1rem" }}
        onRightComponent={<></>}
        size="medium"
      />
      <ButtonWrapper>
        <AcceptButton onClick={() => deleteItem()} text="Tak" />
        <DeleteButton onClick={() => setVisibility(false)} text="Nie" />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default CategoryDeleteForm;
