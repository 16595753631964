import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API
} from '../static/static';


export const updateNotifications = createAsyncThunk('notifications/update', async (data) => {
  const resData = await dataService.put(`${API}notification/update/`, data);
  return resData;
});


//--------------------------Permissions API---------------------------------------------
export const getPermissionsByGroup = createAsyncThunk('permissions/group/get', async () => {
  const resData = await dataService.get(`${API}permissions/group`);
  return resData;
});

export const updateUserPermissions = createAsyncThunk('user/permissions/update', async ({
  data,
  userId
}) => {
  const resData = await dataService.put(`${API}user/permissions/update/${userId}/`, data);
  return resData;
});

const initialState = {
  permissions: null,
};


export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    clearPermissions: (state) => {
      state.permissions = null;
    },

  },
  extraReducers: (builder) => {
    builder
      //-------------------users---------------------

      .addCase(getPermissionsByGroup.fulfilled, (state, action) => {
        state.permissions = action.payload.data;
      });
  }
});

export const {
  clearPermissions
} = permissionSlice.actions;

export default permissionSlice.reducer;