/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Typography } from "@mui/material";
import { dateFormatView } from "../../../static/functions";
import {
  commissionStatus,
  commissionStatusChipStyles,
  commissionType,
  commissionTypesChipStyles,
} from "../../../static/static";
import PageHeader from "../../PageHeader/PageHeader";
import { Article } from "@mui/icons-material";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
  SpecialDataBox,
} from "../../ObjectDetailsElements/ObjectDetailsElements";
import CustomChip from "../../CustomChip/CustomChip";

const CommissionDetails = ({ commission }) => {
  const {
    id,
    faultDescription,
    fixDescription,
    creationDate,
    finishDate,
    status,
    type,
    openCode,
  } = commission;

  return (
    <SectionWrapper>
      <PageHeader icon={<Article />} titleText="Dane zlecenia" size="medium" />
      <InfoWrapper>
        <DataBox>
          <DataTitle> Nr zlecenia:</DataTitle>
          <Typography>{id}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Data utworzenia:</DataTitle>
          <Typography>
            {creationDate !== null && dateFormatView(creationDate)}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Data zakończenia:</DataTitle>
          <Typography>
            {finishDate !== null && status === "Closed"
              ? dateFormatView(finishDate)
              : "Brak"}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Status:</DataTitle>
          <CustomChip
            label={commissionStatus[status]}
            sx={commissionStatusChipStyles(status, "")}
            width="40%"
          />
        </DataBox>
        <DataBox>
          <DataTitle>Typ:</DataTitle>
          <CustomChip
            label={commissionType[type]}
            sx={commissionTypesChipStyles[type]}
            width="60%"
          />
        </DataBox>
        {openCode && (
          <DataBox>
            <DataTitle>Kod błędu:</DataTitle>
            <Typography>{`${openCode.id} ${openCode.name}`}</Typography>
          </DataBox>
        )}
        <SpecialDataBox>
          <DataTitle>Opis błędu:</DataTitle>
          <Typography>{faultDescription}</Typography>
        </SpecialDataBox>
        <SpecialDataBox>
          <DataTitle>Opis naprawy:</DataTitle>
          <Typography>{fixDescription ? fixDescription : "---"}</Typography>
        </SpecialDataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default CommissionDetails;
