import { useState } from "react";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../static/static";

export default function usePagination(
  defaultSorter = "name",
  defaultSorting = "ascending"
) {
  const [refresh, setRefresh] = useState(false);
  const [sorter, setSorter] = useState(defaultSorter);
  const [sorting, setSorting] = useState(defaultSorting);
  const [searchingValue, setSearchingValue] = useState("");
  const [displaySearchingValue, setDisplaySearchingValue] = useState("");
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(DEFAULT_DISPLAY_ITEMS_QUANTITY);
  const [isDataLoading, setIsDataLoading] = useState(true);

  return {
    refresh,
    setRefresh,
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  };
}
