/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import DOAUnitItemDesktop from "./DOAUnitItemVersions/DOAUnitItemDesktop";
import DOAUnitItemMobile from "./DOAUnitItemVersions/DOAUnitItemMobile";

const DOAUnitItem = ({ orderNumber, unit, removeDOAItem }) => {
  const { isDesktopDisplay } = useMediaQ();
  const {
    index,
    removeInner,
  } = unit;

  const deleteElement = () => {
    const value = removeDOAItem(index);
    removeInner(value);
  };
  return (
    <>
      {isDesktopDisplay ? (
        <DOAUnitItemDesktop
          orderNumber={orderNumber}
          unit={unit}
          deleteElement={deleteElement}
        />
      ) : (
        <DOAUnitItemMobile
          orderNumber={orderNumber}
          unit={unit}
          deleteElement={deleteElement}
        />
      )}
    </>
  );
};

export default DOAUnitItem;
