/* eslint-disable react/prop-types */
import React from "react";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dateFormatView } from "../../../../static/functions";
import CustomChip from "../../../CustomChip/CustomChip";
import { taskStatus, taskStatusChipStyles, taskType, taskTypeChipStyles } from "../../../../static/static";
import { setChosenTaskId } from "../../../../slices/taskSlice";
import DetailsButton from "../../../DetailsButton/DetailsButton";

const TaskHistoryItemMobile = ({ task }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: taskId, location, creationDate, status, type } = task;

  const data = [
    { title: "Data utworzenia:", value: dateFormatView(creationDate) },
    { title: "Kod RT:", value: location.code },
    {
      title: "Typ:",
      value: (
        <CustomChip
          label={taskType[type]}
          sx={taskTypeChipStyles(type, "")}
          width="100%"
        />
      ),
    },
    {
      title: "Status:",
      value: (
        <CustomChip
          label={taskStatus[status]}
          sx={taskStatusChipStyles(status, "")}
          width="100%"
        />
      ),
    },
  ];

  const taskDetails = () => {
    dispatch(setChosenTaskId(taskId));
    navigate(`/tasks/${taskId}`);
  };

  return (
    <CompactDataDisplay
      title={`Zadanie #${taskId}`}
      data={data}
      actions={
        <>
          <DetailsButton onClick={() => taskDetails()} />
        </>
      }
    />
  );
};

export default TaskHistoryItemMobile;
