/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Dialog } from "@mui/material";
import ProducerDeleteForm from "../../Producer/ProducerDeleteForm/ProducerDeleteForm";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProducerId } from "../../../slices/producerSlice";
import useMediaQ from "../../../hooks/useMediaQ";
import ProducersItemDesktop from "./ProducersItemVersions/ProducersItemDesktop";
import ProducersItemMobile from "./ProducersItemVersions/ProducersItemMobile";

const ProducerItem = ({ orderNumber, producer, setRefresh }) => {
  const { isDesktopDisplay } = useMediaQ();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, name } = producer;
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  // const isLargeDisplayStart = useMediaQuery("(min-width:1200px)");

  const editProducer = () => {
    dispatch(setProducerId(id));
    navigate("/producer-edit");
  };

  const delProducer = () => {
    setIsDeleteModalVisible(true);
  };

  return (
    <>
      {isDesktopDisplay ? (
        <ProducersItemDesktop
          orderNumber={orderNumber}
          producer={producer}
          editProducer={editProducer}
          delProducer={delProducer}
        />
      ) : (
        <ProducersItemMobile
          orderNumber={orderNumber}
          producer={producer}
          editProducer={editProducer}
          delProducer={delProducer}
        />
      )}

      {isDeleteModalVisible && (
        <Dialog
          open={isDeleteModalVisible}
          onClose={() => setIsDeleteModalVisible(false)}
        >
          <ProducerDeleteForm
            producerId={id}
            producerName={name}
            setVisibility={setIsDeleteModalVisible}
            setRefresh={setRefresh}
          />
        </Dialog>
      )}
    </>
  );
};

export default ProducerItem;
