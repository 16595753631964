/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
// import Select from 'react-select';
import { getPaginatedProducts, getProducts } from "../../slices/productSlice";
import PageHeader from "../PageHeader/PageHeader";
import { Add } from "@mui/icons-material";
import DetailsButton from "../DetailsButton/DetailsButton";
import SearchDropdown from "../SearchDropdown/SearchDropdown";

const Wrapper = styled(Box)(({ theme }) => ({
  minHeight: "20rem",
  width: "31.25rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.625rem",
  [theme.breakpoints.down("tablet")]: {
    width: "16rem",
  },
}));

const AddingBADForm = ({
  unmountedProducts,
  setUnmountedProducts,
  setIsAddingUnitModalVisible,
}) => {
  const dispatch = useDispatch();
  const [chosenProduct, setChosenProduct] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [serialNumberError, setSerialNumberError] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [buttonLock, setButtonLock] = useState(false);

  const getData = useCallback(async () => {
    await dispatch(getProducts());
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const changeQuantity = (value) => {
    setQuantity(value);
  };

  const changeSerialNumber = (value) => {
    setSerialNumber(value);
    if (unmountedProducts.length > 0) {
      const findingIndex = unmountedProducts.findIndex(
        (unit) => unit.serialNumber === value
      );
      if (findingIndex !== -1) {
        setSerialNumberError(true);
      } else {
        setSerialNumberError(false);
      }
    }
  };

  const addBADProduct = useCallback(() => {
    let index = 0;
    if (unmountedProducts.length > 0) {
      index = unmountedProducts[unmountedProducts.length - 1].index + 1;
    }
    setUnmountedProducts((state) => [
      ...state,
      {
        index,
        partNumber: chosenProduct.partNumber,
        serialNumber,
        quantity,
        status: "BAD",
      },
    ]);
    setIsAddingUnitModalVisible(false);
  }, [chosenProduct]);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (keyCode === 13 && !buttonLock) {
        addBADProduct();
        setButtonLock(true);
        setTimeout(() => setButtonLock(false), 5000);
      }
    },
    [buttonLock, addBADProduct]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <Wrapper>
      <Typography
        sx={{
          fontSize: "1.25em",
          marginTop: "1.25em",
          ["@media (max-width:767px)"]: {
            fontSize: "1em",
          },
        }}
      ></Typography>
      <PageHeader
        titleText="Dodawanie produktu BAD"
        icon={<Add />}
        size="medium"
      />
      <SearchDropdown
        collectionName="products"
        sliceName="product"
        getDataFunction={getPaginatedProducts}
        placeholder="Wybierz produkt"
        propertyToDisplay="partNumber"
        setFunction={(item) => setChosenProduct(item)}
        isSearchingValueObjectProps={true}
        propsWidth="90%"
      />
      {chosenProduct && chosenProduct.type === "Quantitative" && (
        <TextField
          sx={{
            width: "90%",
          }}
          label="Numer seryjny"
          value={serialNumber}
          error={serialNumberError}
          helperText={serialNumberError && "Numer seryjny musi być unikalny!"}
          onChange={(e) => changeSerialNumber(e.target.value)}
        />
      )}

      <TextField
        sx={{
          width: "90%",
        }}
        label="Liczba sztuk"
        type="number"
        value={quantity}
        onChange={(e) => changeQuantity(e.target.value)}
        disabled={chosenProduct && chosenProduct.type === "Quantitative"}
        InputProps={
          (chosenProduct &&
            chosenProduct.type === "Quantitative" && {
              inputProps: { min: 1, max: 1 },
            }) ||
          (chosenProduct &&
            chosenProduct.type === "Numerical" && {
              inputProps: { min: 1 },
            })
        }
      />

      <DetailsButton
        onClick={() => addBADProduct()}
        disabled={
          (chosenProduct &&
            chosenProduct.type === "Quantitative" &&
            serialNumber.length === 0) ||
          !chosenProduct
        }
        text="Dodaj produkt BAD"
        startIcon={<Add />}
      />
    </Wrapper>
  );
};

export default AddingBADForm;
