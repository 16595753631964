/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import TableHeader from "../../TableHeader/TableHeader";
import { workModalAvailableUnitsHeaders } from "../../../static/tablesHeaders";
import AvailableUnitsItem from "./AvailableUnitsItem";
import EmptyListError from "../../EmptyListError/EmptyListError";
import { Apps, Warning } from "@mui/icons-material";
import PageHeader from "../../PageHeader/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../../../static/static";
import {
  clearUnits,
  getPaginatedUnitsByStorage,
  getUnitsByStorageCount,
} from "../../../slices/unitSlice";
import Loading from "../../Loading/Loading";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import MultipleChipSelect from "../../MultipleChipSelect/MultipleChipSelect";
import MobileDataSorter from "../../MobileDataSorter/MobileDataSorter";
import {
  clearCategories,
  getFilteredCategories,
} from "../../../slices/categorySlice";
import {
  clearProducers,
  getFilteredProducers,
} from "../../../slices/producerSlice";
import Pagination from "../../Pagination/Pagination";
import TableWrapper from "../../TableWrapper/TableWrapper";

const UnitsWrapper = styled(Box)(() => ({
  maxHight: "15.625rem",
  overflowY: "auto",
}));

const AvailableUnits = ({
  mountedProducts,
  setMountedProducts,
  fatalProducts,
  setFatalProducts,
  chosenStorageId,
}) => {
  const dispatch = useDispatch();
  const searchingRef = useRef("");
  const { units, unitsCount } = useSelector((state) => state.unit);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [sorter, setSorter] = useState("partNumber");
  const [sorting, setSorting] = useState("ascending");
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(DEFAULT_DISPLAY_ITEMS_QUANTITY);
  const [searchingValue, setSearchingValue] = useState("");
  const [displaySearchingValue, setDisplaySearchingValue] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProducers, setSelectedProducers] = useState([]);

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getUnitsByStorageCount({
        searchingValue,
        status: "GOOD",
        storageId: chosenStorageId,
        categories: selectedCategories.map((item) => item.name),
        producers: selectedProducers.map((item) => item.name),
        greaterThanZero: true,
      })
    );
  }, [
    chosenStorageId,
    searchingValue,
    selectedCategories,
    selectedProducers,
  ]);

  const loadData = useCallback(async () => {
    await dispatch(
      getPaginatedUnitsByStorage({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        status: "GOOD",
        storageId: chosenStorageId,
        categories: selectedCategories.map((item) => item.name),
        producers: selectedProducers.map((item) => item.name),
        greaterThanZero: true,
      })
    ),
      setIsDataLoading(false);
  }, [
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    chosenStorageId,
    selectedCategories,
    selectedProducers,
  ]);

  useEffect(() => {
    if (chosenStorageId !== null) {
      loadDataCount();
    }
  }, [loadDataCount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearUnits());
  }, []);

  return (
    <SectionWrapper>
      <PageHeader
        titleText="Aktualny stan magazynu"
        size="medium"
        icon={<Apps />}
      />
      <AdditionalFunctionalitiesWrapper>
        <CustomSearchField
          displaySearchingValue={displaySearchingValue}
          setDisplaySearchingValue={setDisplaySearchingValue}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
          searchingRef={searchingRef}
          placeholder="szukaj produktu..."
          width="20%"
        />
        <MultipleChipSelect
          name="Kategorie:"
          sliceName="category"
          collectionName="categories"
          getDataFunction={getFilteredCategories}
          cleanDataFunction={clearCategories}
          setFunction={setSelectedCategories}
          settingCollection={selectedCategories}
        />
        <MultipleChipSelect
          name="Producenci:"
          sliceName="producer"
          collectionName="producers"
          getDataFunction={getFilteredProducers}
          cleanDataFunction={clearProducers}
          setFunction={setSelectedProducers}
          settingCollection={selectedProducers}
        />
        <MobileDataSorter
          tableHeader={workModalAvailableUnitsHeaders}
          setOuterSorter={setSorter}
          setOuterSorting={setSorting}
          defaultSorter="partNumber"
        />
      </AdditionalFunctionalitiesWrapper>
      <TableWrapper>
        <TableHeader
          headers={workModalAvailableUnitsHeaders}
          setSorter={setSorter}
          setSorting={setSorting}
          gap="1%"
        />
        <UnitsWrapper>
          <Pagination
            collectionSize={unitsCount}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            setLeftIndex={setLeftIndex}
            setRightIndex={setRightIndex}
          >
            {isDataLoading ? (
              <Loading />
            ) : (
              <>
                {units !== null &&
                  units.map((unit, index) => (
                    <AvailableUnitsItem
                      key={unit.id}
                      unit={unit}
                      index={index}
                      orderNumber={index + 1}
                      mountedProducts={mountedProducts}
                      setMountedProducts={setMountedProducts}
                      fatalProducts={fatalProducts}
                      setFatalProducts={setFatalProducts}
                    />
                  ))}
              </>
            )}
            {units !== null && units.length === 0 && (
              <EmptyListError
                message="Brak dostępnych jednostek w wybranym magazynie"
                icon={<Warning />}
              />
            )}
          </Pagination>
        </UnitsWrapper>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default AvailableUnits;
