/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DEFAULT_DISPLAY_ITEMS_QUANTITY,
  taskStatusChipStyles,
  taskTypeChipStyles,
} from "../../../static/static";
import {
  clearTasks,
  getPaginatedTasks,
  getTasksCount,
} from "../../../slices/taskSlice";
import { CheckCircleOutline, Task } from "@mui/icons-material";
import Pagination from "../../Pagination/Pagination";
import Loading from "../../Loading/Loading";
import TaskHistoryItem from "../TaskHistoryItem/TaskHistoryItem";
import DateRangeSelector from "../../DateRangeSelector/DateRangeSelector";
import { getLocalTimeDate } from "../../../static/functions";
import RefreshButton from "../../RefreshButton/RefreshButton";
import PageHeader from "../../PageHeader/PageHeader";
import BlankSpace from "../../BlankSpace/BlankSpace";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import ChipSet from "../../ChipSet/ChipSet";
import TableHeader from "../../TableHeader/TableHeader";
import { historyTaskListHeaders } from "../../../static/tablesHeaders";
import EmptyListError from "../../EmptyListError/EmptyListError";
import PageWrapper from "../../PageWrapper/PageWrapper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import useMediaQ from "../../../hooks/useMediaQ";
import MobileDataSorter from "../../MobileDataSorter/MobileDataSorter";
import authService from "../../../auth/authService";
import ReturnButton from "../../ReturnButton/ReturnButton";

const typeChipSet = (role) => {
  if (role === "admin")
    return [
      {
        label: "Wszystkie",
        keyValue: "",
      },
      {
        label: "Kwalifikacyjne",
        keyValue: "Qualification",
      },
      {
        label: "Przypisania",
        keyValue: "Assignment",
      },
      {
        label: "Do akceptacji",
        keyValue: "Approval",
      },
      {
        label: "Wykonawcze",
        keyValue: "Work",
      },
      {
        label: "Weryfikacyjne",
        keyValue: "Verification",
      },
    ];
  if (role === "service_technician")
    return [
      {
        label: "Wszystkie",
        keyValue: "",
      },
      {
        label: "Do akceptacji",
        keyValue: "Approval",
      },
      {
        label: "Wykonawcze",
        keyValue: "Work",
      },
    ];
};

const statusChipSet = [
  {
    label: "Odrzucone/Zamknęte",
    keyValue: ["Closed", "Rejected"],
  },
  {
    label: "Zamknięte",
    keyValue: "Closed",
  },
  {
    label: "Odrzucone",
    keyValue: "Rejected",
  },
];

const TaskHistory = () => {
  const dispatch = useDispatch();
  const { tasks, tasksCount } = useSelector((slice) => slice.task);
  const searchingRef = useRef("");
  const [refresh, setRefresh] = useState(false);
  const [sorter, setSorter] = useState("executionDate");
  const [sorting, setSorting] = useState("descending");
  const [searchingValue, setSearchingValue] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [statusValue, setStatusValue] = useState(["Closed", "Rejected"]);
  const [displaySearchingValue, setDisplaySearchingValue] = useState("");
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(DEFAULT_DISPLAY_ITEMS_QUANTITY);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [createdDates, setCreationDates] = useState({ start: "", end: "" });
  const [finishDates, setFinishDates] = useState({ start: "", end: "" });
  const { isPhoneDisplay } = useMediaQ();
  const { user_id: userId, role } = authService.getDecodedAccessToken();

  const setCreationDateInterval = (dates) => {
    const [startDate, endDate] = dates;
    setCreationDates({
      start: startDate,
      end: endDate,
    });
  };

  const setFinishDateInterval = (dates) => {
    const [startDate, endDate] = dates;
    setFinishDates({
      start: startDate,
      end: endDate,
    });
  };

  const loadDataCount = useCallback(async () => {
    await dispatch(
      getTasksCount({
        searchingValue,
        type: filteredValue,
        status: statusValue,
        parentTask: "",
        commission: "",
        assignedServiceTechnician: "",
        proposedServiceTechnician: "",
        executor: role === "admin" ? "" : userId,
        creationDate: "",
        planningExecutionDateStart: "",
        planningExecutionDateEnd: "",
        executionDate: "",
        creationDateStart: createdDates.start
          ? getLocalTimeDate(createdDates.start)
          : "",
        creationDateEnd: createdDates.end
          ? getLocalTimeDate(createdDates.end)
          : "",
        finishDateStart: finishDates.start
          ? getLocalTimeDate(finishDates.start)
          : "",
        finishDateEnd: finishDates.end ? getLocalTimeDate(finishDates.end) : "",
      })
    );
  }, [
    refresh,
    searchingValue,
    filteredValue,
    createdDates.start,
    createdDates.end,
    finishDates.start,
    finishDates.end,
  ]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedTasks({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        type: filteredValue,
        status: statusValue,
        parentTask: "",
        commission: "",
        assignedServiceTechnician: "",
        proposedServiceTechnician: "",
        executor: role === "admin" ? "" : userId,
        creationDate: "",
        planningExecutionDateStart: "",
        planningExecutionDateEnd: "",
        executionDate: "",
        creationDateStart: createdDates.start
          ? getLocalTimeDate(createdDates.start)
          : "",
        creationDateEnd: createdDates.end
          ? getLocalTimeDate(createdDates.end)
          : "",
        finishDateStart: finishDates.start
          ? getLocalTimeDate(finishDates.start)
          : "",
        finishDateEnd: finishDates.end ? getLocalTimeDate(finishDates.end) : "",
      })
    );
    setIsDataLoading(false);
  }, [
    refresh,
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    filteredValue,
    createdDates.start,
    createdDates.end,
    finishDates.start,
    finishDates.end,
  ]);

  useEffect(() => {
    loadDataCount();
  }, [loadDataCount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearTasks());
  }, []);

  return (
    <PageWrapper width={isPhoneDisplay ? "95%" : "80%"}>
      <PageHeader
        titleText="Historia zadań"
        icon={<Task />}
        onRightComponent={<ReturnButton />}
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <BlankSpace />
      <SectionWrapper>
        <AdditionalFunctionalitiesWrapper>
          <CustomSearchField
            displaySearchingValue={displaySearchingValue}
            setDisplaySearchingValue={setDisplaySearchingValue}
            searchingValue={searchingValue}
            setSearchingValue={setSearchingValue}
            searchingRef={searchingRef}
            placeholder="szukaj przesyłki..."
          />
          <ChipSet
            mainLabel="Typy:"
            set={typeChipSet(role)}
            chipStyles={taskTypeChipStyles}
            setFunction={setFilteredValue}
            actualValue={filteredValue}
          />
          <ChipSet
            mainLabel="Statusy:"
            set={statusChipSet}
            chipStyles={taskStatusChipStyles}
            setFunction={setStatusValue}
            actualValue={statusValue}
          />
          <DateRangeSelector
            labelName="Data utworzenia: "
            datesRange={createdDates}
            setDatesRange={(dates) => setCreationDateInterval(dates)}
            maxDate={new Date()}
          />
          <DateRangeSelector
            labelName="Data zakończenia: "
            datesRange={finishDates}
            setDatesRange={(dates) => setFinishDateInterval(dates)}
            maxDate={new Date()}
          />
          <MobileDataSorter
            tableHeader={historyTaskListHeaders}
            setOuterSorter={setSorter}
            setOuterSorting={setSorting}
            defaultSorter="creationDate"
          />
        </AdditionalFunctionalitiesWrapper>
        <TableHeader
          headers={historyTaskListHeaders}
          setSorter={setSorter}
          setSorting={setSorting}
          gap="1%"
        />
        <Pagination
          collectionSize={tasksCount}
          leftIndex={leftIndex}
          rightIndex={rightIndex}
          setLeftIndex={setLeftIndex}
          setRightIndex={setRightIndex}
        >
          {isDataLoading ? (
            <Loading />
          ) : (
            <>
              {tasks !== null &&
                tasks.map((task, id) => (
                  <TaskHistoryItem
                    orderNumber={leftIndex + id + 1}
                    key={task.id}
                    task={task}
                    setRefresh={setRefresh}
                  />
                ))}
            </>
          )}
          {tasks !== null && tasks.length === 0 && (
            <EmptyListError
              icon={<CheckCircleOutline />}
              message="Brak zadań"
            />
          )}
        </Pagination>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default TaskHistory;
