import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import authService from "../../auth/authService";
import { logout } from "../../slices/authSlice";
import { resetState } from "../../slices/alertSlice";
import { styled } from "@mui/material/styles";
import { MeetingRoom } from "@mui/icons-material";

const LogoutButton = styled(Button)(({ theme }) => ({
  width: "100%",
  border: "0.0625rem solid rgb(27,150,250)",
  backgroundColor: theme.palette.primary.main,
  color: "#082032",
  transition: "1s",
  "&:hover": {
    bgcolor: "#2C394B",
    color: "rgb(27,150,250)",
  },
}));

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutUser = () => {
    authService.logout();
    dispatch(logout());
    dispatch(resetState());
    navigate("/login");
  };

  return (
    <LogoutButton onClick={() => logoutUser()} startIcon={<MeetingRoom />}>
      Wyloguj
    </LogoutButton>
  );
};

export default Logout;
