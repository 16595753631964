/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import PageHeader from "../../../PageHeader/PageHeader";
import {
  AcceptButton,
  CloseButton,
} from "../../../FunctionalButtons/FunctionalButtons";
import UnitCheckSection from "./UnitCheckSection";
import { Check } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { deliverShipment } from "../../../../slices/shipmentSlice";
import authService from "../../../../auth/authService";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  maxHeight: "36rem",
  width: "60rem",
  padding: "1rem",
  [theme.breakpoints.down("laptop")]: {
    width: "90%",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "16rem",
  },
}));

const ButtonBox = styled(Box)(() => ({
  display: "flex",
  gap: "0.625em",
  marginTop: "1rem",
}));

const ToDeliveredModal = ({
  isStatusChangeToDeliveredModalVisible,
  setIsStatusChangeToDeliveredModalVisible,
  shipment,
  setRefresh,
}) => {
  const dispatch = useDispatch();
  const [report, setReport] = useState([]);

  const changeStatusToDelivered = async () => {
    const data = {
      receiver: authService.getDecodedAccessToken().user_id,
      report,
    };
    await dispatch(deliverShipment({ shipmentId: shipment.id, data }));
    setRefresh((state) => !state);
  };

  return (
    <Dialog
      open={isStatusChangeToDeliveredModalVisible}
      onClose={() => setIsStatusChangeToDeliveredModalVisible(false)}
    >
      <CustomBox>
        <PageHeader
          titleText="Weryfikacja poprawności przesyłki"
          icon={<Check />}
          size="medium"
        />
        <UnitCheckSection
          inventory={shipment.shipment_units}
          report={report}
          setReport={setReport}
        />
        <ButtonBox>
          <AcceptButton
            onClick={() => {
              changeStatusToDelivered();
              setIsStatusChangeToDeliveredModalVisible(false);
            }}
            text="Potwierdzam"
            disabled={report.length !== shipment.shipment_units.length}
          />
          <CloseButton
            onClick={() => setIsStatusChangeToDeliveredModalVisible(false)}
            text="Wyjdź"
          />
        </ButtonBox>
      </CustomBox>
    </Dialog>
  );
};

export default ToDeliveredModal;
