/* eslint-disable react/prop-types */
import React from "react";
import TableHeader from "../../../TableHeader/TableHeader";
import { userPermissionsHeaders } from "../../../../static/tablesHeaders";
import UserPermissionsItem from "./UserPermissionsItem";

const UserPermissionsView = ({ userPermissions }) => {
  return (
    <>
      <TableHeader
        headers={userPermissionsHeaders}
        setSorter={() => {}}
        setSorting={() => {}}
        gap="0.5rem"
      />
      <>
        {userPermissions.map((permission, id) => (
          <UserPermissionsItem key={id} permission={permission} orderNumber={id+1}/>
        ))}
      </>
    </>
  );
};

export default UserPermissionsView;
