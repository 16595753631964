/* eslint-disable react/prop-types */
import React from "react";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../../../TableGridWrapper/TableDataGridWrapper";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { Autorenew } from "@mui/icons-material";
import DetailsButton from "../../../../DetailsButton/DetailsButton";
import { locationDevicesViewHeaders } from "../../../../../static/tablesHeaders";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const LocationDevicesItemDesktop = ({ orderNumber, device }) => {
  const navigate = useNavigate();
  const { id, serialNumber, name, type, isSynchronized } = device;

  return (
    <TableDataGridWrapper headers={locationDevicesViewHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{serialNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <CustomDataDisplay>{type}</CustomDataDisplay>
      {isSynchronized ? (
        <Autorenew
          sx={{
            color: "darkgreen",
          }}
        />
      ) : (
        <Autorenew
          sx={{
            color: "red",
          }}
        />
      )}
      <DetailsButton onClick={() => navigate(`/devices/${id}`)} />
    </TableDataGridWrapper>
  );
};

export default LocationDevicesItemDesktop;
