/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import ProductsList from "../ProductsList/ProductList/ProductsList";
import CategoriesList from "../CategoriesList/CategoriesList/CategoriesList";
import ProducersList from "../ProducersList/ProducersList/ProducersList";
import ChoosingButton from "../ChoosingButton/ChoosingButton";
import { useNavigate } from "react-router-dom";

const ButtonWrapper = styled(Box)(({ theme }) => ({
  width: "90%",
  minHeight: '3rem',
  margin: "1rem auto 0 auto",
  display: "flex",
  flexWrap: "wrap",
  gap: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("tablet")]: {
    width: "70%",
  },
}));

const ProductsAssociations = ({
  products = false,
  categories = false,
  producers = false,
}) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  return (
    <>
      <ButtonWrapper>
        <ChoosingButton
          onClick={() => {
            navigate("/products");
          }}
          name="produkty"
        />
        <ChoosingButton
          onClick={() => {
            navigate("/categories");
          }}
          name="kategorie"
        />

        <ChoosingButton
          onClick={() => {
            navigate("/producers");
          }}
          name="producenci"
        />
      </ButtonWrapper>
      <Box sx={{marginTop: '1rem'}}>
        {products && <ProductsList refresh={refresh} setRefresh={setRefresh} />}
        {categories && (
          <CategoriesList refresh={refresh} setRefresh={setRefresh} />
        )}
        {producers && (
          <ProducersList refresh={refresh} setRefresh={setRefresh} />
        )}
      </Box>
    </>
  );
};

export default ProductsAssociations;
