import axios from 'axios';
import authService from '../auth/authService';
import {
  SERVER_URL
} from '../static/static';
import {
  BASE_URL
} from '../static/static';

const transformUrl = (url) => {
  let userId = "";
  try {
    userId = authService.getDecodedAccessToken().user_id;
  } catch {
    console.log();
  }
  if (url.indexOf("/?") !== -1) {
    return `${url}&userId=${userId}`;
  } else {
    return `${url}/?userId=${userId}`;
  }
};

let isRefreshing = false;
let failedQueue = [];
export const axiosInstance = axios.create({});

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    const originalRequest = err.config;
    if (err.response.status === 401 && !originalRequest._retry) {

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
            failedQueue.push({
              resolve,
              reject
            });
          })
          .then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        axios.post(`${SERVER_URL}/auth/token/refresh/`, {
            refresh: authService.getRefreshToken()
          })
          .then(({
            data
          }) => {
            authService.saveAccessToken(data.access);
            authService.saveRefreshToken(data.refresh);
            axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.access;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.access;
            processQueue(null, data.access);
            resolve(axios(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            authService.logout();
            window.location.replace(`${BASE_URL}/login`);
            localStorage.setItem("tokenExpiredError", "Token przedawniony - sesja wygasła");
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);

const dataService = {
  get: (url) => axiosInstance.get(transformUrl(url), {
      headers: authService.getAuthHeader()
    })

    .catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        console.log(thrown.message);
      }
      return Promise.reject(thrown);
    }),
  post: (url, data) => axiosInstance.post(url, {
      ...data,
      userId: authService.getDecodedAccessToken().user_id
    }, {
      headers: authService.getAuthHeader()
    })
    .catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        console.log('Sesja wygasła');
      }
      return Promise.reject(thrown);
    }),
  put: (url, data) => axiosInstance.put(url, {
      ...data,
      userId: authService.getDecodedAccessToken().user_id
    }, {
      headers: authService.getAuthHeader()
    })
    .catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        console.log('Sesja wygasła');
      }
      return Promise.reject(thrown);
    }),
  delete: (url) => axiosInstance.delete(transformUrl(url), {
    headers: authService.getAuthHeader()
  }),
  abget: (url) => axiosInstance.get(url, {
      headers: authService.getAuthHeader(),
      responseType: 'arraybuffer'
    })

    .catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        console.log(thrown.message);
        console.log('Sesja wygasła');
      }
      return Promise.reject(thrown);
    })

    .catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        console.log('Sesja wygasła');
      }
      return Promise.reject(thrown);
    })
};


export default dataService;


// import axios from 'axios';
// // import authService from '../auth/authService';

// const dataService = {
//   get: (url) => axios.get(url),
//   post: (url, data) => axios.post(url, data),
//   put: (url, data) => axios.put(url, data),
//   delete: (url) => axios.delete(url)
// };

// export default dataService;