/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import UnitItemDesktop from "./UnitItemVersions/UnitItemDesktop";
import UnitItemMobile from "./UnitItemVersions/UnitItemMobile";

const UnitItem = ({ orderNumber, unit, setRefresh }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <UnitItemDesktop
          orderNumber={orderNumber}
          unit={unit}
          setRefresh={setRefresh}
        />
      ) : (
        <UnitItemMobile
          orderNumber={orderNumber}
          unit={unit}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default UnitItem;
