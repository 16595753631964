/* eslint-disable react/prop-types */
import { Chip } from "@mui/material";
import React from "react";

const SelectedChipDisplayItem = ({ chip, removeChip }) => {
  const { id, name } = chip;

  return (
    <Chip
      label={name}
      variant="outlined"
      onDelete={() => removeChip(id)}
    />
  );
};

export default SelectedChipDisplayItem;
