/* eslint-disable react/prop-types */
import React from "react";
import TaskSummaryItemDesktop from "./TaskSummaryItemVersions/TaskSummaryItemDesktop";
import TaskSummaryItemMobile from "./TaskSummaryItemVersions/TaskSummaryItemMobile";
import useMediaQ from "../../../../hooks/useMediaQ";

const TaskSummaryItem = ({ task, orderNumber, setRefresh, chosenUser }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {!isDesktopDisplay ? (
        <TaskSummaryItemMobile
          task={task}
          setRefresh={setRefresh}
          chosenUser={chosenUser}
        />
      ) : (
        <TaskSummaryItemDesktop
          task={task}
          orderNumber={orderNumber}
          setRefresh={setRefresh}
          chosenUser={chosenUser}
        />
      )}
    </>
  );
};

export default TaskSummaryItem;
