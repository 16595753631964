/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState} from "react";
import { Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material";
import { Article } from "@mui/icons-material";

// const CustomButtonWrapper = styled(Box)(({width}) => ({
//   display: "flex",
//   justifyContent: "center",
//   width: width,
// }));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "rgb(27,150,250)",
  fontSize: "0.75rem",
  height: "1.8125rem",
  [theme.breakpoints.down("desktop")]: {
    fontSize: "0.6rem",
  },
}));

const DetailsButton = ({
  onClick,
  startIcon = <Article />,
  endIcon = <></>,
  text = "szczegóły",
  styles: stylesProps,
  disabled = false,
  clickLock = false
}) => {
  const [wasClicked, setWasClicked] = useState(false);

  const handleClick = () => { 
    onClick();
    if (clickLock) {
      setWasClicked(true);
    }
  };

  return (
    <CustomButton
      onClick={() => handleClick()}
      variant="contained"
      sx={stylesProps}
      size="small"
      startIcon={
        wasClicked ? (
          <CircularProgress sx={{ color: "gray" }} size="1rem" />
        ) : (
          startIcon
        )
      }
      endIcon={endIcon}
      disabled={disabled || wasClicked}
    >
      {text}
    </CustomButton>
  );
};

export default DetailsButton;
