/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Slide } from "@mui/material";
import UserProfile from "../UserProfile/UserProfile";
import Navbar from "../Navbar.jsx/Navbar";
import { IconButton } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { closeMenu } from "../../slices/mediaSlice";
import { useTheme } from "@mui/material/styles";
import MenuHamburger from "../MenuHamburger/MenuHamburger";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";

const MenuDisplayButton = () => {
  const dispatch = useDispatch();
  return (
    <IconButton
      onClick={() => dispatch(closeMenu())}
      sx={{ position: "absolute", top: "1%", right: "1%" }}
    >
      <ArrowBackIos sx={{ color: "rgb(27,150,250)", zIndex: "10" }} />
    </IconButton>
  );
};

const DesktopMenuWrapper = styled(Box)(() => ({
  position: "relative",
  flex: 1.5,
  width: "100%",
  minWidth: '14rem',
  height: "100vh",
  backgroundColor: "#082032",
}));

const DesktopMenu = () => (
  <DesktopMenuWrapper>
    <MenuDisplayButton />
    <UserProfile />
    <Navbar />
  </DesktopMenuWrapper>
);

const MobileMenuWrapper = styled(Box)(() => ({
  zIndex: "2",
  position: "fixed",
  height: "100vh",
  width: "100vw",
  backgroundColor: "#082032",
  overflow: "scroll",
}));

const MobileMenu = () => (
  <MobileMenuWrapper>
    <MenuDisplayButton />
    <UserProfile />
    <Navbar />
  </MobileMenuWrapper>
);

const Menu = () => {
  const { breakpoints } = useTheme();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { isMenuVisible } = useSelector((state) => state.media);
  const isMobileDisplay = useMediaQuery(breakpoints.down("laptop"));

  if (!isMenuVisible && isLoggedIn) return <MenuHamburger />;
  if (isMenuVisible && isLoggedIn)
    return (
      <Slide
        direction={!isMobileDisplay ? "right" : 'down'}
        in={isMenuVisible && isLoggedIn}
        mountOnEnter
        unmountOnExit
      >
        <Box sx={{zIndex: 1000, overflowY: 'auto'}}>{!isMobileDisplay ? <DesktopMenu /> : <MobileMenu />}</Box>
      </Slide>
    );
  return <></>;
};

export default Menu;
