/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUnits,
  getPaginatedUnitsByStorage,
  getUnitsByStorageCount,
} from "../../../slices/unitSlice";
import { useLocation, useNavigate } from "react-router-dom";
import UnitItem from "../UnitItem/UnitItem";
import {
  ArrowUpward,
  Add,
  AccessTime,
  BorderOuter,
  Info,
  ClearAll,
  Assignment,
} from "@mui/icons-material";
import Pagination from "../../Pagination/Pagination";
import {
  DEFAULT_DISPLAY_ITEMS_QUANTITY,
  unitStatusChipStyles,
} from "../../../static/static";
import { getAllStorages, setStorageId } from "../../../slices/storageSlice";
import Loading from "../../Loading/Loading";
import PageHeader from "../../PageHeader/PageHeader";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import ChipSet from "../../ChipSet/ChipSet";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import TableHeader from "../../TableHeader/TableHeader";
import { unitListHeaders } from "../../../static/tablesHeaders";
import EmptyListError from "../../EmptyListError/EmptyListError";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import MultipleChipSelect from "../../MultipleChipSelect/MultipleChipSelect";
import {
  clearCategories,
  getFilteredCategories,
} from "../../../slices/categorySlice";
import {
  clearProducers,
  getFilteredProducers,
} from "../../../slices/producerSlice";
import MobileDataSorter from "../../MobileDataSorter/MobileDataSorter";
import authService from "../../../auth/authService";
import TableWrapper from "../../TableWrapper/TableWrapper";
import usePagination from "../../../hooks/usePagination";

const chipSet = [
  {
    label: "Wszystkie",
    keyValue: "",
  },
  {
    label: "GOOD",
    keyValue: "GOOD",
  },
  {
    label: "BAD",
    keyValue: "BAD",
  },
  {
    label: "DOA",
    keyValue: "DOA",
  },
];

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "1.5rem",
  [theme.breakpoints.down("laptop")]: {
    gap: "0.5rem",
  },
}));

// eslint-disable-next-line react/prop-types
// eslint-disable-next-line no-unused-vars
const UnitsList = ({ chosenStorage, setRefresh }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchingRef = useRef("");
  const { units, unitsCount } = useSelector((state) => state.unit);
  const { chosenStorageId } = useSelector((slice) => slice.storage);
  const { pathname } = useLocation();
  const {
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("partNumber");
  const [filteredValue, setFilteredValue] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProducers, setSelectedProducers] = useState([]);

  const { role } = authService.getDecodedAccessToken();

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getUnitsByStorageCount({
        searchingValue,
        status: filteredValue,
        storageId: chosenStorageId,
        categories: selectedCategories.map((item) => item.name),
        producers: selectedProducers.map((item) => item.name),
      })
    );
    setIsDataLoading(false);
  }, [
    chosenStorageId,
    searchingValue,
    filteredValue,
    pathname,
    selectedCategories,
    selectedProducers,
  ]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedUnitsByStorage({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        status: filteredValue,
        storageId: chosenStorageId,
        categories: selectedCategories.map((item) => item.name),
        producers: selectedProducers.map((item) => item.name),
      })
    ),
      setIsDataLoading(false);
  }, [
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    filteredValue,
    chosenStorageId,
    selectedCategories,
    selectedProducers,
  ]);

  useEffect(() => {
    if (chosenStorageId !== null) {
      loadDataCount();
    }
  }, [loadDataCount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearUnits());
  }, []);

  return (
    <SectionWrapper>
      <PageHeader
        icon={<BorderOuter />}
        titleText="Aktualny stan magazynu"
        size="medium"
        onRightComponent={
          <ButtonWrapper>
            {chosenStorage.type === "main" && (
              <DetailsButton
                text="Dodaj sztuki"
                onClick={() => navigate("/units-add")}
                startIcon={<Add />}
              />
            )}
            <DetailsButton
              text="Historia zapotrzebowań"
              onClick={async () => {
                dispatch(setStorageId(chosenStorage.id));
                await dispatch(getAllStorages());
                navigate("/requests");
              }}
              startIcon={<AccessTime />}
            />
            <DetailsButton
              text="Wyślij produkty"
              onClick={() => {
                localStorage.setItem("chosenStorageId", chosenStorage.id);
                navigate("/units-move");
              }}
              startIcon={<ArrowUpward />}
            />
            {(role === "admin" || role === "warehouseman") && (
              <DetailsButton
                text="Utylizacja"
                onClick={() => {
                  localStorage.setItem("chosenStorageId", chosenStorage.id);
                  navigate("/utilizationReports");
                }}
                startIcon={<ClearAll />}
              />
            )}
            {role === "admin" && (
              <DetailsButton
                text="Raporty magazynowe"
                onClick={() => {
                  navigate("/storageReports");
                }}
                startIcon={<Assignment />}
              />
            )}
          </ButtonWrapper>
        }
      />
      <AdditionalFunctionalitiesWrapper>
        <CustomSearchField
          displaySearchingValue={displaySearchingValue}
          setDisplaySearchingValue={setDisplaySearchingValue}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
          searchingRef={searchingRef}
          placeholder="szukaj produktu..."
          width="20%"
        />
        <ChipSet
          mainLabel="Status:"
          set={chipSet}
          chipStyles={unitStatusChipStyles}
          setFunction={setFilteredValue}
          actualValue={filteredValue}
          styleProps={{ marginLeft: "2em" }}
        />
        <MultipleChipSelect
          name="Kategorie:"
          sliceName="category"
          collectionName="categories"
          getDataFunction={getFilteredCategories}
          cleanDataFunction={clearCategories}
          setFunction={setSelectedCategories}
          settingCollection={selectedCategories}
        />
        <MultipleChipSelect
          name="Producenci:"
          sliceName="producer"
          collectionName="producers"
          getDataFunction={getFilteredProducers}
          cleanDataFunction={clearProducers}
          setFunction={setSelectedProducers}
          settingCollection={selectedProducers}
        />
        <MobileDataSorter
          tableHeader={unitListHeaders}
          setOuterSorter={setSorter}
          setOuterSorting={setSorting}
          defaultSorter="partNumber"
        />
      </AdditionalFunctionalitiesWrapper>
      <TableWrapper>
        <TableHeader
          headers={unitListHeaders}
          setSorter={setSorter}
          setSorting={setSorting}
          gap="1%"
        />
        <Pagination
          collectionSize={unitsCount}
          leftIndex={leftIndex}
          rightIndex={rightIndex}
          setLeftIndex={setLeftIndex}
          setRightIndex={setRightIndex}
        >
          {isDataLoading ? (
            <Loading />
          ) : (
            <>
              {units !== null &&
                units.map((unit, id) => (
                  <UnitItem
                    orderNumber={leftIndex + id + 1}
                    key={id}
                    unit={unit}
                    setRefresh={setRefresh}
                  />
                ))}
            </>
          )}
          {units !== null && units.length === 0 && (
            <EmptyListError message="Brak produktów" icon={<Info />} />
          )}
        </Pagination>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default UnitsList;
