/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { getDocumentation } from "../../../../slices/commissionSlice";
import { getDocumentationFromTask } from "../../../../slices/taskSlice";
import Loading from "../../../Loading/Loading";
import { InsertDriveFile, Photo } from "@mui/icons-material";

const PlayerWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  border: "0.0625rem solid #ccc",
}));

const ImageWrapper = styled(Box)(() => ({
  height: 225,
  width: 300,
}));

const ImageWrapperFullSize = styled(Box)(() => ({
  height: 225,
  width: 300,
}));

const DocumentationsItem = ({
  index,
  documentationId,
  commissionId,
  taskId,
  photosView,
}) => {
  const dispatch = useDispatch();
  const { documentations: commissionDocumentations } = useSelector(
    (state) => state.commission
  );
  const { documentations: taskDocumentations } = useSelector(
    (state) => state.task
  );
  const [isFullSizeView, setIsFullSizeView] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    if (commissionId) await dispatch(getDocumentation({ commissionId, index }));
    else if (taskId)
      await dispatch(getDocumentationFromTask({ taskId, index }));
    setIsDataLoading(false);
  }, [documentationId, commissionId]);

  useEffect(() => getData(), [getData]);

  if (isDataLoading) {
    return <Loading />;
  }

  const onDownloadFromCommission = () => {
    const link = document.createElement("a");
    link.download = `zlecenie_${commissionId}_${
      commissionDocumentations[index].split(";")[1]
    }`;
    link.href = `${commissionDocumentations[index]}`;
    link.click();
  };

  const onDownloadFromTask = () => {
    const link = document.createElement("a");
    link.download = `zlecenie_${commissionId}_${
      taskDocumentations[index].split(";")[1]
    }`;
    link.href = `${taskDocumentations[index]}`;
    link.click();
  };

  return (
    <>
      {commissionId &&
        commissionDocumentations !== null &&
        commissionDocumentations.length > index && (
          <>
            {commissionDocumentations[index]
              .match(/:(.*?);/)[1]
              .includes("video") ? (
              <PlayerWrapper>
                <ReactPlayer
                  url={commissionDocumentations[index]}
                  controls
                  light
                  width="300px"
                  height="215px"
                />
              </PlayerWrapper>
            ) : commissionDocumentations[index]
                .match(/:(.*?);/)[1]
                .includes("image") ? (
              <>
                {photosView ? (
                  <>
                    <ImageWrapper
                      component="img"
                      alt="Zdjęcie dokumentacji"
                      src={commissionDocumentations[index]}
                      onClick={() => setIsFullSizeView(true)}
                    />
                    <Dialog
                      open={isFullSizeView}
                      onClose={() => setIsFullSizeView(false)}
                    >
                      <ImageWrapperFullSize
                        component="img"
                        alt="Zdjęcie dokumentacji"
                        src={commissionDocumentations[index]}
                      />
                    </Dialog>
                  </>
                ) : (
                  <Box onClick={onDownloadFromCommission}>
                    <Tooltip title="pobierz">
                      <IconButton
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          cursor: "pointer",
                          "&:hover": {
                            color: "gray",
                          },
                        }}
                      >
                        <Photo sx={{ height: "3rem", width: "3rem" }} />
                        <Typography sx={{ fontSize: "0.8rem" }}>
                          {`Załącznik ${index + 1}`}
                        </Typography>
                        <Typography sx={{ fontSize: "0.8rem" }}>
                          {`${commissionDocumentations[index].split(";")[1]}`}
                        </Typography>
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </>
            ) : (
              <Box onClick={onDownloadFromCommission}>
                <Tooltip title="pobierz">
                  <IconButton
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      "&:hover": {
                        color: "gray",
                      },
                    }}
                  >
                    <InsertDriveFile sx={{ height: "3rem", width: "3rem" }} />
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {`Załącznik ${index + 1}`}
                    </Typography>
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {`${commissionDocumentations[index].split(";")[1]}`}
                    </Typography>
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </>
        )}
      {taskId && taskDocumentations !== null &&
        taskDocumentations.length > index && (
          <>
            {taskDocumentations[index].match(/:(.*?);/)[1].includes("video") ? (
              <PlayerWrapper>
                <ReactPlayer
                  url={taskDocumentations[index]}
                  controls
                  light
                  width="300px"
                  height="215px"
                />
              </PlayerWrapper>
            ) : taskDocumentations[index]
                .match(/:(.*?);/)[1]
                .includes("image") ? (
              <>
                {photosView ? (
                  <>
                    <ImageWrapper
                      component="img"
                      alt="Zdjęcie dokumentacji"
                      src={taskDocumentations[index]}
                      onClick={() => setIsFullSizeView(true)}
                    />
                    <Dialog
                      open={isFullSizeView}
                      onClose={() => setIsFullSizeView(false)}
                    >
                      <ImageWrapperFullSize
                        component="img"
                        alt="Zdjęcie dokumentacji"
                        src={taskDocumentations[index]}
                      />
                    </Dialog>
                  </>
                ) : (
                  <Box onClick={onDownloadFromTask}>
                    <Tooltip title="pobierz">
                      <IconButton
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          cursor: "pointer",
                          "&:hover": {
                            color: "gray",
                          },
                        }}
                      >
                        <Photo sx={{ height: "3rem", width: "3rem" }} />
                        <Typography sx={{ fontSize: "0.8rem" }}>
                          {`Załącznik ${index + 1}`}
                        </Typography>
                        <Typography sx={{ fontSize: "0.8rem" }}>
                          {`${taskDocumentations[index].split(";")[1]}`}
                        </Typography>
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </>
            ) : (
              <Box onClick={onDownloadFromTask}>
                <Tooltip title="pobierz">
                  <IconButton
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer",
                      "&:hover": {
                        color: "gray",
                      },
                    }}
                  >
                    <InsertDriveFile sx={{ height: "3rem", width: "3rem" }} />
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {`Załącznik ${index + 1}`}
                    </Typography>
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {`${taskDocumentations[index].split(";")[1]}`}
                    </Typography>
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </>
        )}
    </>
  );
};

export default DocumentationsItem;
