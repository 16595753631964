/* eslint-disable react/prop-types */
import React from "react";
import { Box, CircularProgress } from "@mui/material";

const Loading = ({ size = "normal", width = "100%", height = "" }) => {
  return (
    <Box
      sx={
        size === "normal"
          ? {
              position: "relative",
              height: height ? height : "20vh",
              width: width,
            }
          : {
              position: "relative",
              height: height ? height : "100%",
              width: width,
            }
      }
    >
      <CircularProgress
        sx={{
          position: "absolute",
          top: "20%",
          left: "45%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Box>
  );
};

export default Loading;
