/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../../hooks/useMediaQ";
import UserPermissionItemDesktop from "./UserPermissionsItemVersions/UserPermissionItemDesktop";
import UserPermissionItemMobile from "./UserPermissionsItemVersions/UserPermissionItemMobile";

const UserPermissionsItem = ({ orderNumber, permission }) => {
  const { isDesktopDisplay } = useMediaQ();
  return (
    <>
      {isDesktopDisplay ? (
        <UserPermissionItemDesktop
          orderNumber={orderNumber}
          permission={permission}
        />
      ) : (
        <UserPermissionItemMobile
          orderNumber={orderNumber}
          permission={permission}
        />
      )}
    </>
  );
};

export default UserPermissionsItem;
