/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";
import { TextField } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";

const CustomDateInput = forwardRef(({ value, onClick }, ref) => {
  return (
    <TextField
      onClick={onClick}
      ref={ref}
      value={value}
      placeholder="wybierz datę..."
      size="small"
      InputProps={{
        startAdornment: <CalendarMonth sx={{ marginRight: "0.3rem" }} />,
      }}
      fullWidth
    />
  );
});

CustomDateInput.displayName = "CustomDateInput";

export default CustomDateInput;
