/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomBox = styled(Box)(({ theme }) => ({
  height: "1rem",
  backgroundColor: theme.palette.pageBackgroundColor.main,
}));

const BlankSpace = ({ styleProps }) => {
  return <CustomBox sx={styleProps} />;
};

export default BlankSpace;
