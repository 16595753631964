import React, { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearUser, getUser } from "../../slices/userSlice";
import UserForm from "../UserForm/UserForm";
import { KeyboardArrowLeft, Person } from "@mui/icons-material";
import UserNotificationsPanel from "./UserNotificationsPanel/UserNotificationsPanel";
import { Navigate } from "react-router-dom";
import UserPermissionsPanel from "./UserPermissionsPanel/UserPermissionsPanel";
import PageHeader from "../PageHeader/PageHeader";
import RefreshButton from "../RefreshButton/RefreshButton";
import BlankSpace from "../BlankSpace/BlankSpace";
import UserDetails from "./UserDetails/UserDetails";
import Loading from "../Loading/Loading";
import DetailsButton from "../DetailsButton/DetailsButton";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../PageWrapper/PageWrapper";

const ConfigureUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { chosenUser } = useSelector((slice) => slice.user);
  const {
    userData: { user_id, role },
  } = useSelector((slice) => slice.auth);
  const [refresh, setRefresh] = useState(false);
  const [isEditUserModalOpened, setIsEditUserModalOpened] = useState(false);
  const [
    isNotificationConfigurationOpened,
    setIsNotificationConfigurationOpened,
  ] = useState(false);
  const [isPermissionConfigurationOpened, setIsPermissionConfigurationOpened] =
    useState(false);

  useEffect(async () => {
    await dispatch(getUser(userId));
  }, [userId, refresh]);

  useEffect(() => {
    return dispatch(clearUser());
  }, []);

  if (role !== "admin" && userId != user_id) return <Navigate to={-1} />;

  return (
    <PageWrapper width="90%">
      {chosenUser !== null ? (
        <>
          <PageHeader
            titleText={`Użytkownik ${chosenUser.username}`}
            icon={<Person />}
            onRightComponent={
              <DetailsButton
                text="Powrót"
                onClick={() => navigate("/users-management")}
                startIcon={<KeyboardArrowLeft />}
              />
            }
          >
            <RefreshButton />
          </PageHeader>
          <BlankSpace />
          <UserDetails
            chosenUser={chosenUser}
            setIsEditUserModalOpened={setIsEditUserModalOpened}
            isNotificationConfigurationOpened={
              isNotificationConfigurationOpened
            }
            isPermissionConfigurationOpened={isPermissionConfigurationOpened}
          />
          <BlankSpace />
          <UserNotificationsPanel
            userId={userId}
            userNotifications={chosenUser.notification}
            setIsNotificationConfigurationOpened={
              setIsNotificationConfigurationOpened
            }
            isNotificationConfigurationOpened={
              isNotificationConfigurationOpened
            }
            setRefresh={setRefresh}
          />

          {role === "admin" && (
            <>
              <BlankSpace />
              <UserPermissionsPanel
                userId={userId}
                userPermissions={chosenUser.permissions}
                refresh={refresh}
                setRefresh={() => setRefresh((state) => !state)}
                isPermissionConfigurationOpened={
                  isPermissionConfigurationOpened
                }
                setIsPermissionConfigurationOpened={
                  setIsPermissionConfigurationOpened
                }
              />
            </>
          )}
          <Dialog
            open={isEditUserModalOpened}
            onClose={() => setIsEditUserModalOpened(false)}
          >
            <UserForm
              type="edit"
              userId={userId}
              closeModal={() => setIsEditUserModalOpened(false)}
              setRefresh={() => setRefresh((state) => !state)}
            />
          </Dialog>
        </>
      ) : (
        <Loading />
      )}
    </PageWrapper>
  );
};

export default ConfigureUser;
