/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { Article } from "@mui/icons-material";
import SectionWrapper from "../SectionWrapper/SectionWrapper";
import PageHeader from "../PageHeader/PageHeader";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
} from "../ObjectDetailsElements/ObjectDetailsElements";
import GeoLocationIcon from "../GeoLocation/GeoLocationIcon";

const StorageDetails = ({ type, chosenStorage }) => {

  return (
    <SectionWrapper>
      <PageHeader icon={<Article />} titleText="Dane" size="medium" />
      <InfoWrapper>
        <DataBox>
          <DataTitle>Nazwa:</DataTitle>
          <Typography>{chosenStorage.name}</Typography>
        </DataBox>
        {type === "local" && (
          <DataBox>
            <DataTitle>Przypisany pracownik:</DataTitle>
            <Typography>
              {`
                  ${chosenStorage.user.name} 
                  ${chosenStorage.user.surname}
                  `}
            </Typography>
          </DataBox>
        )}
        <DataBox>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DataTitle>Adres:</DataTitle>
            <GeoLocationIcon
              query={`${chosenStorage.address.street}, ${chosenStorage.address.city}, ${chosenStorage.address.postcode}, ${chosenStorage.address.country}`}
            />
          </Box>
          <Typography>
            {`ul. ${chosenStorage.address.street}, ${chosenStorage.address.city}, ${chosenStorage.address.postcode}, ${chosenStorage.address.country}`}
          </Typography>
        </DataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default StorageDetails;
