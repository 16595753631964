/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box } from "@mui/material";
import AvailableUnits from "./AvailableUnits/AvailableUnits";
import BlankSpace from "../BlankSpace/BlankSpace";
import MountedUnits from "./MountedUnits/MountedUnits";
import DOAUnits from "./DOAUnits/DOAUnits";
import UnmountedUnits from "./UnmountedUnits/UnmountedUnits";

const UseUnits = ({
  mountedProducts,
  setMountedProducts,
  fatalProducts,
  setFatalProducts,
  unmountedProducts,
  setUnmountedProducts,
  removeItem,
  removeDOAItem,
  removeBADItem,
  chosenStorageId,
}) => {
  const [isAddingUnitModalVisible, setIsAddingUnitModalVisible] =
    useState(false);

  const [sorter, setSorter] = useState({
    new: "",
    mounted: "",
    doa: "",
    bad: "",
  });
  const [sorting, setSorting] = useState({
    new: "",
    mounted: "",
    doa: "",
    bad: "",
  });

  const sort = (a, b, type) => {
    let value = 0;
    if (!isNaN(a[sorter[type]]) && !isNaN(b[sorter[type]])) {
      value = a[sorter[type]] - b[sorter[type]];
    } else {
      value = a[sorter[type]]
        .toString()
        .localeCompare(b[sorter[type]].toString());
    }
    if (sorting[type] === "descending") value *= -1;
    return value;
  };

  return (
    <Box>
      <AvailableUnits
        mountedProducts={mountedProducts}
        setMountedProducts={setMountedProducts}
        fatalProducts={fatalProducts}
        setFatalProducts={setFatalProducts}
        chosenStorageId={chosenStorageId}
      />
      <BlankSpace />
      <MountedUnits
        setSorter={setSorter}
        setSorting={setSorting}
        mountedProducts={mountedProducts}
        sort={sort}
        sorter={sorter}
        removeItem={removeItem}
      />
      <BlankSpace />
      <DOAUnits
        setSorter={setSorter}
        setSorting={setSorting}
        fatalProducts={fatalProducts}
        sort={sort}
        sorter={sorter}
        removeDOAItem={removeDOAItem}
      />
      <BlankSpace />
      <UnmountedUnits
        setSorter={setSorter}
        setSorting={setSorting}
        setIsAddingUnitModalVisible={setIsAddingUnitModalVisible}
        isAddingUnitModalVisible={isAddingUnitModalVisible}
        sort={sort}
        sorter={sorter}
        unmountedProducts={unmountedProducts}
        setUnmountedProducts={setUnmountedProducts}
        removeBADItem={removeBADItem}
      />
    </Box>
  );
};

export default UseUnits;
