/* eslint-disable react/prop-types */
import React from "react";
import CustomChip from "../../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../../static/static";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";
import DeleteButton from "../../../DeleteButton/DeleteButton";

const MoveUnitFieldMobile = ({ orderNumber, removeItem, unit, index }) => {
  const {
    partNumber,
    status,
    name,
    serialNumber,
    chosenQuantity,
    removeItemInner,
  } = unit;

  const data = [
    { title: "Part Number:", value: partNumber },
    { title: "Nazwa:", value: name },
    { title: "Nr seryjny:", value: serialNumber },
    {
      title: "Status:",
      value: (
        <CustomChip label={status} sx={unitStatusChipStyles(status, "")} />
      ),
    },
    {
      title: "Wybrana liczba:",
      value: chosenQuantity,
    },
  ];
  return (
    <CompactDataDisplay
      title={`Produkt #${orderNumber}`}
      data={data}
      actions={
        <>
          <DeleteButton
            text="usuń"
            onClick={() => {
              removeItem(index);
              removeItemInner();
            }}
          />
        </>
      }
    />
  );
};

export default MoveUnitFieldMobile;
