/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearTasks,
  getPaginatedTasks,
  getTasksCount,
} from "../../../../slices/taskSlice";
import TaskSummaryItem from "./TaskSummaryItem";
import Pagination from "../../../Pagination/Pagination";
import Loading from "../../../Loading/Loading";
import { Task, Warning } from "@mui/icons-material";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../../../../static/static";
import CustomSearchField from "../../../CustomSearchField/CustomSearchField";
import TableHeader from "../../../TableHeader/TableHeader";
import { tasksSummaryHeaders } from "../../../../static/tablesHeaders";
import EmptyListError from "../../../EmptyListError/EmptyListError";
import DateRangeSelector from "../../../DateRangeSelector/DateRangeSelector";
import { getLocalTimeDate } from "../../../../static/functions";
import RefreshButton from "../../../RefreshButton/RefreshButton";
import PageHeader from "../../../PageHeader/PageHeader";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import MobileDataSorter from "../../../MobileDataSorter/MobileDataSorter";
import { clearUser, getUser } from "../../../../slices/userSlice";
import TableWrapper from "../../../TableWrapper/TableWrapper";
import usePagination from "../../../../hooks/usePagination";
import authService from "../../../../auth/authService";

const TasksSummary = () => {
  const dispatch = useDispatch();
  const searchingRef = useRef("");
  const { tasks, tasksCount } = useSelector((slice) => slice.task);
  const { user_id: userId } = authService.getDecodedAccessToken();
  const { chosenUser } = useSelector((state) => state.user);
  const {
    refresh,
    setRefresh,
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("creationDate", "descending");
  const [filteredValue, setFilteredValue] = useState([
    "Created",
    "In progress",
  ]);
  const [createdDates, setCreationDates] = useState({ start: "", end: "" });
  const [planningExecutionDates, setPlanningExecutionDates] = useState({
    start: "",
    end: "",
  });

  const setCreationDateInterval = (dates) => {
    const [startDate, endDate] = dates;
    setCreationDates({
      start: startDate,
      end: endDate,
    });
  };

  const setPlanningExecutionDateInterval = (dates) => {
    const [startDate, endDate] = dates;
    setPlanningExecutionDates({
      start: startDate,
      end: endDate,
    });
  };

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getTasksCount({
        searchingValue,
        type: "",
        status: filteredValue,
        parentTask: "",
        commission: "",
        assignedServiceTechnician: "",
        proposedServiceTechnician: "",
        creationDate: "",
        executionDate: "",
        executor: "",
        finishDateStart: "",
        finishDateEnd: "",
        creationDateStart: createdDates.start
          ? getLocalTimeDate(createdDates.start)
          : "",
        creationDateEnd: createdDates.end
          ? getLocalTimeDate(createdDates.end)
          : "",
        planningExecutionDateStart: planningExecutionDates.start
          ? getLocalTimeDate(planningExecutionDates.start)
          : "",
        planningExecutionDateEnd: planningExecutionDates.end
          ? getLocalTimeDate(planningExecutionDates.end)
          : "",
      })
    );
    setIsDataLoading(false);
  }, [
    refresh,
    searchingValue,
    filteredValue,
    createdDates.start,
    createdDates.end,
    planningExecutionDates.start,
    planningExecutionDates.end,
  ]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getUser(userId));
    await dispatch(
      getPaginatedTasks({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        type: "",
        status: filteredValue,
        parentTask: "",
        commission: "",
        assignedServiceTechnician: "",
        proposedServiceTechnician: "",
        executor: "",
        creationDate: "",
        executionDate: "",
        finishDateStart: "",
        finishDateEnd: "",
        creationDateStart: createdDates.start
          ? getLocalTimeDate(createdDates.start)
          : "",
        creationDateEnd: createdDates.end
          ? getLocalTimeDate(createdDates.end)
          : "",
        planningExecutionDateStart: planningExecutionDates.start
          ? getLocalTimeDate(planningExecutionDates.start)
          : "",
        planningExecutionDateEnd: planningExecutionDates.end
          ? getLocalTimeDate(planningExecutionDates.end)
          : "",
      })
    );
    setIsDataLoading(false);
  }, [
    refresh,
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    filteredValue,
    createdDates.start,
    createdDates.end,
    planningExecutionDates.start,
    planningExecutionDates.end,
  ]);

  useEffect(() => { loadDataCount(); }, [loadDataCount]);
  useEffect(() => { loadData(); }, [loadData]);
  useEffect(() => {
    return () => {
      dispatch(clearUser());
      dispatch(clearTasks());
    };
  }, []);

  return (
    <SectionWrapper>
      <PageHeader
        titleText={
          <>
            <span>Zadania do zrobienia</span>
            <span style={{ marginLeft: "0.3rem" }}>{`[`}</span>
            <span style={{ color: tasksCount > 0 ? "red" : "green" }}>
              {`${tasksCount}`}
            </span>
            <span>{`]`}</span>
          </>
        }
        icon={<Task />}
        size="medium"
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <AdditionalFunctionalitiesWrapper>
        <CustomSearchField
          displaySearchingValue={displaySearchingValue}
          setDisplaySearchingValue={setDisplaySearchingValue}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
          searchingRef={searchingRef}
          placeholder="szukaj zadania..."
          width="20%"
        />
        <DateRangeSelector
          labelName="Data utworzenia: "
          datesRange={createdDates}
          setDatesRange={(dates) => setCreationDateInterval(dates)}
          maxDate={new Date()}
        />
        <DateRangeSelector
          labelName="Planowana data realizacji: "
          datesRange={planningExecutionDates}
          setDatesRange={(dates) => setPlanningExecutionDateInterval(dates)}
          maxDate={new Date()}
        />
        <MobileDataSorter
          tableHeader={tasksSummaryHeaders}
          setOuterSorter={setSorter}
          setOuterSorting={setSorting}
          defaultSorter="creationDate"
        />
      </AdditionalFunctionalitiesWrapper>
      <TableWrapper>
        <TableHeader
          headers={tasksSummaryHeaders}
          setSorter={setSorter}
          setSorting={setSorting}
          gap="1%"
        />
        <Pagination
          collectionSize={tasksCount}
          leftIndex={leftIndex}
          rightIndex={rightIndex}
          setLeftIndex={setLeftIndex}
          setRightIndex={setRightIndex}
        >
          {isDataLoading ? (
            <Loading />
          ) : (
            <>
              {chosenUser &&
                tasks !== null &&
                tasks.map((task, id) => (
                  <TaskSummaryItem
                    key={id}
                    task={task}
                    orderNumber={leftIndex + id + 1}
                    chosenUser={chosenUser}
                    setRefresh={setRefresh}
                  />
                ))}
            </>
          )}
          {tasks !== null && tasks.length === 0 && (
            <EmptyListError
              message="Brak niewykonanych tasków"
              icon={<Warning />}
            />
          )}
        </Pagination>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default TasksSummary;
