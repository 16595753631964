/* eslint-disable react/prop-types */
import React from "react";
import SectionWrapper from "../SectionWrapper/SectionWrapper";
import PageHeader from "../PageHeader/PageHeader";
import { Article } from "@mui/icons-material";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
} from "../ObjectDetailsElements/ObjectDetailsElements";
import { Typography } from "@mui/material";
import { dateFormatView } from "../../static/functions";
import { storageReportOperationTypeIcons } from "../../static/static";

const StorageReportInfo = ({ storageReport }) => {
  const {
    creationDate,
    operationType,
    user,
    baseStorage,
    destinationStorage,
    shipment,
    utilizationReport,
    task,
  } = storageReport;

  console.log("Raport magazynowy: ", storageReport);

  return (
    <SectionWrapper>
      <PageHeader icon={<Article />} titleText="Dane" size="medium" />
      <InfoWrapper>
        <DataBox>
          <DataTitle>Data utworzenia:</DataTitle>
          <Typography>{dateFormatView(creationDate)}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Typ operacji:</DataTitle>
          <Typography>
            {storageReportOperationTypeIcons[operationType]}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Wykonawca:</DataTitle>
          <Typography>{user ? `${user.name} ${user.surname}` : ""}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Magazyn bazowy:</DataTitle>
          <Typography>{baseStorage ? `${baseStorage.name}` : ""}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Magazyn docelowy:</DataTitle>
          <Typography>
            {destinationStorage ? `${destinationStorage.name}` : ""}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Przesyłka:</DataTitle>
          <Typography>{shipment ? `${shipment.id}` : ""}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Raport utylizacyjny:</DataTitle>
          <Typography>
            {utilizationReport ? `${utilizationReport.id}` : ""}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Zadanie:</DataTitle>
          <Typography>{task ? `${task.id}` : ""}</Typography>
        </DataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default StorageReportInfo;
