import React from 'react';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Menu } from '@mui/icons-material';
import { openMenu } from '../../slices/mediaSlice';

const MenuHamburger = () => {
  const dispatch = useDispatch();
  return (
    <IconButton
      onClick={() => dispatch(openMenu())}
      sx={{
        position: "absolute",
        zIndex: "1",
        top: "1%",
        left: "0.5%",
        
      }}
    >
      <Menu />
    </IconButton>
  );
};

export default MenuHamburger;