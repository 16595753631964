/* eslint-disable react/prop-types */
import React from "react";
import TableDataGridWrapper from "../../../../TableGridWrapper/TableDataGridWrapper";
import { requestSummaryViewHeaders } from "../../../../../static/tablesHeaders";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { requestStatus, requestStatusChipStyles } from "../../../../../static/static";
import DetailsButton from "../../../../DetailsButton/DetailsButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dateFormatView } from "../../../../../static/functions";
import { setChosenRequestId, setRequestSeen } from "../../../../../slices/requestSlice";
import CustomChip from "../../../../CustomChip/CustomChip";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const RequestSummaryItemDesktop = ({ orderNumber, request }) => {
  const {
    id,
    creationDate,
    topic,
  baseStorage: { name: storageName },
    sender: { name: senderName, surname: senderSurname },
    status,
  } = request;
  const {
    userData: { role },
  } = useSelector((slice) => slice.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <TableDataGridWrapper headers={requestSummaryViewHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{dateFormatView(creationDate)}</CustomDataDisplay>
      <CustomDataDisplay>{topic}</CustomDataDisplay>
      <CustomDataDisplay>{storageName}</CustomDataDisplay>
      <CustomDataDisplay>{`${senderName} ${senderSurname}`}</CustomDataDisplay>
      <CustomChip
        label={requestStatus[status]}
        sx={requestStatusChipStyles(status, "")}
      />
      <DetailsButton
        onClick={async () => {
          if (role === "service_technician" && status !== "sent")
            await dispatch(setRequestSeen({ requestId: id }));
          dispatch(setChosenRequestId(id));
          navigate(`/requests/${id}`);
        }}
        width="80%"
      />
    </TableDataGridWrapper>
  );
};

export default RequestSummaryItemDesktop;
