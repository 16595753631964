/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import {
  clearUsers,
  getPaginatedUsers,
  getUsersCount,
} from "../../../slices/userSlice";
import UserManagementItem from "../UserListItem/UsersListItem";
import Pagination from "../../Pagination/Pagination";
import { userRolesChipStyles } from "../../../static/static";
import { PeopleOutlineOutlined, PersonAdd, Warning } from "@mui/icons-material";
import UserForm from "../../UserForm/UserForm";
import Loading from "../../Loading/Loading";
import PageHeader from "../../PageHeader/PageHeader";
import RefreshButton from "../../RefreshButton/RefreshButton";
import BlankSpace from "../../BlankSpace/BlankSpace";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import ChipSet from "../../ChipSet/ChipSet";
import TableHeader from "../../TableHeader/TableHeader";
import { usersListHeadersV1 } from "../../../static/tablesHeaders";
import EmptyListError from "../../EmptyListError/EmptyListError";
import PageWrapper from "../../PageWrapper/PageWrapper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import MobileDataSorter from "../../MobileDataSorter/MobileDataSorter";
import usePagination from "../../../hooks/usePagination";
import ButtonWrapper from "../../ButtonWrapper/ButtonWrapper";
import ReturnButton from "../../ReturnButton/ReturnButton";

const chipSet = [
  {
    label: "Wszystkie",
    keyValue: "",
  },
  {
    label: "Administrator",
    keyValue: "admin",
  },
  {
    label: "Magazynier",
    keyValue: "warehouseman",
  },
  {
    label: "Serwisant",
    keyValue: "service_technician",
  },
  {
    label: "Brak uprawnień",
    keyValue: "no_role",
  },
];

const UsersList = () => {
  const dispatch = useDispatch();
  const searchingRef = useRef("");
  const { users, usersCount } = useSelector((slice) => slice.user);
  const [filteredValue, setFilteredValue] = useState("");
  const {
    refresh,
    setRefresh,
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("id", "ascending");
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [currentHeadersSetup, setCurrentHeadersSetup] =
    useState(usersListHeadersV1);

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getUsersCount({
        searchingValue,
        role: filteredValue,
      })
    );
    setIsDataLoading(false);
  }, [refresh, searchingValue, filteredValue]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedUsers({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        role: filteredValue,
      })
    );
    setIsDataLoading(false);
  }, [
    refresh,
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    filteredValue,
  ]);

  useEffect(() => {
    loadDataCount();
  }, [loadDataCount]);
  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearUsers());
  }, []);

  return (
    <>
      <PageWrapper width="80%">
        <PageHeader
          titleText="Lista użytkowników"
          icon={<PeopleOutlineOutlined />}
          onRightComponent={
            <ButtonWrapper>
              <DetailsButton
                text="Dodaj użytkownika"
                startIcon={<PersonAdd />}
                onClick={() => setIsAddUserModalOpen(true)}
              />
              <ReturnButton />
            </ButtonWrapper>
          }
        >
          <RefreshButton onClick={() => setRefresh((state) => !state)} />
        </PageHeader>
        <BlankSpace />
        <SectionWrapper>
          <AdditionalFunctionalitiesWrapper>
            <CustomSearchField
              displaySearchingValue={displaySearchingValue}
              setDisplaySearchingValue={setDisplaySearchingValue}
              searchingValue={searchingValue}
              setSearchingValue={setSearchingValue}
              searchingRef={searchingRef}
              placeholder="szukaj użytkownika..."
              width="20%"
            />
            <ChipSet
              mainLabel="Role:"
              set={chipSet}
              chipStyles={userRolesChipStyles}
              setFunction={setFilteredValue}
              actualValue={filteredValue}
            />
            <MobileDataSorter
              tableHeader={currentHeadersSetup}
              setOuterSorter={setSorter}
              setOuterSorting={setSorting}
              defaultSorter="id"
            />
          </AdditionalFunctionalitiesWrapper>
          <TableHeader
            headers={currentHeadersSetup}
            setSorter={setSorter}
            setSorting={setSorting}
            gap="1%"
          />
          <Pagination
            collectionSize={usersCount}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            setLeftIndex={setLeftIndex}
            setRightIndex={setRightIndex}
          >
            {isDataLoading ? (
              <Loading />
            ) : (
              <>
                {users !== null &&
                  users.map((user, id) => (
                    <UserManagementItem
                      orderNumber={leftIndex + id + 1}
                      key={user.id}
                      user={user}
                      setRefresh={setRefresh}
                      currentHeadersSetup={currentHeadersSetup}
                      setCurrentHeadersSetup={setCurrentHeadersSetup}
                    />
                  ))}
              </>
            )}
            {users !== null && users.length === 0 && (
              <EmptyListError message="Brak użytkowników" icon={<Warning />} />
            )}
          </Pagination>
        </SectionWrapper>
      </PageWrapper>
      <Dialog
        open={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
      >
        <UserForm
          closeModal={() => setIsAddUserModalOpen(false)}
          setRefresh={() => setRefresh((state) => !state)}
        />
      </Dialog>
    </>
  );
};

export default UsersList;
