/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import CompanyItemDesktop from "./CompanyItemVersions/CompanyItemDesktop";
import CompanyItemMobile from "./CompanyItemVersions/CompanyItemMobile";

const CompanyItem = ({ orderNumber, company }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <CompanyItemDesktop company={company} orderNumber={orderNumber} />
      ) : (
        <CompanyItemMobile company={company} orderNumber={orderNumber} />
      )}
    </>
  );
};

export default CompanyItem;
