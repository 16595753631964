/* eslint-disable react/prop-types */
import React from "react";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { workModalAvailableUnitsHeaders } from "../../../../static/tablesHeaders";
import { Box, TextField, Typography } from "@mui/material";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import { ArrowDownward, CancelPresentation } from "@mui/icons-material";
import CustomChip from "../../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../../static/static";

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "0.1rem 0.8rem",
  justifyContent: "center",
}));

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const AvailableUnitsItemDesktop = ({
  orderNumber,
  unit,
  currentQuantity,
  chosenQuantity,
  isAdded,
  changeAddQuantity,
  changeDOAQuantity,
  addItem,
  returnQuantity,
  addDOAItem,
  addButtonLock,
  DOAButtonLock,
  isDOAAdded,
}) => {
  const {
    product: { partNumber, name, producer, category },
    serialNumber,
    status,
  } = unit;

  return (
    <TableDataGridWrapper headers={workModalAvailableUnitsHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{partNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <CustomDataDisplay>
        {category ? category.name : category}
      </CustomDataDisplay>
      <CustomDataDisplay>{producer ? producer.name : ""}</CustomDataDisplay>
      <CustomDataDisplay>{serialNumber}</CustomDataDisplay>
      <CustomChip label={status} sx={unitStatusChipStyles(status, "")} />
      <CustomDataDisplay>{currentQuantity}</CustomDataDisplay>
      <TextField
        value={chosenQuantity}
        type="number"
        InputProps={{
          inputProps: { min: 0, max: currentQuantity - returnQuantity },
        }}
        onChange={(e) => changeAddQuantity(e.target.value)}
        disabled={isAdded}
        size="small"
        variant="standard"
      />
      <TextField
        value={returnQuantity}
        type="number"
        InputProps={{
          inputProps: { min: 0, max: currentQuantity - chosenQuantity },
        }}
        onChange={(e) => changeDOAQuantity(e.target.value)}
        disabled={isDOAAdded}
        size="small"
        variant="standard"
      />
      <ButtonWrapper>
        <DetailsButton
          disabled={addButtonLock || isAdded}
          onClick={() => addItem()}
          text="montuj"
          startIcon={<ArrowDownward />}
          width="60%"
        />
        <DetailsButton
          disabled={DOAButtonLock || isDOAAdded}
          onClick={() => addDOAItem()}
          text="DOA"
          startIcon={<CancelPresentation />}
          width="60%"
        />
      </ButtonWrapper>
    </TableDataGridWrapper>
  );
};

export default AvailableUnitsItemDesktop;
