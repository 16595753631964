/* eslint-disable react/prop-types */
import React from 'react';
import TableDataGridWrapper from '../../../TableGridWrapper/TableDataGridWrapper';
import { AvailableUnitsHeaders } from '../../../../static/tablesHeaders';
import { styled } from "@mui/material/styles";
import { Typography } from '@mui/material';
import DetailsButton from '../../../DetailsButton/DetailsButton';
import { Add } from '@mui/icons-material';

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const AddUnitItemDesktop = ({ product, addItem, orderNumber }) => {
  const { partNumber, name, category, producer, actualQuantity } = product;

  return (
    <TableDataGridWrapper headers={AvailableUnitsHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{partNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <CustomDataDisplay>{category ? category.name : ""}</CustomDataDisplay>
      <CustomDataDisplay>{producer ? producer.name : ""}</CustomDataDisplay>
      <CustomDataDisplay
        sx={actualQuantity > 0 ? { color: "#0e9c34" } : { color: "red" }}
      >
        {actualQuantity}
      </CustomDataDisplay>
      <DetailsButton
        text="Dodaj"
        startIcon={<Add />}
        onClick={() => addItem()}
      />
    </TableDataGridWrapper>
  );
};

export default AddUnitItemDesktop;