/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Close, ContentCopy } from "@mui/icons-material";
// eslint-disable-next-line no-unused-vars
import { Box, IconButton, Modal, Popover, Tooltip, Typography } from "@mui/material";
import GeoLocation from "./GeoLocation";
import useMediaQ from "../../hooks/useMediaQ";

const GeoLocationIcon = ({ query }) => {
  const [isMapShowed, setIsMapShowed] = useState(false);
  const [isCopyInfoShowed, setIsCopyInfoShowed] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isPhoneDisplay } = useMediaQ();
  let timeoutId = null;

  const copyContent = (event) => {
    navigator.clipboard.writeText(query);
    setIsCopyInfoShowed(true);
    setAnchorEl(event.currentTarget);
    timeoutId = setTimeout(() => setIsCopyInfoShowed(false), 2000);
  };

  useEffect(() => { return () => clearTimeout(timeoutId);}, []);

  return (
    <>
      <Box sx={{ display: "flex", gap: "0.1rem" }}>
        <Tooltip title="Pokaż lokalizacje na mapie">
          <IconButton onClick={() => setIsMapShowed(true)}>
            <Typography>(MAPA)</Typography>
          </IconButton>
        </Tooltip>

        <Tooltip title="Kopiuj do schowka">
          <IconButton onClick={(e) => copyContent(e)}>
            <ContentCopy sx={{ fontSize: "1.25rem" }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Modal open={isMapShowed} onClose={() => setIsMapShowed(false)}>
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: isPhoneDisplay ? "100vw" : "50%",
              height: isPhoneDisplay ? "100vh" : "50vh",
              position: "relative",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                zIndex: 999999,
                color: "black",
                top: "1%",
                right: "1%",
              }}
              onClick={() => setIsMapShowed(false)}
            >
              <Close />
            </IconButton>
            <GeoLocation query={query} />
          </Box>
        </Box>
      </Modal>
      <Popover
        id={0}
        open={isCopyInfoShowed}
        anchorEl={anchorEl}
        onClose={() => setIsCopyInfoShowed(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography>Skopiowano do schowka</Typography>
      </Popover>
    </>
  );
};

export default GeoLocationIcon;
