import React, { useState, useEffect, useCallback } from "react";
import PageWrapper from "../PageWrapper/PageWrapper";
import PageHeader from "../PageHeader/PageHeader";
import { Add, ClearAll, KeyboardArrowLeft } from "@mui/icons-material";
import {
  DetailsButton,
  ReturnButton,
} from "../FunctionalButtons/FunctionalButtons";
import { useNavigate } from "react-router-dom";
import AvailableProducts from "../MoveUnits/AvailableProducts/AvailableProducts";
import BlankSpace from "../BlankSpace/BlankSpace";
import { useDispatch, useSelector } from "react-redux";
import { getStorage } from "../../slices/storageSlice";
import AddedUnits from "../MoveUnits/AddedUnits/AddedUnits";
// eslint-disable-next-line no-unused-vars
import { createUtilizationReport } from "../../slices/utilizationReportSlice";
import authService from "../../auth/authService";

const UtilizationReportForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState([]);
  const { chosenStorage } = useSelector((slice) => slice.storage);
  // const [sorterField, setSorterField] = useState("");
  // const [sortingField, setSortingField] = useState("");

  const getStorageData = useCallback(async () => {
    await dispatch(getStorage());
  }, []);

  useEffect(() => {
    getStorageData();
  }, []);

  // const sortField = (a, b) => {
  //   let value = 0;
  //   if (!isNaN(a[sorterField]) && !isNaN(b[sorterField])) {
  //     value = a[sorterField] - b[sorterField];
  //   } else {
  //     value = a[sorterField]
  //       .toString()
  //       .localeCompare(b[sorterField].toString());
  //   }
  //   if (sortingField === "descending") value *= -1;
  //   return value;
  // };

  const formSubmit = async () => {
    if (formValues.length > 0) {
      const data = {
        units: formValues,
        storage: chosenStorage.id,
        createdBy: authService.getDecodedAccessToken().user_id,
      };
      await dispatch(createUtilizationReport(data));
      navigate(-1);
    }
  };

  const removeItem = (index) => {
    const arrayCopy = [...formValues];
    const findingIndex = arrayCopy.findIndex((unit) => unit.index === index);
    arrayCopy.splice(findingIndex, 1);
    setFormValues(arrayCopy);
  };

  return (
    <PageWrapper width="80%">
      <PageHeader
        icon={<ClearAll />}
        titleText="Tworzenie raportu"
        onRightComponent={
          <ReturnButton
            startIcon={<KeyboardArrowLeft />}
            text="Powrót"
            onClick={() => navigate(-1)}
          />
        }
      >
        <DetailsButton
          text="Utwórz"
          onClick={() => formSubmit()}
          disabled={formValues.length === 0}
          startIcon={<Add />}
        />
      </PageHeader>
      <AvailableProducts
        chosenStorage={chosenStorage}
        setFormValues={setFormValues}
        status={["BAD", "DOA"]}
      />
      <BlankSpace />
      <AddedUnits
        formValues={formValues}
        removeItem={removeItem}
        titleText="Produkty do utylizacji"
      />
    </PageWrapper>
  );
};

export default UtilizationReportForm;
