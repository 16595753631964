import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  getErrorCode
} from '../static/functions';
import {
  API
} from '../static/static';


export const getCompany = createAsyncThunk('company/get', async (companyId, {
  getState
}) => {
  const {
    chosenCompanyId
  } = getState().company;
  const finalCompanyId = companyId !== undefined ? companyId : chosenCompanyId !== null ? chosenCompanyId : localStorage.getItem('chosenCompanyId');
  const resData = await dataService.get(`${API}company/${finalCompanyId}`);
  return resData;
});

export const createCompany = createAsyncThunk('company/add', async (data) => {
  const resData = await dataService.post(`${API}company-add/`, data);
  return resData;
});

export const updateCompany = createAsyncThunk('company/add', async (data, {
  getState
}) => {
  const {
    chosenCompanyId
  } = getState().company;
  const resData = chosenCompanyId ? await dataService.put(`${API}company/u/${chosenCompanyId}`, data) : await dataService.put(`${API}company/u/${localStorage.getItem('chosenCompanyId')}/`, data);
  return resData;
});

export const getPaginatedCompanies = createAsyncThunk('companies/get/pagination', async (data) => {
  const {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    createdBy,
    assignedUser
  } = data;
  const resData = await dataService.get(`${API}companies/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&createdBy=${createdBy}&assignedUser=${assignedUser}`);
  return resData;
});

export const getFilteredCompanies = createAsyncThunk('companies/get/filter', async (searchingValue) => {
  const resData = await dataService.get(`${API}companies/filter/?searchingPhrase=${searchingValue}`);
  return resData;
});

export const getCompaniesCount = createAsyncThunk('companies/get/count', async (data) => {
  const {
    searchingValue,
    createdBy,
    assignedUser
  } = data;
  const resData = await dataService.get(`${API}companies/count/?searchingPhrase=${searchingValue}&createdBy=${createdBy}&assignedUser=${assignedUser}`);
  return resData;
});

export const generatePin = createAsyncThunk('company/pin/generate', async (data) => {
  const resData = await dataService.post(`${API}generatePin/`, data);
  return resData;
});

export const getPin = createAsyncThunk('company/pin/get', async (companyId) => {
  const resData = await dataService.get(`${API}pin/${companyId}`);
  return resData;
});

export const deletePin = createAsyncThunk('company/pin/delete', async (companyId) => {
  const resData = await dataService.delete(`${API}deletePin/${companyId}`);
  return resData;
});

const initialState = {
  companies: null,
  companiesCount: 0,
  generatedPin: null,
  chosenCompany: null,
  chosenCompanyId: null,
  companyError: null
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setChosenCompanyId: (state, action) => {
      state.chosenCompanyId = action.payload;
      localStorage.setItem("chosenCompanyId", action.payload);
    },
    clearCompaniesData: (state) => {
      state.companies = null;
      state.companiesCount = 0;
    },
    clearChosenCompanyData: (state) => {
      state.chosenCompany = null;
      state.chosenCompanyId = null;
    },
    clearCompaniesError: (state) => {
      state.companyError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.fulfilled, (state, action) => {
        state.chosenCompany = action.payload.data;
        state.chosenCompanyId = action.payload.data.id;
      })
      .addCase(getCompany.rejected, (state, action) => {
        const code = getErrorCode(action.error.message);
        if (code == 400)
          state.companyError = `błędny id firmy`;
      })
      .addCase(getPaginatedCompanies.fulfilled, (state, action) => {
        state.companies = action.payload.data;
      })
      .addCase(getPaginatedCompanies.rejected, () => {})
      .addCase(getFilteredCompanies.fulfilled, (state, action) => {
        state.companies = action.payload.data;
      })
      .addCase(getFilteredCompanies.rejected, () => {})
      .addCase(getCompaniesCount.fulfilled, (state, action) => {
        state.companiesCount = action.payload.data;
      })
      .addCase(getCompaniesCount.rejected, () => {})
      .addCase(generatePin.fulfilled, (state, action) => {
        state.generatedPin = action.payload.data;
      })
      .addCase(generatePin.rejected, () => {

      })
      .addCase(deletePin.fulfilled, (state) => {
        state.generatedPin = null;
      })
      .addCase(deletePin.rejected, () => {})
      .addCase(getPin.fulfilled, (state, action) => {
        state.generatedPin = action.payload.data;
      })
      .addCase(getPin.rejected, (state, action) => {
        const code = getErrorCode(action.error.message);
        if (code == 400)
          console.log();
      });

  }
});


export const {
  setChosenCompanyId,
  clearCompaniesData,
  clearChosenCompanyData,
  clearCompaniesError
} = companySlice.actions;

export default companySlice.reducer;