/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { dateFormatView } from "../../../../../static/functions";
import EditButton from "../../../../EditButton/EditButton";
import { Info } from "@mui/icons-material";
import TableDataGridWrapper from "../../../../TableGridWrapper/TableDataGridWrapper";
import { taskCommentsHeaders } from "../../../../../static/tablesHeaders";

const Wrapper = styled(Box)(() => ({
  height: "4rem",
  borderBottom: "0.0625rem solid #eee",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  position: "relative",
}));

const ModificationWrapper = styled(Box)(() => ({
  position: "absolute",
  top: "75%",
  right: "5%",
}));

const ModificationInnerWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.2rem",
  marginRight: "12rem",
  alignItems: "center",
}));

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const CustomModifierInfo = styled(Typography)(() => ({
  fontSize: "0.7em",
  fontWeight: 700,
}));

const TaskCommentsItemDesktop = ({
  orderNumber,
  comment,
  setIsAddEditTaskCommentVisible,
}) => {
  const {
    creationDate,
    author,
    message,
    lastModifiedBy,
    lastModificationDate,
  } = comment;

  return (
    <Wrapper>
      <TableDataGridWrapper headers={taskCommentsHeaders} gap="1rem">
        <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
        <CustomDataDisplay>{dateFormatView(creationDate)}</CustomDataDisplay>
        <CustomDataDisplay>
          {`${author.name} ${author.surname}`}
        </CustomDataDisplay>
        <CustomDataDisplay>{message}</CustomDataDisplay>
        <EditButton onClick={() => setIsAddEditTaskCommentVisible(true)} />
      </TableDataGridWrapper>
      {lastModifiedBy !== null && (
        <ModificationWrapper>
          <ModificationInnerWrapper>
            <Info sx={{ fontSize: "0.9rem" }} />
            <CustomModifierInfo>Ostatnio modyfikowane przez</CustomModifierInfo>
            <CustomModifierInfo>{lastModifiedBy.username}</CustomModifierInfo>
            <CustomModifierInfo>
              {`o ${dateFormatView(lastModificationDate)}`}
            </CustomModifierInfo>
          </ModificationInnerWrapper>
        </ModificationWrapper>
      )}
    </Wrapper>
  );
};

export default TaskCommentsItemDesktop;
