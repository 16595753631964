/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  usersListHeadersV1,
  usersListHeadersV2,
} from "../../../../static/tablesHeaders";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  roles,
  userRolesChipStyles,
  userSourceChipStyles,
  userSources,
} from "../../../../static/static";
import AcceptButton from "../../../AcceptButton/AcceptButton";
import EditButton from "../../../EditButton/EditButton";
import { Clear, Settings } from "@mui/icons-material";
import { changeUserRole } from "../../../../slices/userSlice";
import CustomChip from "../../../CustomChip/CustomChip";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const ChangeRoleWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "1rem",
}));

const UserListItemDesktop = ({
  orderNumber,
  user,
  setRefresh,
  currentHeadersSetup,
  setCurrentHeadersSetup,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRoleChanging, setIsRoleChanging] = useState(false);
  const { id, username, name, surname, role: defaultRole, source } = user;
  const [currentRole, setCurrentRole] = useState(defaultRole);

  const sendChangedRole = async () => {
    const data = {
      id,
      role: currentRole,
    };
    await dispatch(changeUserRole(data));
    setIsRoleChanging(false);
    setCurrentHeadersSetup(usersListHeadersV1);
    setRefresh((state) => !state);
  };

  return (
    <TableDataGridWrapper headers={currentHeadersSetup} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{id}</CustomDataDisplay>
      <CustomDataDisplay>{username}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <CustomDataDisplay>{surname}</CustomDataDisplay>
      {!isRoleChanging ? (
        <CustomChip
          label={roles[defaultRole]}
          sx={userRolesChipStyles(defaultRole, "")}
        />
      ) : (
        <Select
          id={`change-user-role-${id}`}
          value={currentRole}
          onChange={(event) => setCurrentRole(event.target.value)}
          size="small"
        >
          {Object.keys(roles).map((role) => (
            <MenuItem key={role} value={role} disabled={role === defaultRole}>
              {roles[role]}
            </MenuItem>
          ))}
        </Select>
      )}
      <CustomChip
        label={userSources[source]}
        sx={userSourceChipStyles[source]}
      />
      <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center", flexWrap: 'wrap'}}>
        {!isRoleChanging ? (
          <EditButton
            text="Zmień rolę"
            onClick={() => {
              setIsRoleChanging(true);
              setCurrentHeadersSetup(usersListHeadersV2);
            }}
          />
        ) : (
          <ChangeRoleWrapper>
            <AcceptButton
              onClick={() => sendChangedRole()}
              text="Potwierdź"
              disabled={currentRole === defaultRole}
            />
            <EditButton
              text="Anuluj"
              startIcon={<Clear />}
              onClick={() => {
                setIsRoleChanging(false);
                setCurrentHeadersSetup(usersListHeadersV1);
                setCurrentRole(defaultRole);
              }}
            />
          </ChangeRoleWrapper>
        )}
        <EditButton
          onClick={() => navigate(`/user-management/${id}`)}
          text="Edytuj"
          startIcon={<Settings />}
        />
      </Box>
    </TableDataGridWrapper>
  );
};

export default UserListItemDesktop;
