export const TaskCommentModalClasses = {
  wrapper: {
    width: "24rem",
    height: "20rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: '2rem',
    alignItems: "center",
  },
  textFieldProps: {
    width: "20rem"
  },
  buttonsWrapper: {
    display: "flex",
    marginTop: "0.5rem",
    gap: "0.5rem",
    justifyContent: "center",
  }
};