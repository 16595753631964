/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { categoriesListHeaders } from "../../../../static/tablesHeaders";
import EditButton from "../../../EditButton/EditButton";
import DeleteButton from "../../../DeleteButton/DeleteButton";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "0.1rem 1rem",
}));

const CategoryItemDesktop = ({
  orderNumber,
  category,
  editCategory,
  delCategory,
}) => {
  const { name, description } = category;

  return (
    <TableDataGridWrapper headers={categoriesListHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <CustomDataDisplay>{description}</CustomDataDisplay>
      <ButtonWrapper>
        <EditButton onClick={() => editCategory()} />
        <DeleteButton onClick={() => delCategory()} />
      </ButtonWrapper>
    </TableDataGridWrapper>
  );
};

export default CategoryItemDesktop;
