/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dropdown from "../../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../slices/userSlice";
import {
  createCompany,
  getCompany,
  updateCompany,
} from "../../../slices/companySlice";
import { Add, Clear, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import authService from "../../../auth/authService";
import PageHeader from "../../PageHeader/PageHeader";
import EditButton from "../../EditButton/EditButton";
import Loading from "../../Loading/Loading";
import CustomTextField from "../../CustomTextField/CustomTextField";
import AcceptButton from "../../AcceptButton/AcceptButton";

const Wrapper = styled(Box)(({ theme }) => ({
  margin: "2rem auto",
  width: "25rem",
  backgroundColor: "white",
  padding: "1.25rem 0.625rem 1.25rem 0.625rem",
  borderRadius: "1.25rem",
  [theme.breakpoints.down("laptop")]: {
    width: "20rem",
    margin: "0.5rem auto",
  },
}));

const InnerWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
}));

const AddressWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.5rem",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  gap: "1rem",
}));

const CompanyForm = ({ type = "add" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users } = useSelector((slice) => slice.user);
  const { chosenCompany } = useSelector((slice) => slice.company);
  const [buttonLock, setButtonLock] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    timezone: "Europa/Warszawa",
    address: {
      street: "",
      city: "",
      province: "",
      country: "Polska",
      postcode: "",
    },
    assignedUser: "",
    contactPerson: "",
    email: "",
    phoneNumber: "",
    createdBy: authService.getDecodedAccessToken().user_id,
  });
  const [isDataLoading, setIsDataLoading] = useState(false);

  const getAddData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getUsers());
    setIsDataLoading(false);
  }, []);

  const getEditData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getUsers());
    await dispatch(getCompany());
    setIsDataLoading(false);
  }, []);

  const isFormDataCompleted = () => {
    return !(
      !formValues.name ||
      !formValues.timezone ||
      !formValues.address.street ||
      !formValues.address.city ||
      !formValues.address.province ||
      !formValues.address.country ||
      !formValues.address.postcode ||
      !formValues.assignedUser
    );
  };

  useEffect(() => {
    if (type === "add") getAddData();
  }, [getAddData]);

  useEffect(() => {
    if (type === "edit") getEditData();
  }, [getEditData]);

  useEffect(() => {
    if (type === "edit" && chosenCompany !== null) {
      setFormValues({
        name: chosenCompany.name,
        timezone: chosenCompany.timezone,
        address: {
          street: chosenCompany.address.street,
          city: chosenCompany.address.city,
          province: chosenCompany.address.province,
          country: chosenCompany.address.country,
          postcode: chosenCompany.address.postcode,
        },
        assignedUser: chosenCompany.assignedUser.id,
        contactPerson: chosenCompany.contactPerson,
        email: chosenCompany.email,
        phoneNumber: chosenCompany.phoneNumber,
      });
    }
  }, [chosenCompany]);

  const addCompany = useCallback(async () => {
    await dispatch(createCompany(formValues));
    navigate("/companies");
  }, [formValues]);

  const editCompany = useCallback(async () => {
    await dispatch(updateCompany(formValues));
    navigate(`/companies/${chosenCompany.id}`);
  }, [formValues]);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (keyCode === 13 && !buttonLock && isFormDataCompleted()) {
        if (type === "add") addCompany();
        if (type === "edit") editCompany();
        setButtonLock(true);
        setTimeout(() => setButtonLock(false), 5000);
      }
    },
    [buttonLock, addCompany, editCompany]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  if (isDataLoading) return <Loading />;

  return (
    <Wrapper>
      {users !== null &&
      users.length > 0 &&
      (type === "add" || (type === "edit" && chosenCompany !== null)) ? (
        <InnerWrapper>
          <PageHeader
            titleText={
              type === "add"
                ? "Dodawanie firmy"
                : `Edytowanie firmy '${chosenCompany.name}'`
            }
            icon={type === "add" ? <Add /> : <Edit />}
            size="medium"
          />
          <Typography sx={{ color: "red", fontSize: "0.8rem" }}>
            *Pola obowiązkowe
          </Typography>
          <CustomTextField
            label="Nazwa*"
            value={formValues.name}
            onChange={(e) =>
              setFormValues((state) => {
                return { ...state, name: e.target.value };
              })
            }
            maxLength={200}
          />
          <CustomTextField
            label="Strefa czasowa*"
            value={formValues.timezone}
            onChange={(e) =>
              setFormValues((state) => {
                return { ...state, timezone: e.target.value };
              })
            }
            maxLength={100}
          />
          <AddressWrapper>
            <Typography
              sx={{
                fontSize: "1.3rem",
                alignSelf: "start",
                marginLeft: "1rem",
              }}
            >
              Adres
            </Typography>
            <CustomTextField
              label="Ulica*"
              value={formValues.address.street}
              onChange={(e) =>
                setFormValues((state) => {
                  return {
                    ...state,
                    address: {
                      street: e.target.value,
                      city: state.address.city,
                      province: state.address.province,
                      country: state.address.country,
                      postcode: state.address.postcode,
                    },
                  };
                })
              }
              maxLength={50}
            />
            <CustomTextField
              label="Miasto*"
              value={formValues.address.city}
              onChange={(e) =>
                setFormValues((state) => {
                  return {
                    ...state,
                    address: {
                      street: state.address.street,
                      city: e.target.value,
                      province: state.address.province,
                      country: state.address.country,
                      postcode: state.address.postcode,
                    },
                  };
                })
              }
              maxLength={50}
            />
            <CustomTextField
              label="Województwo/Prowincja*"
              value={formValues.address.province}
              onChange={(e) =>
                setFormValues((state) => {
                  return {
                    ...state,
                    address: {
                      street: state.address.street,
                      city: state.address.city,
                      province: e.target.value,
                      country: state.address.country,
                      postcode: state.address.postcode,
                    },
                  };
                })
              }
              maxLength={50}
            />
            <CustomTextField
              label="Kraj*"
              value={formValues.address.country}
              onChange={(e) =>
                setFormValues((state) => {
                  return {
                    ...state,
                    address: {
                      street: state.address.street,
                      city: state.address.city,
                      province: state.address.province,
                      country: e.target.value,
                      postcode: state.address.postcode,
                    },
                  };
                })
              }
              maxLength={50}
            />
            <CustomTextField
              label="Kod pocztowy*"
              value={formValues.address.postcode}
              onChange={(e) =>
                setFormValues((state) => {
                  return {
                    ...state,
                    address: {
                      street: state.address.street,
                      city: state.address.city,
                      province: state.address.province,
                      country: state.address.country,
                      postcode: e.target.value,
                    },
                  };
                })
              }
              maxLength={20}
            />
          </AddressWrapper>
          <Dropdown
            label="Przypisany użytkownik"
            collection={[
              ...users.map((user) => {
                return {
                  ...user,
                  name_surname: `${user.surname} ${user.name}`,
                };
              }),
            ]}
            displayAttribute="name_surname"
            searchingAttribute="id"
            shownValue="name_surname"
            setValue={(value) =>
              setFormValues((state) => {
                return {
                  ...state,
                  assignedUser: value,
                };
              })
            }
          />
          <CustomTextField
            label="Osoba kontaktowa"
            value={formValues.contactPerson}
            onChange={(e) =>
              setFormValues((state) => {
                return {
                  ...state,
                  contactPerson: e.target.value,
                };
              })
            }
            maxLength={100}
          />
          <CustomTextField
            label="Email"
            value={formValues.email}
            onChange={(e) =>
              setFormValues((state) => {
                return {
                  ...state,
                  email: e.target.value,
                };
              })
            }
            maxLength={20}
          />
          <CustomTextField
            label="Nr telefonu"
            value={formValues.phoneNumber}
            onChange={(e) =>
              setFormValues((state) => {
                return {
                  ...state,
                  phoneNumber: e.target.value,
                };
              })
            }
            maxLength={20}
          />
          <ButtonWrapper>
            {type === "add" && (
              <AcceptButton
                onClick={() => addCompany()}
                text="Dodaj"
                startIcon={<Add />}
                disabled={buttonLock || !isFormDataCompleted()}
              />
            )}
            {type === "edit" && (
              <EditButton
                onClick={() => editCompany()}
                text="Edytuj"
                disabled={buttonLock || !isFormDataCompleted()}
              />
            )}
            <EditButton
              onClick={() => navigate(-1)}
              startIcon={<Clear />}
              text="Anuluj"
            />
          </ButtonWrapper>
        </InnerWrapper>
      ) : (
        <Typography>Błąd wczytywania</Typography>
      )}
    </Wrapper>
  );
};

export default CompanyForm;
