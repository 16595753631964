/* eslint-disable react/prop-types */
import React from "react";
import { dateFormatView } from "../../../../static/functions";
import CustomChip from "../../../CustomChip/CustomChip";
import { requestStatus, requestStatusChipStyles } from "../../../../static/static";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";

const RequestItemMobile = ({ orderNumber, request, openItemDetails }) => {
  const {
    creationDate,
    topic,
    baseStorage: { name: baseStorageName },
    destinationStorage: { name: destinationStorageName },
    sender: { name: senderName, surname: senderSurname },
    status,
  } = request;

  const data = [
    { title: "Data wysłania:", value: dateFormatView(creationDate) },
    { title: "Temat:", value: topic },
    { title: "Magazyn nadawczy:", value: baseStorageName },
    { title: "Magazyn odbiorczy:", value: destinationStorageName },
    { title: "Dane nadawcy:", value: `${senderName} ${senderSurname}` },
    {
      title: "Status:",
      value: (
        <CustomChip
          label={requestStatus[status]}
          sx={requestStatusChipStyles(status, "")}
          width="100%"
        />
      ),
    },
  ];
  return (
    <CompactDataDisplay
      title={`Zapotrzebowanie #${orderNumber}`}
      data={data}
      actions={
        <>
          <DetailsButton
            onClick={() => openItemDetails()}
            styles={{ marginLeft: "2rem" }}
          />
        </>
      }
    />
  );
};

export default RequestItemMobile;
