import React from "react";
import { Box, Tooltip } from "@mui/material";
import {
  AddCircle,
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  LayersClear,
  ImportExport,
  RemoveCircle,
  Inventory,
  LocationOn,
  Clear
} from "@mui/icons-material";

export const ACCESS_TOKEN = 'storages_access';
export const REFRESH_TOKEN = 'storages_refresh';
export const USER_DATA = 'userData';
// export const SERVER_URL = 'http://127.0.0.1:7000';
// export const SERVER_URL = 'https://svc-dev.tetranet.pl';
export const SERVER_URL = 'https://svc.tetranet.pl';
export const API = `${SERVER_URL}/api/`;
// export const BASE_URL = 'http://localhost:3000';
// export const BASE_URL = 'https://svc-dev.tetranet.pl';
export const BASE_URL = 'https://svc.tetranet.pl';
export const DEFAULT_DISPLAY_ITEMS_QUANTITY = 15;

export const roles = {
  'service_technician': 'Serwisant',
  'warehouseman': 'Magazynier',
  'admin': 'Administrator',
  'no_role': 'Brak uprawnień'
};

export const shipmentStatus = {
  'Created': 'Utworzona',
  'In progress': 'W dostarczeniu',
  'Delivered': 'Dostarczona',
  'To Explain': 'Do wyjaśnienia'
};

export const requestStatus = {
  'Sent': 'Nowe',
  'Accepted': 'W realizacji',
  'Warned': 'Realiz. cz.',
  'Rejected': 'Odrzucone'
};

export const commissionType = {
  'Online Fix': 'Naprawa zdalna',
  'Stationary Fix': 'Naprawa stacjonarna',
  'Not Defined': 'Nieokreślony',
};

export const commissionStatus = {
  'Created': 'Utworzone',
  'In progress': 'W trakcie',
  'Closed': 'Zamknięte'
};

export const taskType = {
  'Qualification': 'Kwalifikacyjne',
  'Assignment': 'Przypisania',
  'Approval': 'Do akceptacji',
  'Work': 'Wykonawcze',
  'Verification': 'Weryfikacyjne'
};

export const taskStatus = {
  'Created': 'Utworzone',
  'In progress': 'W trakcie',
  'Closed': 'Zamknięte',
  'Rejected': 'Odrzucone'
};

export const userSources = {
  'ldap': 'ldap',
  'inner': 'wewnętrzny',
  'sds': 'sds',
};

export const shipmentStatusChipStyles = (keyword, actualValue) => {
  if (keyword === "") return {
    bgcolor: "#AAA",
    color: "white",
    border: actualValue === keyword ? "0.1875em solid black" : ""
  };
  else if (keyword === "Created") return {
    bgcolor: "#6EAFFA",
    color: "#170BD9",
    border: actualValue === keyword ? "0.1875em solid #170BD9" : ""
  };
  else if (keyword === "In progress") return {
    bgcolor: "#edd679",
    color: "#9e7f05",
    border: actualValue === keyword ? "0.1875em solid #FFC80A" : ""
  };
  else if (keyword === "Delivered") return {
    bgcolor: "#8afa84",
    color: "#0b8a04",
    border: actualValue === keyword ?
      "0.1875em solid #1ED926" : ""
  };
  else if (keyword === "To Explain") return {
    bgcolor: "#af58cc",
    color: "#720c94",
    border: actualValue === keyword ?
      "0.1875em solid #1ED926" : ""
  };
  else {
    return {};
  }
};


export const requestStatusChipStyles = (keyword, actualValue) => {
  if (keyword === "") return {
    bgcolor: "#AAA",
    color: "white",
    border: actualValue === keyword ? "0.1875em solid black" : ""
  };
  else if (keyword === "Sent") return {
    bgcolor: "#6EAFFA",
    color: "#170BD9",
    border: actualValue === keyword ? "0.1875em solid #170BD9" : ""
  };
  else if (keyword === "Accepted") return {
    bgcolor: "#8afa84",
    color: "#0b8a04",
    border: actualValue === keyword ? "0.1875em solid #1ED926" : ""
  };
  else if (keyword === "Warned") return {
    bgcolor: "#edd679",
    color: "#9e7f05",
    border: actualValue === keyword ?
      "0.1875em solid #FFC80A" : ""
  };
  else if (keyword === "Rejected") return {
    bgcolor: "black",
    color: "white",
    border: actualValue === keyword ?
      "0.1875em solid #888" : ""
  };
  else {
    return {};
  }
};

export const unitStatusChipStyles = (keyword, actualValue) => {
  if (keyword === "") return {
    bgcolor: "#AAA",
    color: "white",
    border: actualValue === keyword ? "0.1875rem solid black" : ""
  };
  else if (keyword === "GOOD") return {
    bgcolor: "#8afa84",
    color: "#0b8a04",
    border: actualValue === keyword ? "0.1875rem solid #1ED926" : ""
  };
  else if (keyword === "MOUNTED") return {
    bgcolor: "#edd679",
    color: "#9e7f05",
    border: actualValue === keyword ? "0.1875rem solid #FFC80A" : ""
  };
  else if (keyword === "BAD") return {
    bgcolor: "#eb7373",
    color: "#9c0202",
    border: actualValue === keyword ?
      "0.1875rem solid #bd372d" : ""
  };
  else if (keyword === "DOA") return {
    bgcolor: "black",
    color: "white",
    border: actualValue === keyword ?
      "0.1875rem solid #888" : ""
  };
  else if (keyword === "UTILIZED") return {
    bgcolor: "#a3aab5",
    color: "white",
    border: "0.1875rem dashed #888"
  };
  else {
    return {};
  }
};

export const taskStatusChipStyles = (keyword, actualValue) => {
  if (JSON.stringify(keyword) == JSON.stringify(["Created", "In progress"]) || JSON.stringify(keyword) == JSON.stringify(["Closed", "Rejected"])) return {
    bgcolor: "#AAA",
    color: "white",
    border: JSON.stringify(actualValue) == JSON.stringify(keyword) ? "0.1875em solid black" : ""
  };
  else if (keyword === "Created") return {
    bgcolor: "#6EAFFA",
    color: "#170BD9",
    border: actualValue === keyword ? "0.1875em solid #170BD9" : ""
  };
  else if (keyword === "In progress") return {
    bgcolor: "#edd679",
    color: "#9e7f05",
    border: actualValue === keyword ? "0.1875em solid #FFC80A" : ""
  };
  else if (keyword === "Closed") return {
    bgcolor: "#eb7373",
    color: "#9c0202",
    border: actualValue === keyword ? "0.1875em solid #870709" : ""
  };
  else if (keyword === "Rejected") return {
    bgcolor: "black",
    color: "white",
    border: actualValue === keyword ? "0.1875em solid black" : ""
  };
  else {
    return {};
  }
};

export const taskTypeChipStyles = (keyword, actualValue) => {
  if (keyword == "") return {
    bgcolor: "#AAA",
    color: "white",
    border: actualValue == keyword ? "0.1875em solid black" : ""
  };
  else if (keyword === "Qualification") return {
    bgcolor: "#f7c845",
    color: "#ba8a06",
    border: actualValue === keyword ? "0.1875em solid #170BD9" : ""
  };
  else if (keyword === "Assignment") return {
    bgcolor: "#8eedec",
    color: "#3c9190",
    border: actualValue === keyword ? "0.1875em solid #FFC80A" : ""
  };
  else if (keyword === "Approval") return {
    bgcolor: "#acf075",
    color: "#357006",
    border: actualValue === keyword ? "0.1875em solid #FFC80A" : ""
  };
  else if (keyword === "Work") return {
    bgcolor: "#ebaf73",
    color: "#a85e14",
    border: actualValue === keyword ? "0.1875em solid #870709" : ""
  };
  else if (keyword === "Verification") return {
    bgcolor: "#c37cf2",
    color: "#701ea6",
    border: actualValue === keyword ? "0.1875em solid black" : ""
  };
  else {
    return {};
  }
};

export const commissionStatusChipStyles = (keyword, actualValue) => {
  if (keyword == "") return {
    bgcolor: "#AAA",
    color: "white",
    border: actualValue == keyword ? "0.1875em solid black" : ""
  };
  else if (keyword === "Created") return {
    bgcolor: "#6EAFFA",
    color: "#170BD9",
    border: actualValue === keyword ? "0.1875em solid #170BD9" : ""
  };
  else if (keyword === "In progress") return {
    bgcolor: "#f7cc65",
    color: "#bf8c11",
    border: actualValue === keyword ? "0.1875em solid #bf8c11" : ""
  };
  else if (keyword === "Closed") return {
    bgcolor: "#8afa84",
    color: "#0b8a04",
    border: actualValue === keyword ? "0.1875em solid #870709" : ""
  };
  else {
    return {};
  }
};

export const userRolesChipStyles = (keyword, actualValue) => {
  if (keyword === "") return {
    bgcolor: "#AAA",
    color: "white",
    border: actualValue == keyword ? "0.1875em solid black" : ""
  };
  else if (keyword === "admin") return {
    bgcolor: "#6EAFFA",
    color: "#170BD9",
    border: actualValue === keyword ? "0.1875em solid #170BD9" : ""
  };
  else if (keyword === "warehouseman") return {
    bgcolor: "#8afa84",
    color: "#0b8a04",
    border: actualValue === keyword ? "0.1875em solid #FFC80A" : ""
  };
  else if (keyword === "service_technician") return {
    bgcolor: "#edd679",
    color: "#9e7f05",
    border: actualValue === keyword ? "0.1875em solid #870709" : ""
  };
  else if (keyword === "no_role") return {
    bgcolor: "black",
    color: "white",
    border: actualValue === keyword ? "0.1875em solid black" : ""
  };
  else {
    return {};
  }
};

export const commissionTypesChipStyles = {
  'Online Fix': {
    bgcolor: "#cb96ff",
    color: "#390170"
  },
  'Stationary Fix': {
    bgcolor: "#edd679",
    color: "#9e7f05"
  },
  'Not Defined': {
    bgcolor: "black",
    color: "white"
  },
};


export const userSourceChipStyles = {
  ldap: {
    bgcolor: "#ae9cf7",
    color: "#7360bf"
  },
  inner: {
    bgcolor: "#d98e50",
    color: "#995218"
  },
  sds: {
    bgcolor: "#76e4e8",
    color: "#0e8a8f"
  },
};

export const polishProvinces = ['dolnośląskie', 'kujawsko-pomorskie', 'lubelskie', 'lubuskie', 'łódzkie', 'małopolskie', 'mazowieckie', 'opolskie',
  'podkarpackie', 'podlaskie', 'pomorskie', 'śląskie', 'świętokrzyskie', 'warmińsko-mazurskie', 'wielkopolskie', 'zachodniopomorskie'
];

export const errorMessages = {
  unit: {
    450: 'Produkt o podanym numerze seryjnym już istnieje'
  },
  shipment: {
    450: 'Zbyt krótki numer przesyłki'
  },
  user: {
    450: 'Nie można modyfikować danych admina'
  },
  storage: {
    450: 'Nie można usunąć magazynu głównego'
  },
};


export const defaultPermissions = {
  service: {
    'Qualification Tasks View': 'Qualification Tasks View',
    'Assignment Tasks View': 'Assignment Tasks View',
    'Verification Tasks View': 'Verification Tasks View',
  }
};

export const storageReportOperationType = {
  "add_units": "Dodanie jednostek na magazyn",
  "modify_units": "Modyfikacja jednostek",
  "delete_units": "Usunięcie jednostek",
  "add_to_shipment": "Dodanie do przesyłki",
  "get_from_shipment": "Odebranie z przesyłki",
  "mount_units": "Montowane komponenty",
  "unmount_units": "Demontowane komponenty",
  "mark_as_doa": "Oznacz jako DOA",
  "utilize_units": "Utylizacja",
};

export const storageReportOperationTypeIcons = {
  "add_units": <Tooltip title="Dodanie jednostek na magazyn">
    <AddCircle sx={{color: 'green'}} />
  </Tooltip>,
  "modify_units": <Tooltip title="Modyfikacja jednostek">
    <ImportExport sx={{color: 'blue'}} />
  </Tooltip>,
   "delete_units": <Tooltip title="Usunięcie jednostek">
    <RemoveCircle sx={{color: 'red'}} />
  </Tooltip>,
  "add_to_shipment": < Tooltip title="Dodanie do przesyłki" >
    <Box display="flex">
      <ArrowForward sx={{ color: 'orange' }} />
      <Inventory sx={{ color: 'orange' }}/>
    </Box>
  </Tooltip>,
     "get_from_shipment": < Tooltip title = "Odebranie z przesyłki" >
       <Box display="flex">
         <Inventory sx={{ color: 'purple' }}/>
      <ArrowForward sx={{ color: 'purple' }} />
      
    </Box>
  </Tooltip>,
  "mount_units": < Tooltip title="Montowane komponenty" >
      <Box display="flex" >
      <ArrowDownward sx={{ color: '#4e9dfc' }} />
      <LocationOn sx={{ color: '#4e9dfc' }}/>
      </Box>
  </Tooltip>,
  "unmount_units": < Tooltip title="Demontowane komponenty" >
    <Box display="flex" >
      <ArrowUpward sx={{ color: '#6e0e22' }} />
      <LocationOn sx={{color: "#6e0e22"}} />
      </Box>
         
         </Tooltip>,
       "mark_as_doa": < Tooltip title = "Oznacz jako DOA" >
    <LayersClear sx={{color: '#2b3138'}} />
  </Tooltip>,
  "utilize_units": < Tooltip title="Utylizacja" >
    <Box display="flex">
      <ArrowForward sx={{ color: 'gray' }} />
      <Clear sx={{ color: 'gray' }}/>
    </Box>
  </Tooltip>,
};