export const SwitchTabClasses = {
  innerStyle: (selected) => {
    return {
      bgcolor: "white",
      border: "none",
      color: selected ? "black" : "#FF4C29",
      boxShadow: "none",
      borderBottom: selected ?
        "0.0625em solid grey" : "0.0625em solid #FF4C29",
      "&:hover": {
        bgcolor: "white",
        boxShadow: "none",
        color: "orange",
        borderBottom: "0.0625em solid #FF4C29",
      },
      ["@media (max-width:1023px)"]: {
        fontSize: "0.8em",
      },
      ["@media (max-width:767px)"]: {
        fontSize: "0.6em",
      },
    };
  }
};