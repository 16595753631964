/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import TableDataGridWrapper from "../../TableGridWrapper/TableDataGridWrapper";
import { Checkbox, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import DetailsButton from "../../DetailsButton/DetailsButton";
import { storageReportsListHeaders } from "../../../static/tablesHeaders";
import { dateFormatView } from "../../../static/functions";
import { storageReportOperationTypeIcons } from "../../../static/static";
import { Link } from "react-router-dom";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
  color: "black",
  textDecoration: "none",
}));

const StorageReportItemDesktop = ({
  orderNumber,
  storageReport,
  storageReportDetails,
  selectedStorageReports,
  addStorageReportToSelected,
  removeStorageReportFromSelected,
}) => {
  const {
    id,
    creationDate,
    operationType,
    user,
    baseStorage,
    destinationStorage,
    shipment,
    utilizationReport,
    task,
  } = storageReport;

  const handleClick = useCallback(() => {
    if (selectedStorageReports) {
      if (
        selectedStorageReports.filter(
          (filteringStorageReport) => filteringStorageReport.id === id
        ).length > 0
      ) {
        removeStorageReportFromSelected(id);
      } else {
        addStorageReportToSelected(storageReport);
      }
    }
  }, [addStorageReportToSelected, removeStorageReportFromSelected]);

  return (
    <TableDataGridWrapper headers={storageReportsListHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <Checkbox
        checked={
          selectedStorageReports && selectedStorageReports.filter(
            (filteringStorageReport) => filteringStorageReport.id === id
          ).length > 0
        }
        onClick={() => handleClick()}
      />
      <CustomDataDisplay>{dateFormatView(creationDate)}</CustomDataDisplay>
      <>{storageReportOperationTypeIcons[operationType]}</>
      <CustomDataDisplay>
        {user ? `${user.name} ${user.surname}` : ""}
      </CustomDataDisplay>
      <Link to={baseStorage ? `/storages/${baseStorage.id}` : ""}>
        <CustomDataDisplay>
          {baseStorage ? `${baseStorage.name}` : ""}
        </CustomDataDisplay>
      </Link>
      <Link to={shipment ? `/shipments/${shipment.id}` : ""}>
        <CustomDataDisplay>
          {shipment ? `${shipment.id}` : ""}
        </CustomDataDisplay>
      </Link>
      <Link to={destinationStorage ? `/storages/${destinationStorage.id}` : ""}>
        <CustomDataDisplay>
          {destinationStorage ? `${destinationStorage.name}` : ""}
        </CustomDataDisplay>
      </Link>
      <Link
        to={
          utilizationReport ? `/utilizationReports/${utilizationReport.id}` : ""
        }
      >
        <CustomDataDisplay>
          {utilizationReport ? `${utilizationReport.id}` : ""}
        </CustomDataDisplay>
      </Link>
      <Link to={task ? `/tasks/${task.id}` : ""}>
        <CustomDataDisplay>{task ? `${task.id}` : ""}</CustomDataDisplay>
      </Link>
      <DetailsButton onClick={() => storageReportDetails()} text="szczegóły" />
    </TableDataGridWrapper>
  );
};

export default StorageReportItemDesktop;
