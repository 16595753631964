/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearStorage,
  getMainStorage,
  getStorage,
  setStorageId,
} from "../../slices/storageSlice";
import { useLocation, useNavigate } from "react-router-dom";
import UnitsList from "../UnitsList/UnitsList/UnitsList";
import ShipmentsByDestinationView from "../Shipments/ShipmentsByDestination/ShipmentsByDestinationView";
import ShipmentsByBaseView from "../Shipments/ShipmentsByBase/ShipmentsByBaseView";
import { Warehouse, WarehouseOutlined } from "@mui/icons-material";
import StorageNotFound from "./StorageNotFound";
import RefreshButton from "../RefreshButton/RefreshButton";
import { EditButton } from "../FunctionalButtons/FunctionalButtons";
import BlankSpace from "../BlankSpace/BlankSpace";
import StorageDetails from "./StorageDetails";
import PageHeader from "../PageHeader/PageHeader";
import Loading from "../Loading/Loading";
import PageWrapper from "../PageWrapper/PageWrapper";
import { ReturnButton } from "../FunctionalButtons/FunctionalButtons";

// eslint-disable-next-line react/prop-types
const Storage = ({ type = "local" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const { storageId } = useParams();
  const { chosenStorage, storageError } = useSelector((slice) => slice.storage);
  const { pathname } = useLocation();
  const [isDataLoading, setIsDataLoading] = useState(false);
  // const [isDataViewOpened, setIsDataViewOpened] = useState(false);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    if (type === "local") {
      await dispatch(getStorage(storageId));
    } else {
      await dispatch(getMainStorage());
    }
    setIsDataLoading(false);
  }, [pathname, refresh]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => {
      dispatch(clearStorage());
    };
  }, []);

  const editStorage = () => {
    dispatch(setStorageId(chosenStorage.id));
    navigate("/storage-edit");
  };

  if (isDataLoading) return <Loading />;

  if (storageError) {
    return <StorageNotFound storageError={storageError} />;
  }

  return (
    <PageWrapper width="90%">
      {chosenStorage ? (
        <>
          <PageHeader
            icon={type === "main" ? <Warehouse /> : <WarehouseOutlined />}
            titleText={
              type === "main"
                ? "Magazyn główny"
                : `Magazyn lokalny "${chosenStorage.name}"`
            }
            onRightComponent={<ReturnButton onClick={() => navigate(-1)} />}
          >
            <RefreshButton onClick={() => setRefresh((state) => !state)} />
            <EditButton onClick={() => editStorage()} />
          </PageHeader>
          <BlankSpace />
          <StorageDetails type={type} chosenStorage={chosenStorage} />
          <BlankSpace />
          <ShipmentsByDestinationView />
          <BlankSpace />
          <ShipmentsByBaseView setRefresh={setRefresh} />
          <BlankSpace />
          <UnitsList chosenStorage={chosenStorage} setRefresh={setRefresh} />
        </>
      ) : (
        <></>
      )}
    </PageWrapper>
  );
};

export default Storage;
