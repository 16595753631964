/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import authService from "../../../auth/authService";

const SectionsList = ({
  sections,
  currentSectionOrder,
  setCurrentSectionOrder,
}) => {
  const { role } = authService.getDecodedAccessToken();

  const handleCheckboxClick = useCallback(
    (sectionId) => {
      setCurrentSectionOrder(
        currentSectionOrder.map((currentSectionOrderUnit) =>
          currentSectionOrderUnit.id === sectionId
            ? {
                ...currentSectionOrderUnit,
                isVisible: !currentSectionOrderUnit.isVisible,
              }
            : currentSectionOrderUnit
        )
      );
    },
    [currentSectionOrder, setCurrentSectionOrder]
  );

  const moveUp = useCallback(
    (sectionId) => {
      const findingIndex = currentSectionOrder.findIndex(
        (currentSectionOrderUnit) => currentSectionOrderUnit.id === sectionId
      );
      const arrayCopy = [...currentSectionOrder];
      const swappedItem = arrayCopy[findingIndex];
      arrayCopy[findingIndex] = arrayCopy[findingIndex - 1];
      arrayCopy[findingIndex - 1] = swappedItem;
      setCurrentSectionOrder(arrayCopy);
    },
    [currentSectionOrder]
  );
  const moveDown = useCallback(
    (sectionId) => {
      const findingIndex = currentSectionOrder.findIndex(
        (currentSectionOrderUnit) => currentSectionOrderUnit.id === sectionId
      );
      const arrayCopy = [...currentSectionOrder];
      const swappedItem = arrayCopy[findingIndex];
      arrayCopy[findingIndex] = arrayCopy[findingIndex + 1];
      arrayCopy[findingIndex + 1] = swappedItem;
      setCurrentSectionOrder(arrayCopy);
    },
    [currentSectionOrder]
  );

  return (
    <List sx={{ width: "100%", maxWidth: 500, bgcolor: "background.paper" }}>
      {currentSectionOrder.map((currentSectionOrderUnit, index) => {
        const section = sections.filter(
          (section) => section.id === currentSectionOrderUnit.id
        )[0];
        const labelId = `checkbox-list-label-${section.id}`;

        if (
          section.roles.length > 0 &&
          role !== "admin" &&
          !section.roles.includes(role)
        )
          return <></>;

        return (
          <ListItem
            key={section.id}
            secondaryAction={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {currentSectionOrder[0].id !== currentSectionOrderUnit.id && (
                  <ArrowDropUp
                    onClick={() => moveUp(section.id)}
                    sx={{ cursor: "pointer" }}
                  />
                )}
                {currentSectionOrder[currentSectionOrder.length - 1].id !==
                  currentSectionOrderUnit.id && (
                  <ArrowDropDown
                    onClick={() => moveDown(section.id)}
                    sx={{ cursor: "pointer" }}
                  />
                )}
              </Box>
            }
            disablePadding
            sx={{ transition: "5s" }}
          >
            <ListItemButton
              role={undefined}
              onClick={() => handleCheckboxClick(section.id)}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={currentSectionOrderUnit.isVisible}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${index+1}. ${section.name}`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default SectionsList;
