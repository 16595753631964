/* eslint-disable react/prop-types */
import React from "react";
import CompactDataDisplay from "../../CompactDataDisplay/CompactDataDisplay";
import CustomChip from "../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../static/static";

const StorageReportUnitItemMobile = ({ orderNumber, unit }) => {
  const {
    partNumber,
    productName,
    categoryName,
    producerName,
    serialNumber,
    status,
    quantity,
  } = unit;

  const data = [
    { title: "PartNumber:", value: partNumber },
    { title: "Nazwa:", value: productName },
    { title: "Nr seryjny:", value: serialNumber },
    { title: "Kategoria:", value: categoryName },
    { title: "Producent:", value: producerName },
    {
      title: "Status:",
      value: (
        <CustomChip
          label={status}
          sx={unitStatusChipStyles(status, "")}
          width="40%"
        />
      ),
    },

    { title: "Liczba:", value: quantity },
  ];
  return (
    <CompactDataDisplay
      title={`Produkt #${orderNumber}`}
      data={data}
      actions={<></>}
    />
  );
};

export default StorageReportUnitItemMobile;
