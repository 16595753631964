/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

const EmptyListError = ({
  message,
  icon = <CheckCircleOutline />,
  styleProps = {},
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        gap: "0.5em",
        justifyContent: "center",
        margin: "1em 0",
        ...styleProps,
      }}
    >
      {icon}
      <Typography>{message}</Typography>
    </Box>
  );
};

export default EmptyListError;
