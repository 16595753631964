import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API
} from '../static/static';

export const getProducers = createAsyncThunk('producers/get', async () => {
  const resData = await dataService.get(`${API}producers/`);
  return resData;
});

export const getFilteredProducers = createAsyncThunk('producers/get/filtered', async (searchingValue) => {
  const resData = await dataService.get(`${API}producers/filter/?searchingPhrase=${searchingValue}`);
  return resData;
});

export const getPaginatedProducers = createAsyncThunk('producers/get/paginate', async (data) => {
  let {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
  } = data;
  if (leftIndex === undefined)
    leftIndex = "";
  if (rightIndex === undefined)
    rightIndex = "";
  if (sortingParam === undefined)
    sortingParam = "name";
  if (sortingOrder === undefined)
    sortingOrder = "ascending";
  if (searchingValue === undefined)
    searchingValue = "";
  const resData = await dataService.get(`${API}producers/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}`);
  return resData;
});

export const getProducersCount = createAsyncThunk('producers/get/count', async (data) => {
  const {
    searchingValue
  } = data;
  const resData = await dataService.get(`${API}producers/count/?searchingPhrase=${searchingValue}`);
  return resData;
});

export const getProducer = createAsyncThunk('producer/get', async (givenProducerId) => {
  const resData = givenProducerId ? await dataService.get(`${API}producer/${givenProducerId}`) : await dataService.get(`${API}producer/${localStorage.getItem("chosenProducerId")}`);
  return resData;
});

export const createProducer = createAsyncThunk('producer/create', async (data) => {
  const resData = await dataService.post(`${API}producer/`, data);
  return resData;
});

export const updateProducer = createAsyncThunk('producer/update', async (data, {
  getState
}) => {
  const {
    chosenProducerId
  } = getState().data;
  const resData = chosenProducerId ? await dataService.put(`${API}producer/u/${chosenProducerId}`, data) : await dataService.put(`${API}producer/u/${localStorage.getItem("chosenProducerId")}`, data);
  return resData;
});

export const deleteProducer = createAsyncThunk('producer/delete', async (id) => {
  const resData = await dataService.delete(`${API}producer/d/${id}`);
  return resData;
});


const initialState = {
  producers: null,
  producersCount: 0,
  chosenProducerId: null,
  chosenProducer: null,
  isProducerLoading: false
};


export const producerSlice = createSlice({
  name: 'producer',
  initialState,
  reducers: {
    setProducerId: (state, action) => {
      localStorage.setItem('chosenProducerId', action.payload);
      state.chosenProducerId = action.payload;
      state.chosenProducer = null;
    },
    setProducerLoading: (state, action) => {
      state.isProducerLoading = action.payload;
    },
    clearProducers: (state) => {
      state.producers = null;
      state.producersCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducers.fulfilled, (state, action) => {
        state.producers = action.payload.data;
      })
      // .addCase(getProducers.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getPaginatedProducers.fulfilled, (state, action) => {
        state.producers = action.payload.data;
      })
      // .addCase(getPaginatedProducers.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getProducersCount.fulfilled, (state, action) => {
        state.producersCount = action.payload.data;
      })
      // .addCase(getProducersCount.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getProducer.fulfilled, (state, action) => {
        state.chosenProducer = action.payload.data;
        state.isProducerLoading = false;
      })
      // .addCase(getProducer.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(createProducer.fulfilled, (state, action) => {
        state.chosenProducer = action.payload.data;
      })
      .addCase(getFilteredProducers.fulfilled, (state, action) => {
        state.producers = action.payload.data;
      })
      // .addCase(createProducer.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(updateProducer.fulfilled, (state, action) => {
        state.chosenProducer = action.payload.data;
      });
    // .addCase(updateProducer.rejected, (_, action) => {
    //   alert(`Operation failed - ${action.error.message}`);
    // });
  }
});

export const {
  setProducerId,
  setProducerLoading,
  clearProducers,
} = producerSlice.actions;

export default producerSlice.reducer;