/* eslint-disable react/prop-types */
import React from "react";
import { TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import { moveUnitsAvailableUnitsHeaders } from "../../../../static/tablesHeaders";
import CustomChip from "../../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../../static/static";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const MoveUnitItemDesktop = ({
  orderNumber,
  unit,
  chosenQuantity,
  changeQuantity,
  isAdded,
  addItem,
  buttonLock,
}) => {
  const {
    product: { name, partNumber, category, producer },
    serialNumber,
    status,
    quantity,
  } = unit;

  return (
    <TableDataGridWrapper headers={moveUnitsAvailableUnitsHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{partNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <CustomDataDisplay>{serialNumber}</CustomDataDisplay>
      <CustomDataDisplay>{category ? category.name : ""}</CustomDataDisplay>
      <CustomDataDisplay>{producer ? producer.name : ""}</CustomDataDisplay>
      <CustomChip label={status} sx={unitStatusChipStyles(status, "")} />
      <CustomDataDisplay>{quantity}</CustomDataDisplay>
      <TextField
        value={chosenQuantity}
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            max: quantity,
            style: {
              textAlign: "center",
            },
          },
        }}
        onChange={(e) => changeQuantity(e.target.value)}
        disabled={isAdded}
        variant="standard"
        sx={{ width: "4.375em", padding: 0 }}
      />
      <DetailsButton
        text="+"
        startIcon={<></>}
        disabled={buttonLock || isAdded}
        onClick={() => addItem()}
        styles={{}}
      />
    </TableDataGridWrapper>
  );
};

export default MoveUnitItemDesktop;
