import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import {jwtDecode} from 'jwt-decode';
import authService from '../auth/authService';
import {
  getErrorCode
} from '../static/functions';

const accessToken = authService.getAccessToken();
const initialState = accessToken ? {
  isLoggedIn: true,
  userData: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : jwtDecode(accessToken),
  loggingError: null
} : {
  isLoggedIn: false,
  userData: null,
  loggingError: null
};


export const login = createAsyncThunk('auth/login', async (data) => {
  const resData = await authService.login(data);
  return resData;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.userData = null;
    },
    setUserData: (state) => {
      state.userData = JSON.parse(localStorage.getItem("userData"));
    },
    clearLoggingError: (state) => {
      state.loggingError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.userData = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        const code = getErrorCode(action.error.message);
        if (code == 401)
          state.loggingError = `nieprawidłowy login lub hasło`;
      });
  }
});

export const {
  logout,
  setUserData,
  clearLoggingError
} = authSlice.actions;

export default authSlice.reducer;