/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box, TextField, MenuItem } from "@mui/material";

const Dropdown = ({
  collection,
  setValue,
  outsideValue = "",
  textFieldProps,
  optionsProps = {},
  itemProps = {},
  label = "",
  searchingAttribute,
  displayAttribute,
  shownValue = "",
  error = false,
  errorText = "",
  disabled = false,
  extraComponent = <Box />,
}) => {
  const [searchingValue, setSearchingValue] = useState(outsideValue);
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);
  const [isValueSet, setIsValueSet] = useState(false);

  const changingSearchingValue = (value) => {
    setValue("");
    setIsValueSet(false);
    setSearchingValue(value);
  };

  const clickOption = (value) => {
    setSearchingValue(value[shownValue]);
    setValue(value[searchingAttribute]);
    setAreOptionsVisible(false);
    setIsValueSet(true);
  };

  return (
    <Box
      sx={{ position: "relative", width: '100%'}}
      onBlur={() => window.setTimeout(() => setAreOptionsVisible(false), 200)}
    >
      <TextField
        value={searchingValue}
        onChange={(e) => changingSearchingValue(e.target.value)}
        onFocus={() => setAreOptionsVisible(true)}
        label={label}
        error={error}
        helperText={error && errorText}
        sx={textFieldProps}
        disabled={disabled}
        fullWidth
      />
      {collection !== null &&
        collection !== undefined &&
        areOptionsVisible &&
        !isValueSet && (
          <Box
            sx={{
              ...optionsProps,
              position: "absolute",
              bgcolor: "white",
              zIndex: "9999",
              opacity: "1",
              width: "100%",
            }}
          >
            {collection
              .filter((item) =>
                item[displayAttribute]
                  .toString()
                  .toLowerCase()
                  .includes(searchingValue.toLowerCase())
              )
              .sort((a, b) => {
                if (a[displayAttribute] > b[displayAttribute]) return 1;
                if (a[displayAttribute] < b[displayAttribute]) return -1;
                return 0;
              })
              .filter((_, index) => index < 5)
              .map((item, index) => (
                <MenuItem
                  key={index}
                  value={item[searchingAttribute]}
                  onClick={() => clickOption(item)}
                  sx={{
                    ...itemProps,
                    border: "0.0625em solid #CCC",
                    "&:hover": {
                      color: "#BBB",
                    },
                  }}
                >
                  {item[displayAttribute]}
                </MenuItem>
              ))}
            {extraComponent}
          </Box>
        )}
    </Box>
  );
};

export default Dropdown;
