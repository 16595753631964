/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchDropdown from "../../../SearchDropdown/SearchDropdown";
import CustomTestField from "../../../CustomTextField/CustomTextField";
import {
  clearCommissionCodes,
  getCommissionFilteredOpenCodes,
} from "../../../../slices/commissionSlice";
import { useDispatch } from "react-redux";

const CustomWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const WarningText = styled(Typography)(() => ({
  fontSize: "0.8rem",
  marginTop: "1.25rem",
  marginBottom: "1.25rem",
  color: "red",
}));

const CustomTitle = styled(Typography)(() => ({
  fontSize: "1.4rem",
  marginBottom: "1rem",
  fontWeight: 700,
}));

const CommissionDetailsPage = ({ commissionDetails, setCommissionDetails }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearCommissionCodes());
  });

  return (
    <CustomWrapper>
      <CustomTitle>4. Uzupełnij szczegóły zlecenia</CustomTitle>
      <WarningText>Pola oznaczone * są obowiązkowe</WarningText>
      <>
        <SearchDropdown
          collectionName="commissionOpenCodes"
          sliceName="commission"
          getDataFunction={getCommissionFilteredOpenCodes}
          placeholder="Kod błędu* ..."
          outerValue={
            commissionDetails.errorCode
              ? commissionDetails.errorCode.name !== undefined
                ? commissionDetails.errorCode.name
                : ""
              : ""
          }
          setFunction={(errorCode) =>
            setCommissionDetails((state) => {
              return { ...state, errorCode };
            })
          }
          propertyToDisplay="name"
          propsWidth="60%"
        />
        <CustomTestField
          value={commissionDetails.description}
          onChange={(e) =>
            setCommissionDetails((state) => {
              return { ...state, description: e.target.value };
            })
          }
          placeholder="Opis problemu*"
          multiline
          minRows={10}
          fullWidth
          sx={{ marginTop: "0.5rem" }}
          maxLength={1000}
          width="60%"
        />
      </>
    </CustomWrapper>
  );
};

export default CommissionDetailsPage;
