/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from "react";
import SectionWrapper from "../SectionWrapper/SectionWrapper";
import PageHeader from "../PageHeader/PageHeader";
import { Info, MoveToInbox } from "@mui/icons-material";
import AdditionalFunctionalitiesWrapper from "../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import CustomSearchField from "../CustomSearchField/CustomSearchField";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import MobileDataSorter from "../MobileDataSorter/MobileDataSorter";
import { useSelector, useDispatch } from "react-redux";
import { getLocalTimeDate } from "../../static/functions";
import {
  clearShipmentsByDestination,
  getPaginatedShipmentsByDestination,
  getShipmentsByDestinationCount,
} from "../../slices/shipmentSlice";
import TableHeader from "../TableHeader/TableHeader";
import Pagination from "../Pagination/Pagination";
import Loading from "../Loading/Loading";
import EmptyListError from "../EmptyListError/EmptyListError";
import authService from "../../auth/authService";
import { ShipmentsSummaryHeaders } from "../../static/tablesHeaders";
import ShipmentsSummaryItem from "./ShipmentsSummaryItem";
import TableWrapper from "../TableWrapper/TableWrapper";
import usePagination from "../../hooks/usePagination";

const ShipmentsSummaryView = ({ type = "destination" }) => {
  const dispatch = useDispatch();
  const { shipmentsByDestination, shipmentsByDestinationCount } = useSelector(
    (slice) => slice.shipment
  );
  const searchingRef = useRef("");
  const {
    refresh,
    setRefresh,
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("creationDate", "descending");
  const [creationDates, setCreationDates] = useState("");

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getShipmentsByDestinationCount({
        searchingValue,
        status: ["Created", "In progress", "To Explain"],
        baseStorage: "",
        destinationStorage: "",
        creationDateStart: creationDates.start
          ? getLocalTimeDate(creationDates.start)
          : "",
        creationDateEnd: creationDates.end
          ? getLocalTimeDate(creationDates.end)
          : "",
        sendDatesStart: "",
        sendDatesEnd: "",
        deliveredDatesStart: "",
        deliveredDatesEnd: "",
        sender:
          type === "base" ? authService.getDecodedAccessToken().user_id : "",
        receiver:
          type === "destination"
            ? authService.getDecodedAccessToken().user_id
            : "",
      })
    );
    setIsDataLoading(false);
  }, [searchingValue, creationDates.start, creationDates.end, refresh]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedShipmentsByDestination({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        status: ["Created", "In progress", "To Explain"],
        baseStorage: "",
        destinationStorage: "",
        creationDateStart: creationDates.start
          ? getLocalTimeDate(creationDates.start)
          : "",
        creationDateEnd: creationDates.end
          ? getLocalTimeDate(creationDates.end)
          : "",
        sendDatesStart: "",
        sendDatesEnd: "",
        deliveredDatesStart: "",
        deliveredDatesEnd: "",
        sender:
          type === "base" ? authService.getDecodedAccessToken().user_id : "",
        receiver:
          type === "destination"
            ? authService.getDecodedAccessToken().user_id
            : "",
      })
    );
    setIsDataLoading(false);
  }, [
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    creationDates.start,
    creationDates.end,
    refresh,
  ]);

  const setCreationDateInterval = (dates) => {
    const [startDate, endDate] = dates;
    setCreationDates({
      start: startDate,
      end: endDate,
    });
  };

  useEffect(() => { loadDataCount(); }, [loadDataCount]);

  useEffect(() => { loadData(); }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearShipmentsByDestination());
  }, []);

  return (
    <SectionWrapper>
      <PageHeader
        icon={<MoveToInbox />}
        titleText={
          <>
            <span>Przesyłki przychodzące</span>
            <span style={{ marginLeft: "0.3rem" }}>{`[`}</span>
            <span
              style={{
                color: shipmentsByDestinationCount > 0 ? "red" : "green",
              }}
            >
              {`${shipmentsByDestinationCount}`}
            </span>
            <span>{`]`}</span>
          </>
        }
        size="medium"
      />
      <AdditionalFunctionalitiesWrapper>
        <CustomSearchField
          displaySearchingValue={displaySearchingValue}
          setDisplaySearchingValue={setDisplaySearchingValue}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
          searchingRef={searchingRef}
          placeholder="szukaj przesyłek..."
          width="20%"
        />
        <CustomDatePicker
          labelName="Data wysłania"
          datesRange={creationDates}
          setDatesRange={(dates) => setCreationDateInterval(dates)}
          maxDate={new Date()}
        />
        <MobileDataSorter
          tableHeader={ShipmentsSummaryHeaders}
          setOuterSorter={setSorter}
          setOuterSorting={setSorting}
          defaultSorter="creationDate"
        />
      </AdditionalFunctionalitiesWrapper>
      <TableWrapper>
        <TableHeader
          setSorter={setSorter}
          setSorting={setSorting}
          headers={ShipmentsSummaryHeaders}
          gap="1%"
        />
        <Pagination
          collectionSize={shipmentsByDestinationCount}
          leftIndex={leftIndex}
          rightIndex={rightIndex}
          setLeftIndex={setLeftIndex}
          setRightIndex={setRightIndex}
        >
          {isDataLoading ? (
            <Loading />
          ) : (
            <>
              {shipmentsByDestination !== null &&
                shipmentsByDestination.map((shipment, id) => (
                  <ShipmentsSummaryItem
                    orderNumber={leftIndex + id + 1}
                    key={shipment.id}
                    shipment={shipment}
                    setRefresh={setRefresh}
                  />
                ))}
            </>
          )}
          {shipmentsByDestination !== null &&
            shipmentsByDestination.length === 0 && (
              <EmptyListError
                message="Brak przesyłek przychodzących"
                icon={<Info />}
              />
            )}
        </Pagination>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default ShipmentsSummaryView;
