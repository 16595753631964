/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../../hooks/useMediaQ";
import UnitCheckSectionItemDesktop from "./UnitCheckSectionItemVersions/UnitCheckSectionItemDesktop";
import UnitCheckSectionItemMobile from "./UnitCheckSectionItemVersions/UnitCheckSectionItemMobile";

const UnitCheckSectionItem = ({ unit, report, setReport, orderNumber }) => {
  const { isDesktopDisplay } = useMediaQ();
  return (
    <>
      {isDesktopDisplay ? (
        <UnitCheckSectionItemDesktop
          unit={unit}
          report={report}
          setReport={setReport}
          orderNumber={orderNumber}
        />
      ) : (
        <UnitCheckSectionItemMobile
          unit={unit}
          report={report}
          setReport={setReport}
          orderNumber={orderNumber}
        />
      )}
    </>
  );
};

export default UnitCheckSectionItem;
