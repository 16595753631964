import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API
} from '../static/static';

//--------------------------Location API---------------------------------------------
export const getExternalLocations = createAsyncThunk('locations/get/external', async () => {
  const resData = await dataService.get(`${API}locations/external`);
  return resData;
});

export const getLocations = createAsyncThunk('locations/get/internal', async () => {
  const resData = await dataService.get(`${API}locations`);
  return resData;
});

export const getLocation = createAsyncThunk('location/get', async (_, {
  getState
}) => {
  const {
    chosenLocationId
  } = getState().commission;
  const resData = chosenLocationId ? await dataService.get(`${API}location/${chosenLocationId}`) : await dataService.get(`${API}location/${localStorage.getItem('chosenLocationId')}`);
  return resData;
});


export const getCommissions = createAsyncThunk('commissions/alert', async () => {
  const resData = await dataService.get(`${API}commissions`);
  return resData;
});


export const getTasks = createAsyncThunk('tasks/alert', async () => {
  const resData = await dataService.get(`${API}tasks`);
  return resData;
});


const initialState = {
  commissions: null,
  tasks: null,
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    resetState: (state) => {
      state.commissions = null;
      state.tasks = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommissions.fulfilled, (state, action) => {
        state.commissions = action.payload.data;
      })
      // .addCase(getCommissions.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.tasks = action.payload.data;
      });
      // .addCase(getTasks.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // });
  }
});

export const {
  resetState
} = alertSlice.actions;

export default alertSlice.reducer;