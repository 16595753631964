/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../../hooks/useMediaQ";
import LocationItemDesktop from "./LocationItemVersions/LocationItemDesktop";
import LocationItemMobile from "./LocationItemVersions/LocationItemMobile";

const LocationItem = ({ orderNumber, location }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <LocationItemDesktop location={location} orderNumber={orderNumber} />
      ) : (
        <LocationItemMobile location={location} orderNumber={orderNumber} />
      )}
    </>
  );
};

export default LocationItem;
