/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useCallback } from "react";
import { AddLocation, LocationOn, Warning } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../Loading/Loading";
import Pagination from "../../../Pagination/Pagination";
import {
  clearLocations,
  getLocationsCount,
  getPaginatedLocations,
} from "../../../../slices/locationSlice";
import LocationItem from "../LocationItem/LocationItem";
import PageHeader from "../../../PageHeader/PageHeader";
import { DetailsButton } from "../../../FunctionalButtons/FunctionalButtons";
import CustomSearchField from "../../../CustomSearchField/CustomSearchField";
import TableHeader from "../../../TableHeader/TableHeader";
import { locationsHeaders } from "../../../../static/tablesHeaders";
import EmptyListError from "../../../EmptyListError/EmptyListError";
import { useNavigate } from "react-router-dom";
import { setChosenCompanyId } from "../../../../slices/companySlice";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import MobileDataSorter from "../../../MobileDataSorter/MobileDataSorter";
import TableWrapper from "../../../TableWrapper/TableWrapper";
import usePagination from "../../../../hooks/usePagination";

const Locations = ({ companyId, refresh }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchingRef = useRef("");
  const {
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("code", "ascending");

  const { locations, locationsCount } = useSelector((slice) => slice.location);

  const loadDataCount = useCallback(async () => {
    await dispatch(
      getLocationsCount({
        searchingValue,
        company: companyId,
        createdBy: "",
        lastModifiedBy: "",
        creationDateStart: "",
        creationDateEnd: "",
        lastModificationDateStart: "",
        lastModificationDateEnd: "",
        isSynchronized: "",
      })
    );
  }, [searchingValue, refresh]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedLocations({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        company: companyId,
        createdBy: "",
        lastModifiedBy: "",
        creationDateStart: "",
        creationDateEnd: "",
        lastModificationDateStart: "",
        lastModificationDateEnd: "",
        isSynchronized: "",
      })
    );
    setIsDataLoading(false);
  }, [leftIndex, rightIndex, sorter, sorting, searchingValue, refresh]);

  useEffect(() => {
    loadDataCount();
  }, [loadDataCount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearLocations());
  }, []);

  return (
    <SectionWrapper>
      <PageHeader
        titleText="Lokalizacje"
        icon={<LocationOn />}
        size="medium"
        onRightComponent={
          <DetailsButton
            text="Dodaj lokalizacje"
            startIcon={<AddLocation />}
            onClick={() => {
              dispatch(setChosenCompanyId(companyId));
              navigate("/location-add");
            }}
          />
        }
      />
      <AdditionalFunctionalitiesWrapper>
        <CustomSearchField
          displaySearchingValue={displaySearchingValue}
          setDisplaySearchingValue={setDisplaySearchingValue}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
          searchingRef={searchingRef}
          placeholder="szukaj lokalizacji..."
          width="30%"
        />
        <MobileDataSorter
          tableHeader={locationsHeaders}
          setOuterSorter={setSorter}
          setOuterSorting={setSorting}
          defaultSorter="code"
        />
      </AdditionalFunctionalitiesWrapper>
      <TableWrapper>
        <TableHeader
          headers={locationsHeaders}
          setSorter={setSorter}
          setSorting={setSorting}
          gap="1%"
        />
        <Pagination
          collectionSize={locationsCount}
          leftIndex={leftIndex}
          rightIndex={rightIndex}
          setLeftIndex={setLeftIndex}
          setRightIndex={setRightIndex}
        >
          {isDataLoading ? (
            <Loading />
          ) : (
            <>
              {locations !== null &&
                locations.map((location, id) => (
                  <LocationItem
                    key={location.code}
                    location={location}
                    orderNumber={leftIndex + id + 1}
                  />
                ))}
            </>
          )}
          {locations !== null && locations.length === 0 && (
            <EmptyListError message="Brak lokalizacji" icon={<Warning />} />
          )}
        </Pagination>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default Locations;
