/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getStorage, setStorageId } from "../../../../slices/storageSlice";
import {
  clearShipments,
  getPaginatedShipments,
  getShipmentsCount,
} from "../../../../slices/shipmentSlice";
import ShipmentItem from "../ShipmentItem";
import Pagination from "../../../Pagination/Pagination";
import { Add, Warning } from "@mui/icons-material";
import {
  DEFAULT_DISPLAY_ITEMS_QUANTITY,
  shipmentStatusChipStyles,
} from "../../../../static/static";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Loading/Loading";
import { Inventory } from "@mui/icons-material";
import EmptyListError from "../../../EmptyListError/EmptyListError";
import CustomSearchField from "../../../CustomSearchField/CustomSearchField";
import CustomDatePicker from "../../../CustomDatePicker/CustomDatePicker";
import BlankSpace from "../../../BlankSpace/BlankSpace";
import StorageDetails from "../../../Storage/StorageDetails";
import { DetailsButton } from "../../../FunctionalButtons/FunctionalButtons";
import TableHeader from "../../../TableHeader/TableHeader";
import { ShipmentsHeaders } from "../../../../static/tablesHeaders";
import ChipSet from "../../../ChipSet/ChipSet";
import SwitchTab from "../../../SwitchTab/SwitchTab";
import PageHeader from "../../../PageHeader/PageHeader";
import RefreshButton from "../../../RefreshButton/RefreshButton";
import { getLocalTimeDate } from "../../../../static/functions";
import PageWrapper from "../../../PageWrapper/PageWrapper";
import AdditionalFunctionalitiesWrapper from "../../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";
import MobileDataSorter from "../../../MobileDataSorter/MobileDataSorter";
import ReturnButton from "../../../ReturnButton/ReturnButton";

const chipSet = [
  {
    label: "Wszystkie",
    keyValue: "",
  },
  {
    label: "Nowe",
    keyValue: "Created",
  },
  {
    label: "W dostarczeniu",
    keyValue: "In progress",
  },
  {
    label: "Dostarczone",
    keyValue: "Delivered",
  },
];

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "0.5rem",
}));

const DatePickerWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "0.5rem",
  width: "50%",
  justifyContent: "center",
}));

const ShipmentsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chosenStorage } = useSelector((slice) => slice.storage);
  const {
    shipments: { all: allShipments },
    shipmentsCount,
  } = useSelector((slice) => slice.shipment);
  const searchingRef = useRef("");
  const [refresh, setRefresh] = useState(false);
  const [shipmentsKind, setShipmentsKind] = useState("incoming");
  const [sorter, setSorter] = useState("creationDate");
  const [sorting, setSorting] = useState("descending");
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(DEFAULT_DISPLAY_ITEMS_QUANTITY);
  const [searchingValue, setSearchingValue] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [displaySearchingValue, setDisplaySearchingValue] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [creationDates, setCreationDates] = useState({ start: "", end: "" });
  const [sendDates, setSendDates] = useState({ start: "", end: "" });
  const [deliveredDates, setDeliveredDates] = useState({ start: "", end: "" });

  const shipmentDatesTypes = [
    {
      label: "Data utworzenia: ",
      state: creationDates,
      stateFunction: setCreationDates,
    },
    {
      label: "Data wysłania: ",
      state: sendDates,
      stateFunction: setSendDates,
    },
    {
      label: "Data otrzymania: ",
      state: deliveredDates,
      stateFunction: setDeliveredDates,
    },
  ];

  const setDateInterval = (dates, setFunction) => {
    const [startDate, endDate] = dates;
    setFunction({
      start: startDate,
      end: endDate,
    });
  };

  const loadDataCount = useCallback(async () => {
    if (chosenStorage !== null) {
      setIsDataLoading(true);
      await dispatch(
        getShipmentsCount({
          searchingValue,
          status: filteredValue,
          baseStorage: shipmentsKind === "incoming" ? "" : chosenStorage.id,
          destinationStorage:
            shipmentsKind === "incoming" ? chosenStorage.id : "",
          creationDateStart: creationDates.start
            ? getLocalTimeDate(creationDates.start)
            : "",
          creationDateEnd: creationDates.end
            ? getLocalTimeDate(creationDates.end)
            : "",
          sendDatesStart: sendDates.start
            ? getLocalTimeDate(sendDates.start)
            : "",
          sendDatesEnd: sendDates.end ? getLocalTimeDate(sendDates.end) : "",
          deliveredDatesStart: deliveredDates.start
            ? getLocalTimeDate(deliveredDates.start)
            : "",
          deliveredDatesEnd: deliveredDates.end
            ? getLocalTimeDate(deliveredDates.end)
            : "",
          sender: "",
          receiver: ""
        })
      );
    }
  }, [
    searchingValue,
    shipmentsKind,
    chosenStorage,
    filteredValue,
    refresh,
    creationDates.start,
    creationDates.end,
    sendDates.start,
    sendDates.end,
    deliveredDates.start,
    deliveredDates.end,
  ]);

  const loadData = useCallback(
    chosenStorage !== null
      ? async () => {
          await dispatch(
            getPaginatedShipments({
              leftIndex,
              rightIndex,
              sortingParam: sorter,
              sortingOrder: sorting,
              searchingValue,
              status: filteredValue,
              baseStorage: shipmentsKind === "incoming" ? "" : chosenStorage.id,
              destinationStorage:
                shipmentsKind === "incoming" ? chosenStorage.id : "",
              creationDateStart: creationDates.start
                ? getLocalTimeDate(creationDates.start)
                : "",
              creationDateEnd: creationDates.end
                ? getLocalTimeDate(creationDates.end)
                : "",
              sendDatesStart: sendDates.start
                ? getLocalTimeDate(sendDates.start)
                : "",
              sendDatesEnd: sendDates.end
                ? getLocalTimeDate(sendDates.end)
                : "",
              deliveredDatesStart: deliveredDates.start
                ? getLocalTimeDate(deliveredDates.start)
                : "",
              deliveredDatesEnd: deliveredDates.end
                ? getLocalTimeDate(deliveredDates.end)
                : "",
              sender: "",
              receiver: "",
            })
          );
          setIsDataLoading(false);
        }
      : () => {},
    [
      leftIndex,
      rightIndex,
      sorter,
      sorting,
      searchingValue,
      shipmentsKind,
      chosenStorage,
      filteredValue,
      refresh,
      creationDates.start,
      creationDates.end,
      sendDates.start,
      sendDates.end,
      deliveredDates.start,
      deliveredDates.end,
    ]
  );

  const getDataStorage = useCallback(async () => {
    await dispatch(getStorage());
  }, []);

  useEffect(() => {
    getDataStorage();
    return () => dispatch(clearShipments());
  }, []);

  useEffect(() => {
    loadDataCount();
  }, [loadDataCount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <PageWrapper width="95%">
      {chosenStorage !== null ? (
        <>
          <PageHeader
            titleText="Historia przesyłek"
            icon={<Inventory />}
            onRightComponent={
              <ButtonWrapper>
                <DetailsButton
                  text="Utwórz przesyłkę"
                  onClick={() => {
                    dispatch(setStorageId(chosenStorage.id));
                    navigate("/units-move");
                  }}
                  startIcon={<Add />}
                />
                <ReturnButton />
              </ButtonWrapper>
            }
          >
            <RefreshButton onClick={() => setRefresh((state) => !state)} />
          </PageHeader>
          <BlankSpace />
          <StorageDetails
            type={chosenStorage.type}
            chosenStorage={chosenStorage}
          />
          <BlankSpace />
          <SectionWrapper>
            <AdditionalFunctionalitiesWrapper>
              <ButtonWrapper>
                <SwitchTab
                  text="Przychodzące"
                  onClick={() => setShipmentsKind("incoming")}
                  selected={shipmentsKind !== "incoming"}
                />
                <SwitchTab
                  text="Wychodzące"
                  onClick={() => setShipmentsKind("outcoming")}
                  selected={shipmentsKind === "incoming"}
                />
              </ButtonWrapper>
            </AdditionalFunctionalitiesWrapper>
            <AdditionalFunctionalitiesWrapper>
              <CustomSearchField
                displaySearchingValue={displaySearchingValue}
                setDisplaySearchingValue={setDisplaySearchingValue}
                searchingValue={searchingValue}
                setSearchingValue={setSearchingValue}
                searchingRef={searchingRef}
                placeholder="szukaj przesyłki..."
                width="20%"
              />
              <DatePickerWrapper>
                {shipmentDatesTypes.map(
                  ({ label, state, stateFunction }, index) => (
                    <CustomDatePicker
                      key={index}
                      labelName={label}
                      datesRange={state}
                      setDatesRange={(dates) =>
                        setDateInterval(dates, stateFunction)
                      }
                      maxDate={new Date()}
                      width="30%"
                      mobileWidth="30%"
                    />
                  )
                )}
              </DatePickerWrapper>
              <ChipSet
                mainLabel="Status:"
                set={chipSet}
                chipStyles={shipmentStatusChipStyles}
                setFunction={setFilteredValue}
                actualValue={filteredValue}
              />
              <MobileDataSorter
                tableHeader={ShipmentsHeaders}
                setOuterSorter={setSorter}
                setOuterSorting={setSorting}
                defaultSorter="creationDate"
              />
            </AdditionalFunctionalitiesWrapper>
            <TableHeader
              headers={ShipmentsHeaders}
              setSorter={setSorter}
              setSorting={setSorting}
              gap="0.5rem"
            />
            <Pagination
              collectionSize={shipmentsCount}
              leftIndex={leftIndex}
              rightIndex={rightIndex}
              setLeftIndex={setLeftIndex}
              setRightIndex={setRightIndex}
            >
              {isDataLoading ? (
                <Loading />
              ) : (
                <>
                  {allShipments !== null &&
                    allShipments.map((shipment, index) => (
                      <ShipmentItem
                        orderNumber={index + 1}
                        key={shipment.id}
                        shipment={shipment}
                        refresh={() => setRefresh((state) => !state)}
                      />
                    ))}
                </>
              )}
              {allShipments !== null && allShipments.length === 0 && (
                <EmptyListError message="Brak przesyłek" icon={<Warning />} />
              )}
            </Pagination>
          </SectionWrapper>
        </>
      ) : (
        <Box>
          <EmptyListError
            message="Brak wskazanego magazynu"
            icon={<Warning />}
          />
        </Box>
      )}
    </PageWrapper>
  );
};

export default ShipmentsList;
