/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import CompactDataDisplay from "../../../../CompactDataDisplay/CompactDataDisplay";
import CustomChip from "../../../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../../../static/static";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { Check, Clear, Warning } from "@mui/icons-material";
import DetailsButton from "../../../../DetailsButton/DetailsButton";
import FaultModal from "../FaultModal/FaultModal";

const FaultInfoWrapper = styled(Box)(() => ({
  display: "flex",
  border: "0.0625rem dotted red",
  alignItems: "center",
  gap: "1rem",
}));

const UnitCheckSectionItemMobile = ({
  orderNumber,
  unit,
  report,
  setReport,
}) => {

  const {
    product: { name, partNumber, type },
    quantity,
    serialNumber,
    status,
  } = unit;

  const [correctness, setCorrectness] = useState({
    status: null,
    realUnits: null,
    positiveUnits: null,
    negativeUnits: null,
    serialNumber: "",
    comments: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const acceptCorrectness = () => {
    setCorrectness({
      status: true,
      realUnits: quantity,
      positiveUnits: quantity,
      negativeUnits: 0,
      serialNumber: serialNumber,
      comments: "",
    });
    setReport((prevState) => [
      ...prevState,
      {
        id: unit.id,
        status: true,
        realUnits: quantity,
        positiveUnits: quantity,
        negativeUnits: 0,
        serialNumber: serialNumber,
        comments: "",
      },
    ]);
  };

  const changeCorrectness = () => {
    if (correctness.status === true) {
      setCorrectness({
        status: null,
        realUnits: 0,
        positiveUnits: 0,
        negativeUnits: 0,
        comments: "",
      });
      const reportCopy = [...report];
      const findingIndex = reportCopy.findIndex(
        (element) => element.id === unit.id
      );
      if (findingIndex * 1 !== -1) {
        reportCopy.splice(findingIndex, 1);
        setReport(reportCopy);
      }
    }
    if (correctness.status === false) {
      setIsModalOpen(true);
    }
  };

  const declineCorrectness = () => {
    setIsModalOpen(true);
  };

  const data = [
    { title: "Nazwa:", value: name },
    { title: "PartNumber:", value: partNumber },
    { title: "Nr seryjny:", value: serialNumber },
    {
      title: "Liczba:",
      value: quantity,
    },
    {
      title: "Status:",
      value: (
        <CustomChip label={status} sx={unitStatusChipStyles(status, "")} width="30%"/>
      ),
    },
    {
      title: "Zmienione dane:",
      value: correctness.status === false && (
        <FaultInfoWrapper>
          <Warning />
          <Typography sx={{ flexBasis: "15%", fontSize: "0.7rem" }}>
            <span style={{ fontWeight: 700 }}>Rzecz. licz.:</span>
            <span style={{ marginLeft: "0.5rem" }}>
              {correctness.realUnits}
            </span>
          </Typography>
          <Typography sx={{ flexBasis: "15%", fontSize: "0.7rem" }}>
            <span style={{ fontWeight: 700 }}> Licz. prawidł.:</span>
            <span style={{ marginLeft: "0.5rem" }}>
              {correctness.positiveUnits}
            </span>
          </Typography>
          <Typography sx={{ flexBasis: "15%", fontSize: "0.7rem" }}>
            <span style={{ fontWeight: 700 }}> Licz. niepraw.:</span>
            <span style={{ marginLeft: "0.5rem" }}>
              {correctness.negativeUnits}
            </span>
          </Typography>
          {type === "Quantitative" && (
            <Typography sx={{ flexBasis: "0.5fr", fontSize: "0.7rem" }}>
              <span style={{ fontWeight: 700 }}> Nr ser.:</span>
              <span style={{ marginLeft: "0.5rem" }}>
                {correctness.serialNumber}
              </span>
            </Typography>
          )}
          <Typography sx={{ flexBasis: "0.5fr", fontSize: "0.7rem" }}>
            <span style={{ fontWeight: 700 }}> Uwagi:</span>
            <span style={{ marginLeft: "0.5rem" }}>{correctness.comments}</span>
          </Typography>
        </FaultInfoWrapper>
      ),
    },
  ];
  return (
    <>
      <CompactDataDisplay
        title={`Produkt #${orderNumber}`}
        data={data}
        actions={
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => acceptCorrectness()}
                disabled={correctness.status !== null}
              >
                <Check
                  sx={correctness.status === true ? { color: "green" } : {}}
                />
              </IconButton>
              <IconButton
                disabled={correctness.status !== null}
                onClick={() => declineCorrectness()}
              >
                <Clear
                  sx={correctness.status === false ? { color: "red" } : {}}
                />
              </IconButton>
              <DetailsButton
                text="zmień"
                disabled={correctness.status === null}
                onClick={() => changeCorrectness()}
              />
            </Box>
          </>
        }
      />
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <FaultModal
          unit={unit}
          setCorrectness={setCorrectness}
          setIsModalOpen={setIsModalOpen}
          correctness={correctness.status === false ? correctness : null}
          report={report}
          setReport={setReport}
        />
      </Dialog>
    </>
  );
};

export default UnitCheckSectionItemMobile;
