/* eslint-disable react/prop-types */
import React, { useState } from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import CommissionCodeItemDesktop from "./CommissionCodeItemVersions/CommissionCodeItemDesktop";
import CommissionCodeItemMobile from "./CommissionCodeItemVersions/CommissionCodeItemMobile";
import { Dialog } from "@mui/material";
import CommissionCodeForm from "../CommissionCodeForm/CommissionCodeForm";

const CommissionCodeItem = ({
  orderNumber,
  codeData,
  setRefresh,
  codeType,
}) => {
  const { isDesktopDisplay } = useMediaQ();
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);

  return (
    <>
      {isDesktopDisplay ? (
        <CommissionCodeItemDesktop
          orderNumber={orderNumber}
          codeData={codeData}
          setRefresh={setRefresh}
          codeType={codeType}
          setIsEditModalOpened={setIsEditModalOpened}
        />
      ) : (
        <CommissionCodeItemMobile
          codeData={codeData}
          setRefresh={setRefresh}
          codeType={codeType}
          setIsEditModalOpened={setIsEditModalOpened}
        />
      )}
      <Dialog
        open={isEditModalOpened}
        onClose={() => setIsEditModalOpened(false)}
      >
        <CommissionCodeForm
          mode="edit"
          closeModal={() => setIsEditModalOpened(false)}
          codeId={codeData.id}
          setRefresh={setRefresh}
          codeType={codeType}
        />
      </Dialog>
    </>
  );
};

export default CommissionCodeItem;
