/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addLocation,
  getLocation,
  updateLocation,
} from "../../slices/locationSlice";
import PageHeader from "../PageHeader/PageHeader";
import { Add, Clear, Edit, KeyboardArrowLeft } from "@mui/icons-material";
import AcceptButton from "../AcceptButton/AcceptButton";
import EditButton from "../EditButton/EditButton";
import { DetailsButton } from "../FunctionalButtons/FunctionalButtons";
import { useNavigate } from "react-router-dom";
import { getCompany } from "../../slices/companySlice";
import Loading from "../Loading/Loading";
import authService from "../../auth/authService";

const LocationFormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "10rem auto 0 auto",
  width: "40rem",
  alignItems: "center",
  justifyContent: "center",
  border: "0.0625em solid #ddd",
  padding: "0.0625rem",
  borderRadius: "0.125rem",
  backgroundColor: "white",
  [theme.breakpoints.down("tablet")]: {
    width: "20rem",
    margin: "1rem auto 1rem auto",
    padding: "0.125rem",
  },
}));

const ColumnFormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "5rem",
  [theme.breakpoints.down("tablet")]: {
    gap: "0.5rem",
    flexDirection: "column",
  },
}));

const TextFieldsWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "1rem",
  gap: "1.5rem",
  justifyContent: "center",
  marginBottom: "0.5rem",
  [theme.breakpoints.down("laptop")]: {
    marginTop: "0.5rem",
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "14rem",
  [theme.breakpoints.down("tablet")]: {
    width: "90%",
  },
}));

const LocationForm = ({ type = "add" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chosenCompany } = useSelector((slice) => slice.company);
  const { chosenLocation } = useSelector((slice) => slice.location);
  const [newLocationData, setNewLocationData] = useState({
    code: "",
    name: "",
    contactPhone: "",
    contactPerson: "",
    street: "",
    city: "",
    country: "Polska",
    province: "",
    postcode: "",
  });
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [buttonLock, setButtonLock] = useState(false);
  const [formErrors, setFormErrors] = useState({
    code: false,
    name: false,
    contactPhone: false,
    contactPerson: false,
    street: false,
    city: false,
    country: false,
    province: false,
    postcode: false,
  });

  const getCompanyData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getCompany());
    setIsDataLoading(false);
  }, []);

  const getLocationData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getLocation());
    setIsDataLoading(false);
  }, []);

  const createLocation = useCallback(async () => {
    const data = {
      ...newLocationData,
      companyId: chosenCompany.id,
      createdBy: authService.getDecodedAccessToken().user_id,
    };
    await dispatch(addLocation(data));
    navigate(-1);
  }, [newLocationData]);

  const editLocation = useCallback(async () => {
    const data = {
      ...newLocationData,
      companyId: chosenLocation.company.id,
      lastModifiedBy: authService.getDecodedAccessToken().user_id,
    };
    await dispatch(updateLocation(data));
    navigate(-1);
  }, [newLocationData]);

  const isFormDataCompleted = () => {
    return !(
      !newLocationData.code ||
      !newLocationData.name ||
      !newLocationData.contactPhone ||
      !newLocationData.contactPerson ||
      !newLocationData.street ||
      !newLocationData.city ||
      !newLocationData.province ||
      !newLocationData.country ||
      !newLocationData.postcode
    );
  };

  const submitForm = useCallback(() => {
    if (!isFormDataCompleted()) {
      if (newLocationData.code.length == 0)
        setFormErrors((state) => {
          return { ...state, code: true };
        });
      if (newLocationData.name.length == 0)
        setFormErrors((state) => {
          return { ...state, name: true };
        });
      if (newLocationData.contactPhone.length == 0)
        setFormErrors((state) => {
          return { ...state, contactPhone: true };
        });
      if (newLocationData.contactPerson.length == 0)
        setFormErrors((state) => {
          return { ...state, contactPerson: true };
        });
      if (newLocationData.street.length == 0)
        setFormErrors((state) => {
          return { ...state, street: true };
        });
      if (newLocationData.city.length == 0)
        setFormErrors((state) => {
          return { ...state, city: true };
        });
      if (newLocationData.country.length == 0)
        setFormErrors((state) => {
          return { ...state, country: true };
        });
      if (newLocationData.province.length == 0)
        setFormErrors((state) => {
          return { ...state, province: true };
        });
      if (newLocationData.postcode.length == 0)
        setFormErrors((state) => {
          return { ...state, postcode: true };
        });
      return;
    }
    if (type === "add") createLocation();
    else {
      editLocation();
    }
  }, [createLocation, editLocation, newLocationData]);

  useEffect(() => {
    if (type === "add") getCompanyData();
  }, [getCompanyData]);

  useEffect(() => {
    if (type === "edit") {
      getLocationData();
    }
  }, [getLocationData]);

  useEffect(() => {
    if (type === "edit" && chosenLocation !== null) {
      setNewLocationData({
        code: chosenLocation.code,
        name: chosenLocation.name,
        contactPhone: chosenLocation.contactPhone,
        contactPerson: chosenLocation.contactPerson,
        street: chosenLocation.address.street,
        city: chosenLocation.address.city,
        country: chosenLocation.address.country,
        province: chosenLocation.address.province,
        postcode: chosenLocation.address.postcode,
      });
    }
  }, [type, chosenLocation]);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (keyCode === 13 && !buttonLock && isFormDataCompleted()) {
        submitForm();
        setButtonLock(true);
        setTimeout(() => setButtonLock(false), 5000);
      }
    },
    [buttonLock, submitForm]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  if (isDataLoading) return <Loading />;

  return (
    <LocationFormWrapper>
      <PageHeader
        titleText={type === "add" ? "Utwórz lokalizację" : "Edytuj lokalizację"}
        icon={type === "add" ? <Add /> : <Edit />}
        size="medium"
        onRightComponent={
          <DetailsButton
            text="Powrót"
            startIcon={<KeyboardArrowLeft />}
            onClick={() => navigate(-1)}
          />
        }
      />
      <Typography
        sx={{ fontSize: "0.8rem", marginBottom: "1.25rem", color: "red" }}
      >
        Pola oznaczone * są obowiązkowe
      </Typography>
      <ColumnFormWrapper>
        <TextFieldsWrapper>
          <CustomTextField
            label="Kod lokalizacji*"
            value={newLocationData.code}
            onChange={(e) => {
              setNewLocationData((state) => ({
                ...state,
                code: e.target.value,
              }));
              setFormErrors((state) => {
                return { ...state, code: false };
              });
            }}
            disabled={type === "edit"}
            error={formErrors.code}
            helperText={
              formErrors.code && "Musi zostać podany kod lokalizacji! "
            }
          />
          <CustomTextField
            label="Nazwa*"
            value={newLocationData.name}
            onChange={(e) => {
              setNewLocationData((state) => ({
                ...state,
                name: e.target.value,
              }));
              setFormErrors((state) => {
                return { ...state, name: false };
              });
            }}
            error={formErrors.name}
            helperText={
              formErrors.name && "Musi zostać podana nazwa lokalizacji! "
            }
          />
          <CustomTextField
            label="Numer telefoniczny*"
            value={newLocationData.contactPhone}
            onChange={(e) => {
              setNewLocationData((state) => ({
                ...state,
                contactPhone: e.target.value,
              }));
              setFormErrors((state) => {
                return { ...state, contactPhone: false };
              });
            }}
            error={formErrors.contactPhone}
            helperText={
              formErrors.contactPhone &&
              "Musi zostać podany telefon kontaktowy! "
            }
          />
          <CustomTextField
            label="Osoba kontaktowa*"
            value={newLocationData.contactPerson}
            onChange={(e) => {
              setNewLocationData((state) => ({
                ...state,
                contactPerson: e.target.value,
              }));
              setFormErrors((state) => {
                return { ...state, contactPerson: false };
              });
            }}
            error={formErrors.contactPerson}
            helperText={
              formErrors.contactPerson &&
              "Musi zostać podana osoba kontaktowa! "
            }
          />
        </TextFieldsWrapper>
        <TextFieldsWrapper>
          <Typography>Adres</Typography>
          <CustomTextField
            label="Ulica*"
            value={newLocationData.street}
            onChange={(e) => {
              setNewLocationData((state) => ({
                ...state,
                street: e.target.value,
              }));
              setFormErrors((state) => {
                return { ...state, street: false };
              });
            }}
            error={formErrors.street}
            helperText={
              formErrors.street && "Musi zostać podana ulica lokalizacji! "
            }
          />
          <CustomTextField
            label="Miasto*"
            value={newLocationData.city}
            onChange={(e) => {
              setNewLocationData((state) => ({
                ...state,
                city: e.target.value,
              }));
              setFormErrors((state) => {
                return { ...state, city: false };
              });
            }}
            error={formErrors.city}
            helperText={
              formErrors.city && "Musi zostać podane miasto lokalizacji! "
            }
          />
          <CustomTextField
            label="Województwo/Prowincja*"
            value={newLocationData.province}
            onChange={(e) => {
              setNewLocationData((state) => ({
                ...state,
                province: e.target.value,
              }));
              setFormErrors((state) => {
                return { ...state, province: false };
              });
            }}
            error={formErrors.province}
            helperText={
              formErrors.province &&
              "Musi zostać podane województwo lokalizacji! "
            }
          />
          <CustomTextField
            label="Kraj*"
            value={newLocationData.country}
            onChange={(e) => {
              setNewLocationData((state) => ({
                ...state,
                country: e.target.value,
              }));
              setFormErrors((state) => {
                return { ...state, country: false };
              });
            }}
            error={formErrors.country}
            helperText={
              formErrors.country && "Musi zostać podany kraj lokalizacji! "
            }
          />
          <CustomTextField
            label="Kod pocztowy*"
            value={newLocationData.postcode}
            onChange={(e) => {
              setNewLocationData((state) => ({
                ...state,
                postcode: e.target.value,
              }));
              setFormErrors((state) => {
                return { ...state, postcode: false };
              });
            }}
            error={formErrors.postcode}
            helperText={
              formErrors.postcode &&
              "Musi zostać podany kod pocztowy lokalizacji! "
            }
          />
        </TextFieldsWrapper>
      </ColumnFormWrapper>
      <ButtonWrapper>
        <AcceptButton
          onClick={() => submitForm()}
          disabled={buttonLock || !isFormDataCompleted()}
          text="Potwierdź"
        />
        <EditButton
          onClick={() => navigate(-1)}
          startIcon={<Clear />}
          disabled={buttonLock || !isFormDataCompleted()}
          text="Anuluj"
        />
      </ButtonWrapper>
    </LocationFormWrapper>
  );
};

export default LocationForm;
