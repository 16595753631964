/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { useDispatch } from "react-redux";
import ApprovalModal from "../../TaskModals/ApprovalModal/ApprovalModal";
import QualificationModal from "../../TaskModals/QualificationModal/QualificationModal";
import AssignmentModal from "../../TaskModals/AssignmentModal/AssignmentModal";
import WorkModal from "../../TaskModals/WorkModal/WorkModal";
import VerificationModal from "../../TaskModals/VerificationModal/VerificationModal";
import Slide from "@mui/material/Slide";
import { changeTaskStatus } from "../../../slices/taskSlice";
import { Cached, Check } from "@mui/icons-material";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import { defaultPermissions } from "../../../static/static";
import ChangeExecutorModal from "../../TaskModals/ChangeExecutorModal/ChangeExecutorModal";
import authService from "../../../auth/authService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TaskExecution = ({
  task,
  setRefresh,
  isMobileViewDisabled = false,
  chosenUser,
}) => {
  const dispatch = useDispatch();
  const { user_id: userId, role } = authService.getDecodedAccessToken();
  const proposedExecutor =
    task.proposedExecutor !== null ? task.proposedExecutor.id : "";

  const [isQualificationModalVisible, setIsQualificationModalVisible] =
    useState(false);
  const [isAssignmentModalVisible, setIsAssignmentModalVisible] =
    useState(false);
  const [isApprovalModalVisible, setIsApprovalModalVisible] = useState(false);
  const [isWorkModalVisible, setIsWorkModalVisible] = useState(false);
  const [isVerificationModalVisible, setIsVerificationModalVisible] =
    useState(false);
  const [isChangeExecutorModalVisible, setIsChangeExecutorModalVisible] =
    useState(false);

  const openQualificationModal = async () => {
    await dispatch(
      changeTaskStatus({ taskId: task.id, status: "In progress" })
    );
    setIsQualificationModalVisible(true);
  };

  const closeQualificationModal = async () => {
    setIsQualificationModalVisible(false);
  };

  const openAssignmentModal = async () => {
    setIsAssignmentModalVisible(true);
  };

  const closeAssignmentModal = async () => {
    setIsAssignmentModalVisible(false);
  };

  const openApprovalModal = async () => {
    setIsApprovalModalVisible(true);
  };

  const closeApprovalModal = async () => {
    setIsApprovalModalVisible(false);
  };

  const openWorkModal = async () => {
    setIsWorkModalVisible(true);
  };

  const closeWorkModal = async () => {
    setIsWorkModalVisible(false);
  };

  const openVerificationModal = async () => {
    setIsVerificationModalVisible(true);
  };

  const closeVerificationModal = async () => {
    setIsVerificationModalVisible(false);
  };

  const openChangeExecutorModal = async () => {
    setIsChangeExecutorModalVisible(true);
  };

  const closeChangeExecutorModal = async () => {
    setIsChangeExecutorModalVisible(false);
  };

  return (
    <>
      <>
        {(task.status === "Created" || task.status === "In progress") &&
          task.type === "Qualification" &&
          (role === "admin" ||
            (role === "service_technician" &&
              chosenUser.permissions.findIndex(
                (permission) =>
                  permission.name ===
                  defaultPermissions.service["Qualification Tasks View"]
              ) !== -1)) && (
            <DetailsButton
              onClick={() => openQualificationModal()}
              text="Wykonaj"
              startIcon={<Check />}
              isMobileViewDisabled={isMobileViewDisabled}
            />
          )}
        {(task.status === "Created" || task.status === "In progress") &&
          task.type === "Assignment" &&
          (role === "admin" ||
            (role === "service_technician" &&
              chosenUser.permissions.findIndex(
                (permission) =>
                  permission.name ===
                  defaultPermissions.service["Assignment Tasks View"]
              ) !== -1)) && (
            <DetailsButton
              text="Wykonaj"
              startIcon={<Check />}
              onClick={() => openAssignmentModal()}
              isMobileViewDisabled={isMobileViewDisabled}
            />
          )}
        {(task.status === "Created" || task.status === "In progress") &&
          task.type === "Approval" &&
          proposedExecutor === userId && (
            <DetailsButton
              text="Wykonaj"
              startIcon={<Check />}
              onClick={() => openApprovalModal()}
              isMobileViewDisabled={isMobileViewDisabled}
            />
          )}
        {(task.status === "Created" || task.status === "In progress") &&
          task.type === "Work" &&
          role === "service_technician" && (
            <DetailsButton
              onClick={() => openWorkModal()}
              text="Wykonaj"
              startIcon={<Check />}
              isMobileViewDisabled={isMobileViewDisabled}
            />
          )}
        {(task.status === "Created" || task.status === "In progress") &&
          task.type === "Work" &&
          role === "admin" && (
            <DetailsButton
              onClick={() => openChangeExecutorModal()}
              text="Odepnij"
              startIcon={<Cached />}
              isMobileViewDisabled={isMobileViewDisabled}
            />
          )}
        {task.commission.type === "Online Fix" &&
          task.type === "Work" &&
          (task.status === "Created" || task.status === "In progress") &&
          role !== "service_technician" && (
            <DetailsButton
              text="Wykonaj"
              startIcon={<Check />}
              onClick={() => openWorkModal()}
              isMobileViewDisabled={isMobileViewDisabled}
            />
          )}
        {(task.status === "Created" || task.status === "In progress") &&
          task.type === "Verification" &&
          (role === "admin" ||
            (role === "service_technician" &&
              chosenUser.permissions.findIndex(
                (permission) =>
                  permission.name ===
                  defaultPermissions.service["Verification Tasks View"]
              ) !== -1)) && (
            <DetailsButton
              onClick={() => openVerificationModal()}
              text="Wykonaj"
              startIcon={<Check />}
              isMobileViewDisabled={isMobileViewDisabled}
            />
          )}
      </>

      <>
        <Dialog
          open={isQualificationModalVisible}
          onClose={() => closeQualificationModal()}
          // fullScreen={isSmallDisplay}
        >
          <QualificationModal
            taskId={task.id}
            setIsQualificationModalVisible={setIsQualificationModalVisible}
            task={task}
            setRefresh={setRefresh}
          />
        </Dialog>
        <Dialog
          open={isAssignmentModalVisible}
          onClose={() => closeAssignmentModal()}
          // fullScreen={isSmallDisplay}
        >
          <AssignmentModal
            taskId={task.id}
            locationProvince={task.locationProvince}
            setIsAssignmentModalVisible={setIsAssignmentModalVisible}
            task={task}
            setRefresh={setRefresh}
          />
        </Dialog>
        <Dialog
          open={isApprovalModalVisible}
          onClose={() => closeApprovalModal()}
        >
          <ApprovalModal
            taskId={task.id}
            setIsApprovalModalVisible={setIsApprovalModalVisible}
            setRefresh={setRefresh}
            task={task}
          />
        </Dialog>
        <Dialog
          open={isWorkModalVisible}
          onClose={() => closeWorkModal()}
          fullScreen
          TransitionComponent={Transition}
        >
          <WorkModal
            taskId={task.id}
            setIsWorkModalVisible={setIsWorkModalVisible}
            setRefresh={setRefresh}
            commissionType={task.commission.type}
            commissionId={task.commission.id}
          />
        </Dialog>
        <Dialog
          open={isVerificationModalVisible}
          onClose={() => closeVerificationModal()}
        >
          <VerificationModal
            taskId={task.id}
            setIsVerificationModalVisible={setIsVerificationModalVisible}
            setRefresh={setRefresh}
            task={task}
          />
        </Dialog>
        <Dialog
          open={isChangeExecutorModalVisible}
          onClose={() => closeChangeExecutorModal()}
        >
          <ChangeExecutorModal
            setIsChangeExecutorModalVisible={setIsChangeExecutorModalVisible}
            setRefresh={setRefresh}
            task={task}
          />
        </Dialog>
      </>
    </>
  );
};

export default TaskExecution;
