/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box, Typography, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChooseLocationSelectPage from "../ChooseLocationSelectPage/ChooseLocationSelectPage";
import ChooseLocationCreatePage from "../ChooseLocationCreatePage/ChooseLocationCreatePage";
import SwitchQuestionModal from "../../SwitchQuestionModal/SwitchQuestionModal";

const CustomWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const TitleWrapper = styled(Box)(() => ({
  alignSelf: "center",
  display: "flex",
  gap: "0.5rem",
}));

const ChooseLocationMainPage = ({
  chosenCompany,
  chosenLocation,
  setChosenLocation,
  createdLocation,
  setCreatedLocation,
  setChosenDevice,
  setCreatedDevice,
  listOfActivePages,
  setListOfActivePages,
  setCommissionDetails,
}) => {
  const { id: companyId } = chosenCompany;
  const [mode, setMode] = useState(
    listOfActivePages.length > 1 &&
      listOfActivePages[1].prop === "createLocation"
      ? "create"
      : "select"
  );
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const changeToSelect = () => {
    setMode("select");
    setCreatedLocation({
      locationCode: "",
      name: "",
      address: {
        street: "",
        city: "",
        province: "",
        country: "Polska",
        postcode: "",
      },
      contactPhone: "",
      contactPerson: "",
    });
    setListOfActivePages((state) => [
      state[0],
      { id: "1", prop: "selectLocation" },
    ]);
    setChosenDevice("");
    setCreatedDevice({
      name: "",
      createdAt: "",
      deviceId: "",
      type: "",
      orientation: "",
      subType: "",
    });
    setCommissionDetails({
      description: "",
      errorCode: "",
    });
  };

  const changeToCreate = () => {
    setMode("create");
    setChosenLocation("");
    setListOfActivePages((state) => [
      state[0],
      { id: "1", prop: "createLocation" },
    ]);
    setChosenDevice("");
    setCreatedDevice({
      name: "",
      createdAt: "",
      deviceId: "",
      type: "",
      orientation: "",
      subType: "",
    });
    setCommissionDetails({
      description: "",
      errorCode: "",
    });
  };

  return (
    <>
      <CustomWrapper>
        <TitleWrapper>
          {mode === "select" ? (
            <Typography
              sx={{
                fontSize: "1.4rem",
                marginBottom: "1rem",
                fontWeight: mode === "select" ? 700 : 400,
              }}
            >
              2. Wybierz istniejącą lokalizację
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: "1.4rem",
                marginBottom: "1rem",
                fontWeight: mode !== "select" ? 700 : 400,
              }}
            >
              2. Utwórz nową lokalizację
            </Typography>
          )}
        </TitleWrapper>
        {mode === "select" ? (
          <ChooseLocationSelectPage
            chosenLocation={chosenLocation}
            setChosenLocation={setChosenLocation}
            companyId={companyId}
            mode={mode}
            setIsWarningModalOpen={setIsWarningModalOpen}
          />
        ) : (
          <ChooseLocationCreatePage
            createdLocation={createdLocation}
            setCreatedLocation={setCreatedLocation}
            mode={mode}
            setIsWarningModalOpen={setIsWarningModalOpen}
          />
        )}
      </CustomWrapper>
      <Dialog
        open={isWarningModalOpen}
        onClose={() => setIsWarningModalOpen(false)}
      >
        <SwitchQuestionModal
          stepsString="2-5"
          executiveFunction={
            mode === "select" ? () => changeToCreate() : () => changeToSelect()
          }
          closeFunction={() => setIsWarningModalOpen(false)}
        />
      </Dialog>
    </>
  );
};

export default ChooseLocationMainPage;
