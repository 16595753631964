/* eslint-disable react/prop-types */
import { Box, TextField, Typography } from "@mui/material";
import React from "react";

const CustomTextField = ({
  value = "",
  onChange = () => {},
  maxLength = 0,
  width = "100%",
  counter = true,
  ...props
}) => {
  return (
    <Box sx={{ position: "relative", marginBottom: "0.7rem", width }}>
      <TextField
        value={value}
        onChange={onChange}
        inputProps={{ maxLength: maxLength }}
        fullWidth
        {...props}
      />
      {counter && (
        <Typography
          sx={{
            position: "absolute",
            right: 0,
            fontSize: "0.8rem",
            color: value && value.length === maxLength ? "red" : "black",
          }}
        >
          {value ? `${value.length}/${maxLength}` : ""}
        </Typography>
      )}
    </Box>
  );
};

export default CustomTextField;
