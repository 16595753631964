/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Pagination from "../../Pagination/Pagination";
import MoveUnitItem from "../MoveUnitItem/MoveUnitItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaginatedUnitsByStorage,
  getUnitsByStorageCount,
} from "../../../slices/unitSlice";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../../../static/static";
import TableHeader from "../../TableHeader/TableHeader";
import { moveUnitsAvailableUnitsHeaders } from "../../../static/tablesHeaders";
import PageHeader from "../../PageHeader/PageHeader";
import { Check, Warning } from "@mui/icons-material";
import RefreshButton from "../../RefreshButton/RefreshButton";
import { clearUnits } from "../../../slices/unitSlice";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import EmptyListError from "../../EmptyListError/EmptyListError";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import Loading from "../../Loading/Loading";
import MultipleChipSelect from "../../MultipleChipSelect/MultipleChipSelect";
import {
  clearCategories,
  getFilteredCategories,
} from "../../../slices/categorySlice";
import { clearProducers, getFilteredProducers } from "../../../slices/producerSlice";
import TableWrapper from "../../TableWrapper/TableWrapper";

const CustomWrapper = styled(Box)(() => ({
  maxHight: "18.75rem",
  overflowY: "auto",
}));

const AvailableProducts = ({ chosenStorage, setFormValues, status = "" }) => {
  const dispatch = useDispatch();
  const searchingRef = useRef("");
  const [searchingValue, setSearchingValue] = useState("");
  const [displaySearchingValue, setDisplaySearchingValue] = useState("");
  const { units, unitsCount } = useSelector((slice) => slice.unit);
  const [sorter, setSorter] = useState("");
  const [sorting, setSorting] = useState("");
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(DEFAULT_DISPLAY_ITEMS_QUANTITY);
  const [refresh, setRefresh] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProducers, setSelectedProducers] = useState([]);

  const getDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getUnitsByStorageCount({
        searchingValue: searchingValue,
        status: status,
        storageId: chosenStorage.id,
        categories: selectedCategories.map((item) => item.name),
        producers: selectedProducers.map((item) => item.name),
        greaterThanZero: true
      })
    );
  }, [chosenStorage, refresh, searchingValue, selectedCategories, selectedProducers]);

  const getData = useCallback(async () => {
    await dispatch(
      getPaginatedUnitsByStorage({
        leftIndex,
        rightIndex,
        sortingParam: "partNumber",
        sortingOrder: "ascending",
        searchingValue: searchingValue,
        status: status,
        storageId: chosenStorage.id,
        categories: selectedCategories.map((item) => item.name),
        producers: selectedProducers.map((item) => item.name),
        greaterThanZero: true,
      })
    );
    setIsDataLoading(false);
  }, [
    chosenStorage,
    refresh,
    leftIndex,
    rightIndex,
    searchingValue,
    selectedCategories,
    selectedProducers,
  ]);

  useEffect(() => {
    if (chosenStorage !== null) {
      getDataCount();
    }
  }, [getDataCount]);

  useEffect(() => {
    if (chosenStorage !== null) {
      getData();
    }
  }, [getData]);

  useEffect(() => {
    return () => dispatch(clearUnits());
  }, []);

  return (
    <SectionWrapper>
      <PageHeader icon={<Check />} titleText="Dostępne produkty" size="medium">
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <AdditionalFunctionalitiesWrapper>
        <CustomSearchField
          displaySearchingValue={displaySearchingValue}
          setDisplaySearchingValue={setDisplaySearchingValue}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
          searchingRef={searchingRef}
          placeholder="szukaj produktu..."
        />
        <MultipleChipSelect
          name="Kategorie:"
          sliceName="category"
          collectionName="categories"
          getDataFunction={getFilteredCategories}
          cleanDataFunction={clearCategories}
          setFunction={setSelectedCategories}
          settingCollection={selectedCategories}
        />
        <MultipleChipSelect
          name="Producenci:"
          sliceName="producer"
          collectionName="producers"
          getDataFunction={getFilteredProducers}
          cleanDataFunction={clearProducers}
          setFunction={setSelectedProducers}
          settingCollection={selectedProducers}
        />
      </AdditionalFunctionalitiesWrapper>
      <TableWrapper>
        <TableHeader
          headers={moveUnitsAvailableUnitsHeaders}
          setSorter={setSorter}
          setSorting={setSorting}
          gap="1%"
        />
        <Pagination
          collectionSize={unitsCount}
          leftIndex={leftIndex}
          rightIndex={rightIndex}
          setLeftIndex={setLeftIndex}
          setRightIndex={setRightIndex}
        >
          <CustomWrapper>
            {isDataLoading ? (
              <Loading />
            ) : units !== null && units.length > 0 ? (
              units.map((unit, index) => (
                <MoveUnitItem
                  key={unit.id}
                  unit={unit}
                  index={index}
                  setFormValues={setFormValues}
                  orderNumber={index + 1}
                />
              ))
            ) : (
              <EmptyListError
                icon={<Warning />}
                message="Brak dostępnych produktów"
              />
            )}
          </CustomWrapper>
        </Pagination>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default AvailableProducts;
