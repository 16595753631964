export const IntegrationFormClasses = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
    height: "20rem",
    width: "20rem",
  },
  textFieldProps: {
    width: "18rem",
  },
  selectProps: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem"
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  }
};