/* eslint-disable react/prop-types */
import React from "react";
import { Box, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { deleteStorage } from "../../slices/storageSlice";
import AcceptButton from "../AcceptButton/AcceptButton";
import CloseButton from "../CloseButton/CloseButton";

const Wrapper = styled(Box)(() => ({
  width: "25rem",
  height: "5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: '1rem'
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
  justifyContent: "center",
}));

const StorageDeleteForm = ({
  isModalVisible,
  storageId,
  storageName,
  setVisibility,
  setRefresh,
}) => {
  const dispatch = useDispatch();

  const deleteItem = async () => {
    await dispatch(deleteStorage(storageId));
    setRefresh((state) => !state);
    setVisibility(false);
  };

  return (
    <Dialog open={isModalVisible} onClose={() => setVisibility(false)}>
      <Wrapper>
        <Typography>{`Czy na pewno chcesz usunąć magazyn ${storageName} ?`}</Typography>
        <ButtonWrapper>
          <AcceptButton onClick={() => deleteItem()} text="Tak" />
          <CloseButton onClick={() => setVisibility(false)} text="Nie" />
        </ButtonWrapper>
      </Wrapper>
    </Dialog>
  );
};

export default StorageDeleteForm;
