/* eslint-disable react/prop-types */
import React from "react";
import { useNavigate } from "react-router-dom";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";
import DetailsButton from "../../../DetailsButton/DetailsButton";

const CompanyItemMobile = ({ company }) => {
  const navigate = useNavigate();
  const {
    id,
    name,
    address: { street, city, country, postcode },
    assignedUser: { name: assignedUserName, surname: assignedUserSurname },
  } = company;

  const data = [
    { title: "Nazwa:", value: name },
    { title: "Adres:", value: `${street} ${city} ${country} ${postcode}` },
    {
      title: "Przypisany użytkownik:",
      value: `${assignedUserName} ${assignedUserSurname}`,
    },
  ];

  const navigateToCompany = () => {
    localStorage.setItem("chosenCompanyId", id);
    navigate(`/companies/${id}`);
  };

  return (
    <CompactDataDisplay
      title={`Firma #${id}`}
      data={data}
      actions={
        <>
          <DetailsButton onClick={() => navigateToCompany()} />
        </>
      }
    />
  );
};

export default CompanyItemMobile;
