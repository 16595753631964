export const ChangeRequestStatusModalClasses = {
  wrapper: {
    height: '9.375rem',
    width: '34.375rem',
    marginTop: "0.625em",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: "1.5em"
  },
  innerWrapper: {
    display: 'flex',
    gap: '0.5em'
  }
};