/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import useMediaQ from "../../../hooks/useMediaQ";
import MoveUnitItemDesktop from "./MoveUnitItemVersions/MoveUnitItemDesktop";
import MoveUnitItemMobile from "./MoveUnitItemVersions/MoveUnitItemMobile";

const MoveUnitItem = ({ index, unit, setFormValues, orderNumber }) => {
  const { isDesktopDisplay } = useMediaQ();
  const {
    id: unitId,
    product: { id, name, partNumber },
    serialNumber,
    status,
    quantity,
  } = unit;
  const [chosenQuantity, setChosenQuantity] = useState(0);
  const [buttonLock, setButtonLock] = useState(true);
  const [isAdded, setIsAdded] = useState(false);
  const { chosenStorageId } = useSelector((slice) => slice.storage);

  const changeQuantity = (value) => {
    if (value > quantity) setChosenQuantity(quantity);
    else setChosenQuantity(value);
    if (value == 0) {
      setButtonLock(true);
    } else {
      setButtonLock(false);
    }
  };

  const addItem = () => {
    setFormValues((state) => [
      ...state,
      {
        index,
        name,
        unitId,
        partNumber,
        serialNumber,
        chosenQuantity,
        status,
        productId: id,
        storage: chosenStorageId,
        removeItemInner,
      },
    ]);
    setIsAdded(true);
  };

  const removeItemInner = () => {
    setChosenQuantity(0);
    setButtonLock(true);
    setIsAdded(false);
  };

  return (
    <>
      {isDesktopDisplay ? (
        <MoveUnitItemDesktop
          orderNumber={orderNumber}
          unit={unit}
          chosenQuantity={chosenQuantity}
          changeQuantity={changeQuantity}
          isAdded={isAdded}
          addItem={addItem}
          buttonLock={buttonLock}
        />
      ) : (
        <MoveUnitItemMobile
          orderNumber={orderNumber}
          unit={unit}
          chosenQuantity={chosenQuantity}
          changeQuantity={changeQuantity}
          isAdded={isAdded}
          addItem={addItem}
          buttonLock={buttonLock}
        />
      )}
    </>
  );
};

export default MoveUnitItem;
