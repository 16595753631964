/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import DocumentationsItem from "../DocumentationsItem/DocumentationsItem";
import PageHeader from "../../../PageHeader/PageHeader";
import { Attachment, Info } from "@mui/icons-material";
import EmptyListError from "../../../EmptyListError/EmptyListError";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";
import { useDispatch } from "react-redux";
import { clearDocumentations } from "../../../../slices/commissionSlice";
import { clearDocumentationsFromTask } from "../../../../slices/taskSlice";

const InnerWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.625rem",
  flexWrap: "wrap",
  alignItems: "center",
}));

const DocumentationsView = ({
  documentation,
  commissionId = "",
  taskId = "",
  sx={}
}) => {
  const dispatch = useDispatch();
  const [photosView, setPhotosView] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(clearDocumentations());
      dispatch(clearDocumentationsFromTask());
    };
  }, []);

  return (
    <SectionWrapper sx={sx}>
      <PageHeader
        titleText="Załączniki"
        size="medium"
        icon={<Attachment />}
        onRightComponent={
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={photosView}
                  onChange={() => setPhotosView((state) => !state)}
                />
              }
              label="Podgląd zdjęć"
            />
          </FormGroup>
        }
      />
      <>
        {documentation !== null && documentation.length > 0 ? (
          <InnerWrapper>
            {documentation.map((documentation, index) => (
              <DocumentationsItem
                key={documentation.id}
                index={index}
                documentationId={documentation.id}
                commissionId={commissionId}
                taskId={taskId}
                photosView={photosView}
              />
            ))}
          </InnerWrapper>
        ) : (
          <EmptyListError message="Brak załączników" icon={<Info />} />
        )}
      </>
    </SectionWrapper>
  );
};

export default DocumentationsView;
