/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import TableHeader from "../../TableHeader/TableHeader";
import Pagination from "../../Pagination/Pagination";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../../../static/static";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProducts,
  getPaginatedProducts,
  getProductsCount,
} from "../../../slices/productSlice";
import { AvailableUnitsHeaders } from "../../../static/tablesHeaders";
import AddUnitItem from "../AddUnitsItem/AddUnitItem";
import PageHeader from "../../PageHeader/PageHeader";
import RefreshButton from "../../RefreshButton/RefreshButton";
import { Check, Warning } from "@mui/icons-material";
import Loading from "../../Loading/Loading";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import EmptyListError from "../../EmptyListError/EmptyListError";
import MultipleChipSelect from "../../MultipleChipSelect/MultipleChipSelect";
import {
  clearCategories,
  getFilteredCategories,
} from "../../../slices/categorySlice";
import {
  clearProducers,
  getFilteredProducers,
} from "../../../slices/producerSlice";
import TableWrapper from "../../TableWrapper/TableWrapper";

const DataWrapper = styled(Box)(() => ({
  maxHeight: "21.875rem",
  overflowY: "scroll",
  scrollbarWidth: "none",
  "body::-webkit-scrollbar": {
    width: "none",
  },
}));

const AvailableUnits = ({ addItem }) => {
  const dispatch = useDispatch();
  const searchingRef = useRef("");
  const [searchingValue, setSearchingValue] = useState("");
  const [displaySearchingValue, setDisplaySearchingValue] = useState("");
  const [sorterItem, setSorterItem] = useState("name");
  const [sortingItem, setSortingItem] = useState("ascending");
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(DEFAULT_DISPLAY_ITEMS_QUANTITY);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProducers, setSelectedProducers] = useState([]);
  const { products, productsCount } = useSelector((slice) => slice.product);

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getProductsCount({
        searchingValue,
        categories: selectedCategories.map((item) => item.name),
        producers: selectedProducers.map((item) => item.name),
      })
    );
  }, [refresh, searchingValue, selectedCategories, selectedProducers]);

  const loadData = useCallback(async () => {
    await dispatch(
      getPaginatedProducts({
        leftIndex,
        rightIndex,
        sortingParam: sorterItem,
        sortingOrder: sortingItem,
        searchingValue,
        categories: selectedCategories.map((item) => item.name),
        producers: selectedProducers.map((item) => item.name),
      })
    );
    setIsDataLoading(false);
  }, [
    refresh,
    leftIndex,
    rightIndex,
    sorterItem,
    sortingItem,
    searchingValue,
    selectedCategories,
    selectedProducers,
  ]);

  useEffect(() => {
    loadDataCount();
    loadData();
  }, [loadDataCount, loadData]);

  useEffect(() => {
    return () => dispatch(clearProducts());
  }, []);

  return (
    <SectionWrapper>
      <PageHeader
        icon={<Check />}
        titleText="Dostępne karty produktów"
        size="medium"
        styleProps={{ marginBottom: "1rem" }}
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <AdditionalFunctionalitiesWrapper>
        <CustomSearchField
          displaySearchingValue={displaySearchingValue}
          setDisplaySearchingValue={setDisplaySearchingValue}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
          searchingRef={searchingRef}
          placeholder="szukaj karty produktu..."
        />
        <MultipleChipSelect
          name="Kategorie:"
          sliceName="category"
          collectionName="categories"
          getDataFunction={getFilteredCategories}
          cleanDataFunction={clearCategories}
          setFunction={setSelectedCategories}
          settingCollection={selectedCategories}
        />
        <MultipleChipSelect
          name="Producenci:"
          sliceName="producer"
          collectionName="producers"
          getDataFunction={getFilteredProducers}
          cleanDataFunction={clearProducers}
          setFunction={setSelectedProducers}
          settingCollection={selectedProducers}
        />
      </AdditionalFunctionalitiesWrapper>
      <TableWrapper>
        <TableHeader
          headers={AvailableUnitsHeaders}
          setSorter={setSorterItem}
          setSorting={setSortingItem}
          gap="1%"
          scroll
        />
        <Pagination
          collectionSize={productsCount}
          leftIndex={leftIndex}
          rightIndex={rightIndex}
          setLeftIndex={setLeftIndex}
          setRightIndex={setRightIndex}
        >
          <DataWrapper>
            {isDataLoading ? (
              <Loading />
            ) : products && products.length > 0 ? (
              products.map((product, index) => (
                <AddUnitItem
                  key={index}
                  product={product}
                  addItem={() =>
                    addItem({
                      product: product.id,
                      partNumber: product.partNumber,
                      name: product.name,
                      type: product.type,
                    })
                  }
                  orderNumber={index + 1}
                />
              ))
            ) : (
              <EmptyListError icon={<Warning />} message="Brak produktów" />
            )}
          </DataWrapper>
        </Pagination>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default AvailableUnits;
