/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Inventory2 } from "@mui/icons-material";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
} from "../../ObjectDetailsElements/ObjectDetailsElements";
import CustomChip from "../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../static/static";

const CustomBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const ContentWrapper = styled(Box)(() => ({
  marginLeft: "0.5rem",
  maxHeight: "4.5rem",
  overflowY: "scroll",
}));

const ShipmentInventory = ({ shipmentUnits }) => {
  return (
    <CustomBox>
      <Inventory2 />
      <ContentWrapper>
        {shipmentUnits.map((unit, id) => (
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              borderBottom: "0.0625rem solid #ddd",
            }}
            key={id}
          >
            <Typography>{id + 1}</Typography>
            <InfoWrapper>
              <DataBox sx={{ flexBasis: "46%" }}>
                <DataTitle sx={{ fontSize: "0.7rem" }}>PartNumber:</DataTitle>
                <Typography sx={{ fontSize: "0.7rem" }}>
                  {unit.product.partNumber}
                </Typography>
              </DataBox>
              <DataBox sx={{ flexBasis: "46%" }}>
                <DataTitle sx={{ fontSize: "0.7rem" }}>Nazwa:</DataTitle>
                <Typography sx={{ fontSize: "0.7rem" }}>
                  {unit.product.name}
                </Typography>
              </DataBox>
              <DataBox sx={{ flexBasis: "46%" }}>
                <DataTitle sx={{ fontSize: "0.7rem" }}>Nr seryjny:</DataTitle>
                <Typography sx={{ fontSize: "0.7rem" }}>
                  {unit.serialNumber}
                </Typography>
              </DataBox>
              <DataBox sx={{ flexBasis: "46%" }}>
                <DataTitle sx={{ fontSize: "0.7rem" }}>Status:</DataTitle>
                <CustomChip
                  label={unit.status}
                  sx={{...unitStatusChipStyles(unit.status, ""), fontSize: '0.65rem'}}
                  width="50%"
                />
              </DataBox>
              <DataBox sx={{ flexBasis: "46%" }}>
                <DataTitle sx={{ fontSize: "0.7rem" }}>Liczba:</DataTitle>
                <Typography sx={{ fontSize: "0.7rem" }}>
                  {unit.quantity}
                </Typography>
              </DataBox>
            </InfoWrapper>
          </Box>
        ))}
      </ContentWrapper>
    </CustomBox>
  );
};

export default ShipmentInventory;
