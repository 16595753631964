/* eslint-disable react/prop-types */
import React from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SelectedChipDisplayItem from "./SelectedChipDisplayItem";
import { Clear, ClearAll } from "@mui/icons-material";

const CustomWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "0.1rem",
  width: "100%",
}));

const SelectedChipsDisplay = ({
  selectedChips,
  removeChip,
  removeAll,
  closeModal,
}) => {
  return (
    <Box
      sx={{
        borderBottom: "0.0625rem solid black",
        paddingBottom: "0.5rem",
        position: "relative",
      }}
    >
      <Tooltip title="zamknij">
        <IconButton
          sx={{ position: "absolute", top: "1%", right: "1%" }}
          onClick={() => closeModal()}
        >
          <Clear />
        </IconButton>
      </Tooltip>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography>Wybrane:</Typography>
        <Tooltip title="usuń wszystkie">
          <IconButton onClick={() => removeAll()}>
            <ClearAll />
          </IconButton>
        </Tooltip>
      </Box>
      <CustomWrapper>
        {selectedChips.map((chip, key) => (
          <SelectedChipDisplayItem
            key={key}
            chip={chip}
            removeChip={removeChip}
          />
        ))}
      </CustomWrapper>
    </Box>
  );
};

export default SelectedChipsDisplay;
