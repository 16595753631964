/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import ReactDatePicker from "react-datepicker";
import CustomDateInput from "../CustomDateInput/CustomDateInput";
import "react-datepicker/dist/react-datepicker.css";
import { Clear } from "@mui/icons-material";
import useMediaQ from "../../hooks/useMediaQ";

const CustomDatePicker = ({ labelName = "", datesRange, setDatesRange, maxDate = "", minDate = "", width = "20%", mobileWidth = "50%" }) => {
  const { isPhoneDisplay, isTabletDisplay } = useMediaQ();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        width: !(isPhoneDisplay || isTabletDisplay) ? width : mobileWidth
      }}
    >
      <Typography sx={{ fontWeight: 700 }}>{labelName}</Typography>
      <Box sx={{ position: "relative", width: '100%' }}>
        <ReactDatePicker
          selected={datesRange.start}
          onChange={(dates) => setDatesRange(dates)}
          startDate={datesRange.start}
          endDate={datesRange.end}
          selectsRange
          dateFormat="yyyy-MM-dd"
          maxDate={maxDate}
          minDate={minDate}
          // withPortal
          customInput={<CustomDateInput />}
        />
        <IconButton
          sx={{
            visibility: datesRange.start ? "visible" : "hidden",
            zIndex: 10,
            position: "absolute",
            top: "6%",
            right: 0,
          }}
          onClick={() => setDatesRange(["", ""])}
        >
          <Clear />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomDatePicker;
