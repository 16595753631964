/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Done } from "@mui/icons-material";

const CustomButtonWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
}));

const CustomButton = styled(Button)(({ theme }) => ({
  width: "auto",
  fontSize: "0.75rem",
  height: "1.8125rem",
  backgroundColor: "#6ed91c",
  "&:hover": {
    background: "#439603",
  },
  [theme.breakpoints.down("desktop")]: {
    fontSize: "0.6rem",
  },
}));

const AcceptButton = ({
  onClick,
  startIcon = <Done />,
  text = "akceptuj",
  styles: stylesProps,
  disabled = false,
  clickLock = false,
}) => {
  const [wasClicked, setWasClicked] = useState(false);

  const handleClick = () => {
    onClick();
    if (clickLock) {
      setWasClicked(true);
    }
  };

  return (
    <CustomButtonWrapper>
      <CustomButton
        onClick={() => handleClick()}
        variant="contained"
        size="small"
        startIcon={
          wasClicked ? (
            <CircularProgress sx={{ color: "gray" }} size="1rem" />
          ) : (
            startIcon
          )
        }
        disabled={disabled || wasClicked}
        sx={stylesProps}
      >
        {text}
      </CustomButton>
    </CustomButtonWrapper>
  );
};

export default AcceptButton;
