import React from 'react';
import {
  createRoot
} from 'react-dom/client';
import {
  BrowserRouter
} from 'react-router-dom';
import App from './App';
import {
  store
} from './store/store';
import {
  Provider
} from 'react-redux';
import {
  unregister as unregisterServiceWorker
} from "./registerServiceWorker";
import {
  ThemeProvider
}
from "@mui/material/styles";
import {
  theme
} from './themes/theme';

unregisterServiceWorker();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode >
    <Provider store={store} >
      <BrowserRouter>
        <ThemeProvider theme={theme} >
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);