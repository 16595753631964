/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import AddUnitItemDesktop from "./AddUnitItemVersions/AddUnitItemDesktop";
import AddUnitItemMobile from "./AddUnitItemVersions/AddUnitItemMobile";

const AddUnitItem = ({ product, addItem, orderNumber }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <AddUnitItemDesktop
          product={product}
          addItem={addItem}
          orderNumber={orderNumber}
        />
      ) : (
        <AddUnitItemMobile
          product={product}
          addItem={addItem}
          orderNumber={orderNumber}
        />
      )}
    </>
  );
};

export default AddUnitItem;
