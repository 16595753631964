import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API
} from '../static/static';

export const getExternalLocations = createAsyncThunk('locations/get/external', async () => {
  const resData = await dataService.get(`${API}outerLocations/external`);
  return resData;
});

export const getLocations = createAsyncThunk('locations/get/internal', async () => {
  const resData = await dataService.get(`${API}locations`);
  return resData;
});

export const getCompanyLocations = createAsyncThunk('company/locations/get', async (data) => {
  const {
    companyId
  } = data;
  const resData = await dataService.get(`${API}companyLocations/${companyId}`);
  return resData;
});

export const getCompanyFilteredLocations = createAsyncThunk('company/locations/get/filtered', async (data) => {
  const {
    companyId,
    searchingValue,
  } = data;
  const resData = await dataService.get(`${API}companyLocations/${companyId}/filter/?searchingPhrase=${searchingValue}`);
  return resData;
});

export const getPaginatedLocations = createAsyncThunk('locations/get/pagination', async (data) => {
  const {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    company,
    createdBy,
    lastModifiedBy,
    creationDateStart,
    creationDateEnd,
    lastModificationDateStart,
    lastModificationDateEnd,
    isSynchronized
  } = data;
  const resData = await dataService.get(`${API}locations/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&company=${company}&createdBy=${createdBy}&lastModifiedBy=${lastModifiedBy}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&lastModificationDateStart=${lastModificationDateStart}&lastModificationDateEnd=${lastModificationDateEnd}&isSynchronized=${isSynchronized}`);
  return resData;
});

export const getLocationsCount = createAsyncThunk('locations/get/count', async (data) => {
  const {
    searchingValue,
    company,
    createdBy,
    lastModifiedBy,
    creationDateStart,
    creationDateEnd,
    lastModificationDateStart,
    lastModificationDateEnd,
    isSynchronized
  } = data;
  const resData = await dataService.get(`${API}locations/count/?searchingPhrase=${searchingValue}&company=${company}&createdBy=${createdBy}&lastModifiedBy=${lastModifiedBy}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&lastModificationDateStart=${lastModificationDateStart}&lastModificationDateEnd=${lastModificationDateEnd}&isSynchronized=${isSynchronized}`);
  return resData;
});

export const getLocation = createAsyncThunk('location/get', async (locationId, {
  getState
}) => {
  const {
    chosenLocationId
  } = getState().location;
  const resData = locationId ? await dataService.get(`${API}location/${locationId}`) : chosenLocationId ? await dataService.get(`${API}location/${chosenLocationId}`) : await dataService.get(`${API}location/${localStorage.getItem('chosenLocationId')}`);
  return resData;
});

export const addLocation = createAsyncThunk('location/add', async (data) => {
  const resData = await dataService.post(`${API}location-add/`, data);
  return resData;
});

export const updateLocation = createAsyncThunk('location/edit', async (data) => {
  const resData = await dataService.post(`${API}location-edit/`, data);
  return resData;
});

const initialState = {
  locations: null,
  locationsCount: 0,
  chosenLocation: null,
  chosenLocationId: null,
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setChosenLocationId: (state, action) => {
      localStorage.setItem('chosenLocationId', action.payload);
      state.chosenLocationId = action.payload;
    },
    clearLocations: (state) => {
      state.locations = null;
      state.locationsCount = 0;
    },
    clearLocation: (state) => {
      state.chosenLocation = null;
      state.chosenLocationId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExternalLocations.fulfilled, (state, action) => {
        state.locationsExternal = action.payload.data;
      })
      // .addCase(getExternalLocations.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.locationsInternal = action.payload.data;
      })
      // .addCase(getLocations.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getPaginatedLocations.fulfilled, (state, action) => {
        state.locations = action.payload.data;
      })
      // .addCase(getPaginatedLocations.rejected, (state, action) => {

      // })
      .addCase(getLocationsCount.fulfilled, (state, action) => {
        state.locationsCount = action.payload.data;
      })
      // .addCase(getLocationsCount.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getCompanyLocations.fulfilled, (state, action) => {
        state.locations = action.payload.data;
      })
      // .addCase(getCompanyLocations.rejected, (state, action) => {
      //   state.locationsInternal = action.payload.data;
      // })
      .addCase(getCompanyFilteredLocations.fulfilled, (state, action) => {
        state.locations = action.payload.data;
      })
      // .addCase(getCompanyFilteredLocations.rejected, (state, action) => {
      //   state.locationsInternal = action.payload.data;
      // })
      .addCase(getLocation.fulfilled, (state, action) => {
        state.chosenLocation = action.payload.data;
      });
    // .addCase(getLocation.rejected, (_, action) => {
    //   alert(`Operation failed - ${action.error.message}`);
    // })
  },
});


export const {
  setChosenLocationId,
  clearLocations,
  clearLocation,
} = locationSlice.actions;

export default locationSlice.reducer;