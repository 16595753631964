/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Typography } from "@mui/material";
import RequestsSummaryView from "./Elements/RequestSummary/RequestsSummaryView";
import TaskCharts from "./Elements/TaskCharts/TaskCharts";
import TasksSummary from "./Elements/TasksSummary/TasksSummary";
import { useSelector } from "react-redux";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import HomeIcon from "@mui/icons-material/Home";
import PageHeader from "../PageHeader/PageHeader";
import PageWrapper from "../PageWrapper/PageWrapper";
import BlankSpace from "../BlankSpace/BlankSpace";
import { styled } from "@mui/material/styles";
import ShipmentsSummaryView from "../ShipmentsSummaryView/ShipmentsSummaryView";
import SectionsSetter from "../SectionsSetter/SectionsSetter/SectionsSetter";

const NoRoleErrorWrapper = styled(Box)(() => ({
  padding: "3.2rem",
  border: "0.0625rem solid red",
  top: "50%",
  left: "50%",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  display: "flex",
  gap: "0.625rem",
  alignItems: "center",
}));

const sections = [
  {
    id: 1,
    component: <TasksSummary />,
    name: "Zadania do zrobienia",
    roles: [],
  },
  {
    id: 2,
    component: <ShipmentsSummaryView />,
    name: "Przesyłki przychodzące",
    roles: [],
  },
  {
    id: 3,
    component: <RequestsSummaryView />,
    name: "Nowe zapotrzebowania",
    roles: [],
  },
  {
    id: 4,
    component: <TaskCharts />,
    name: "Wykresy dot. zadań",
    roles: ["admin"],
  },
];

const NoRoleError = () => (
  <NoRoleErrorWrapper>
    <ReportProblemIcon />
    <Typography>
      Brak uprawnień. W celu wyjaśnienia nieprawidłowości skontaktuj się z
      administratorem systemu.
    </Typography>
  </NoRoleErrorWrapper>
);

const Dashboard = () => {
  const {
    userData: { role },
  } = useSelector((slice) => slice.auth);

  return (
    <PageWrapper
      width="95%"
      sx={{
        height: role === "no_role" ? "100vh" : "auto",
        position: role === "no_role" ? "relative" : "static",
      }}
    >
      {role === "no_role" ? (
        <NoRoleError />
      ) : (
        <>
          <PageHeader titleText="Strona główna" icon={<HomeIcon />} />
          <BlankSpace />
          <SectionsSetter
            sections={sections}
            defaultSectionOrder={[
              { id: 1, isVisible: true },
              { id: 2, isVisible: true },
              { id: 3, isVisible: true },
              { id: 4, isVisible: true },
            ]}
          />
        </>
      )}
    </PageWrapper>
  );
};

export default Dashboard;
