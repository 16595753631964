import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API
} from '../static/static';


export const getProducts = createAsyncThunk('products/get', async () => {
  const resData = await dataService.get(`${API}products/`);
  return resData;
});

export const getPaginatedProducts = createAsyncThunk('products/get/paginate', async (data) => {
  let {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    products,
    categories
  } = data;
  if (leftIndex === undefined)
    leftIndex = "";
  if (rightIndex === undefined)
    rightIndex = "";
  if (sortingParam === undefined)
    sortingParam = "name";
  if (sortingOrder === undefined)
    sortingOrder = "ascending";
  if (searchingValue === undefined)
    searchingValue = "";
  if (products === undefined)
    products = "";
  if (categories === undefined)
    categories = "";
  const resData = await dataService.get(`${API}products/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&products=${products}&categories=${categories}`);
  return resData;
});

export const getProductsCount = createAsyncThunk('products/get/count', async (data) => {
  const {
    searchingValue,
    products,
    categories
  } = data;
  const resData = await dataService.get(`${API}products/count/?searchingPhrase=${searchingValue}&products=${products}&categories=${categories}`);
  return resData;
});

export const getProduct = createAsyncThunk('product/get', async (_, {
  getState
}) => {
  const {
    chosenProductId
  } = getState().data;
  const resData = chosenProductId ? await dataService.get(`${API}product/${chosenProductId}`) : await dataService.get(`${API}product/${localStorage.getItem('chosenProductId')}`);
  return resData;
});

export const createProduct = createAsyncThunk('product/create', async (data) => {
  const resData = await dataService.post(`${API}product/`, data);
  return resData;
});

export const updateProduct = createAsyncThunk('product/update', async (data, {
  getState
}) => {
  const {
    chosenProductId
  } = getState().data;
  const resData = chosenProductId ? await dataService.put(`${API}product/u/${chosenProductId}`, data) : await dataService.put(`${API}product/u/${localStorage.getItem("chosenProductId")}`, data);
  return resData;
});

export const deleteProduct = createAsyncThunk('product/delete', async (id) => {
  const resData = await dataService.delete(`${API}product/d/${id}`);
  return resData;
});

const initialState = {
  products: null,
  productsCount: 0,
  chosenProduct: null,
  chosenProductId: null,
  isProductLoading: false,
};


export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductId: (state, action) => {
      localStorage.setItem('chosenProductId', action.payload);
      state.chosenProductId = action.payload;
      state.chosenProduct = null;
    },
    setProductLoading: (state, action) => {
      state.isProductLoading = action.payload;
    },
    clearProduct: (state) => {
      state.chosenProduct = null;
      state.chosenProductId = null;
    },
    clearProducts: (state) => {
      state.products = null;
      state.productsCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload.data;
      })
      .addCase(getPaginatedProducts.fulfilled, (state, action) => {
        state.products = action.payload.data;
      })
      // .addCase(getPaginatedProducts.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getProductsCount.fulfilled, (state, action) => {
        state.productsCount = action.payload.data;
      })
      // .addCase(getProductsCount.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      // .addCase(getProducts.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getProduct.fulfilled, (state, action) => {
        const gotProduct = action.payload.data;
        state.chosenProduct = gotProduct;
        state.chosenProductId = gotProduct.id;
        state.isProductLoading = false;
      })
      // .addCase(getProduct.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(createProduct.fulfilled, (state, action) => {
        const newProduct = action.payload.data;
        state.chosenProduct = newProduct;
        state.chosenProductId = newProduct.id;
      })
      // .addCase(createProduct.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(updateProduct.fulfilled, (state, action) => {
        const newProduct = action.payload.data;
        state.chosenProduct = newProduct;
        state.chosenProductId = newProduct.id;
      });
    // .addCase(updateProduct.rejected, (_, action) => {
    //   alert(`Operation failed - ${action.error.message}`);
    // })
  }
});

export const {
  setProductId,
  setProductLoading,
  clearProduct,
  clearProducts,
} = productSlice.actions;

export default productSlice.reducer;