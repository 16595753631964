/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useRef } from "react";
import LocationDevicesItem from "../LocationDevicesItem/LocationDevicesItem";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDevicesData,
  getDevicesCount,
  getPaginatedDevices,
} from "../../../../slices/deviceSlice";
import { Add, Devices, Info } from "@mui/icons-material";
import Pagination from "../../../Pagination/Pagination";
import Loading from "../../../Loading/Loading";
import PageHeader from "../../../PageHeader/PageHeader";
import CustomSearchField from "../../../CustomSearchField/CustomSearchField";
import TableHeader from "../../../TableHeader/TableHeader";
import EmptyListError from "../../../EmptyListError/EmptyListError";
import { locationDevicesViewHeaders } from "../../../../static/tablesHeaders";
import { DetailsButton } from "../../../FunctionalButtons/FunctionalButtons";
import { useNavigate } from "react-router-dom";
import { setChosenLocationId } from "../../../../slices/locationSlice";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import MobileDataSorter from "../../../MobileDataSorter/MobileDataSorter";
import TableWrapper from "../../../TableWrapper/TableWrapper";
import usePagination from "../../../../hooks/usePagination";

const LocationDevices = ({ locationCode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchingRef = useRef("");
  const {
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("name", "ascending");

  const { devices, devicesCount } = useSelector((slice) => slice.device);

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getDevicesCount({
        searchingValue,
        location: locationCode,
        type: "",
        orientation: "",
        createdBy: "",
        lastModifiedBy: "",
        creationDateStart: "",
        creationDateEnd: "",
        lastModificationDateStart: "",
        lastModificationDateEnd: "",
        isSynchronized: "",
      })
    );
    setIsDataLoading(false);
  }, [searchingValue, locationCode]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedDevices({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        location: locationCode,
        type: "",
        orientation: "",
        createdBy: "",
        lastModifiedBy: "",
        creationDateStart: "",
        creationDateEnd: "",
        lastModificationDateStart: "",
        lastModificationDateEnd: "",
        isSynchronized: "",
      })
    );
    setIsDataLoading(false);
  }, [leftIndex, rightIndex, sorter, sorting, searchingValue, locationCode]);

  useEffect(() => loadDataCount(), [loadDataCount]);
  useEffect(() => loadData(), [loadData]);

  useEffect(() => {
    return () => clearDevicesData();
  }, []);

  return (
    <SectionWrapper>
      <PageHeader
        titleText="Urządzenia"
        icon={<Devices />}
        size="medium"
        onRightComponent={
          <DetailsButton
            startIcon={<Add />}
            onClick={() => {
              dispatch(setChosenLocationId(locationCode));
              navigate("/device-add");
            }}
            text="Dodaj urządzenie"
          />
        }
        styleProps={{ border: "" }}
      />
      <AdditionalFunctionalitiesWrapper>
        <CustomSearchField
          displaySearchingValue={displaySearchingValue}
          setDisplaySearchingValue={setDisplaySearchingValue}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
          searchingRef={searchingRef}
          placeholder="szukaj urządzenia..."
          width="30%"
        />
        <MobileDataSorter
          tableHeader={locationDevicesViewHeaders}
          setOuterSorter={setSorter}
          setOuterSorting={setSorting}
          defaultSorter="name"
        />
      </AdditionalFunctionalitiesWrapper>
      <TableWrapper>
        <TableHeader
          headers={locationDevicesViewHeaders}
          setSorter={setSorter}
          setSorting={setSorting}
          gap="1%"
        />
        <Pagination
          collectionSize={devicesCount}
          leftIndex={leftIndex}
          rightIndex={rightIndex}
          setLeftIndex={setLeftIndex}
          setRightIndex={setRightIndex}
        >
          {isDataLoading ? (
            <Loading />
          ) : (
            <>
              {devices !== null &&
                devices.map((device, id) => (
                  <LocationDevicesItem
                    key={device.id}
                    device={device}
                    orderNumber={leftIndex + id + 1}
                  />
                ))}
            </>
          )}
          {devices !== null && devices.length === 0 && (
            <EmptyListError
              message="Brak urządzeń w danej lokalizacji"
              icon={<Info />}
            />
          )}
        </Pagination>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default LocationDevices;
