/* eslint-disable react/prop-types */
import React, { useState } from "react";
import DeleteButton from "../../DeleteButton/DeleteButton";
import DeleteUnitForm from "./DeleteUnitForm";

const DeleteUnit = ({ unitId, setRefresh }) => {
  const [isUnitDeleteModalOpen, setIsUnitDeleteModalOpen] = useState(false);
  return (
    <>
      <DeleteButton onClick={() => setIsUnitDeleteModalOpen(true)} />
      {isUnitDeleteModalOpen && (
        <DeleteUnitForm
          unitId={unitId}
          isUnitDeleteModalOpen={isUnitDeleteModalOpen}
          setIsUnitDeleteModalOpen={setIsUnitDeleteModalOpen}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default DeleteUnit;
