/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Button, CircularProgress, Fab, Tooltip } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import {
  Article,
  Check,
  Clear,
  Edit,
  KeyboardArrowLeft,
} from "@mui/icons-material";
import useMediaQ from "../../hooks/useMediaQ";

const CustomButton = styled(Button)(({ theme }) => ({
  width: "auto",
  fontSize: "0.75rem",
  height: "1.8125rem",
  [theme.breakpoints.down("desktop")]: {
    fontSize: "0.6rem",
  },
}));

const ResponsiveButton = ({
  isMobileViewDisabled = false,
  startIcon,
  endIcon,
  text,
  disabled,
  onClick,
  backgroundColor,
  stylesProps,
  afterLoading = false,
}) => {
  const { isMobileDisplay } = useMediaQ();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    onClick();
    if (afterLoading) {
      setIsLoading(true);
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 6000);
      clearTimeout(timeoutId);
    }
  };

  return (
    <>
      {isMobileDisplay && !isMobileViewDisabled && (
        <Tooltip title={text}>
          <span>
            <Fab
              onClick={handleClick}
              size="small"
              disabled={disabled || isLoading}
              sx={{ bgcolor: backgroundColor, color: "white" }}
            >
              {!isLoading ? startIcon : <CircularProgress size="1.5rem" />}
            </Fab>
          </span>
        </Tooltip>
      )}
      {(!isMobileDisplay || (isMobileDisplay && isMobileViewDisabled)) && (
        <CustomButton
          onClick={handleClick}
          variant="contained"
          size="small"
          disabled={disabled || isLoading}
          startIcon={
            !isLoading ? startIcon : <CircularProgress size="1.5rem" />
          }
          endIcon={endIcon}
          sx={{ bgcolor: backgroundColor, ...stylesProps }}
        >
          {text}
        </CustomButton>
      )}
    </>
  );
};

export const DetailsButton = ({
  onClick,
  startIcon = <Article />,
  endIcon = <></>,
  text = "szczegóły",
  styles: stylesProps,
  disabled = false,
  isMobileViewDisabled = false,
  children,
  afterLoading = false,
  clickLock = false,
}) => {
  const { palette } = useTheme();

  const [wasClicked, setWasClicked] = useState(false);

  const handleClick = () => {
    onClick();
    if (clickLock) {
      setWasClicked(true);
    }
  };

  return (
    <ResponsiveButton
      onClick={handleClick}
      startIcon={
        wasClicked ? (
          <CircularProgress sx={{ color: "gray" }} size="1rem" />
        ) : (
          startIcon
        )
      }
      endIcon={endIcon}
      text={text}
      disabled={disabled || wasClicked}
      stylesProps={stylesProps}
      backgroundColor={palette.primary.main}
      isMobileViewDisabled={isMobileViewDisabled}
      afterLoading={afterLoading}
    >
      {children}
    </ResponsiveButton>
  );
};

export const EditButton = ({
  onClick,
  startIcon = <Edit />,
  endIcon = <></>,
  text = "Edytuj",
  styles: stylesProps = {},
  disabled = false,
  isMobileViewDisabled = false,
}) => {
  const { palette } = useTheme();

  return (
    <ResponsiveButton
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      text={text}
      disabled={disabled}
      stylesProps={stylesProps}
      backgroundColor={palette.secondary.main}
      isMobileViewDisabled={isMobileViewDisabled}
    />
  );
};

export const ReturnButton = ({
  onClick,
  startIcon = <KeyboardArrowLeft />,
  endIcon = <></>,
  text = "powrót",
  styles: stylesProps,
  disabled = false,
  isMobileViewDisabled = false,
}) => {
  return (
    <DetailsButton
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      text={text}
      disabled={disabled}
      stylesProps={stylesProps}
      isMobileViewDisabled={isMobileViewDisabled}
    />
  );
};

export const CloseButton = ({
  onClick,
  startIcon = <Clear />,
  endIcon = <></>,
  text = "zamknij",
  styles: stylesProps = {},
  disabled = false,
  isMobileViewDisabled = false,
}) => {
  return (
    <EditButton
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      text={text}
      disabled={disabled}
      styles={{ ...stylesProps, "&:hover": { backgroundColor: "#595c5a" } }}
      isMobileViewDisabled={isMobileViewDisabled}
    />
  );
};
export const AcceptButton = ({
  onClick,
  startIcon = <Check />,
  endIcon = <></>,
  text = "potwierdź",
  styles: stylesProps,
  disabled = false,
  isMobileViewDisabled = false,
  afterLoading = false,
}) => {
  return (
    <ResponsiveButton
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      text={text}
      disabled={disabled}
      stylesProps={{
        ...stylesProps,
        "&:hover": {
          background: "#439603",
        },
      }}
      backgroundColor="#6ed91c"
      isMobileViewDisabled={isMobileViewDisabled}
      afterLoading={afterLoading}
    />
  );
};
