/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setStorageId } from "../../slices/storageSlice";
import { useNavigate } from "react-router-dom";
import StorageDeleteForm from "../Storage/StorageDeleteForm";
import useMediaQ from "../../hooks/useMediaQ";
import StorageItemDesktop from "./StorageItemVersions/StorageItemDesktop";
import StorageItemMobile from "./StorageItemVersions/StorageItemMobile";

const StorageItem = ({ orderNumber, data, setRefresh }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isDesktopDisplay } = useMediaQ();

  const { id } = data;

  const storageDetails = () => {
    dispatch(setStorageId(id));
    navigate(`/storages/${id}`);
  };

  const editStorage = () => {
    dispatch(setStorageId(id));
    navigate("/storage-edit");
  };

  return (
    <>
      {isDesktopDisplay ? (
        <StorageItemDesktop
          orderNumber={orderNumber}
          storage={data}
          storageDetails={storageDetails}
          editStorage={editStorage}
          setIsModalVisible={setIsModalVisible}
        />
      ) : (
        <StorageItemMobile
          storage={data}
          storageDetails={storageDetails}
          editStorage={editStorage}
          setIsModalVisible={setIsModalVisible}
        />
      )}
      <StorageDeleteForm
        isModalVisible={isModalVisible}
        storageId={id}
        storageName={name}
        setVisibility={setIsModalVisible}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default StorageItem;
