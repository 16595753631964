/* eslint-disable react/prop-types */
import React from "react";
import TableWrapper from "../TableWrapper/TableWrapper";
import TableHeader from "../TableHeader/TableHeader";
import { storageReportUnitListHeaders } from "../../static/tablesHeaders";
import StorageReportUnitItem from "./StorageReportUnitItem";
import SectionWrapper from "../SectionWrapper/SectionWrapper";

const StorageReportUnits = ({ units }) => {
  return (
    <SectionWrapper>
      <TableWrapper>
        <TableHeader
          headers={storageReportUnitListHeaders}
          setSorter={() => {}}
          setSorting={() => {}}
          gap="1%"
        />
        <>
          {units !== null &&
            units.map((unit, id) => (
              <StorageReportUnitItem
                orderNumber={id + 1}
                key={id}
                unit={unit}
                setRefresh={() => {}}
              />
            ))}
        </>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default StorageReportUnits;
