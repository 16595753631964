/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
} from "../../ObjectDetailsElements/ObjectDetailsElements";
import { useTheme } from "@mui/material/styles";
import GeoLocationIcon from "../../GeoLocation/GeoLocationIcon";
import { dateFormatView } from "../../../static/functions";

const TitleWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  gap: "0.5rem",
  cursor: "pointer",
  width: "100%",
}));

const CustomDataBox = ({ children }) => {
  const { breakpoints } = useTheme();
  return (
    <DataBox
      sx={{
        flexBasis: "100%",
        [breakpoints.down("desktop")]: {
          flexBasis: "100%",
        },
      }}
    >
      {children}
    </DataBox>
  );
};

const TaskExecutionCompactData = ({ task }) => {
  const [isDataDisplay, setIsDataDisplay] = useState(true);

  return (
    <>
      <TitleWrapper onClick={() => setIsDataDisplay((state) => !state)}>
        <Typography sx={{ fontSize: "1.2rem" }}>Dane zlecenia:</Typography>
        {isDataDisplay ? <ArrowDropUp /> : <ArrowDropDown />}
      </TitleWrapper>
      {isDataDisplay && (
        <InfoWrapper
          sx={{
            paddingBottom: isDataDisplay ? "0.5rem" : "",
            borderBottom: isDataDisplay ? "0.0625rem solid #ccc" : "none",
          }}
        >
          <CustomDataBox>
            <DataTitle>Firma:</DataTitle>
            <Typography>{task.location.company.name}</Typography>
          </CustomDataBox>
          <CustomDataBox>
            <DataTitle>Nazwa lokalizacji:</DataTitle>
            <Typography>{task.location.name}</Typography>
          </CustomDataBox>
          <CustomDataBox>
            <DataTitle>Kod lokalizacji:</DataTitle>
            <Typography>{task.location.code}</Typography>
          </CustomDataBox>
          <CustomDataBox>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <DataTitle>Adres:</DataTitle>
              <GeoLocationIcon query={task.location.address.linkedVersion} />
            </Box>
            <Typography
              sx={{ textAlign: "justify", textJustify: "inter-word" }}
            >
              {task.location.address.linkedVersion}
            </Typography>
          </CustomDataBox>
          <CustomDataBox>
            <DataTitle>Osoba kontaktowa:</DataTitle>
            <Typography>{task.location.contactPerson}</Typography>
          </CustomDataBox>
          <CustomDataBox>
            <DataTitle>Telefon:</DataTitle>
            <Typography>{task.location.contactPhone}</Typography>
          </CustomDataBox>
          {task.type === "Work" && (
            <CustomDataBox>
              <DataTitle>Przypisany wykonawca:</DataTitle>
              <Typography>{`${task.assignedExecutor.name} ${task.assignedExecutor.surname}`}</Typography>
            </CustomDataBox>
          )}
          {task.type === "Work" && (
            <CustomDataBox>
              <DataTitle>Planowania data realizacji:</DataTitle>
              <Typography>
                {dateFormatView(task.planningExecutionDate)}
              </Typography>
            </CustomDataBox>
          )}
        </InfoWrapper>
      )}
    </>
  );
};

export default TaskExecutionCompactData;
