/* eslint-disable react/prop-types */
import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CompactDataDisplay from '../../CompactDataDisplay/CompactDataDisplay';
import DetailsButton from '../../DetailsButton/DetailsButton';
import EditButton from '../../EditButton/EditButton';
import DeleteButton from '../../DeleteButton/DeleteButton';

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
}));

const StorageItemMobile = ({
  storageDetails,
  editStorage,
  setIsModalVisible,
  storage
}) => {
  const {
    id,
    name,
    user: { name: userName, surname: userSurname },
    address: { street, city, country, postcode },
  } = storage;

  const data = [
    { title: "Nazwa magazynu:", value: name },
    { title: "Przypisany użytkownik:", value: `${userSurname} ${userName}` },
    { title: "Adres:", value: `${city}, ${street}, ${country}, ${postcode}` },
  ];

  return (
    <CompactDataDisplay
      title={`Magazyn #${id}`}
      data={data}
      actions={
        <ButtonWrapper>
          <DetailsButton onClick={() => storageDetails()} text="szczegóły" />
          <EditButton onClick={() => editStorage()} />
          <DeleteButton onClick={() => setIsModalVisible(true)} />
        </ButtonWrapper>
      }
    />
  );
};

export default StorageItemMobile;