/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import Loading from "../Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUtilizationReport,
  getUtilizationReport,
} from "../../slices/utilizationReportSlice";
import PageWrapper from "../PageWrapper/PageWrapper";
import PageHeader from "../PageHeader/PageHeader";
import { ClearAll } from "@mui/icons-material";
import UtilizationReportUnitsList from "./UtilizationReportUnitsList/UtilizationReportUnitsList";
import UtilizationReportDetails from "./UtilizationReportDetails";
import BlankSpace from "../BlankSpace/BlankSpace";
import { useParams } from "react-router-dom";
import ReturnButton from "../ReturnButton/ReturnButton";

const UtilizationReport = () => {
  const dispatch = useDispatch();
  const { utilizationReportId } = useParams();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { chosenUtilizationReport } = useSelector(
    (slice) => slice.utilizationReport
  );
  const getData = useCallback(async () => {
    setIsDataLoading(true);
    dispatch(getUtilizationReport(utilizationReportId));
    setIsDataLoading(false);
  }, [utilizationReportId]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => dispatch(clearUtilizationReport());
  }, []);

  if (isDataLoading) return <Loading />;

  return (
    <>
      {chosenUtilizationReport && (
        <PageWrapper width="90%">
          <PageHeader
            icon={<ClearAll />}
            titleText="Raport utylizacyjny"
            onRightComponent={<ReturnButton />}
          />
          <BlankSpace />
          <UtilizationReportDetails
            chosenUtilizationReport={chosenUtilizationReport}
          />
          <BlankSpace />
          <UtilizationReportUnitsList
            chosenUtilizationReport={chosenUtilizationReport}
          />
        </PageWrapper>
      )}
    </>
  );
};

export default UtilizationReport;
