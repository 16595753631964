/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import CommissionTaskItem from "../CommissionTasksItem/CommissionTaskItem";
import PageHeader from "../../../../PageHeader/PageHeader";
import { Task, Warning } from "@mui/icons-material";
import TableHeader from "../../../../TableHeader/TableHeader";
import { commissionTasksViewHeaders } from "../../../../../static/tablesHeaders";
import EmptyListError from "../../../../EmptyListError/EmptyListError";
import SectionWrapper from "../../../../SectionWrapper/SectionWrapper";

const CommissionTaskView = ({ setRefresh, tasks, chosenUser }) => {
  return (
    <SectionWrapper>
      <PageHeader titleText="Zadania" icon={<Task />} size="medium" />
      <TableHeader
        headers={commissionTasksViewHeaders}
        setSorter={() => {}}
        setSorting={() => {}}
        gap="1%"
      />
      <>
        {tasks !== null && tasks.length > 0 ? (
          tasks.map((task, id) => (
            <CommissionTaskItem
              key={task.id}
              data={task}
              orderNumber={id + 1}
              setRefresh={setRefresh}
              chosenUser={chosenUser}
            />
          ))
        ) : (
          <EmptyListError message="Brak zadań w zleceniu" icon={<Warning />} />
        )}
      </>
    </SectionWrapper>
  );
};

export default CommissionTaskView;
