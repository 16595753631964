/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Dialog } from "@mui/material";
import CategoryDeleteForm from "../../Category/CategoryDeleteForm/CategoryDeleteForm";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCategoryId } from "../../../slices/categorySlice";
import useMediaQ from "../../../hooks/useMediaQ";
import CategoryItemDesktop from "./CategoryItemVersions/CategoryItemDesktop";
import CategoryItemMobile from "./CategoryItemVersions/CategoryItemMobile";

const CategoryItem = ({ orderNumber, category, setRefresh }) => {
  const { isDesktopDisplay } = useMediaQ();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, name } = category;
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  // const isLargeDisplayStart = useMediaQuery("(min-width:1200px)");

  const editCategory = () => {
    dispatch(setCategoryId(id));
    navigate("/category-edit");
  };

  const delCategory = () => {
    setIsDeleteModalVisible(true);
  };

  return (
    <>
      {isDesktopDisplay ? (
        <CategoryItemDesktop
          orderNumber={orderNumber}
          category={category}
          editCategory={editCategory}
          delCategory={delCategory}
        />
      ) : (
        <CategoryItemMobile
          orderNumber={orderNumber}
          category={category}
          editCategory={editCategory}
          delCategory={delCategory}
        />
      )}

      {isDeleteModalVisible && (
        <Dialog
          open={isDeleteModalVisible}
          onClose={() => setIsDeleteModalVisible(false)}
        >
          <CategoryDeleteForm
            categoryId={id}
            categoryName={name}
            setVisibility={setIsDeleteModalVisible}
            setRefresh={setRefresh}
          />
        </Dialog>
      )}
    </>
  );
};

export default CategoryItem;
