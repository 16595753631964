/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserByProvinceAndRole } from "../../../slices/userSlice";
import AssignmentFinalModal from "./AssignmentFinalModal";
import { Check, Clear } from "@mui/icons-material";
import { polishProvinces } from "../../../static/static";
import authService from "../../../auth/authService";
import AcceptButton from "../../AcceptButton/AcceptButton";
import EditButton from "../../EditButton/EditButton";
import TaskExecutionCompactData from "../../TaskView/TaskExecution/TaskExecutionCompactData";
import ReactDatePicker from "react-datepicker";
import CustomDateInput from "../../CustomDateInput/CustomDateInput";

const Wrapper = styled(Box)(({ theme }) => ({
  height: "40rem",
  width: "31.25rem",
  overflowY: "auto",
  padding: "0.5rem",
  [theme.breakpoints.down("laptop")]: {
    width: "25rem",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "16rem",
  },
}));

const ContentWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "80%",
  margin: "1.875em auto 0 auto",
  gap: "0.625em",
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "1.875rem",
  marginTop: "1rem",
  [theme.breakpoints.down("laptop")]: {
    gap: "0.5rem",
  },
}));

const AssignmentModal = ({
  taskId,
  setIsAssignmentModalVisible,
  locationProvince = "",
  setRefresh,
  task,
}) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const [chosenUser, setChosenUser] = useState("");
  const [chosenProvince, setChosenProvince] = useState(locationProvince);
  const [description, setDescription] = useState("");
  const [isChosenUserError, setIsChosenUserError] = useState(false);
  const [isFinalModalVisible, setIsFinalModalVisible] = useState(false);
  const [planningExecutionDate, setPlanningExecutionDate] = useState(
    new Date()
  );

  const getUserData = useCallback(async () => {
    await dispatch(
      getUserByProvinceAndRole({
        province: chosenProvince === "wszystkie" ? "" : chosenProvince,
        role: "service_technician",
      })
    );
  }, [chosenProvince]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const openModal = () => {
    if (chosenUser === "") {
      setIsChosenUserError(true);
    } else {
      setIsFinalModalVisible(true);
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <Typography
          sx={{
            fontSize: "1.875rem",
            width: "100%",
            textAlign: "center",
          }}
        >
          Wybierz proponowanego serwisanta
        </Typography>
        <Typography
          sx={{
            fontSize: "0.8rem",
            marginBottom: "0.75rem",
            color: "red",
          }}
        >
          Pola oznaczone * są obowiązkowe
        </Typography>
        <TaskExecutionCompactData task={task} />
        <InputLabel
          id="province-select-label"
          sx={{
            alignSelf: "start",
            fontSize: "1.2rem",
          }}
        >
          Województwo
        </InputLabel>
        <Select
          labelId="province-select-label"
          id="province-select-label"
          value={chosenProvince}
          onChange={(e) => setChosenProvince(e.target.value)}
          sx={{
            width: "100%",
            margin: "0 auto",
          }}
          MenuProps={{
            classes: {
              paper: {
                maxHeight: "10em",
              },
            },
          }}
        >
          <MenuItem key="" value="wszystkie">
            Wszystkie
          </MenuItem>
          {polishProvinces.map((province, index) => (
            <MenuItem key={index} value={province}>
              {province}
            </MenuItem>
          ))}
        </Select>
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            alignSelf: "start",
            fontSize: "1.2rem",
          }}
        >
          Serwisant*
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={chosenUser}
          onChange={(e) => {
            setChosenUser(e.target.value);
            setIsChosenUserError(false);
          }}
          error={isChosenUserError}
          sx={{
            width: "100%",
            margin: "0 auto",
          }}
        >
          {users !== null && users.length > 0 ? (
            users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {`${user.name} ${user.surname}`}
              </MenuItem>
            ))
          ) : (
            <MenuItem key={0} value={""} disabled>
              {`Nie znaleziono`}
            </MenuItem>
          )}
        </Select>
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            alignSelf: "start",
            fontSize: "1.2rem",
          }}
        >
          Przewidywana data realizacji*
        </InputLabel>
        <ReactDatePicker
          selected={planningExecutionDate}
          onChange={(date) => setPlanningExecutionDate(date)}
          startDate={planningExecutionDate}
          minDate={new Date()}
          dateFormat="yyyy-MM-dd"
          customInput={<CustomDateInput />}
          withPortal
        />
        <TextField
          label="Opis decyzji"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={10}
          sx={{
            width: "100%",
          }}
        />
        {isChosenUserError && (
          <Typography
            sx={{
              color: "red",
              fontSize: "0.75rem",
              gap: 0,
            }}
          >
            Pole nie może być puste
          </Typography>
        )}
        <ButtonWrapper>
          <AcceptButton
            onClick={() => openModal()}
            startIcon={<Check />}
            disabled={chosenUser === ""}
            text="zatwierdź"
          />
          <EditButton
            onClick={() => setIsAssignmentModalVisible(false)}
            startIcon={<Clear />}
            text="anuluj"
          />
        </ButtonWrapper>
        {isFinalModalVisible && (
          <Dialog
            open={isFinalModalVisible}
            onClose={() => setIsFinalModalVisible(false)}
          >
            <AssignmentFinalModal
              data={{
                taskId,
                proposedExecutor: chosenUser,
                description,
                planningExecutionDate: planningExecutionDate.getTime(),
                executor: authService.getDecodedAccessToken().user_id,
              }}
              setIsFinalModal={setIsFinalModalVisible}
              setIsAssignmentModalVisible={setIsAssignmentModalVisible}
              setRefresh={setRefresh}
            />
          </Dialog>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default AssignmentModal;
