/* eslint-disable react/prop-types */
import React from 'react';
import { dateFormatView } from '../../../../static/functions';
import CustomChip from '../../../CustomChip/CustomChip';
import { shipmentStatus, shipmentStatusChipStyles } from '../../../../static/static';
import CompactDataDisplay from '../../../CompactDataDisplay/CompactDataDisplay';
import { Box } from '@mui/material';
import DetailsButton from '../../../DetailsButton/DetailsButton';
import DeleteButton from '../../../DeleteButton/DeleteButton';

const ShipmentItemMobile = ({ shipment, navigateToItem, deleteItem }) => {
  const {
    id,
    sendDate,
    deliveredDate,
    letterNumber,
    creationDate,
    baseStorage: { name: baseStorageName },
    destinationStorage: { name: destinationStorageName },
    status,
  } = shipment;

  const data = [
    {
      title: "Data utworzenia:",
      value: creationDate ? dateFormatView(creationDate) : "Brak",
    },
    {
      title: "Data wysłania:",
      value: sendDate ? dateFormatView(sendDate) : "Brak",
    },
    {
      title: "Data otrzymania:",
      value: deliveredDate ? dateFormatView(deliveredDate) : "Brak",
    },
    { title: "Nr listu przew.:", value: letterNumber },
    { title: "Magazyn bazowy:", value: baseStorageName },
    { title: "Magazyn docelowy:", value: destinationStorageName },
    {
      title: "Status:",
      value: (
        <CustomChip label={shipmentStatus[status]} sx={shipmentStatusChipStyles(status, "")} />
      ),
    },
  ];
  return (
    <CompactDataDisplay
      title={`Przesyłka #${id}`}
      data={data}
      actions={
        <>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DetailsButton onClick={() => navigateToItem()} text="szczegóły" />
            {status === "Created" && (
              <DeleteButton onClick={() => deleteItem()} variant="contained" />
            )}
          </Box>
        </>
      }
    />
  );
};

export default ShipmentItemMobile;