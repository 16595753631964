/* eslint-disable react/prop-types */
import React from "react";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Clear } from "@mui/icons-material";

const CustomButtonWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
}));

const CustomButton = styled(Button)(({ theme }) => ({
  width: "auto",
  backgroundColor: "#fc5b5b",
  fontSize: "0.75rem",
  height: "1.8125rem",
  "&:hover": {
    backgroundColor: "#de200b",
  },
  [theme.breakpoints.down("desktop")]: {
    fontSize: "0.6rem",
  },
}));

const DeleteButton = ({
  onClick,
  startIcon = <Clear/>,
  text = "usuń",
  styles: stylesProps,
  disabled
}) => {
  return (
    <CustomButtonWrapper>
      <CustomButton
        onClick={() => onClick()}
        variant="contained"
        sx={stylesProps}
        size="small"
        startIcon={startIcon}
        disabled={disabled}
      >
        {text}
      </CustomButton>
    </CustomButtonWrapper>
  );
};

export default DeleteButton;
