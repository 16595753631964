/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import MoveUnitFieldDesktop from "./MoveUnitFieldVersions/MoveUnitFieldDesktop";
import MoveUnitFieldMobile from "./MoveUnitFieldVersions/MoveUnitFieldMobile";


const MoveUnitField = ({ unit, index, removeItem, orderNumber }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <MoveUnitFieldDesktop
          orderNumber={orderNumber}
          removeItem={removeItem}
          unit={unit}
          index={index}
        />
      ) : (
        <MoveUnitFieldMobile
          orderNumber={orderNumber}
          removeItem={removeItem}
          unit={unit}
          index={index}
        />
      )}
    </>
  );
};

export default MoveUnitField;
