import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const InfoWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "start",
  flexWrap: "wrap",
  width: '100%',
  gap: "1rem",
}));

export const DataBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0 0.5rem",
  flexWrap: "wrap",
  alignItems: "center",
  flexBasis: "22%",
  justifyContent: 'start',
  [theme.breakpoints.down("laptop")]: {
    flexBasis: "48%",
  },
  [theme.breakpoints.down("tablet")]: {
    flexBasis: "99%",
  },
}));

export const SpecialDataBox = styled(DataBox)(() => ({
  flexGrow: 1,
  flexBasis: "99%",
}));

export const DataTitle = styled(Typography)(() => ({
  fontWeight: 700,
}));
