/* eslint-disable react/prop-types */
import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import authService from "../../../auth/authService";
import BlankSpace from "../../BlankSpace/BlankSpace";
import { Box, IconButton, Slide, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import SectionSetterModal from "../SectionsSetterModal/SectionSetterModal";
import { storageDataService } from "../../../storageDataService/storageDataService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditSectionLine = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "-0.7%",
  left: "-1%",
  width: "102%",
  height: "0.0625rem",
  borderTop: "0.0625rem dotted #bbb",
  "&:hover": {
    borderTopColor: "black",
  },
  [theme.breakpoints.down("desktop")]: {
    top: "-0.45%",
  },
  [theme.breakpoints.down("laptop")]: {
    top: "-0.35%",
  },
  [theme.breakpoints.down("tablet")]: {
    top: "-0.3%",
  },
}));

const EditButton = ({ handleClick }) => (
  <Tooltip
    title="Edytuj sekcję"
    sx={{
      position: "absolute",
      left: "50%",
      top: "-1.3rem",
      transform: "translate(-50%, 0)",
    }}
  >
    <IconButton onClick={handleClick}>
      <Edit
        sx={{
          color: "#bbb",
          "&:hover": {
            color: "black",
          },
        }}
      />
    </IconButton>
  </Tooltip>
);

const SectionsSetter = ({ defaultSectionOrder, sections, dataStorageName }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentSectionOrder, setCurrentSectionOrder] = useState(
    storageDataService.getItem(dataStorageName)
      ? storageDataService.getItem(dataStorageName)
      : defaultSectionOrder
  );
  const { role } = authService.getDecodedAccessToken();
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <EditSectionLine>
          <EditButton handleClick={() => setIsEditModalOpen(true)} />
        </EditSectionLine>
        {currentSectionOrder
          .filter((currentSection) => currentSection.isVisible)
          .map((currentSection) => {
            const currentSectionId = currentSection.id;
            const section = sections.filter(
              (section) => section.id === currentSectionId
            )[0];
            if (section.roles.length > 0) {
              if (section.roles.includes(role))
                return (
                  <Fragment key={currentSectionId}>
                    {section.component}
                    <BlankSpace />
                  </Fragment>
                );
              return <Fragment key={currentSectionId} />;
            } else {
              return (
                <Fragment key={currentSectionId}>
                  {section.component}
                  <BlankSpace />
                </Fragment>
              );
            }
          })}
      </Box>
      <SectionSetterModal
        open={isEditModalOpen}
        Transition={Transition}
        handleClose={() => setIsEditModalOpen(false)}
        sections={sections}
        defaultSectionOrder={currentSectionOrder}
        setDefaultSectionOrder={setCurrentSectionOrder}
        dataStorageName={dataStorageName}
      />
    </>
  );
};

export default SectionsSetter;
