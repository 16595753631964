/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import ShortenTaskDetails from "./ShortenTaskDetails";
import PageHeader from "../../PageHeader/PageHeader";
import { ArrowDownward, ArrowUpward, Info } from "@mui/icons-material";
import TableHeader from "../../TableHeader/TableHeader";
import { unitListHeaders } from "../../../static/tablesHeaders";
import UnitItem from "../../UnitsList/UnitItem/UnitItem";
import EmptyListError from "../../EmptyListError/EmptyListError";

const Wrapper = styled(Box)(() => ({
  width: "95%",
  margin: "0 auto",
  padding: "1.25rem",
  border: "0.125rem solid #bbb",
  borderRadius: "0.625rem",
}));

const InnerWrapper = styled(Box)(() => ({
  marginTop: "1rem",
}));

const UnitMigrationHistoryItem = ({ workSet, taskData }) => {
  const { task, mountedUnits, unmountedUnits } = taskData ? taskData : workSet;

  return (
    <Wrapper
      sx={
        taskData ? { width: "100%", border: "none", padding: 0, margin: 0 } : {}
      }
    >
      {!taskData && <ShortenTaskDetails task={task} />}
      <InnerWrapper>
        <PageHeader
          titleText="Zamontowane komponenty"
          icon={<ArrowDownward />}
          size="medium"
        />

        <TableHeader
          headers={unitListHeaders}
          setSorter={() => {}}
          setSorting={() => {}}
          gap="0.625rem"
        />
        <>
          {mountedUnits !== null && mountedUnits.length > 0 ? (
            mountedUnits.map((unit, id) => (
              <UnitItem orderNumber={id + 1} key={id} unit={unit} />
            ))
          ) : (
            <EmptyListError
              message="Brak części zamontowanych"
              icon={<Info />}
            />
          )}
        </>
      </InnerWrapper>
      <InnerWrapper>
        <PageHeader
          titleText="Zdemontowane komponenty"
          icon={<ArrowUpward />}
          size="medium"
        />
        <TableHeader
          headers={unitListHeaders}
          setSorter={() => {}}
          setSorting={() => {}}
          gap="0.625rem"
        />
        <>
          {unmountedUnits !== null && unmountedUnits.length > 0 ? (
            unmountedUnits.map((unit, id) => (
              <UnitItem orderNumber={id + 1} key={id} unit={unit} />
            ))
          ) : (
            <EmptyListError
              message="Brak części zdemontowanych"
              icon={<Info />}
            />
          )}
        </>
      </InnerWrapper>
    </Wrapper>
  );
};

export default UnitMigrationHistoryItem;
