/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CompactDataDisplay from "../../CompactDataDisplay/CompactDataDisplay";
import { dateFormatView } from "../../../static/functions";
import CustomChip from "../../CustomChip/CustomChip";
import {
  shipmentStatus,
  shipmentStatusChipStyles,
} from "../../../static/static";
import ShipmentInventory from "./ShipmentInventory";
import DetailsButton from "../../DetailsButton/DetailsButton";
import { setChosenShipmentId } from "../../../slices/shipmentSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import authService from "../../../auth/authService";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "0.5rem",
}));

const ShipmentsSummaryMobile = ({
  shipment,
  setIsStatusChangeToDeliveredModalVisible,
  setIsStatusChangeToInProgressModalVisible,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    id: shipmentId,
    sendDate,
    letterNumber,
    baseStorage,
    destinationStorage,
    status,
    shipment_units: shipmentUnits,
  } = shipment;

  const navigateToItem = () => {
    dispatch(setChosenShipmentId(shipmentId));
    navigate(`/shipments/${shipmentId}`);
  };

  const { user_id: userId, role } = authService.getDecodedAccessToken();

  const data = [
    { title: "Data wysłania:", value: dateFormatView(sendDate) },
    { title: "Nr listu przew.:", value: letterNumber },
    { title: "Magazyn wysyłkowy:", value: baseStorage.name },
    { title: "Magazyn bazowy:", value: destinationStorage.name },
    {
      title: "Status:",
      value: (
        <CustomChip
          label={shipmentStatus[status]}
          sx={shipmentStatusChipStyles(status, "")}
          width="40%"
        />
      ),
    },
    {
      title: "Zawartość przesyłki:",
      value: <ShipmentInventory shipmentUnits={shipmentUnits} />,
    },
  ];
  return (
    <CompactDataDisplay
      title={`Przesyłka #${shipmentId}`}
      data={data}
      actions={
        <ButtonWrapper>
          {status === "In progress" &&
            userId === destinationStorage.user.id && (
              <DetailsButton
                onClick={() => setIsStatusChangeToDeliveredModalVisible(true)}
                text="Odbierz"
                startIcon={<ArrowDownward />}
              />
            )}
          {status === "Created" &&
            (userId === baseStorage.user.id ||
              role === "admin" ||
              role === "warehouseman") && (
              <DetailsButton
                onClick={() => setIsStatusChangeToInProgressModalVisible(true)}
                text="Wyślij"
                startIcon={<ArrowUpward />}
              />
            )}
          <DetailsButton onClick={() => navigateToItem()} />
        </ButtonWrapper>
      }
    />
  );
};

export default ShipmentsSummaryMobile;
