/* eslint-disable react/prop-types */
import React from "react";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import EditButton from "../../../EditButton/EditButton";
import DeleteButton from "../../../DeleteButton/DeleteButton";

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
}));


const ProductItemMobile = ({
  product,
  orderNumber,
  editProduct,
  delProduct,
}) => {
  const { name, category, producer, partNumber, description } = product;

  const data = [
    { title: "Nazwa:", value: name },
    { title: "Kategoria:", value: category ? category.name : "Brak kategorii" },
    {
      title: "Producent:",
      value: producer ? producer.name : "Brak producenta",
    },
    { title: "PartNumber:", value: partNumber },
    { title: "Opis:", value: description },
  ];

  return (
    <CompactDataDisplay
      title={`Produkt #${orderNumber}`}
      data={data}
      actions={
        <ButtonWrapper>
          <EditButton onClick={() => editProduct()} />
          <DeleteButton onClick={() => delProduct()} />
        </ButtonWrapper>
      }
    />
  );
};

export default ProductItemMobile;
