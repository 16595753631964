import React, { useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import StorageItem from "./StorageItem";
import {
  clearStoragesData,
  getStoragesCount,
  getPaginatedStorages,
} from "../../slices/storageSlice";
import Pagination from "../Pagination/Pagination";
import { Navigate, useNavigate } from "react-router-dom";
import { WarehouseOutlined, Add, Warning } from "@mui/icons-material";
import Loading from "../Loading/Loading";
import RefreshButton from "../RefreshButton/RefreshButton";
import CustomSearchField from "../CustomSearchField/CustomSearchField";
import { DetailsButton} from "../FunctionalButtons/FunctionalButtons";
import TableHeader from "../TableHeader/TableHeader";
import { storagesListHeaders } from "../../static/tablesHeaders";
import EmptyListError from "../EmptyListError/EmptyListError";
import BlankSpace from "../BlankSpace/BlankSpace";
import PageHeader from "../PageHeader/PageHeader";
import PageWrapper from "../PageWrapper/PageWrapper";
import SectionWrapper from "../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import MobileDataSorter from "../MobileDataSorter/MobileDataSorter";
import TableWrapper from "../TableWrapper/TableWrapper";
import usePagination from "../../hooks/usePagination";
import authService from "../../auth/authService";
import ButtonWrapper from "../ButtonWrapper/ButtonWrapper";
import ReturnButton from "../ReturnButton/ReturnButton";

const StoragesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { storages, localStoragesCount, users } = useSelector(
    (slice) => slice.storage
  );
  const { user_id, role } = authService.getDecodedAccessToken();
  const searchingRef = useRef("");
  const {
    refresh,
    setRefresh,
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("name");

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getStoragesCount({
        searchingValue,
        user: role === "service_technician" ? user_id : "",
        storagesType: "local",
      })
    );
    setIsDataLoading(false);
  }, [refresh, searchingValue]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedStorages({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        storagesType: "local",
        user: role === "service_technician" ? user_id : "",
      })
    );
    setIsDataLoading(false);
  }, [refresh, leftIndex, rightIndex, sorter, sorting, searchingValue]);

  useEffect(() => { loadDataCount(); }, [loadDataCount]);

  useEffect(() => { loadData(); }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearStoragesData());
  }, []);

  if (
    role === "service_technician" &&
    storages !== null &&
    storages.length === 1
  )
    return <Navigate to={`/storages/${storages[0].id}`} />;

  return (
    <PageWrapper width="95%">
      <PageHeader
        titleText="Magazyny lokalne"
        icon={<WarehouseOutlined />}
        onRightComponent={
          <ButtonWrapper>
            <DetailsButton
              onClick={() => navigate("/storage-add")}
              startIcon={<Add />}
              text="Dodaj magazyn"
            />
            <ReturnButton/>
          </ButtonWrapper>
        }
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <BlankSpace />
      <SectionWrapper>
        <AdditionalFunctionalitiesWrapper>
          <CustomSearchField
            displaySearchingValue={displaySearchingValue}
            setDisplaySearchingValue={setDisplaySearchingValue}
            searchingValue={searchingValue}
            setSearchingValue={setSearchingValue}
            searchingRef={searchingRef}
            placeholder="szukaj magazynu..."
            width="20%"
            widthMobile="60%"
          />
          <MobileDataSorter
            tableHeader={storagesListHeaders}
            setOuterSorter={setSorter}
            setOuterSorting={setSorting}
            defaultSorter="name"
          />
        </AdditionalFunctionalitiesWrapper>
        <TableWrapper>
          <TableHeader
            headers={storagesListHeaders}
            setSorter={setSorter}
            setSorting={setSorting}
            gap="0.5rem"
          />
          <Pagination
            collectionSize={localStoragesCount}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            setLeftIndex={setLeftIndex}
            setRightIndex={setRightIndex}
          >
            {isDataLoading ? (
              <Loading />
            ) : (
              <>
                {storages !== null &&
                  storages.map((storage, id) => (
                    <StorageItem
                      orderNumber={leftIndex + id + 1}
                      key={storage.id}
                      data={storage}
                      setRefresh={setRefresh}
                      allUsers={users}
                    />
                  ))}
              </>
            )}
            {storages !== null && storages.length === 0 && (
              <EmptyListError message="Brak magazynów" icon={<Warning />} />
            )}
          </Pagination>
        </TableWrapper>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default StoragesList;
