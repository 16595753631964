import React, { useState } from "react";
import PageWrapper from "../PageWrapper/PageWrapper";
import CustomTextField from "../CustomTextField/CustomTextField";

const Sandbox = () => {
  const [value, setValue] = useState("");
  return (
    <PageWrapper>
      <CustomTextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        maxLength={20}
        multiline
        minRows={4}
      />
    </PageWrapper>
  );
};

export default Sandbox;
