import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API,
  errorMessages
} from '../static/static';
import {
  getErrorCode
} from '../static/functions';


export const getShipment = createAsyncThunk('shipment/get', async (shipmentId) => {
  const resData = shipmentId ? await dataService.get(`${API}shipment/${shipmentId}`) : await dataService.get(`${API}shipment/${localStorage.getItem('chosenShipmentId')}`);
  return resData;
});

export const updateShipment = createAsyncThunk('shipment/update', async ({
  shipmentId,
  data
}) => {
  const resData = await dataService.put(`${API}shipment/u/${shipmentId}/`, data);
  return resData;
});

export const deleteShipment = createAsyncThunk('shipment/delete', async (shipmentId) => {
  const resData = await dataService.delete(`${API}shipment/d/${shipmentId}/`);
  return resData;
});

export const deliverShipment = createAsyncThunk('shipment/deliver', async ({
  shipmentId,
  data
}) => {
  const resData = await dataService.put(`${API}shipment/deliver/${shipmentId}/`, data);
  return resData;
});

export const changeToInProgressShipment = createAsyncThunk('shipment/progress', async ({
  shipmentId,
  data
}) => {
  const resData = await dataService.put(`${API}shipment/progress/${shipmentId}/`, data);
  return resData;
});

export const explainShipment = createAsyncThunk('shipment/explain', async (shipmentId) => {
  const resData = await dataService.put(`${API}shipment/explain/${shipmentId}/`, {});
  return resData;
});

export const getShipments = createAsyncThunk('shipments/get', async () => {
  const resData = await dataService.get(`${API}shipments`);
  return resData;
});


export const getShipmentsByStorageDestination = createAsyncThunk('shipments/destination/get', async (storageId) => {
  const resData = await dataService.get(`${API}shipments/destination/${storageId}`);
  return resData;
});

export const getShipmentsByStorageBase = createAsyncThunk('shipments/base/get', async (storageId) => {
  const resData = await dataService.get(`${API}shipments/base/${storageId}`);
  return resData;
});

export const getPaginatedShipments = createAsyncThunk('shipments/get/pagination', async (data) => {
  const {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    status,
    baseStorage,
    destinationStorage,
    creationDateStart,
    creationDateEnd,
    sendDatesStart,
    sendDatesEnd,
    deliveredDatesStart,
    deliveredDatesEnd
  } = data;
  const resData = await dataService.get(`${API}shipments/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&status=${status}&baseStorage=${baseStorage}&destinationStorage=${destinationStorage}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&sendDatesStart=${sendDatesStart}&sendDatesEnd=${sendDatesEnd}&deliveredDatesStart=${deliveredDatesStart}&deliveredDatesEnd=${deliveredDatesEnd}`);
  return resData;
});

export const getShipmentsCount = createAsyncThunk('shipments/get/count', async (data) => {
  const {
    searchingValue,
    status,
    baseStorage,
    destinationStorage,
    creationDateStart,
    creationDateEnd,
    sendDatesStart,
    sendDatesEnd,
    deliveredDatesStart,
    deliveredDatesEnd,
    sender,
    receiver
  } = data;
  const resData = await dataService.get(`${API}shipments/count/?searchingPhrase=${searchingValue}&status=${status}&baseStorage=${baseStorage}&destinationStorage=${destinationStorage}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&sendDatesStart=${sendDatesStart}&sendDatesEnd=${sendDatesEnd}&deliveredDatesStart=${deliveredDatesStart}&deliveredDatesEnd=${deliveredDatesEnd}&sender=${sender}&receiver=${receiver}`);
  return resData;
});

export const getPaginatedShipmentsByDestination = createAsyncThunk('shipments/byDestination/get/pagination', async (data) => {
  const {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    status,
    baseStorage,
    destinationStorage,
    creationDateStart,
    creationDateEnd,
    sendDatesStart,
    sendDatesEnd,
    deliveredDatesStart,
    deliveredDatesEnd,
    sender,
    receiver
  } = data;
  const resData = await dataService.get(`${API}shipments/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&status=${status}&baseStorage=${baseStorage}&destinationStorage=${destinationStorage}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&sendDatesStart=${sendDatesStart}&sendDatesEnd=${sendDatesEnd}&deliveredDatesStart=${deliveredDatesStart}&deliveredDatesEnd=${deliveredDatesEnd}&sender=${sender}&receiver=${receiver}`);
  return resData;
});

export const getShipmentsByDestinationCount = createAsyncThunk('shipments/byDestination/get/count', async (data) => {
  const {
    searchingValue,
    status,
    baseStorage,
    destinationStorage,
    creationDateStart,
    creationDateEnd,
    sendDatesStart,
    sendDatesEnd,
    deliveredDatesStart,
    deliveredDatesEnd,
    sender,
    receiver
  } = data;
  const resData = await dataService.get(`${API}shipments/count/?searchingPhrase=${searchingValue}&status=${status}&baseStorage=${baseStorage}&destinationStorage=${destinationStorage}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&sendDatesStart=${sendDatesStart}&sendDatesEnd=${sendDatesEnd}&deliveredDatesStart=${deliveredDatesStart}&deliveredDatesEnd=${deliveredDatesEnd}&sender=${sender}&receiver=${receiver}`);
  return resData;
});

export const getPaginatedShipmentsByBase = createAsyncThunk('shipments/byBase/get/pagination', async (data) => {
  const {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    status,
    baseStorage,
    destinationStorage,
    creationDateStart,
    creationDateEnd,
    sendDatesStart,
    sendDatesEnd,
    deliveredDatesStart,
    deliveredDatesEnd,
    sender,
    receiver
  } = data;
  const resData = await dataService.get(`${API}shipments/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&status=${status}&baseStorage=${baseStorage}&destinationStorage=${destinationStorage}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&sendDatesStart=${sendDatesStart}&sendDatesEnd=${sendDatesEnd}&deliveredDatesStart=${deliveredDatesStart}&deliveredDatesEnd=${deliveredDatesEnd}&sender=${sender}&receiver=${receiver}`);
  return resData;
});

export const getShipmentsByBaseCount = createAsyncThunk('shipments/byBase/get/count', async (data) => {
  const {
    searchingValue,
    status,
    baseStorage,
    destinationStorage,
    creationDateStart,
    creationDateEnd,
    sendDatesStart,
    sendDatesEnd,
    deliveredDatesStart,
    deliveredDatesEnd
  } = data;
  const resData = await dataService.get(`${API}shipments/count/?searchingPhrase=${searchingValue}&status=${status}&baseStorage=${baseStorage}&destinationStorage=${destinationStorage}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&sendDatesStart=${sendDatesStart}&sendDatesEnd=${sendDatesEnd}&deliveredDatesStart=${deliveredDatesStart}&deliveredDatesEnd=${deliveredDatesEnd}`);
  return resData;
});


const initialState = {
  shipments: {
    all: null,
    chosen: null,
  },
  shipmentsByBase: null,
  shipmentsByDestination: null,
  shipmentsByBaseCount: 0,
  shipmentsByDestinationCount: 0,
  shipmentsCount: 0,
  chosenShipmentId: null,
  shipmentInfo: null,
  shipmentError: null
};

export const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    clearShipment: (state) => {
      state.shipments.chosen = null;
      state.chosenShipmentId = null;
    },
    setChosenShipmentId: (state, action) => {
      localStorage.setItem('chosenShipmentId', action.payload);
      state.chosenShipmentId = action.payload;
    },
    clearShipments: (state) => {
      state.shipments.all = null;
      state.shipmentsCount = 0;
    },
    clearShipmentsByDestination: (state) => {
      state.shipmentsByDestination = null;
      state.shipmentsByDestinationCount = 0;
    },
    clearShipmentsByBase: (state) => {
      state.shipmentsByBase = null;
      state.shipmentsByBaseCount = 0;
    },
    clearShipmentError: (state) => {
      state.shipmentError = null;
    },
    clearShipmentInfo: (state) => {
      state.shipmentInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShipment.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.chosenShipmentId = data.id;
        state.shipments.chosen = data;
      })
      // .addCase(getShipment.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(updateShipment.fulfilled, (state) => {
        state.shipments.chosen = null;
      })
      // .addCase(updateShipment.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(deleteShipment.fulfilled, (state) => {
        state.shipments.chosen = null;
      })
      // .addCase(deleteShipment.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getShipments.fulfilled, (state, action) => {
        state.shipments.all = action.payload.data;
      })
      // .addCase(getShipments.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(changeToInProgressShipment.fulfilled, (state) => {
        state.shipmentInfo = 'Pomyślnie zaktualizowano przesyłkę';
      })
      .addCase(changeToInProgressShipment.rejected, (state, action) => {
        state.shipmentError = errorMessages['shipment'][getErrorCode(action.error.message)];
      })
      .addCase(getPaginatedShipments.fulfilled, (state, action) => {
        state.shipments.all = action.payload.data;
      })
      // .addCase(getPaginatedShipments.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
       .addCase(explainShipment.fulfilled, (state) => {
         state.shipmentInfo = 'Pomyślnie wyjaśniono przesyłkę';
       })
       // .addCase(explainShipment.rejected, (_, action) => {
       //   alert(`Operation failed - ${action.error.message}`);
       // })
      .addCase(getShipmentsCount.fulfilled, (state, action) => {
        state.shipmentsCount = action.payload.data;
      })
      // .addCase(getShipmentsCount.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getPaginatedShipmentsByDestination.fulfilled, (state, action) => {
        state.shipmentsByDestination = action.payload.data;
      })
      // .addCase(getPaginatedShipmentsByDestination.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getShipmentsByDestinationCount.fulfilled, (state, action) => {
        state.shipmentsByDestinationCount = action.payload.data;
      })
      // .addCase(getShipmentsByDestinationCount.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getPaginatedShipmentsByBase.fulfilled, (state, action) => {
        state.shipmentsByBase = action.payload.data;
      })
      // .addCase(getPaginatedShipmentsByBase.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getShipmentsByBaseCount.fulfilled, (state, action) => {
        state.shipmentsByBaseCount = action.payload.data;
      })
      // .addCase(shipmentsByBaseCount.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getShipmentsByStorageDestination.fulfilled, (state, action) => {
        state.shipments.byDestinationStorage = action.payload.data;
      })
      // .addCase(getShipmentsByStorageDestination.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getShipmentsByStorageBase.fulfilled, (state, action) => {
        state.shipments.byBaseStorage = action.payload.data;
      });
    // .addCase(getShipmentsByStorageBase.rejected, (_, action) => {
    //   alert(`Operation failed - ${action.error.message}`);
    // })
  }
});

// Action creators are generated for each case reducer function
export const {
  clearShipment,
  setChosenShipmentId,
  clearShipments,
  clearShipmentsByDestination,
  clearShipmentsByBase,
  clearShipmentInfo,
  clearShipmentError
} = shipmentSlice.actions;

export default shipmentSlice.reducer;