/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import UserListItemDesktop from "./UserListItemVersions/UserListItemDesktop";
import UserListItemMobile from "./UserListItemVersions/UserListItemMobile";

const UserManagementItem = ({
  orderNumber,
  user,
  setRefresh,
  currentHeadersSetup,
  setCurrentHeadersSetup,
}) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <UserListItemDesktop
          orderNumber={orderNumber}
          user={user}
          setRefresh={setRefresh}
          currentHeadersSetup={currentHeadersSetup}
          setCurrentHeadersSetup={setCurrentHeadersSetup}
        />
      ) : (
        <UserListItemMobile
          orderNumber={orderNumber}
          user={user}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default UserManagementItem;
