/* eslint-disable react/prop-types */
import React from 'react';
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from '../../../TableGridWrapper/TableDataGridWrapper';
import { productsListHeaders } from '../../../../static/tablesHeaders';
import { Box, Typography } from '@mui/material';
import EditButton from '../../../EditButton/EditButton';
import DeleteButton from '../../../DeleteButton/DeleteButton';

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexWrap: 'wrap',
  gap: "0.1rem 1rem",
}));



const ProductItemDesktop = ({
  product,
  orderNumber,
  editProduct,
  delProduct,
}) => {
  const { name, category, producer, partNumber, description } = product;

  return (
    <TableDataGridWrapper headers={productsListHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{partNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>

      <CustomDataDisplay>{category ? category.name : ""}</CustomDataDisplay>

      <CustomDataDisplay>{producer ? producer.name : ""}</CustomDataDisplay>
      <CustomDataDisplay>{description}</CustomDataDisplay>
      <ButtonWrapper>
        <EditButton onClick={() => editProduct()} />
        <DeleteButton onClick={() => delProduct()} />
      </ButtonWrapper>
    </TableDataGridWrapper>
  );
};

export default ProductItemDesktop;