/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import TaskListItemDesktop from "./TaskListItemVersions/TaskListItemDesktop";
import TaskListItemMobile from "./TaskListItemVersions/TaskListItemMobile";

const TaskListItem = ({ orderNumber, task, setRefresh, chosenUser }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <TaskListItemDesktop
          orderNumber={orderNumber}
          task={task}
          setRefresh={setRefresh}
          chosenUser={chosenUser}
        />
      ) : (
        <TaskListItemMobile
          orderNumber={orderNumber}
          task={task}
          setRefresh={setRefresh}
          chosenUser={chosenUser}
        />
      )}
    </>
  );
};

export default TaskListItem;
