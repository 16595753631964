/* eslint-disable react/prop-types */
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";
import Logout from "../Logout/Logout";
import useMediaQ from "../../hooks/useMediaQ";
import { useDispatch } from "react-redux";
import { closeMenu } from "../../slices/mediaSlice";
import {
  Warehouse,
  WarehouseOutlined,
  Inventory2Outlined,
  InventoryOutlined,
  PeopleOutlineOutlined,
  HomeOutlined,
  Business,
  Task,
  TaskAlt,
  TaskOutlined,
  AccessTime,
} from "@mui/icons-material";
import ExtendableMenu from "../ExtendableMenu/ExtendableMenu";
import { styled } from "@mui/material/styles";

const routes = {
  service_technician: [
    { path: "/", name: "Strona główna", icon: <HomeOutlined /> },
    { path: "/storages", name: "Magazyny", icon: <WarehouseOutlined /> },
    {
      path: "",
      name: "Zadania",
      icon: <Task />,
      subPaths: [
        { path: "/tasks", name: "Aktualne", icon: <TaskOutlined /> },
        { path: "/tasks-history", name: "Historia", icon: <TaskAlt /> },
      ],
    },
  ],
  warehouseman: [
    { path: "/", name: "Strona główna", icon: <HomeOutlined /> },
    { path: "/main-storage", name: "Magazyn główny", icon: <Warehouse /> },
    {
      path: "/storages",
      name: "Magazyny lokalne",
      icon: <WarehouseOutlined />,
    },
    {
      path: "/products",
      name: "Administracja produktami",
      icon: <Inventory2Outlined />,
    },
    // { path: "/product-add", name: "Dodaj kartę produktu" },
    // { path: "/requests/u", name: "Zapotrzebowania" },
    { path: "/commissions", name: "Zlecenia", icon: <InventoryOutlined /> },
  ],
  // admin: [
  //   { path: "/", name: "Strona główna" },
  //   { path: "/main-storage", name: "Magazyn główny" },
  //   { path: "/storages", name: "Magazyny lokalne" },
  //   { path: "/assign-user", name: "Przypisz użytkownika" },
  //   { path: "/logs", name: "Zdarzenia" },
  // ],
  admin: [
    { path: "/", name: "Strona główna", icon: <HomeOutlined /> },
    {
      path: "",
      name: "Zarządzanie magazynami",
      icon: <Warehouse />,
      subPaths: [
        {
          path: "/main-storage",
          name: "Magazyn główny",
          icon: <Warehouse />,
        },
        {
          path: "/storages",
          name: "Magazyny lokalne",
          icon: <WarehouseOutlined />,
        },
      ],
    },
    {
      path: "/products",
      name: "Administracja produktami",
      icon: <Inventory2Outlined />,
    },
    // { path: "/product-add", name: "Dodaj kartę produktu" },
    // { path: "/requests/u", name: "Zapotrzebowania" },
    {
      path: "",
      name: "Zadania",
      icon: <Task />,
      subPaths: [
        { path: "/tasks", name: "Aktualne", icon: <TaskOutlined /> },
        { path: "/tasks-history", name: "Historia", icon: <TaskAlt /> },
      ],
    },
    { path: "/commissions", name: "Zlecenia", icon: <InventoryOutlined /> },
    {
      path: "/users-management",
      name: "Zarządzanie użytkownikami",
      icon: <PeopleOutlineOutlined />,
    },
    {
      path: "/companies",
      name: "Firmy",
      icon: <Business />,
    },
    {
      path: "/changelog",
      name: "ChangeLog",
      icon: <AccessTime />,
    },
  ],
  no_role: [],
};

const NavbarWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "1.25em",
  textAlign: "left",
  bgcolor: "#082032",
}));

const NavButton = styled(Button)(({ theme }) => ({
  zIndex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  height: "3.5rem",
  gap: "1rem",
  backgroundColor: "#082032",
  color: theme.palette.primary.main,
  transition: "1s",
  "&:hover": {
    backgroundColor: "#2C394B",
  },
}));

const NavButtonTitle = styled(Typography)(() => ({
  textAlign: "left",
  textTransform: "none",
  width: "75%",
}));

const Navbar = () => {
  const { userData } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isPhoneDisplay, isTabletDisplay } = useMediaQ();

  return (
    <NavbarWrapper>
      {userData !== null ? (
        routes[userData.role].map(({ path, name, icon, subPaths }, index) => {
          if (!subPaths)
            return (
              <NavButton
                key={index}
                onClick={() => {
                  navigate(path);
                  if (isPhoneDisplay || isTabletDisplay) {
                    dispatch(closeMenu());
                  }
                }}
              >
                {icon}
                <NavButtonTitle>{name}</NavButtonTitle>
              </NavButton>
            );
          else {
            return (
              <ExtendableMenu
                key={index}
                path={path}
                name={name}
                icon={icon}
                subPaths={subPaths}
              />
            );
          }
        })
      ) : (
        <></>
      )}
      <Logout />
    </NavbarWrapper>
  );
};

export default Navbar;
