/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { cloneElement } from "react";
import { Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

const CustomBox = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  gap: "1rem",
  alignItems: "end",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  marginBottom: "1rem",
}));

const AdditionalFunctionalitiesWrapper = ({ children, sx = {} }) => {
  const { breakpoints } = useTheme();
  return (
    <CustomBox sx={sx}>
      {Array.isArray(children)
        ? children.map((child, index) => (
            <React.Fragment key={child.key ? child.key : index}>
              {cloneElement(child, {
                sx: {
                  ...child.props.sx,
                  [breakpoints.down("laptop")]: {
                    flexGrow: 1,
                    alignContent: "flex-start",
                  },
                },
              })}
            </React.Fragment>
          ))
        : children}
    </CustomBox>
  );
};

export default AdditionalFunctionalitiesWrapper;
