import React from "react";
import { Typography } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import LoginPanel from "../LoginPanel/LoginPanel";
import StoragesList from "../StoragesList/StoragesList";
import RequireAuth from "./RequireAuth";
import Storage from "../Storage/Storage";
// import StorageForm from '../Storage/StorageForm';
import ProductForm from "../Product/ProductForm/ProductForm";
import ProductsAssociations from "../ProductsAssociations/ProductsAssociations";
import AddUnits from "../AddUnits/AddUnits";
import MoveUnits from "../MoveUnits/MoveUnits/MoveUnits";
import UseUnits from "../UseUnits/UseUnits";
import ShipmentsList from "../Shipments/ShipmentsElements/ShipmentsList/ShipmentsList";
import Shipment from "../Shipment/Shipment";
import RequestForm from "../Requests/RequestForm/RequestForm";
import RequestsList from "../Requests/RequestsList/RequestsList";
import Request from "../Requests/Request/Request";
import CommissionsList from "../CommissionsList/CommissionsList/CommissionsList";
// import CommissionFormOuter from '../Commission/CommissionFormOuter';
// import CommissionFormInner from '../Commission/CommissionFormInner';
import Commission from "../Commission/Commission/Commission";
import Dashboard from "../Dashboard/Dashboard";
import UsersList from "../UsersList/UsersList/UsersList";
import CompaniesList from "../Companies/CompaniesList/CompaniesList";
import CompanyForm from "../Companies/CompanyForm/CompanyForm";
import Company from "../Companies/Company/Company";
import ConfigureUser from "../ConfigureUser/ConfigureUser";
import RequirePermissions from "./RequirePermissions";
import CommissionCodeConfigurator from "../CommissionCodesList/CommissionCodeConfigurator/CommissionCodeConfigurator";
import TaskListView from "../TaskListView/TaskListView/TaskListView";
import TaskView from "../TaskView/TaskView/TaskView";
import CommissionForm from "../CommissionForm/CommissionForm/CommissionForm";
import StorageForm from "../Storage/StorageForm";
import Location from "../Location/Location";
import Device from "../Device/Device";
import TaskHistory from "../TaskHistory/TaskHistory/TaskHistory";
import CategoryForm from "../Category/CategoryForm/CategoryForm";
import ProducerForm from "../Producer/ProducerForm/ProducerForm";
import LocationForm from "../LocationForm/LocationForm";
import DeviceForm from "../DeviceForm/DeviceForm";
import Sandbox from "../Sandbox/Sandbox";
import UtilizationReportsList from "../UtilizationReportsList/UtilizationReportsList";
import UtilizationReport from "../UtilizationReport/UtilizationReport";
import UtilizationReportForm from "../UtilizationReportForm/UtilizationReportForm";
import ChangeLogPage from "../ChangeLog/ChangeLogPage";
import StorageReports from "../StorageReports/StorageReports";
import StorageReport from "../StorageReport/StorageReport";

const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPanel />} />
      <Route element={<RequireAuth />}>
        <Route path="/" element={<Dashboard />} />

        <Route path="/storages" element={<StoragesList />} />
        <Route path="/storages/:storageId" element={<Storage type="local" />} />

        <Route path="/storage-add" element={<StorageForm />} />
        <Route path="/storage-edit" element={<StorageForm formType="edit" />} />
        <Route path="/products" element={<ProductsAssociations products />} />
        {/* <Route path="/product-add" element={<ProductForm />} /> */}
        <Route path="/product-edit" element={<ProductForm type="edit" />} />
        <Route
          path="/categories"
          element={<ProductsAssociations categories />}
        />
        <Route path="/category-edit" element={<CategoryForm type="edit" />} />
        <Route path="/producers" element={<ProductsAssociations producers />} />
        <Route path="/producer-edit" element={<ProducerForm type="edit" />} />
        <Route path="/units-add" element={<AddUnits />} />
        <Route path="/units-move" element={<MoveUnits />} />
        <Route path="/units-use" element={<UseUnits />} />
        <Route path="/shipments" element={<ShipmentsList />} />
        <Route path="/shipments/:shipmentId" element={<Shipment />} />
        <Route path="/request-add" element={<RequestForm />} />
        <Route path="/request-edit" element={<RequestForm type="edit" />} />
        <Route path="/requests" element={<RequestsList />} />
        <Route path="/requests/:requestId" element={<Request />} />
        <Route path="/tasks" element={<TaskListView />} />
        <Route path="/tasks-history" element={<TaskHistory />} />
        <Route path="/tasks/:taskId" element={<TaskView />} />

        <Route path="/locations/:locationId" element={<Location />} />
        <Route path="/devices/:deviceId" element={<Device />} />
        <Route element={<RequirePermissions roles={["admin"]} />}>
          <Route path="/commissions" element={<CommissionsList />} />
          <Route path="/commission-add" element={<CommissionForm />} />
          <Route path="/commissions/:commissionId" element={<Commission />} />
          <Route path="/users-management" element={<UsersList />} />
          <Route
            path="/commission-codes"
            element={<CommissionCodeConfigurator />}
          />
          <Route path="/companies" element={<CompaniesList />} />
          <Route path="/companies/:companyId" element={<Company />} />
          <Route path="/company-add" element={<CompanyForm />} />
          <Route path="/company-edit" element={<CompanyForm type="edit" />} />
          <Route path="/location-add" element={<LocationForm />} />
          <Route path="/location-edit" element={<LocationForm type="edit" />} />
          <Route path="/device-add" element={<DeviceForm />} />
          <Route path="/device-edit" element={<DeviceForm type="edit" />} />
        </Route>
        <Route path="/user-management/:userId" element={<ConfigureUser />} />
        <Route
          element={<RequirePermissions roles={["admin", "warehouseman"]} />}
        >
          <Route path="/main-storage" element={<Storage type="main" />} />
          <Route
            path="/utilizationReports"
            element={<UtilizationReportsList />}
          />
          <Route
            path="/utilizationReports/:utilizationReportId"
            element={<UtilizationReport />}
          />
          <Route
            path="/utilizationReports-add"
            element={<UtilizationReportForm />}
          />
          <Route path="/storageReports" element={<StorageReports />} />
          <Route path="/storageReports/:storageReportId" element={<StorageReport />} />
        </Route>
        <Route path="/changelog" element={<ChangeLogPage />} />
      </Route>
      <Route path="/sandbox" element={<Sandbox />} />
      <Route path="*" element={<Typography>Strona nie istnieje</Typography>} />
    </Routes>
  );
};

export default Router;
