/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChooseCompanyPage from "../CommissionFormPages/ChosenCompanyPage/ChooseCompanyPage";
import { AddTask, East, KeyboardArrowLeft, West } from "@mui/icons-material";
import ChooseLocationMainPage from "../CommissionFormPages/ChooseLocationMainPage/ChooseLocationMainPage";
import ChooseDeviceMainPage from "../CommissionFormPages/ChooseDeviceMainPage/ChooseDeviceMainPage";
import CommissionDetailsPage from "../CommissionFormPages/CommissionDetailsPage/CommissionDetailsPage";
import SummaryPage from "../CommissionFormPages/SummaryPage/SummaryPage";
import { useNavigate } from "react-router-dom";
import FormSteps from "../CommissionFormPages/FormSteps/FormSteps";
import LastPage from "../CommissionFormPages/LastPage/LastPage";
import { useDispatch } from "react-redux";
import { createCommission } from "../../../slices/commissionSlice";
import authService from "../../../auth/authService";
import PageHeader from "../../PageHeader/PageHeader";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import BlankSpace from "../../BlankSpace/BlankSpace";
import PageWrapper from "../../PageWrapper/PageWrapper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const MAX_NUMBER_OF_PAGES = 5;

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
  marginTop: "3rem",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
}));

const CommissionForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [listOfActivePages, setListOfActivePages] = useState([
    { id: "0", prop: "default" },
  ]);
  const [chosenCompany, setChosenCompany] = useState("");
  const [chosenLocation, setChosenLocation] = useState("");
  const [chosenDevice, setChosenDevice] = useState("");
  const [createdLocation, setCreatedLocation] = useState({
    locationCode: "",
    name: "",
    address: {
      street: "",
      city: "",
      province: "",
      country: "Polska",
      postcode: "",
    },
    contactPhone: "",
    contactPerson: "",
  });
  const [createdDevice, setCreatedDevice] = useState({
    name: "",
    description: "",
    serialNumber: "",
    producerId: "",
    type: "",
    orientation: "",
    subType: "",
  });
  const [commissionDetails, setCommissionDetails] = useState({
    description: "",
    errorCode: "",
  });

  const goPrevious = () => {
    setCurrentPage((state) => state - 1);
  };

  const goNext = () => {
    if (currentPage === 0 && listOfActivePages.length === 1) {
      setCurrentPage((state) => state + 1);
      setListOfActivePages((state) => [
        ...state,
        { id: "1", prop: "selectLocation" },
      ]);
    } else if (currentPage === 1 && listOfActivePages.length === 2) {
      setCurrentPage((state) => state + 1);
      if (
        listOfActivePages[listOfActivePages.length - 1].prop ===
        "selectLocation"
      )
        setListOfActivePages((state) => [
          ...state,
          { id: "2", prop: "selectDevice" },
        ]);
      else {
        setListOfActivePages((state) => [
          ...state,
          { id: "2", prop: "createDevice" },
        ]);
      }
    } else if (currentPage === 2 && listOfActivePages.length === 3) {
      setCurrentPage((state) => state + 1);
      setListOfActivePages((state) => [...state, { id: "3", prop: "default" }]);
    } else if (currentPage === 3 && listOfActivePages.length === 4) {
      setCurrentPage((state) => state + 1);
      setListOfActivePages((state) => [...state, { id: "4", prop: "default" }]);
    } else {
      setCurrentPage((state) => state + 1);
    }
  };

  const addCommission = async () => {
    const data = {
      chosenCompany,
      chosenLocation,
      createdLocation: {
        ...createdLocation,
        code: createdLocation.locationCode,
      },
      chosenDevice,
      createdDevice,
      commissionDetails,
      createdBy: authService.getDecodedAccessToken().user_id,
    };
    await dispatch(createCommission(data));
    navigate("/commissions");
  };

  return (
    <PageWrapper width="95%">
      <PageHeader
        titleText="Tworzenie zlecenia"
        icon={<AddTask />}
        onRightComponent={
          <DetailsButton
            onClick={() => navigate("/commissions")}
            startIcon={<KeyboardArrowLeft />}
            text="Powrót"
          />
        }
      />
      <BlankSpace />
      <FormSteps
        currentPage={currentPage}
        listOfActivePages={listOfActivePages}
      />
      <BlankSpace />
      <SummaryPage
        commissionDetails={commissionDetails}
        chosenCompany={chosenCompany}
        chosenLocation={chosenLocation}
        createdLocation={createdLocation}
        chosenDevice={chosenDevice}
        createdDevice={createdDevice}
        listOfActivePages={listOfActivePages}
      />
      <BlankSpace />
      <SectionWrapper>
        {currentPage === 0 && (
          <ChooseCompanyPage
            setChosenCompany={setChosenCompany}
            chosenCompany={chosenCompany}
          />
        )}
        {currentPage === 1 && (
          <ChooseLocationMainPage
            chosenCompany={chosenCompany}
            chosenLocation={chosenLocation}
            setChosenLocation={setChosenLocation}
            createdLocation={createdLocation}
            setCreatedLocation={setCreatedLocation}
            listOfActivePages={listOfActivePages}
            setListOfActivePages={setListOfActivePages}
            setChosenDevice={setChosenDevice}
            setCreatedDevice={setCreatedDevice}
            setCommissionDetails={setCommissionDetails}
          />
        )}
        {currentPage === 2 && (
          <ChooseDeviceMainPage
            chosenCompany={chosenCompany}
            chosenLocation={chosenLocation}
            setChosenLocation={setChosenLocation}
            createdLocation={createdLocation}
            setCreatedLocation={setCreatedLocation}
            chosenDevice={chosenDevice}
            setChosenDevice={setChosenDevice}
            createdDevice={createdDevice}
            setCreatedDevice={setCreatedDevice}
            listOfActivePages={listOfActivePages}
            setListOfActivePages={setListOfActivePages}
            setCommissionDetails={setCommissionDetails}
          />
        )}
        {currentPage === 3 && (
          <CommissionDetailsPage
            commissionDetails={commissionDetails}
            setCommissionDetails={setCommissionDetails}
            chosenCompany={chosenCompany}
            chosenLocation={chosenLocation}
            createdLocation={createdLocation}
            chosenDevice={chosenDevice}
            createdDevice={createdDevice}
          />
        )}
        {currentPage === 4 && <LastPage addCommission={addCommission} />}
        <ButtonWrapper>
          <DetailsButton
            text="Poprzedni"
            onClick={() => goPrevious()}
            disabled={currentPage === 0}
            startIcon={<West />}
          />
          <DetailsButton
            text="Następny"
            startIcon={<East />}
            onClick={() => goNext()}
            disabled={
              currentPage === MAX_NUMBER_OF_PAGES - 1 ||
              (currentPage === 0 && chosenCompany === "") ||
              (currentPage === 1 &&
                chosenLocation === "" &&
                (createdLocation.locationCode.length === 0 ||
                  createdLocation.name.length === 0 ||
                  createdLocation.address.street.length === 0 ||
                  createdLocation.address.city.length === 0 ||
                  createdLocation.address.postcode.length === 0 ||
                  createdLocation.address.country.length === 0 ||
                  createdLocation.address.province.length === 0 ||
                  createdLocation.contactPhone.length === 0 ||
                  createdLocation.contactPerson.length === 0)) ||
              (currentPage === 2 &&
                chosenDevice === "" &&
                createdDevice.name.length === 0) ||
              (currentPage === 3 &&
                (commissionDetails.errorCode.length === 0 ||
                  commissionDetails.description.length === 0))
            }
          />
        </ButtonWrapper>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default CommissionForm;
