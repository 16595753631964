import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API
} from '../static/static';

export const getDevices = createAsyncThunk('devices/get', async () => {
  const resData = await dataService.get(`${API}devices`);
  return resData;
});

export const getDevice = createAsyncThunk('device/get', async (deviceId, {
  getState
}) => {
  const {
    chosenDeviceId
  } = getState().commission;
  const resData = deviceId ? await dataService.get(`${API}device/${deviceId}`) : chosenDeviceId ? await dataService.get(`${API}device/${chosenDeviceId}`) : await dataService.get(`${API}device/${localStorage.getItem('chosenDeviceId')}`);
  return resData;
});

export const getDeviceWithLocation = createAsyncThunk('device/location/get', async (deviceId, {
  getState
}) => {
  const {
    chosenDeviceId
  } = getState().commission;
  const resData = deviceId ? await dataService.get(`${API}deviceWithLocation/${deviceId}`) : chosenDeviceId ? await dataService.get(`${API}deviceWithLocation/${chosenDeviceId}`) : await dataService.get(`${API}deviceWithLocation/${localStorage.getItem('chosenDeviceId')}`);
  return resData;
});

export const getLocationFilteredDevices = createAsyncThunk('device/locations/get/filtered', async (data) => {
  const {
    locationId,
    searchingValue,
  } = data;
  const resData = await dataService.get(`${API}locationDevices/${locationId}/filter/?searchingPhrase=${searchingValue}`);
  return resData;
});


export const addDevice = createAsyncThunk('device/add', async (data) => {
  const resData = await dataService.post(`${API}device-add/`, data);
  return resData;
});

export const updateDevice = createAsyncThunk('device/edit', async (data) => {
  const resData = await dataService.put(`${API}device-edit/`, data);
  return resData;
});

export const getPaginatedDevices = createAsyncThunk('devices/get/pagination', async (data) => {
  const {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    location,
    type,
    orientation,
    createdBy,
    lastModifiedBy,
    creationDateStart,
    creationDateEnd,
    lastModificationDateStart,
    lastModificationDateEnd,
    isSynchronized
  } = data;
  const resData = await dataService.get(`${API}devices/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&location=${location}&type=${type}&orientation=${orientation}&createdBy=${createdBy}&lastModifiedBy=${lastModifiedBy}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&lastModificationDateStart=${lastModificationDateStart}&lastModificationDateEnd=${lastModificationDateEnd}&isSynchronized=${isSynchronized}`);
  return resData;
});

export const getDevicesCount = createAsyncThunk('devices/get/count', async (data) => {
  const {
    searchingValue,
    location,
    type,
    orientation,
    createdBy,
    lastModifiedBy,
    creationDateStart,
    creationDateEnd,
    lastModificationDateStart,
    lastModificationDateEnd,
    isSynchronized
  } = data;
  const resData = await dataService.get(`${API}devices/count/?searchingPhrase=${searchingValue}&location=${location}&type=${type}&orientation=${orientation}&createdBy=${createdBy}&lastModifiedBy=${lastModifiedBy}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&lastModificationDateStart=${lastModificationDateStart}&lastModificationDateEnd=${lastModificationDateEnd}&isSynchronized=${isSynchronized}`);
  return resData;
});

const initialState = {
  devices: null,
  devicesCount: 0,
  chosenDevice: null,
  chosenDeviceId: null,
  deviceInfo: null,
  deviceError: null
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setChosenDeviceId: (state, action) => {
      localStorage.setItem('chosenDeviceId', action.payload);
      state.chosenDeviceId = action.payload;
    },
    clearDevice: (state) => {
      state.chosenDevice = null;
    },
    clearDevicesData: (state) => {
      state.devices = null;
      state.devicesCount = 0;
    },
    clearDeviceInfo: (state) => {
      state.deviceInfo = null;
    },
    clearDeviceError: (state) => {
      state.deviceError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocationFilteredDevices.fulfilled, (state, action) => {
        state.devices = action.payload.data;
      })
      // .addCase(getLocationFilteredDevices.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })

      .addCase(getDevices.fulfilled, (state, action) => {
        state.devices = action.payload.data;
      })
      // .addCase(getDevices.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getDeviceWithLocation.fulfilled, (state, action) => {
        state.chosenLocation = {
          ...action.payload.data.device,
          ...action.payload.data.location
        };
      })
      .addCase(getDevice.fulfilled, (state, action) => {
        state.chosenDevice = action.payload.data;
      })
      // .addCase(getDeviceWithLocation.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })      .addCase(getPaginatedDevices.fulfilled, (state, action) => {
      .addCase(getPaginatedDevices.fulfilled, (state, action) => {
        state.devices = action.payload.data;
      })
      .addCase(getDevicesCount.fulfilled, (state, action) => {
        state.devicesCount = action.payload.data;
      })
      .addCase(addDevice.fulfilled, (state) => {
        state.deviceInfo = "Dodano pomyślnie urządzenie";
      })
      .addCase(updateDevice.fulfilled, (state) => {
        state.deviceInfo = "Zedytowano pomyślnie urządzenie";
      })
      ;
  },

});

export const {
  setChosenDeviceId,
  clearDevicesData,
  clearDevice,
  clearDeviceInfo,
  clearDeviceError
} = deviceSlice.actions;

export default deviceSlice.reducer;