/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Task, CheckCircleOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Loading/Loading";
import {
  DEFAULT_DISPLAY_ITEMS_QUANTITY,
  taskStatusChipStyles,
} from "../../../static/static";
import {
  clearTasks,
  getPaginatedTasks,
  getTasksCount,
} from "../../../slices/taskSlice";
import TaskListItem from "../TaskListItem/TaskListItem";
import Pagination from "../../Pagination/Pagination";
import DateRangeSelector from "../../DateRangeSelector/DateRangeSelector";
import PageHeader from "../../PageHeader/PageHeader";
import RefreshButton from "../../RefreshButton/RefreshButton";
import BlankSpace from "../../BlankSpace/BlankSpace";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import ChipSet from "../../ChipSet/ChipSet";
import TableHeader from "../../TableHeader/TableHeader";
import { taskListHeaders } from "../../../static/tablesHeaders";
import EmptyListError from "../../EmptyListError/EmptyListError";
import { getLocalTimeDate } from "../../../static/functions";
import PageWrapper from "../../PageWrapper/PageWrapper";
import ChooseTaskKindSelection from "./ChooseTaskKindSelection";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import useMediaQ from "../../../hooks/useMediaQ";
import MobileDataSorter from "../../MobileDataSorter/MobileDataSorter";
import { clearUser, getUser } from "../../../slices/userSlice";
import TaskReportDownloader from "./TaskReportDownloader";
import TableWrapper from "../../TableWrapper/TableWrapper";
import usePagination from "../../../hooks/usePagination";
import ReturnButton from "../../ReturnButton/ReturnButton";

const chipSet = [
  {
    label: "Wszystkie",
    keyValue: ["Created", "In progress"],
  },
  {
    label: "Utworzone",
    keyValue: "Created",
  },
  {
    label: "W trakcie",
    keyValue: "In progress",
  },
];

const taskHeaderNames = {
  Qualification: "kwalifikacyjne",
  Assignment: "przypisania",
  Approval: "do akceptacji",
  Work: "wykonawcze",
  Verification: "weryfikacyjne",
};

const TaskListView = () => {
  const dispatch = useDispatch();
  const { tasks, tasksCount } = useSelector((slice) => slice.task);
  const {
    userData: { user_id: userId, role },
  } = useSelector((state) => state.auth);
  const { chosenUser } = useSelector((state) => state.user);
  const searchingRef = useRef("");
  const [currentTaskType, setCurrentTaskType] = useState(
    role === "admin" ? "Qualification" : "Approval"
  );
  const [filteredValue, setFilteredValue] = useState([
    "Created",
    "In progress",
  ]);
  const [createdDates, setCreationDates] = useState({ start: "", end: "" });
  const { isPhoneDisplay, isTabletDisplay, isLaptopDisplay } = useMediaQ();
  const {
    refresh,
    setRefresh,
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("creationDate", "descending");

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getUser(userId));
    await dispatch(
      getTasksCount({
        searchingValue,
        type: currentTaskType,
        status: filteredValue,
        parentTask: "",
        commission: "",
        assignedServiceTechnician: "",
        proposedServiceTechnician: currentTaskType === "Approval" ? userId : "",
        executor: "",
        creationDate: "",
        planningExecutionDateStart: "",
        planningExecutionDateEnd: "",
        executionDate: "",
        creationDateStart: createdDates.start
          ? getLocalTimeDate(createdDates.start)
          : "",
        creationDateEnd: createdDates.end
          ? getLocalTimeDate(createdDates.end)
          : "",
        finishDateStart: "",
        finishDateEnd: "",
      })
    );
    setIsDataLoading(false);
  }, [
    refresh,
    searchingValue,
    filteredValue,
    currentTaskType,
    createdDates.start,
    createdDates.end,
  ]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedTasks({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        type: currentTaskType,
        status: filteredValue,
        parentTask: "",
        commission: "",
        assignedServiceTechnician: "",
        proposedServiceTechnician: currentTaskType === "Approval" ? userId : "",
        executor: "",
        creationDate: "",
        planningExecutionDateStart: "",
        planningExecutionDateEnd: "",
        executionDate: "",
        creationDateStart: createdDates.start
          ? getLocalTimeDate(createdDates.start)
          : "",
        creationDateEnd: createdDates.end
          ? getLocalTimeDate(createdDates.end)
          : "",
        finishDateStart: "",
        finishDateEnd: "",
      })
    );
    setIsDataLoading(false);
  }, [
    refresh,
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    filteredValue,
    currentTaskType,
    createdDates.start,
    createdDates.end,
  ]);

  useEffect(() => {
    loadDataCount();
  }, [loadData]);
  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      dispatch(clearTasks());
      dispatch(clearUser());
    };
  }, []);

  const setCreationDateInterval = useCallback((dates) => {
    const [startDate, endDate] = dates;
    setCreationDates({
      start: startDate,
      end: endDate,
    });
  }, []);

  return (
    <PageWrapper
      width={
        isPhoneDisplay
          ? "90%"
          : isTabletDisplay
          ? "75%"
          : isLaptopDisplay
          ? "80%"
          : "60%"
      }
    >
      <ChooseTaskKindSelection
        setCurrentTaskType={setCurrentTaskType}
        setFilteredValue={setFilteredValue}
        chosenUser={chosenUser}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <BlankSpace />
      <PageHeader
        titleText={`Zadania ${taskHeaderNames[currentTaskType]}`}
        icon={<Task />}
        onRightComponent={<ReturnButton />}
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <BlankSpace />
      <SectionWrapper>
        <AdditionalFunctionalitiesWrapper>
          <CustomSearchField
            displaySearchingValue={displaySearchingValue}
            setDisplaySearchingValue={setDisplaySearchingValue}
            searchingValue={searchingValue}
            setSearchingValue={setSearchingValue}
            searchingRef={searchingRef}
            placeholder="szukaj zadania..."
            width="40%"
          />
          <ChipSet
            mainLabel="Statusy:"
            set={chipSet}
            chipStyles={taskStatusChipStyles}
            setFunction={setFilteredValue}
            actualValue={filteredValue}
          />
          <TaskReportDownloader tasks={tasks} />
          <DateRangeSelector
            labelName="Data utworzenia: "
            datesRange={createdDates}
            setDatesRange={(dates) => setCreationDateInterval(dates)}
            maxDate={new Date()}
            sx={{ flexBasis: "60%" }}
          />
          <MobileDataSorter
            tableHeader={taskListHeaders}
            setOuterSorter={setSorter}
            setOuterSorting={setSorting}
            defaultSorter="creationDate"
          />
        </AdditionalFunctionalitiesWrapper>
        <TableWrapper>
          <TableHeader
            headers={taskListHeaders}
            setSorter={setSorter}
            setSorting={setSorting}
            gap="1%"
          />
          <Pagination
            collectionSize={tasksCount}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            setLeftIndex={setLeftIndex}
            setRightIndex={setRightIndex}
          >
            {isDataLoading ? (
              <Loading />
            ) : (
              <>
                {tasks !== null &&
                  tasks.map((task, id) => (
                    <TaskListItem
                      orderNumber={leftIndex + id + 1}
                      key={task.id}
                      task={task}
                      setRefresh={setRefresh}
                      chosenUser={chosenUser}
                    />
                  ))}
              </>
            )}
            {tasks !== null && tasks.length === 0 && (
              <EmptyListError
                icon={<CheckCircleOutline />}
                message="Brak zadań"
              />
            )}
          </Pagination>
        </TableWrapper>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default TaskListView;
