/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQ from "../../hooks/useMediaQ";
import StorageReportItemDesktop from "./StorageReportItemVersions/StorageReportItemDesktop";
import StorageReportItemMobile from "./StorageReportItemVersions/StorageReportItemMobile";

const StorageReportItem = ({
  orderNumber,
  storageReport,
  selectedStorageReports,
  addStorageReportToSelected,
  removeStorageReportFromSelected,
}) => {
  const navigate = useNavigate();
  const { isDesktopDisplay } = useMediaQ();

  const storageReportDetails = useCallback(
    () => navigate(`/storageReports/${storageReport.id}`),
    [storageReport]
  );

  return (
    <>
      {isDesktopDisplay ? (
        <StorageReportItemDesktop
          orderNumber={orderNumber}
          storageReport={storageReport}
          storageReportDetails={storageReportDetails}
          selectedStorageReports={selectedStorageReports}
          addStorageReportToSelected={addStorageReportToSelected}
          removeStorageReportFromSelected={removeStorageReportFromSelected}
        />
      ) : (
        <StorageReportItemMobile
          storageReport={storageReport}
          storageReportDetails={storageReportDetails}
        />
      )}
    </>
  );
};

export default StorageReportItem;
