/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colorize } from "@mui/icons-material";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import SearchDropdown from "../../../SearchDropdown/SearchDropdown";
import { getFilteredProducers } from "../../../../slices/producerSlice";

const CustomWrapper = styled(Box)(({ theme }) => ({
  marginTop: "0.5rem",
  display: "flex",
  flexDirection: "column",
  height: "41rem",
  width: "25rem",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("tablet")]: {
    width: "20rem",
  },
}));

const WarningText = styled(Typography)(() => ({
  fontSize: "0.8rem",
  marginTop: "1.25rem",
  marginBottom: "1.25rem",
  color: "red",
}));

const InnerWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  gap: "0.5rem",
}));


const ChooseDeviceCreatePage = ({
  createdDevice,
  setCreatedDevice,
  mode,
  setIsWarningModalOpen,
  listOfActivePages,
}) => {
  return (
    <CustomWrapper>
      <DetailsButton
        text="Wybierz istniejące urządzenie"
        onClick={
          mode === "create" && listOfActivePages[1].prop !== "createLocation"
            ? () => setIsWarningModalOpen(true)
            : () => {}
        }
        startIcon={<Colorize />}
        styles={{ marginTop: "1.5rem" }}
      />
      <WarningText>Pola oznaczone * są obowiązkowe</WarningText>
      <InnerWrapper>
        <TextField
          label="Nazwa*"
          value={createdDevice.name}
          onChange={(e) =>
            setCreatedDevice({
              ...createdDevice,
              name: e.target.value,
            })
          }
        />
        <SearchDropdown
          collectionName="producers"
          sliceName="producer"
          getDataFunction={(searchingValue) =>
            getFilteredProducers(searchingValue)
          }
          placeholder="Producent"
          setFunction={(producer) =>
            setCreatedDevice({
              ...createdDevice,
              producerId: producer.id,
            })
          }
          propertyToDisplay="name"
          propsWidth="20rem"
        />
        <TextField
          label="Opis"
          value={createdDevice.description}
          onChange={(e) =>
            setCreatedDevice({
              ...createdDevice,
              description: e.target.value,
            })
          }
          multiline
          minRows={5}
          maxRows={5}
          helperText="Jeżeli nie jest możliwe podanie parametrów urządzenia zaleca się opis wizualny w celach łatwiejszej identyfikacji"
        />

        <TextField
          label="Numer seryjny"
          value={createdDevice.serialNumber}
          onChange={(e) =>
            setCreatedDevice({
              ...createdDevice,
              serialNumber: e.target.value,
            })
          }
        />

        <TextField
          label="Typ"
          value={createdDevice.type}
          onChange={(e) =>
            setCreatedDevice({
              ...createdDevice,
              type: e.target.value,
            })
          }
        />
        <TextField
          label="Orientacja"
          value={createdDevice.orientation}
          onChange={(e) =>
            setCreatedDevice({
              ...createdDevice,
              orientation: e.target.value,
            })
          }
        />
        <TextField
          label="Podtyp"
          value={createdDevice.subType}
          onChange={(e) =>
            setCreatedDevice({
              ...createdDevice,
              subType: e.target.value,
            })
          }
        />
      </InnerWrapper>
    </CustomWrapper>
  );
};

export default ChooseDeviceCreatePage;
