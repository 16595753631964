/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { login } from "../../slices/authSlice";
import { Login } from "@mui/icons-material";

const CustomWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.25rem",
  width: "25rem",
  padding: "2.5rem",
  border: "0.2rem solid white",
  borderRadius: "0.5rem",
  backgroundColor: "rgba(215,215,215,0.5)",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("laptop")]: {
    width: "90%",
    padding: "1rem",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "90%",
    padding: "1rem",
  },
}));

const CustomTextField = styled(TextField)(() => ({
  width: "90%",
  border: "none",

  "& .MuiInputBase-input": {
    color: "white",
    background: "transparent",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: 0,
      borderRadius: 0,
      borderBottom: "0.0625rem solid white",
    },
    "&:hover fieldset": {
      borderColor: "rgb(150, 150, 150)",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderRadius: 0,
      borderBottom: "0.125rem solid white",
    },
  },
  "&:-webkit-autofill": {
    backgroundColor: "rgba(215,215,215,0.5)",
  },
}));

const CustomButton = styled(Button)(() => ({
  width: "90%",
}));

const LoginForm = () => {
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({ login: "", password: "" });
  const [buttonLock, setButtonLock] = useState(false);

  const onSubmit = useCallback(async () => {
    await dispatch(login(loginData));
  }, [loginData]);

  const isFormDataCompleted = () => {
    return loginData.login && loginData.password;
  };

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (keyCode === 13 && !buttonLock && isFormDataCompleted()) {
        onSubmit();
        setButtonLock(true);
        setTimeout(() => setButtonLock(false), 5000);
      }
    },
    [buttonLock, onSubmit]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <CustomWrapper>
      <Typography
        sx={{
          fontSize: "2.5rem",
          color: "white",
          textAlign: 'center'
        }}
      >
        Panel logowania
      </Typography>
      <CustomTextField
        id="login"
        label="Login"
        type="text"
        onChange={(e) =>
          setLoginData((state) => ({ ...state, login: e.target.value }))
        }
        InputLabelProps={{
          style: { color: "#fff" },
        }}
      />
      <CustomTextField
        id="password"
        label="Password"
        type="password"
        onChange={(e) =>
          setLoginData((state) => ({ ...state, password: e.target.value }))
        }
        InputLabelProps={{
          style: { color: "#fff" },
        }}
      />
      <CustomButton
        startIcon={<Login />}
        id="login-button"
        type="submit"
        variant="contained"
        onClick={() => onSubmit()}
        sx={{ marginTop: "1rem" }}
        disabled={!loginData.login || !loginData.password}
      >
        Zaloguj
      </CustomButton>
    </CustomWrapper>
  );
};

export default LoginForm;
