/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Dialog } from "@mui/material";
import TaskCommentsItem from "../TaskCommentsItem/TaskCommentsItem";
import { AddComment, Comment, Info } from "@mui/icons-material";
import TaskCommentModal from "../../TaskCommentModal/TaskCommentModal";
import PageHeader from "../../../PageHeader/PageHeader";
import TableHeader from "../../../TableHeader/TableHeader";
import { taskCommentsHeaders } from "../../../../static/tablesHeaders";
import EmptyListError from "../../../EmptyListError/EmptyListError";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";
import {DetailsButton} from "../../../FunctionalButtons/FunctionalButtons";

const TaskComments = ({ comments, setRefresh, taskId, taskStatus }) => {
  const [isAddEditTaskCommentVisible, setIsAddEditTaskCommentVisible] =
    useState(false);

  return (
    <>
      <SectionWrapper>
        <PageHeader
          titleText="Komentarze"
          icon={<Comment />}
          size="medium"
          onRightComponent={
            taskStatus === "Created" || taskStatus === "In progress" ? (
              <DetailsButton
                text="Dodaj komentarz"
                startIcon={<AddComment />}
                onClick={() => setIsAddEditTaskCommentVisible(true)}
              />
            ) : (
              <></>
            )
          }
        />
        <TableHeader
          headers={taskCommentsHeaders}
          setSorter={() => {}}
          setSorting={() => {}}
          gap="1rem"
        />
        {comments.length > 0 ? (
          <>
            {comments.map((comment, id) => (
              <TaskCommentsItem
                key={id}
                comment={comment}
                orderNumber={id + 1}
              />
            ))}
          </>
        ) : (
          <EmptyListError
            message="Brak komentarzy do zadania"
            icon={<Info />}
          />
        )}
      </SectionWrapper>
      <Dialog
        open={isAddEditTaskCommentVisible}
        onClose={() => setIsAddEditTaskCommentVisible(false)}
      >
        <TaskCommentModal
          closeModal={() => setIsAddEditTaskCommentVisible(false)}
          setRefresh={setRefresh}
          taskId={taskId}
        />
      </Dialog>
    </>
  );
};

export default TaskComments;
