/* eslint-disable react/prop-types */
import React from "react";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import PageHeader from "../../PageHeader/PageHeader";
import { Apps, Info } from "@mui/icons-material";
import { shipmentUnitsHeaders } from "../../../static/tablesHeaders";
import TableHeader from "../../TableHeader/TableHeader";
import EmptyListError from "../../EmptyListError/EmptyListError";
import ShipmentUnitItem from "./ShipmentUnitItem";

const ShipmentUnits = ({ shipmentUnits }) => {
  return (
    <SectionWrapper>
      <PageHeader titleText="Skład zamówienia" icon={<Apps />} size="medium" />
      {shipmentUnits !== null &&
      Array.isArray(shipmentUnits) &&
      shipmentUnits.length > 0 ? (
        <>
          <TableHeader
            headers={shipmentUnitsHeaders}
            setSorter={() => {}}
            setSorting={() => {}}
            gap="1%"
          />
          {shipmentUnits.map((unit, index) => (
            <ShipmentUnitItem
              key={unit.id}
              orderNumber={index + 1}
              shipmentUnit={unit}
            />
          ))}
        </>
      ) : (
        <EmptyListError message="Brak składowych" icon={<Info />} />
      )}
    </SectionWrapper>
  );
};

export default ShipmentUnits;
