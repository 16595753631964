/* eslint-disable react/prop-types */
import React from 'react';
import CustomChip from '../../../CustomChip/CustomChip';
import { unitStatusChipStyles } from '../../../../static/static';
import CompactDataDisplay from '../../../CompactDataDisplay/CompactDataDisplay';
import DeleteButton from '../../../DeleteButton/DeleteButton';

const DOAUnitItemMobile = ({ orderNumber, unit, deleteElement }) => {
  const { partNumber, status, name, serialNumber, chosenQuantity } = unit;

  const data = [
    { title: "PartNumber:", value: partNumber },
    { title: "Nazwa:", value: name },
    { title: "Nr seryjny:", value: serialNumber },
    {
      title: "Status:",
      value: (
        <CustomChip label={status} sx={unitStatusChipStyles(status, "")} />
      ),
    },
    { title: "Wybrana liczba:", value: chosenQuantity },
  ];
  return (
    <CompactDataDisplay
      title={`Jednostka #${orderNumber}`}
      data={data}
      actions={
        <>
          <DeleteButton
            onClick={() => deleteElement()}
            variant="contained"
            text="usuń"
          />
        </>
      }
    />
  );
};

export default DOAUnitItemMobile;