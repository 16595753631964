/* eslint-disable no-unused-vars */
import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API,
  errorMessages
} from '../static/static';
import {
  getErrorCode
} from '../static/functions';

export const getUsers = createAsyncThunk('users/get/all', async () => {
  const resData = await dataService.get(`${API}users`);
  return resData;
});

export const getUser = createAsyncThunk('user/get/', async (userId) => {
  const resData = await dataService.get(`${API}users/${userId}`);
  return resData;
});

export const getUserWithSave = createAsyncThunk('user/get/save/', async (userId) => {
  const resData = await dataService.get(`${API}users/${userId}`);
  return resData;
});

export const getPaginatedUsers = createAsyncThunk('users/get/paginated', async (data) => {
  const {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    role
  } = data;
  const resData = await dataService.get(`${API}users/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&role=${role}`);
  return resData;
});

export const getUsersCount = createAsyncThunk('users/get/count', async (data) => {
  const {
    searchingValue,
    role
  } = data;
  const resData = await dataService.get(`${API}users/count/?searchingPhrase=${searchingValue}&role=${role}`);
  return resData;
});

export const changeUserRole = createAsyncThunk('users/changeRole', async (data) => {
  const resData = await dataService.put(`${API}user/changeRole/`, data);
  return resData;
});

export const createUser = createAsyncThunk('user/create', async (data) => {
  const resData = await dataService.post(`${API}user/create/`, data);
  return resData;
});

export const updateUser = createAsyncThunk('user/update', async ({
  data,
  userId
}) => {
  const resData = await dataService.put(`${API}user/update/${userId}/`, data);
  return resData;
});

export const getUserByProvinceAndRole = createAsyncThunk('user/get/province&role', async (data) => {
  const {
    province,
    role
  } = data;
  const resData = await dataService.get(`${API}user/province-selected/role-selected/?province=${province}&role=${role}`);
  return resData;
});

export const getFilteredUsers = createAsyncThunk('users/get/filtered', async ({
  searchingValue,
  role
}) => {
  const resData = await dataService.get(`${API}users/filter/?searchingPhrase=${searchingValue}&role=${role}`);
  return resData;
});


const initialState = {
  users: null,
  usersCount: 0,
  chosenUser: null,
  chosenUserId: null,
  userError: null,
  userInfo: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUsers: (state) => {
      state.users = null;
      state.usersCount = 0;
    },
    clearUser: (state) => {
      state.chosenUser = null;
      state.chosenUserId = null;
    },
    clearUserInfo: (state) => {
      state.userInfo = null;
    },
    clearUserError: (state) => {
      state.userError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.data;
      })
      .addCase(getUserByProvinceAndRole.fulfilled, (state, action) => {
        state.users = action.payload.data;
      })
      // .addCase(getUsers.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getUser.fulfilled, (state, action) => {
        state.chosenUser = action.payload.data;
        state.chosenUserId = action.payload.data.id;
      })
      .addCase(getUserWithSave.fulfilled, (state, action) => {
        localStorage.setItem("userData", JSON.stringify(action.payload.data));
      })
      .addCase(getPaginatedUsers.fulfilled, (state, action) => {
        state.users = action.payload.data;
      })
      .addCase(getFilteredUsers.fulfilled, (state, action) => {
        state.users = action.payload.data;
      })
      // .addCase(getPaginatedUsers.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getUsersCount.fulfilled, (state, action) => {
        state.usersCount = action.payload.data;
      })
      .addCase(changeUserRole.fulfilled, (state, action) => {
        state.usersCount = action.payload.data;
      })
      .addCase(changeUserRole.rejected, (state, action) => {
        state.userError = errorMessages['user'][getErrorCode(action.error.message)];
      });
    // .addCase(getUsersCount.rejected, (_, action) => {
    //   alert(`Operation failed - ${action.error.message}`);
    // })
  }
});

// Action creators are generated for each case reducer function
export const {
  clearUsers,
  clearUser,
  clearUserInfo,
  clearUserError
} = userSlice.actions;

export default userSlice.reducer;