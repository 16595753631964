/* eslint-disable react/prop-types */
import React from "react";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { requestListHeaders } from "../../../../static/tablesHeaders";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomChip from "../../../CustomChip/CustomChip";
import {
  requestStatus,
  requestStatusChipStyles,
} from "../../../../static/static";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import { dateFormatView } from "../../../../static/functions";
import ModifiedItemInfo from "../../../ModifiedItemInfo/ModifiedItemInfo";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const RequestItemDesktop = ({
  orderNumber,
  request,
  type,
  openItemDetails,
}) => {
  const {
    creationDate,
    topic,
    baseStorage: { name: baseStorageName },
    destinationStorage: { name: destinationStorageName },
    sender: { name: senderName, surname: senderSurname },
    modifier,
    modificationDate,
    status,
  } = request;

  return (
    <TableDataGridWrapper headers={requestListHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      {modifier ? (
        <ModifiedItemInfo
          message={`Modyfikowane przez ${modifier.name} ${
            modifier.surname
          } o ${dateFormatView(modificationDate)}`}
        />
      ) : (
        <Box />
      )}
      <CustomDataDisplay>{dateFormatView(creationDate)}</CustomDataDisplay>
      <CustomDataDisplay>{topic}</CustomDataDisplay>
      <CustomDataDisplay>
        {type === "out" ? destinationStorageName : baseStorageName}
      </CustomDataDisplay>
      <CustomDataDisplay>{`${senderName} ${senderSurname}`}</CustomDataDisplay>
      <CustomChip
        label={requestStatus[status]}
        sx={requestStatusChipStyles(status, "")}
      />
      <DetailsButton
        onClick={() => openItemDetails()}
        styles={{ marginLeft: "2rem" }}
      />
    </TableDataGridWrapper>
  );
};

export default RequestItemDesktop;
