/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import {
  clearCompaniesData,
  getFilteredCompanies,
} from "../../../../slices/companySlice";
import SearchDropdown from "../../../SearchDropdown/SearchDropdown";
import { ChooseCompanyPageClasses } from "./ChooseCompanyPage.style";
import useMediaQ from "../../../../hooks/useMediaQ";

const ChooseCompanyPage = ({ chosenCompany, setChosenCompany }) => {
  const { isPhoneDisplay } = useMediaQ();
  return (
    <Box sx={ChooseCompanyPageClasses.wrapper}>
      <Typography sx={ChooseCompanyPageClasses.titleProps}>
        1. Wybierz firmę
      </Typography>
      <SearchDropdown
        collectionName="companies"
        sliceName="company"
        getDataFunction={getFilteredCompanies}
        placeholder="Firma"
        outerValue={
          chosenCompany
            ? chosenCompany.name !== undefined
              ? chosenCompany.name
              : ""
            : ""
        }
        setFunction={setChosenCompany}
        propertyToDisplay="name"
        clearFunction={clearCompaniesData}
        propsWidth={isPhoneDisplay ? "90%" : "50%"}
      />
    </Box>
  );
};

export default ChooseCompanyPage;
