/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box, Fade, IconButton, Popper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import SelectedChipsDisplay from "./SelectedChipsDisplay/SelectedChipsDisplay";
import SelectChips from "./SelectChips/SelectChips";
import { ArrowCircleDown } from "@mui/icons-material";
import useMediaQ from "../../hooks/useMediaQ";

const CustomBox = styled(Box)(({ theme }) => ({
  border: "0.0625rem solid black",
  padding: "0.5rem",
  backgroundColor: "white",
  display: "flex",
  gap: "1rem",
  width: "30rem",
  minHeight: "11rem",
  flexDirection: "column",
  [theme.breakpoints.down("tablet")]: {
    width: "16rem",
  },
}));

const CustomTitle = styled(Typography)(() => ({
  fontSize: "1.1rem",
  fontWeight: 700,
}));

const MultipleChipSelect = ({ name, setFunction, settingCollection, ...props }) => {
  const [selectedChips, setSelectedChips] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isPhoneDisplay, isTabletDisplay } = useMediaQ();

  const addChip = (newChip) => {
    setSelectedChips((state) => [...state, newChip]);
    setFunction((state => [...state, newChip]));
  };

  const removeChip = (id) => {
    let arrayCopy = [...selectedChips];
    let findingIndex = arrayCopy.findIndex((item) => item.id === id);
    if (findingIndex !== -1) {
      arrayCopy.splice(findingIndex, 1);
      setSelectedChips(arrayCopy);
    }
    arrayCopy = [...settingCollection];
    findingIndex = arrayCopy.findIndex((item) => item.id === id);
    if (findingIndex !== -1) {
      arrayCopy.splice(findingIndex, 1);
      setFunction(arrayCopy);
    }
  };

  const removeAll = () => {
    setSelectedChips([]);
    setFunction([]);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const canBeOpen = isModalOpen && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <Box sx={{margin: '0 1rem'}}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <CustomTitle>{name}</CustomTitle>
        <IconButton
          aria-describedby={id}
          onClick={(event) => handleClick(event)}
        >
          <ArrowCircleDown />
        </IconButton>
      </Box>
      <Popper
        id={id}
        open={isModalOpen}
        anchorEl={anchorEl}
        placement={
          isPhoneDisplay
            ? "bottom"
            : isTabletDisplay
            ? "bottom-end"
            : "bottom-end"
        }
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <CustomBox>
              <SelectedChipsDisplay
                selectedChips={selectedChips}
                removeChip={removeChip}
                removeAll={removeAll}
                closeModal={closeModal}
              />
              <SelectChips
                addChip={addChip}
                selectedChips={selectedChips}
                {...props}
              />
            </CustomBox>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default MultipleChipSelect;
