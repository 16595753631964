/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addDevice,
  clearDevice,
  getDevice,
  updateDevice,
} from "../../slices/deviceSlice";
import AcceptButton from "../AcceptButton/AcceptButton";
import { useNavigate } from "react-router-dom";
import EditButton from "../EditButton/EditButton";
import { Add, Clear, Edit, KeyboardArrowLeft } from "@mui/icons-material";
import PageHeader from "../PageHeader/PageHeader";
import { DetailsButton } from "../FunctionalButtons/FunctionalButtons";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import { getFilteredProducers } from "../../slices/producerSlice";
import authService from "../../auth/authService";
import { clearLocation, getLocation } from "../../slices/locationSlice";
import Loading from "../Loading/Loading";
import CustomTextField from "../CustomTextField/CustomTextField";

const DeviceFormWrapper = styled(Box)(({ theme }) => ({
  width: "30rem",
  display: "flex",
  margin: "3rem auto 0 auto",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  border: "0.0625rem solid #ddd",
  padding: "0.625rem",
  borderRadius: "1.25rem",
  backgroundColor: "white",
  [theme.breakpoints.down("tablet")]: {
    width: "16rem",
  },
}));

const DeviceForm = ({ type = "add" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newDeviceData, setNewDeviceData] = useState({
    name: "",
    description: "",
    producerId: "",
    serialNumber: "",
    type: "",
    orientation: "",
    subType: "",
  });
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { chosenLocation } = useSelector((slice) => slice.location);
  const { chosenDevice } = useSelector((slice) => slice.device);
  const [buttonLock, setButtonLock] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: false,
    producerId: false,
    serialNumber: false,
    type: false,
  });

  const getAddData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getLocation());
    setIsDataLoading(false);
  }, []);

  const getEditData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getDevice());
    setIsDataLoading(false);
  }, []);

  const createDevice = useCallback(async () => {
    const data = {
      ...newDeviceData,
      createdBy: authService.getDecodedAccessToken().user_id,
      locationCode: chosenLocation.code,
    };
    await dispatch(addDevice(data));
    navigate(`/locations/${chosenLocation.code}`);
  }, [newDeviceData, chosenLocation]);

  const editDevice = useCallback(async () => {
    const data = {
      ...newDeviceData,
      deviceId: chosenDevice.id,
      lastModifiedBy: authService.getDecodedAccessToken().user_id,
    };
    await dispatch(updateDevice(data));
    navigate(`/devices/${chosenDevice.id}`);
  }, [newDeviceData, chosenDevice]);

  const isFormDataCompleted = () => {
    return !(
      newDeviceData.name== 0 ||
      newDeviceData.type == 0 ||
      newDeviceData.producerId == 0 ||
      newDeviceData.serialNumber == 0
    );
  };

  const submitForm = useCallback(() => {
    if (!isFormDataCompleted()) {
      if (newDeviceData.name.length == 0)
        setFormErrors((state) => {
          return { ...state, name: true };
        });
      if (newDeviceData.type.length == 0)
        setFormErrors((state) => {
          return { ...state, type: true };
        });
      if (newDeviceData.producerId.length == 0)
        setFormErrors((state) => {
          return { ...state, producerId: true };
        });
      if (newDeviceData.serialNumber.length == 0)
        setFormErrors((state) => {
          return { ...state, serialNumber: true };
        });
      return;
    }
    if (type === "add") {
      createDevice();
    } else {
      editDevice();
    }
  }, [newDeviceData]);

  useEffect(() => {
    if (type === "add") {
      getAddData();
    }
  }, [getAddData]);

  useEffect(() => {
    if (type === "edit") {
      getEditData();
    }
  }, [getEditData]);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (keyCode === 13 && !buttonLock) {
        submitForm();
        setButtonLock(true);
        setTimeout(() => setButtonLock(false), 5000);
      }
    },
    [buttonLock, submitForm]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    if (type === "edit" && chosenDevice !== null) {
      setNewDeviceData({
        name: chosenDevice.name,
        description: chosenDevice.description,
        producerId: chosenDevice.producer.id,
        serialNumber: chosenDevice.serialNumber,
        type: chosenDevice.type,
        orientation: chosenDevice.orientation,
        subType: chosenDevice.subType,
      });
    }
  }, [type, chosenDevice]);

  useEffect(() => {
    if (type === "edit") {
      return () => dispatch(clearDevice());
    }
    if (type === "add") {
      return () => dispatch(clearLocation());
    }
  }, []);

  if (isDataLoading) return <Loading />;

  return (
    <DeviceFormWrapper>
      <PageHeader
        titleText={
          type === "add" ? "Dodawanie urządzenia" : "Edytowanie urządzenia"
        }
        icon={type === "add" ? <Add /> : <Edit />}
        size="medium"
        onRightComponent={
          <DetailsButton
            text="Powrót"
            startIcon={<KeyboardArrowLeft />}
            onClick={() => navigate(-1)}
          />
        }
      />
      <Typography
        sx={{ fontSize: "0.8rem", marginBottom: "1.25rem", color: "red" }}
      >
        Pola oznaczone * są obowiązkowe
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "80%",
          gap: "1.25em",
        }}
      >
        <CustomTextField
          label="Nazwa*"
          value={newDeviceData.name}
          onChange={(e) => {
            setNewDeviceData((state) => ({
              ...state,
              name: e.target.value,
            }));
            setFormErrors((state) => {
              return { ...state, name: false };
            });
          }}
          error={formErrors.name}
          helperText={
            formErrors.name && "Musi zostać podana nazwa urządzenia! "
          }
          maxLength={150}
        />
        <CustomTextField
          label="Opis"
          value={newDeviceData.description}
          onChange={(e) =>
            setNewDeviceData((state) => ({
              ...state,
              description: e.target.value,
            }))
          }
          multiline
          minRows={5}
          maxRows={15}
          maxLength={300}
        />
        <CustomTextField
          label="Typ*"
          value={newDeviceData.type}
          onChange={(e) => {
            setNewDeviceData((state) => ({
              ...state,
              type: e.target.value,
            }));
            setFormErrors((state) => {
              return { ...state, type: false };
            });
          }}
          error={formErrors.type}
          helperText={formErrors.type && "Musi zostać podany typ urządzenia! "}
          maxLength={50}
        />
        <SearchDropdown
          collectionName="producers"
          sliceName="producer"
          getDataFunction={(searchingValue) =>
            getFilteredProducers(searchingValue)
          }
          outerValue={
            chosenDevice
              ? chosenDevice.producer
                ? chosenDevice.producer.name
                : ""
              : ""
          }
          placeholder="Producent"
          setFunction={(producer) => {
            setNewDeviceData((state) => ({
              ...state,
              producerId: producer.id ? producer.id : "",
            }));
            setFormErrors((state) => {
              return { ...state, producerId: false };
            });
          }}
          propertyToDisplay="name"
          propsWidth="100%"
          error={formErrors.producerId}
          helperText="Musi zostać podany producent urządzenia! "
        />
        <CustomTextField
          label="Numer seryjny*"
          value={newDeviceData.serialNumber}
          onChange={(e) => {
            setNewDeviceData((state) => ({
              ...state,
              serialNumber: e.target.value,
            }));
            setFormErrors((state) => {
              return { ...state, serialNumber: false };
            });
          }}
          error={formErrors.serialNumber}
          helperText={
            formErrors.serialNumber &&
            "Musi zostać podany numer seryjny urządzenia! "
          }
          maxLength={100}
        />
        <CustomTextField
          label="Orientacja"
          value={newDeviceData.orientation}
          onChange={(e) =>
            setNewDeviceData((state) => ({
              ...newDeviceData,
              orientation: e.target.value,
            }))
          }
          maxLength={50}
        />
        <CustomTextField
          label="Podtyp"
          value={newDeviceData.subType}
          onChange={(e) =>
            setNewDeviceData((state) => ({
              ...newDeviceData,
              subType: e.target.value,
            }))
          }
          maxLength={50}
        />
        <Box sx={{ display: "flex", gap: "1.25em", justifyContent: "center" }}>
          <AcceptButton
            onClick={() => submitForm()}
            text="Akceptuj"
            disabled={buttonLock || !isFormDataCompleted()}
          />
          <EditButton
            onClick={() => navigate(-1)}
            startIcon={<Clear />}
            text="Anuluj"
          />
        </Box>
      </Box>
    </DeviceFormWrapper>
  );
};

export default DeviceForm;
