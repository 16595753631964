/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { cloneElement, isValidElement } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQ from "../../hooks/useMediaQ";

const AdditionalFlexWrapper = ({
  onRightComponent,
  children,
  size,
  styleProps,
}) => {
  const { isPhoneDisplay } = useMediaQ();

  const newProps = { ...children.props, children: [] };

  return (
    <Box sx={{ marginBottom: "0.5rem", ...styleProps }}>
      {!onRightComponent ? (
        children
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: !isPhoneDisplay ? "space-between" : "center",
              flexWrap: "wrap",
              gap: "0 0.5rem",
              backgroundColor: "rgb(249, 251, 252)",
              border: size === "large" ? "0.0625rem solid #ddd" : "",
              padding: size === "large" ? "0.625rem" : "",
              borderRadius: size === "large" ? "1.25rem" : "",
            }}
          >
            {isPhoneDisplay ? (
              <>
                {cloneElement(children, newProps)}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  {children.props.children ? (
                    children.props.children &&
                    Array.isArray(children.props.children) ? (
                      children.props.children.map((child, index) => {
                        if (isValidElement(child))
                          return cloneElement(child, { key: index });
                        return <Box key={index} />;
                      })
                    ) : (
                      cloneElement(children.props.children)
                    )
                  ) : (
                    <></>
                  )}
                </Box>
              </>
            ) : (
              <>{children}</>
            )}
            {onRightComponent}
          </Box>
        </>
      )}
    </Box>
  );
};

const PageHeaderInner = ({
  icon,
  titleText,
  children,
  styleProps = {},
  onRightComponent = null,
}) => {
  const { breakpoints } = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        border: !onRightComponent ? "0.0625rem solid #ddd" : "",
        padding: !onRightComponent ? "0.625rem" : "",
        borderRadius: !onRightComponent ? "1.25rem" : "",
        backgroundColor: "rgb(249, 251, 252)",
        ...styleProps,
      }}
    >
      {cloneElement(icon, {
        sx: {
          fontSize: "1.75rem",
          [breakpoints.down("laptop")]: {
            fontSize: "1.5rem",
          },
        },
      })}
      <Typography
        sx={{
          fontSize: "1.75rem",
          textAlign: 'center',
          [breakpoints.down("laptop")]: {
            fontSize: "1.5rem",
          },
        }}
      >
        {titleText}
      </Typography>
      <>{children}</>
    </Box>
  );
};

const SectionHeader = ({ icon, titleText, children, styleProps = {} }) => {
  const { breakpoints } = useTheme();
  const { isPhoneDisplay } = useMediaQ();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: isPhoneDisplay ? "center" : "flex-start",
        gap: "0.5rem",
        backgroundColor: "rgb(249, 251, 252)",
        ...styleProps,
      }}
    >
      {cloneElement(icon, {
        sx: {
          fontSize: "1.5rem",
          [breakpoints.down("laptop")]: {
            fontSize: "1.25rem",
          },
        },
      })}
      <Typography
        sx={{
          fontSize: "1.5rem",
          textAlign: "center",
          [breakpoints.down("laptop")]: {
            fontSize: "1.25rem",
          },
        }}
      >
        {titleText}
      </Typography>
      <>{children}</>
    </Box>
  );
};

const PageHeader = ({
  icon,
  titleText,
  children,
  size = "large",
  styleProps = {},
  onRightComponent = null,
}) => {
  return (
    <AdditionalFlexWrapper
      onRightComponent={onRightComponent}
      size={size}
      styleProps={styleProps}
    >
      {size === "large" ? (
        <PageHeaderInner
          icon={icon}
          titleText={titleText}
          onRightComponent={onRightComponent}
        >
          {children}
        </PageHeaderInner>
      ) : (
        <SectionHeader icon={icon} titleText={titleText}>
          {children}
        </SectionHeader>
      )}
    </AdditionalFlexWrapper>
  );
};
export default PageHeader;
