/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import {
  getFilteredStorages,
} from "../../../slices/storageSlice";
import UseUnits from "../../UseUnits/UseUnits";
import { executeWorkDate } from "../../../slices/taskSlice";
import { createDocumentation } from "../../../slices/commissionSlice";
import Documentations from "./Documentation/Documentations";
import {
  Architecture,
  ArrowUpward,
  Clear,
  CropSquare,
  Warehouse,
} from "@mui/icons-material";
import authService from "../../../auth/authService";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import BlankSpace from "../../BlankSpace/BlankSpace";
import PageHeader from "../../PageHeader/PageHeader";
import SearchDropdown from "../../SearchDropdown/SearchDropdown";

const Wrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  padding: "1rem",
  height: "100vh",
  overflowY: "auto",
  backgroundColor: theme.palette.pageBackgroundColor.main,
}));

const InnerWrapper = styled(Box)(({ theme }) => ({
  width: "65%",
  margin: "0 auto",
  [theme.breakpoints.down("laptop")]: {
    width: "80%",
  },
  [theme.breakpoints.down("desktop")]: {
    width: "95%",
  },
}));

const WorkModal = ({
  taskId,
  setIsWorkModalVisible,
  setRefresh,
  commissionType,
  commissionId,
}) => {
  const dispatch = useDispatch();
  const [mountedProducts, setMountedProducts] = useState([]);
  const [fatalProducts, setFatalProducts] = useState([]);
  const [unmountedProducts, setUnmountedProducts] = useState([]);
  const [documentations, setDocumentations] = useState([]);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(true);
  const [chosenStorage, setChosenStorage] = useState(null);

  const removeItem = (index) => {
    const arrayMountCopy = [...mountedProducts];
    const findingMountedIndex = arrayMountCopy.findIndex(
      (unit) => unit.index === index
    );
    let removedElement = arrayMountCopy.splice(findingMountedIndex, 1);
    console.log(removedElement);
    setMountedProducts(arrayMountCopy);
    return removedElement[0].chosenQuantity;
  };

  const removeDOAItem = (index) => {
    const arrayFatalCopy = [...fatalProducts];
    const findingFatalIndex = arrayFatalCopy.findIndex(
      (unit) => unit.index === index
    );
    let removedElement = arrayFatalCopy.splice(findingFatalIndex, 1);
    setFatalProducts(arrayFatalCopy);
    return removedElement[0].returnQuantity;
  };

  const removeBADItem = (index) => {
    const arrayUnmountCopy = [...unmountedProducts];
    const findingUnmountedIndex = arrayUnmountCopy.findIndex(
      (unit) => unit.index === index
    );
    arrayUnmountCopy.splice(findingUnmountedIndex, 1);
    setUnmountedProducts(arrayUnmountCopy);
  };

  const removeDocumentation = (index) => {
    const arrayCopy = [...documentations];
    const findingIndex = arrayCopy.findIndex(
      (documentation) => documentation.index === index
    );
    arrayCopy.splice(findingIndex, 1);
    setDocumentations(arrayCopy);
  };

  const changeDescription = (value) => {
    setDescription(value);
    if (value.length > 0) {
      setDescriptionError(false);
    } else {
      setDescriptionError(true);
    }
  };

  const sendReport = async () => {
    const data = {
      taskId,
      mountedProducts,
      fatalProducts,
      unmountedProducts,
      description,
      chosenStorageId: chosenStorage ? chosenStorage.id : null,
      commissionType,
      executor: authService.getDecodedAccessToken().user_id,
    };
    const docData = {
      taskId,
      commissionId,
      documentations,
    };
    await dispatch(executeWorkDate(data));
    await dispatch(createDocumentation(docData));
    setIsWorkModalVisible(false);
    setRefresh((state) => !state);
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <PageHeader
          titleText="Raport wykonania"
          icon={<Architecture />}
          onRightComponent={
            <DetailsButton
              onClick={() => setIsWorkModalVisible(false)}
              startIcon={<Clear />}
              text="Zamknij"
            />
          }
        >
          <>
            <DetailsButton
              startIcon={<ArrowUpward />}
              onClick={() => sendReport()}
              disabled={
                descriptionError ||
                (chosenStorage === null && commissionType !== "Online Fix")
              }
              text="Wyślij raport"
            />
            <Typography sx={{ color: "red", fontSize: "0.75rem" }}>
              *pola nie mogą być puste
            </Typography>
          </>
        </PageHeader>
        <SectionWrapper>
          <PageHeader
            titleText="Opis zakresu działań*"
            icon={<CropSquare />}
            size="medium"
          />
          <TextField
            label="Opis zakresu działań"
            multiline
            rows={10}
            value={description}
            helperText={descriptionError && ""}
            onChange={(e) => changeDescription(e.target.value)}
            fullWidth
          />
        </SectionWrapper>
        <BlankSpace />
        {commissionType !== "Online Fix" && (
          <SectionWrapper>
            <PageHeader
              titleText="Wybrany magazyn"
              size="medium"
              icon={<Warehouse />}
            />
            <SearchDropdown
              collectionName="storages"
              sliceName="storage"
              getDataFunction={getFilteredStorages}
              placeholder="Magazyn*"
              outerValue={chosenStorage ? chosenStorage.name : ""}
              setFunction={(value) => setChosenStorage(value)}
              propertyToDisplay="name"
              propsWidth="100%"
            />
          </SectionWrapper>
        )}
        {commissionType !== "Online Fix" && <BlankSpace />}
        {commissionType !== "Online Fix" && chosenStorage && (
          <UseUnits
            mountedProducts={mountedProducts}
            setMountedProducts={setMountedProducts}
            fatalProducts={fatalProducts}
            setFatalProducts={setFatalProducts}
            unmountedProducts={unmountedProducts}
            setUnmountedProducts={setUnmountedProducts}
            removeItem={removeItem}
            removeDOAItem={removeDOAItem}
            removeBADItem={removeBADItem}
            chosenStorageId={chosenStorage.id}
          />
        )}
        <BlankSpace />
        {commissionType !== "Online Fix" && (
          <Documentations
            documentations={documentations}
            setDocumentation={setDocumentations}
            removeDocumentation={removeDocumentation}
          />
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

export default WorkModal;
