/* eslint-disable react/prop-types */
import { Box, Chip, Stack, Typography } from "@mui/material";
import React from "react";

const ChipSet = ({
  mainLabel,
  set,
  chipStyles,
  setFunction,
  actualValue,
  styleProps = {},
}) => {
  return (
    <Box sx={{ ...styleProps}}>
      <Typography sx={{ fontWeight: 700 }}>{mainLabel}</Typography>
      <Stack
        direction="row"
        sx={{ display: "flex", flexWrap: "wrap", gap: '0.2rem' }}
      >
        {set.map(({ label, keyValue }, index) => (
          <Chip
            key={index}
            label={label}
            sx={chipStyles(keyValue, actualValue)}
            onClick={() => setFunction(keyValue)}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default ChipSet;
