/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect, cloneElement } from "react";
import SortingHeader from "../SortingHeader/SortingHeader";
import useMediaQ from "../../hooks/useMediaQ";
import TableHeaderGridWrapper from "../TableGridWrapper/TableHeaderGridWrapper";
import { Box } from "@mui/material";

const TableHeader = ({
  headers,
  styles = {},
  setSorter,
  setSorting,
  columnHeaderProps = {},
  gap,
  scroll = false,
}) => {
  const { isDesktopDisplay } = useMediaQ();
  const [generatedContent, setGeneratedContent] = useState([]);

  const generateContent = useCallback(
    (headers) => {
      setGeneratedContent([]);
      headers.map(({ id, headerName, sorter, disabled }) => {
        if (disabled === "blank")
          setGeneratedContent((state) => [...state, <></>]);
        else if (disabled) {
          setGeneratedContent((state) => [
            ...state,
            <SortingHeader
              key={id}
              headerName={headerName}
              styleProps={columnHeaderProps}
              functionForArrowUp={() => {}}
              functionForArrowDown={() => {}}
              disabled={true}
            />,
          ]);
        } else {
          setGeneratedContent((state) => [
            ...state,
            <SortingHeader
              key={id}
              headerName={headerName}
              styleProps={columnHeaderProps}
              functionForArrowUp={() => {
                setSorter(sorter);
                setSorting("ascending");
              }}
              functionForArrowDown={() => {
                setSorter(sorter);
                setSorting("descending");
              }}
            />,
          ]);
        }
      });
    },
    [headers]
  );

  useEffect(() => {
    generateContent(headers);
  }, [generateContent]);

  return (
    <Box sx={scroll ? { marginRight: '1rem'} : {}}>
      {isDesktopDisplay ? (
        <TableHeaderGridWrapper styles={styles} headers={headers} gap={gap}>
          {generatedContent.map((element, index) =>
            cloneElement(element, { key: index })
          )}
        </TableHeaderGridWrapper>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default TableHeader;
