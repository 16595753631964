/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import Router from "./components/Routing/Router";
import { Box, Stack, Typography } from "@mui/material";
import "./App.css";
import LeftMenu from "./components/Menu/Menu";
import UserInformationSystem from "./components/UserInformation/UserInformationSystem";
import { styled } from "@mui/material/styles";
import useMediaQ from "./hooks/useMediaQ";
import { useDispatch } from "react-redux";
import authService from "./auth/authService";
import { getUserWithSave } from "./slices/userSlice";
import { setUserData } from "./slices/authSlice";

const PrintMediaQueries = () => {
  const {
    isPhoneDisplay,
    isTabletDisplay,
    isLaptopDisplay,
    isDesktopDisplay,
    isMobileDisplay,
    isNotMobileDisplay,
  } = useMediaQ();
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  useEffect(
    () =>
      window.addEventListener("resize", () =>
        setCurrentWidth(window.innerWidth)
      ),
    []
  );

  return (
    <Box
      sx={{
        position: "absolute",
        top: "1%",
        right: "2%",
        bgcolor: "orange",
        zIndex: 99,
      }}
    >
      {isPhoneDisplay && <Typography>Phone Display</Typography>}
      {isTabletDisplay && <Typography>Tablet Display</Typography>}
      {isLaptopDisplay && <Typography>Laptop Display</Typography>}
      {isDesktopDisplay && <Typography>Desktop Display</Typography>}
      {isMobileDisplay && <Typography>Mobile Display</Typography>}
      {isNotMobileDisplay && <Typography>Not Mobile Display</Typography>}
      <Typography>{`${currentWidth}px`}</Typography>
    </Box>
  );
};

const CustomStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down("laptop")]: {
    overflow: "hidden",
  },
}));

const CustomWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.pageBackgroundColor.main,
  overflowY: "auto",
  height: "100vh",
  flex: 10,
}));

const App = () => {
  const dispatch = useDispatch();
  const loginReload = useCallback(async () => {
    const { user_id: userId } = authService.getDecodedAccessToken();
    await dispatch(getUserWithSave(userId));
    dispatch(setUserData());
  }, []);

  // useEffect(() => loginReload(), [loginReload]);

  return (
    <CustomStack direction="row">
      {/* <PrintMediaQueries /> */}
      <LeftMenu />
      <CustomWrapper>
        <UserInformationSystem>
          <Router />
        </UserInformationSystem>
      </CustomWrapper>
    </CustomStack>
  );
};

export default App;
