import {
  configureStore
} from '@reduxjs/toolkit';
import authSlice from '../slices/authSlice';
import unitSlice from '../slices/unitSlice';
import commissionSlice from '../slices/commissionSlice';
import alertSlice from '../slices/alertSlice';
import mediaSlice from '../slices/mediaSlice';
import companySlice from '../slices/companySlice';
import requestSlice from '../slices/requestSlice';
import taskSlice from '../slices/taskSlice';
import storageSlice from '../slices/storageSlice';
import shipmentSlice from '../slices/shipmentSlice';
import productSlice from '../slices/productSlice';
import categorySlice from '../slices/categorySlice';
import producerSlice from '../slices/producerSlice';
import locationSlice from '../slices/locationSlice';
import deviceSlice from '../slices/deviceSlice';
import utilizationReportSlice from '../slices/utilizationReportSlice';
import userSlice from '../slices/userSlice';
import permissionSlice from '../slices/permissionSlice';
import storageReportSlice from '../slices/storageReportSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    company: companySlice,
    unit: unitSlice,
    request: requestSlice,
    commission: commissionSlice,
    storage: storageSlice,
    shipment: shipmentSlice,
    task: taskSlice,
    alert: alertSlice,
    media: mediaSlice,
    product: productSlice,
    category: categorySlice,
    producer: producerSlice,
    location: locationSlice,
    device: deviceSlice,
    utilizationReport: utilizationReportSlice,
    user: userSlice,
    permission: permissionSlice,
    storageReport: storageReportSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});