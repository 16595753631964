/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../TableGridWrapper/TableDataGridWrapper";
import { storagesListHeaders } from "../../../static/tablesHeaders";
import DetailsButton from "../../DetailsButton/DetailsButton";
import EditButton from "../../EditButton/EditButton";
import DeleteButton from "../../DeleteButton/DeleteButton";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexWrap: 'wrap',
  gap: "0.1rem 0.5rem",
}));

const StorageItemDesktop = ({
  orderNumber,
  storage,
  storageDetails,
  editStorage,
  setIsModalVisible,
}) => {
  const {
    name,
    user: { name: userName, surname: userSurname },
    address: { street, city, country, postcode },
  } = storage;

  return (
    <TableDataGridWrapper headers={storagesListHeaders} gap="0.5rem">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <CustomDataDisplay>{`${userSurname} ${userName}`}</CustomDataDisplay>
      <CustomDataDisplay>
        {`${city}, ${street}, ${country}, ${postcode}`}
      </CustomDataDisplay>
      <ButtonWrapper>
        <DetailsButton onClick={() => storageDetails()} text="szczegóły" />
        <EditButton onClick={() => editStorage()} />
        <DeleteButton onClick={() => setIsModalVisible(true)} />
      </ButtonWrapper>
    </TableDataGridWrapper>
  );
};

export default StorageItemDesktop;
