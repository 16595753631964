/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { commissionCodesListHeaders } from "../../../../static/tablesHeaders";
import { Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import EditButton from "../../../EditButton/EditButton";
import { Edit } from "@mui/icons-material";
import { dateFormatView } from "../../../../static/functions";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const CommissionCodeItemDesktop = ({
  orderNumber,
  codeData,
  setIsEditModalOpened,
}) => {
  const { lastModifiedBy, id, name, description } = codeData;

  return (
    <>
      <TableDataGridWrapper headers={commissionCodesListHeaders} gap="0.625rem">
        <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
        {lastModifiedBy !== null ? (
          <Tooltip
            title={`Modyfikowane przez ${
              codeData.lastModifiedBy.username
            } o ${dateFormatView(codeData.lastModificationDate)}`}
          >
            <Edit sx={{ fontSize: "1rem" }} />
          </Tooltip>
        ) : (
          <Typography></Typography>
        )}
        <CustomDataDisplay>{id}</CustomDataDisplay>
        <CustomDataDisplay>{name}</CustomDataDisplay>
        <CustomDataDisplay>
          {description ? description : "Brak"}
        </CustomDataDisplay>
        <EditButton onClick={() => setIsEditModalOpened(true)} />
      </TableDataGridWrapper>
    </>
  );
};

export default CommissionCodeItemDesktop;
