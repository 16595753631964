import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API
} from '../static/static';

export const getStorageReport = createAsyncThunk('storageReport/get', async (storageReportId) => {
  const resData = await dataService.get(`${API}storageReport/${storageReportId}`);
  return resData;
});

export const deleteStorageReport = createAsyncThunk('storageReport/delete', async (storageReportId) => {
  const resData = await dataService.delete(`${API}storageReport/delete/${storageReportId}`);
  return resData;
});

export const getPaginatedStorageReports = createAsyncThunk('storageReports/get/pagination', async (data) => {
  let {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    creationDateStart,
    creationDateEnd,
    operationType,
    user,
    baseStorage,
    destinationStorage,
    shipment,
    utilizationReport,
    task
  } = data;
  const resData = await dataService.get(`${API}storageReports/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&operationType=${operationType}&user=${user}&baseStorage=${baseStorage}&destinationStorage=${destinationStorage}&shipment=${shipment}&utilizationReport=${utilizationReport}&task=${task}`);
  return resData;
});

export const getStorageReportsCount = createAsyncThunk('storageReports/get/count', async (data) => {
  let {
    searchingValue,
    creationDateStart,
    creationDateEnd,
    operationType,
    user,
    baseStorage,
    destinationStorage,
    shipment,
    utilizationReport,
    task
  } = data;
  const resData = await dataService.get(`${API}storageReports/count/?searchingPhrase=${searchingValue}&status=${status}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&operationType=${operationType}&user=${user}&baseStorage=${baseStorage}&destinationStorage=${destinationStorage}&shipment=${shipment}&utilizationReport=${utilizationReport}&task=${task}`);
  return resData;
});

const initialState = {
  storageReports: null,
  storageReportsCount: 0,
  chosenStorageReport: null,
  storageReportInfo: null,
  storageReportError: null
};

export const storageReportSlice = createSlice({
  name: 'storageReport',
  initialState,
  reducers: {
    clearStorageReport: (state) => {
      state.storageReportInfo = null;
    },
    clearStorageReports: (state) => {
      state.storageReports = null;
      state.storageReportsCount = 0;
    },
    clearStorageReportInfo: (state) => {
      state.storageReportInfo = null;
    },
    clearStorageReportError: (state) => {
      state.storageReportError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStorageReport.fulfilled, (state, action) => {
        state.chosenStorageReport = action.payload.data;
      })
      // .addCase(getStorageReport.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(deleteStorageReport.fulfilled, (state) => {
        state.storageReportInfo = 'Pomyślnie usunięto raport';
      })
      // .addCase(deleteStorageReport.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getPaginatedStorageReports.fulfilled, (state, action) => {
        state.storageReports = action.payload.data;
      })
      // .addCase(getPaginatedStorageReports.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getStorageReportsCount.fulfilled, (state, action) => {
        state.storageReportsCount = action.payload.data;
      });
    // .addCase(storageReportsCount.rejected, (_, action) => {
    //   alert(`Operation failed - ${action.error.message}`);
    // })
  }
});

// Action creators are generated for each case reducer function
export const {
  clearStorageReport,
  clearStorageReports,
  clearStorageReportInfo,
  clearStorageReportError
} = storageReportSlice.actions;

export default storageReportSlice.reducer;