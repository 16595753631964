import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API
} from '../static/static';
import authService from '../auth/authService';


export const getChartTasks = createAsyncThunk('tasks/get', async () => {
  const resData = await dataService.get(`${API}tasks`);
  return resData;
});

export const getTask = createAsyncThunk('task/get', async (taskId, {
  getState
}) => {
  const {
    chosenTaskId
  } = getState().commission;
  const userId = authService.getDecodedAccessToken().user_id;
  const resData = taskId ? await dataService.get(`${API}task/${taskId}/?user=${userId}`) : chosenTaskId ? await dataService.get(`${API}task/${chosenTaskId}/?user=${userId}`) : await dataService.get(`${API}task/${localStorage.getItem('chosenTaskId')}/?user=${userId}`);
  return resData;
});

export const getCommissionTasks = createAsyncThunk('tasks/commission/get', async (_, {
  getState
}) => {
  const {
    chosenCommissionId
  } = getState().commission;
  const resData = chosenCommissionId ? await dataService.get(`${API}tasksByCommission/${chosenCommissionId}`) : await dataService.get(`${API}tasksByCommission/${localStorage.getItem('chosenCommissionId')}`);
  return resData;
});

export const changeTaskStatus = createAsyncThunk('task/status/change', async (data) => {
  const resData = await dataService.put(`${API}task/status/${data.taskId}/`, data);
  return resData;
});


export const executeQualificationTask = createAsyncThunk('task/qualification/execute', async (data) => {
  const resData = await dataService.put(`${API}task/executeQualificationTask/${data.taskId}/`, data);
  return resData;
});

export const executeAssignmentTask = createAsyncThunk('task/assignment/proposal/make', async (data) => {
  const resData = await dataService.put(`${API}task/executeAssignmentTask/${data.taskId}/`, data);
  return resData;
});

export const executeApprovalTask = createAsyncThunk('task/approval/execute', async (data) => {
  const resData = await dataService.put(`${API}task/executeApprovalTask/${data.taskId}/`, data);
  return resData;
});

export const declineApprovalTask = createAsyncThunk('task/approval/decline', async (data) => {
  const resData = await dataService.put(`${API}task/declineApprovalTask/${data.taskId}/`, data);
  return resData;
});

export const updateWorkTaskDate = createAsyncThunk('task/work/date/update', async (data) => {
  const resData = await dataService.put(`${API}task/updateWorkTaskDate/${data.taskId}/`, data);
  return resData;
});


export const executeWorkDate = createAsyncThunk('task/work/execute', async (data) => {
  const resData = await dataService.put(`${API}task/acceptWorkTask/${data.taskId}/`, data);
  return resData;
});

export const acceptVerificationTask = createAsyncThunk('task/verification/accept', async (data) => {
  const resData = await dataService.put(`${API}task/acceptVerificationTask/${data.taskId}/`, data);
  return resData;
});

export const declineVerificationTask = createAsyncThunk('task/verification/decline', async (data) => {
  const resData = await dataService.put(`${API}task/declineVerificationTask/${data.taskId}/`, data);
  return resData;
});

export const getDocumentationFromTask = createAsyncThunk('task/documentations/get', async (data) => {
  const {
    taskId,
    index
  } = data;
  const resData = await dataService.abget(`${API}documentations/task/${taskId}/${index}/`);
  return resData;
});

export const changeTaskExecutor = createAsyncThunk('task/changeTaskExecutor', async (taskId) => {
  const userId = authService.getDecodedAccessToken().user_id;
  const resData = await dataService.put(`${API}task/changeTaskExecutor/${taskId}/`, {
    userId
  });
  return resData;
});

export const getPaginatedTasks = createAsyncThunk('tasks/get/pagination', async (data) => {
  const {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    type,
    status,
    parentTask,
    commission,
    assignedServiceTechnician,
    proposedServiceTechnician,
    executor,
    planningExecutionDateStart,
    planningExecutionDateEnd,
    executionDate,
    creationDateStart,
    creationDateEnd,
    finishDateStart,
    finishDateEnd,
  } = data;
  const user = authService.getDecodedAccessToken().user_id;
  const resData = await dataService.get(`${API}tasks/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&status=${status}&type=${type}&parentTask=${parentTask}&commission=${commission}&assignedServiceTechnician=${assignedServiceTechnician}&proposedServiceTechnician=${proposedServiceTechnician}&planningExecutionDateStart=${planningExecutionDateStart}&planningExecutionDateEnd=${planningExecutionDateEnd}&executionDate=${executionDate}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&finishDateStart=${finishDateStart}&finishDateEnd=${finishDateEnd}&user=${user}&executor=${executor}`);
  return resData;
});

export const getTasksCount = createAsyncThunk('tasks/get/count', async (data) => {
  const {
    searchingValue,
    type,
    status,
    parentTask,
    commission,
    assignedServiceTechnician,
    proposedServiceTechnician,
    executor,
    planningExecutionDateStart,
    planningExecutionDateEnd,
    executionDate,
    creationDateStart,
    creationDateEnd,
    finishDateStart,
    finishDateEnd,
  } = data;
  const user = authService.getDecodedAccessToken().user_id;
  const resData = await dataService.get(`${API}tasks/count/?searchingPhrase=${searchingValue}&status=${status}&type=${type}&parentTask=${parentTask}&commission=${commission}&assignedServiceTechnician=${assignedServiceTechnician}&proposedServiceTechnician=${proposedServiceTechnician}&planningExecutionDateStart=${planningExecutionDateStart}&planningExecutionDateEnd=${planningExecutionDateEnd}&executionDate=${executionDate}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&finishDateStart=${finishDateStart}&finishDateEnd=${finishDateEnd}&user=${user}&executor=${executor}`);
  return resData;
});


export const addTaskComment = createAsyncThunk('taskComment/add', async (data) => {
  const resData = await dataService.post(`${API}taskComment/add/`, data);
  return resData;
});

export const getTaskComment = createAsyncThunk('taskComment/get', async (taskCommentId) => {
  const resData = await dataService.get(`${API}taskComment/${taskCommentId}`);
  return resData;
});

export const updateTaskComment = createAsyncThunk('taskComment/edit', async ({
  data,
  taskCommentId
}) => {
  const resData = await dataService.put(`${API}taskComment/update/${taskCommentId}/`, data);
  return resData;
});

export const getTasksKindQuantity = createAsyncThunk('taskKindQuantity/get', async (userId) => {
  const resData = await dataService.get(`${API}tasksQuantity/${userId}`);
  return resData;
});

const initialState = {
  tasks: null,
  tasksCount: 0,
  documentations: [],
  chartTasks: null,
  chosenTask: null,
  chosenTaskId: null,
  chosenTaskComment: null,
  chosenTaskCommentId: null,
  tasksKindQuantity: null
};

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setError: (state, action) => {
      const {
        attribute,
        value
      } = action.payload;
      state.errors[attribute] = value;
    },
    setChosenTaskId: (state, action) => {
      localStorage.setItem('chosenTaskId', action.payload);
      state.chosenTaskId = action.payload;
    },
    clearTask: (state) => {
      state.chosenTask = null;
      state.chosenTaskId = null;
    },
    clearTasks: (state) => {
      state.tasks = null;
      state.tasksCount = 0;
    },
    clearTasksCharts: (state) => {
      state.chartTasks = null;
    },
    clearTasksQuantity: (state) => {
      state.tasksKindQuantity = null;
    },
    clearDocumentationsFromTask: (state) => {
      state.documentations = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChartTasks.fulfilled, (state, action) => {
        state.chartTasks = action.payload.data;
      })
      // .addCase(getTasks.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getCommissionTasks.fulfilled, (state, action) => {
        state.tasks = action.payload.data;
      })
      // .addCase(getCommissionTasks.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getTask.fulfilled, (state, action) => {
        state.chosenTask = action.payload.data;
      })
      // .addCase(getTask.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getPaginatedTasks.fulfilled, (state, action) => {
        state.tasks = action.payload.data;
      })
      .addCase(getTasksCount.fulfilled, (state, action) => {
        state.tasksCount = action.payload.data;
      })
      .addCase(getTaskComment.fulfilled, (state, action) => {
        state.chosenTaskComment = action.payload.data;
      })
      .addCase(getTasksKindQuantity.fulfilled, (state, action) => {
        state.tasksKindQuantity = action.payload.data;
      })
      .addCase(getDocumentationFromTask.fulfilled, (state, action) => {
        const file = action.payload.data;
        const base64 = btoa(
          new Uint8Array(file).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        );
        if (state.documentations == null)
          state.documentations = [`data:${action.payload.headers['content-type']};base64,` + base64];
        else {
          state.documentations = [...state.documentations, `data:${action.payload.headers['content-type']};base64,` + base64];
        }
      });
  }
});

export const {
  setChosenTaskId,
  clearDocumentationsFromTask,
  clearTask,
  clearTasks,
  clearTasksCharts,
  clearTasksQuantity
} = taskSlice.actions;

export default taskSlice.reducer;