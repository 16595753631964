/* eslint-disable react/prop-types */
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  setChosenRequestId,
  setRequestSeen,
} from "../../../slices/requestSlice";
import { useDispatch } from "react-redux";
import { setStorageId } from "../../../slices/storageSlice";
import { useSelector } from "react-redux";
import useMediaQ from "../../../hooks/useMediaQ";
import RequestItemDesktop from "./RequestItemVersions/RequestItemDesktop";
import RequestItemMobile from "./RequestItemVersions/RequestItemMobile";

const RequestItem = ({ request, type, chosenStorage, orderNumber }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    userData: { role },
  } = useSelector((slice) => slice.auth);
  const { isDesktopDisplay } = useMediaQ();
  // const isMediumDisplayEnd = useMediaQuery("(max-width:1199px)");

  const openItemDetails = async () => {
    if (role === "service_technician")
      await dispatch(setRequestSeen({ requestId: request.id }));
    dispatch(setStorageId(chosenStorage.id));
    dispatch(setChosenRequestId(request.id));
    navigate(`/requests/${request.id}`);
  };

  return (
    <>
      {isDesktopDisplay ? (
        <RequestItemDesktop
          orderNumber={orderNumber}
          request={request}
          type={type}
          openItemDetails={openItemDetails}
        />
      ) : (
        <RequestItemMobile
          orderNumber={orderNumber}
          request={request}
          type={type}
          openItemDetails={openItemDetails}
        />
      )}
    </>
  );
};

export default RequestItem;
