/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PageHeader from "../../PageHeader/PageHeader";
import { Cached, Check, Clear } from "@mui/icons-material";
import AcceptButton from "../../AcceptButton/AcceptButton";
import EditButton from "../../EditButton/EditButton";
import { useDispatch } from "react-redux";
import TaskExecutionCompactData from "../../TaskView/TaskExecution/TaskExecutionCompactData";
import { changeTaskExecutor } from "../../../slices/taskSlice";

const Wrapper = styled(Box)(({ theme }) => ({
  width: "31.25rem",
  [theme.breakpoints.down("tablet")]: {
    width: "16rem",
  },
}));

const InnerWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%",
  margin: "1.875em auto 0 auto",
  gap: "0.625em",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  marginBottom: "1rem",
  justifyContent: "center",
  gap: "1.25rem",
}));

const ChangeExecutorModal = ({
  task,
  setIsChangeExecutorModalVisible,
  setRefresh,
}) => {
  const dispatch = useDispatch();

  const acceptTask = useCallback(async () => {
    await dispatch(changeTaskExecutor(task.id));
    setRefresh((state) => !state);
  }, []);

  return (
    <Wrapper>
      <InnerWrapper>
        <PageHeader
          titleText="Odepnij wykonawcę"
          icon={<Cached />}
          size="medium"
        />
        <Typography
          sx={{
            fontSize: "0.8em",
            marginBottom: "0.75em",
            color: "red",
            textAlign: "center",
          }}
        >
          Kontynuacja działania spowoduje usunięcie bieżącego zadania oraz
          przypisania i akceptacji!
        </Typography>
        <TaskExecutionCompactData task={task} />
        <ButtonWrapper>
          <AcceptButton
            onClick={() => acceptTask()}
            variant="contained"
            size="small"
            startIcon={<Check />}
            text="akceptuj"
          />
          <EditButton
            variant="contained"
            size="small"
            startIcon={<Clear />}
            text="anuluj"
            onClick={() => setIsChangeExecutorModalVisible(false)}
          />
        </ButtonWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

export default ChangeExecutorModal;
