/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearRequests,
  getPaginatedRequests,
  getRequestsCount,
} from "../../../../slices/requestSlice";
import RequestSummaryItem from "./RequestSummaryItem";
import Pagination from "../../../Pagination/Pagination";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../../../../static/static";
import { Add, Announcement, Info } from "@mui/icons-material";
import Loading from "../../../Loading/Loading";
import CustomDatePicker from "../../../CustomDatePicker/CustomDatePicker";
import CustomSearchField from "../../../CustomSearchField/CustomSearchField";
import TableHeader from "../../../TableHeader/TableHeader";
import { requestSummaryViewHeaders } from "../../../../static/tablesHeaders";
import { getLocalTimeDate } from "../../../../static/functions";
import EmptyListError from "../../../EmptyListError/EmptyListError";
import RefreshButton from "../../../RefreshButton/RefreshButton";
import PageHeader from "../../../PageHeader/PageHeader";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import MobileDataSorter from "../../../MobileDataSorter/MobileDataSorter";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import { useNavigate } from "react-router-dom";
import TableWrapper from "../../../TableWrapper/TableWrapper";
import usePagination from "../../../../hooks/usePagination";
import authService from "../../../../auth/authService";

const RequestsSummaryView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    requests: { all: allRequests },
    requestsCount,
  } = useSelector((slice) => slice.request);
  const { role, user_id } = authService.getDecodedAccessToken();
  // const isSmallMediumDisplayStart = useMediaQuery("(min-width:768px)");
  const searchingRef = useRef("");

  const {
    refresh,
    setRefresh,
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("creationDate", "descending");
  const [creationDates, setCreationDates] = useState("");

  // eslint-disable-next-line react/display-name, react/prop-types

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getRequestsCount({
        searchingValue,
        status: role !== "service_technician" ? "Sent" : "",
        isSeen: role !== "service_technician" ? "" : "false",
        parentRequest: "null",
        baseStorage: "",
        destinationStorage: "",
        sender: role === "service_technician" ? user_id : "",
        receiver: "",
        childRequest: "",
        creationDateStart: creationDates.start
          ? getLocalTimeDate(creationDates.start)
          : "",
        creationDateEnd: creationDates.end
          ? getLocalTimeDate(creationDates.end)
          : "",
      })
    );
    setIsDataLoading(false);
  }, [searchingValue, refresh, creationDates.start, creationDates.end]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedRequests({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        status: role !== "service_technician" ? "Sent" : "",
        isSeen: role !== "service_technician" ? "" : "false",
        parentRequest: "null",
        baseStorage: "",
        destinationStorage: "",
        sender: role === "service_technician" ? user_id : "",
        receiver: "",
        childRequest: "",
        creationDateStart: creationDates.start
          ? getLocalTimeDate(creationDates.start)
          : "",
        creationDateEnd: creationDates.end
          ? getLocalTimeDate(creationDates.end)
          : "",
      })
    );
    setIsDataLoading(false);
  }, [
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    refresh,
    creationDates.start,
    creationDates.end,
  ]);

  useEffect(() => { loadDataCount(); }, [loadDataCount]);

  useEffect(() => { loadData(); }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearRequests());
  }, []);

  const setCreationDateInterval = (dates) => {
    const [startDate, endDate] = dates;
    setCreationDates({
      start: startDate,
      end: endDate,
    });
  };

  return (
    <SectionWrapper>
      <PageHeader
        icon={<Announcement />}
        titleText={
          <>
            <span>
              {role !== "service_technician"
                ? "Nowe zapotrzebowania"
                : "Rozstrzygnięte zapotrzebowania"}
            </span>
            <span style={{ marginLeft: "0.3rem" }}>{`[`}</span>
            <span style={{ color: requestsCount > 0 ? "red" : "green" }}>
              {`${requestsCount}`}
            </span>
            <span>{`]`}</span>
          </>
        }
        size="medium"
        onRightComponent={
          role === "service_technician" && (
            <DetailsButton
              text="Utwórz zapotrzebowanie"
              onClick={() => {
                navigate("/request-add");
              }}
              startIcon={<Add />}
            />
          )
        }
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <AdditionalFunctionalitiesWrapper>
        <CustomSearchField
          displaySearchingValue={displaySearchingValue}
          setDisplaySearchingValue={setDisplaySearchingValue}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
          searchingRef={searchingRef}
          placeholder="szukaj zapotrzebowania..."
          width="20%"
        />
        <CustomDatePicker
          labelName="Data wysłania"
          datesRange={creationDates}
          setDatesRange={(dates) => setCreationDateInterval(dates)}
          maxDate={new Date()}
        />
        <MobileDataSorter
          tableHeader={requestSummaryViewHeaders}
          setOuterSorter={setSorter}
          setOuterSorting={setSorting}
          defaultSorter="creationDate"
        />
      </AdditionalFunctionalitiesWrapper>
      <TableWrapper>
        <TableHeader
          headers={requestSummaryViewHeaders}
          setSorter={setSorter}
          setSorting={setSorting}
          gap="1%"
        />
        <Pagination
          collectionSize={requestsCount}
          leftIndex={leftIndex}
          rightIndex={rightIndex}
          setLeftIndex={setLeftIndex}
          setRightIndex={setRightIndex}
        >
          <>
            {isDataLoading ? (
              <Loading />
            ) : (
              <>
                {allRequests !== null &&
                  allRequests.map((request, id) => (
                    <RequestSummaryItem
                      key={id}
                      request={request}
                      orderNumber={leftIndex + id + 1}
                    />
                  ))}
              </>
            )}

            {allRequests !== null && allRequests.length === 0 && (
              <EmptyListError message="Brak zapotrzebowań" icon={<Info />} />
            )}
          </>
        </Pagination>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default RequestsSummaryView;
