/* eslint-disable react/prop-types */
import React from "react";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";
import EditButton from "../../../EditButton/EditButton";
import { dateFormatView } from "../../../../static/functions";

const CommissionCodeItemMobile = ({ codeData, setIsEditModalOpened }) => {
  const { lastModifiedBy, id, name, description } = codeData;

  const data = [
    { title: "Nazwa:", value: name },
    { title: "Opis:", value: description ? description : "Brak" },
    {
      title: "Modyfikowane:",
      value: lastModifiedBy
        ? `Modyfikowane przez ${
            codeData.lastModifiedBy.username
          } o ${dateFormatView(codeData.lastModificationDate)}`
        : "Nie",
    },
  ];
  return (
    <CompactDataDisplay
      title={`Kod #${id}`}
      data={data}
      actions={
        <>
          <EditButton onClick={() => setIsEditModalOpened(true)} />
        </>
      }
    />
  );
};

export default CommissionCodeItemMobile;
