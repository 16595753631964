import React, { useCallback, useEffect, useState } from "react";
import PageWrapper from "../PageWrapper/PageWrapper";
import PageHeader from "../PageHeader/PageHeader";
import { Assessment } from "@mui/icons-material";
import { ReturnButton } from "../FunctionalButtons/FunctionalButtons";
import { useNavigate, useParams } from "react-router-dom";
import RefreshButton from "../RefreshButton/RefreshButton";
import StorageReportInfo from "./StorageReportInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStorageReport,
  getStorageReport,
} from "../../slices/storageReportSlice";
import Loading from "../Loading/Loading";
import BlankSpace from "../BlankSpace/BlankSpace";
import StorageReportUnits from "./StorageReportUnits";
import StorageReportsDownloader from "./StorageReportsDownloader";

const StorageReport = () => {
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { storageReportId } = useParams();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { chosenStorageReport } = useSelector((slice) => slice.storageReport);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getStorageReport(storageReportId));
    setIsDataLoading(false);
  }, [refresh, storageReportId]);

  useEffect(() => getData(), [getData]);
  useEffect(() => {
    return () => dispatch(clearStorageReport());
  }, []);

  if (isDataLoading) return <Loading />;

  return (
    <>
      {chosenStorageReport && (
        <PageWrapper width="80%">
          <PageHeader
            icon={<Assessment />}
            titleText="Raport magazynowy"
            onRightComponent={<ReturnButton onClick={() => navigate(-1)} />}
          >
            <RefreshButton onClick={() => setRefresh((state) => !state)} />
            <StorageReportsDownloader
              type="single"
              storageReports={[chosenStorageReport]}
            />
          </PageHeader>
          <BlankSpace />
          <StorageReportInfo storageReport={chosenStorageReport} />
          <BlankSpace />
          <StorageReportUnits units={chosenStorageReport.convertedUnits} />
        </PageWrapper>
      )}
    </>
  );
};

export default StorageReport;
