/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchDropdown from "../../../SearchDropdown/SearchDropdown";
import {
  clearLocations,
  getCompanyFilteredLocations,
} from "../../../../slices/locationSlice";
import { useDispatch } from "react-redux";
import { Add } from "@mui/icons-material";
import { DetailsButton } from "../../../FunctionalButtons/FunctionalButtons";
import useMediaQ from "../../../../hooks/useMediaQ";

const CustomWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: 'center',
  width: '100%',
  gap: "1rem",
}));

const ChooseLocationSelectPage = ({
  chosenLocation,
  setChosenLocation,
  companyId,
  mode,
  setIsWarningModalOpen,
}) => {
  const { isPhoneDisplay } = useMediaQ();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearLocations());
  });

  return (
    <CustomWrapper>
      <SearchDropdown
        collectionName="locations"
        sliceName="location"
        getDataFunction={(searchingValue) =>
          getCompanyFilteredLocations({ companyId, searchingValue })
        }
        placeholder="Lokalizacja"
        outerValue={
          chosenLocation
            ? chosenLocation.name !== undefined
              ? chosenLocation.name
              : ""
            : ""
        }
        setFunction={setChosenLocation}
        propertyToDisplay="codeName"
        propsWidth={isPhoneDisplay ? "90%" : "50%"}
      />
      <DetailsButton
        startIcon={<Add />}
        onClick={
          mode === "select" ? () => setIsWarningModalOpen(true) : () => {}
        }
        text="Dodaj lokalizację"
      />
    </CustomWrapper>
  );
};

export default ChooseLocationSelectPage;
