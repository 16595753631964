/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../hooks/useMediaQ";
import UtilizationReportItemDesktop from "./UtilizationReportItemVersions/UtilizationReportItemDesktop";
import UtilizationReportItemMobile from "./UtilizationReportItemVersions/UtilizationReportItemMobile";

const UtilizationReportItem = ({ orderNumber, utilizationReport }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <UtilizationReportItemDesktop
          orderNumber={orderNumber}
          utilizationReport={utilizationReport}
        />
      ) : (
        <UtilizationReportItemMobile
          orderNumber={orderNumber}
          utilizationReport={utilizationReport}
        />
      )}
    </>
  );
};

export default UtilizationReportItem;
