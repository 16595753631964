import React, { useState, useEffect, useCallback } from "react";
import { KeyboardArrowLeft, Task } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { clearTask, getTask } from "../../../slices/taskSlice";
import { useParams } from "react-router-dom";
import Loading from "../../Loading/Loading";
import TaskComments from "../TaskComments/TaskComments/TaskComments";
import TaskExecution from "../TaskExecution/TaskExecution";
import PageHeader from "../../PageHeader/PageHeader";
import RefreshButton from "../../RefreshButton/RefreshButton";
import BlankSpace from "../../BlankSpace/BlankSpace";
import TaskViewDetails from "../TaskViewDetails/TaskViewDetails";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../../PageWrapper/PageWrapper";
import DeviceDetails from "../TaskViewDetails/DeviceDetails";
import LocationDetails from "../TaskViewDetails/LocationDetails";
import DocumentationsView from "../../Commission/DocumentationsView/DocumentationsView/DocumentationsView";
import { clearUser, getUser } from "../../../slices/userSlice";
import UnitsMigrationHistory from "../../UnitsMigrationHistory/UnitsMigrationHistory";

const TaskView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { taskId } = useParams();
  const { chosenTask } = useSelector((slice) => slice.task);
  const { chosenUser } = useSelector((state) => state.user);
  const {
    userData: { user_id: userId },
  } = useSelector((state) => state.auth);
  const [refresh, setRefresh] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getUser(userId));
    await dispatch(getTask(taskId));
    setIsDataLoading(false);
  }, [refresh, taskId]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => {
      dispatch(clearUser());
      dispatch(clearTask());
    };
  }, []);

  if (isDataLoading) {
    return <Loading />;
  }

  return (
    <>
      {chosenTask ? (
        <PageWrapper width="95%">
          <PageHeader
            titleText="Zadanie"
            icon={<Task />}
            onRightComponent={
              <DetailsButton
                text="Powrót"
                startIcon={<KeyboardArrowLeft />}
                onClick={() => navigate(-1)}
              />
            }
          >
            <RefreshButton onClick={() => setRefresh((state) => !state)} />
            <TaskExecution
              task={chosenTask}
              setRefresh={setRefresh}
              chosenUser={chosenUser}
            />
          </PageHeader>
          <BlankSpace />
          <TaskViewDetails chosenTask={chosenTask} />
          <BlankSpace />
          <LocationDetails location={chosenTask.location} />
          <BlankSpace />
          <DeviceDetails device={chosenTask.device} />
          <BlankSpace />
          {chosenTask.type === "Work" && (
            <>
              <UnitsMigrationHistory taskData={chosenTask} />
              <BlankSpace />
              <DocumentationsView
                refresh={refresh}
                setRefresh={setRefresh}
                documentation={chosenTask.documentation}
                taskId={chosenTask.id}
              />
              <BlankSpace />
            </>
          )}
          {chosenTask.type === "Verification" && (
            <>
              <UnitsMigrationHistory taskData={chosenTask.parentTask} />
              <BlankSpace />
            </>
          )}
          <TaskComments
            comments={chosenTask.comments}
            setRefresh={setRefresh}
            taskId={chosenTask.id}
            taskStatus={chosenTask.status}
          />
        </PageWrapper>
      ) : null}
    </>
  );
};

export default TaskView;
