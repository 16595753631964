/* eslint-disable react/prop-types */
import React from "react";
import DocumentationItem from "./DocumentationItem";
import { CropOriginal, FileCopy, Warning } from "@mui/icons-material";
import PageHeader from "../../../PageHeader/PageHeader";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";
import EmptyListError from "../../../EmptyListError/EmptyListError";
import { Button } from "@mui/material";

// eslint-disable-next-line react/prop-types
const Documentations = ({
  documentations,
  setDocumentation,
  removeDocumentation,
}) => {
  const onFileChange = (file) => {
    if (file.length > 0) setDocumentation((state) => [...state, file[0]]);
  };

  return (
    <SectionWrapper>
      <PageHeader
        titleText="Załączniki"
        size="medium"
        icon={<CropOriginal />}
        onRightComponent={
          <Button
            component="label"
            startIcon={<FileCopy />}
            variant="contained"
            size="small"
            sx={{
              backgroundColor: "rgb(27,150,250)",
              fontSize: "0.75rem",
              height: "1.8125rem",
            }}
          >
            Załącz plik
            <input
              type="file"
              hidden
              onChange={(e) => onFileChange(e.target.files)}
            />
          </Button>
        }
      />
      <>
        {documentations.length > 0 ? (
          documentations.map((documentation, index) => (
            <DocumentationItem
              key={index}
              documentation={documentation}
              removeDocumentation={() => removeDocumentation(index)}
            />
          ))
        ) : (
          <EmptyListError
            message="Brak załączników"
            icon={<Warning />}
          />
        )}
      </>
    </SectionWrapper>
  );
};

export default Documentations;
