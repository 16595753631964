/* eslint-disable react/prop-types */
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from 'react';

const MidButtonWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const MidButtonSection = ({
  currentChosenPermissions,
  selectedUserPermissions,
  selectedDefaultPermissions,
  availablePermissionsSize,
  setCurrentChosenPermissions,
  setSelectedDefaultPermissions,
  transformedPermissions,
  setSelectedUserPermissions,
}) => {
  const moveRight = () => {
    setCurrentChosenPermissions((state) => {
      return [...state, ...selectedDefaultPermissions];
    });
    setSelectedDefaultPermissions([]);
  };

  const doubleMoveRight = () => {
    const array = [];
    transformedPermissions.map((category) =>
      category.map((permission) => array.push(permission))
    );
    setCurrentChosenPermissions(array);
  };

  const moveLeft = () => {
    const currentChosenPermissionsCopy = [...currentChosenPermissions].filter(
      (permission) => !selectedUserPermissions.includes(permission)
    );
    setCurrentChosenPermissions(currentChosenPermissionsCopy);
    setSelectedUserPermissions([]);
  };

  const doubleMoveLeft = () => {
    setCurrentChosenPermissions([]);
  };

  return (
    <MidButtonWrapper>
      <Button
        onClick={() => doubleMoveLeft()}
        disabled={currentChosenPermissions.length === 0}
      >
        <KeyboardDoubleArrowLeft />
      </Button>
      <Button
        onClick={() => moveLeft()}
        disabled={selectedUserPermissions.length === 0}
      >
        <KeyboardArrowLeft />
      </Button>
      <Button
        onClick={() => moveRight()}
        disabled={selectedDefaultPermissions.length === 0}
      >
        <KeyboardArrowRight />
      </Button>
      <Button
        onClick={() => doubleMoveRight()}
        disabled={availablePermissionsSize === currentChosenPermissions.length}
      >
        <KeyboardDoubleArrowRight />
      </Button>
    </MidButtonWrapper>
  );
};

export default MidButtonSection;