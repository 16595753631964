/* eslint-disable react/prop-types */
import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { workModalUnmountedUnitsHeaders } from "../../../../static/tablesHeaders";
import DeleteButton from "../../../DeleteButton/DeleteButton";
import CustomChip from "../../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../../static/static";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const UnmountedUnitsItemDesktop = ({ orderNumber, unit, deleteElement }) => {
  const { partNumber, status, serialNumber, quantity } = unit;
  return (
    <TableDataGridWrapper
      headers={workModalUnmountedUnitsHeaders}
      gap="1%"
    >
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{partNumber}</CustomDataDisplay>
      <CustomDataDisplay>{serialNumber}</CustomDataDisplay>
      <CustomChip label={status} sx={unitStatusChipStyles(status, "")} />
      <CustomDataDisplay>{quantity}</CustomDataDisplay>
      <DeleteButton
        onClick={() => deleteElement()}
        variant="contained"
        text="usuń"
      />
    </TableDataGridWrapper>
  );
};

export default UnmountedUnitsItemDesktop;
