/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import { polishProvinces } from "../../static/static";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createStorage,
  getMainStorage,
  getStorage,
  updateStorage,
} from "../../slices/storageSlice";
import { Add, ArrowBack, Edit } from "@mui/icons-material";
import { styled, useTheme } from "@mui/material/styles";
import { AcceptButton, DetailsButton } from "../FunctionalButtons/FunctionalButtons";
import PageHeader from "../PageHeader/PageHeader";
import CustomTextField from "../CustomTextField/CustomTextField";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import {
  clearUsers,
  getFilteredUsers,
  getUserByProvinceAndRole,
} from "../../slices/userSlice";

const CustomWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  [theme.breakpoints.down("laptop")]: {
    height: "auto",
  },
}));

const CustomInnerWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  alignItems: "center",
  justifyContent: "center",
  width: "30%",
  backgroundColor: "white",
  border: "0.0625rem solid #ddd",
  padding: "1.25rem 0.625rem",
  borderRadius: "1.25rem",
  [theme.breakpoints.down("desktop")]: {
    width: "40%",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "80%",
    padding: "0.625rem",
  },
}));

const StorageForm = ({ formType = "add" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  const [buttonLock, setButtonLock] = useState(false);
  const [storageData, setStorageData] = useState({
    name: "",
    type: "local",
    address: {
      street: "",
      city: "",
      province: "",
      country: "Polska",
      postcode: "",
    },
    serviceTechnician: "",
  });
  const [isMainStorageExistsCheck, setIsMainStorageExistsCheck] =
    useState(false);
  const { chosenStorage } = useSelector(
    (slice) => slice.storage
  );

  const getStorageData = useCallback(async () => {
    await dispatch(getStorage());
  }, []);

  const getUsersData = useCallback(async () => {
    await dispatch(getUserByProvinceAndRole({ province: "", role: "" }));
  }, []);

  const getMainStorageData = useCallback(async () => {
    await dispatch(getMainStorage());
  }, []);

  const isFormDataCompleted = () => {
    return !(
      !storageData.name ||
      !storageData.type ||
      !storageData.serviceTechnician ||
      !storageData.address.street ||
      !storageData.address.city ||
      !storageData.address.province ||
      !storageData.address.country ||
      !storageData.address.postcode
    );
  };

  useEffect(() => {
    getUsersData();
  }, [getUsersData]);

  useEffect(() => {
    if (formType === "add" && !isMainStorageExistsCheck) {
      getMainStorageData();
      setIsMainStorageExistsCheck(true);
    }
    if (
      isMainStorageExistsCheck &&
      chosenStorage === "brak magazynu głównego"
    ) {
      setStorageData((state) => {
        return { ...state, type: "main" };
      });
    }
  }, [getMainStorageData, isMainStorageExistsCheck, chosenStorage]);

  useEffect(() => {
    if (formType === "edit") {
      if (!chosenStorage) {
        getStorageData();
      } else {
        console.log("chosenStorage:   ", chosenStorage);
        setStorageData({
          name: chosenStorage.name,
          type: chosenStorage.type,
          address: {
            street: chosenStorage.address.street,
            city: chosenStorage.address.city,
            province: chosenStorage.address.province,
            country: chosenStorage.address.country,
            postcode: chosenStorage.address.postcode,
          },
          serviceTechnician: chosenStorage.user,
        });
      }
    }
  }, [getStorageData, chosenStorage]);

  const addStorage = useCallback(async () => {
    await dispatch(
      createStorage({
        ...storageData,
        serviceTechnician: storageData.serviceTechnician.id,
      })
    );
    if (storageData.type === "local") navigate(`/storages`);
    else {
      navigate(`/main-storage`);
    }
  }, [storageData]);

  const editStorage = useCallback(async () => {
    console.log("LECI");
    await dispatch(
      updateStorage({
        ...storageData,
        serviceTechnician: storageData.serviceTechnician.id,
      })
    );
    navigate(-1);
  }, [storageData]);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (keyCode === 13 && !buttonLock && isFormDataCompleted()) {
        if (formType === "add") addStorage();
        if (formType === "edit") editStorage();
        setButtonLock(true);
        setTimeout(() => setButtonLock(false), 5000);
      }
    },
    [buttonLock, addStorage, editStorage]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <CustomWrapper>
      <CustomInnerWrapper>
        <PageHeader
          icon={formType === "add" ? <Add /> : <Edit />}
          size="medium"
          titleText={
            formType === "add" ? "Dodawanie magazynu" : "Edycja magazynu"
          }
          onRightComponent={
            <DetailsButton
              styles={{
                position: "absolute",
                top: "2em",
                right: "2em",
                [breakpoints.down("laptop")]: {
                  position: "relative",
                },
              }}
              startIcon={<ArrowBack />}
              onClick={() => navigate("/storages")}
              text="Lista magazynów"
            />
          }
        />
        <Typography sx={{ color: "red", fontSize: "0.8rem" }}>
          *Pola obowiązkowe
        </Typography>
        <CustomTextField
          label="Nazwa*"
          value={storageData.name}
          onChange={(e) =>
            setStorageData((state) => {
              return { ...state, name: e.target.value };
            })
          }
          width="80%"
          maxLength={100}
        />
        <CustomTextField
          id="typeSelect"
          select
          label="Typ*"
          value={storageData.type}
          onChange={(e) =>
            setStorageData((state) => {
              return { ...state, type: e.target.value };
            })
          }
          counter={false}
          width="80%"
        >
          <MenuItem
            value="main"
            disabled={chosenStorage && chosenStorage.type === "main"}
          >
            główny
          </MenuItem>
          <MenuItem
            value="local"
            disabled={chosenStorage === "brak magazynu głównego"}
          >
            lokalny
          </MenuItem>
        </CustomTextField>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5em",
            width: "80%",
          }}
        >
          <Typography sx={{ fontSize: "1.2em" }}>Adres</Typography>
          <CustomTextField
            label="Ulica*"
            value={storageData.address.street}
            onChange={(e) =>
              setStorageData((state) => {
                return {
                  ...state,
                  address: {
                    street: e.target.value,
                    city: state.address.city,
                    province: state.address.province,
                    country: state.address.country,
                    postcode: state.address.postcode,
                  },
                };
              })
            }
            width="100%"
            maxLength={50}
          />
          <CustomTextField
            label="Miasto*"
            value={storageData.address.city}
            onChange={(e) =>
              setStorageData((state) => {
                return {
                  ...state,
                  address: {
                    street: state.address.street,
                    city: e.target.value,
                    province: state.address.province,
                    country: state.address.country,
                    postcode: state.address.postcode,
                  },
                };
              })
            }
            width="100%"
            maxLength={50}
          />
          <CustomTextField
            id="provinceSelect"
            width="100%"
            select
            label="Województwo*"
            value={storageData.address.province}
            onChange={(e) =>
              setStorageData((state) => {
                return {
                  ...state,
                  address: {
                    street: state.address.street,
                    city: state.address.city,
                    province: e.target.value,
                    country: state.address.country,
                    postcode: state.address.postcode,
                  },
                };
              })
            }
            counter={false}
            maxLength={50}
          >
            {polishProvinces.map((province, index) => (
              <MenuItem key={index} value={province}>
                {province}
              </MenuItem>
            ))}
          </CustomTextField>
          <CustomTextField
            label="Kraj*"
            value={storageData.address.country}
            onChange={(e) =>
              setStorageData((state) => {
                return {
                  ...state,
                  address: {
                    street: state.address.street,
                    city: state.address.city,
                    province: state.address.province,
                    country: e.target.value,
                    postcode: state.address.postcode,
                  },
                };
              })
            }
            width="100%"
            maxLength={50}
          />
          <CustomTextField
            label="Kod pocztowy*"
            value={storageData.address.postcode}
            onChange={(e) =>
              setStorageData((state) => {
                return {
                  ...state,
                  address: {
                    street: state.address.street,
                    city: state.address.city,
                    province: state.address.province,
                    country: state.address.country,
                    postcode: e.target.value,
                  },
                };
              })
            }
            width="100%"
            maxLength={20}
          />
        </Box>
        {(chosenStorage !== null || formType === "add") && (
          <SearchDropdown
            collectionName="users"
            sliceName="user"
            getDataFunction={(searchingValue) =>
              getFilteredUsers({ searchingValue, role: "service_technician" })
            }
            placeholder="Serwisant*"
            setFunction={(user) =>
              setStorageData((state) => {
                return {
                  ...state,
                  serviceTechnician: user,
                };
              })
            }
            propertyToDisplay="fullData"
            propsWidth="80%"
            clearFunction={clearUsers}
            outerValue={
              chosenStorage
                ? chosenStorage.user
                  ? chosenStorage.user
                    ? chosenStorage.user.fullData
                    : ""
                  : ""
                : ""
            }
          />
        )}
        <AcceptButton
          onClick={
            formType === "add" ? () => addStorage() : () => editStorage()
          }
          styles={{ width: "80%" }}
          startIcon={formType === "add" ? <Add /> : <Edit />}
          text={formType === "add" ? "Dodaj magazyn" : "Edytuj magazyn"}
          isMobileViewDisabled
          disabled={buttonLock || !isFormDataCompleted()}
          afterLoading
        />
      </CustomInnerWrapper>
    </CustomWrapper>
  );
};

export default StorageForm;
