/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableHeader from "../../../TableHeader/TableHeader";
import { shipmentInventoryHeaders } from "../../../../static/tablesHeaders";
import UnitCheckSectionItem from "./UnitCheckSectionItem";

const Wrapper = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  width: '90%'
}));

const CustomWrapper = styled(Box)(({ theme }) => ({
  maxHeight: "18rem",
  overflowY: "auto",
  [theme.breakpoints.down("tablet")]: {
    maxHeight: "30rem",
  },
}));

const UnitCheckSection = ({ inventory, report, setReport }) => {
  return (
    <Wrapper>
      <Typography sx={{ fontSize: "1.5rem", width: "90%" }}>
        Składowe przesyłki
      </Typography>
      <TableHeader
        setSorter={() => {}}
        setSorting={() => {}}
        headers={shipmentInventoryHeaders}
        gap="1%"
        styles={{ height: "3rem" }}
      />
      <CustomWrapper>
        {inventory.map((unit, id) => (
          <UnitCheckSectionItem
            unit={unit}
            key={id}
            report={report}
            setReport={setReport}
            orderNumber={id + 1}
          />
        ))}
      </CustomWrapper>
    </Wrapper>
  );
};

export default UnitCheckSection;
