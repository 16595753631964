/* eslint-disable react/prop-types */
import React from "react";
import { TextField, IconButton } from "@mui/material";
import { Search, Clear } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

const CustomSearchField = ({
  displaySearchingValue,
  setDisplaySearchingValue,
  searchingValue,
  setSearchingValue,
  size = "small",
  placeholder = "",
  searchingRef,
  width = '40%',
  widthMobile = "90%"
}) => {
  const changeSearchingValue = (value) => {
    setDisplaySearchingValue(value);
    searchingRef.current = value;
    setTimeout(() => setSearchingValue(searchingRef.current), 1000);
  };
  const { breakpoints } = useTheme();

  return (
    <TextField
      value={displaySearchingValue}
      onChange={(e) => changeSearchingValue(e.target.value)}
      placeholder={placeholder}
      size={size}
      autoComplete="off"
      InputProps={{
        startAdornment: <Search sx={{ marginRight: "0.3em" }} />,
        endAdornment: (
          <IconButton
            sx={{ visibility: searchingValue ? "visible" : "hidden" }}
            onClick={() => changeSearchingValue("")}
          >
            <Clear />
          </IconButton>
        ),
      }}
      sx={{
        width: width,
        [breakpoints.down("laptop")]: {
          width: widthMobile,
        },
      }}
    />
  );
};

export default CustomSearchField;
