/* eslint-disable react/prop-types */
import { Edit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React from "react";

const ModifiedItemInfo = ({ message }) => {
  return (
    <Tooltip title={message}>
      <Edit sx={{ fontSize: "1.2rem" }} />
    </Tooltip>
  );
};

export default ModifiedItemInfo;
