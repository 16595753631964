/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState} from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import ShipmentsByItemMobile from "../ShipmentsByItemVersions/ShipmentsByItemMobile";
import ShipmentsByItemDesktop from "../ShipmentsByItemVersions/ShipmentsByItemDesktop";
import ToDeliveredModal from "../ShipmentsElements/ToDeliveredModal/ToDeliveredModal";
import ToInProgressModal from "../ShipmentsElements/ToInProgressModal";

const ShipmentsByDestinationItem = ({
  orderNumber,
  shipment,
  chosenStorageId,
  setRefresh
}) => {
  const { isDesktopDisplay } = useMediaQ();
    const [
      isStatusChangeToDeliveredModalVisible,
      setIsStatusChangeToDeliveredModalVisible,
    ] = useState(false);

    const [
      isStatusChangeToInProgressModalVisible,
      setIsStatusChangeToInProgressModalVisible,
    ] = useState(false);

  return (
    <>
      {isDesktopDisplay ? (
        <ShipmentsByItemDesktop
          orderNumber={orderNumber}
          shipment={shipment}
          chosenStorageId={chosenStorageId}
          setIsStatusChangeToDeliveredModalVisible={
            setIsStatusChangeToDeliveredModalVisible
          }
          setIsStatusChangeToInProgressModalVisible={
            setIsStatusChangeToInProgressModalVisible
          }
        />
      ) : (
        <ShipmentsByItemMobile
          orderNumber={orderNumber}
          shipment={shipment}
          chosenStorageId={chosenStorageId}
          setIsStatusChangeToDeliveredModalVisible={
            setIsStatusChangeToDeliveredModalVisible
          }
          setIsStatusChangeToInProgressModalVisible={
            setIsStatusChangeToInProgressModalVisible
          }
        />
      )}
      <ToDeliveredModal
        isStatusChangeToDeliveredModalVisible={
          isStatusChangeToDeliveredModalVisible
        }
        setIsStatusChangeToDeliveredModalVisible={
          setIsStatusChangeToDeliveredModalVisible
        }
        shipment={shipment}
        setRefresh={setRefresh}
      />

      <ToInProgressModal
        shipmentId={shipment.id}
        isStatusChangeToInProgressModalVisible={
          isStatusChangeToInProgressModalVisible
        }
        setIsStatusChangeToInProgressModalVisible={
          setIsStatusChangeToInProgressModalVisible
        }
        setRefresh={setRefresh}
      />
    </>
  );
};

export default ShipmentsByDestinationItem;
