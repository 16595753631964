/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProductId } from "../../../slices/productSlice";
import ProductDeleteForm from "../../Product/ProductDeleteForm/ProductDeleteForm";
import useMediaQ from "../../../hooks/useMediaQ";
import ProductItemDesktop from "./ProductItemVersions/ProductItemDesktop";
import ProductItemMobile from "./ProductItemVersions/ProductItemMobile";

const ProductItem = ({ orderNumber, product, setRefresh }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isDesktopDisplay } = useMediaQ();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id, name } = product;

  const editProduct = () => {
    dispatch(setProductId(id));
    navigate("/product-edit");
  };

  const delProduct = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      {isDesktopDisplay ? (
        <ProductItemDesktop
          orderNumber={orderNumber}
          product={product}
          editProduct={editProduct}
          delProduct={delProduct}
        />
      ) : (
        <ProductItemMobile
          orderNumber={orderNumber}
          product={product}
          editProduct={editProduct}
          delProduct={delProduct}
        />
      )}
      <Dialog open={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <ProductDeleteForm
          productId={id}
          productName={name}
          setVisibility={setIsModalVisible}
          setRefresh={setRefresh}
        />
      </Dialog>
    </>
  );
};

export default ProductItem;
