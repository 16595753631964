/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchDropdown from "../../../SearchDropdown/SearchDropdown";
import {
  clearDevicesData,
  getLocationFilteredDevices,
} from "../../../../slices/deviceSlice";
import { useDispatch } from "react-redux";
import { Add } from "@mui/icons-material";
import { DetailsButton } from "../../../FunctionalButtons/FunctionalButtons";
import useMediaQ from "../../../../hooks/useMediaQ";
import { setError } from "../../../../slices/commissionSlice";

const CustomWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  gap: "1rem",
}));

const ChooseDeviceSelectPage = ({
  chosenDevice,
  setChosenDevice,
  locationId,
  mode,
  setIsWarningModalOpen,
}) => {
  const { isPhoneDisplay } = useMediaQ();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearDevicesData());
  });

  return (
    <CustomWrapper>
      <SearchDropdown
        collectionName="devices"
        sliceName="device"
        getDataFunction={(searchingValue) =>
          getLocationFilteredDevices({ locationId, searchingValue })
        }
        placeholder="Urządzenie"
        outerValue={
          chosenDevice
            ? chosenDevice.name !== undefined
              ? chosenDevice.name
              : ""
            : ""
        }
        setFunction={setChosenDevice}
        propertyToDisplay="serialNumberName"
        propsWidth={isPhoneDisplay ? "90%" : "50%"}
        errorFunction={(message) =>
          setError({ attribute: "commissionForm", value: message })
        }
        errorMessage="Nie można utworzyć zlecenia. Na wybrane urządzenie istnieje aktywne zlecenie!"
      />
      <DetailsButton
        startIcon={<Add />}
        text="Utwórz nowe urządzenie"
        onClick={
          mode === "select" ? () => setIsWarningModalOpen(true) : () => {}
        }
      />
    </CustomWrapper>
  );
};

export default ChooseDeviceSelectPage;
