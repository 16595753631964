import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API,
  errorMessages
} from '../static/static';
import {
  getErrorCode
} from '../static/functions';

export const getUnit = createAsyncThunk('unit/get', async (unitId) => {
  const resData = await dataService.get(`${API}unit/${unitId}`);
  return resData;
});

export const updateUnit = createAsyncThunk('unit/update', async ({
  unitId,
  data
}) => {
  const resData = await dataService.put(`${API}unit/update/${unitId}/`, data);
  return resData;
});

export const deleteUnit = createAsyncThunk('unit/delete', async (unitId) => {
  const resData = await dataService.delete(`${API}unit/delete/${unitId}`);
  return resData;
});

export const getUnitsByStorage = createAsyncThunk('unit/get/byStorage', async (storageId) => {
  const resData = await dataService.get(`${API}units/${storageId}/`);
  return resData;
});

export const getPaginatedUnitsByStorage = createAsyncThunk('unit/get/byStorage/pagination', async (data) => {
  let {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    status,
    categories,
    producers,
    storageId,
    greaterThanZero
  } = data;
  if (greaterThanZero === undefined) {
    greaterThanZero = false;
  }
  const resData = await dataService.get(`${API}units/${storageId}/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&status=${status}&categories=${categories}&producers=${producers}&greaterThanZero=${greaterThanZero}/`);
  return resData;
});

export const getUnitsByStorageCount = createAsyncThunk('unit/get/byStorage/count', async (data) => {
  let {
    searchingValue,
    status,
    storageId,
    categories,
    producers,
    greaterThanZero
  } = data;
  if (greaterThanZero === undefined) {
    greaterThanZero = false;
  }
  const resData = await dataService.get(`${API}units/${storageId}/count/?searchingPhrase=${searchingValue}&status=${status}&categories=${categories}&producers=${producers}&greaterThanZero=${greaterThanZero}/`);
  return resData;
});

export const getPaginatedUnitsByUtilizationReport = createAsyncThunk('unit/get/byUtilizationReport/pagination', async (data) => {
  const {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    status,
    utilRepId
  } = data;
  const resData = await dataService.get(`${API}units/ur/${utilRepId}/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&status=${status}`);
  return resData;
});

export const getUnitsByUtilizationReportCount = createAsyncThunk('unit/get/byUtilizationReport/count', async (data) => {
  const {
    searchingValue,
    status,
    utilRepId
  } = data;
  const resData = await dataService.get(`${API}units/ur/${utilRepId}/count/?searchingPhrase=${searchingValue}&status=${status}`);
  return resData;
});

export const increaseUnitsState = createAsyncThunk('units/increase', async ({
  storageId,
  data
}) => {
  const resData = await dataService.put(`${API}units/increase/${storageId}/`, {records: data});
  return resData;
});

export const moveUnits = createAsyncThunk('units/move', async ({
  storageId,
  data
}) => {
  const resData = await dataService.put(`${API}units/move/${storageId}/`, data);
  return resData;
});

export const getShipmentUnits = createAsyncThunk('shipments/units/get', async (shipmentId) => {
  const resData = await dataService.get(`${API}shipments/units/${shipmentId}/`);
  return resData;
});


const initialState = {
  units: null,
  unitsCount: 0,
  chosenUnit: null,
  unitInfo: null,
  unitError: null
};

export const unitSlice = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    clearUnit: (state) => {
      state.chosenUnit = null;
    },
    clearUnits: (state) => {
      state.units = null;
      state.unitsCount = 0;
    },
    clearUnitInfo: (state) => {
      state.unitInfo = null;
    },
    clearUnitError: (state) => {
      state.unitError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnit.fulfilled, (state, action) => {
        state.chosenUnit = action.payload.data;
      })
      // .addCase(getUnitsByStorage.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(updateUnit.fulfilled, (state) => {
        state.unitInfo = 'Pomyślnie zaktualizowano jednostkę';
      })
      // .addCase(updateUnit.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(deleteUnit.fulfilled, (state) => {
        state.unitInfo = 'Pomyślnie usunięto jednostkę';
      })
      // .addCase(getUnitsByStorage.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getPaginatedUnitsByStorage.fulfilled, (state, action) => {
        state.units = action.payload.data;
      })
      // .addCase(getPaginatedUnitsByStorage.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getUnitsByStorageCount.fulfilled, (state, action) => {
        state.unitsCount = action.payload.data;
      })
      // .addCase(getUnitsByStorageCount.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getPaginatedUnitsByUtilizationReport.fulfilled, (state, action) => {
        state.units = action.payload.data;
      })
      // .addCase(getPaginatedUnitsByStorage.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getUnitsByUtilizationReportCount.fulfilled, (state, action) => {
        state.unitsCount = action.payload.data;
      })
      // .addCase(getUnitsByStorageCount.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(increaseUnitsState.fulfilled, (state) => {
        state.unitInfo = "Pomyślnie zwiększono stan magazynowy";
      })
      .addCase(increaseUnitsState.rejected, (state, action) => {
        state.unitError = errorMessages['unit'][getErrorCode(action.error.message)];
      })
      .addCase(moveUnits.fulfilled, () => {})
      // .addCase(moveUnits.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })

      .addCase(getShipmentUnits.fulfilled, (state, action) => {
        state.units = action.payload.data;
      });
    // .addCase(getShipmentUnits.rejected, (_, action) => {
    //   alert(`Operation failed - ${action.error.message}`);
    // })

  }
});

// Action creators are generated for each case reducer function
export const {
  clearUnit,
  clearUnits,
  clearUnitInfo,
  clearUnitError
} = unitSlice.actions;

export default unitSlice.reducer;