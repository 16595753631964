import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API
} from '../static/static';


export const getUtilizationReport = createAsyncThunk('utilizationReport/get', async (utilizationReportId) => {
  const resData = utilizationReportId ? await dataService.get(`${API}utilizationReport/${utilizationReportId}`) : await dataService.get(`${API}utilizationReport/${localStorage.getItem('chosenUtilizationReportId')}`);
  return resData;
});

export const getUtilizationReports = createAsyncThunk('utilizationReports/get', async () => {
  const resData = await dataService.get(`${API}utilizationReports/`);
  return resData;
});

export const getPaginatedUtilizationReports = createAsyncThunk('utilizationReport/get/pagination', async (data) => {
  const {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    createdBy,
    creationDateStart,
    creationDateEnd,
  } = data;
  const resData = await dataService.get(`${API}utilizationReports/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&createdBy=${createdBy}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}`);
  return resData;
});

export const getUtilizationReportsCount = createAsyncThunk('utilizationReports/get/count', async (data) => {
  const {
    searchingValue,
    createdBy,
    creationDateStart,
    creationDateEnd,
  } = data;
  const resData = await dataService.get(`${API}utilizationReports/count/?searchingPhrase=${searchingValue}&createdBy=${createdBy}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}`);
  return resData;
});


export const createUtilizationReport = createAsyncThunk('utilizationReport/create', async (data) => {
  const resData = await dataService.post(`${API}utilizationReports/create/`, data);
  return resData;
});


const initialState = {
  utilizationReports: null,
  utilizationReportsCount: 0,
  chosenUtilizationReport: null,
  chosenUtilizationReportId: null
};

export const utilizationReportSlice = createSlice({
  name: 'utilizationReport',
  initialState,
  reducers: {
    setChosenUtilizationReportId: (state, action) => {
      localStorage.setItem('chosenUtilizationReportId', action.payload);
      state.chosenUtilizationReportId = action.payload;
    },
    clearUtilizationReports: (state) => {
      state.utilizationReports = null;
      state.utilizationReportsCount = 0;
    },
    clearUtilizationReport: (state) => {
      state.chosenUtilizationReport = null;
      state.chosenUtilizationReportId = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUtilizationReport.fulfilled, (state, action) => {
        state.chosenUtilizationReport = action.payload.data;
        state.chosenUtilizationReportId = action.payload.data.id;
      })
      // .addCase(getShipment.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getUtilizationReports.fulfilled, (state, action) => {
        state.utilizationReports = action.payload.data;
      })
      .addCase(getPaginatedUtilizationReports.fulfilled, (state, action) => {
        state.utilizationReports = action.payload.data;
      })
      .addCase(getUtilizationReportsCount.fulfilled, (state, action) => {
        state.utilizationReportsCount = action.payload.data;
      });
  }
});

// Action creators are generated for each case reducer function
export const {
  setChosenUtilizationReportId,
  clearUtilizationReports,
  clearUtilizationReport
} = utilizationReportSlice.actions;

export default utilizationReportSlice.reducer;