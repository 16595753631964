/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const DocumentationItem = ({ documentation, removeDocumentation }) => {
  return (
    <Box sx={{ display: "flex", gap: "0.625em", alignItems: "center" }}>
      <Typography
        sx={{
          ["@media (max-width:767px)"]: {
            fontSize: '0.8em'
          },
        }}
      >
        {documentation?.name}
      </Typography>
      <Button onClick={() => removeDocumentation()}>X</Button>
    </Box>
  );
};

export default DocumentationItem;