/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { producersListHeaders } from "../../../../static/tablesHeaders";
import EditButton from "../../../EditButton/EditButton";
import DeleteButton from "../../../DeleteButton/DeleteButton";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "0.1rem 1rem",
}));

const ProducersItemDesktop = ({
  orderNumber,
  producer,
  editProducer,
  delProducer,
}) => {
  const { name } = producer;

  return (
    <TableDataGridWrapper headers={producersListHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <ButtonWrapper>
        <EditButton onClick={() => editProducer()} />
        <DeleteButton onClick={() => delProducer()} />
      </ButtonWrapper>
    </TableDataGridWrapper>
  );
};

export default ProducersItemDesktop;
