/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaginatedRequests,
  getRequestsCount,
} from "../../../slices/requestSlice";
import { getStorage, setStorageId } from "../../../slices/storageSlice";
import RequestItem from "../RequestItem/RequestItem";
import Pagination from "../../Pagination/Pagination";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../../../static/static";
import { ListAlt, Add, KeyboardArrowLeft, Info } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { requestStatusChipStyles } from "../../../static/static";
import PageHeader from "../../PageHeader/PageHeader";
import StorageDetails from "../../Storage/StorageDetails";
import BlankSpace from "../../BlankSpace/BlankSpace";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import { getLocalTimeDate } from "../../../static/functions";
import CustomDatePicker from "../../CustomDatePicker/CustomDatePicker";
import ChipSet from "../../ChipSet/ChipSet";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import { requestListHeaders } from "../../../static/tablesHeaders";
import TableHeader from "../../TableHeader/TableHeader";
import EmptyListError from "../../EmptyListError/EmptyListError";
import Loading from "../../Loading/Loading";
import SwitchTab from "../../SwitchTab/SwitchTab";
import RefreshButton from "../../RefreshButton/RefreshButton";
import PageWrapper from "../../PageWrapper/PageWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import TableWrapper from "../../TableWrapper/TableWrapper";
import authService from "../../../auth/authService";
import ReturnButton from "../../ReturnButton/ReturnButton";
import ButtonWrapper from "../../ButtonWrapper/ButtonWrapper";

const chipSet = [
  {
    label: "Wszystkie",
    keyValue: "",
  },
  {
    label: "Nowe",
    keyValue: "Sent",
  },
  {
    label: "W realizacji",
    keyValue: "Accepted",
  },
  {
    label: "Realizowane częściowo",
    keyValue: "Warned",
  },
  {
    label: "Odrzucone",
    keyValue: "Rejected",
  },
];

const RequestsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(-1);
  const {
    requests: { all: allRequests },
    requestsCount,
  } = useSelector((slice) => slice.request);
  const { chosenStorage } = useSelector((slice) => slice.storage);
  const { name, surname, user_id, role } = authService.getDecodedAccessToken();
  const searchingRef = useRef("");
  const [requestsKind, setRequestsKind] = useState(
    role === "service_technician" ? "outcoming" : "incoming"
  );
  const [sorter, setSorter] = useState("creationDate");
  const [sorting, setSorting] = useState("descending");
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(DEFAULT_DISPLAY_ITEMS_QUANTITY);
  const [searchingValue, setSearchingValue] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [displaySearchingValue, setDisplaySearchingValue] = useState("");
  const [creationDates, setCreationDates] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const setCreationDateInterval = (dates) => {
    const [startDate, endDate] = dates;
    setCreationDates({
      start: startDate,
      end: endDate,
    });
  };

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getRequestsCount({
        searchingValue,
        status: filteredValue,
        isSeen: "",
        parentRequest: "null",
        baseStorage: requestsKind === "incoming" ? "" : chosenStorage.id,
        destinationStorage: requestsKind === "incoming" ? chosenStorage.id : "",
        sender: "",
        receiver: "",
        childRequest: "",
        creationDateStart: creationDates.start
          ? getLocalTimeDate(creationDates.start)
          : "",
        creationDateEnd: creationDates.end
          ? getLocalTimeDate(creationDates.end)
          : "",
      })
    );
  }, [
    requestsKind,
    chosenStorage,
    searchingValue,
    refresh,
    creationDates.start,
    creationDates.end,
  ]);

  const loadData = useCallback(async () => {
    await dispatch(
      getPaginatedRequests({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        status: filteredValue,
        isSeen: "",
        parentRequest: "null",
        baseStorage: requestsKind === "incoming" ? "" : chosenStorage.id,
        destinationStorage: requestsKind === "incoming" ? chosenStorage.id : "",
        sender: "",
        receiver: "",
        childRequest: "",
        creationDateStart: creationDates.start
          ? getLocalTimeDate(creationDates.start)
          : "",
        creationDateEnd: creationDates.end
          ? getLocalTimeDate(creationDates.end)
          : "",
      })
    );
    setIsDataLoading(false);
  }, [
    leftIndex,
    rightIndex,
    requestsKind,
    sorter,
    sorting,
    chosenStorage,
    searchingValue,
    refresh,
    creationDates.start,
    creationDates.end,
  ]);

  const getDataStorage = useCallback(async () => {
    await dispatch(getStorage());
  }, []);

  useEffect(() => {
    getDataStorage();
  }, []);

  useEffect(() => {
    if (chosenStorage !== null) {
      loadDataCount();
    }
  }, [loadDataCount]);

  useEffect(() => {
    if (chosenStorage !== null) {
      loadData();
    }
  }, [loadData]);

  if (chosenStorage === null) return <Loading />;

  return (
    <>
      {chosenStorage !== null && (
        <PageWrapper width="90%">
          <PageHeader
            titleText="Historia zapotrzebowań"
            icon={<ListAlt />}
            onRightComponent={
              <ButtonWrapper>
                <DetailsButton
                  text="Utwórz zapotrzebowanie"
                  onClick={() => {
                    dispatch(setStorageId(chosenStorage.id));
                    navigate("/request-add");
                  }}
                  startIcon={<Add />}
                />
                <ReturnButton />
              </ButtonWrapper>
            }
          >
            <RefreshButton onClick={() => setRefresh((state) => !state)} />
          </PageHeader>
          <BlankSpace />
          <StorageDetails
            chosenStorage={chosenStorage}
            type={chosenStorage.type}
          />
          <BlankSpace />
          <SectionWrapper>
            <AdditionalFunctionalitiesWrapper>
              <ButtonWrapper>
                <SwitchTab
                  text="Przychodzące"
                  onClick={() => setRequestsKind("incoming")}
                  selected={requestsKind !== "incoming"}
                />
                <SwitchTab
                  text="Wychodzące"
                  onClick={() => setRequestsKind("outcoming")}
                  selected={requestsKind === "incoming"}
                />
              </ButtonWrapper>
              <CustomSearchField
                displaySearchingValue={displaySearchingValue}
                setDisplaySearchingValue={setDisplaySearchingValue}
                searchingValue={searchingValue}
                setSearchingValue={setSearchingValue}
                searchingRef={searchingRef}
                placeholder="szukaj zapotrzebowania..."
              />
              <CustomDatePicker
                labelName="Data wysłania"
                datesRange={creationDates}
                setDatesRange={(dates) => setCreationDateInterval(dates)}
                maxDate={new Date()}
              />
              <ChipSet
                mainLabel="Status:"
                set={chipSet}
                chipStyles={requestStatusChipStyles}
                setFunction={setFilteredValue}
                actualValue={filteredValue}
                styleProps={{}}
              />
            </AdditionalFunctionalitiesWrapper>
            <TableWrapper>
              <TableHeader
                headers={requestListHeaders}
                setSorter={setSorter}
                setSorting={setSorting}
                gap="1%"
              />
              <Pagination
                collectionSize={requestsCount}
                leftIndex={leftIndex}
                rightIndex={rightIndex}
                setLeftIndex={setLeftIndex}
                setRightIndex={setRightIndex}
              >
                <>
                  {isDataLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {allRequests !== null &&
                        allRequests.map((request, id) => (
                          <RequestItem
                            key={id}
                            request={request}
                            orderNumber={leftIndex + id + 1}
                            chosenStorage={chosenStorage}
                          />
                        ))}
                    </>
                  )}

                  {allRequests !== null && allRequests.length === 0 && (
                    <EmptyListError
                      message="Brak zapotrzebowań"
                      icon={<Info />}
                    />
                  )}
                </>
              </Pagination>
            </TableWrapper>
          </SectionWrapper>
        </PageWrapper>
      )}
    </>
  );
};

export default RequestsList;
