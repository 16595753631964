/* eslint-disable react/prop-types */
import { Chip } from "@mui/material";
import React from "react";

const SelectChipsItem = ({ addChip, itemData }) => {
  const { name } = itemData;

  return (
    <Chip label={name} variant="outlined" onClick={() => addChip(itemData)} />
  );
};

export default SelectChipsItem;
