/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

// eslint-disable-next-line no-unused-vars
const CustomWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.pageBackgroundColor.main,
  margin: "1rem auto",
  maxWidth: "100rem",
  [theme.breakpoints.down("laptop")]: {
    margin: "3rem auto 0 auto",
  },
}));

const PageWrapper = ({ children, width = "auto", sx = {} }) => {
  return <CustomWrapper sx={{ width: width, ...sx }}>{children}</CustomWrapper>;
};

export default PageWrapper;
