/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import PageHeader from "../../PageHeader/PageHeader";
import { Add, Warning } from "@mui/icons-material";
import AddUnitField from "../AddUnitField/AddUnitField";
import TableHeader from "../../TableHeader/TableHeader";
import { AddedUnitsHeaders } from "../../../static/tablesHeaders";
import EmptyListError from "../../EmptyListError/EmptyListError";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import TableWrapper from "../../TableWrapper/TableWrapper";

const DataWrapper = styled(Box)(() => ({
  maxHeight: "21.875rem",
  overflowY: "scroll",
}));

const AddedUnits = ({
  removeItem,
  formValues,
  setFormValues,
  formError,
  setFormError,
  selectedUnits,
}) => {

  return (
    <SectionWrapper>
      <PageHeader
        icon={<Add />}
        titleText="Dodane jednostki"
        size="medium"
      />
      <TableWrapper>
        <TableHeader
          headers={AddedUnitsHeaders}
          setSorter={() => {}}
          setSorting={() => {}}
          gap="1%"
          scroll
        />
        <DataWrapper>
          {selectedUnits.map((unit, index) => (
            <AddUnitField
              key={index}
              unit={unit}
              index={index}
              formValues={formValues}
              setFormValues={setFormValues}
              removeItem={() =>
                removeItem({
                  id: unit.index,
                  partNumber: unit.partNumber,
                })
              }
              formError={formError}
              setFormError={setFormError}
              orderNumber={index + 1}
            />
          ))}
        </DataWrapper>
        {selectedUnits.length === 0 && (
          <EmptyListError
            message="Brak dodanych jednostek"
            icon={<Warning />}
          />
        )}
      </TableWrapper>
    </SectionWrapper>
  );
};

export default AddedUnits;
