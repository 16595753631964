/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  AcceptButton,
  EditButton,
} from "../../FunctionalButtons/FunctionalButtons";
import { Cancel } from "@mui/icons-material";

const CustomBox = styled(Box)(({ theme }) => ({
  height: "7.5rem",
  width: "31.25rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("tablet")]: {
    width: "18rem",
    height: "11rem",
  },
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  gap: "1em",
  marginTop: "1em",
}));

const SwitchQuestionModal = ({
  stepsString,
  executiveFunction,
  closeFunction,
}) => {
  return (
    <CustomBox>
      <Typography sx={{ fontSize: "1.2em", width: '100%' }}>
        Czy na pewno chcesz przełączyć okno ?
      </Typography>
      <Typography sx={{ fontSize: "1em", color: "red", fontWeight: 700 }}>
        {`Przełączenie spowoduje utratę wszystkich danych z kroków ${stepsString}!`}
      </Typography>
      <ButtonWrapper>
        <AcceptButton
          onClick={() => {
            executiveFunction();
            closeFunction();
          }}
          size="small"
          text="Tak"
        />
        <EditButton
          onClick={() => closeFunction()}
          size="small"
          text="Nie"
          startIcon={<Cancel />}
        />
      </ButtonWrapper>
    </CustomBox>
  );
};

export default SwitchQuestionModal;
