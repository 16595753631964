/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { executeQualificationTask } from "../../../slices/taskSlice";
import { Clear } from "@mui/icons-material";
import AcceptButton from "../../AcceptButton/AcceptButton";
import EditButton from "../../EditButton/EditButton";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "8.125rem",
  width: "25rem",
  padding: "0.5rem",
  [theme.breakpoints.down("laptop")]: {
    width: "20rem",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "14rem",
    height: "10rem",
  },
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  gap: "1.5rem",
  marginTop: "1rem",
}));

const QualificationFinalModal = ({
  data,
  setIsFinalModal,
  setIsQualificationModalVisible,
  setRefresh,
}) => {
  const dispatch = useDispatch();

  const confirmDecision = async () => {
    await dispatch(executeQualificationTask(data));
    setIsFinalModal(false);
    setIsQualificationModalVisible(false);
    setRefresh((state) => !state);
  };

  return (
    <Wrapper>
      <Typography sx={{ fontSize: "1.25rem" }}>
        Czy na pewno chcesz zatwierdzić wybór?
      </Typography>
      <Typography sx={{ fontSize: "0.75rem", color: "red" }}>
        Po zatwierdzeniu nie będzie można zmienić typu zlecenia!
      </Typography>
      <ButtonWrapper>
        <AcceptButton
          onClick={() => confirmDecision()}
          text="tak"
        />
        <EditButton
          onClick={() => setIsFinalModal(false)}
          startIcon={<Clear />}
          text="nie"
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default QualificationFinalModal;
