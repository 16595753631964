import {
  // USER_DATA,
  // API,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  SERVER_URL
} from '../static/static';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';

const authService = {
  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  },
  getDecodedAccessToken() {
    try {
      if (localStorage.getItem("userData")) {
        const userData = JSON.parse(localStorage.getItem("userData"));
        return {
          ...userData,
          user_id: userData.id
        };
      }
      return jwtDecode(localStorage.getItem(ACCESS_TOKEN));
    } catch (e) {
      return {
        user_id: ""
      };
    }
  },
  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  },
  saveAccessToken(token) {
    localStorage.setItem(ACCESS_TOKEN, token);
  },
  saveRefreshToken(token) {
    localStorage.setItem(REFRESH_TOKEN, token);
  },
  getAuthHeader() {
    const token = this.getAccessToken();
    return token ? {
      Authorization: `Bearer ${token}`
    } : {};
  },
  // saveUserData(data) {
  //   return localStorage.setItem(USER_DATA, JSON.stringify(data));
  // },
  // getUserData() {
  //   return JSON.parse(localStorage.getItem(USER_DATA));
  // },
  async login({
    login,
    password
  }) {
    const data = {
      username: login,
      password
    };
    const {
      data: {
        access,
        refresh
      }
    } = await axios.post(`${SERVER_URL}/auth/token/`, data);
    this.saveAccessToken(access);
    this.saveRefreshToken(refresh);
    return jwtDecode(access);
  },
  // async login({
  //   login,
  //   password
  // }) {
  //   const data = {
  //     username: login,
  //     password
  //   };
  //   const { data: response } = await axios.post(`${SERVER_URL}/auth/login/`, data);
  //   console.log(response);
  //   this.saveUserData(response);
  //   return response;
  //   // this.saveAccessToken(access);
  //   // this.saveRefreshToken(refresh);
  //   // return jwtDecode(access);
  // },
  async updateToken() {
    console.log("Aktualizacja tokenu");
    const {
      data: {
        access,
        refresh
      }
    } = await axios.post(`${SERVER_URL}/auth/token/refresh/`, {
      refresh: this.getRefreshToken()
    });
    this.saveAccessToken(access);
    this.saveRefreshToken(refresh);
    return {
      access,
      refresh
    };
  },
  logout() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    // localStorage.removeItem(USER_DATA);
    localStorage.removeItem('chosenStorageId');
    localStorage.removeItem('chosenLocationId');
    localStorage.removeItem('chosenDeviceId');
    localStorage.removeItem('chosenShipmentId');
    localStorage.removeItem('chosenRequestId');
    localStorage.removeItem('chosenCommissionId');
    localStorage.removeItem('chosenProductId');
    localStorage.removeItem('chosenUtilizationReportId');
    localStorage.removeItem("userData");
  }
};



export default authService;