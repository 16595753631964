/* eslint-disable react/prop-types */
import React from "react";
import { dateFormatView } from "../../../static/functions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setChosenUtilizationReportId } from "../../../slices/utilizationReportSlice";
import CompactDataDisplay from "../../CompactDataDisplay/CompactDataDisplay";
import DetailsButton from "../../DetailsButton/DetailsButton";

const UtilizationReportItemMobile = ({ orderNumber, utilizationReport }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, creationDate, createdBy } = utilizationReport;

  const navigateToReport = () => {
    dispatch(setChosenUtilizationReportId(id));
    navigate(`/utilizationReports/${id}`);
  };

  const data = [
    { title: "Data utworzenia:", value: dateFormatView(creationDate) },
    { title: "Utworzył:", value: `${createdBy.name} ${createdBy.surname}` },
  ];
  return (
    <CompactDataDisplay
      title={`Raport #${orderNumber}`}
      data={data}
      actions={
        <>
          <DetailsButton
            onClick={() => navigateToReport()}
            variant="contained"
            text="szczegóły"
          />
        </>
      }
    />
  );
};

export default UtilizationReportItemMobile;
