/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import TaskHistoryItemDesktop from "./TaskHistoryItemVersions/TaskHistoryItemDesktop";
import TaskHistoryItemMobile from "./TaskHistoryItemVersions/TaskHistoryItemMobile";

const TaskHistoryItem = ({ orderNumber, task }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <TaskHistoryItemDesktop orderNumber={orderNumber} task={task} />
      ) : (
        <TaskHistoryItemMobile task={task} />
      )}
    </>
  );
};

export default TaskHistoryItem;
