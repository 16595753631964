/* eslint-disable react/prop-types */
import React from "react";
import CompactDataDisplay from "../../../../CompactDataDisplay/CompactDataDisplay";
import EditButton from "../../../../EditButton/EditButton";
import { dateFormatView } from "../../../../../static/functions";

const TaskCommentsItemMobile = ({
  comment,
  setIsAddEditTaskCommentVisible,
}) => {
  const {
    id: taskCommentId,
    creationDate,
    author,
    message,
    lastModifiedBy,
    lastModificationDate,
  } = comment;

  const data = [
    { title: "Data utworzenia:", value: dateFormatView(creationDate) },
    { title: "Autor:", value: `${author.name} ${author.surname}` },
    { title: "Treść:", value: message },
    {
      title: "Ostatnio modyfikowane przez:",
      value: lastModifiedBy
        ? `${lastModifiedBy.name} ${lastModifiedBy.surname}`
        : "Brak",
    },
    {
      title: "Data modyfikacji:",
      value:
        lastModifiedBy && lastModificationDate
          ? dateFormatView(lastModificationDate)
          : "Brak",
    },
  ];

  return (
    <CompactDataDisplay
      title={`Komentarz #${taskCommentId}`}
      data={data}
      actions={
        <>
          <EditButton onClick={() => setIsAddEditTaskCommentVisible(true)} />
        </>
      }
    />
  );
};

export default TaskCommentsItemMobile;
