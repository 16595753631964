/* eslint-disable react/prop-types */
import React from "react";
import { Box, Chip, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomInnerChip = styled(Chip)(() => ({
  fontSize: "0.75rem",
  width: "100%",
}));

const CustomChip = ({ label, sx, width = "100%" }) => {
  return (
    <Box sx={{ width: width }}>
      <Tooltip title={label}>
        <CustomInnerChip label={label} sx={sx} />
      </Tooltip>
    </Box>
  );
};

export default CustomChip;
