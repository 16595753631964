import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const storageDataService = {
  setItem: (key, value) => cookies.set(key, value, {
    path: '/'
  }),
  getItem: (key) => cookies.get(key),
  removeItem: (key) => cookies.remove(key)
};