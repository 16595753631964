/* eslint-disable react/prop-types */
import React from "react";
import { Typography, Chip } from "@mui/material";
import {
  roles,
  userRolesChipStyles,
  userSourceChipStyles,
  userSources,
} from "../../../static/static";
import { Article, Settings } from "@mui/icons-material";
import { EditButton } from "../../FunctionalButtons/FunctionalButtons";
import PageHeader from "../../PageHeader/PageHeader";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
} from "../../ObjectDetailsElements/ObjectDetailsElements";

const UserDetails = ({
  chosenUser,
  setIsEditUserModalOpened,
  isNotificationConfigurationOpened,
  isPermissionConfigurationOpened,
}) => {
  return (
    <SectionWrapper>
      <PageHeader
        titleText="Dane"
        icon={<Article />}
        size="medium"
        onRightComponent={
          <EditButton
            onClick={() => setIsEditUserModalOpened(true)}
            disabled={
              isNotificationConfigurationOpened ||
              isPermissionConfigurationOpened
            }
            startIcon={<Settings />}
          />
        }
      />
      <InfoWrapper>
        <DataBox>
          <DataTitle>Login:</DataTitle>
          <Typography>{chosenUser.username}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Imię:</DataTitle>
          <Typography>{chosenUser.name}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Nazwisko:</DataTitle>
          <Typography>{chosenUser.surname}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Rola:</DataTitle>
          <Chip
            label={roles[chosenUser.role]}
            sx={userRolesChipStyles(chosenUser.role, "")}
          />
        </DataBox>
        <DataBox>
          <DataTitle>Email:</DataTitle>
          <Typography>
            {chosenUser.email === "" ? "Brak" : chosenUser.email}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Telefon:</DataTitle>
          <Typography>
            {chosenUser.phone === "" ? "Brak" : chosenUser.phone}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Źródło:</DataTitle>
          <Chip
            label={userSources[chosenUser.source]}
            sx={userSourceChipStyles[chosenUser.source]}
          />
        </DataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default UserDetails;
