/* eslint-disable react/prop-types */
import { RefreshOutlined } from "@mui/icons-material";
import { Fab, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQ from "../../hooks/useMediaQ";

const RefreshButton = ({ onClick, iconStyle = { fontSize: "1.5rem" } }) => {
  const { isMobileDisplay } = useMediaQ();
  const { breakpoints } = useTheme();
  return (
    <>
      {isMobileDisplay ? (
        <Tooltip title="Odśwież">
          <Fab size="small" sx={{ bgcolor: "#a62bc4", color: "white" }}>
            <RefreshOutlined />
          </Fab>
        </Tooltip>
      ) : (
        <IconButton onClick={() => onClick()}>
          <RefreshOutlined
            sx={{
              ...iconStyle,
              [breakpoints.down("laptop")]: {
                fontSize: "1.2rem",
              },
            }}
          />
        </IconButton>
      )}
    </>
  );
};

export default RefreshButton;
