/* eslint-disable react/prop-types */
import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Clear, Info } from "@mui/icons-material";
import EmptyListError from "../../../EmptyListError/EmptyListError";

const mapGroupNames = {
  general: "ogólne",
  service: "serwis",
  warehouse: "magazyn",
};

const TableWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  alignItems: "center",
  flexBasis: "38%",
}));

const TableTitleWrapper = styled(Typography)(() => ({
  fontSize: "1rem",
}));

const TableBorderWrapper = styled(Box)(() => ({
  border: "0.0625rem solid black",
  width: "100%",
}));

const PermissionWrapper = styled(Box)(() => ({
  minHeight: "2.5rem",
  border: "0.0625rem dotted black",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const PermissionContentWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  width: "92%",
  gap: "1rem",
}));

const ToChoosePermissions = ({
  transformedPermissions,
  permissions,
  currentChosenPermissions,
  selectedDefaultPermissions,
  setSelectedDefaultPermissions,
}) => {
  const isPermissionAlreadySelected = (permission) => {
    const isPermissionSelectedIndex = selectedDefaultPermissions.findIndex(
      (arrayPermission) => arrayPermission.id === permission.id
    );
    if (isPermissionSelectedIndex === -1) return false;
    return true;
  };

  const selectPermission = (permission) => {
    if (!isPermissionAlreadySelected(permission)) {
      setSelectedDefaultPermissions((state) => [...state, permission]);
    }
  };

  const unselectPermission = (permission) => {
    const index = [...selectedDefaultPermissions].findIndex(
      (item) => item.id == permission.id
    );
    const table = [...selectedDefaultPermissions];
    table.splice(index, 1);
    setSelectedDefaultPermissions(table);
  };

  const filteredTransformedPermissions = (id) => {
    return transformedPermissions[id].filter((permission) => {
      const index = currentChosenPermissions.findIndex(
        (currChosenPermission) => currChosenPermission.id === permission.id
      );
      if (index === -1) return true;
      return false;
    });
  };

  return (
    <TableWrapper>
      <TableTitleWrapper>Wszystkie</TableTitleWrapper>
      <TableBorderWrapper>
        {transformedPermissions.map((_, id) => (
          <Box key={id}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.5em",
                borderTop: id > 0 ? "0.125rem solid black" : "",
                borderBottom: "0.125rem solid black",
              }}
            >
              <Typography sx={{ fontWeight: 700 }}>Kategoria:</Typography>
              <Typography sx={{ fontWeight: 700 }}>
                {mapGroupNames[Object.keys(permissions)[id]]}
              </Typography>
            </Box>
            {filteredTransformedPermissions(id).length > 0 ? (
              filteredTransformedPermissions(id).map((permission, id) => {
                console.log("permissionAAA: ", permission);
                return (
                  <PermissionWrapper
                    key={id}
                    onClick={() => selectPermission(permission)}
                    sx={{
                      cursor: !isPermissionAlreadySelected(permission)
                        ? "pointer"
                        : "default",
                      bgcolor: isPermissionAlreadySelected(permission)
                        ? "#cae4eb"
                        : "white",
                    }}
                  >
                    <PermissionContentWrapper>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          fontStyle: "italic",
                          flexGrow: 2,
                          flexBasis: "30%",
                          marginLeft: "0.5rem",
                        }}
                      >
                        {`${permission.name}`}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.7rem",
                          wordWrap: "break-word",
                        }}
                      >
                        {permission.description}
                      </Typography>
                    </PermissionContentWrapper>

                    {selectedDefaultPermissions.includes(permission) && (
                      <IconButton
                        sx={{ zIndex: 2 }}
                        onClick={() => unselectPermission(permission)}
                      >
                        <Clear sx={{ fontSize: 16 }} />
                      </IconButton>
                    )}
                  </PermissionWrapper>
                );
              })
            ) : (
              <EmptyListError
                icon={<Info />}
                message="Brak uprawnień w danej kategorii"
              />
            )}
          </Box>
        ))}
      </TableBorderWrapper>
    </TableWrapper>
  );
};

export default ToChoosePermissions;
