/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { IconButton, MenuItem, Select, Typography } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../../static/static";

const BORDER_VISIBLE_VALUES = 2;

const ActualPageInfo = ({
  collectionSize,
  actualDisplayQuantity,
  actualMark,
}) => {
  return (
    <Typography sx={{ fontSize: "0.8em" }}>
      {Math.ceil(collectionSize / actualDisplayQuantity) !== 0
        ? `Strona ${actualMark + 1} z ${Math.ceil(
            collectionSize / actualDisplayQuantity
          )}`
        : `Strona ${actualMark} z ${Math.ceil(
            collectionSize / actualDisplayQuantity
          )}`}
    </Typography>
  );
};

const PagePaginateWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  justifySelf: "center",
  alignSelf: 'center'
}));


const PagePaginate = ({
  leftIndex,
  leftDoubleArrowClick,
  leftArrowClick,
  collectionSize,
  actualDisplayQuantity,
  actualMark,
  changeIndex,
  rightIndex,
  rightArrowClick,
  rightDoubleArrowClick,
}) => {
  return (
    <PagePaginateWrapper>
      <IconButton
        disabled={leftIndex == 0}
        onClick={() => leftDoubleArrowClick()}
      >
        <KeyboardDoubleArrowLeftIcon />
      </IconButton>
      <IconButton disabled={leftIndex == 0} onClick={() => leftArrowClick()}>
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.3em",
        }}
      >
        {Array.from(
          Array(Math.ceil(collectionSize / actualDisplayQuantity)).keys()
        )
          .filter(
            (_, index) =>
              actualMark - BORDER_VISIBLE_VALUES <= index &&
              index <= actualMark + BORDER_VISIBLE_VALUES
          )
          .map((value) => (
            <Typography
              key={value}
              variant="outlined"
              sx={{
                textAlign: "center",
                cursor: "pointer",
                color: actualMark === value ? "blue" : "black",
                fontWeight: 700,
              }}
              onClick={() => changeIndex(value)}
            >
              {value + 1}
            </Typography>
          ))}
      </Box>
      <IconButton
        disabled={rightIndex >= collectionSize}
        onClick={() => rightArrowClick()}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
      <IconButton
        disabled={rightIndex >= collectionSize}
        onClick={() => rightDoubleArrowClick()}
      >
        <KeyboardDoubleArrowRightIcon />
      </IconButton>
    </PagePaginateWrapper>
  );
};

const ItemPerPageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  gap: "0.5rem",
  [theme.breakpoints.down("laptop")]: {
    flexGrow: 1,
    alignContent: "flex-end",
  },
}));

const ItemPerPage = ({ actualDisplayQuantity, setActualDisplayQuantity }) => {

  return (
    <ItemPerPageWrapper>
      <Typography sx={{ fontSize: "0.8rem" }}>
        Liczba elementów na stronie:
      </Typography>
      <Select
        value={actualDisplayQuantity}
        onChange={(e) => setActualDisplayQuantity(e.target.value)}
        sx={{ fontSize: "0.8em" }}
        variant="standard"
      >
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={DEFAULT_DISPLAY_ITEMS_QUANTITY}>
          {DEFAULT_DISPLAY_ITEMS_QUANTITY}
        </MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={30}>30</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </ItemPerPageWrapper>
  );
};

const PaginationWrapper = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "2rem",
  width: "100%",
}));

const Pagination = ({
  children,
  collectionSize = 0,
  leftIndex,
  rightIndex,
  setLeftIndex,
  setRightIndex,
}) => {
  const [actualMark, setActualMark] = useState(0);
  const [actualDisplayQuantity, setActualDisplayQuantity] = useState(
    DEFAULT_DISPLAY_ITEMS_QUANTITY
  );

  useEffect(() => {
    setLeftIndex(0);
    setRightIndex(actualDisplayQuantity);
    setActualMark(0);
  }, [collectionSize, actualDisplayQuantity]);

  const rightArrowClick = () => {
    setActualMark((state) => state + 1);
    setLeftIndex((state) => state + actualDisplayQuantity);
    setRightIndex((state) => state + actualDisplayQuantity);
  };

  const leftArrowClick = () => {
    setActualMark((state) => state - 1);
    setLeftIndex((state) => state - actualDisplayQuantity);
    setRightIndex((state) => state - actualDisplayQuantity);
  };

  const rightDoubleArrowClick = () => {
    const divider = Math.ceil(collectionSize / actualDisplayQuantity);
    setActualMark(Math.ceil(collectionSize / actualDisplayQuantity) - 1);
    setLeftIndex((divider - 1) * actualDisplayQuantity);
    setRightIndex(divider * actualDisplayQuantity);
  };

  const leftDoubleArrowClick = () => {
    setActualMark(0);
    setLeftIndex(0);
    setRightIndex(actualDisplayQuantity);
  };

  const changeIndex = (index) => {
    setActualMark(index);
    setLeftIndex(index * actualDisplayQuantity);
    setRightIndex((index + 1) * actualDisplayQuantity);
  };

  return (
    <>
      {children}
      <PaginationWrapper>
        <ActualPageInfo
          collectionSize={collectionSize}
          actualDisplayQuantity={actualDisplayQuantity}
          actualMark={actualMark}
        />
        <PagePaginate
          leftIndex={leftIndex}
          leftDoubleArrowClick={leftDoubleArrowClick}
          leftArrowClick={leftArrowClick}
          collectionSize={collectionSize}
          actualDisplayQuantity={actualDisplayQuantity}
          actualMark={actualMark}
          changeIndex={changeIndex}
          rightIndex={rightIndex}
          rightArrowClick={rightArrowClick}
          rightDoubleArrowClick={rightDoubleArrowClick}
        />
        <ItemPerPage
          actualDisplayQuantity={actualDisplayQuantity}
          setActualDisplayQuantity={setActualDisplayQuantity}
        />
      </PaginationWrapper>
    </>
  );
};

export default Pagination;
