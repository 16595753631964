/* eslint-disable react/prop-types */
import React from "react";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";
import CustomChip from "../../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../../static/static";
import { DetailsButton } from "../../../FunctionalButtons/FunctionalButtons";
import { TextField } from "@mui/material";
import { ArrowDownward, CancelPresentation } from "@mui/icons-material";

const AvailableUnitsItemMobile = ({
  orderNumber,
  unit,
  currentQuantity,
  chosenQuantity,
  isAdded,
  changeAddQuantity,
  changeDOAQuantity,
  addItem,
  returnQuantity,
  addDOAItem,
  addButtonLock,
  DOAButtonLock,
  isDOAAdded,
}) => {

  const {
    product: { partNumber, name, producer, category },
    serialNumber,
    status,
  } = unit;

  const data = [
    { title: "PartNumber:", value: partNumber },
    { title: "Nazwa:", value: name },
    { title: "Kategoria:", value: producer ? producer.name : "" },
    { title: "Producent:", value: category ? category.name : "" },
    { title: "Nr seryjny:", value: serialNumber },
    {
      title: "Status:",
      value: (
        <CustomChip label={status} sx={unitStatusChipStyles(status, "")} />
      ),
    },
    {
      title: "Wybrana liczba:",
      value: (
        <TextField
          value={chosenQuantity}
          type="number"
          InputProps={{ inputProps: { min: 0, max: currentQuantity } }}
          onChange={(e) => changeAddQuantity(e.target.value)}
          disabled={isAdded}
          size="small"
          variant="standard"
        />
      ),
    },
    {
      title: "Liczba zwróconych:",
      value: (
        <TextField
          value={returnQuantity}
          type="number"
          InputProps={{ inputProps: { min: 0, max: currentQuantity } }}
          onChange={(e) => changeDOAQuantity(e.target.value)}
          disabled={isDOAAdded}
          size="small"
          variant="standard"
        />
      ),
    },
  ];
  return (
    <CompactDataDisplay
      title={`Jednostka #${orderNumber}`}
      data={data}
      actions={
        <>
          <DetailsButton
            disabled={addButtonLock || isAdded}
            onClick={() => addItem()}
            text="montuj"
            startIcon={<ArrowDownward />}
          />
          <DetailsButton
            disabled={DOAButtonLock || isDOAAdded}
            onClick={() => addDOAItem()}
            text="DOA"
            startIcon={<CancelPresentation />}
          />
        </>
      }
    />
  );
};
export default AvailableUnitsItemMobile;
