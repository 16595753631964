/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";

const CustomTitle = styled(Typography)(({theme}) => ({
  color: theme.palette.secondary.main
}));


const SortingHeader = ({ headerName, styleProps = {}, functionForArrowUp, functionForArrowDown, disabled=false }) => {
  
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CustomTitle sx={styleProps}>{headerName}</CustomTitle>
      {!disabled && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <IconButton
            onClick={() => functionForArrowUp()}
            sx={{ padding: 0 }}
            size="small"
          >
            <ArrowDropUp />
          </IconButton>
          <IconButton
            onClick={() => functionForArrowDown()}
            sx={{ padding: 0 }}
            size="small"
          >
            <ArrowDropDown />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default SortingHeader;