/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import React from "react";

const TableWrapper = ({ children }) => {
  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      {children}
    </Box>
  );
};

export default TableWrapper;
