/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from '../../../../TableGridWrapper/TableDataGridWrapper';
import { userPermissionsHeaders } from '../../../../../static/tablesHeaders';


const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));


const UserPermissionItemDesktop = ({ orderNumber, permission }) => {
  const { name, description } = permission;

  return (
    <TableDataGridWrapper headers={userPermissionsHeaders} gap="0.5rem">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <CustomDataDisplay>{description}</CustomDataDisplay>
    </TableDataGridWrapper>
  );
};

export default UserPermissionItemDesktop;