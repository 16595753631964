/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { Box, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { Code, KeyboardArrowLeft, Add } from "@mui/icons-material";
import {
  clearCommissionCodes,
  getCommissionCloseCodes,
  getCommissionOpenCodes,
} from "../../../slices/commissionSlice";
import { useSelector } from "react-redux";
import CommissionCodeForm from "../CommissionCodeForm/CommissionCodeForm";
import CommissionCodeItem from "../CommissionCodeItem/CommissionCodeItem";
import ChoosingButton from "../../ChoosingButton/ChoosingButton";
import PageHeader from "../../PageHeader/PageHeader";
import RefreshButton from "../../RefreshButton/RefreshButton";
import BlankSpace from "../../BlankSpace/BlankSpace";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import { useNavigate } from "react-router-dom";
import EmptyListError from "../../EmptyListError/EmptyListError";
import TableHeader from "../../TableHeader/TableHeader";
import { commissionCodesListHeaders } from "../../../static/tablesHeaders";
import PageWrapper from "../../PageWrapper/PageWrapper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import useMediaQ from "../../../hooks/useMediaQ";

const BoxWrapper = styled(Box)(() => ({
  display: "flex",
  height: "3rem",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  marginBottom: "1rem",
}));

const CommissionCodeConfigurator = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isPhoneDisplay, isTabletDisplay } = useMediaQ();
  const { commissionCloseCodes, commissionOpenCodes } = useSelector(
    (slice) => slice.commission
  );
  const [refresh, setRefresh] = useState(false);
  const [isAddCommissionProblemCodeOpen, setIsAddCommissionProblemCodeOpen] =
    useState(false);
  const [isAddCommissionFinishCodeOpen, setIsAddCommissionFinishCodeOpen] =
    useState(false);
  const [areOpenCodesDisplayed, setAreOpenCodesDisplayed] = useState(true);
  const [areCloseCodesDisplayed, setAreCloseCodesDisplayed] = useState(false);

  const getData = useCallback(async () => {
    if (areOpenCodesDisplayed) await dispatch(getCommissionOpenCodes());
    if (areCloseCodesDisplayed) await dispatch(getCommissionCloseCodes());
  }, [refresh, isAddCommissionFinishCodeOpen]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => { 
    return () => dispatch(clearCommissionCodes());
  }, []);

  return (
    <PageWrapper
      width={isPhoneDisplay ? "90%" : isTabletDisplay ? "80%" : "60%"}
    >
      <BoxWrapper>
        <ChoosingButton
          name="Kody otwarcia"
          onClick={() => {
            setAreCloseCodesDisplayed(false);
            setAreOpenCodesDisplayed(true);
            setRefresh();
          }}
        />
        <ChoosingButton
          name="Kody zamknięcia"
          onClick={() => {
            setAreCloseCodesDisplayed(true);
            setAreOpenCodesDisplayed(false);
            setRefresh();
          }}
        />
      </BoxWrapper>
      <PageHeader
        titleText={`Kody zleceń - ${
          areOpenCodesDisplayed ? "kody otwarcia" : "kody zamknięcia"
        }`}
        icon={<Code />}
        onRightComponent={
          <Box sx={{ display: "flex", gap: "1rem" }}>
            {areOpenCodesDisplayed && (
              <DetailsButton
                text="Dodaj kod otwarcia"
                startIcon={<Add />}
                onClick={() => setIsAddCommissionProblemCodeOpen(true)}
              />
            )}
            {areCloseCodesDisplayed && (
              <DetailsButton
                text="Dodaj kod zamknięcia"
                startIcon={<Add />}
                onClick={() => setIsAddCommissionFinishCodeOpen(true)}
              />
            )}
            <DetailsButton
              text="Powrót"
              startIcon={<KeyboardArrowLeft />}
              onClick={() => navigate("/commissions")}
            />
          </Box>
        }
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <BlankSpace />
      <SectionWrapper>
        <TableHeader
          headers={commissionCodesListHeaders}
          setSorter={() => {}}
          setSorting={() => {}}
          styles={{ height: "3rem" }}
          gap="0.625rem"
        />
        <>
          {areOpenCodesDisplayed ? (
            commissionOpenCodes !== null && commissionOpenCodes.length > 0 ? (
              <>
                {commissionOpenCodes.map((code, id) => (
                  <CommissionCodeItem
                    codeData={code}
                    orderNumber={id + 1}
                    key={id}
                    setRefresh={() => setRefresh((state) => !state)}
                    codeType="problem"
                  />
                ))}
              </>
            ) : (
              <EmptyListError message="Brak kodów otwarcia zleceń" />
            )
          ) : (
            <></>
          )}
        </>
        <>
          {areCloseCodesDisplayed ? (
            commissionCloseCodes !== null && commissionCloseCodes.length > 0 ? (
              <>
                {commissionCloseCodes.map((code, id) => (
                  <CommissionCodeItem
                    codeData={code}
                    orderNumber={id + 1}
                    key={id}
                    setRefresh={() => setRefresh((state) => !state)}
                    codeType="finish"
                  />
                ))}
              </>
            ) : (
              <EmptyListError message="Brak kodów zamknięć zleceń" />
            )
          ) : (
            <></>
          )}
        </>
      </SectionWrapper>
      <Dialog
        open={isAddCommissionProblemCodeOpen}
        onClose={() => setIsAddCommissionProblemCodeOpen(false)}
      >
        <CommissionCodeForm
          type="open"
          closeModal={() => setIsAddCommissionProblemCodeOpen(false)}
          setRefresh={() => setRefresh((state) => !state)}
        />
      </Dialog>
      <Dialog
        open={isAddCommissionFinishCodeOpen}
        onClose={() => setIsAddCommissionFinishCodeOpen(false)}
      >
        <CommissionCodeForm
          type="close"
          closeModal={() => setIsAddCommissionFinishCodeOpen(false)}
          setRefresh={() => setRefresh((state) => !state)}
        />
      </Dialog>
    </PageWrapper>
  );
};

export default CommissionCodeConfigurator;
