/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from "react";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
} from "../../ObjectDetailsElements/ObjectDetailsElements";
import { Typography } from "@mui/material";
import DocumentationsView from "../../Commission/DocumentationsView/DocumentationsView/DocumentationsView";
import { useTheme } from "@mui/material/styles";
import { onlyDateFormatView } from "../../../static/functions";
import UnitMigrationWorkTask from "./UnitMigrationWorkTask";

const CustomDataBox = ({ children }) => {
  const { breakpoints } = useTheme();
  return (
    <DataBox
      sx={{
        flexBasis: "100%",
        [breakpoints.down("desktop")]: {
          flexBasis: "100%",
        },
      }}
    >
      {children}
    </DataBox>
  );
};

const WorkTaskShortcut = ({ task }) => {
  const {
    id,
    executor,
    executionDate,
    description,
    documentation,
    mountedUnits,
    unmountedUnits,
  } = task;

  return (
    <InfoWrapper>
      <CustomDataBox>
        <DataTitle>Wykonawca:</DataTitle>
        <Typography>{`${executor.name} ${executor.surname}`}</Typography>
      </CustomDataBox>
      <CustomDataBox>
        <DataTitle>Data wykonania:</DataTitle>
        <Typography>{onlyDateFormatView(executionDate)}</Typography>
      </CustomDataBox>
      <CustomDataBox>
        <DataTitle>Opis działań:</DataTitle>
        <Typography
          sx={{
            textAlign: "justify",
            textJustify: "inter-word",
          }}
        >
          {description}
        </Typography>
      </CustomDataBox>
      <UnitMigrationWorkTask
        mountedUnits={mountedUnits}
        unmountedUnits={unmountedUnits}
      />
      <DocumentationsView
        documentation={documentation}
        taskId={id}
        sx={{ width: "100%" }}
      />
    </InfoWrapper>
  );
};

export default WorkTaskShortcut;
