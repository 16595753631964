/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addTaskComment,
  getTaskComment,
  updateTaskComment,
} from "../../../slices/taskSlice";
import { Add, Clear, Edit } from "@mui/icons-material";
import { TaskCommentModalClasses } from "./TaskCommentModal.style";
import PageHeader from "../../PageHeader/PageHeader";
import DetailsButton from "../../DetailsButton/DetailsButton";
import EditButton from "../../EditButton/EditButton";

const TaskCommentModal = ({
  type = "add",
  taskId = "",
  closeModal,
  taskCommentId = "",
  setRefresh = () => {},
}) => {
  const dispatch = useDispatch();
  const { userData: authUserData } = useSelector((slice) => slice.auth);
  const { chosenTaskComment } = useSelector((slice) => slice.task);
  const [data, setData] = useState({ message: "" });

  const getData = useCallback(async () => {
    await dispatch(getTaskComment(taskCommentId));
  }, [taskCommentId]);

  const sendData = async () => {
    const modifiedData =
      type === "add"
        ? {
            ...data,
            userId: authUserData.user_id,
            taskId,
          }
        : { editedMessage: data.message, modifier: authUserData.user_id };
    if (type === "add") await dispatch(addTaskComment(modifiedData));
    else if (type === "edit")
      await dispatch(updateTaskComment({ data: modifiedData, taskCommentId }));
    setRefresh((state) => !state);
    closeModal();
  };

  console.log("chosenTaskComment: ", chosenTaskComment);

  useEffect(() => {
    if (type === "edit") {
      getData();
    }
  }, [getData]);

  useEffect(() => {
    if (type === "edit" && chosenTaskComment !== null) {
      setData((state) => {
        return { ...state, message: chosenTaskComment.message };
      });
    }
  }, [chosenTaskComment]);

  return (
    <Box sx={TaskCommentModalClasses.wrapper}>
      <PageHeader
        titleText={
          type === "add" ? "Dodawanie komentarza" : "Edycja komentarza"
        }
        icon={type === "add" ? <Add /> : <Edit />}
        size="medium"
        onRightComponent={<></>}
      />
      <TextField
        value={data.message}
        onChange={(e) =>
          setData((state) => {
            return { ...state, message: e.target.value };
          })
        }
        label="Treść"
        multiline
        minRows={4}
        sx={TaskCommentModalClasses.textFieldProps}
      />
      <Box sx={TaskCommentModalClasses.buttonsWrapper}>
        {type === "add" && (
          <DetailsButton
            text="Dodaj"
            startIcon={<Add />}
            onClick={() => sendData()}
            disabled={data.message === ""}
          />
        )}
        {type === "edit" && (
          <EditButton
            onClick={() => sendData()}
            disabled={data.message === ""}
          />
        )}

        <EditButton
          onClick={() => closeModal()}
          disabled={data.message === ""}
          startIcon={<Clear />}
          text="Anuluj"
        />
      </Box>
    </Box>
  );
};

export default TaskCommentModal;
