/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { unitListHeaders } from "../../../../static/tablesHeaders";
import CustomChip from "../../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../../static/static";
import EditUnit from "../../../UnitForms/EditUnitForm/EditUnit";
import DeleteUnit from "../../../UnitForms/DeleteUnitForm/DeleteUnit";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
  flexWrap: "wrap",
}));

const UnitItemDesktop = ({ orderNumber, unit, setRefresh }) => {
  const {
    id,
    product: { partNumber, name, category, producer },
    serialNumber,
    status,
    quantity,
  } = unit;

  return (
    <TableDataGridWrapper headers={unitListHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{partNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <CustomDataDisplay>{serialNumber}</CustomDataDisplay>
      <CustomDataDisplay>{category ? category.name : ""}</CustomDataDisplay>
      <CustomDataDisplay>{producer ? producer.name : ""}</CustomDataDisplay>
      <CustomChip
        label={status}
        sx={unitStatusChipStyles(status, "")}
        width="60%"
      />
      <CustomDataDisplay>{quantity}</CustomDataDisplay>
      <ButtonWrapper>
        <EditUnit unitId={id} setRefresh={setRefresh} />
        <DeleteUnit unitId={id} setRefresh={setRefresh} />
      </ButtonWrapper>
    </TableDataGridWrapper>
  );
};

export default UnitItemDesktop;
