/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { deletePin, getPin } from "../../../slices/companySlice";
import { Add, CallMerge, Warning } from "@mui/icons-material";
import PageHeader from "../../PageHeader/PageHeader";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import EmptyListError from "../../EmptyListError/EmptyListError";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const PinWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
}));

const CompanyIntegrations = ({
  companyId,
  refresh,
  setRefresh,
  openGeneratePinModal,
}) => {
  const dispatch = useDispatch();
  const { generatedPin } = useSelector((slice) => slice.company);
  const [timeLeft, setTimeLeft] = useState(
    generatedPin !== null
      ? Math.floor(
          (Date.parse(generatedPin.pinExpirationDate) - Date.now()) / 1000
        )
      : 0
  );

  const getPinData = useCallback(async () => {
    await dispatch(getPin(companyId));
  }, []);

  useEffect(() => {
    getPinData();
  }, []);

  useEffect(() => {
    let interval = null;
    let timeout = null;
    if (generatedPin !== null) {
      setTimeLeft(
        Math.floor(
          (Date.parse(generatedPin.pinExpirationDate) - Date.now()) / 1000
        )
      );
      interval = setInterval(() => {
        setTimeLeft((state) => state - 1);
      }, 1000);
      timeout = setTimeout(async () => {
        await dispatch(deletePin(companyId));
      }, [
        Math.floor(Date.parse(generatedPin.pinExpirationDate) - Date.now()) > 0
          ? Math.floor(Date.parse(generatedPin.pinExpirationDate) - Date.now())
          : 0.1,
      ]);
    }
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [generatedPin, refresh]);

  return (
    <SectionWrapper>
      <PageHeader
        titleText="Integracje"
        icon={<CallMerge />}
        size="medium"
        onRightComponent={
          <DetailsButton
            text="Dodaj integrację"
            onClick={() => openGeneratePinModal()}
            startIcon={<Add />}
          />
        }
      >
        <Typography
          sx={{
            fontSize: "1.4rem",
            bgcolor: "#ddd",
          }}
        >
          {0}
        </Typography>
        {(generatedPin || timeLeft > 0) && (
          <PinWrapper>
            <Typography>Wygenerowany pin:</Typography>
            <Typography sx={{ fontWeight: 700 }}>
              {`${generatedPin.pin}`}
            </Typography>
            <Typography>Wygasa za </Typography>
            <Typography>{`${timeLeft} sekund`}</Typography>
          </PinWrapper>
        )}
      </PageHeader>
      <EmptyListError message="Brak integracji" icon={<Warning />} />
    </SectionWrapper>
  );
};

export default CompanyIntegrations;
