/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import MoveUnitField from "../MoveUnitField/MoveUnitField";
import PageHeader from "../../PageHeader/PageHeader";
import { Download, Warning } from "@mui/icons-material";
import TableHeader from "../../TableHeader/TableHeader";
import { moveUnitsAddedUnitsHeaders } from "../../../static/tablesHeaders";
import EmptyListError from "../../EmptyListError/EmptyListError";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import TableWrapper from "../../TableWrapper/TableWrapper";

const CustomWrapper = styled(Box)(() => ({
  maxHeight: "18.75rem",
  overflowY: "auto",
}));

const AddedUnits = ({ formValues, removeItem, titleText="Aktualny stan paczki" }) => {
  return (
    <SectionWrapper>
      <PageHeader icon={<Download />} titleText={titleText} size="medium" />
      <TableWrapper>
        <TableHeader
          headers={moveUnitsAddedUnitsHeaders}
          setSorter={() => {}}
          setSorting={() => {}}
          gap="1%"
        />
        {formValues.length > 0 ? (
          <CustomWrapper>
            {formValues.map((unit, index) => (
              <MoveUnitField
                unit={unit}
                key={index}
                index={index}
                removeItem={removeItem}
                orderNumber={index + 1}
              />
            ))}
          </CustomWrapper>
        ) : (
          <EmptyListError
            message="Brak dodanych jednostek"
            icon={<Warning />}
          />
        )}
      </TableWrapper>
    </SectionWrapper>
  );
};

export default AddedUnits;
