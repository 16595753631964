/* eslint-disable react/prop-types */
import React from 'react';
import CompactDataDisplay from '../../../CompactDataDisplay/CompactDataDisplay';
import { TextField } from '@mui/material';
import DeleteButton from '../../../DeleteButton/DeleteButton';

const AddUnitFieldMobile = ({
  unit,
  orderNumber,
  serialNumber,
  formError,
  onSerialNumberChange,
  quantity,
  onQuantityChange,
  removeItem,
}) => {
  const { partNumber, name, quantityLock, type } = unit;
  
  const data = [
    { title: "PartNumber:", value: partNumber },
    { title: "Nazwa:", value: name },
    {
      title: "Nr seryjny:",
      value: (
        <TextField
          value={serialNumber}
          disabled={type === "Numerical"}
          error={formError && type === "Quantitative"}
          onChange={(e) => onSerialNumberChange(e.target.value)}
          variant="standard"
          size="small"
        />
      ),
    },
    {
      title: "Liczba sztuk:",
      value: (
        <TextField
          value={quantity}
          type="number"
          disabled={quantityLock || type === "Quantitative"}
          InputProps={{
            inputProps: { min: 1, style: { textAlign: "center" } },
          }}
          onChange={(e) => onQuantityChange(e.target.value)}
          variant="standard"
          size="small"
        />
      ),
    },
  ];
  return (
    <CompactDataDisplay
      title={`Produkt #${orderNumber}`}
      data={data}
      actions={
        <>
          <DeleteButton onClick={() => removeItem()} />
        </>
      }
    />
  );
};

export default AddUnitFieldMobile;