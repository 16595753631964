/* eslint-disable react/prop-types */
import React from "react";
import { Autorenew } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CompactDataDisplay from "../../../../CompactDataDisplay/CompactDataDisplay";
import DetailsButton from "../../../../DetailsButton/DetailsButton";
import GeoLocationIcon from "../../../../GeoLocation/GeoLocationIcon";

const LocationItemMobile = ({ location }) => {
  const navigate = useNavigate();
  const {
    code,
    name,
    address: { linkedVersion },
    devicesCount,
    isSynchronized,
  } = location;

  const navigateToLocation = () => {
    navigate(`/locations/${code}`);
  };

  const data = [
    { title: "Nazwa:", value: name },
    { title: "Adres:", value: linkedVersion },
    { title: "Na mapie:", value: <GeoLocationIcon query={linkedVersion}/> },
    { title: "Liczba urządzeń:", value: devicesCount },
    {
      title: "Czy zsynchronizowane?:",
      value: isSynchronized ? (
        <Autorenew
          sx={{
            color: "darkgreen",
          }}
        />
      ) : (
        <Autorenew
          sx={{
            color: "red",
          }}
        />
      ),
    },
  ];
  return (
    <CompactDataDisplay
      title={`Lokalizacja #${code}`}
      data={data}
      actions={
        <>
          <DetailsButton onClick={() => navigateToLocation()} />
        </>
      }
    />
  );
};

export default LocationItemMobile;
