/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  KeyboardDoubleArrowLeft,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowRight,
  Clear,
  Check,
  Settings,
  DoNotTouch,
} from "@mui/icons-material";
import {
  clearPermissions,
  getPermissionsByGroup,
  updateUserPermissions,
} from "../../../slices/permissionSlice";
import PageHeader from "../../PageHeader/PageHeader";
import { EditButton } from "../../FunctionalButtons/FunctionalButtons";
import EmptyListError from "../../EmptyListError/EmptyListError";
import AcceptButton from "../../AcceptButton/AcceptButton";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import ToChoosePermissions from "./ToChoosePermissions/ToChoosePermissions";
import ChosenPermissions from "./ChosenPermissions/ChosenPermissions";
import MidButtonSection from "./MidButtonSection/MidButtonSection";
import UserPermissionsView from "./UserPermissionsView/UserPermissionsView";

const CustomWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  marginBottom: "2rem",
}));

const CustomInnerWrapper = styled(Box)(() => ({
  display: "flex",
  marginTop: "1.5rem",
  justifyContent: "center",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  gap: "1rem",
  marginTop: "1rem",
}));

const UserPermissionsPanel = ({
  userId,
  userPermissions,
  setRefresh,
  refresh,
  isPermissionConfigurationOpened,
  setIsPermissionConfigurationOpened,
  isNotificationConfigurationOpened,
}) => {
  const dispatch = useDispatch();
  const [selectedDefaultPermissions, setSelectedDefaultPermissions] = useState(
    []
  );
  const [selectedUserPermissions, setSelectedUserPermissions] = useState([]);
  const [currentChosenPermissions, setCurrentChosenPermissions] =
    useState(userPermissions);
  const { permissions } = useSelector((slice) => slice.permission);
  const [isDataLoading, setIsDataLoading] = useState(false);

  let transformedPermissions = [];
  if (permissions !== null) {
    Object.keys(permissions).map((key) =>
      transformedPermissions.push(permissions[key])
    );
  }

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getPermissionsByGroup());
    setIsDataLoading(false);
  }, [refresh]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    () => dispatch(clearPermissions());
  }, []);

  useEffect(() => {
    setCurrentChosenPermissions(userPermissions);
  }, [isPermissionConfigurationOpened]);

  const changePermissions = async () => {
    let data = [];
    currentChosenPermissions.map((permission) => data.push(permission.id));
    data = { permissions: data };
    await dispatch(updateUserPermissions({ data, userId }));
    setRefresh();
    setIsPermissionConfigurationOpened(false);
  };

  let availablePermissionsSize = 0;
  transformedPermissions.map(
    (_, id) => (availablePermissionsSize += transformedPermissions[id].length)
  );

  return (
    <SectionWrapper>
      <PageHeader
        titleText="Uprawnienia"
        icon={<DoNotTouch />}
        size="medium"
        onRightComponent={
          <EditButton
            disabled={
              isNotificationConfigurationOpened ||
              isPermissionConfigurationOpened
            }
            onClick={() => setIsPermissionConfigurationOpened(true)}
            startIcon={<Settings />}
          />
        }
      />
      <>
        {!isPermissionConfigurationOpened ? (
          <Box>
            {userPermissions.length > 0 ? (
              <UserPermissionsView userPermissions={userPermissions} />
            ) : (
              <EmptyListError message="Użytkownik nie posiada dodatkowych uprawnień" />
            )}
          </Box>
        ) : (
          <Box>
            {transformedPermissions.length > 0 ? (
              <CustomWrapper>
                <CustomInnerWrapper>
                  <ToChoosePermissions
                    transformedPermissions={transformedPermissions}
                    permissions={permissions}
                    currentChosenPermissions={currentChosenPermissions}
                    selectedDefaultPermissions={selectedDefaultPermissions}
                    selectedUserPermissions={selectedUserPermissions}
                    setSelectedDefaultPermissions={
                      setSelectedDefaultPermissions
                    }
                  />
                  <MidButtonSection
                    currentChosenPermissions={currentChosenPermissions}
                    selectedUserPermissions={selectedUserPermissions}
                    selectedDefaultPermissions={selectedDefaultPermissions}
                    availablePermissionsSize={availablePermissionsSize}
                    setCurrentChosenPermissions={setCurrentChosenPermissions}
                    setSelectedDefaultPermissions={
                      setSelectedDefaultPermissions
                    }
                    transformedPermissions={transformedPermissions}
                    setSelectedUserPermissions={setSelectedUserPermissions}
                  />
                  <ChosenPermissions
                    currentChosenPermissions={currentChosenPermissions}
                    selectedUserPermissions={selectedUserPermissions}
                    setSelectedUserPermissions={setSelectedUserPermissions}
                  />
                </CustomInnerWrapper>
                <ButtonWrapper>
                  <AcceptButton
                    onClick={() => changePermissions()}
                    startIcon={<Check />}
                    text="Zmień"
                  />
                  <EditButton
                    onClick={() => setIsPermissionConfigurationOpened(false)}
                    startIcon={<Clear />}
                    text="Anuluj"
                  />
                </ButtonWrapper>
              </CustomWrapper>
            ) : (
              <EmptyListError message="Brak dostępnych pozwoleń" />
            )}
          </Box>
        )}
      </>
    </SectionWrapper>
  );
};

export default UserPermissionsPanel;
