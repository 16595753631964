/* eslint-disable react/prop-types */
import { FmdBad } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

const WarningButton = ({
  onClick,
  startIcon = <FmdBad />,
  text = "przyjmij częściowo",
  styles: stylesProps,
  disabled = false,
}) => {
  return (
    <Button
      onClick={() => onClick()}
      variant="contained"
      sx={{
        width: "auto",
        bgcolor: "#ccab50",
        "&:hover": {
          background: "#968145",
        },
        fontSize: "0.75rem",
        height: "1.8125rem",
        ...stylesProps,
      }}
      size="small"
      startIcon={startIcon}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default WarningButton;
