/* eslint-disable react/prop-types */
import React from "react";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import PageHeader from "../../PageHeader/PageHeader";
import { Airplay, Autorenew } from "@mui/icons-material";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
  SpecialDataBox,
} from "../../ObjectDetailsElements/ObjectDetailsElements";
import { Typography } from "@mui/material";

const DeviceDetails = ({ device }) => {
  const { name, producer, serialNumber, type, description, isSynchronized } =
    device;

  return (
    <SectionWrapper>
      <PageHeader
        icon={<Airplay />}
        titleText="Dane urządzenia"
        size="medium"
      />
      <InfoWrapper>
        <DataBox>
          <DataTitle>Nazwa:</DataTitle>
          <Typography>{name}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Producent:</DataTitle>
          <Typography>{producer ? producer.name : "Brak"}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Nr seryjny:</DataTitle>
          <Typography>{serialNumber ? serialNumber : "Brak"}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Typ:</DataTitle>
          <Typography>{type ? type : "Brak"}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Czy zsynchronizowane:</DataTitle>
          {isSynchronized ? (
            <Autorenew sx={{ color: "darkgreen" }} />
          ) : (
            <Autorenew
              sx={{
                color: "red",
              }}
            />
          )}
        </DataBox>
        <SpecialDataBox>
          <DataTitle>Opis:</DataTitle>
          <Typography>{description ? description : "Brak"}</Typography>
        </SpecialDataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default DeviceDetails;
