/* eslint-disable react/prop-types */
import React from "react";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import PageHeader from "../../PageHeader/PageHeader";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import UnitItem from "./UnitItem";

const UnitMigrationWorkTask = ({ mountedUnits, unmountedUnits }) => {
  return (
    <>
      <SectionWrapper>
        <PageHeader
          titleText="Zamontowane produkty"
          icon={<ArrowDownward />}
          size="medium"
        />
        {mountedUnits.map((unit, index) => (
          <UnitItem orderNumber={index + 1} unit={unit} key={unit.id} />
        ))}
      </SectionWrapper>
      <SectionWrapper>
        <PageHeader
          titleText="Zdemontowane produkty"
          icon={<ArrowUpward />}
          size="medium"
        />
        {unmountedUnits.map((unit, index) => (
          <UnitItem orderNumber={index + 1} unit={unit} key={unit.id} />
        ))}
      </SectionWrapper>
    </>
  );
};

export default UnitMigrationWorkTask;
