/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";

const ButtonWrapper = ({ children }) => {
  return (
    <Box sx={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
      {children}
    </Box>
  );
};

export default ButtonWrapper;
