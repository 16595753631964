/* eslint-disable react/prop-types */
import React from "react";
import CompactDataDisplay from "../../CompactDataDisplay/CompactDataDisplay";
import DetailsButton from "../../DetailsButton/DetailsButton";
import { storageReportOperationTypeIcons } from "../../../static/static";

const StorageReportItemMobile = ({ storageReport, storageReportDetails }) => {
  const {
    id,
    creationDate,
    operationType,
    user,
    baseStorage,
    destinationStorage,
    shipment,
    utilizationReport,
    task,
  } = storageReport;

  const data = [
    { title: "Data utworzenia:", value: creationDate },
    {
      title: "Typ operacji:",
      value: storageReportOperationTypeIcons[operationType],
    },
    { title: "Użytkownik", value: user ? `${user.name} ${user.surname}` : "" },
    {
      title: "Magazyn bazowy",
      value: baseStorage ? `${baseStorage.name}` : "",
    },
    {
      title: "Przesyłka",
      value: shipment ? `${shipment.id}` : "",
    },
    {
      title: "Magazyn docelowy",
      value: destinationStorage ? `${destinationStorage.name}` : "",
    },
    {
      title: "Raport utylizacyjny",
      value: utilizationReport ? `${utilizationReport.id}` : "",
    },
    {
      title: "Zadanie",
      value: task ? `${task.id}` : "",
    },
  ];

  return (
    <CompactDataDisplay
      title={`Raport magazynowy #${id}`}
      data={data}
      actions={
        <DetailsButton
          onClick={() => storageReportDetails()}
          text="szczegóły"
        />
      }
    />
  );
};

export default StorageReportItemMobile;
