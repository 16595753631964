import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API, errorMessages
} from '../static/static';
import {
  getErrorCode
} from '../static/functions';
import authService from '../auth/authService';


export const getAllStorages = createAsyncThunk('storages/get/all', async () => {
  const resData = await dataService.get(`${API}storages`);
  return resData;
});

export const getLocalStorages = createAsyncThunk('storages/get/local', async () => {
  const resData = await dataService.get(`${API}storages/local/`);
  return resData;
});

export const getMainStorage = createAsyncThunk('storage/get/main', async () => {
  const resData = await dataService.get(`${API}main-storage`);
  return resData;
});

export const getPaginatedStorages = createAsyncThunk('storage/get/paginate', async (data) => {
  let {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    storagesType,
    user
  } = data;
  if (storagesType === undefined)
    storagesType = "";
  if (user === undefined)
    user = "";
  if (leftIndex === undefined)
    leftIndex = "";
  if (rightIndex === undefined)
    rightIndex = "";
  if (sortingParam === undefined)
    sortingParam = "name";
  if (sortingOrder === undefined)
    sortingOrder = "ascending";
  if (searchingValue === undefined || searchingValue === null)
    searchingValue = "";
  const resData = await dataService.get(`${API}storages/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&user=${user}&storagesType=${storagesType}`);
  return resData;
});

export const getStoragesCount = createAsyncThunk('storage/get/count', async (data) => {
  const {
    searchingValue,
    storagesType,
    user
  } = data;
  const resData = await dataService.get(`${API}storages/count/?searchingPhrase=${searchingValue}&user=${user}&storagesType=${storagesType}`);
  return resData;
});

export const getStorage = createAsyncThunk('storage/get', async (storageId, {
  getState
}) => {
  const {
    chosenStorageId
  } = getState().storage;
  const resData = storageId === undefined ? chosenStorageId ? await dataService.get(`${API}storage/${chosenStorageId}`) : await dataService.get(`${API}storage/${localStorage.getItem('chosenStorageId')}`) : await dataService.get(`${API}storage/${storageId}`);
  return resData;
});

export const getUserStorages = createAsyncThunk('storages/get/user', async (id) => {
  const resData = await dataService.get(`${API}user-storages/${id}`);
  return resData;
});

export const createStorage = createAsyncThunk('storage/create', async (data) => {
  const resData = await dataService.post(`${API}storage/`, data);
  return resData;
});

export const getFilteredStorages = createAsyncThunk('storages/get/filtered', async (searchingValue) => {
  const {
    user_id: userId
  } = authService.getDecodedAccessToken();
  const resData = await dataService.get(`${API}storages/filter/?searchingPhrase=${searchingValue}&user=${userId}`);
  return resData;
});

export const updateStorage = createAsyncThunk('storage/update', async (data, {
  getState
}) => {
  const {
    chosenStorageId
  } = getState().storage;
  const resData = chosenStorageId ? await dataService.put(`${API}storage/u/${chosenStorageId}/`, data) : await dataService.put(`${API}storage/u/${localStorage.getItem('chosenStorageId')}/`, data);
  return resData;
});

export const deleteStorage = createAsyncThunk('storage/delete', async (id) => {
  const resData = await dataService.delete(`${API}storage/d/${id}`);
  return resData;
});

const initialState = {
  storages: null,
  localStoragesCount: 0,
  chosenStorage: null,
  chosenStorageId: null,
  storageError: null,
  storageInfo: null,
};

export const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    clearStorage: (state) => {
      state.chosenStorage = null;
      state.chosenStorageId = null;
    },
    setStorageId: (state, action) => {
      if (action.payload !== null)
        localStorage.setItem('chosenStorageId', action.payload);
      state.chosenStorageId = action.payload;
      state.chosenStorage = null;
    },
    clearStoragesData: (state) => {
      state.storages = null;
      state.localStoragesCount = 0;
    },
    clearStorageErrors: (state) => {
      state.storageError = null;
    },
    clearStorageInfo: (state) => {
      state.storageInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedStorages.fulfilled, (state, action) => {
        state.storages = action.payload.data;
      })
      // .addCase(getPaginatedLocalStorages.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getStoragesCount.fulfilled, (state, action) => {
        state.localStoragesCount = action.payload.data;
      })
      // .addCase(getLocalStoragesCount.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getAllStorages.fulfilled, (state, action) => {
        state.storages = action.payload.data;
      })
      // .addCase(getAllStorages.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getLocalStorages.fulfilled, (state, action) => {
        state.storages = action.payload.data;
      })
      // .addCase(getLocalStorages.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getMainStorage.fulfilled, (state, action) => {
        const newStorage = action.payload.data;
        state.chosenStorage = newStorage;
        state.chosenStorageId = newStorage.id;
        localStorage.setItem('chosenStorageId', newStorage.id);
      })
      .addCase(getMainStorage.rejected, (state, action) => {
        const code = getErrorCode(action.error.message);
        if (code == 400)
          state.storageError = `brak magazynu głównego`;
      })
      .addCase(getStorage.fulfilled, (state, action) => {
        state.chosenStorage = action.payload.data;
        state.chosenStorageId = action.payload.data.id;
      })
      .addCase(getStorage.rejected, (state, action) => {
        const code = getErrorCode(action.error.message);
        if (code == 400)
          state.storageError = `błędny id magazynu`;
      })
      .addCase(getUserStorages.fulfilled, (state, action) => {
        state.storages = action.payload.data;
      })
      // .addCase(getUserStorages.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getFilteredStorages.fulfilled, (state, action) => {
        state.storages = action.payload.data;
      })
      .addCase(createStorage.fulfilled, (state, action) => {
        const newStorage = action.payload.data;
        state.chosenStorage = newStorage;
        state.chosenStorageId = newStorage.id;
        state.storageInfo = "Pomyślnie dodano nowy magazyn";
      })
      // .addCase(createStorage.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(deleteStorage.fulfilled, (state) => {
        state.storageInfo = "Pomyślnie usunięto magazyn";
      })
      .addCase(deleteStorage.rejected, (state, action) => {
        state.storageError = errorMessages['storage'][getErrorCode(action.error.message)];
      })
      .addCase(updateStorage.fulfilled, (state, action) => {
        const newStorage = action.payload.data;
        state.chosenStorage = newStorage;
        state.chosenStorageId = newStorage.id;
        state.storageInfo = "Pomyślnie zaktualizowano magazyn";
      });
    // .addCase(updateStorage.rejected, (_, action) => {
    //   alert(`Operation failed - ${action.error.message}`);
    // })
  }
});

export const {
  clearStorage,
  setStorageId,
  clearStoragesData,
  clearStorageErrors,
  clearStorageInfo
} = storageSlice.actions;

export default storageSlice.reducer;