/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearShipmentsByBase,
  getPaginatedShipmentsByBase,
  getShipmentsByBaseCount,
} from "../../../slices/shipmentSlice";
import ShipmentsByBaseItem from "./ShipmentsByBaseItem";
import { AccessTime, Eject, Info } from "@mui/icons-material";
import { getAllStorages } from "../../../slices/storageSlice";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../../../static/static";
import Pagination from "../../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading/Loading";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import CustomDatePicker from "../../CustomDatePicker/CustomDatePicker";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import { getLocalTimeDate } from "../../../static/functions";
import PageHeader from "../../PageHeader/PageHeader";
import TableHeader from "../../TableHeader/TableHeader";
import { ShipmentsByDestinationHeaders } from "../../../static/tablesHeaders";
import EmptyListError from "../../EmptyListError/EmptyListError";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import MobileDataSorter from "../../MobileDataSorter/MobileDataSorter";
import usePagination from "../../../hooks/usePagination";

const ShipmentsByBaseView = ({ setRefresh }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chosenStorage } = useSelector((slice) => slice.storage);
  const { shipmentsByBase, shipmentsByBaseCount } = useSelector(
    (slice) => slice.shipment
  );
  const searchingRef = useRef("");
  const {
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("creationDate", "descending");
  const [creationDates, setCreationDates] = useState("");

  const loadDataCount = useCallback(async () => {
    if (chosenStorage !== null) {
      setIsDataLoading(true);
      await dispatch(
        getShipmentsByBaseCount({
          searchingValue,
          status: ["Created", "In progress"],
          baseStorage: chosenStorage.id,
          destinationStorage: "",
          creationDateStart: creationDates.start
            ? getLocalTimeDate(creationDates.start)
            : "",
          creationDateEnd: creationDates.end
            ? getLocalTimeDate(creationDates.end)
            : "",
          sendDatesStart: "",
          sendDatesEnd: "",
          deliveredDatesStart: "",
          deliveredDatesEnd: "",
          sender: "",
          receiver: "",
        })
      );
      setIsDataLoading(false);
    }
  }, [searchingValue, chosenStorage, creationDates.start, creationDates.end]);

  const loadData = useCallback(
    chosenStorage !== null
      ? async () => {
          setIsDataLoading(true);
          await dispatch(
            getPaginatedShipmentsByBase({
              leftIndex,
              rightIndex,
              sortingParam: sorter,
              sortingOrder: sorting,
              searchingValue,
              status: ["Created", "In progress"],
              baseStorage: chosenStorage.id,
              destinationStorage: "",
              creationDateStart: creationDates.start
                ? getLocalTimeDate(creationDates.start)
                : "",
              creationDateEnd: creationDates.end
                ? getLocalTimeDate(creationDates.end)
                : "",
              sendDatesStart: "",
              sendDatesEnd: "",
              deliveredDatesStart: "",
              deliveredDatesEnd: "",
              sender: "",
              receiver: "",
            })
          );
          setIsDataLoading(false);
        }
      : () => {},
    [
      leftIndex,
      rightIndex,
      sorter,
      sorting,
      searchingValue,
      chosenStorage,
      creationDates.start,
      creationDates.end,
    ]
  );

  const setCreationDateInterval = (dates) => {
    const [startDate, endDate] = dates;
    setCreationDates({
      start: startDate,
      end: endDate,
    });
  };

  useEffect(() => { loadDataCount(); }, [loadDataCount]);

  useEffect(() => { loadData(); }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearShipmentsByBase());
  }, []);

  return (
    <SectionWrapper>
      <PageHeader
        icon={<Eject />}
        titleText="Przesyłki wychodzące"
        size="medium"
        onRightComponent={
          <DetailsButton
            onClick={async () => {
              localStorage.setItem("chosenStorageId", chosenStorage.id);
              await dispatch(getAllStorages());
              navigate("/shipments");
            }}
            startIcon={<AccessTime />}
            text="Historia przesyłek"
          />
        }
      />
      <AdditionalFunctionalitiesWrapper>
        <CustomSearchField
          displaySearchingValue={displaySearchingValue}
          setDisplaySearchingValue={setDisplaySearchingValue}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
          searchingRef={searchingRef}
          placeholder="szukaj przesyłek..."
          width="20%"
        />
        <CustomDatePicker
          labelName="Data wysłania"
          datesRange={creationDates}
          setDatesRange={(dates) => setCreationDateInterval(dates)}
          maxDate={new Date()}
        />
        <MobileDataSorter
          tableHeader={ShipmentsByDestinationHeaders}
          setOuterSorter={setSorter}
          setOuterSorting={setSorting}
          defaultSorter="creationDate"
        />
      </AdditionalFunctionalitiesWrapper>
      <TableHeader
        setSorter={setSorter}
        setSorting={setSorting}
        headers={ShipmentsByDestinationHeaders}
        gap="1rem"
      />
      <Pagination
        collectionSize={shipmentsByBaseCount}
        leftIndex={leftIndex}
        rightIndex={rightIndex}
        setLeftIndex={setLeftIndex}
        setRightIndex={setRightIndex}
      >
        {isDataLoading ? (
          <Loading />
        ) : (
          <>
            {shipmentsByBase !== null &&
              shipmentsByBase.map((shipment, id) => (
                <ShipmentsByBaseItem
                  orderNumber={leftIndex + id + 1}
                  key={shipment.id}
                  shipment={shipment}
                  chosenStorageId={chosenStorage.id}
                  setRefresh={setRefresh}
                />
              ))}
          </>
        )}
        {shipmentsByBase !== null && shipmentsByBase.length === 0 && (
          <EmptyListError
            message="Brak przesyłek wychodzących"
            icon={<Info />}
          />
        )}
      </Pagination>
    </SectionWrapper>
  );
};

export default ShipmentsByBaseView;
