/* eslint-disable react/prop-types */
import React from "react";
import { dateFormatView } from "../../../../../../static/functions";
import CustomChip from "../../../../../CustomChip/CustomChip";
import {
  taskStatus,
  taskStatusChipStyles,
  taskType,
  taskTypeChipStyles,
} from "../../../../../../static/static";
import CompactDataDisplay from "../../../../../CompactDataDisplay/CompactDataDisplay";
import DetailsButton from "../../../../../DetailsButton/DetailsButton";
import { useNavigate } from "react-router-dom";
import TaskExecution from "../../../../../TaskView/TaskExecution/TaskExecution";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "0.1rem 1rem",
}));


const CommissionTaskItemMobile = ({ task, chosenUser, setRefresh }) => {
  const navigate = useNavigate();

  const {
    id,
    creationDate,
    planningExecutionDate,
    executionDate,
    description,
    executor,
    proposedExecutor,
    assignedExecutor,
    status,
    type,
  } = task;

  const data = [
    {
      title: "Data utworzenia:",
      value: creationDate ? dateFormatView(creationDate) : "Brak",
    },
    {
      title: "Planowana data real.:",
      value: planningExecutionDate
        ? dateFormatView(planningExecutionDate)
        : "Brak",
    },
    {
      title: "Data wykonania:",
      value: executionDate ? dateFormatView(executionDate) : "Brak",
    },
    { title: "Opis:", value: description ? description : "Brak" },
    {
      title: "Proponowany wykonawca:",
      value: proposedExecutor
        ? `${proposedExecutor.name} ${proposedExecutor.surname}`
        : "Brak",
    },
    {
      title: "Przypisany wykonawca:",
      value: assignedExecutor
        ? `${assignedExecutor.name} ${assignedExecutor.surname}`
        : "Brak",
    },
    {
      title: "Wykonawca:",
      value: executor ? `${executor.name} ${executor.surname}` : "Brak",
    },
    {
      title: "Status:",
      value: (
        <CustomChip
          label={taskStatus[status]}
          sx={taskStatusChipStyles(status, "")}
          width="40%"
        />
      ),
    },
    {
      title: "Typ:",
      value: (
        <CustomChip
          label={taskType[type]}
          sx={taskTypeChipStyles(type, "")}
          width="10%"
        />
      ),
    },
  ];
  return (
    <CompactDataDisplay
      title={`Zadanie #${id}`}
      data={data}
      actions={
        <ButtonWrapper>
          <DetailsButton onClick={() => navigate(`/tasks/${id}`)} />
          <TaskExecution
            task={task}
            setRefresh={setRefresh}
            chosenUser={chosenUser}
          />
        </ButtonWrapper>
      }
    />
  );
};

export default CommissionTaskItemMobile;
