/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, TextField, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  acceptVerificationTask,
  clearTask,
  declineVerificationTask,
  getTask,
} from "../../../slices/taskSlice";
import { getCommissionFilteredCloseCodes } from "../../../slices/commissionSlice";
import { CallMissed, Check, Clear } from "@mui/icons-material";
import SearchDropdown from "../../SearchDropdown/SearchDropdown";
import authService from "../../../auth/authService";
import PageHeader from "../../PageHeader/PageHeader";
import AcceptButton from "../../AcceptButton/AcceptButton";
import DeleteButton from "../../DeleteButton/DeleteButton";
import EditButton from "../../EditButton/EditButton";
import TaskExecutionCompactData from "../../TaskView/TaskExecution/TaskExecutionCompactData";
import WorkTaskShortcut from "../../TaskView/TaskExecution/WorkTaskShortcut";
import Loading from "../../Loading/Loading";

const Wrapper = styled(Box)(({ theme }) => ({
  height: "45rem",
  width: "31.25rem",
  [theme.breakpoints.down("tablet")]: {
    width: "16rem",
  },
}));

const InnerWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%",
  margin: "1.875em auto 0 auto",
  gap: "0.625em",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  marginBottom: "1rem",
  justifyContent: "center",
  gap: "1.25rem",
}));

const VerificationModal = ({
  taskId,
  setIsVerificationModalVisible,
  setRefresh,
}) => {
  const dispatch = useDispatch();
  const [finishCode, setFinishCode] = useState("");
  const [description, setDescription] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { chosenTask } = useSelector((slice) => slice.task);

  const acceptTask = async () => {
    const data = {
      taskId,
      description,
      finishCode: finishCode.id,
      executor: authService.getDecodedAccessToken().user_id,
    };
    await dispatch(acceptVerificationTask(data));
    setRefresh((state) => !state);
    setIsVerificationModalVisible(false);
  };
  const declineTask = async () => {
    const data = { taskId, description };
    await dispatch(declineVerificationTask(data));
    setRefresh((state) => !state);
    setIsVerificationModalVisible(false);
  };

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getTask(taskId));
    setIsDataLoading(false);
  }, [taskId]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => dispatch(clearTask());
  }, []);

  if (isDataLoading)
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );

  return (
    <>
      {chosenTask !== null && (
        <Wrapper>
          <InnerWrapper>
            <PageHeader
              titleText="Weryfikacja naprawy serwisowej"
              icon={<Check />}
              size="medium"
            />
            <Typography
              sx={{
                fontSize: "0.8em",
                marginBottom: "0.75em",
                color: "red",
              }}
            >
              Pola oznaczone * są obowiązkowe
            </Typography>
            <TaskExecutionCompactData task={chosenTask} />
            <WorkTaskShortcut task={chosenTask.parentTask} />
            <InputLabel sx={{ alignSelf: "start" }}>Kod zamknięcia</InputLabel>
            <SearchDropdown
              collectionName="commissionCloseCodes"
              sliceName="commission"
              getDataFunction={getCommissionFilteredCloseCodes}
              placeholder="Wybierz kod zamknięcia* ..."
              outerValue={
                finishCode
                  ? finishCode.name !== undefined
                    ? finishCode.name
                    : ""
                  : ""
              }
              setFunction={(errorCode) => setFinishCode(errorCode)}
              propertyToDisplay="name"
              propsWidth="100%"
            />
            <TextField
              label="Opis wykonania/Powód odrzucenia*"
              multiline
              rows={12}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
            />
            <ButtonWrapper>
              <AcceptButton
                onClick={() => acceptTask()}
                variant="contained"
                size="small"
                startIcon={<Check />}
                disabled={description === "" || finishCode === ""}
                text="akceptuj"
                clickLock
              />
              <DeleteButton
                onClick={() => declineTask()}
                startIcon={<CallMissed />}
                disabled={description === ""}
                text="odrzuć"
              />
              <EditButton
                variant="contained"
                size="small"
                startIcon={<Clear />}
                text="anuluj"
                onClick={() => setIsVerificationModalVisible(false)}
              />
            </ButtonWrapper>
          </InnerWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default VerificationModal;
