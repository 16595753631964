/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AcceptButton from "../../AcceptButton/AcceptButton";
import EditButton from "../../EditButton/EditButton";
import { useDispatch } from "react-redux";
import { Clear } from "@mui/icons-material";
import { deleteUnit } from "../../../slices/unitSlice";

const DeleteUnitForm = ({
  unitId,
  isUnitDeleteModalOpen,
  setIsUnitDeleteModalOpen,
  setRefresh,
}) => {
  const dispatch = useDispatch();

  const deleteInnerUnit = useCallback(async () => {
    await dispatch(deleteUnit(unitId));
    setIsUnitDeleteModalOpen(false);
    setRefresh(state => !state);
  }, []);

  return (
    <Dialog
      open={isUnitDeleteModalOpen}
      onClose={() => setIsUnitDeleteModalOpen()}
    >
      <Box sx={{ maxWidth: "20rem" }}>
        <DialogTitle>Edycja jednostki produktu</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Usuwanie wartości może powodować nieścisłości w rzeczywistym obrocie
            towarami.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ gap: "0.5rem" }}>
          <AcceptButton onClick={() => deleteInnerUnit()} />
          <EditButton
            onClick={() => setIsUnitDeleteModalOpen(false)}
            text="Anuluj"
            startIcon={<Clear />}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DeleteUnitForm;
