/* eslint-disable react/prop-types */
import React from "react";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import PageHeader from "../../PageHeader/PageHeader";
import { Autorenew, LocationOn } from "@mui/icons-material";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
} from "../../ObjectDetailsElements/ObjectDetailsElements";
import { Box, Typography } from "@mui/material";
import GeoLocationIcon from "../../GeoLocation/GeoLocationIcon";

const LocationDetails = ({ location }) => {
  const {
    company,
    name,
    address: { street, province, postcode, city, country, linkedVersion },
    contactPerson,
    contactPhone,
    code,
    isSynchronized
  } = location;

  return (
    <SectionWrapper>
      <PageHeader
        size="medium"
        titleText="Dane lokalizacji"
        icon={<LocationOn />}
      />
      <InfoWrapper>
        <DataBox>
          <DataTitle>Firma:</DataTitle>
          <Typography>{company.name}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Kod RT:</DataTitle>
          <Typography>{code}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Nazwa:</DataTitle>
          <Typography>{name}</Typography>
        </DataBox>
        <DataBox>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <DataTitle>Adres:</DataTitle>
            <GeoLocationIcon query={linkedVersion}/>
          </Box>
          <Typography>
            {linkedVersion
              ? linkedVersion
              : `ul. ${street}, ${city}, ${province}, ${country}, ${postcode}`}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Telefon:</DataTitle>
          <Typography>{contactPhone ? contactPhone : "Brak"}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Osoba kontaktowa:</DataTitle>
          <Typography>{contactPerson ? contactPerson : "Brak"}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Czy zsynchronizowane:</DataTitle>
          <Typography>
            {isSynchronized ? (
              <Autorenew sx={{ color: "darkgreen" }} />
            ) : (
              <Autorenew
                sx={{
                  color: "red",
                }}
              />
            )}
          </Typography>
        </DataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default LocationDetails;
