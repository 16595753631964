import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { clearShipment, getShipment } from "../../slices/shipmentSlice";
import ToDeliveredModal from "../Shipments/ShipmentsElements/ToDeliveredModal/ToDeliveredModal";
import ToInProgressModal from "../Shipments/ShipmentsElements/ToInProgressModal";
import ShipmentUnits from "../Shipments/ShipmentsElements/ShipmentUnits";
import ShipmentInfo from "./ShipmentInfo";
import PageWrapper from "../PageWrapper/PageWrapper";
import PageHeader from "../PageHeader/PageHeader";
import {
  ArrowDownward,
  ArrowUpward,
  CheckCircle,
  LocalShipping,
} from "@mui/icons-material";
import {
  DetailsButton,
} from "../FunctionalButtons/FunctionalButtons";
import BlankSpace from "../BlankSpace/BlankSpace";
import Loading from "../Loading/Loading";
import authService from "../../auth/authService";
import { useParams } from "react-router-dom";
import ExplainShipment from "../Shipments/ShipmentsElements/ExplainShipment";
import ReturnButton from "../ReturnButton/ReturnButton";

const Shipment = () => {
  const dispatch = useDispatch();
  const { shipmentId } = useParams();
  console.log("ShipmentID: ", shipmentId);
  const {
    shipments: { chosen: chosenShipment },
    chosenShipmentId,
  } = useSelector((state) => state.shipment);
  let { chosenStorageId } = useSelector((state) => state.storage);
  chosenStorageId = chosenStorageId
    ? chosenStorageId
    : localStorage.getItem("chosenStorageId");

  const [isDataLoading, setIsDataLoading] = useState(false);

  const { user_id: userId } = authService.getDecodedAccessToken();

  const [
    isStatusChangeToDeliveredModalVisible,
    setIsStatusChangeToDeliveredModalVisible,
  ] = useState(false);

  const [
    isStatusChangeToInProgressModalVisible,
    setIsStatusChangeToInProgressModalVisible,
  ] = useState(false);

  const [isExplainShipmentModalVisible, setIsExplainShipmentModalVisible] =
    useState(false);

  const [refresh, setRefresh] = useState(false);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getShipment(shipmentId));
    setIsDataLoading(false);
  }, [shipmentId, refresh]);

  useEffect(() => loadData(), [loadData]);

  useEffect(() => {
    return () => dispatch(clearShipment());
  }, []);

  if (isDataLoading) return <Loading />;

  return (
    <>
      {chosenShipmentId !== null && chosenShipment !== null ? (
        <>
          <PageWrapper width="80%">
            <PageHeader
              titleText="Informacje o przesyłce"
              icon={<LocalShipping />}
              onRightComponent={<ReturnButton />}
            >
              {chosenShipment.status === "In progress" &&
                (chosenStorageId === chosenShipment.destinationStorage.id ||
                  userId === chosenShipment.destinationStorage.user.id) && (
                  <DetailsButton
                    onClick={() =>
                      setIsStatusChangeToDeliveredModalVisible(true)
                    }
                    text="Odbierz"
                    startIcon={<ArrowDownward />}
                  />
                )}
              {chosenShipment.status === "To Explain" &&
                (chosenStorageId === chosenShipment.destinationStorage.id ||
                  userId === chosenShipment.destinationStorage.user.id) && (
                  <DetailsButton
                    onClick={() => setIsExplainShipmentModalVisible(true)}
                    text="Rozwiąż"
                    startIcon={<CheckCircle />}
                  />
                )}
              {chosenShipment.status === "Created" &&
                (chosenStorageId === chosenShipment.baseStorage.id ||
                  userId === chosenShipment.baseStorage.user.id) && (
                  <DetailsButton
                    onClick={() =>
                      setIsStatusChangeToInProgressModalVisible(true)
                    }
                    text="Wyślij"
                    startIcon={<ArrowUpward />}
                  />
                )}
            </PageHeader>
            <BlankSpace />
            <ShipmentInfo shipment={chosenShipment} />
            <BlankSpace />
            <ShipmentUnits shipmentUnits={chosenShipment.shipment_units} />
          </PageWrapper>
          <ToDeliveredModal
            isStatusChangeToDeliveredModalVisible={
              isStatusChangeToDeliveredModalVisible
            }
            setIsStatusChangeToDeliveredModalVisible={
              setIsStatusChangeToDeliveredModalVisible
            }
            shipment={chosenShipment}
            setRefresh={setRefresh}
          />

          <ToInProgressModal
            shipmentId={chosenShipment.id}
            isStatusChangeToInProgressModalVisible={
              isStatusChangeToInProgressModalVisible
            }
            setIsStatusChangeToInProgressModalVisible={
              setIsStatusChangeToInProgressModalVisible
            }
            setRefresh={setRefresh}
          />
          <ExplainShipment
            shipmentId={chosenShipment.id}
            isExplainShipmentModalVisible={isExplainShipmentModalVisible}
            setIsExplainShipmentModalVisible={setIsExplainShipmentModalVisible}
            setRefresh={setRefresh}
          />
        </>
      ) : (
        <Box>
          <Typography>Brak danych o przesyłce</Typography>
        </Box>
      )}
    </>
  );
};

export default Shipment;
