/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { polishProvinces, roles } from "../../static/static";
import { useDispatch, useSelector } from "react-redux";
import { createUser, getUser, updateUser } from "../../slices/userSlice";
import { Add, Clear, Edit } from "@mui/icons-material";
import PageHeader from "../PageHeader/PageHeader";
import EditButton from "../EditButton/EditButton";
import AcceptButton from "../AcceptButton/AcceptButton";
import CustomTextField from "../CustomTextField/CustomTextField";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  height: "50rem",
  width: "22rem",
  justifyContent: "center",
  alignItems: "center",
  padding: "0.5rem",
  [theme.breakpoints.down("tablet")]: {
    width: "16rem",
    height: "56rem",
  },
}));

const InnerWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  width: "90%",
  justifyContent: "center",
  alignItems: "center",
}));

const ButtonsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
}));

const UserForm = ({
  type = "add",
  userId = "",
  closeModal = () => {},
  setRefresh = () => {},
}) => {
  const dispatch = useDispatch();
  const [buttonLock, setButtonLock] = useState(false);
  const { userData: authUserData } = useSelector((slice) => slice.auth);

  const { chosenUser } = useSelector((slice) => slice.user);
  const [userData, setUserData] = useState({
    username: "",
    name: "",
    surname: "",
    role: "",
    email: "",
    phone: "",
    province: "",
    oldPassword: "",
    password: "",
    repeatPassword: "",
  });

  const getData = useCallback(async () => {
    await dispatch(getUser(userId));
  }, [userId]);

  const isFormDataCompleted = () => {
    return !(
      !userData.username ||
      !userData.name ||
      !userData.surname ||
      !userData.role ||
      !userData.province ||
      (type === "add" && (!userData.password || !userData.repeatPassword)) ||
      (type === "edit" && !userData.oldPassword)
    );
  };

  const addUser = useCallback(async () => {
    const data = {
      username: userData.username,
      name: userData.name,
      surname: userData.surname,
      role: userData.role,
      email: userData.email,
      province: userData.province,
      phone: userData.phone,
      password: userData.password,
    };
    await dispatch(createUser(data));
    setRefresh();
    closeModal();
  }, [userData]);

  const editUser = useCallback(async () => {
    const data = {
      username: userData.username,
      name: userData.name,
      surname: userData.surname,
      role: userData.role,
      email: userData.email,
      province: userData.province,
      phone: userData.phone,
      oldPassword: userData.oldPassword,
      newPassword: userData.password,
      modifier: authUserData.user_id,
    };
    await dispatch(updateUser({ data, userId }));
    setRefresh();
    closeModal();
  }, [userData]);

  useEffect(() => {
    if (type === "edit" && chosenUser === null) {
      getData();
    }
    if (chosenUser !== null && userData.username === "") {
      setUserData({
        username: chosenUser.username,
        name: chosenUser.name,
        surname: chosenUser.surname,
        role: chosenUser.role,
        email: chosenUser.email,
        phone: chosenUser.phone,
        province: chosenUser.province,
        oldPassword: "",
        password: "",
        repeatPassword: "",
      });
    }
  }, [chosenUser, getData]);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (keyCode === 13 && !buttonLock && isFormDataCompleted()) {
        if (type === "add") addUser();
        if (type === "edit") editUser();
        setButtonLock(true);
        setTimeout(() => setButtonLock(false), 5000);
      }
    },
    [buttonLock, addUser, editUser]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <Wrapper>
      <InnerWrapper>
        <PageHeader
          titleText={
            type === "edit"
              ? `Edycja użytkownika ${userData.username}`
              : "Dodawanie użytkownika"
          }
          icon={type === "edit" ? <Edit /> : <Add />}
          size="medium"
          onRightComponent={<></>}
        />
        <Typography sx={{ color: "red", fontSize: "0.8rem" }}>
          *Pola obowiązkowe
        </Typography>
        <CustomTextField
          label="Login*"
          value={userData.username}
          onChange={(e) =>
            setUserData((state) => {
              return { ...state, username: e.target.value };
            })
          }
          disabled={type === "edit"}
          fullWidth
          maxLength={100}
        />
        <CustomTextField
          label="Imię*"
          value={userData.name}
          onChange={(e) =>
            setUserData((state) => {
              return { ...state, name: e.target.value };
            })
          }
          disabled={authUserData.role !== "admin"}
          fullWidth
          maxLength={30}
        />
        <CustomTextField
          label="Nazwisko*"
          value={userData.surname}
          onChange={(e) =>
            setUserData((state) => {
              return { ...state, surname: e.target.value };
            })
          }
          disabled={authUserData.role !== "admin"}
          fullWidth
          maxLength={30}
        />
        <CustomTextField
          onChange={(e) =>
            setUserData((state) => {
              return { ...state, role: e.target.value };
            })
          }
          value={userData.role}
          label="Rola*"
          autoComplete="off"
          disabled={authUserData.role !== "admin"}
          fullWidth
          select
          counter={false}
        >
          {Object.keys(roles).map((role, id) => (
            <MenuItem key={id} value={role}>
              {roles[role]}
            </MenuItem>
          ))}
        </CustomTextField>
        <CustomTextField
          label="Województwo/Prowincja*"
          value={userData.province}
          onChange={(e) =>
            setUserData((state) => {
              return { ...state, province: e.target.value };
            })
          }
          fullWidth
          select
          counter={false}
        >
          <MenuItem key="" value="wszystkie">
            Wszystkie
          </MenuItem>
          {polishProvinces.map((province, index) => (
            <MenuItem key={index} value={province}>
              {province}
            </MenuItem>
          ))}
        </CustomTextField>
        <CustomTextField
          label="Adres email"
          value={userData.email}
          onChange={(e) =>
            setUserData((state) => {
              return { ...state, email: e.target.value };
            })
          }
          disabled={authUserData.role !== "admin"}
          fullWidth
          maxLength={50}
        />
        <CustomTextField
          label="Telefon"
          autoComplete="new-password"
          value={userData.phone}
          onChange={(e) =>
            setUserData((state) => {
              return { ...state, phone: e.target.value };
            })
          }
          disabled={authUserData.role !== "admin"}
          fullWidth
          maxLength={20}
        />
        {type === "edit" && (
          <CustomTextField
            label="Hasło*"
            onChange={(e) =>
              setUserData((state) => {
                return { ...state, oldPassword: e.target.value };
              })
            }
            autocomplete="new-password"
            value={userData.oldPassword}
            type="password"
            disabled={authUserData.role === "admin"}
            fullWidth
            counter={false}
            maxLength={50}
          />
        )}
        <CustomTextField
          label={type === "edit" ? "Nowe hasło*" : "Hasło*"}
          autoComplete="new-password"
          onChange={(e) =>
            setUserData((state) => {
              return { ...state, password: e.target.value };
            })
          }
          value={userData.password}
          type="password"
          disabled={
            userData.oldPassword === "" && authUserData.role !== "admin"
          }
          fullWidth
          counter={false}
          maxLength={50}
        />
        <CustomTextField
          label={type === "edit" ? "Powtórz nowe hasło*" : "Powtórz hasło*"}
          onChange={(e) =>
            setUserData((state) => {
              return { ...state, repeatPassword: e.target.value };
            })
          }
          autoComplete="nope"
          value={userData.repeatPassword}
          type="password"
          fullWidth
          disabled={
            userData.oldPassword === "" && authUserData.role !== "admin"
          }
          maxLength={50}
          counter={false}
        />
        <ButtonsWrapper>
          {type === "edit" ? (
            <EditButton
              onClick={() => editUser()}
              startIcon={<Edit />}
              text="Edytuj"
              disabled={buttonLock || !isFormDataCompleted()}
            />
          ) : (
            <AcceptButton
              onClick={() => addUser()}
              startIcon={<Add />}
              text="Dodaj"
              disabled={buttonLock || !isFormDataCompleted()}
            />
          )}
          <EditButton
            onClick={() => closeModal()}
            startIcon={<Clear />}
            text="Anuluj"
          />
        </ButtonsWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

export default UserForm;
