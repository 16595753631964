/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PageHeader from "../../../PageHeader/PageHeader";
import { DynamicForm } from "@mui/icons-material";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  width: "100%",
  justifyContent: "center",
  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
    width: "60%",
    margin: '0 auto',
    justifyContent: "center",
  },
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexBasis: "18%",
  minHeight: "3rem",
  gap: "0.5rem",
  position: "relative",
  [theme.breakpoints.down("tablet")]: {
    flexBasis: "100%",
    minHeight: "2rem",
  },
}));

const CustomButtonTitle = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "10%",
  transform: "translateY(-50%)",
  [theme.breakpoints.down("desktop")]: {
    fontSize: "0.75rem",
  },
  [theme.breakpoints.down("laptop")]: {
    fontSize: "0.65rem",
  },
}));

const Triangle = styled(Box)(({ theme }) => ({
  width: 0,
  height: 0,
  position: "absolute",
  right: 0,
  borderTop: "1.5625rem solid transparent",
  borderBottom: "1.5625rem solid transparent",
  top: "50%",
  transform: "translateY(-50%)",
  [theme.breakpoints.down("laptop")]: {
    borderTop: "1rem solid transparent",
    borderBottom: "1rem solid transparent",
  },
}));

const FormSteps = ({ currentPage, listOfActivePages }) => {
  return (
    <SectionWrapper>
      <PageHeader
        icon={<DynamicForm />}
        titleText="Kroki formularza"
        size="medium"
      />
      <ContentWrapper>
        <ButtonWrapper
          sx={{
            bgcolor: listOfActivePages.length > 0 ? "#7e9dfc" : "#9b9c9e",
            border: currentPage === 0 ? "0.2em solid #042487" : "none",
          }}
        >
          <CustomButtonTitle
            sx={{
              color: listOfActivePages.length > 0 ? "#042487" : "#555659",
            }}
          >
            1. Firma
          </CustomButtonTitle>
          <Triangle
            sx={{
              borderRight:
                listOfActivePages.length > 0
                  ? "1.5625rem solid #042487"
                  : "1.5625rem solid #555659",
            }}
          />
        </ButtonWrapper>
        <ButtonWrapper
          sx={{
            bgcolor: listOfActivePages.length > 1 ? "#7e9dfc" : "#9b9c9e",
            border: currentPage === 1 ? "0.2em solid #042487" : "none",
          }}
        >
          <CustomButtonTitle
            sx={{
              color: listOfActivePages.length > 1 ? "#042487" : "#555659",
            }}
          >
            2. Lokalizacja
          </CustomButtonTitle>
          <Triangle
            sx={{
              borderRight:
                listOfActivePages.length > 1
                  ? "1.5625rem solid #042487"
                  : "1.5625rem solid #555659",
            }}
          />
        </ButtonWrapper>
        <ButtonWrapper
          sx={{
            bgcolor: listOfActivePages.length > 2 ? "#7e9dfc" : "#9b9c9e",
            border: currentPage === 2 ? "0.2em solid #042487" : "none",
          }}
        >
          <CustomButtonTitle
            sx={{
              color: listOfActivePages.length > 2 ? "#042487" : "#555659",
            }}
          >
            3. Urządzenie
          </CustomButtonTitle>
          <Triangle
            sx={{
              borderRight:
                listOfActivePages.length > 2
                  ? "25px solid #042487"
                  : "25px solid #555659",
            }}
          />
        </ButtonWrapper>
        <ButtonWrapper
          sx={{
            bgcolor: listOfActivePages.length > 3 ? "#7e9dfc" : "#9b9c9e",
            border: currentPage === 3 ? "0.2em solid #042487" : "none",
          }}
        >
          <CustomButtonTitle
            sx={{
              color: listOfActivePages.length > 3 ? "#042487" : "#555659",
            }}
          >
            4. Szczegóły
          </CustomButtonTitle>
          <Triangle
            sx={{
              borderRight:
                listOfActivePages.length > 3
                  ? "25px solid #042487"
                  : "25px solid #555659",
            }}
          />
        </ButtonWrapper>
        <ButtonWrapper
          sx={{
            bgcolor: listOfActivePages.length > 4 ? "#7e9dfc" : "#9b9c9e",
            border: currentPage === 4 ? "0.2em solid #042487" : "none",
          }}
        >
          <CustomButtonTitle
            sx={{
              color: listOfActivePages.length > 4 ? "#042487" : "#555659",
            }}
          >
            5. Podsumowanie
          </CustomButtonTitle>
          <Triangle
            sx={{
              borderRight:
                listOfActivePages.length > 4
                  ? "25px solid #042487"
                  : "25px solid #555659",
            }}
          />
        </ButtonWrapper>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default FormSteps;
