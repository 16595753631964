/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaginatedCommissions,
  getCommissionsCount,
  clearCommissionData,
} from "../../../slices/commissionSlice";
import CommissionItem from "../CommissionItem/CommissionItem";
import Pagination from "../../Pagination/Pagination";
import { InventoryOutlined, Settings, Add } from "@mui/icons-material";
import { commissionStatusChipStyles } from "../../../static/static";
import Loading from "../../Loading/Loading";
import "react-datepicker/dist/react-datepicker.css";
import CustomDatePicker from "../../CustomDatePicker/CustomDatePicker";
import EmptyListError from "../../EmptyListError/EmptyListError";
import { getLocalTimeDate } from "../../../static/functions";
import PageHeader from "../../PageHeader/PageHeader";
import {
  DetailsButton,
  EditButton,
} from "../../FunctionalButtons/FunctionalButtons";
import RefreshButton from "../../RefreshButton/RefreshButton";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import BlankSpace from "../../BlankSpace/BlankSpace";
import ChipSet from "../../ChipSet/ChipSet";
import TableHeader from "../../TableHeader/TableHeader";
import { commissionListHeaders } from "../../../static/tablesHeaders";
import PageWrapper from "../../PageWrapper/PageWrapper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import MobileDataSorter from "../../MobileDataSorter/MobileDataSorter";
import TableWrapper from "../../TableWrapper/TableWrapper";
import usePagination from "../../../hooks/usePagination";
import ButtonWrapper from "../../ButtonWrapper/ButtonWrapper";
import ReturnButton from "../../ReturnButton/ReturnButton";

// eslint-disable-next-line no-unused-vars

const chipSet = [
  {
    label: "Wszystkie",
    keyValue: "",
  },
  {
    label: "Utworzone",
    keyValue: "Created",
  },
  {
    label: "W trakcie",
    keyValue: "In progress",
  },
  {
    label: "Zamknięte",
    keyValue: "Closed",
  },
];

const CommissionsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchingRef = useRef("");

  const { commissions, commissionsCount } = useSelector(
    (state) => state.commission
  );
  const {
    userData: { role },
  } = useSelector((state) => state.auth);
  const [filterValue, setFilterValue] = useState("");
  const [createdDates, setCreationDates] = useState({ start: "", end: "" });
  const [finishDates, setFinishDates] = useState({ start: "", end: "" });
  const {
    refresh,
    setRefresh,
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("id", "descending");

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getCommissionsCount({
        searchingValue,
        type: "",
        status: filterValue,
        createdBy: "",
        deviceId: "",
        companyId: "",
        problemCode: "",
        finishCode: "",
        creationDateStart: createdDates.start
          ? getLocalTimeDate(createdDates.start)
          : "",
        creationDateEnd: createdDates.end
          ? getLocalTimeDate(createdDates.end)
          : "",
        finishDateStart: finishDates.start
          ? getLocalTimeDate(finishDates.start)
          : "",
        finishDateEnd: finishDates.end ? getLocalTimeDate(finishDates.end) : "",
      })
    );
    setIsDataLoading(false);
  }, [
    searchingValue,
    filterValue,
    refresh,
    createdDates.start,
    createdDates.end,
    finishDates.start,
    finishDates.end,
  ]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedCommissions({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        type: "",
        status: filterValue,
        createdBy: "",
        deviceId: "",
        companyId: "",
        problemCode: "",
        finishCode: "",
        creationDateStart: createdDates.start
          ? getLocalTimeDate(createdDates.start)
          : "",
        creationDateEnd: createdDates.end
          ? getLocalTimeDate(createdDates.end)
          : "",
        finishDateStart: finishDates.start
          ? getLocalTimeDate(finishDates.start)
          : "",
        finishDateEnd: finishDates.end ? getLocalTimeDate(finishDates.end) : "",
      })
    );
    setIsDataLoading(false);
  }, [
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    filterValue,
    refresh,
    createdDates.start,
    createdDates.end,
    finishDates.start,
    finishDates.end,
  ]);

  useEffect(() => { loadDataCount(); }, [loadData]);

  useEffect(() => { loadData(); }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearCommissionData());
  }, []);

  const setCreationDateInterval = (dates) => {
    const [startDate, endDate] = dates;
    setCreationDates({
      start: startDate,
      end: endDate,
    });
  };

  const setFinishDateInterval = (dates) => {
    const [startDate, endDate] = dates;
    setFinishDates({
      start: startDate,
      end: endDate,
    });
  };

  return (
    <PageWrapper width="90%">
      <PageHeader
        titleText="Zlecenia"
        icon={<InventoryOutlined />}
        onRightComponent={
          <ButtonWrapper>
            <DetailsButton
              onClick={() => navigate("/commission-add")}
              startIcon={<Add />}
              text="Utwórz zlecenie"
            />
            <ReturnButton/>
          </ButtonWrapper>
        }
      >
        <>
          <RefreshButton onClick={() => setRefresh((state) => !state)} />
          {role !== "service_technician" && (
            <EditButton
              text="Konfiguruj kody zleceń"
              onClick={() => navigate("/commission-codes")}
              startIcon={<Settings />}
            />
          )}
        </>
      </PageHeader>
      <BlankSpace />
      <SectionWrapper>
        <AdditionalFunctionalitiesWrapper>
          <CustomSearchField
            displaySearchingValue={displaySearchingValue}
            setDisplaySearchingValue={setDisplaySearchingValue}
            searchingValue={searchingValue}
            setSearchingValue={setSearchingValue}
            searchingRef={searchingRef}
            placeholder="szukaj zlecenia..."
            width="20%"
          />
          <ChipSet
            mainLabel="Statusy:"
            set={chipSet}
            chipStyles={commissionStatusChipStyles}
            setFunction={setFilterValue}
            actualValue={filterValue}
          />
          <CustomDatePicker
            labelName="Data utworzenia: "
            datesRange={createdDates}
            setDatesRange={(dates) => setCreationDateInterval(dates)}
            maxDate={new Date()}
          />
          <CustomDatePicker
            labelName="Data zakończenia: "
            datesRange={finishDates}
            setDatesRange={(dates) => setFinishDateInterval(dates)}
            maxDate={new Date()}
          />
          <MobileDataSorter
            tableHeader={commissionListHeaders}
            setOuterSorter={setSorter}
            setOuterSorting={setSorting}
            defaultSorter="id"
            defaultSorting="descending"
          />
        </AdditionalFunctionalitiesWrapper>
        <TableWrapper>
          <TableHeader
            headers={commissionListHeaders}
            setSorter={setSorter}
            setSorting={setSorting}
            gap="1%"
          />
          <Pagination
            collectionSize={commissionsCount}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            setLeftIndex={setLeftIndex}
            setRightIndex={setRightIndex}
          >
            {isDataLoading ? (
              <Loading />
            ) : (
              <>
                {commissions !== null &&
                  commissions.map((commission, id) => (
                    <CommissionItem
                      key={commission.id}
                      data={commission}
                      orderNumber={leftIndex + id + 1}
                    />
                  ))}
              </>
            )}
            {commissions !== null && commissions.length === 0 && (
              <EmptyListError message="Brak zleceń" />
            )}
          </Pagination>
        </TableWrapper>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default CommissionsList;
