/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { storageReportUnitListHeaders } from "../../../static/tablesHeaders";
import CustomChip from "../../CustomChip/CustomChip";
import TableDataGridWrapper from "../../TableGridWrapper/TableDataGridWrapper";
import { unitStatusChipStyles } from "../../../static/static";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
  flexWrap: "wrap",
}));

const StorageReportUnitItemDesktop = ({ orderNumber, unit}) => {
  const {
    partNumber,
    productName,
    categoryName,
    producerName,
    serialNumber,
    status,
    quantity,
  } = unit;

  return (
    <TableDataGridWrapper headers={storageReportUnitListHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{partNumber}</CustomDataDisplay>
      <CustomDataDisplay>{productName}</CustomDataDisplay>
      <CustomDataDisplay>{serialNumber}</CustomDataDisplay>
      <CustomDataDisplay>{categoryName}</CustomDataDisplay>
      <CustomDataDisplay>{producerName}</CustomDataDisplay>
      <CustomChip
        label={status}
        sx={unitStatusChipStyles(status, "")}
        width="60%"
      />
      <CustomDataDisplay>{quantity}</CustomDataDisplay>
      <ButtonWrapper></ButtonWrapper>
    </TableDataGridWrapper>
  );
};

export default StorageReportUnitItemDesktop;
