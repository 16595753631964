/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import useMediaQ from "../../../hooks/useMediaQ";
import AvailableUnitsItemDesktop from "./AvailableUnitsItemVersions/AvailableUnitsItemDesktop";
import AvailableUnitsItemMobile from "./AvailableUnitsItemVersions/AvailableUnitsItemMobile";

const AvailableUnitsItem = ({
  index,
  orderNumber,
  unit,
  setMountedProducts,
  setFatalProducts,
}) => {
  const { isDesktopDisplay } = useMediaQ();
  const {
    product: { productId, partNumber, name },
    serialNumber,
  } = unit;
  const [chosenQuantity, setChosenQuantity] = useState(0);
  const [returnQuantity, setReturnQuantity] = useState(0);
  const [addButtonLock, setAddButtonLock] = useState(true);
  const [DOAButtonLock, setDOAButtonLock] = useState(true);
  const [isAdded, setIsAdded] = useState(false);
  const [isDOAAdded, setIsDOAAdded] = useState(false);
  const { chosenStorageId } = useSelector((state) => state.storage);
  const [currentQuantity, setCurrentQuantity] = useState(unit.quantity);

  const changeAddQuantity = (value) => {
    setChosenQuantity(value);
    if (value == 0) {
      setAddButtonLock(true);
    } else {
      setAddButtonLock(false);
      if (value < returnQuantity) {
        setReturnQuantity(value);
      }
    }
  };

  const changeDOAQuantity = (value) => {
    setReturnQuantity(value);
    if (value == 0) {
      setDOAButtonLock(true);
    } else {
      setDOAButtonLock(false);
    }
  };

  const addItem = () => {
    setMountedProducts((state) => [
      ...state,
      {
        index,
        product: productId,
        partNumber,
        name,
        serialNumber,
        chosenQuantity,
        status: "MOUNTED",
        storage: chosenStorageId,
        removeInner: removeInnerItem,
      },
    ]);
    setCurrentQuantity((state) => state - chosenQuantity);
    setIsAdded(true);
  };

  const addDOAItem = () => {
    setFatalProducts((state) => [
      ...state,
      {
        index,
        product: productId,
        partNumber,
        name,
        serialNumber,
        returnQuantity,
        status: "DOA",
        storage: chosenStorageId,
        removeInner: removeInnerDOAItem,
      },
    ]);
    setCurrentQuantity((state) => state - returnQuantity);
    setIsDOAAdded(true);
  };

  const removeInnerItem = (chosenQuantity) => {
    setChosenQuantity(0);
    setCurrentQuantity((state) => state + chosenQuantity * 1);
    setAddButtonLock(true);
    setIsAdded(false);
  };

  const removeInnerDOAItem = (returnQuantity) => {
    setCurrentQuantity((state) => state + returnQuantity * 1);
    setReturnQuantity(0);
    setDOAButtonLock(true);
    setIsDOAAdded(false);
  };

  return (
    <>
      {isDesktopDisplay ? (
        <AvailableUnitsItemDesktop
          orderNumber={orderNumber}
          unit={unit}
          currentQuantity={currentQuantity}
          chosenQuantity={chosenQuantity}
          isAdded={isAdded}
          changeAddQuantity={changeAddQuantity}
          changeDOAQuantity={changeDOAQuantity}
          addItem={addItem}
          returnQuantity={returnQuantity}
          addDOAItem={addDOAItem}
          addButtonLock={addButtonLock}
          DOAButtonLock={DOAButtonLock}
          isDOAAdded={isDOAAdded}
        />
      ) : (
        <AvailableUnitsItemMobile
          orderNumber={orderNumber}
          unit={unit}
          currentQuantity={currentQuantity}
          chosenQuantity={chosenQuantity}
          isAdded={isAdded}
          changeAddQuantity={changeAddQuantity}
          changeDOAQuantity={changeDOAQuantity}
          addItem={addItem}
          returnQuantity={returnQuantity}
          addDOAItem={addDOAItem}
          addButtonLock={addButtonLock}
          DOAButtonLock={DOAButtonLock}
          isDOAAdded={isDOAAdded}
        />
      )}
    </>
  );
};

export default AvailableUnitsItem;
