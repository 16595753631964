/* eslint-disable react/prop-types */
import React from 'react';
import CompactDataDisplay from '../../../../CompactDataDisplay/CompactDataDisplay';
import DetailsButton from '../../../../DetailsButton/DetailsButton';
import { useNavigate } from 'react-router-dom';

const LocationDevicesItemMobile = ({device}) => {
  const navigate = useNavigate();
  const { id, serialNumber, name, type, isSynchronized } = device;

  const data = [
    { title: "Nr seryjny:", value: serialNumber },
    { title: "Nazwa:", value: name },
    { title: "Typ:", value: type },
    {
      title: "Czy zsynchronizowane:",
      value: isSynchronized,
    },
  ];

const navigateToCompany = () => {
  localStorage.setItem("chosenCompanyId", id);
  navigate(`/companies/${id}`);
};

return (
  <CompactDataDisplay
    title={`Urządzenie #${id}`}
    data={data}
    actions={
      <>
        <DetailsButton onClick={() => navigateToCompany()} />
      </>
    }
  />
);
};

export default LocationDevicesItemMobile;