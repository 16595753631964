/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { Box, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { Warning } from "@mui/icons-material";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const myIcon = new L.Icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  popupAnchor: [-0, -0],
  iconSize: [24, 32],
});

L.Marker.prototype.options.icon = myIcon;

const GeoLocation = ({ query = "" }) => {
  const provider = new OpenStreetMapProvider();
  const [cords, setCords] = useState(null);

  const findCords = useCallback(async () => {
    const results = await provider.search({ query });
    if(results.length > 0)
      setCords([results[0].y, results[0].x]);
  }, [query]);

  useEffect(() => findCords(), [findCords]);

  return (
    <>
      {cords !== null ? (
        <MapContainer
          center={cords}
          zoom={14}
          scrollWheelZoom={true}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={cords}>
            <Popup>
              Lokalizacja
              <br />
              {query}
            </Popup>
          </Marker>
        </MapContainer>
      ) : (
        <Box
          sx={{
            backgroundColor: "white",
            width: "100%",
            height: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Warning />
          <Typography>Nieznaleziono lokacji - nieprawidłowy adres</Typography>
        </Box>
      )}
    </>
  );
};

export default GeoLocation;
