/* eslint-disable react/prop-types */
import React from "react";

import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Clear, Info } from "@mui/icons-material";
import EmptyListError from "../../../EmptyListError/EmptyListError";

const TableWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  alignItems: "center",
  flexBasis: "30%",
}));

const TableTitleWrapper = styled(Typography)(() => ({
  fontSize: "1rem",
}));

const TableBorderWrapper = styled(Box)(() => ({
  border: "0.0625rem solid black",
  width: "100%",
}));

const PermissionWrapper = styled(Box)(() => ({
  minHeight: "2.5rem",
  border: "0.0625rem dotted black",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const PermissionContentWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  width: "92%",
  gap: "1rem",
}));

const ChosenPermissions = ({
  currentChosenPermissions,
  selectedUserPermissions,
  setSelectedUserPermissions,
}) => {
  return (
    <TableWrapper>
      <TableTitleWrapper>Przypisane</TableTitleWrapper>
      <TableBorderWrapper>
        {currentChosenPermissions.length > 0 ? (
          currentChosenPermissions.map((permission, id) => (
            <PermissionWrapper
              key={id}
              onClick={() =>
                !selectedUserPermissions.includes(permission)
                  ? setSelectedUserPermissions((state) => {
                      return [...state, permission];
                    })
                  : () => {}
              }
              sx={{
                cursor: !selectedUserPermissions.includes(permission)
                  ? "pointer"
                  : "default",
                bgcolor: selectedUserPermissions.includes(permission)
                  ? "#cae4eb"
                  : "white",
              }}
            >
              <PermissionContentWrapper>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontStyle: "italic",
                    flexGrow: 2,
                    flexBasis: "10rem",
                    marginLeft: "0.5rem",
                  }}
                >
                  {`${permission.name}`}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.7rem",
                    wordWrap: "break-word",
                  }}
                >
                  {permission.description}
                </Typography>
              </PermissionContentWrapper>
              {selectedUserPermissions.includes(permission) && (
                <IconButton
                  sx={{
                    zIndex: 2,
                  }}
                  onClick={() => {
                    const index = [...selectedUserPermissions].findIndex(
                      (item) => item.id == permission.id
                    );
                    console.log("index: ", index);
                    const table = [...selectedUserPermissions];
                    table.splice(index, 1);
                    setSelectedUserPermissions(table);
                  }}
                >
                  <Clear sx={{ fontSize: 16 }} />
                </IconButton>
              )}
            </PermissionWrapper>
          ))
        ) : (
          <EmptyListError icon={<Info />} message="Brak przypisanych ról" />
        )}
      </TableBorderWrapper>
    </TableWrapper>
  );
};

export default ChosenPermissions;
