import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API
} from '../static/static';


export const getCategories = createAsyncThunk('categories/get', async () => {
  const resData = await dataService.get(`${API}categories/`);
  return resData;
});

export const getPaginatedCategories = createAsyncThunk('categories/get/paginate', async (data) => {
  let {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
  } = data;
  if (leftIndex === undefined)
    leftIndex = "";
  if (rightIndex === undefined)
    rightIndex = "";
  if (sortingParam === undefined)
    sortingParam = "name";
  if (sortingOrder === undefined)
    sortingOrder = "ascending";
  if (searchingValue === undefined)
    searchingValue = "";
  const resData = await dataService.get(`${API}categories/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}`);
  return resData;
});

export const getCategoriesCount = createAsyncThunk('categories/get/count', async (data) => {
  let {
    searchingValue
  } = data;
  if (searchingValue === undefined)
    searchingValue = "";
  const resData = await dataService.get(`${API}categories/count/?searchingPhrase=${searchingValue}`);
  return resData;
});

export const getCategory = createAsyncThunk('category/get', async (givenCategoryId) => {
  const resData = givenCategoryId ? await dataService.get(`${API}category/${givenCategoryId}`) : await dataService.get(`${API}category/${localStorage.getItem("chosenCategoryId")}`);
  return resData;
});

export const createCategory = createAsyncThunk('category/create', async (data) => {
  const resData = await dataService.post(`${API}category/`, data);
  return resData;
});

export const updateCategory = createAsyncThunk('category/update', async (data, {
  getState
}) => {
  const {
    chosenCategoryId
  } = getState().data;
  const resData = chosenCategoryId ? await dataService.put(`${API}category/u/${chosenCategoryId.id}`, data) : await dataService.put(`${API}category/u/${localStorage.getItem("chosenCategoryId")}`, data);
  return resData;
});

export const deleteCategory = createAsyncThunk('category/delete', async (id) => {
  const resData = await dataService.delete(`${API}category/d/${id}`);
  return resData;
});

export const getFilteredCategories = createAsyncThunk('categories/get/filtered', async (searchingValue) => {
  const resData = await dataService.get(`${API}categories/filter/?searchingPhrase=${searchingValue}`);
  return resData;
});


const initialState = {
  categories: null,
  categoriesCount: 0,
  chosenCategoryId: null,
  chosenCategory: null,
};


export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategoryId: (state, action) => {
      localStorage.setItem('chosenCategoryId', action.payload);
      state.chosenCategoryId = action.payload;
      state.chosenCategory = null;
    },
    clearCategories: (state) => {
      state.categories = null;
      state.categoriesCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload.data;
      })
      // .addCase(getCategories.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getPaginatedCategories.fulfilled, (state, action) => {
        state.categories = action.payload.data;
      })
      // .addCase(getPaginatedCategories.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getCategoriesCount.fulfilled, (state, action) => {
        state.categoriesCount = action.payload.data;
      })
      // .addCase(getCategoriesCount.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.chosenCategory = action.payload.data;
        state.isCategoryLoading = false;
      })
      // .addCase(getCategory.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.chosenCategory = action.payload.data;
      })
      // .addCase(createCategory.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.chosenCategory = action.payload.data;
      })
      // .addCase(updateCategory.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getFilteredCategories.fulfilled, (state, action) => {
        state.categories = action.payload.data;
      });
  }
});

export const {
  clearCategories,
  setCategoryId
} = categorySlice.actions;

export default categorySlice.reducer;