import React from "react";
import { DetailsButton } from "../FunctionalButtons/FunctionalButtons";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ReturnButton = () => {
  const navigate = useNavigate();

  return (
    <DetailsButton
      text="Powrót"
      startIcon={<KeyboardArrowLeft />}
      onClick={() => navigate(-1)}
    />
  );
};

export default ReturnButton;
