/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colorize } from "@mui/icons-material";
import { DetailsButton } from "../../../FunctionalButtons/FunctionalButtons";

const CustomWrapper = styled(Box)(({ theme }) => ({
  marginTop: "0.5rem",
  display: "flex",
  flexDirection: "column",
  height: "41rem",
  width: "25rem",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("tablet")]: {
    width: "20rem",
  },
}));

const WarningText = styled(Typography)(() => ({
  fontSize: "0.8rem",
  marginTop: "1.25rem",
  marginBottom: "1.25rem",
  color: "red",
}));

const InnerWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  gap: "0.5rem",
}));

const AddressWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
}));

const ChooseLocationCreatePage = ({
  createdLocation,
  setCreatedLocation,
  mode,
  setIsWarningModalOpen,
}) => {
  return (
    <CustomWrapper>
      <DetailsButton
        startIcon={<Colorize />}
        text="Wybierz istniejącą lokalizację"
        onClick={
          mode === "create" ? () => setIsWarningModalOpen(true) : () => {}
        }
      />
      <WarningText>Pola oznaczone * są obowiązkowe</WarningText>
      <InnerWrapper>
        <TextField
          label="Kod lokalizacji*"
          value={createdLocation.locationCode}
          onChange={(e) =>
            setCreatedLocation({
              ...createdLocation,
              locationCode: e.target.value,
            })
          }
        />
        <TextField
          label="Nazwa*"
          value={createdLocation.name}
          onChange={(e) =>
            setCreatedLocation({
              ...createdLocation,
              name: e.target.value,
            })
          }
        />

        <TextField
          label="Numer telefoniczny*"
          value={createdLocation.contactPhone}
          onChange={(e) =>
            setCreatedLocation({
              ...createdLocation,
              contactPhone: e.target.value,
            })
          }
        />
        <TextField
          label="Osoba kontaktowa*"
          value={createdLocation.contactPerson}
          onChange={(e) =>
            setCreatedLocation({
              ...createdLocation,
              contactPerson: e.target.value,
            })
          }
        />
        <AddressWrapper>
          <Typography sx={{fontSize: '1.3rem'}}>
            Adres
          </Typography>
          <TextField
            label="Ulica*"
            value={createdLocation.address.street}
            onChange={(e) =>
              setCreatedLocation({
                ...createdLocation,
                address: {
                  street: e.target.value,
                  city: createdLocation.address.city,
                  province: createdLocation.address.province,
                  country: createdLocation.address.country,
                  postcode: createdLocation.address.postcode,
                },
              })
            }
          />
          <TextField
            label="Miasto*"
            value={createdLocation.address.city}
            onChange={(e) =>
              setCreatedLocation({
                ...createdLocation,
                address: {
                  street: createdLocation.address.street,
                  city: e.target.value,
                  province: createdLocation.address.province,
                  country: createdLocation.address.country,
                  postcode: createdLocation.address.postcode,
                },
              })
            }
          />
          <TextField
            label="Województwo/Prowincja*"
            value={createdLocation.address.province}
            onChange={(e) =>
              setCreatedLocation({
                ...createdLocation,
                address: {
                  street: createdLocation.address.street,
                  city: createdLocation.address.city,
                  province: e.target.value,
                  country: createdLocation.address.country,
                  postcode: createdLocation.address.postcode,
                },
              })
            }
          />
          <TextField
            label="Kraj*"
            value={createdLocation.address.country}
            onChange={(e) =>
              setCreatedLocation({
                ...createdLocation,
                address: {
                  street: createdLocation.address.street,
                  city: createdLocation.address.city,
                  province: createdLocation.address.province,
                  country: e.target.value,
                  postcode: createdLocation.address.postcode,
                },
              })
            }
          />
          <TextField
            label="Kod pocztowy*"
            value={createdLocation.address.postcode}
            onChange={(e) =>
              setCreatedLocation({
                ...createdLocation,
                address: {
                  street: createdLocation.address.street,
                  city: createdLocation.address.city,
                  province: createdLocation.address.province,
                  country: createdLocation.address.country,
                  postcode: e.target.value,
                },
              })
            }
          />
        </AddressWrapper>
      </InnerWrapper>
    </CustomWrapper>
  );
};

export default ChooseLocationCreatePage;
