/* eslint-disable react/prop-types */
import React from "react";
import { Typography } from "@mui/material";
import { Article, Check, Clear, Info } from "@mui/icons-material";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";
import PageHeader from "../../../PageHeader/PageHeader";
import EmptyListError from "../../../EmptyListError/EmptyListError";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
  SpecialDataBox,
} from "../../../ObjectDetailsElements/ObjectDetailsElements";

const SummaryPage = ({
  commissionDetails,
  chosenCompany,
  chosenLocation,
  createdLocation,
  chosenDevice,
  createdDevice,
  listOfActivePages,
}) => {
  const {
    name,
    isIntegrated,
    address: { street, city, country, postcode, linkedVersion },
  } = chosenCompany
    ? chosenCompany
    : { name: "", isIntegrated: "", address: "" };

  const {
    code: locationCode,
    name: locationName,
    address: {
      street: locationStreet,
      city: locationCity,
      country: locationCountry,
      province: locationProvince,
      postcode: locationPostcode,
      linkedVersion: locationLinkedVersion,
    },
    contactPhone,
    contactPerson,
  } = chosenLocation ? chosenLocation : createdLocation;

  const {
    name: deviceName,
    description: deviceDescription,
    serialNumber,
    type,
    orientation,
  } = chosenDevice ? chosenDevice : createdDevice;

  const { description, errorCode } = commissionDetails;

  return (
    <SectionWrapper>
      <PageHeader icon={<Article />} titleText="Dane zlecenia" size="medium" />
      {listOfActivePages.length === 1 && (
        <EmptyListError message="Brak danych zlecenia" icon={<Info />} />
      )}
      {listOfActivePages.length > 1 && (
        <InfoWrapper>
          <DataBox>
            <DataTitle>Wybrana firma:</DataTitle>
            <Typography>{name}</Typography>
          </DataBox>

          <DataBox>
            <DataTitle>Czy zintegrowane: </DataTitle>
            {isIntegrated ? (
              <Check sx={{ color: "green" }} />
            ) : (
              <Clear sx={{ color: "red" }} />
            )}
          </DataBox>
          <SpecialDataBox>
            <DataTitle>Adres główny firmy: </DataTitle>
            <Typography sx={{ wordWrap: "break-word" }}>
              {linkedVersion
                ? linkedVersion
                : `ul. ${street}, ${city}, ${country}, ${postcode}`}
            </Typography>
          </SpecialDataBox>
        </InfoWrapper>
      )}
      {listOfActivePages.length > 2 && (
        <InfoWrapper>
          <DataBox>
            <DataTitle>Kod lokalizacji: </DataTitle>
            <Typography>{locationCode}</Typography>
          </DataBox>
          <DataBox>
            <DataTitle>Nazwa lokalizacji: </DataTitle>
            <Typography>{locationName}</Typography>
          </DataBox>
          <DataBox>
            <DataTitle>Typ: </DataTitle>
            <Typography>{chosenLocation ? "Wybrana" : "Tworzona"}</Typography>
          </DataBox>
          <DataBox>
            <DataTitle>Osoba kontaktowa: </DataTitle>
            <Typography>{contactPerson}</Typography>
          </DataBox>
          <DataBox>
            <DataTitle>Numer kontaktowy: </DataTitle>
            <Typography>{contactPhone}</Typography>
          </DataBox>
          <SpecialDataBox>
            <DataTitle>Adres lokalizacji: </DataTitle>
            <Typography sx={{ wordWrap: "break-word" }}>
              {locationLinkedVersion
                ? locationLinkedVersion
                : `ul. ${locationStreet}, ${locationCity}, ${locationProvince}, ${locationCountry}, ${locationPostcode}`}
            </Typography>
          </SpecialDataBox>
        </InfoWrapper>
      )}
      {listOfActivePages.length > 3 && (
        <InfoWrapper>
          <DataBox>
            <DataTitle>Nazwa urządzenia: </DataTitle>
            <Typography>{deviceName}</Typography>
          </DataBox>
          <DataBox>
            <DataTitle>Nr seryjny: </DataTitle>
            <Typography>{serialNumber}</Typography>
          </DataBox>
          <DataBox>
            <DataTitle>Typ </DataTitle>
            <Typography>{type}</Typography>
          </DataBox>
          <DataBox>
            <DataTitle>Orientacja: </DataTitle>
            <Typography>{orientation}</Typography>
          </DataBox>
          <SpecialDataBox>
            <DataTitle>Opis: </DataTitle>
            <Typography>{deviceDescription}</Typography>
          </SpecialDataBox>
        </InfoWrapper>
      )}
      {listOfActivePages.length > 4 && (
        <>
          <DataBox>
            <DataTitle>Kod błędu: </DataTitle>
            <Typography>{errorCode.name}</Typography>
          </DataBox>
          <SpecialDataBox>
            <DataTitle>Szczegóły: </DataTitle>
            <Typography>{description}</Typography>
          </SpecialDataBox>
        </>
      )}
    </SectionWrapper>
  );
};

export default SummaryPage;
