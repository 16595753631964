export const dateFormatView = (date) => {
  return `${date.substring(8, 10)}.${date.substring(5, 7)}.${date.substring(
    0,4
  )} ${date.substring(11, 16)}`;
};

export const onlyDateFormatView = (date) => {
  return `${date.substring(8, 10)}.${date.substring(5, 7)}.${date.substring(
    0,4
  )}`;
};

function toTimestamp(strDate) {
  const datum = Date.parse(strDate);
  return datum / 1000;
}

export const sortDateToOldestBySendDate = (a, b) => {
  const formattedA = toTimestamp(a.sendDate);
  const formattedB = toTimestamp(b.sendDate);
  if (formattedA - formattedB > 0)
    return -1;
  if (formattedB - formattedA > 0)
    return 1;
  return 0;
};


export const sortDateToOldestByDeliveryDate = (a, b) => {
  const formattedA = toTimestamp(a.deliveredDate);
  const formattedB = toTimestamp(b.deliveredDate);
  if (formattedA - formattedB > 0)
    return -1;
  if (formattedB - formattedA > 0)
    return 1;
  return 0;
};

export const differenceBetweenDatesInDays = (youngerDate, olderDate) => {
  return Math.round((olderDate - youngerDate) / (24 * 60 * 60 * 1000));
};


export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {
    type: mime
  });
}


export const getErrorCode = (message) => {
  return message.toString().substring(message.length - 3);
};

export const setHeaderSize = (headers) => {
  let size = "";
  const maxWindowSize = 1920;
  const currentWindowSize = window.innerWidth;
  headers.forEach((header) => {
    const indexOfRem = header.columnSize.indexOf('rem');
    let valueOfHeader = header.columnSize;
    if (indexOfRem !== -1) {
      valueOfHeader = header.columnSize.substring(0, indexOfRem);
      valueOfHeader = (currentWindowSize / maxWindowSize) * (valueOfHeader * 1);
      valueOfHeader += "rem";
    }
    size += valueOfHeader + " ";
  });
  return size;
};


export const getLocalTimeDate = (date) => {
  return new Date(
      date -
      date.getTimezoneOffset() * 60 * 1000
    )
    .toISOString()
    .split("T")[0];
};