/* eslint-disable react/prop-types */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import React from "react";
import AcceptButton from "../AcceptButton/AcceptButton";
import CloseButton from "../CloseButton/CloseButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReturnConfirmationModal = ({ isOpen, handleClose, passFunction }) => {
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Czy aby na pewno chcesz kontynuować?"}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{ color: "red" }}
        >
          Twoje zmiany nie zostały zapisane!
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ gap: "0.5rem" }}>
        <AcceptButton text="Tak" onClick={passFunction} />
        <CloseButton text="Nie" onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};

export default ReturnConfirmationModal;
