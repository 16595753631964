/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import CommissionItemDesktop from "./CommissionItemVersions/CommissionItemDesktop";
import CommissionItemMobile from "./CommissionItemVersions/CommissionItemMobile";

const CommissionItem = ({ data, orderNumber }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <CommissionItemDesktop orderNumber={orderNumber} commission={data} />
      ) : (
        <CommissionItemMobile orderNumber={orderNumber} commission={data} />
      )}
    </>
  );
};

export default CommissionItem;
