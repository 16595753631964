/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { companiesListHeaders } from "../../../../static/tablesHeaders";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import { useNavigate } from "react-router-dom";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const CompanyItemDesktop = ({ orderNumber, company }) => {
  const navigate = useNavigate();
  const {
    id,
    name,
    address: { street, city, country, postcode, linkedVersion },
    assignedUser: { name: assignedUserName, surname: assignedUserSurname },
  } = company;

  const navigateToCompany = () => {
    localStorage.setItem("chosenCompanyId", id);
    navigate(`/companies/${id}`);
  };

  return (
    <TableDataGridWrapper headers={companiesListHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{`${id.substring(0, 6)}...`}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <Box>
        {linkedVersion ? (
          <CustomDataDisplay>{linkedVersion}</CustomDataDisplay>
        ) : (
          <>
            <CustomDataDisplay>{`ul. ${street}, ${city}`}</CustomDataDisplay>
            <CustomDataDisplay>{`${postcode}, ${country}`}</CustomDataDisplay>
          </>
        )}
      </Box>
      <CustomDataDisplay>
        {`${assignedUserName} ${assignedUserSurname}`}
      </CustomDataDisplay>
      <DetailsButton onClick={() => navigateToCompany()} width="80%" />
    </TableDataGridWrapper>
  );
};

export default CompanyItemDesktop;
