/* eslint-disable react/prop-types */
import React from "react";
import TableGridWrapper from "./TableGridWrapper";

const predefineTableHeaderProps = {
  borderTop: "0.125em solid #ccc",
  borderBottom: "0.125em solid #ccc",
};

const TableHeaderGridWrapper = ({ children, styles = {}, headers, gap }) => {
  return (
    <TableGridWrapper
      styles={{ ...predefineTableHeaderProps, ...styles }}
      headers={headers}
      gap={gap}
    >
      {children}
    </TableGridWrapper>
  );
};

export default TableHeaderGridWrapper;
