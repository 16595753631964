/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../../hooks/useMediaQ";
import LocationDevicesItemDesktop from "./LocationDevicesItemVersions/LocationDevicesItemDesktop";
import LocationDevicesItemMobile from "./LocationDevicesItemVersions/LocationDevicesItemMobile";

const LocationDevicesItem = ({ device, orderNumber }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <LocationDevicesItemDesktop device={device} orderNumber={orderNumber} />
      ) : (
        <LocationDevicesItemMobile device={device} />
      )}
    </>
  );
};

export default LocationDevicesItem;
