import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import dataService from '../dataService/dataService';
import {
  API
} from '../static/static';


export const getRequest = createAsyncThunk('request/get', async (givenRequestId, {
  getState
}) => {
  const {
    chosenRequestId: requestId
  } = getState().request;
  const resData = givenRequestId ? await dataService.get(`${API}request/${givenRequestId}`) : requestId ? await dataService.get(`${API}request/${requestId}`) : await dataService.get(`${API}request/${localStorage.getItem('chosenRequestId')}`);
  return resData;
});

export const getRequests = createAsyncThunk('requests/get', async () => {
  const resData = await dataService.get(`${API}requests`);
  return resData;
});

export const getPaginatedRequests = createAsyncThunk('requests/get/pagination', async (data) => {
  const {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    status,
    baseStorage,
    destinationStorage,
    sender,
    receiver,
    childRequest,
    parentRequest,
    isSeen,
    creationDateStart,
    creationDateEnd
  } = data;
  const resData = await dataService.get(`${API}requests/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&status=${status}&baseStorage=${baseStorage}&destinationStorage=${destinationStorage}&sender=${sender}&receiver=${receiver}&parentRequest=${parentRequest}&childRequest=${childRequest}&isSeen=${isSeen}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}`);
  return resData;
});

export const getRequestsCount = createAsyncThunk('requests/get/count', async (data) => {
  const {
    searchingValue,
    status,
    baseStorage,
    destinationStorage,
    sender,
    receiver,
    childRequest,
    parentRequest,
    isSeen,
    creationDateStart,
    creationDateEnd
  } = data;
  const resData = await dataService.get(`${API}requests/count/?searchingPhrase=${searchingValue}&status=${status}&baseStorage=${baseStorage}&destinationStorage=${destinationStorage}&sender=${sender}&receiver=${receiver}&parentRequest=${parentRequest}&childRequest=${childRequest}&isSeen=${isSeen}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}`);
  return resData;
});

export const createRequest = createAsyncThunk('request/create', async (data) => {
  const resData = await dataService.post(`${API}request/`, data);
  return resData;
});

export const updateRequest = createAsyncThunk('request/update', async (data) => {
  const resData = await dataService.put(`${API}request/u/${data.requestId}/`, data);
  return resData;
});

export const deleteRequest = createAsyncThunk('request/delete', async (requestId) => {
  const resData = await dataService.delete(`${API}request/d/${requestId}/`);
  return resData;
});

export const responseRequest = createAsyncThunk('request/response', async ({
  requestId,
  data
}) => {
  const resData = await dataService.put(`${API}request/response/${requestId}/`, data);
  return resData;
});

export const changeRequestStatus = createAsyncThunk('request/status/change', async ({
  requestId,
  data
}) => {
  const storedRequestId = localStorage.getItem("chosenRequestId");
  const resData = requestId ? await dataService.put(`${API}request/change-status/${requestId}/`, data) : await dataService.put(`${API}request/change-status/${storedRequestId}/`, data);
  return resData;
});

export const setRequestSeen = createAsyncThunk('request/seen/change', async ({
  requestId
}) => {
  const resData = await dataService.put(`${API}request/change-isSeen/${requestId}/`, {});
  return resData;
});

const initialState = {
  requests: {
    all: null,
    chosen: null
  },
  requestsCount: 0,
  chosenRequestId: null,
  isRequestLoading: false
};

export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    clearRequest: (state) => {
      state.requests.chosen = null;
      state.chosenRequestId = null;
    },
    setChosenRequestId: (state, action) => {
      localStorage.setItem('chosenRequestId', action.payload);
      state.chosenRequestId = action.payload;
      state.requests.chosen = null;
    },
    setRequestLoading: (state, action) => {
      state.isRequestLoading = action.payload;
    },
    clearRequests: (state) => {
      state.requests = {
        all: null,
        chosen: null
      };
      state.requestsCount = 0;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequest.fulfilled, (state, action) => {
        state.requests.chosen = action.payload.data;
      })
      // .addCase(getRequest.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getRequests.fulfilled, (state, action) => {
        state.requests.all = action.payload.data;
      })
      // .addCase(getRequests.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getPaginatedRequests.fulfilled, (state, action) => {
        state.requests.all = action.payload.data;
      })
      // .addCase(getPaginatedRequests.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getRequestsCount.fulfilled, (state, action) => {
        state.requestsCount = action.payload.data;
      })
      // .addCase(getRequestsCount.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(createRequest.fulfilled, () => {})
      // .addCase(createRequest.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(updateRequest.fulfilled, () => {})
      // .addCase(updateRequest.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(deleteRequest.fulfilled, () => {})
      // .addCase(deleteRequest.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(responseRequest.fulfilled, () => {})
      // .addCase(responseRequest.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(changeRequestStatus.fulfilled, () => {});
    // .addCase(changeRequestStatus.rejected, (_, action) => {
    //   alert(`Operation failed - ${action.error.message}`);
    // });
  }
});

export const {
  clearRequest,
  setChosenRequestId,
  setRequestLoading,
  clearRequests
} = requestSlice.actions;

export default requestSlice.reducer;