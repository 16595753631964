/* eslint-disable react/prop-types */
import React from "react";
import { Edit } from "@mui/icons-material";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomButton = styled(Button)(({ theme }) => ({
  width: "auto",
  backgroundColor: theme.palette.secondary.main,
  fontSize: "0.75rem",
  height: "1.8125rem",
  "&:hover": {
    backgroundColor: "#434544",
  },
  [theme.breakpoints.down("desktop")]: {
    fontSize: "0.6rem",
  },
}));

const EditButton = ({
  onClick,
  startIcon = <Edit />,
  text = "edytuj",
  styles: stylesProps,
  disabled = false,
}) => {
  return (
    <CustomButton
      onClick={() => onClick()}
      variant="contained"
      sx={stylesProps}
      size="small"
      startIcon={startIcon}
      disabled={disabled}
    >
      {text}
    </CustomButton>
  );
};

export default EditButton;
