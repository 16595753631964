import {
  createTheme
}
  from "@mui/material/styles";

const Colors = {
  primary: "rgb(27,150,250)",
  secondary: "#827d7d",
  pageBackgroundColor: "#e5e5e5"
};

export const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1500,
    },
  },
  palette: {
    primary: {
      main: Colors.primary
    },
    secondary: {
      main: Colors.secondary
    },
    pageBackgroundColor: {
      main: Colors.pageBackgroundColor
    }
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            "&:-webkit-autofill": {
              // WebkitBoxShadow: "0 0 0 100px #000 inset",
              WebkitTextFillColor: "white",
              WebkitBackgroundClip: 'text'
            },
          },
        },
      },
    },
  },
});