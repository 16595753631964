/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { taskListHeaders } from "../../../../static/tablesHeaders";
import CustomChip from "../../../CustomChip/CustomChip";
import { setChosenTaskId } from "../../../../slices/taskSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dateFormatView } from "../../../../static/functions";
import { taskStatus, taskStatusChipStyles } from "../../../../static/static";
import TaskExecution from "../../../TaskView/TaskExecution/TaskExecution";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: 'wrap',
  gap: "0.1rem 0.8rem",
  justifyContent: 'center',
}));

const TaskListItemDesktop = ({ orderNumber, task, setRefresh, chosenUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: taskId, location, creationDate, status } = task;

  const taskDetails = () => {
    dispatch(setChosenTaskId(taskId));
    navigate(`/tasks/${taskId}`);
  };

  return (
    <TableDataGridWrapper headers={taskListHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{location.code}</CustomDataDisplay>
      <CustomDataDisplay>{dateFormatView(creationDate)}</CustomDataDisplay>
      <CustomChip
        label={taskStatus[status]}
        sx={taskStatusChipStyles(status, "")}
      />
      <ButtonWrapper>
        <DetailsButton onClick={() => taskDetails()}/>
        <TaskExecution task={task} setRefresh={setRefresh} chosenUser={chosenUser}/>
      </ButtonWrapper>
    </TableDataGridWrapper>
  );
};

export default TaskListItemDesktop;
