/* eslint-disable react/prop-types */
import React from "react";
import CompactDataDisplay from "../../../../CompactDataDisplay/CompactDataDisplay";
import { dateFormatView } from "../../../../../static/functions";
import {
  requestStatus,
  requestStatusChipStyles,
} from "../../../../../static/static";
import CustomChip from "../../../../CustomChip/CustomChip";
import DetailsButton from "../../../../DetailsButton/DetailsButton";
import { useSelector, useDispatch } from "react-redux";
import {
  setChosenRequestId,
  setRequestSeen,
} from "../../../../../slices/requestSlice";
import { useNavigate } from "react-router-dom";

const RequestSummaryItemMobile = ({ request }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    id,
    creationDate,
    topic,
    baseStorage: { name: storageName },
    sender: { name: senderName, surname: senderSurname },
    status,
  } = request;
  const {
    userData: { role },
  } = useSelector((slice) => slice.auth);
  const data = [
    { title: "Data utworzenia:", value: dateFormatView(creationDate) },
    { title: "Temat:", value: topic },
    { title: "Nazwa magazynu nadawczego:", value: storageName },
    { title: "Dane nadawcy:", value: `${senderName} ${senderSurname}` },
    {
      title: "Status:",
      value: (
        <CustomChip
          label={requestStatus[status]}
          sx={requestStatusChipStyles(status, "")}
        />
      ),
    },
  ];
  return (
    <CompactDataDisplay
      title={`Zapotrzebowanie #${id}`}
      data={data}
      actions={
        <>
          <DetailsButton
            onClick={async () => {
              if (role === "service_technician" && status !== "sent")
                await dispatch(setRequestSeen({ requestId: id }));
              dispatch(setChosenRequestId(id));
              navigate(`/requests/${id}`);
            }}
          />
        </>
      }
    />
  );
};

export default RequestSummaryItemMobile;
