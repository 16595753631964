/* eslint-disable react/prop-types */
import React from "react";
import { Chip, Typography } from "@mui/material";
import { dateFormatView } from "../../../static/functions";
import PageHeader from "../../PageHeader/PageHeader";
import { Article } from "@mui/icons-material";
import {
  taskStatus,
  taskStatusChipStyles,
  taskType,
  taskTypeChipStyles,
} from "../../../static/static";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
  SpecialDataBox,
} from "../../ObjectDetailsElements/ObjectDetailsElements";
import CustomChip from "../../CustomChip/CustomChip";

const TaskViewDetails = ({ chosenTask }) => {

  return (
    <SectionWrapper>
      <PageHeader size="medium" titleText="Dane zadania" icon={<Article />} />
      <InfoWrapper>
        <DataBox>
          <DataTitle>ID:</DataTitle>
          <Typography>{chosenTask.id}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Kod RT:</DataTitle>
          <Typography>{chosenTask.location.code}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Typ:</DataTitle>
          <Chip
            label={taskType[chosenTask.type]}
            sx={taskTypeChipStyles(chosenTask.type, "")}
          />
        </DataBox>
        <DataBox>
          <DataTitle>Status:</DataTitle>
          <CustomChip
            label={taskStatus[chosenTask.status]}
            sx={taskStatusChipStyles(chosenTask.status, "")}
            width="40%"
          />
        </DataBox>
        <DataBox>
          <DataTitle>ID zlecenia:</DataTitle>
          <Typography>{chosenTask.commission.id}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Data utworzenia:</DataTitle>
          <Typography>
            {chosenTask.creationDate
              ? dateFormatView(chosenTask.creationDate)
              : "Brak"}
          </Typography>
        </DataBox>
        {chosenTask.type === "Work" &&
          (chosenTask.status === "Created" ||
            chosenTask.status === "In progress") && (
            <DataBox>
              <DataTitle>Planowana data realizacji:</DataTitle>
              <Typography>
                {chosenTask.planningExecutionDate
                  ? dateFormatView(chosenTask.planningExecutionDate)
                  : "Brak"}
              </Typography>
            </DataBox>
          )}
        {chosenTask.status === "Closed" && (
          <DataBox>
            <DataTitle>Data wykonania:</DataTitle>
            <Typography>
              {chosenTask.executionDate
                ? dateFormatView(chosenTask.executionDate)
                : "Brak"}
            </Typography>
          </DataBox>
        )}
        {chosenTask.proposedExecutor !== null && (
          <DataBox>
            <DataTitle>Proponowany wykonawca:</DataTitle>
            <Typography>
              {`${chosenTask.proposedExecutor.name} ${chosenTask.proposedExecutor.surname}`}
            </Typography>
          </DataBox>
        )}
        {chosenTask.assignedExecutor !== null && (
          <DataBox>
            <DataTitle>Wykonawca:</DataTitle>
            <Typography>
              {`${chosenTask.assignedExecutor.name} ${chosenTask.assignedExecutor.surname}`}
            </Typography>
          </DataBox>
        )}
        <DataBox>
          <DataTitle>Kod błędu:</DataTitle>
          <Typography>
            {chosenTask.commission.openCode !== null
              ? `[${chosenTask.commission.openCode.id}] ${chosenTask.commission.openCode.name}`
              : "Brak"}
          </Typography>
        </DataBox>
        <SpecialDataBox>
          <DataTitle>Powód zgłoszenia zlecenia:</DataTitle>
          <Typography>{chosenTask.commission.faultDescription}</Typography>
        </SpecialDataBox>
        <SpecialDataBox>
          {chosenTask.type !== "Work" && <DataTitle>Opis decyzji:</DataTitle>}
          {chosenTask.type === "Work" && (
            <DataTitle>Opis wykonania zadania:</DataTitle>
          )}
          <Typography sx={{ textAlign: "justify", textJustify: "inter-word" }}>
            {chosenTask.description ? chosenTask.description : "Brak"}
          </Typography>
        </SpecialDataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default TaskViewDetails;
