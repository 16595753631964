/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Add, Clear, Edit, KeyboardArrowLeft } from "@mui/icons-material";
import PageHeader from "../../PageHeader/PageHeader";
import SearchDropdown from "../../SearchDropdown/SearchDropdown";
import { getPaginatedCategories } from "../../../slices/categorySlice";
import { getPaginatedProducers } from "../../../slices/producerSlice";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import DetailsButton2 from "../../DetailsButton/DetailsButton";
import EditButton from "../../EditButton/EditButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createProduct,
  getProduct,
  updateProduct,
} from "../../../slices/productSlice";
import Loading from "../../Loading/Loading";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../CustomTextField/CustomTextField";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "0.5rem",
  width: "33rem",
  margin: "0 auto",
  [theme.breakpoints.down("tablet")]: {
    width: "16rem",
  },
}));

const InnerWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "90%",
  gap: "0.5rem",
}));

const ProductForm = ({ type = "add", closeAddForm, setRefresh }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    name: "",
    type: "",
    category: "",
    producer: "",
    partNumber: "",
    description: "",
  });
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [buttonLock, setButtonLock] = useState(false);

  const { chosenProduct } = useSelector((slice) => slice.product);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getProduct());
    setIsDataLoading(false);
  }, []);

  const isFormDataCompleted = () => {
    return !(
      !formValues.name ||
      !formValues.type ||
      !formValues.category ||
      !formValues.producer ||
      !formValues.partNumber
    );
  };

  useEffect(() => {
    if (type === "edit") getData();
  }, [getData]);

  useEffect(() => {
    if (type === "edit" && chosenProduct !== null)
      setFormValues({
        name: chosenProduct.name,
        type: chosenProduct.type,
        category: chosenProduct.category ? chosenProduct.category.id : "",
        producer: chosenProduct.producer ? chosenProduct.producer.id : "",
        partNumber: chosenProduct.partNumber,
        description: chosenProduct.description,
      });
  }, [chosenProduct]);

  const addInnerProduct = useCallback(async () => {
    await dispatch(createProduct(formValues));
    closeAddForm();
    setRefresh();
  }, [formValues]);

  const editInnerProduct = useCallback(async () => {
    await dispatch(updateProduct(formValues));
    navigate("/products");
  }, [formValues]);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (keyCode === 13 && !buttonLock && isFormDataCompleted()) {
        if (type === "add") addInnerProduct();
        if (type === "edit") editInnerProduct();
        setButtonLock(true);
        setTimeout(() => setButtonLock(false), 5000);
      }
    },
    [buttonLock, addInnerProduct, editInnerProduct]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  if (isDataLoading) return <Loading />;

  return (
    <Wrapper>
      {type === "add" && (
        <PageHeader
          titleText="Dodawanie produktu"
          icon={<Add />}
          styleProps={{ margin: "0 1rem" }}
          onRightComponent={
            <DetailsButton
              startIcon={<Clear />}
              text="Zamknij"
              onClick={() => closeAddForm()}
            />
          }
        />
      )}
      {type === "edit" && (
        <PageHeader
          titleText="Edytowanie produktu"
          icon={<Edit />}
          styleProps={{ margin: "0 1rem" }}
          onRightComponent={
            <DetailsButton
              startIcon={<KeyboardArrowLeft />}
              text="Powrót"
              onClick={() => navigate(-1)}
            />
          }
        />
      )}
      <Typography
        sx={{ color: "red", fontSize: "0.8rem", marginTop: "0.5rem" }}
      >
        *Pola obowiązkowe
      </Typography>
      <InnerWrapper>
        <CustomTextField
          label="Nazwa*"
          type="text"
          value={formValues.name}
          onChange={(e) =>
            setFormValues((state) => {
              return { ...state, name: e.target.value };
            })
          }
          width="100%"
          maxLength={50}
        />
        <CustomTextField
          value={formValues.type}
          onChange={(e) =>
            setFormValues((state) => {
              return { ...state, type: e.target.value };
            })
          }
          width="100%"
          disabled={type === "edit"}
          select
          label="Typ produktu*"
          counter={false}
        >
          <MenuItem value="Quantitative">Jakościowy</MenuItem>
          <MenuItem value="Numerical">Ilościowy</MenuItem>
        </CustomTextField>
        <SearchDropdown
          collectionName="categories"
          sliceName="category"
          getDataFunction={getPaginatedCategories}
          placeholder="Kategoria*"
          propertyToDisplay="name"
          setFunction={(item) =>
            setFormValues((state) => {
              return {
                ...state,
                category: item.id,
              };
            })
          }
          outerValue={
            chosenProduct
              ? chosenProduct.category
                ? chosenProduct.category.name
                : ""
              : ""
          }
          isSearchingValueObjectProps={true}
        />
        <SearchDropdown
          collectionName="producers"
          sliceName="producer"
          getDataFunction={getPaginatedProducers}
          placeholder="Producent*"
          propertyToDisplay="name"
          setFunction={(item) =>
            setFormValues((state) => {
              return {
                ...state,
                producer: item.id,
              };
            })
          }
          outerValue={
            chosenProduct &&
            chosenProduct.producer &&
            chosenProduct.producer.name
          }
          isSearchingValueObjectProps={true}
        />
        <CustomTextField
          label="Part Number*"
          value={formValues.partNumber}
          onChange={(e) =>
            setFormValues((state) => {
              return { ...state, partNumber: e.target.value };
            })
          }
          width="100%"
          maxLength={50}
        />
        <CustomTextField
          label="Opis"
          multiline
          rows={4}
          value={formValues.description}
          onChange={(e) =>
            setFormValues((state) => {
              return { ...state, description: e.target.value };
            })
          }
          width="100%"
          maxLength={1000}
        />
        {type === "add" && (
          <DetailsButton2
            startIcon={<Add />}
            text="Dodaj produkt"
            onClick={() => addInnerProduct()}
            disabled={buttonLock || !isFormDataCompleted()}
          />
        )}
        {type === "edit" && (
          <EditButton
            text="Edytuj produkt"
            onClick={() => editInnerProduct()}
            disabled={buttonLock || !isFormDataCompleted()}
          />
        )}
      </InnerWrapper>
    </Wrapper>
  );
};

export default ProductForm;
