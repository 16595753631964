/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChoosingButton from "../../ChoosingButton/ChoosingButton";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTasksQuantity,
  getTasksKindQuantity,
} from "../../../slices/taskSlice";
import Loading from "../../Loading/Loading";
import { defaultPermissions } from "../../../static/static";
const ButtonWrapper = styled(Box)(() => ({
  width: "100%",
  margin: "0 auto",
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
  justifyContent: "center",
  alignItems: "center",
}));

const ChooseTaskKindSelection = ({
  setCurrentTaskType,
  setFilteredValue,
  chosenUser,
  refresh,
  setRefresh,
}) => {
  const dispatch = useDispatch();
  const { tasksKindQuantity } = useSelector((slice) => slice.task);
  const {
    userData: { user_id: userId },
  } = useSelector((slice) => slice.auth);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const choosingButtonsSet = [
    {
      name: "Kwalifikacyjne",
      type: "qualificationTasksQuantity",
      permissions: [defaultPermissions.service["Qualification Tasks View"]],
      onClick: () => {
        setCurrentTaskType("Qualification");
        setFilteredValue(["Created", "In progress"]);
        setRefresh((state) => !state);
      },
    },
    {
      name: "Przypisania",
      type: "assignmentTasksQuantity",
      permissions: [defaultPermissions.service["Assignment Tasks View"]],
      onClick: () => {
        setCurrentTaskType("Assignment");
        setFilteredValue(["Created", "In progress"]);
        setRefresh((state) => !state);
      },
    },
    {
      name: "Do akceptacji",
      type: "approvalTasksQuantity",
      permissions: [],
      onClick: () => {
        setCurrentTaskType("Approval");
        setFilteredValue(["Created", "In progress"]);
        setRefresh((state) => !state);
      },
    },
    {
      name: "Wykonawcze",
      type: "workTasksQuantity",
      permissions: [],
      onClick: () => {
        setCurrentTaskType("Work");
        setFilteredValue(["Created", "In progress"]);
        setRefresh((state) => !state);
      },
    },
    {
      name: "Weryfikacyjne",
      type: "verificationTasksQuantity",
      permissions: [defaultPermissions.service["Verification Tasks View"]],
      onClick: () => {
        setCurrentTaskType("Verification");
        setFilteredValue(["Created", "In progress"]);
        setRefresh((state) => !state);
      },
    },
  ];

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getTasksKindQuantity(userId));
    setIsDataLoading(false);
  }, [refresh]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => {
      dispatch(clearTasksQuantity());
    };
  }, []);

  if (isDataLoading) return <Loading size="custom" height="5vh" />;

  return (
    <ButtonWrapper>
      {tasksKindQuantity !== null &&
        choosingButtonsSet
          .filter(({ permissions }) => {
            let flag = true;
            permissions.forEach((permission) => {
              const findingIndex =
                chosenUser !== null &&
                chosenUser.permissions.findIndex(
                  (userPermission) => userPermission.name === permission
                );
              if (findingIndex === -1 && chosenUser.role !== "admin")
                flag = false;
            });
            return flag;
          })
          .map(({ name, type, onClick }, index) => (
            <ChoosingButton
              key={index}
              onClick={onClick}
              name={name}
              tasksKindQuantity={tasksKindQuantity[type]}
            />
          ))}
    </ButtonWrapper>
  );
};

export default ChooseTaskKindSelection;
