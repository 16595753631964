export const ChooseCompanyPageClasses = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  titleProps: {
    fontSize: "1.4rem",
    marginBottom: "1rem",
    fontWeight: 700,
  }
};