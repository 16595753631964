import {
  createSlice
} from '@reduxjs/toolkit';

const initialState = {
  isMenuVisible: true
};

export const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    openMenu: (state) => {
      state.isMenuVisible = true;
    },
    closeMenu: (state) => {
      state.isMenuVisible = false;
    }
  },
});

export const {
  openMenu, closeMenu
} = mediaSlice.actions;

export default mediaSlice.reducer;