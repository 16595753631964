/* eslint-disable react/prop-types */
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dateFormatView } from "../../../../static/functions";
import CustomChip from "../../../CustomChip/CustomChip";
import { taskStatus, taskStatusChipStyles } from "../../../../static/static";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import { setChosenTaskId } from "../../../../slices/taskSlice";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import TaskExecution from "../../../TaskView/TaskExecution/TaskExecution";

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.8rem",
  justifyContent: "center",
}));

const TaskListItemMobile = ({ task, setRefresh, chosenUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: taskId, location, creationDate, status } = task;

  const data = [
    { title: "Data utworzenia:", value: dateFormatView(creationDate) },
    { title: "Kod RT:", value: location.code },
    {
      title: "Status:",
      value: (
        <CustomChip
          label={taskStatus[status]}
          sx={taskStatusChipStyles(status, "")}
          width="35%"
        />
      ),
    },
  ];

  const taskDetails = () => {
    dispatch(setChosenTaskId(taskId));
    navigate(`/tasks/${taskId}`);
  };

  return (
    <CompactDataDisplay
      title={`Zadanie #${taskId}`}
      data={data}
      actions={
        <ButtonWrapper>
          <DetailsButton onClick={() => taskDetails()} />
          <TaskExecution
            task={task}
            setRefresh={setRefresh}
            isMobileViewDisabled={true}
            chosenUser={chosenUser}
          />
        </ButtonWrapper>
      }
    />
  );
};

export default TaskListItemMobile;
