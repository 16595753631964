import React, { useState, useEffect, useCallback } from "react";
import { Devices } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDevice, setChosenDeviceId } from "../../slices/deviceSlice";
import Loading from "../Loading/Loading";
import { useNavigate } from "react-router-dom";
import DeviceDetails from "./DeviceDetails/DeviceDetails";
import BlankSpace from "../BlankSpace/BlankSpace";
import PageHeader from "../PageHeader/PageHeader";
import RefreshButton from "../RefreshButton/RefreshButton";
import { EditButton } from "../FunctionalButtons/FunctionalButtons";
import PageWrapper from "../PageWrapper/PageWrapper";
import ReturnButton from "../ReturnButton/ReturnButton";

const Device = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { chosenDevice } = useSelector((slice) => slice.device);
  const { deviceId } = useParams();

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getDevice(deviceId));
    setIsDataLoading(false);
  }, [deviceId, refresh]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (isDataLoading) return <Loading />;

  return (
    <>
      {chosenDevice !== null && (
        <PageWrapper width="80%">
          <PageHeader
            titleText="Urządzenie"
            icon={<Devices />}
            onRightComponent={<ReturnButton />}
          >
            <RefreshButton onClick={() => setRefresh((state) => !state)} />
            <EditButton
              onClick={() => {
                dispatch(setChosenDeviceId(chosenDevice.id));
                navigate("/device-edit");
              }}
            />
          </PageHeader>
          <BlankSpace />
          <DeviceDetails chosenDevice={chosenDevice} />
        </PageWrapper>
      )}
    </>
  );
};

export default Device;
