/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { dateFormatView } from "../../../../static/functions";
import PageHeader from "../../../PageHeader/PageHeader";
import { Article } from "@mui/icons-material";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
} from "../../../ObjectDetailsElements/ObjectDetailsElements";
import GeoLocationIcon from "../../../GeoLocation/GeoLocationIcon";

const CompanyDetails = ({ chosenCompany }) => {
  return (
    <SectionWrapper>
      <PageHeader titleText="Dane" icon={<Article />} size="medium" />
      <InfoWrapper>
        <DataBox>
          <DataTitle>Nazwa</DataTitle>
          <Typography>{chosenCompany.name}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Przypisany pracownik</DataTitle>
          <Typography>
            {`
                  ${chosenCompany.assignedUser.name} 
                  ${chosenCompany.assignedUser.surname}
                  `}
          </Typography>
        </DataBox>
        <DataBox>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DataTitle>Adres:</DataTitle>
            <GeoLocationIcon
              query={
                chosenCompany.address.linkedVersion
                  ? chosenCompany.address.linkedVersion
                  : `${chosenCompany.address.street}, ${chosenCompany.address.city}, ${chosenCompany.address.postcode}, ${chosenCompany.address.country}`
              }
            />
          </Box>
          <Typography>
            {chosenCompany.address.linkedVersion
              ? chosenCompany.address.linkedVersion
              : `ul. ${chosenCompany.address.street}, ${chosenCompany.address.city}, ${chosenCompany.address.postcode}, ${chosenCompany.address.country}`}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Strefa czasowa</DataTitle>
          <Typography>{chosenCompany.timezone}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle> Utworzył</DataTitle>
          <Typography>
            {`
                  ${
                    chosenCompany.createdBy
                      ? chosenCompany.createdBy.name
                        ? chosenCompany.createdBy.name
                        : ""
                      : ""
                  } 
                  ${
                    chosenCompany.createdBy
                      ? chosenCompany.createdBy.surname
                        ? chosenCompany.createdBy.surname
                        : ""
                      : ""
                  }
                  `}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Utworzone</DataTitle>
          <Typography>{dateFormatView(chosenCompany.createdAt)}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Ostatnio modyfikowane</DataTitle>
          <Typography>{dateFormatView(chosenCompany.lastModified)}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Osoba kontaktowa</DataTitle>
          <Typography>{chosenCompany.contactPerson}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Nr telefonu</DataTitle>
          <Typography>{chosenCompany.phoneNumber}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Email</DataTitle>
          <Typography>{chosenCompany.email}</Typography>
        </DataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default CompanyDetails;
