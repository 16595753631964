/* eslint-disable react/prop-types */
import React from "react";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";
import { TextField } from "@mui/material";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import CustomChip from "../../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../../static/static";

const MoveUnitItemMobile = ({
  unit,
  chosenQuantity,
  changeQuantity,
  isAdded,
  buttonLock,
  addItem,
}) => {
  const {
    id,
    product: { name, partNumber, category, producer },
    serialNumber,
    status,
    quantity,
  } = unit;

  const data = [
    { title: "Part number:", value: partNumber },
    { title: "Nazwa:", value: name },
    {
      title: "Nr seryjny:",
      value: serialNumber,
    },
    {
      title: "Kategoria:",
      value: category ? category.name : "",
    },
    {
      title: "Producent:",
      value: producer ? producer.name : "",
    },
    {
      title: "Status:",
      value: (
        <CustomChip label={status} sx={unitStatusChipStyles(status, "")} />
      ),
    },
    {
      title: "Liczba:",
      value: quantity,
    },
    {
      title: "Wybrana liczba:",
      value: (
        <TextField
          value={chosenQuantity}
          type="number"
          InputProps={{
            inputProps: {
              min: 0,
              max: quantity,
              style: {
                textAlign: "center",
              },
            },
          }}
          onChange={(e) => changeQuantity(e.target.value)}
          disabled={isAdded}
          variant="standard"
          sx={{ width: "4.375em", padding: 0 }}
        />
      ),
    },
  ];
  return (
    <CompactDataDisplay
      title={`Produkt #${id}`}
      data={data}
      actions={
        <>
          <DetailsButton
            text="+"
            startIcon={<></>}
            disabled={buttonLock || isAdded}
            onClick={() => addItem()}
            styles={{}}
          />
        </>
      }
    />
  );
};

export default MoveUnitItemMobile;
