/* eslint-disable react/prop-types */
import React, { useState } from "react";
import EditButton from "../../EditButton/EditButton";
import EditUnitForm from "./EditUnitForm";

const EditUnit = ({ unitId, setRefresh}) => {
  const [isUnitEditModalOpen, setIsUnitEditModalOpen] = useState(false);
  return (
    <>
      <EditButton onClick={() => setIsUnitEditModalOpen(true)} />
      {isUnitEditModalOpen && (
        <EditUnitForm
          unitId={unitId}
          isUnitEditModalOpen={isUnitEditModalOpen}
          setIsUnitEditModalOpen={setIsUnitEditModalOpen}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default EditUnit;
