/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Business, DomainAdd, Warning } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Pagination from "../../Pagination/Pagination";
import CompanyItem from "../CompaniesListItem/CompanyItem";
import {
  clearCompaniesData,
  getCompaniesCount,
  getPaginatedCompanies,
} from "../../../slices/companySlice";
import Loading from "../../Loading/Loading";
import PageHeader from "../../PageHeader/PageHeader";
import RefreshButton from "../../RefreshButton/RefreshButton";
import BlankSpace from "../../BlankSpace/BlankSpace";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import TableHeader from "../../TableHeader/TableHeader";
import { companiesListHeaders } from "../../../static/tablesHeaders";
import EmptyListError from "../../EmptyListError/EmptyListError";
import PageWrapper from "../../PageWrapper/PageWrapper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import useMediaQ from "../../../hooks/useMediaQ";
import MobileDataSorter from "../../MobileDataSorter/MobileDataSorter";
import TableWrapper from "../../TableWrapper/TableWrapper";
import usePagination from "../../../hooks/usePagination";
import ButtonWrapper from "../../ButtonWrapper/ButtonWrapper";
import ReturnButton from "../../ReturnButton/ReturnButton";

const CompaniesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchingRef = useRef("");
  const {
    refresh,
    setRefresh,
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("name", "ascending");
  const {
    userData: { user_id, role },
  } = useSelector((state) => state.auth);
  const { isPhoneDisplay, isTabletDisplay } = useMediaQ();

  const { companies, companiesCount } = useSelector((state) => state.company);

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getCompaniesCount({
        searchingValue,
        // assignedUser: role === "service_technician" ? user_id : "",
        assignedUser: "",
        createdBy: "",
      })
    );
    setIsDataLoading(false);
  }, [searchingValue, refresh]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedCompanies({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        // assignedUser: role === "service_technician" ? user_id : "",
        assignedUser: "",
        createdBy: "",
      })
    );
    setIsDataLoading(false);
  }, [leftIndex, rightIndex, sorter, sorting, searchingValue, refresh]);

  useEffect(() => {
    loadDataCount();
  }, [loadDataCount]);
  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearCompaniesData());
  }, []);

  return (
    <PageWrapper
      width={isPhoneDisplay ? "90%" : isTabletDisplay ? "80%" : "70%"}
    >
      <PageHeader
        titleText="Firmy"
        icon={<Business />}
        onRightComponent={
          <ButtonWrapper>
            <DetailsButton
              onClick={() => navigate("/company-add")}
              text="Dodaj firmę"
              startIcon={<DomainAdd />}
            />
            <ReturnButton />
          </ButtonWrapper>
        }
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <BlankSpace />
      <SectionWrapper>
        <AdditionalFunctionalitiesWrapper>
          <CustomSearchField
            displaySearchingValue={displaySearchingValue}
            setDisplaySearchingValue={setDisplaySearchingValue}
            searchingValue={searchingValue}
            setSearchingValue={setSearchingValue}
            searchingRef={searchingRef}
            placeholder="szukaj firmy..."
            width="30%"
          />
          <MobileDataSorter
            tableHeader={companiesListHeaders}
            setOuterSorter={setSorter}
            setOuterSorting={setSorting}
            defaultSorter="id"
          />
        </AdditionalFunctionalitiesWrapper>
        <TableWrapper>
          <TableHeader
            headers={companiesListHeaders}
            setSorter={setSorter}
            setSorting={setSorting}
            gap="1%"
          />
          <Pagination
            collectionSize={companiesCount}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            setLeftIndex={setLeftIndex}
            setRightIndex={setRightIndex}
          >
            {isDataLoading ? (
              <Loading />
            ) : (
              <>
                {companies !== null &&
                  companies.map((company, id) => (
                    <CompanyItem
                      key={company.id}
                      company={company}
                      orderNumber={leftIndex + id + 1}
                    />
                  ))}
              </>
            )}
            {companies !== null && companies.length === 0 && (
              <EmptyListError message="Brak firm" icon={<Warning />} />
            )}
          </Pagination>
        </TableWrapper>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default CompaniesList;
