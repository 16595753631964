/* eslint-disable react/prop-types */
import React from "react";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import PageHeader from "../../PageHeader/PageHeader";
import { Airplay } from "@mui/icons-material";
import { DataBox, DataTitle, InfoWrapper } from "../../ObjectDetailsElements/ObjectDetailsElements";
import { Typography } from "@mui/material";
import { dateFormatView } from "../../../static/functions";

const DeviceDetails = ({ device, company }) => {
  const {
    name,
    createdAt,
    type: deviceType,
    description: deviceDescription,
    serialNumber: deviceSerialNumber,
    orientation: deviceOrientation,
    subType: deviceSubType,
    location: {
      code: locationCode,
      name: locationName,
      contactPhone: locationContactPhone,
      contactPerson: locationContactPerson,
      address: {
        street: locationStreet,
        city: locationCity,
        province: locationProvince,
        country: locationCountry,
        postcode: locationPostcode,
      },
    },
  } = device;

  return (
    <SectionWrapper>
      <PageHeader
        icon={<Airplay />}
        titleText="Dane urządzenia"
        size="medium"
      />
      <InfoWrapper>
        <DataBox>
          <DataTitle>Kod lokalizacji:</DataTitle>
          <Typography>{locationCode}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Nazwa:</DataTitle>
          <Typography>{name}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Data utworzenia:</DataTitle>
          <Typography>{name}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Data utworzenia:</DataTitle>
          <Typography>
            {createdAt ? dateFormatView(createdAt) : "Brak"}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Firma:</DataTitle>
          <Typography>{company.name}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Typ urządzenia:</DataTitle>
          <Typography>{deviceType}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Opis urządzenia:</DataTitle>
          <Typography>{deviceDescription}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Nr seryjny:</DataTitle>
          <Typography>{deviceSerialNumber}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Orientacja:</DataTitle>
          <Typography>{deviceOrientation}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Podtyp:</DataTitle>
          <Typography>{deviceSubType}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Nazwa lokalizacji:</DataTitle>
          <Typography>{locationName}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Ulica:</DataTitle>
          <Typography>{locationStreet}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Miasto:</DataTitle>
          <Typography>{locationCity}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Kraj:</DataTitle>
          <Typography>{locationCountry}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Kod pocztowy:</DataTitle>
          <Typography>{locationPostcode}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Osoba kontaktowa:</DataTitle>
          <Typography>{locationContactPerson}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Tel. kontaktowy:</DataTitle>
          <Typography>{locationContactPhone}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Województwo:</DataTitle>
          <Typography>{locationProvince}</Typography>
        </DataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default DeviceDetails;
