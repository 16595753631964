/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { Send } from "@mui/icons-material";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import { styled } from "@mui/material/styles";

const CustomWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const CustomTitle = styled(Typography)(() => ({
  fontSize: "1.4rem",
  fontWeight: 700,
}));

const CustomWarning = styled(Typography)(() => ({
  color: "red",
  fontWeight: 700,
}));

const LastPage = ({ addCommission }) => {
  return (
    <CustomWrapper>
      <CustomTitle>5. Podsumowanie</CustomTitle>
      <CustomWarning>
        Upewnij się, że wszystkie wprowadzone dane są poprawne.
      </CustomWarning>
      <CustomWarning sx={{ fontWeight: 700 }}>
        Po zatwierdzeniu formularza nie będzie możliwości edycji danych.
      </CustomWarning>
      <DetailsButton
        onClick={() => addCommission()}
        text="Utwórz"
        startIcon={<Send />}
      />
    </CustomWrapper>
  );
};

export default LastPage;
