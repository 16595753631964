/* eslint-disable react/prop-types */
import React from "react";
import { styled } from "@mui/material/styles";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import { workModalMountedUnitsHeaders } from "../../../static/tablesHeaders";
import { Box } from "@mui/material";
import TableHeader from "../../TableHeader/TableHeader";
import EmptyListError from "../../EmptyListError/EmptyListError";
import { ArrowDownward, Warning } from "@mui/icons-material";
import MountedUnitsItem from "./MountedUnitsItem";
import PageHeader from "../../PageHeader/PageHeader";
import TableWrapper from "../../TableWrapper/TableWrapper";

const UnitsWrapper = styled(Box)(() => ({
  maxHight: "15.625rem",
  overflowY: "auto",
}));

const MountedUnits = ({
  setSorter,
  setSorting,
  mountedProducts,
  sorter,
  sort,
  removeItem,
}) => {
  return (
    <SectionWrapper>
      <PageHeader
        titleText="Zamontowane komponenty"
        size="medium"
        icon={<ArrowDownward />}
      />
      <TableWrapper>
        <TableHeader
          headers={workModalMountedUnitsHeaders}
          setSorter={setSorter}
          setSorting={setSorting}
          gap="1%"
        />
        <UnitsWrapper>
          {mountedProducts.length > 0 ? (
            <>
              {[...mountedProducts]
                .sort(
                  sorter["mounted"] !== ""
                    ? (a, b) => sort(a, b, "mounted")
                    : undefined
                )
                .map((unit, index) => (
                  <MountedUnitsItem
                    unit={unit}
                    orderNumber={index + 1}
                    key={index}
                    removeItem={removeItem}
                  />
                ))}
            </>
          ) : (
            <EmptyListError
              message="Brak zamontowanych jednostek"
              icon={<Warning />}
            />
          )}
        </UnitsWrapper>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default MountedUnits;
