/* eslint-disable react/prop-types */
import React from "react";
import SectionWrapper from "../SectionWrapper/SectionWrapper";
import PageHeader from "../PageHeader/PageHeader";
import { Article } from "@mui/icons-material";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
} from "../ObjectDetailsElements/ObjectDetailsElements";
import { Typography } from "@mui/material";
import { dateFormatView } from "../../static/functions";

const UtilizationReportDetails = ({ chosenUtilizationReport }) => {
  const { createdBy, creationDate } = chosenUtilizationReport;

  return (
    <SectionWrapper>
      <PageHeader icon={<Article />} titleText="Dane" size="medium" />
      <InfoWrapper>
        <DataBox>
          <DataTitle>Twórca:</DataTitle>
          <Typography>{`${createdBy.name} ${createdBy.surname}`}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Data utworzenia:</DataTitle>
          <Typography>{dateFormatView(creationDate)}</Typography>
        </DataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default UtilizationReportDetails;
