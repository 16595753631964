/* eslint-disable react/prop-types */
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RequirePermissions = ({ roles = '' }) => {
  const { userData: {role: userRole} } = useSelector(slice => slice.auth);
  const convertedRoles = Array.isArray(roles) ? roles : [roles];
  

  if (convertedRoles.includes(userRole))
    return <Outlet />;
  
  return <Navigate to="/error" />;
};

export default RequirePermissions;