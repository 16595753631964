/* eslint-disable react/prop-types */
import React, { isValidElement, useState } from "react";
import { Box, Card, CardContent, CardActions, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LongerTextModal from "../LongerTextModal/LongerTextModal";

const CustomBox = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "start",
  justifyContent: "start",
  gap: "0.5rem",
}));

const CustomDataBox = styled(Box)(({ theme }) => ({
  flex: "1 0 24%",
  display: "flex",
  flexWrap: "wrap",
  gap: "0 0.5rem",
  alignItems: "center",
  justifyContent: "start",
  [theme.breakpoints.only("mobile")]: {
    flexBasis: "99%",
  },
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: "1.5rem",
  fontWeight: 400,
}));

const DataTitle = styled(Typography)(() => ({
  fontWeight: 700,
}));

const CompactDataDisplay = ({ title, data, actions }) => {
  const [isFullTitleDisplayed, setIsFullTitleDisplayed] = useState(false);
  return (
    <Card variant="outlined">
      <CardContent>
        <CardTitle>
          {isFullTitleDisplayed ? title : title.substring(0, 15)}
          {title.length > 15 && (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setIsFullTitleDisplayed((state) => !state)}
            >
              [...]
            </span>
          )}
        </CardTitle>
        <CustomBox>
          {data.map((singleData, index) => (
            <CustomDataBox key={index}>
              <DataTitle>{singleData.title}</DataTitle>
              {isValidElement(singleData.value) ? (
                <Typography sx={singleData.sx ? singleData.sx : {}}>
                  {singleData.value}
                </Typography>
              ) : (
                <LongerTextModal
                  text={singleData.value}
                  styles={singleData?.styles}
                />
              )}
            </CustomDataBox>
          ))}
        </CustomBox>
      </CardContent>
      <CardActions>{actions}</CardActions>
    </Card>
  );
};

export default CompactDataDisplay;
