/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { dateFormatView } from "../../../../static/functions";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import EmptyListError from "../../../EmptyListError/EmptyListError";

const ChartWrapper = styled(Box)(() => ({
  width: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const Chart = ({
  tasks,
  taskType,
  chosenYear,
  chosenMonth,
  chartLabel,
  chartWarningLabel,
}) => {
  const dateFilter = (task) => {
    if (chosenYear !== "" && chosenMonth !== "") {
      return (
        dateFormatView(task.creationDate).substring(3, 5) * 1 ==
          chosenMonth * 1 &&
        dateFormatView(task.creationDate).substring(6, 10) == chosenYear
      );
    } else if (chosenYear !== "") {
      return dateFormatView(task.creationDate).substring(6, 10) == chosenYear;
    } else if (chosenMonth !== "") {
      return (
        dateFormatView(task.creationDate).substring(3, 5) * 1 == chosenMonth * 1
      );
    } else {
      return true;
    }
  };

  return (
    <ChartWrapper>
      <Typography sx={{ fontWeight: "700" }}>{chartLabel}</Typography>
      {tasks
        .filter((task) => dateFilter(task))
        .filter((task) => task.type === taskType).length > 0 ? (
        <PieChart width={300} height={300}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={[
              {
                name: "Utworzone",
                value: tasks
                  .filter((task) => dateFilter(task))
                  .filter(
                    (task) =>
                      task.type === taskType && task.status === "Created"
                  ).length,
              },
              {
                name: "W trakcie",
                value: tasks
                  .filter((task) => dateFilter(task))
                  .filter(
                    (task) =>
                      task.type === taskType && task.status === "In progress"
                  ).length,
              },
              {
                name: "Zamknięte",
                value: tasks
                  .filter((task) => dateFilter(task))
                  .filter(
                    (task) => task.type === taskType && task.status === "Closed"
                  ).length,
              },
              {
                name: "Odrzucone",
                value: tasks
                  .filter((task) => dateFilter(task))
                  .filter(
                    (task) =>
                      task.type === taskType && task.status === "Rejected"
                  ).length,
              },
            ]}
            innerRadius={60}
            outerRadius={100}
            fill="red"
            label
          >
            <Cell fill="#69A5D3" />
            <Cell fill="#D4C66A" />
            <Cell fill="#A1DC86" />
            <Cell fill="#FF7676" />
          </Pie>
          <Tooltip />
        </PieChart>
      ) : (
        <EmptyListError
          message={chartWarningLabel}
          styleProps={{ marginTop: "8rem" }}
        />
      )}
    </ChartWrapper>
  );
};

export default Chart;
