/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import useMediaQ from "../../hooks/useMediaQ";
import ShipmentsSummaryItemDesktop from "./ShipmentsSummaryItemVersions/ShipmentsSummaryItemDesktop";
import ShipmentsSummaryItemMobile from "./ShipmentsSummaryItemVersions/ShipmentsSummaryItemMobile";
import ToDeliveredModal from "../Shipments/ShipmentsElements/ToDeliveredModal/ToDeliveredModal";
import ToInProgressModal from "../Shipments/ShipmentsElements/ToInProgressModal";

const ShipmentsSummaryItem = ({ orderNumber, shipment, setRefresh }) => {
  const { isDesktopDisplay } = useMediaQ();
  const [
    isStatusChangeToDeliveredModalVisible,
    setIsStatusChangeToDeliveredModalVisible,
  ] = useState(false);

  const [
    isStatusChangeToInProgressModalVisible,
    setIsStatusChangeToInProgressModalVisible,
  ] = useState(false);

  return (
    <>
      {isDesktopDisplay ? (
        <ShipmentsSummaryItemDesktop
          orderNumber={orderNumber}
          shipment={shipment}
          setIsStatusChangeToDeliveredModalVisible={
            setIsStatusChangeToDeliveredModalVisible
          }
          setIsStatusChangeToInProgressModalVisible={
            setIsStatusChangeToInProgressModalVisible
          }
        />
      ) : (
        <ShipmentsSummaryItemMobile
          orderNumber={orderNumber}
          shipment={shipment}
          setIsStatusChangeToDeliveredModalVisible={
            setIsStatusChangeToDeliveredModalVisible
          }
          setIsStatusChangeToInProgressModalVisible={
            setIsStatusChangeToInProgressModalVisible
          }
        />
      )}
      <ToDeliveredModal
        isStatusChangeToDeliveredModalVisible={
          isStatusChangeToDeliveredModalVisible
        }
        setIsStatusChangeToDeliveredModalVisible={
          setIsStatusChangeToDeliveredModalVisible
        }
        shipment={shipment}
        setRefresh={setRefresh}
      />

      <ToInProgressModal
        shipmentId={shipment.id}
        isStatusChangeToInProgressModalVisible={
          isStatusChangeToInProgressModalVisible
        }
        setIsStatusChangeToInProgressModalVisible={
          setIsStatusChangeToInProgressModalVisible
        }
        setRefresh={setRefresh}
      />
    </>
  );
};

export default ShipmentsSummaryItem;
