/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SearchDropdown from "../../SearchDropdown/SearchDropdown";
import { getPaginatedStorages, getStorage } from "../../../slices/storageSlice";
import {
  DetailsButton,
  EditButton,
} from "../../FunctionalButtons/FunctionalButtons";
import { Add, Edit, KeyboardArrowLeft, Reply } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  createRequest,
  getRequest,
  responseRequest,
  updateRequest,
} from "../../../slices/requestSlice";
import PageHeader from "../../PageHeader/PageHeader";
import BlankSpace from "../../BlankSpace/BlankSpace";
import PageWrapper from "../../PageWrapper/PageWrapper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import useMediaQ from "../../../hooks/useMediaQ";
import authService from "../../../auth/authService";

const RequestForm = ({
  type = "add",
  destinationStorage = null,
  respTopic = "",
  parentId = "",
  receiverId = "",
  setIsRequestFormVisible,
  setRefresh = () => {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user_id: userId } = authService.getDecodedAccessToken();
  const {
    requests: { chosen: chosenRequest },
  } = useSelector((slice) => slice.request);
  const { role } = authService.getDecodedAccessToken();
  const [formValues, setFormValues] = useState({
    destinationStorage: "",
    topic: "",
    message: "",
  });
  const { isPhoneDisplay } = useMediaQ();
  const [baseStorage, setBaseStorage] = useState(null);

  const getData = useCallback(async () => {
    if (baseStorage) await dispatch(getStorage(baseStorage.id));
    if (type === "edit") {
      await dispatch(getRequest());
    }
  }, []);

  const createRequestInner = async () => {
    const data = {
      ...formValues,
      status: "Sent",
      childRequest: null,
      parentRequest: null,
      receiver: null,
      baseStorage: baseStorage.id,
      sender: userId,
    };
    await dispatch(createRequest(data));
    navigate("/requests");
  };

  const editRequest = async () => {
    const data = {
      ...formValues,
      requestId: chosenRequest.id,
      user: userId,
    };
    await dispatch(updateRequest(data));
    navigate("/requests");
  };

  const createResponse = async () => {
    const data = {
      requestId: parentId,
      data: { ...formValues, sender: userId },
    };
    await dispatch(responseRequest(data));
    setIsRequestFormVisible();
    setRefresh();
  };

  const submit = () => {
    if (type === "add") return createRequestInner();
    else if (type === "edit") return editRequest();
    else {
      return createResponse();
    }
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (type === "edit" && chosenRequest) {
      setFormValues({
        destinationStorage: chosenRequest.destinationStorage.id,
        topic: chosenRequest.topic,
        message: chosenRequest.message,
      });
    }
  }, [chosenRequest]);

  useEffect(() => {
    if (type === "response") {
      setFormValues({
        destinationStorage: destinationStorage.id,
        topic: respTopic,
        message: "",
      });
    }
  }, []);

  return (
    <PageWrapper width={isPhoneDisplay ? "90%" : "100%"}>
      {type === "add" && (
        <PageHeader
          titleText="Tworzenie zapotrzebowania"
          icon={<Add />}
          onRightComponent={
            <DetailsButton
              startIcon={<KeyboardArrowLeft />}
              text="Powrót"
              onClick={() => navigate(-1)}
            />
          }
        >
          {type === "add" && (
            <DetailsButton
              text="Utwórz zapotrzebowanie"
              startIcon={<Add />}
              onClick={() => submit()}
              disabled={
                !baseStorage ||
                !formValues.destinationStorage ||
                !formValues.topic ||
                !formValues.message
              }
            />
          )}
        </PageHeader>
      )}
      {type === "edit" && (
        <PageHeader
          titleText="Edycja zapotrzebowania"
          icon={<Edit />}
          onRightComponent={
            <DetailsButton
              startIcon={<KeyboardArrowLeft />}
              text="Powrót"
              onClick={() => navigate(-1)}
            />
          }
        >
          {type === "edit" && (
            <EditButton
              text="Edytuj"
              startIcon={<Edit />}
              onClick={() => submit()}
            />
          )}
        </PageHeader>
      )}
      {type === "response" && (
        <PageHeader
          titleText="Tworzenie odpowiedzi"
          icon={<Reply />}
          onRightComponent={
            <DetailsButton
              startIcon={<KeyboardArrowLeft />}
              text="Anuluj"
              onClick={() => setIsRequestFormVisible(false)}
            />
          }
        >
          {type === "response" && (
            <DetailsButton
              text="Wyślij odpowiedź"
              startIcon={<Reply />}
              onClick={() => submit()}
            />
          )}
        </PageHeader>
      )}
      <BlankSpace />
      <SectionWrapper
        sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
      >
        <SearchDropdown
          collectionName="storages"
          sliceName="storage"
          getDataFunction={getPaginatedStorages}
          placeholder="Magazyn bazowy"
          propertyToDisplay="name"
          setFunction={(item) => setBaseStorage(item)}
          restrictionFunction={(item) =>
            role === "service_technician" ? item.user.id === userId : true
          }
          isSearchingValueObjectProps={true}
        />
        <SearchDropdown
          collectionName="storages"
          sliceName="storage"
          outerValue={
            (destinationStorage && destinationStorage.name) ||
            (chosenRequest && chosenRequest.destinationStorage.name)
          }
          getDataFunction={getPaginatedStorages}
          placeholder="Magazyn docelowy"
          propertyToDisplay="name"
          setFunction={(item) =>
            setFormValues((state) => ({
              ...state,
              destinationStorage: item.id,
            }))
          }
          restrictionFunction={(item) =>
            (role !== "service_technician" && item.type === "main") ||
            (role === "service_technician" && item.type === "main")
          }
          isSearchingValueObjectProps={true}
        />
        <TextField
          value={formValues.topic}
          onChange={(e) =>
            setFormValues((state) => {
              return {
                ...state,
                topic: e.target.value,
              };
            })
          }
          label="Temat"
          fullWidth
        />
        <TextField
          value={formValues.message}
          onChange={(e) =>
            setFormValues((state) => {
              return {
                ...state,
                message: e.target.value,
              };
            })
          }
          label="Wiadomość"
          multiline
          minRows={8}
          maxRows={20}
          fullWidth
        />
      </SectionWrapper>
    </PageWrapper>
  );
};

export default RequestForm;
