/* eslint-disable react/prop-types */
import React from "react";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import { setChosenCommissionId } from "../../../../slices/commissionSlice";
import { setChosenDeviceId } from "../../../../slices/deviceSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dateFormatView } from "../../../../static/functions";
import CustomChip from "../../../CustomChip/CustomChip";
import {
  commissionStatus,
  commissionStatusChipStyles,
} from "../../../../static/static";

const CommissionItemMobile = ({ commission }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    id,
    company: { name: companyName },
    creationDate,
    finishDate,
    status,
    device: { id: deviceId, location: locationCode, name: deviceName },
    createdBy: { name, surname },
  } = commission;
  const data = [
    { title: "Data utworzenia:", value: dateFormatView(creationDate) },
    {
      title: "Data zakończenia:",
      value: finishDate ? dateFormatView(creationDate) : "Brak",
    },
    { title: "Nazwa firmy:", value: companyName },
    { title: "Kod lokalizacji:", value: locationCode },
    { title: "Nazwa urządzenia:", value: `${deviceName}` },
    { title: "Utworzone przez:", value: `${name} ${surname}` },
    {
      title: "Status:",
      value: (
        <CustomChip
          label={commissionStatus[status]}
          sx={commissionStatusChipStyles(status, "")}
          width="50%"
        />
      ),
    },
  ];
  return (
    <CompactDataDisplay
      title={`Zlecenie #${id}`}
      data={data}
      actions={
        <>
          <DetailsButton
            onClick={() => {
              dispatch(setChosenCommissionId(id));
              dispatch(setChosenDeviceId(deviceId));
              navigate(`/commissions/${id}`);
            }}
          />
        </>
      }
    />
  );
};

export default CommissionItemMobile;
