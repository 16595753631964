/* eslint-disable react/prop-types */
import React from "react";
import { Typography } from "@mui/material";
import { dateFormatView } from "../../../../static/functions";
import {
  requestStatus,
  requestStatusChipStyles,
} from "../../../../static/static";
import PageHeader from "../../../PageHeader/PageHeader";
import { Article, Reply } from "@mui/icons-material";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";
import CustomChip from "../../../CustomChip/CustomChip";
import { DataBox, DataTitle, InfoWrapper, SpecialDataBox } from "../../../ObjectDetailsElements/ObjectDetailsElements";

const RequestInfo = ({ request, type = "normal" }) => {
  return (
    <SectionWrapper>
      {type === "normal" && (
        <PageHeader titleText="Dane" size="medium" icon={<Article />} />
      )}
      {type === "response" && (
        <PageHeader titleText="Odpowiedź" size="medium" icon={<Reply />} />
      )}
      <InfoWrapper>
        <DataBox>
          <DataTitle>Data wysłania:</DataTitle>
          <Typography>{dateFormatView(request.creationDate)}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Nadawca:</DataTitle>
          <Typography>{`${request.sender.name} ${request.sender.surname}`}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Magazyn nadawczy:</DataTitle>
          <Typography>{request.baseStorage.name}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Magazyn docelowy:</DataTitle>
          <Typography>{request.destinationStorage.name}</Typography>
        </DataBox>
        {type !== "response" && (
          <DataBox>
            <DataTitle>Status:</DataTitle>
            <CustomChip
              label={requestStatus[request.status]}
              sx={requestStatusChipStyles(request.status, "")}
              width="40%"
            />
          </DataBox>
        )}
        {request.receiver !== null && (
          <DataBox>
            <DataTitle>Odbiorca</DataTitle>
            <Typography>{`${request.receiver.name} ${request.receiver.surname}`}</Typography>
          </DataBox>
        )}
        {request.modifier !== null && (
          <DataBox>
            <DataTitle>Modyfikowane przez:</DataTitle>
            <Typography>{`${request.modifier.name} ${request.modifier.surname}`}</Typography>
          </DataBox>
        )}
        {request.modifier !== null && (
          <DataBox>
            <DataTitle>Data modyfikacji:</DataTitle>
            <Typography>{dateFormatView(request.modificationDate)}</Typography>
          </DataBox>
        )}
        <SpecialDataBox>
          <DataTitle>Temat:</DataTitle>
          <Typography>{request.topic}</Typography>
        </SpecialDataBox>
        <SpecialDataBox>
          <DataTitle>Wiadomość:</DataTitle>
          <Typography>{request.message}</Typography>
        </SpecialDataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default RequestInfo;
