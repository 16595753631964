/* eslint-disable react/prop-types */
import React from "react";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
} from "../../ObjectDetailsElements/ObjectDetailsElements";
import { Typography } from "@mui/material";
import CustomChip from "../../CustomChip/CustomChip";
import { taskStatus, taskStatusChipStyles } from "../../../static/static";
import { dateFormatView } from "../../../static/functions";
import PageHeader from "../../PageHeader/PageHeader";
import { Info } from "@mui/icons-material";

const ShortenTaskDetails = ({ task }) => {
  const { id, status, creationDate, executionDate } = task;

  return (
    <>
      <PageHeader titleText="Szczegóły zadania" icon={<Info />} size="medium" />
      <InfoWrapper>
        <DataBox>
          <DataTitle>ID:</DataTitle>
          <Typography>{id}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Status:</DataTitle>
          <CustomChip
            label={taskStatus[status]}
            sx={taskStatusChipStyles(status, "")}
            width="40%"
          />
        </DataBox>
        <DataBox>
          <DataTitle>Data utworzenia:</DataTitle>
          <Typography>
            {creationDate ? dateFormatView(creationDate) : "Brak"}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Data wykonania:</DataTitle>
          <Typography>
            {executionDate ? dateFormatView(executionDate) : "Brak"}
          </Typography>
        </DataBox>
      </InfoWrapper>
    </>
  );
};

export default ShortenTaskDetails;
