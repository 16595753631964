/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../../../TableGridWrapper/TableDataGridWrapper";
import { tasksSummaryHeaders } from "../../../../../static/tablesHeaders";
import { dateFormatView } from "../../../../../static/functions";
import CustomChip from "../../../../CustomChip/CustomChip";
import {
  taskStatus,
  taskStatusChipStyles,
  taskType,
  taskTypeChipStyles,
} from "../../../../../static/static";
import DetailsButton from "../../../../DetailsButton/DetailsButton";
import { useNavigate } from "react-router-dom";
import TaskExecution from "../../../../TaskView/TaskExecution/TaskExecution";
import { Warning } from "@mui/icons-material";

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "0.1rem 0.8rem",
  justifyContent: "center",
}));

const TaskSummaryItemDesktop = ({
  orderNumber,
  task,
  setRefresh,
  chosenUser,
}) => {
  const navigate = useNavigate();

  const {
    id,
    commission,
    creationDate,
    proposedExecutor,
    assignedExecutor,
    planningExecutionDate,
    status,
    type,
  } = task;

  return (
    <TableDataGridWrapper headers={tasksSummaryHeaders} gap="1%">
      <Typography>{orderNumber}</Typography>
      <Typography>{commission.id}</Typography>
      <Box sx={{ display: "flex", gap: "0.5rem" }}>
        <Typography
          sx={
            new Date(planningExecutionDate).getTime() < new Date().getTime()
              ? { color: "red" }
              : {}
          }
        >
          {planningExecutionDate ? dateFormatView(planningExecutionDate) : ""}
        </Typography>
        {planningExecutionDate &&
          new Date(planningExecutionDate).getTime() < new Date().getTime() && (
            <Warning sx={{ color: "red" }} />
          )}
      </Box>
      <Typography>{dateFormatView(creationDate)}</Typography>
      {proposedExecutor !== null ? (
        <Typography>{`${proposedExecutor.name} ${proposedExecutor.surname}`}</Typography>
      ) : (
        <Typography>--------------------</Typography>
      )}
      {assignedExecutor !== null ? (
        <Typography>{`${assignedExecutor.name} ${assignedExecutor.surname}`}</Typography>
      ) : (
        <Typography>--------------------</Typography>
      )}
      <CustomChip
        label={taskStatus[status]}
        sx={taskStatusChipStyles(status, "")}
      />
      <CustomChip label={taskType[type]} sx={taskTypeChipStyles(type, "")} />
      <ButtonWrapper>
        <DetailsButton onClick={() => navigate(`/tasks/${id}`)} width="60%" />
        <TaskExecution
          task={task}
          setRefresh={setRefresh}
          chosenUser={chosenUser}
          buttonWidth="60%"
        />
      </ButtonWrapper>
    </TableDataGridWrapper>
  );
};

export default TaskSummaryItemDesktop;
