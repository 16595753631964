import React, { useState, useCallback, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import PageWrapper from "../PageWrapper/PageWrapper";
import PageHeader from "../PageHeader/PageHeader";
import { Add, ClearAll, Info } from "@mui/icons-material";
import { DetailsButton } from "../FunctionalButtons/FunctionalButtons";
import { useNavigate } from "react-router-dom";
import SectionWrapper from "../SectionWrapper/SectionWrapper";
import EmptyListError from "../EmptyListError/EmptyListError";
import { useDispatch, useSelector } from "react-redux";
import AdditionalFunctionalitiesWrapper from "../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import CustomSearchField from "../CustomSearchField/CustomSearchField";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../../static/static";
import {
  clearUtilizationReports,
  getPaginatedUtilizationReports,
  getUtilizationReportsCount,
} from "../../slices/utilizationReportSlice";
import { getLocalTimeDate } from "../../static/functions";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import TableHeader from "../TableHeader/TableHeader";
import Pagination from "../Pagination/Pagination";
import Loading from "../Loading/Loading";
import { utilizationReportsHeaders } from "../../static/tablesHeaders";
import UtilizationReportItem from "./UtilizationReportsItem";
import useMediaQ from "../../hooks/useMediaQ";
import TableWrapper from "../TableWrapper/TableWrapper";
import ReturnButton from "../ReturnButton/ReturnButton";

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
}));

const UtilizationReportsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isPhoneDisplay, isTabletDisplay } = useMediaQ();
  const searchingRef = useRef("");
  const { utilizationReports, utilizationReportsCount } = useSelector(
    (slice) => slice.utilizationReport
  );
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [sorter, setSorter] = useState("creationDate");
  const [sorting, setSorting] = useState("descending");
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(DEFAULT_DISPLAY_ITEMS_QUANTITY);
  const [searchingValue, setSearchingValue] = useState("");
  const [displaySearchingValue, setDisplaySearchingValue] = useState("");
  const [creationDates, setCreationDates] = useState("");

  const setCreationDateInterval = (dates) => {
    const [startDate, endDate] = dates;
    setCreationDates({
      start: startDate,
      end: endDate,
    });
  };

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getUtilizationReportsCount({
        searchingValue,
        createdBy: "",
        creationDateStart: creationDates.start
          ? getLocalTimeDate(creationDates.start)
          : "",
        creationDateEnd: creationDates.end
          ? getLocalTimeDate(creationDates.end)
          : "",
      })
    );
  }, [searchingValue, creationDates.start, creationDates.end]);

  const loadData = useCallback(async () => {
    await dispatch(
      getPaginatedUtilizationReports({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        createdBy: "",
        creationDateStart: creationDates.start
          ? getLocalTimeDate(creationDates.start)
          : "",
        creationDateEnd: creationDates.end
          ? getLocalTimeDate(creationDates.end)
          : "",
      })
    ),
      setIsDataLoading(false);
  }, [
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    creationDates.start,
    creationDates.end,
  ]);

  useEffect(() => {
    {
      loadDataCount();
    }
  }, [loadDataCount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearUtilizationReports());
  }, []);

  return (
    <PageWrapper
      width={isPhoneDisplay ? "80%" : isTabletDisplay ? "80%" : "65%"}
    >
      <PageHeader
        icon={<ClearAll />}
        titleText="Raporty utylizacyjne"
        onRightComponent={
          <ButtonWrapper>
            <DetailsButton
              text="Utwórz raport"
              startIcon={<Add />}
              onClick={() => navigate("/utilizationReports-add")}
            />
            <ReturnButton />
          </ButtonWrapper>
        }
      />
      <SectionWrapper>
        <AdditionalFunctionalitiesWrapper>
          <CustomSearchField
            displaySearchingValue={displaySearchingValue}
            setDisplaySearchingValue={setDisplaySearchingValue}
            searchingValue={searchingValue}
            setSearchingValue={setSearchingValue}
            searchingRef={searchingRef}
            placeholder="szukaj raportu..."
          />
          <CustomDatePicker
            labelName="Data utworzenia"
            datesRange={creationDates}
            setDatesRange={(dates) => setCreationDateInterval(dates)}
            maxDate={new Date()}
          />
        </AdditionalFunctionalitiesWrapper>
        <TableWrapper>
          <TableHeader
            headers={utilizationReportsHeaders}
            setSorter={setSorter}
            setSorting={setSorting}
            gap="1%"
          />
          <Pagination
            collectionSize={utilizationReportsCount}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            setLeftIndex={setLeftIndex}
            setRightIndex={setRightIndex}
          >
            {isDataLoading ? (
              <Loading />
            ) : (
              <>
                {utilizationReports !== null &&
                  utilizationReports.length > 0 &&
                  utilizationReports.map((utilizationReport, id) => (
                    <UtilizationReportItem
                      orderNumber={leftIndex + id + 1}
                      key={id}
                      utilizationReport={utilizationReport}
                    />
                  ))}
              </>
            )}
            {utilizationReports !== null && utilizationReports.length === 0 && (
              <EmptyListError message="Brak raportów" icon={<Info />} />
            )}
          </Pagination>
        </TableWrapper>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default UtilizationReportsList;
