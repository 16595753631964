/* eslint-disable react/prop-types */
import React from "react";
import TableHeader from "../../TableHeader/TableHeader";
import { workModalUnmountedUnitsHeaders } from "../../../static/tablesHeaders";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import { Dialog, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddingBADForm from "../AddingBADForm";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import { Add, ArrowUpward, Warning } from "@mui/icons-material";
import UnmountedUnitsItem from "./UnmountedUnitsItem";
import EmptyListError from "../../EmptyListError/EmptyListError";
import PageHeader from "../../PageHeader/PageHeader";
import TableWrapper from "../../TableWrapper/TableWrapper";

const UnitsWrapper = styled(Box)(() => ({
  maxHight: "15.625rem",
  overflowY: "auto",
}));

const UnmountedUnits = ({
  setSorter,
  setSorting,
  setIsAddingUnitModalVisible,
  isAddingUnitModalVisible,
  sorter,
  sort,
  unmountedProducts,
  setUnmountedProducts,
  removeBADItem,
}) => {
  return (
    <>
      <SectionWrapper>
        <PageHeader
          titleText="Zdemontowane komponenty"
          size="medium"
          icon={<ArrowUpward />}
          onRightComponent={
            <DetailsButton
              onClick={() => setIsAddingUnitModalVisible(true)}
              startIcon={<Add />}
              text="Dodaj produkt BAD"
            />
          }
        />
        <TableWrapper>
          <TableHeader
            headers={workModalUnmountedUnitsHeaders}
            setSorter={setSorter}
            setSorting={setSorting}
            gap="1%"
          />
          <UnitsWrapper>
            {unmountedProducts.length > 0 ? (
              <>
                {[...unmountedProducts]
                  .sort(
                    sorter["bad"] !== ""
                      ? (a, b) => sort(a, b, "bad")
                      : undefined
                  )
                  .map((unit, index) => (
                    <UnmountedUnitsItem
                      key={index}
                      unit={unit}
                      orderNumber={index + 1}
                      removeBADItem={removeBADItem}
                    />
                  ))}
              </>
            ) : (
              <EmptyListError
                message="Brak zdemontowanych jednostek"
                icon={<Warning />}
              />
            )}
          </UnitsWrapper>
        </TableWrapper>
      </SectionWrapper>
      <Dialog
        open={isAddingUnitModalVisible}
        onClose={() => setIsAddingUnitModalVisible(false)}
      >
        <AddingBADForm
          unmountedProducts={unmountedProducts}
          setUnmountedProducts={setUnmountedProducts}
          setIsAddingUnitModalVisible={setIsAddingUnitModalVisible}
        />
      </Dialog>
    </>
  );
};

export default UnmountedUnits;
