/* eslint-disable react/prop-types */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import PageWrapper from "../PageWrapper/PageWrapper";
import PageHeader from "../PageHeader/PageHeader";
import { Assessment, Info } from "@mui/icons-material";
import RefreshButton from "../RefreshButton/RefreshButton";
import BlankSpace from "../BlankSpace/BlankSpace";
import SectionWrapper from "../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import TableWrapper from "../TableWrapper/TableWrapper";
import TableHeader from "../TableHeader/TableHeader";
import Pagination from "../Pagination/Pagination";
import usePagination from "../../hooks/usePagination";
import { useDispatch, useSelector } from "react-redux";
import {
  clearStorageReports,
  getPaginatedStorageReports,
  getStorageReportsCount,
} from "../../slices/storageReportSlice";
import CustomSearchField from "../CustomSearchField/CustomSearchField";
import Loading from "../Loading/Loading";
import EmptyListError from "../EmptyListError/EmptyListError";
import StorageReportItem from "./StorageReportItem";
import { storageReportsListHeaders } from "../../static/tablesHeaders";
import { storageReportOperationTypeIcons } from "../../static/static";
import { Box, Button, Checkbox, Chip, Typography } from "@mui/material";
import DateRangeSelector from "../DateRangeSelector/DateRangeSelector";
import { getLocalTimeDate } from "../../static/functions";
import StorageReportsDownloader from "../StorageReport/StorageReportsDownloader";
import { ReturnButton } from "../FunctionalButtons/FunctionalButtons";
import { useNavigate } from "react-router-dom";

const OperationTypeSelector = ({ setOperationType }) => {
  const objectKeys = useMemo(
    () => Object.keys(storageReportOperationTypeIcons),
    []
  );
  const transformedOperationTypes = useMemo(() => {
    const array = objectKeys.map((key) => storageReportOperationTypeIcons[key]);
    return array;
  }, []);
  return (
    <Box>
      <Typography sx={{ fontWeight: 700 }}>Typy operacji:</Typography>
      <Box sx={{ display: "flex", gap: "0.1rem", flexWrap: "wrap" }}>
        <Chip
          label="Wszystkie"
          sx={{
            bgcolor: "#AAA",
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => setOperationType("")}
        />
        {transformedOperationTypes.map((item, index) => (
          <Button
            key={index}
            onClick={() => setOperationType(objectKeys[index])}
            sx={{ margin: 0, padding: 0 }}
            size="small"
          >
            {item}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

const StorageReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { storageReports, storageReportsCount } = useSelector(
    (slice) => slice.storageReport
  );
  const searchingRef = useRef("");
  const [chosenOperationType, setChosenOperationType] = useState("");
  const [createdDates, setCreationDates] = useState({ start: "", end: "" });
  const [selectedStorageReports, setSelectedStorageReports] = useState([]);
  const [mainCheckboxChecked, setMainCheckboxChecked] = useState(false);
  const [actualHeaders, setActualHeaders] = useState(storageReportsListHeaders);
  const {
    refresh,
    setRefresh,
    sorter,
    setSorter,
    sorting,
    setSorting,
    searchingValue,
    setSearchingValue,
    displaySearchingValue,
    setDisplaySearchingValue,
    leftIndex,
    setLeftIndex,
    rightIndex,
    setRightIndex,
    isDataLoading,
    setIsDataLoading,
  } = usePagination("creationDate", "descending");

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getStorageReportsCount({
        searchingValue,
        creationDateStart: createdDates.start
          ? getLocalTimeDate(createdDates.start)
          : "",
        creationDateEnd: createdDates.end
          ? getLocalTimeDate(createdDates.end)
          : "",
        operationType: chosenOperationType,
        user: "",
        baseStorage: "",
        destinationStorage: "",
        shipment: "",
        utilizationReport: "",
        task: "",
      })
    );
    setIsDataLoading(false);
  }, [
    refresh,
    searchingValue,
    chosenOperationType,
    createdDates.start,
    createdDates.end,
  ]);

  const loadData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getPaginatedStorageReports({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        creationDateStart: createdDates.start
          ? getLocalTimeDate(createdDates.start)
          : "",
        creationDateEnd: createdDates.end
          ? getLocalTimeDate(createdDates.end)
          : "",
        operationType: chosenOperationType,
        user: "",
        baseStorage: "",
        destinationStorage: "",
        shipment: "",
        utilizationReport: "",
        task: "",
      })
    );
    setIsDataLoading(false);
  }, [
    refresh,
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    chosenOperationType,
    createdDates.start,
    createdDates.end,
  ]);

  useEffect(() => {
    loadDataCount();
  }, [loadDataCount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearStorageReports());
  }, []);

  const setCreationDateInterval = useCallback((dates) => {
    const [startDate, endDate] = dates;
    setCreationDates({
      start: startDate,
      end: endDate,
    });
  }, []);

  const addStorageReportToSelected = useCallback((storageReport) => {
    setSelectedStorageReports((state) => [...state, storageReport]);
  }, []);

  const removeStorageReportFromSelected = useCallback(
    (storageReportId) => {
      setSelectedStorageReports(
        selectedStorageReports.filter(
          (storageReport) => storageReport.id !== storageReportId
        )
      );
    },
    [selectedStorageReports]
  );

  const selectAll = useCallback(() => {
    setMainCheckboxChecked(true);
    setSelectedStorageReports(storageReports);
  }, [storageReports]);

  const unselectAll = useCallback(() => {
    setMainCheckboxChecked(false);
    setSelectedStorageReports([]);
  }, [selectedStorageReports]);

  const handleMainCheckboxClick = useCallback(() => {
    if (mainCheckboxChecked) {
      unselectAll();
    } else {
      selectAll();
    }
  }, [selectAll, unselectAll]);

  useEffect(() => {
    const arrayClone = [...actualHeaders];
    arrayClone[1] = {
      id: 1.5,
      headerName: (
        <Checkbox
          onChange={() => handleMainCheckboxClick()}
          checked={mainCheckboxChecked}
        />
      ),
      sorter: "",
      disabled: true,
      columnSize: "3%",
    };
    setActualHeaders(arrayClone);
  }, [handleMainCheckboxClick]);

  return (
    <PageWrapper width="95%">
      <PageHeader
        titleText="Raporty magazynowe"
        icon={<Assessment />}
        onRightComponent={<ReturnButton onClick={() => navigate(-1)} />}
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <BlankSpace />
      <SectionWrapper>
        <AdditionalFunctionalitiesWrapper>
          <CustomSearchField
            displaySearchingValue={displaySearchingValue}
            setDisplaySearchingValue={setDisplaySearchingValue}
            searchingValue={searchingValue}
            setSearchingValue={setSearchingValue}
            searchingRef={searchingRef}
            placeholder="szukaj raportu..."
            width="15%"
            widthMobile="60%"
          />
          <OperationTypeSelector
            setOperationType={(operationType) => {
              setChosenOperationType(operationType);
              unselectAll();
            }}
          />
          <DateRangeSelector
            labelName="Data utworzenia: "
            datesRange={createdDates}
            setDatesRange={(dates) => setCreationDateInterval(dates)}
            maxDate={new Date()}
            sx={{
              flexBasis: "30%",
            }}
          />
          <StorageReportsDownloader
            type="multi"
            fontSize="2.5rem"
            storageReports={selectedStorageReports}
          />
        </AdditionalFunctionalitiesWrapper>
        <TableWrapper>
          <TableHeader
            headers={actualHeaders}
            setSorter={setSorter}
            setSorting={setSorting}
            gap="1%"
          />
          <Pagination
            collectionSize={storageReportsCount}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            setLeftIndex={setLeftIndex}
            setRightIndex={setRightIndex}
          >
            {isDataLoading ? (
              <Loading />
            ) : (
              <>
                {storageReports !== null &&
                  storageReports.map((storageReport, id) => (
                    <StorageReportItem
                      orderNumber={leftIndex + id + 1}
                      key={storageReport.id}
                      storageReport={storageReport}
                      setRefresh={setRefresh}
                      selectedStorageReports={selectedStorageReports}
                      setSelectedStorageReports={setSelectedStorageReports}
                      addStorageReportToSelected={addStorageReportToSelected}
                      removeStorageReportFromSelected={
                        removeStorageReportFromSelected
                      }
                    />
                  ))}
              </>
            )}
            {storageReports !== null && storageReports.length === 0 && (
              <EmptyListError
                message="Brak raportów magazynowych"
                icon={<Info />}
              />
            )}
          </Pagination>
        </TableWrapper>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default StorageReports;
