/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import MountedUnitsItemDesktop from "./MountedUnitsItemVersions/MountedUnitsItemDesktop";
import MountedUnitsItemMobile from "./MountedUnitsItemVersions/MountedUnitsItemMobile";

const MountedUnitsItem = ({ unit, removeItem, orderNumber }) => {
  const { isDesktopDisplay } = useMediaQ();
  const {
    index,
    removeInner,
  } = unit;

  const deleteElement = () => {
    const value = removeItem(index);
    removeInner(value);
  };

  return (
    <>
      {isDesktopDisplay ? (
        <MountedUnitsItemDesktop
          unit={unit}
          orderNumber={orderNumber}
          deleteElement={deleteElement}
        />
      ) : (
        <MountedUnitsItemMobile
          unit={unit}
          orderNumber={orderNumber}
          deleteElement={deleteElement}
        />
      )}
    </>
  );
};

export default MountedUnitsItem;
