/* eslint-disable react/prop-types */
import React from "react";
import { Button } from "@mui/material";
import { SwitchTabClasses } from "./SwitchTab.style";

const SwitchTab = ({ text, onClick, selected }) => {
  return (
    <Button
      onClick={() => onClick()}
      variant="contained"
      sx={SwitchTabClasses.innerStyle(selected)}
    >
      {text}
    </Button>
  );
};

export default SwitchTab;
