/* eslint-disable react/prop-types */
import React from "react";
import CustomChip from "../../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../../static/static";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";

const ShipmentUnitItemMobile = ({ orderNumber, shipmentUnit }) => {
  const {
    product: { partNumber, name },
    serialNumber,
    status,
    quantity,
  } = shipmentUnit;

  const data = [
    { title: "Part Number:", value: partNumber },
    { title: "Nazwa:", value: name },
    { title: "Nr seryjny:", value: serialNumber ? serialNumber : "Brak" },
    {
      title: "Status:",
      value: (
        <CustomChip
          label={status}
          sx={unitStatusChipStyles(status, "")}
          width="100%"
        />
      ),
    },
    {
      title: "Liczba:",
      value: quantity,
    },
  ];
  return (
    <CompactDataDisplay
      title={`Składowa #${orderNumber}`}
      data={data}
      actions={<></>}
    />
  );
};

export default ShipmentUnitItemMobile;
