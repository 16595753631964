/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box, Typography, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import SwitchQuestionModal from "../../SwitchQuestionModal/SwitchQuestionModal";
import ChooseDeviceCreatePage from "../ChooseDeviceCreatePage/ChooseDeviceCreatePage";
import ChooseDeviceSelectPage from "../ChooseDeviceSelectPage/ChooseDeviceSelectPage";

const CustomWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const TitleWrapper = styled(Box)(() => ({
  alignSelf: "center",
  display: "flex",
  gap: "0.5rem",
}));

const ChooseDeviceMainPage = ({
  chosenCompany,
  chosenLocation,
  createdLocation,
  chosenDevice,
  setChosenDevice,
  createdDevice,
  setCreatedDevice,
  listOfActivePages,
  setListOfActivePages,
  setCommissionDetails,
}) => {
  const [mode, setMode] = useState(
    (listOfActivePages.length > 2 &&
      listOfActivePages[1].prop === "createLocation") ||
      listOfActivePages[2].prop === "createDevice"
      ? "create"
      : "select"
  );
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const changeToSelect = () => {
    setMode("select");
    setCreatedDevice({
      name: "",
      createdAt: "",
      deviceId: "",
      type: "",
      orientation: "",
      subType: "",
    });
    setListOfActivePages((state) => [
      state[0],
      state[1],
      { id: "2", prop: "selectDevice" },
    ]);
    setCommissionDetails({
      description: "",
      errorCode: "",
    });
  };

  const changeToCreate = () => {
    setMode("create");
    setChosenDevice("");
    setListOfActivePages((state) => [
      state[0],
      state[1],
      { id: "2", prop: "createDevice" },
    ]);
    setCommissionDetails({
      description: "",
      errorCode: "",
    });
  };

  return (
    <>
      <CustomWrapper>
        <TitleWrapper>
          {mode === "select" ? (
            <Typography
              sx={{
                fontSize: "1.4rem",
                marginBottom: "1rem",
                fontWeight: mode === "select" ? 700 : 400,
              }}
            >
              3. Wybierz istniejące urządzenie
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: "1.4rem",
                marginBottom: "1rem",
                fontWeight: mode !== "select" ? 700 : 400,
              }}
            >
              3. Utwórz nowe urządzenie
            </Typography>
          )}
        </TitleWrapper>
        {mode === "select" ? (
          <ChooseDeviceSelectPage
            chosenDevice={chosenDevice}
            setChosenDevice={setChosenDevice}
            locationId={chosenLocation.code}
            mode={mode}
            setIsWarningModalOpen={setIsWarningModalOpen}
          />
        ) : (
          <ChooseDeviceCreatePage
            createdDevice={createdDevice}
            setCreatedDevice={setCreatedDevice}
            listOfActivePages={listOfActivePages}
            mode={mode}
            setIsWarningModalOpen={setIsWarningModalOpen}
          />
        )}
      </CustomWrapper>
      <Dialog
        open={isWarningModalOpen}
        onClose={() => setIsWarningModalOpen(false)}
      >
        <SwitchQuestionModal
          stepsString="3-5"
          executiveFunction={
            mode === "select" ? () => changeToCreate() : () => changeToSelect()
          }
          closeFunction={() => setIsWarningModalOpen(false)}
        />
      </Dialog>
    </>
  );
};

export default ChooseDeviceMainPage;
