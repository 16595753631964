/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import AcceptButton from "../../../AcceptButton/AcceptButton";
import WarningButton from "../../../WarningButton/WarningButton";
import DeleteButton from "../../../DeleteButton/DeleteButton";
import EditButton from "../../../EditButton/EditButton";
import { ChangeRequestStatusModalClasses } from "./ChangeRequestStatusModal.style";
import PageHeader from "../../../PageHeader/PageHeader";
import { TrendingFlat, Logout } from "@mui/icons-material";

const ChangeRequestStatusModal = ({
  changeStatus,
  styleProps,
  setAreStatusOptionsVisible,
}) => {
  return (
    <Box sx={{ ...ChangeRequestStatusModalClasses.wrapper, ...styleProps }}>
      <PageHeader
        titleText="Zmiana statusu zapotrzebowania"
        icon={<TrendingFlat />}
      />
      <Box sx={ChangeRequestStatusModalClasses.innerWrapper}>
        <AcceptButton onClick={() => changeStatus("Accepted")} />
        <WarningButton onClick={() => changeStatus("Warned")} />
        <DeleteButton onClick={() => changeStatus("Rejected")} text="odrzuć" />
        <EditButton
          onClick={() => setAreStatusOptionsVisible(false)}
          text="Anuluj"
          startIcon={<Logout />}
        />
      </Box>
    </Box>
  );
};

export default ChangeRequestStatusModal;
