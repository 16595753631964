/* eslint-disable react/prop-types */
import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { historyTaskListHeaders } from "../../../../static/tablesHeaders";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { dateFormatView } from "../../../../static/functions";
import CustomChip from "../../../CustomChip/CustomChip";
import {
  taskStatus,
  taskStatusChipStyles,
  taskType,
  taskTypeChipStyles,
} from "../../../../static/static";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setChosenTaskId } from "../../../../slices/taskSlice";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const TaskHistoryItemDesktop = ({orderNumber, task }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const taskDetails = () => {
    dispatch(setChosenTaskId(taskId));
    navigate(`/tasks/${taskId}`);
  };

  const {
    id: taskId,
    location,
    creationDate,
    status,
    type,
    executionDate,
    executor,
  } = task;

  return (
    <TableDataGridWrapper headers={historyTaskListHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{location.code}</CustomDataDisplay>
      <CustomDataDisplay>{dateFormatView(creationDate)}</CustomDataDisplay>
      <CustomDataDisplay>{dateFormatView(executionDate)}</CustomDataDisplay>
      <CustomChip
        label={taskStatus[status]}
        sx={taskStatusChipStyles(status, "")}
        width="100%"
      />
      <CustomChip
        label={taskType[type]}
        sx={taskTypeChipStyles(type, "")}
        width="80%"
      />
      <CustomDataDisplay>
        {`${executor.name} ${executor.surname}`}
      </CustomDataDisplay>
      <DetailsButton onClick={() => taskDetails()} width="70%"/>
    </TableDataGridWrapper>
  );
};

export default TaskHistoryItemDesktop;
