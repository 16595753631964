/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Box,
  Fade,
  IconButton,
  MenuItem,
  Popper,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Clear, ClearAll, Sort } from "@mui/icons-material";
import useMediaQ from "../../hooks/useMediaQ";

const CustomBox = styled(Box)(({ theme }) => ({
  border: "0.0625rem solid black",
  padding: "0.5rem",
  backgroundColor: "white",
  display: "flex",
  gap: "1rem",
  width: "28rem",
  [theme.breakpoints.down("tablet")]: {
    width: "18rem",
  },
}));

const MobileDataSorter = ({
  tableHeader = [],
  setOuterSorter,
  setOuterSorting,
  defaultSorter = "",
  defaultSorting = 'ascending'
}) => {
  const [sorter, setSorter] = useState(defaultSorter);
  const [sorting, setSorting] = useState(defaultSorting);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isPhoneDisplay, isTabletDisplay, isDesktopDisplay } = useMediaQ();

  const clearState = () => {
    setSorter(defaultSorter);
    setOuterSorter(defaultSorter);
    setSorting(defaultSorting);
    setOuterSorting(defaultSorting);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const canBeOpen = isModalOpen && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <>
      {!isDesktopDisplay && (
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Tooltip title="sortowanie">
              <IconButton
                aria-describedby={id}
                onClick={(event) => handleClick(event)}
              >
                <Sort />
              </IconButton>
            </Tooltip>
          </Box>
          <Popper
            id={id}
            open={isModalOpen}
            anchorEl={anchorEl}
            placement={
              isPhoneDisplay
                ? "bottom-start"
                : isTabletDisplay
                ? "bottom-end"
                : "bottom-start"
            }
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <CustomBox>
                  <TextField
                    value={sorter}
                    onChange={(e) => {
                      setSorter(e.target.value);
                      setOuterSorter(e.target.value);
                    }}
                    placeholder="sortowanie"
                    label="Sortowanie"
                    select
                    sx={{
                      width: isPhoneDisplay ? "40%" : "auto",
                      flexBasis: "35%",
                    }}
                  >
                    {tableHeader
                      .filter((header) => header.disabled === false)
                      .map((header, id) => (
                        <MenuItem key={id} value={header.sorter}>
                          {header.headerName}
                        </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                    value={sorting}
                    onChange={(e) => {
                      setSorting(e.target.value);
                      setOuterSorting(e.target.value);
                    }}
                    select
                    placeholder="sposób"
                    label="Sposób"
                    sx={{
                      width: isPhoneDisplay ? "40%" : "auto",
                      flexBasis: "35%",
                    }}
                  >
                    <MenuItem value="ascending">rosnąco</MenuItem>
                    <MenuItem value="descending">malejąco</MenuItem>
                  </TextField>
                  <Tooltip title="wyczyść">
                    <IconButton onClick={clearState}>
                      <ClearAll />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="zamknij">
                    <IconButton
                      onClick={closeModal}
                      sx={{ position: "absolute", top: "1%", right: "1%" }}
                    >
                      <Clear />
                    </IconButton>
                  </Tooltip>
                </CustomBox>
              </Fade>
            )}
          </Popper>
        </Box>
      )}
    </>
  );
};

export default MobileDataSorter;
