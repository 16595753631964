/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUnits,
  getPaginatedUnitsByUtilizationReport,
  getUnitsByUtilizationReportCount,
} from "../../../slices/unitSlice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Info,
  Clear,
} from "@mui/icons-material";
import Pagination from "../../Pagination/Pagination";
import {
  DEFAULT_DISPLAY_ITEMS_QUANTITY,
} from "../../../static/static";
import Loading from "../../Loading/Loading";
import PageHeader from "../../PageHeader/PageHeader";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import TableHeader from "../../TableHeader/TableHeader";
import { unitListHeaders } from "../../../static/tablesHeaders";
import EmptyListError from "../../EmptyListError/EmptyListError";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import UnitItem from "../../UnitsList/UnitItem/UnitItem";
import TableWrapper from "../../TableWrapper/TableWrapper";

// eslint-disable-next-line react/prop-types
// eslint-disable-next-line no-unused-vars
const UtilizationReportUnitsList = ({ chosenUtilizationReport }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchingRef = useRef("");
  const { units, unitsCount } = useSelector((state) => state.unit);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [sorter, setSorter] = useState("partNumber");
  const [sorting, setSorting] = useState("ascending");
  const [leftIndex, setLeftIndex] = useState(0);
  const { pathname } = useLocation();
  const [rightIndex, setRightIndex] = useState(DEFAULT_DISPLAY_ITEMS_QUANTITY);
  const [searchingValue, setSearchingValue] = useState("");
  const [displaySearchingValue, setDisplaySearchingValue] = useState("");
  const [filteredValue, setFilteredValue] = useState("UTILIZED");

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getUnitsByUtilizationReportCount({
        searchingValue,
        status: filteredValue,
        utilRepId: chosenUtilizationReport.id,
      })
    );
  }, [chosenUtilizationReport, searchingValue, filteredValue, pathname]);

  const loadData = useCallback(async () => {
    await dispatch(
      getPaginatedUnitsByUtilizationReport({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
        status: filteredValue,
        utilRepId: chosenUtilizationReport.id,
      })
    ),
      setIsDataLoading(false);
  }, [
    leftIndex,
    rightIndex,
    sorter,
    sorting,
    searchingValue,
    filteredValue,
    chosenUtilizationReport,
  ]);

  useEffect(async () => {
    if (chosenUtilizationReport !== null) {
      loadDataCount();
    }
  }, [loadDataCount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearUnits());
  }, []);

  return (
    <SectionWrapper>
      <PageHeader
        icon={<Clear />}
        titleText="Zutylizowane jednostki"
        size="medium"
      />
      <AdditionalFunctionalitiesWrapper>
        <CustomSearchField
          displaySearchingValue={displaySearchingValue}
          setDisplaySearchingValue={setDisplaySearchingValue}
          searchingValue={searchingValue}
          setSearchingValue={setSearchingValue}
          searchingRef={searchingRef}
          placeholder="szukaj produktu..."
        />
      </AdditionalFunctionalitiesWrapper>
      <TableWrapper>
        <TableHeader
          headers={unitListHeaders}
          setSorter={setSorter}
          setSorting={setSorting}
          gap="1%"
        />
        <Pagination
          collectionSize={unitsCount}
          leftIndex={leftIndex}
          rightIndex={rightIndex}
          setLeftIndex={setLeftIndex}
          setRightIndex={setRightIndex}
        >
          {isDataLoading ? (
            <Loading />
          ) : (
            <>
              {units !== null &&
                units.map((unit, id) => (
                  <UnitItem
                    orderNumber={leftIndex + id + 1}
                    key={id}
                    unit={unit}
                  />
                ))}
            </>
          )}
          {units !== null && units.length === 0 && (
            <EmptyListError message="Brak produktów" icon={<Info />} />
          )}
        </Pagination>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default UtilizationReportUnitsList;
