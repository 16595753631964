/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import ShipmentUnitItemMobile from "./ShipmentUnitItemVersions/ShipmentUnitItemMobile";
import ShipmentUnitItemDesktop from "./ShipmentUnitItemVersions/ShipmentUnitItemDesktop";

const ShipmentUnitItem = ({ orderNumber, shipmentUnit }) => {
  const { isDesktopDisplay } = useMediaQ();
  return (
    <>
      {!isDesktopDisplay ? (
        <ShipmentUnitItemMobile
          orderNumber={orderNumber}
          shipmentUnit={shipmentUnit}
        />
      ) : (
        <ShipmentUnitItemDesktop
          orderNumber={orderNumber}
          shipmentUnit={shipmentUnit}
        />
      )}
    </>
  );
};

export default ShipmentUnitItem;
