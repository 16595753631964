/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SectionsList from "../SectionsList/SectionsList";
import AcceptButton from "../../AcceptButton/AcceptButton";
import CloseButton from "../../CloseButton/CloseButton";
import { storageDataService } from "../../../storageDataService/storageDataService";

const SectionSetterModal = ({
  open,
  Transition,
  handleClose,
  sections,
  defaultSectionOrder,
  setDefaultSectionOrder,
  dataStorageName,
}) => {
  const [currentSectionOrder, setCurrentSectionOrder] = useState(
    storageDataService.getItem(dataStorageName)
      ? storageDataService.getItem(dataStorageName)
      : defaultSectionOrder
  );

  const handleChange = () => {
    setDefaultSectionOrder(currentSectionOrder);
    storageDataService.setItem(dataStorageName, currentSectionOrder);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Edytuj sekcje"}</DialogTitle>
      <DialogContent>
        <SectionsList
          sections={sections}
          currentSectionOrder={currentSectionOrder}
          setCurrentSectionOrder={setCurrentSectionOrder}
        />
      </DialogContent>
      <DialogActions sx={{ gap: "0.5rem" }}>
        <AcceptButton onClick={handleChange} />
        <CloseButton onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
};

export default SectionSetterModal;
