/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getRequest,
  changeRequestStatus,
  clearRequest,
} from "../../../slices/requestSlice";
import RequestForm from "../RequestForm/RequestForm";
import PageHeader from "../../PageHeader/PageHeader";
import { ListAlt, Warning } from "@mui/icons-material";
import {
  DetailsButton,
  EditButton,
} from "../../FunctionalButtons/FunctionalButtons";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import RequestInfo from "./RequestInfo/RequestInfo";
import BlankSpace from "../../BlankSpace/BlankSpace";
import ChangeRequestStatusModal from "./ChangeRequestStatusModal/ChangeRequestStatusModal";
import RefreshButton from "../../RefreshButton/RefreshButton";
import Loading from "../../Loading/Loading";
import EmptyListError from "../../EmptyListError/EmptyListError";
import PageWrapper from "../../PageWrapper/PageWrapper";
import ReturnButton from "../../ReturnButton/ReturnButton";

const Request = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestId } = useLocation();
  const [areStatusOptionsVisible, setAreStatusOptionsVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isRequestFormVisible, setIsRequestFormVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getRequest(requestId));
    setIsDataLoading(false);
  }, [requestId, refresh]);

  const chosenStorageId = localStorage.getItem("chosenStorageId");
  const {
    requests: { chosen },
    chosenRequestId,
  } = useSelector((slice) => slice.request);
  const {
    userData: { user_id: userId, role },
  } = useSelector((state) => state.auth);

  useEffect(() => getData(), [getData]);

  const changeStatus = async (status) => {
    const data = {
      status,
      userId: userId,
    };
    await dispatch(changeRequestStatus({ requestId: chosenRequestId, data }));
    setAreStatusOptionsVisible(false);
    setRefresh((state) => !state);
  };

  useEffect(() => {
    return () => dispatch(clearRequest());
  }, []);

  if (isDataLoading) return <Loading />;

  return (
    <>
      {chosen !== null ? (
        <PageWrapper width="80%">
          <>
            <PageHeader
              titleText="Zapotrzebowanie"
              icon={<ListAlt />}
              onRightComponent={<ReturnButton />}
            >
              <RefreshButton onClick={() => setRefresh((state) => !state)} />
              {chosen.status === "Sent" &&
                (chosenStorageId === chosen.destinationStorage.id ||
                  userId === chosen.destinationStorage.user.id ||
                  role === "admin" ||
                  role === "warehouseman") && (
                  <DetailsButton
                    text="Zmień status"
                    onClick={() => setAreStatusOptionsVisible(true)}
                    styles={{ marginLeft: "1rem" }}
                  />
                )}
              {chosen.status !== "Sent" && chosen.childRequest === null && (
                <DetailsButton
                  text="Wyślij odpowiedź"
                  onClick={() => setIsRequestFormVisible(true)}
                  styles={{ marginLeft: "1rem" }}
                />
              )}
              <EditButton
                onClick={() => {
                  localStorage.setItem("chosenRequestId", chosen.id);
                  navigate("/request-edit");
                }}
              />
            </PageHeader>
            <BlankSpace />
            <RequestInfo request={chosen} />
            <BlankSpace />
            {chosen.childRequest !== null && (
              <RequestInfo type="response" request={chosen.childRequest} />
            )}
          </>

          <Dialog
            open={areStatusOptionsVisible}
            onClose={() => setAreStatusOptionsVisible(false)}
          >
            <ChangeRequestStatusModal
              changeStatus={changeStatus}
              setAreStatusOptionsVisible={setAreStatusOptionsVisible}
            />
          </Dialog>
          <Dialog
            open={chosen.childRequest === null ? isRequestFormVisible : false}
            onClose={() => setIsRequestFormVisible(false)}
          >
            <RequestForm
              type="response"
              destinationStorage={chosen.baseStorage}
              respTopic={`Re: ${chosen.topic}`}
              parentId={chosen.id}
              receiverId={chosen.sender}
              setRefresh={() => setRefresh((state) => !state)}
              setIsRequestFormVisible={() => setIsRequestFormVisible(false)}
            />
          </Dialog>
        </PageWrapper>
      ) : (
        <EmptyListError icon={<Warning />} message="Brak danych" />
      )}
    </>
  );
};

export default Request;
