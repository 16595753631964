/* eslint-disable react/prop-types */
import React from "react";
import TableGridWrapper from "./TableGridWrapper";

const predefineTableDataProps = {
  minHeight: "3.125rem",
  borderBottom: "0.0625rem solid #ccc",
  maxWidth: '100%'
  // overflowX: 'auto',
};

const TableDataGridWrapper = ({ children, styles = {}, headers, gap }) => {
  return (
    <TableGridWrapper
      styles={{ ...predefineTableDataProps, ...styles }}
      headers={headers}
      gap={gap}
    >
      {children}
    </TableGridWrapper>
  );
};

export default TableDataGridWrapper;
