/* eslint-disable react/prop-types */
import React from "react";
import CustomChip from "../../CustomChip/CustomChip";
import CompactDataDisplay from "../../CompactDataDisplay/CompactDataDisplay";
import { unitStatusChipStyles } from "../../../static/static";

const UnitItem = ({orderNumber, unit }) => {
  const {
    product: { partNumber, name, category, producer },
    serialNumber,
    status,
    quantity,
  } = unit;

  const data = [
    { title: "PartNumber:", value: partNumber },
    { title: "Nazwa:", value: name },
    { title: "Nr seryjny:", value: serialNumber },
    { title: "Kategoria:", value: category ? category.name : "" },
    { title: "Producent:", value: producer ? producer.name : "" },
    {
      title: "Status:",
      value: (
        <CustomChip
          label={status}
          sx={unitStatusChipStyles(status, "")}
          width="80%"
        />
      ),
    },

    { title: "Liczba:", value: quantity },
  ];
  return (
    <CompactDataDisplay
      title={`Produkt #${orderNumber}`}
      data={data}
      actions={<></>}
    />
  );
};

export default UnitItem;
