/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";
import EditButton from "../../../EditButton/EditButton";
import DeleteButton from "../../../DeleteButton/DeleteButton";

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
}));

const CategoryItemMobile = ({
  orderNumber,
  category,
  editCategory,
  delCategory,
}) => {
  const { name, description } = category;

  const data = [
    { title: "Nazwa:", value: name },
    { title: "Opis:", value: description ? description : "Brak" },
  ];

  return (
    <CompactDataDisplay
      title={`Kategoria #${orderNumber}`}
      data={data}
      actions={
        <ButtonWrapper>
          <EditButton onClick={() => editCategory()} />
          <DeleteButton onClick={() => delCategory()} />
        </ButtonWrapper>
      }
    />
  );
};

export default CategoryItemMobile;
