import React, { useState, useEffect, useCallback } from "react";
import { LocationOn } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { clearDevicesData } from "../../slices/deviceSlice";
import {
  clearLocation,
  getLocation,
  setChosenLocationId,
} from "../../slices/locationSlice";
import { useParams } from "react-router-dom";
import Loading from "../Loading/Loading";
import LocationDevices from "./LocationDevices/LocationDevices/LocationDevices";
import { useNavigate } from "react-router-dom";
import BlankSpace from "../BlankSpace/BlankSpace";
import PageHeader from "../PageHeader/PageHeader";
import RefreshButton from "../RefreshButton/RefreshButton";
import { EditButton } from "../FunctionalButtons/FunctionalButtons";
import LocationDetails from "./LocationDetails/LocationDetails";
import PageWrapper from "../PageWrapper/PageWrapper";
import ReturnButton from "../ReturnButton/ReturnButton";

const Location = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { chosenLocation } = useSelector((slice) => slice.location);
  const { locationId } = useParams();

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getLocation(locationId));
    setIsDataLoading(false);
  }, [refresh, locationId]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => {
      dispatch(clearDevicesData());
      dispatch(clearLocation());
    };
  }, []);

  if (isDataLoading) return <Loading />;

  return (
    <PageWrapper width="80%">
      <PageHeader
        titleText="Lokalizacja"
        icon={<LocationOn />}
        onRightComponent={<ReturnButton />}
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
        <EditButton
          onClick={() => {
            dispatch(setChosenLocationId(chosenLocation.code));
            navigate("/location-edit");
          }}
        />
      </PageHeader>
      <BlankSpace />
      <LocationDetails chosenLocation={chosenLocation} />
      <BlankSpace />
      <LocationDevices locationCode={chosenLocation.code} refresh={refresh} />
    </PageWrapper>
  );
};

export default Location;
