/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducersCount,
  getPaginatedProducers,
  clearProducers,
} from "../../../slices/producerSlice";
import ProducerItem from "../ProducerItem/ProducerItem";
import ProducerForm from "../../Producer/ProducerForm/ProducerForm";
import Pagination from "../../Pagination/Pagination";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../../../static/static";
import { Add, Factory, Warning } from "@mui/icons-material";
import Loading from "../../Loading/Loading";
import PageHeader from "../../PageHeader/PageHeader";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import RefreshButton from "../../RefreshButton/RefreshButton";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import BlankSpace from "../../BlankSpace/BlankSpace";
import TableHeader from "../../TableHeader/TableHeader";
import { producersListHeaders } from "../../../static/tablesHeaders";
import EmptyListError from "../../EmptyListError/EmptyListError";
import PageWrapper from "../../PageWrapper/PageWrapper";
import useMediaQ from "../../../hooks/useMediaQ";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import MobileDataSorter from "../../MobileDataSorter/MobileDataSorter";
import TableWrapper from "../../TableWrapper/TableWrapper";
import ButtonWrapper from "../../ButtonWrapper/ButtonWrapper";
import ReturnButton from "../../ReturnButton/ReturnButton";

const ProducersList = ({ refresh, setRefresh }) => {
  const { isPhoneDisplay, isTabletDisplay } = useMediaQ();
  const dispatch = useDispatch();
  const searchingRef = useRef("");
  const { producers, producersCount } = useSelector((state) => state.producer);
  const [searchingValue, setSearchingValue] = useState("");
  const [displaySearchingValue, setDisplaySearchingValue] = useState("");
  const [sorter, setSorter] = useState("name");
  const [sorting, setSorting] = useState("ascending");
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(DEFAULT_DISPLAY_ITEMS_QUANTITY);
  const [isAddProducerFormVisible, setAddProducerFormVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const closeAddForm = () => {
    setAddProducerFormVisible(false);
  };

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getProducersCount({
        searchingValue,
      })
    );
  }, [refresh, searchingValue]);

  const loadData = useCallback(async () => {
    await dispatch(
      getPaginatedProducers({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
      })
    );
    setIsDataLoading(false);
  }, [refresh, leftIndex, rightIndex, sorter, sorting, searchingValue]);

  useEffect(() => {
    loadDataCount();
  }, [loadDataCount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearProducers());
  }, []);

  return (
    <PageWrapper
      width={isPhoneDisplay ? "90%" : isTabletDisplay ? "80%" : "35%"}
    >
      <PageHeader
        titleText="Producenci"
        icon={<Factory />}
        onRightComponent={
          <ButtonWrapper>
            <DetailsButton
              startIcon={<Add />}
              text="Dodaj producenta"
              onClick={() => setAddProducerFormVisible(true)}
            />
            <ReturnButton />
          </ButtonWrapper>
        }
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <BlankSpace />
      <SectionWrapper>
        <AdditionalFunctionalitiesWrapper>
          <CustomSearchField
            displaySearchingValue={displaySearchingValue}
            setDisplaySearchingValue={setDisplaySearchingValue}
            searchingValue={searchingValue}
            setSearchingValue={setSearchingValue}
            searchingRef={searchingRef}
            placeholder="szukaj producentów..."
          />
          <MobileDataSorter
            tableHeader={producersListHeaders}
            setOuterSorter={setSorter}
            setOuterSorting={setSorting}
            defaultSorter="name"
          />
        </AdditionalFunctionalitiesWrapper>
        <TableWrapper>
          <TableHeader
            headers={producersListHeaders}
            setSorter={setSorter}
            setSorting={setSorting}
            gap="1%"
          />
          <Pagination
            collectionSize={producersCount}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            setLeftIndex={setLeftIndex}
            setRightIndex={setRightIndex}
          >
            {isDataLoading ? (
              <Loading />
            ) : (
              <>
                {producers !== null &&
                  producers.map((producer, id) => (
                    <ProducerItem
                      orderNumber={leftIndex + id + 1}
                      key={producer.id}
                      producer={producer}
                      setRefresh={setRefresh}
                    />
                  ))}
              </>
            )}
            {producers !== null && producers.length === 0 && (
              <EmptyListError icon={<Warning />} message="Brak producentów" />
            )}
          </Pagination>
        </TableWrapper>

        <Dialog open={isAddProducerFormVisible} onClose={() => closeAddForm()}>
          <ProducerForm
            closeAddForm={() => closeAddForm()}
            setRefresh={() => setRefresh((state) => !state)}
          />
        </Dialog>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default ProducersList;
