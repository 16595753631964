/* eslint-disable react/prop-types */
import React from 'react';
import CompactDataDisplay from '../../../CompactDataDisplay/CompactDataDisplay';
import DetailsButton from '../../../DetailsButton/DetailsButton';
import { Add } from '@mui/icons-material';

const AddUnitItemMobile = ({ product, orderNumber, addItem }) => {
  const { partNumber, name, category, producer, actualQuantity } = product;

  const data = [
    { title: "PartNumber:", value: partNumber },
    { title: "Nazwa:", value: name },
    { title: "Kategoria:", value: category ? category.name : "Brak" },
    { title: "Producent:", value: producer ? producer.name : "Brak" },
    {
      title: "Aktualna liczba:",
      value: actualQuantity,
      styles: actualQuantity > 0 ? { color: "#0e9c34" } : { color: "red" },
    },
  ];
  return (
    <CompactDataDisplay
      title={`Produkt #${orderNumber}`}
      data={data}
      actions={
        <>
          <DetailsButton
            text="Dodaj"
            startIcon={<Add />}
            onClick={() => addItem()}
          />
        </>
      }
    />
  );
};

export default AddUnitItemMobile;