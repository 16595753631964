/* eslint-disable react/prop-types */
import React from "react";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../TableGridWrapper/TableDataGridWrapper";
import { utilizationReportsHeaders } from "../../../static/tablesHeaders";
import { Typography } from "@mui/material";
import { dateFormatView } from "../../../static/functions";
import { useNavigate } from "react-router-dom";
import DetailsButton from "../../DetailsButton/DetailsButton";
import { useDispatch } from "react-redux";
import { setChosenUtilizationReportId } from "../../../slices/utilizationReportSlice";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const UtilizationReportItemDesktop = ({ orderNumber, utilizationReport }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, creationDate, createdBy } = utilizationReport;

  const navigateToReport = () => {
    dispatch(setChosenUtilizationReportId(id));
    navigate(`/utilizationReports/${id}`);
  };

  return (
    <TableDataGridWrapper headers={utilizationReportsHeaders} gap="0.625rem">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{dateFormatView(creationDate)}</CustomDataDisplay>
      <CustomDataDisplay>{`${createdBy.name} ${createdBy.surname}`}</CustomDataDisplay>
      <DetailsButton
        onClick={() => navigateToReport()}
        variant="contained"
        text="szczegóły"
      />
    </TableDataGridWrapper>
  );
};

export default UtilizationReportItemDesktop;
