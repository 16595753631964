/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";

const DateRangeSelector = ({
  labelName,
  datesRange,
  setDatesRange,
  maxDate = "",
  sx={}
}) => {
  const [timeRangeType, setTimeRangeType] = useState("");

  const changeTimeRange = (value) => {
    setTimeRangeType(value);
    if (value === "") {
      setDatesRange(["", ""]);
    } else if (value === "30-days") {
      const date30DaysEarlier = new Date();
      date30DaysEarlier.setDate(date30DaysEarlier.getDate() - 30);
      setDatesRange([date30DaysEarlier, new Date()]);
    } else {
      setDatesRange(["", ""]);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "end",
        justifyContent: "start",
        ...sx
      }}
    >
      <FormControl>
        <FormLabel id="date-range">{labelName}</FormLabel>
        <RadioGroup
          aria-labelledby="date-range"
          value={timeRangeType}
          onChange={(e) => changeTimeRange(e.target.value)}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "end",
          }}
        >
          <FormControlLabel
            value=""
            control={<Radio />}
            label="Brak"
            sx={{ height: "2rem" }}
          />
          <FormControlLabel
            value="30-days"
            control={<Radio />}
            label="ostatnie 30 dni"
            sx={{ height: "2rem" }}
          />
          <FormControlLabel
            value="custom"
            control={<Radio />}
            label="Niestandardowe"
            sx={{ height: "2rem" }}
          />
        </RadioGroup>
      </FormControl>
      {timeRangeType === "custom" ? (
        <CustomDatePicker
          datesRange={datesRange}
          setDatesRange={(dates) => setDatesRange(dates)}
          maxDate={maxDate}
          width="60%"
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default DateRangeSelector;
