/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Dialog } from "@mui/material";
import { BusinessOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearChosenCompanyData,
  getCompany,
} from "../../../slices/companySlice";
import { useParams } from "react-router-dom";
import CompanyIntegrations from "../Integrations/CompanyIntegrations";
import IntegrationForm from "../Integrations/IntegrationForm/IntegrationForm";
import Locations from "../Locations/Locations/Locations";
import PageHeader from "../../PageHeader/PageHeader";
import RefreshButton from "../../RefreshButton/RefreshButton";
import { EditButton } from "../../FunctionalButtons/FunctionalButtons";
import BlankSpace from "../../BlankSpace/BlankSpace";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import Loading from "../../Loading/Loading";
import PageWrapper from "../../PageWrapper/PageWrapper";
import ReturnButton from "../../ReturnButton/ReturnButton";

const Company = () => {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chosenCompany, generatedPin, companyError } = useSelector(
    (slice) => slice.company
  );
  const [refresh, setRefresh] = useState(false);
  const [isGeneratePinModalIsOpen, setIsGeneratePinModalIsOpen] =
    useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getCompany(companyId));
    setIsDataLoading(false);
  }, [companyId, refresh]);

  const editCompany = () => {
    localStorage.setItem("chosenCompanyId", chosenCompany.id);
    navigate("/company-edit");
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => dispatch(clearChosenCompanyData());
  }, []);

  if (isDataLoading) return <Loading />;

  if (companyError) {
    return (
      <Box sx={{ position: "relative", width: "100%", height: "100vh" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            gap: "1em",
            color: "red",
          }}
        >
          <BusinessOutlined sx={{ height: "5em", width: "5em" }} />
          <Typography sx={{ fontSize: "2em" }}>Nie ma takiej firmy</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {chosenCompany !== null ? (
        <PageWrapper width="85%">
          <PageHeader
            titleText="Firma"
            icon={<BusinessOutlined />}
            onRightComponent={<ReturnButton />}
          >
            <RefreshButton onClick={() => setRefresh((state) => !state)} />
            <EditButton onClick={() => editCompany()} />
          </PageHeader>
          <BlankSpace />
          <CompanyDetails chosenCompany={chosenCompany} />
          <BlankSpace />
          <CompanyIntegrations
            companyId={companyId}
            refresh={refresh}
            setRefresh={setRefresh}
            openGeneratePinModal={() => setIsGeneratePinModalIsOpen(true)}
          />
          <BlankSpace />
          <Locations companyId={chosenCompany.id} refresh={refresh} />
          <Dialog
            open={isGeneratePinModalIsOpen}
            onClose={() => setIsGeneratePinModalIsOpen(false)}
          >
            <IntegrationForm
              companyId={companyId}
              closeForm={() => setIsGeneratePinModalIsOpen(false)}
            />
          </Dialog>
        </PageWrapper>
      ) : (
        <Typography>Brak danych firmy</Typography>
      )}
    </>
  );
};

export default Company;
