/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Modal, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  backgroundColor: "white",
  border: "0.0625rem solid #eee",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  [theme.breakpoints.down("laptop")]: {
    width: "80%",
    height: "90vh",
  },
}));

const FullTextOption = ({ open, handleClose, text }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CustomBox>
        <Typography
          id="modal-modal-description"
          sx={{
            textAlign: "justify",
            textJustify: "inter-word",
            margin: "1.25rem",
          }}
        >
          {text}
        </Typography>
      </CustomBox>
    </Modal>
  );
};

const DataContent = styled(Typography)(() => ({
  fontWeight: 400,
}));

const LongerTextModal = ({ text, styles, quantity=30 }) => {
  const [isFullTextShown, setIsFullTextShown] = useState(false);
  return (
    <>
      <DataContent sx={styles}>
        {String(text).substring(0, quantity)}
        {String(text).length > quantity && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setIsFullTextShown(true)}
          >
            [...]
          </span>
        )}
      </DataContent>
      {isFullTextShown && (
        <FullTextOption
          open={isFullTextShown}
          handleClose={() => setIsFullTextShown(false)}
          text={text}
        />
      )}
    </>
  );
};

export default LongerTextModal;
