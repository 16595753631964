/* eslint-disable react/prop-types */
import React, { useState } from "react";
import CustomChip from "../../../CustomChip/CustomChip";
import {
  roles,
  userRolesChipStyles,
  userSourceChipStyles,
  userSources,
} from "../../../../static/static";
import { Box, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";
import EditButton from "../../../EditButton/EditButton";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeUserRole } from "../../../../slices/userSlice";
import AcceptButton from "../../../AcceptButton/AcceptButton";
import { Clear, Settings } from "@mui/icons-material";

const ChangeRoleWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
  flexWrap: 'wrap'
}));

const UserListItemMobile = ({ orderNumber, user, setRefresh }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, username, name, surname, role: defaultRole, source } = user;
  const [currentRole, setCurrentRole] = useState(defaultRole);
  const [isRoleChanging, setIsRoleChanging] = useState(false);

  const sendChangedRole = async () => {
    const data = {
      id,
      role: currentRole,
    };
    await dispatch(changeUserRole(data));
    setIsRoleChanging(false);
    setRefresh((state) => !state);
  };

  const data = [
    { title: "ID:", value: id },
    { title: "Login:", value: username },
    { title: "Imię:", value: name },
    { title: "Nazwisko:", value: surname },
    {
      title: "Rola:",
      value: !isRoleChanging ? (
        <CustomChip
          label={roles[defaultRole]}
          sx={userRolesChipStyles(defaultRole, "")}
          width="30%"
        />
      ) : (
        <Select
          id={`change-user-role-${id}`}
          value={currentRole}
          onChange={(event) => setCurrentRole(event.target.value)}
          size="small"
        >
          {Object.keys(roles).map((role) => (
            <MenuItem key={role} value={role} disabled={role === defaultRole}>
              {roles[role]}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      title: "Źródło:",
      value: (
        <CustomChip
          label={userSources[source]}
          sx={userSourceChipStyles[source]}
          width="30%"
        />
      ),
    },
  ];
  return (
    <CompactDataDisplay
      title={`Użytkownik #${orderNumber}`}
      data={data}
      actions={
        <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "center", flexWrap: 'wrap'}}>
          {!isRoleChanging ? (
            <EditButton
              text="Zmień rolę"
              onClick={() => setIsRoleChanging(true)}
            />
          ) : (
            <ChangeRoleWrapper>
              <AcceptButton
                onClick={() => sendChangedRole()}
                text="Potwierdź"
                disabled={currentRole === defaultRole}
              />
              <EditButton
                text="Anuluj"
                startIcon={<Clear />}
                onClick={() => {
                  setIsRoleChanging(false);
                  setCurrentRole(defaultRole);
                }}
              />
            </ChangeRoleWrapper>
          )}
          <EditButton
            onClick={() => navigate(`/user-management/${id}`)}
            text="Edytuj"
            startIcon={<Settings />}
          />
        </Box>
      }
    />
  );
};

export default UserListItemMobile;
