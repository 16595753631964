/* eslint-disable react/prop-types */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  deleteShipment,
  setChosenShipmentId,
} from "../../../slices/shipmentSlice";
import useMediaQ from "../../../hooks/useMediaQ";
import ShipmentItemDesktop from "./ShipmentItemVersions/ShipmentItemDesktop";
import ShipmentItemMobile from "./ShipmentItemVersions/ShipmentItemMobile";

const ShipmentItem = ({ shipment, refresh, orderNumber }) => {
  const { isDesktopDisplay } = useMediaQ();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToItem = () => {
    dispatch(setChosenShipmentId(shipment.id));
    navigate(`/shipments/${shipment.id}`);
  };

  const deleteItem = async () => {
    await dispatch(deleteShipment(shipment.id));
    refresh();
  };

  return (
    <>
      {isDesktopDisplay ? (
        <ShipmentItemDesktop
          orderNumber={orderNumber}
          shipment={shipment}
          navigateToItem={navigateToItem}
          deleteItem={deleteItem}
        />
      ) : (
        <ShipmentItemMobile     
          orderNumber={orderNumber}
          shipment={shipment}
          navigateToItem={navigateToItem}
          deleteItem={deleteItem}/>
      )}
    </>
  );
};

export default ShipmentItem;
