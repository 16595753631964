/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { ShipmentsHeaders } from "../../../../static/tablesHeaders";
import { dateFormatView } from "../../../../static/functions";
import CustomChip from "../../../CustomChip/CustomChip";
import { shipmentStatus, shipmentStatusChipStyles } from "../../../../static/static";
import DetailsButton from "../../../DetailsButton/DetailsButton";
import DeleteButton from "../../../DeleteButton/DeleteButton";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const ShipmentItemDesktop = ({
  orderNumber,
  shipment,
  navigateToItem,
  deleteItem,
}) => {
  const {
    sendDate,
    deliveredDate,
    letterNumber,
    creationDate,
    baseStorage: { name: baseStorageName },
    destinationStorage: { name: destinationStorageName },
    status,
  } = shipment;

  return (
    <TableDataGridWrapper headers={ShipmentsHeaders} gap="0.5rem">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{dateFormatView(creationDate)}</CustomDataDisplay>
      <CustomDataDisplay>
        {sendDate ? dateFormatView(sendDate) : "Brak"}
      </CustomDataDisplay>
      <CustomDataDisplay>
        {deliveredDate ? dateFormatView(deliveredDate) : "Brak"}
      </CustomDataDisplay>

      <CustomDataDisplay>{letterNumber}</CustomDataDisplay>
      <CustomDataDisplay>{baseStorageName}</CustomDataDisplay>
      <CustomDataDisplay>{destinationStorageName}</CustomDataDisplay>
      <CustomChip
        label={shipmentStatus[status]}
        sx={shipmentStatusChipStyles(status, "")}
      />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <DetailsButton onClick={() => navigateToItem()} text="szczegóły" />
        {status === "Created" && (
          <DeleteButton onClick={() => deleteItem()} variant="contained" />
        )}
      </Box>
    </TableDataGridWrapper>
  );
};

export default ShipmentItemDesktop;
