/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PageHeader from "../../../../PageHeader/PageHeader";
import { ArrowDropDown, Check, Clear } from "@mui/icons-material";
import {
  AcceptButton,
  EditButton,
} from "../../../../FunctionalButtons/FunctionalButtons";

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  width: "26rem",
  minHeight: "32rem",
  maxHeight: "38rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.down("tablet")]: {
    width: "16rem",
  },
}));

const CustomInnerWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
}));

const TextFieldsWrapper = styled(Box)(() => ({
  marginTop: "1rem",
  display: "flex",
  gap: "1rem",
  flexWrap: "wrap",
  justifyContent: "center",
}));

const ButtonWrapper = styled(Box)(() => ({
  marginTop: "0.5rem",
  bgcolor: "red",
  display: "flex",
  gap: "2rem",
  justifyContent: "center",
}));

const InfoWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "start",
}));

const PositiveMessageWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
}));

const FaultModal = ({
  unit,
  correctness = null,
  setCorrectness,
  setIsModalOpen,
  report,
  setReport,
}) => {
  const [realNumber, setRealNumber] = useState(
    correctness ? correctness.realUnits : 0
  );
  const [positiveNumber, setPositiveNumber] = useState(
    correctness ? correctness.positiveUnits : 0
  );
  const [negativeNumber, setNegativeNumber] = useState(
    correctness ? correctness.negativeUnits : 0
  );
  const [serialNumber, setSerialNumber] = useState(
    correctness ? correctness.serialNumber : unit.serialNumber
  );
  const [comments, setComments] = useState(
    correctness ? correctness.comments : ""
  );

  const acceptModal = () => {
    setCorrectness({
      status: false,
      realUnits: realNumber,
      positiveUnits: positiveNumber,
      negativeUnits: negativeNumber,
      serialNumber,
      comments
    });
    const reportCopy = [...report];
    const findingIndex = reportCopy.findIndex(
      (element) => element.id === unit.id
    );
    if (findingIndex * 1 !== -1) {
      reportCopy.splice(findingIndex, 1);
      setReport([
        ...reportCopy,
        {
          id: unit.id,
          status: false,
          realUnits: realNumber,
          positiveUnits: positiveNumber,
          negativeUnits: negativeNumber,
          serialNumber: serialNumber,
          comments: comments,
        },
      ]);
    } else {
      setReport((prevState) => [
        ...prevState,
        {
          id: unit.id,
          status: false,
          realUnits: realNumber,
          positiveUnits: positiveNumber,
          negativeUnits: negativeNumber,
          serialNumber: serialNumber,
          comments: comments,
        },
      ]);
    }
    setIsModalOpen(false);
  };

  const changeToCorrect = () => { 
    setCorrectness({
      status: true,
      realUnits: unit.quantity,
      positiveUnits: unit.quantity,
      negativeUnits: 0,
      serialNumber: unit.serialNumber,
      comments: "",
    });
    const reportCopy = [...report];
    const findingIndex = reportCopy.findIndex(
      (element) => element.id === unit.id
    );
    if (findingIndex * 1 !== -1) {
      reportCopy.splice(findingIndex, 1);
      setReport([
        ...reportCopy,
        {
          id: unit.id,
          status: true,
          realUnits: unit.quantity,
          positiveUnits: unit.quantity,
          negativeUnits: 0,
          serialNumber: unit.serialNumber,
          comments: "",
        },
      ]);
    } else {
      setReport((prevState) => [
        ...prevState,
        {
          id: unit.id,
          status: true,
          realUnits: unit.quantity,
          positiveUnits: unit.quantity,
          negativeUnits: 0,
          serialNumber: unit.serialNumber,
          comments: "",
        },
      ]);
    }
    setIsModalOpen(false);
  };

  return (
    <CustomBox>
      <CustomInnerWrapper>
        <PageHeader
          size="medium"
          icon={<ArrowDropDown />}
          titleText="Wskazanie nieprawidłowości"
        />
        <InfoWrapper>
          <Typography>
            <span style={{ fontWeight: 700 }}>Nazwa:</span>
            <span style={{ marginLeft: "0.5rem" }}>{unit.product.name}</span>
          </Typography>
          <Typography>
            <span style={{ fontWeight: 700 }}>PartNumber:</span>
            <span style={{ marginLeft: "0.5rem" }}>
              {unit.product.partNumber}
            </span>
          </Typography>
          <Typography>
            <span style={{ fontWeight: 700 }}>Nr seryjny:</span>
            <span style={{ marginLeft: "0.5rem" }}>
              {unit.product.serialNumber}
            </span>
          </Typography>
          <Typography>
            <span style={{ fontWeight: 700 }}>Zadeklarowana liczba:</span>
            <span style={{ color: "red", marginLeft: "0.5rem" }}>
              {unit.quantity}
            </span>
          </Typography>
        </InfoWrapper>
        <TextFieldsWrapper>
          <TextField
            label="Rzeczywista liczba"
            value={realNumber}
            type="number"
            onChange={(e) => {
              const newNumber = e.target.value;
              setRealNumber(newNumber);
              if (positiveNumber > newNumber) setPositiveNumber(newNumber);
              if (negativeNumber > newNumber) setNegativeNumber(newNumber);
            }}
            InputProps={{ inputProps: { min: 0, max: unit.quantity } }}
            variant="standard"
            sx={{ flexBasis: "25%" }}
          />
          <TextField
            label="L. prawidłowych"
            value={positiveNumber}
            type="number"
            onChange={(e) => setPositiveNumber(e.target.value)}
            InputProps={{
              inputProps: { min: 0, max: realNumber - negativeNumber },
            }}
            variant="standard"
            sx={{ flexBasis: "25%" }}
          />
          <TextField
            label="L. nieprawidłowych"
            value={negativeNumber}
            type="number"
            onChange={(e) => setNegativeNumber(e.target.value)}
            InputProps={{
              inputProps: { min: 0, max: realNumber - positiveNumber },
            }}
            variant="standard"
            sx={{ flexBasis: "25%" }}
          />
          {unit.product.type === "Quantitative" && (
            <TextField
              label="Poprawny nr seryjny"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
              sx={{ flexBasis: "90%" }}
            />
          )}
          <TextField
            label="Uwagi"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            sx={{ flexBasis: "90%" }}
            multiline
            minRows={4}
            maxRows={4}
          />
        </TextFieldsWrapper>
        <PositiveMessageWrapper>
          <Typography sx={{fontSize: '0.9rem'}}>Zmiana na prawidłowy:</Typography>
          <IconButton onClick={() => changeToCorrect()}>
            <Check sx={{fontSize: '1rem'}} />
          </IconButton>
        </PositiveMessageWrapper>
        <ButtonWrapper>
          <AcceptButton
            onClick={() => acceptModal()}
            disabled={
              realNumber * 1 !== positiveNumber * 1 + negativeNumber * 1 ||
              (realNumber * 1 === positiveNumber * 1 && comments === "")
            }
          />
          <EditButton
            text="Anuluj"
            startIcon={<Clear />}
            onClick={() => setIsModalOpen(false)}
          />
        </ButtonWrapper>
      </CustomInnerWrapper>
    </CustomBox>
  );
};

export default FaultModal;
