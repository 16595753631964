/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import PageHeader from "../../PageHeader/PageHeader";
import { Add } from "@mui/icons-material";
import {
  AcceptButton,
  CloseButton,
} from "../../FunctionalButtons/FunctionalButtons";
import { changeToInProgressShipment } from "../../../slices/shipmentSlice";
import { useDispatch } from "react-redux";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "12rem",
  width: "30rem",
  gap: "1rem",
  [theme.breakpoints.down("tablet")]: {
    width: "90%",
    padding: "1rem",
  },
}));

const InnerBox = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
  marginTop: "0.5rem",
}));

const ToInProgressModal = ({
  shipmentId,
  isStatusChangeToInProgressModalVisible,
  setIsStatusChangeToInProgressModalVisible,
  setRefresh,
}) => {
  const dispatch = useDispatch();
  const [currentLetterNumber, setCurrentLetterNumber] = useState("");
  const [currentLetterNumberError, setCurrentLetterNumberError] =
    useState(false);
  const [buttonLock, setButtonLock] = useState(false);

  const changeStatusToInProgress = useCallback(async () => {
    if (currentLetterNumber.length > 0) {
      const data = {
        letterNumber: currentLetterNumber,
      };
      await dispatch(
        changeToInProgressShipment({ shipmentId: shipmentId, data })
      );
      setIsStatusChangeToInProgressModalVisible(false);
      setRefresh((state) => !state);
    } else {
      setCurrentLetterNumberError(true);
    }
  }, [currentLetterNumber]);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (keyCode === 13 && !buttonLock) {
        changeStatusToInProgress();
        setButtonLock(true);
        setTimeout(() => setButtonLock(false), 5000);
      }
    },
    [buttonLock, changeStatusToInProgress]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <Dialog
      open={isStatusChangeToInProgressModalVisible}
      onClose={() => setIsStatusChangeToInProgressModalVisible(false)}
    >
      <CustomBox>
        <PageHeader
          titleText="Podaj numer listu przewozowego"
          size="medium"
          icon={<Add />}
        />
        <TextField
          value={currentLetterNumber}
          onChange={(e) => {
            if (e.target.value.length > 0) {
              setCurrentLetterNumberError(false);
            }
            setCurrentLetterNumber(e.target.value);
          }}
          error={currentLetterNumberError}
          size="small"
          label="Nr listu przewozowego"
        />
        <InnerBox>
          <AcceptButton
            onClick={() => changeStatusToInProgress()}
            disabled={currentLetterNumber.length === 0}
          />
          <CloseButton
            onClick={() => {
              setIsStatusChangeToInProgressModalVisible(false);
              setCurrentLetterNumber("");
            }}
            text="Zamknij"
          />
        </InnerBox>
      </CustomBox>
    </Dialog>
  );
};

export default ToInProgressModal;
