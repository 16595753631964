/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Typography} from "@mui/material";
import { useDispatch } from "react-redux";
import { executeAssignmentTask } from "../../../slices/taskSlice";
import { Check, Clear } from "@mui/icons-material";
import AcceptButton from "../../AcceptButton/AcceptButton";
import EditButton from "../../EditButton/EditButton";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "8.125rem",
  width: "25rem",
  padding: "0.5rem",
  [theme.breakpoints.down("laptop")]: {
    width: "20rem",
    height: "12rem",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "14rem",
    height: "12rem",
  },
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  gap: "1.5rem",
  marginTop: "1rem",
}));

const AssignmentFinalModal = ({
  data,
  setIsFinalModal,
  setIsAssignmentModalVisible,
  setRefresh,
}) => {
  const dispatch = useDispatch();

  const confirmDecision = async () => {
    await dispatch(executeAssignmentTask(data));
    setIsFinalModal(false);
    setIsAssignmentModalVisible(false);
    setRefresh((state) => !state);
  };

  return (
    <Wrapper>
      <Typography sx={{ fontSize: "1.25rem" }}>
        Czy na pewno chcesz zatwierdzić wybór?
      </Typography>
      <Typography
        sx={{
          fontSize: "0.75rem",
          color: "red",
          textAlign: "justify",
          textJustify: "inter-word",
        }}
      >
        Po zatwierdzeniu nie będzie można zmienić proponowanego serwisanta do
        momentu odrzucenia przez niego propozycji!
      </Typography>
      <ButtonWrapper>
        <AcceptButton
          onClick={() => confirmDecision()}
          startIcon={<Check />}
          text="Tak"
        />
        <EditButton
          onClick={() => setIsFinalModal(false)}
          startIcon={<Clear />}
          text="Nie"
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default AssignmentFinalModal;
