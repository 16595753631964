/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../../hooks/useMediaQ";
import RequestSummaryItemDesktop from "./RequestSummaryItemVersions/RequestSummaryItemDesktop";
import RequestSummaryItemMobile from "./RequestSummaryItemVersions/RequestSummaryItemMobile";

const RequestSummaryItem = ({ request, orderNumber }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {!isDesktopDisplay ? (
        <RequestSummaryItemMobile request={request} orderNumber={orderNumber} />
      ) : (
        <RequestSummaryItemDesktop
          request={request}
          orderNumber={orderNumber}
        />
      )}
    </>
  );
};

export default RequestSummaryItem;
