/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomBox = styled(Box)(() => ({
  border: "0.0625rem solid #ddd",
  padding: "0.625rem",
  borderRadius: "1.25rem",
  backgroundColor: "rgb(249, 251, 252)",
}));

const SectionWrapper = ({ children, sx }) => {
  return <CustomBox sx={sx}>{children}</CustomBox>;
};

export default SectionWrapper;
