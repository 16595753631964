import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import axios from 'axios';
import dataService from '../dataService/dataService';
import {
  API
} from '../static/static';
import authService from '../auth/authService';
// import { dataURLtoFile } from '../static/functions';


//--------------------------Commission API---------------------------------------------
export const getCommissions = createAsyncThunk('commissions/get', async () => {
  const resData = await dataService.get(`${API}commissions`);
  return resData;
});

export const getPaginatedCommissions = createAsyncThunk('commissions/get/pagination', async (data) => {
  const {
    leftIndex,
    rightIndex,
    sortingParam,
    sortingOrder,
    searchingValue,
    type,
    status,
    createdBy,
    deviceId,
    companyId,
    problemCode,
    finishCode,
    creationDateStart,
    creationDateEnd,
    finishDateStart,
    finishDateEnd,
  } = data;
  const resData = await dataService.get(`${API}commissions/pagination/?leftIndex=${leftIndex}&rightIndex=${rightIndex}&sortingParam=${sortingParam}&sortingOrder=${sortingOrder}&searchingPhrase=${searchingValue}&status=${status}&type=${type}&createdBy=${createdBy}&deviceId=${deviceId}&companyId=${companyId}&problemCode=${problemCode}&finishCode=${finishCode}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&finishDateStart=${finishDateStart}&finishDateEnd=${finishDateEnd}`);
  return resData;
});

export const getCommissionsCount = createAsyncThunk('commissions/get/count', async (data) => {
  const {
    searchingValue,
    type,
    status,
    createdBy,
    deviceId,
    companyId,
    problemCode,
    finishCode,
    creationDateStart,
    creationDateEnd,
    finishDateStart,
    finishDateEnd,
  } = data;
  const resData = await dataService.get(`${API}commissions/count/?searchingPhrase=${searchingValue}&status=${status}&type=${type}&createdBy=${createdBy}&deviceId=${deviceId}&companyId=${companyId}&problemCode=${problemCode}&finishCode=${finishCode}&creationDateStart=${creationDateStart}&creationDateEnd=${creationDateEnd}&finishDateStart=${finishDateStart}&finishDateEnd=${finishDateEnd}`);
  return resData;
});

export const getCommission = createAsyncThunk('commission/get', async (commissionId, {
  getState
}) => {
  const {
    chosenCommissionId
  } = getState().commission;
  const resData = commissionId !== undefined ? await dataService.get(`${API}commission/${commissionId}`) : chosenCommissionId ? await dataService.get(`${API}commission/${chosenCommissionId}`) : await dataService.get(`${API}commission/${localStorage.getItem('chosenCommissionId')}`);
  return resData;
});

export const createCommission = createAsyncThunk('commission/create', async (data) => {
  const resData = await dataService.post(`${API}commission-add/`, data);
  return resData;
});

export const updateCommission = createAsyncThunk('commission/update', async (data, {
  getState
}) => {
  const {
    chosenCommissionId
  } = getState().commission;
  const resData = await dataService.put(`${API}commission/u/${chosenCommissionId}`, data);
  return resData;
});

export const deleteCommission = createAsyncThunk('commission/delete', async (_, {
  getState
}) => {
  const {
    chosenCommissionId
  } = getState().commission;
  const resData = await dataService.delete(`${API}commission/d/${chosenCommissionId}`);
  return resData;
});

export const getCommissionMigratedUnits = createAsyncThunk('commission/migratedUnits/get', async (commissionId) => {
  const resData = await dataService.get(`${API}commission/unitsMigration/${commissionId}`);
  return resData;
});

export const createDocumentation = createAsyncThunk('documentation/create', async (data) => {
  let formData = new FormData();
  formData.append('taskId', data.taskId);
  formData.append('commissionId', data.commissionId);
  for (let i = 0; i < data.documentations.length; i++) {
    formData.append(`files[${i}]`, data.documentations[i]);
  }
  const resData = await axios.post(`${API}documentation/`, formData, {
    headers: {
      ...authService.getAuthHeader(),
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryqtrzr9jKAQDgGjk2'
    }
  });
  return resData;
});

export const getCommissionDocumentations = createAsyncThunk('documentations/commission/get', async (_, {
  getState
}) => {
  const {
    chosenCommissionId
  } = getState().commission;
  const resData = chosenCommissionId ? await dataService.get(`${API}documentation/commission/${chosenCommissionId}`) : await dataService.get(`${API}documentation/commission/${localStorage.getItem('chosenCommissionId')}`);
  return resData;
});

export const getDocumentation = createAsyncThunk('documentations/get', async (data) => {
  const {
    commissionId,
    index
  } = data;
  const resData = await dataService.abget(`${API}documentations/${commissionId}/${index}`);
  return resData;
});

//-----------------------------------------API Locations --------------------------------------------------



export const addCompany = createAsyncThunk('company/add', async (data) => {
  const resData = await dataService.post(`${API}company-add/`, data);
  return resData;
});


//--------------------------- Commission Codes API --------------------------

export const getCommissionCodes = createAsyncThunk('commission/codes/get', async () => {
  const resData = await dataService.get(`${API}commissions/codes`);
  return resData;
});

export const getCommissionCode = createAsyncThunk('commission/code/get', async (codeId) => {
  const resData = await dataService.get(`${API}commissions/code/${codeId}`);
  return resData;
});

export const addCommissionCode = createAsyncThunk('commission/codes/add', async (data) => {
  const resData = await dataService.post(`${API}commissions/code/create/`, data);
  return resData;
});

export const updateCommissionCode = createAsyncThunk('commission/codes/update', async ({
  data,
  codeId
}) => {
  const resData = await dataService.put(`${API}commissions/code/update/${codeId}/`, data);
  return resData;
});

export const getCommissionOpenCodes = createAsyncThunk('commission/codes/open/get', async () => {
  const resData = await dataService.get(`${API}commissions/openCodes`);
  return resData;
});

export const getCommissionCloseCodes = createAsyncThunk('commission/codes/close/get', async () => {
  const resData = await dataService.get(`${API}commissions/closeCodes`);
  return resData;
});

export const getCommissionFilteredOpenCodes = createAsyncThunk('commission/codes/open/filtered/get', async (searchingValue) => {
  const resData = await dataService.get(`${API}commissions/openCodes/filter/?searchingPhrase=${searchingValue}`);
  return resData;
});

export const getCommissionFilteredCloseCodes = createAsyncThunk('commission/codes/close/filtered/get', async (searchingValue) => {
  const resData = await dataService.get(`${API}commissions/closeCodes/filter/?searchingPhrase=${searchingValue}`);
  return resData;
});


//--------------------------- Commission Problem Codes API --------------------------

export const getCommissionProblemCodes = createAsyncThunk('commission/problemCodes/get', async () => {
  const resData = await dataService.get(`${API}commissions/problemCodes`);
  return resData;
});

export const getCommissionFilteredProblemCodes = createAsyncThunk('commission/problemCodes/filtered/get', async (searchingValue) => {
  const resData = await dataService.get(`${API}commissions/problemCodes/filter/?searchingPhrase=${searchingValue}`);
  return resData;
});

export const getCommissionProblemCode = createAsyncThunk('commission/problemCode/get', async (codeId) => {
  const resData = await dataService.get(`${API}commissions/problemCode/${codeId}`);
  return resData;
});

export const addCommissionProblemCode = createAsyncThunk('commission/problemCodes/add', async (data) => {
  const resData = await dataService.post(`${API}commissions/problemCode/create/`, data);
  return resData;
});

export const updateCommissionProblemCode = createAsyncThunk('commission/problemCodes/update', async ({
  data,
  codeId
}) => {
  const resData = await dataService.put(`${API}commissions/problemCode/update/${codeId}/`, data);
  return resData;
});

//--------------------------- Commission Finish Codes API --------------------------

export const getCommissionFinishCodes = createAsyncThunk('commission/finishCodes/get', async () => {
  const resData = await dataService.get(`${API}commissions/finishCodes`);
  return resData;
});

export const getCommissionFinishCode = createAsyncThunk('commission/finishCode/get', async (codeId) => {
  const resData = await dataService.get(`${API}commissions/finishCode/${codeId}`);
  return resData;
});

export const getCommissionFilteredFinishCodes = createAsyncThunk('commission/finishCodes/filtered/get', async (searchingValue) => {
  const resData = await dataService.get(`${API}commissions/finishCodes/filter/?searchingPhrase=${searchingValue}`);
  return resData;
});


export const addCommissionFinishCode = createAsyncThunk('commission/finishCodes/add', async (data) => {
  const resData = await dataService.post(`${API}commissions/finishCode/create/`, data);
  return resData;
});

export const updateCommissionFinishCode = createAsyncThunk('commission/finishCodes/update', async ({
  data,
  codeId
}) => {
  const resData = await dataService.put(`${API}commissions/finishCode/update/${codeId}/`, data);
  return resData;
});


const initialState = {
  companies: null,
  documentations: [],
  commissions: null,
  commissionsCount: 0,
  chosenCommission: null,
  chosenCommissionId: null,
  commissionOpenCodes: null,
  commissionCloseCodes: null,
  commissionChosenCode: null,
  migratedUnits: null,
  errors: {
    commission: null,
    commissionForm: null
  }
};


export const commissionSlice = createSlice({
  name: 'commission',
  initialState,
  reducers: {
    setError: (state, action) => {
      const {
        attribute,
        value
      } = action.payload;
      state.errors[attribute] = value;
    },
    setChosenTaskId: (state, action) => {
      localStorage.setItem('chosenTaskId', action.payload);
      state.chosenTaskId = action.payload;
    },
    setChosenCommissionId: (state, action) => {
      localStorage.setItem('chosenCommissionId', action.payload);
      state.chosenCommissionId = action.payload;
    },

    clearCommissionData: (state) => {
      state.chosenCommission = null;
      state.chosenLocation = null;
      state.documentations = [];
      state.commissions = null;
      state.commissionsCount = 0;
    },
    clearChosenCode: (state) => {
      state.commissionChosenCode = null;
    },
    clearCommissionCodes: (state) => {
      state.commissionOpenCodes = null;
      state.commissionCloseCodes = null;
      state.commissionChosenCode = null;
    },
    clearDocumentations: (state) => {
      state.documentations = [];
    },
    clearErrors: (state) => {
      state.errors = {
        commission: null,
        commissionForm: null
      };
    },
    clearMigratedUnits: (state) => {
      state.migratedUnits = null;
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(getCommissionDocumentations.fulfilled, (state, action) => {
        if (action.payload.data.length > 0) {
          state.documentations = action.payload.data;
        }
        state.documentations = [];
      })
      // .addCase(getCommissionDocumentations.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getDocumentation.fulfilled, (state, action) => {
        const file = action.payload.data;
        const base64 = btoa(
          new Uint8Array(file).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        );
        // const fileObj = dataURLtoFile(`data:${action.payload.headers['content-type']};base64,` + base64, `file${state.postDocumentations.length+1}`);
        if (state.documentations == null)
          state.documentations = [`data:${action.payload.headers['content-type']};base64,` + base64];
        else {
          state.documentations = [...state.documentations, `data:${action.payload.headers['content-type']};base64,` + base64];
        }
      })
      // .addCase(getDocumentation.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(createCommission.fulfilled, (state, action) => {
        state.chosenCommissionId = action.payload.data;
      })
      // .addCase(createCommission.rejected, (state, action) => {
      //   const errorCode = action.error.message.substring(action.error.message.length - 4);
      //   if (errorCode == 461)
      //     state.errors.commission = 'The commission based on given location exists and is in progress!';
      //   else if (errorCode == 462)
      //     state.errors.commission = 'Wrong commission data - the object wasn\'t created!';
      // })
      .addCase(getCommissions.fulfilled, (state, action) => {
        state.commissions = action.payload.data;
      })
      .addCase(getPaginatedCommissions.fulfilled, (state, action) => {
        state.commissions = action.payload.data;
      })
      .addCase(getCommissionsCount.fulfilled, (state, action) => {
        state.commissionsCount = action.payload.data;
      })
      // .addCase(getCommissions.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getCommission.fulfilled, (state, action) => {
        state.chosenCommission = action.payload.data;
        state.chosenCommissionId = action.payload.data.id;
        localStorage.setItem('chosenCommissionId', action.payload.data.id);
        state.chosenDeviceId = action.payload.data.deviceId;
      })
      // .addCase(getCommission.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getCommissionMigratedUnits.fulfilled, (state, action) => {
        state.migratedUnits = action.payload.data;
      })
      // .addCase(getCommissionMountedUnits.rejected, (_, action) => {
      //   alert(`Operation failed - ${action.error.message}`);
      // })
      .addCase(getCommissionCloseCodes.fulfilled, (state, action) => {
        state.commissionCloseCodes = action.payload.data;
      })
      .addCase(getCommissionFilteredCloseCodes.fulfilled, (state, action) => {
        state.commissionCloseCodes = action.payload.data;
      })
      .addCase(getCommissionOpenCodes.fulfilled, (state, action) => {
        state.commissionOpenCodes = action.payload.data;
      })
      .addCase(getCommissionFilteredOpenCodes.fulfilled, (state, action) => {
        state.commissionOpenCodes = action.payload.data;
      })
      .addCase(getCommissionCode.fulfilled, (state, action) => {
        state.commissionChosenCode = action.payload.data;
      });
  }
});

export const {
  setError,
  clearErrors,
  setChosenCommissionId,
  clearCommissionData,
  clearChosenCode,
  clearMigratedUnits,
  clearCommissionCodes: clearCommissionCodes,
  clearDocumentations
} = commissionSlice.actions;

export default commissionSlice.reducer;