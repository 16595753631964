/* eslint-disable react/prop-types */
import React from 'react';
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CompactDataDisplay from '../../../CompactDataDisplay/CompactDataDisplay';
import EditButton from '../../../EditButton/EditButton';
import DeleteButton from '../../../DeleteButton/DeleteButton';

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
}));


const ProducersItemMobile = ({
  orderNumber,
  producer,
  editProducer,
  delProducer,
}) => {
  const { name } = producer;

  const data = [
    { title: "Nazwa:", value: name },
  ];

  return (
    <CompactDataDisplay
      title={`Producent #${orderNumber}`}
      data={data}
      actions={
        <ButtonWrapper>
          <EditButton onClick={() => editProducer()} />
          <DeleteButton onClick={() => delProducer()} />
        </ButtonWrapper>
      }
    />
  );
};

export default ProducersItemMobile;