/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import StorageReportUnitItemDesktop from "./StorageReportUnitItemVersions/StorageReportUnitItemDesktop";
import StorageReportUnitItemMobile from "./StorageReportUnitItemVersions/StorageReportUnitItemMobile";
import useMediaQ from "../../hooks/useMediaQ";

const StorageReportUnitItem = ({ orderNumber, unit, setRefresh }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <StorageReportUnitItemDesktop
          orderNumber={orderNumber}
          unit={unit}
          setRefresh={setRefresh}
        />
      ) : (
        <StorageReportUnitItemMobile
          orderNumber={orderNumber}
          unit={unit}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default StorageReportUnitItem;
