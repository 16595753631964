/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { Article, Autorenew } from "@mui/icons-material";
import { dateFormatView } from "../../../static/functions";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../PageHeader/PageHeader";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
} from "../../ObjectDetailsElements/ObjectDetailsElements";
import GeoLocationIcon from "../../GeoLocation/GeoLocationIcon";

const LocationDetails = ({ chosenLocation }) => {
  const navigate = useNavigate();

  return (
    <SectionWrapper>
      <PageHeader titleText="Dane" icon={<Article />} size="medium" />
      <InfoWrapper>
        <DataBox>
          <DataTitle>Kod:</DataTitle>
          <Typography>{chosenLocation.code}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Nazwa:</DataTitle>
          <Typography>{chosenLocation.name}</Typography>
        </DataBox>
        <DataBox>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DataTitle>Adres:</DataTitle>
            <GeoLocationIcon query={chosenLocation.address.linkedVersion} />
          </Box>
          <Typography>
            {chosenLocation.address.linkedVersion
              ? chosenLocation.address.linkedVersion
              : `ul. ${chosenLocation.address.street}, ${chosenLocation.address.city}, ${chosenLocation.address.province}, ${chosenLocation.address.country}, ${chosenLocation.address.postcode}`}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Firma:</DataTitle>
          <Typography
            onClick={() => navigate(`/companies/${chosenLocation.company.id}`)}
            sx={{ textDecoration: "underline" }}
          >
            {chosenLocation.company.name}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Tel. kontaktowy:</DataTitle>
          <Typography>{chosenLocation.contactPhone}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Osoba kontaktowa:</DataTitle>
          <Typography>{chosenLocation.contactPerson}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Data utworzenia:</DataTitle>
          <Typography>{dateFormatView(chosenLocation.creationDate)}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Utworzone przez:</DataTitle>
          {chosenLocation.createdBy !== null ? (
            <Typography>{`${chosenLocation.createdBy.name} ${chosenLocation.createdBy.surname}`}</Typography>
          ) : (
            <Typography>Brak</Typography>
          )}
        </DataBox>
        <DataBox>
          <DataTitle>Data ostatniej mod.:</DataTitle>
          {chosenLocation.lastModificationDate ? (
            <Typography>
              {dateFormatView(chosenLocation.lastModificationDate)}
            </Typography>
          ) : (
            <Typography>Brak</Typography>
          )}
        </DataBox>
        <DataBox>
          <DataTitle>Ostatnio modyfikowane przez:</DataTitle>
          {chosenLocation.lastModifiedBy !== null ? (
            <Typography>{`${chosenLocation.lastModifiedBy.name} ${chosenLocation.lastModifiedBy.surname}`}</Typography>
          ) : (
            <Typography>Brak</Typography>
          )}
        </DataBox>
        <DataBox>
          <DataTitle>Czy zsynchronizowane:</DataTitle>
          {chosenLocation.isSynchronized ? (
            <Autorenew
              sx={{
                color: "darkgreen",
              }}
            />
          ) : (
            <Autorenew
              sx={{
                color: "red",
              }}
            />
          )}
        </DataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default LocationDetails;
