/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import useMediaQ from "../../../hooks/useMediaQ";
import AddUnitFieldDesktop from "./AddUnitFieldVersions/AddUnitFieldDesktop";
import AddUnitFieldMobile from "./AddUnitFieldVersions/AddUnitFieldMobile";

const AddUnitField = ({
  unit,
  index,
  formValues,
  setFormValues,
  removeItem,
  formError,
  setFormError,
  orderNumber,
}) => {
  const { isDesktopDisplay } = useMediaQ();
  const [serialNumber, setSerialNumber] = useState("");
  const [quantity, setQuantity] = useState(1);
  const { partNumber, product, type } = unit;

  const updateData = ({ serialNumber, quantity }) => {
    const findingIndex = formValues.findIndex(
      (element) => element.index === index
    );
    if (findingIndex !== -1) {
      const arrayCopy = [...formValues];
      arrayCopy.splice(findingIndex, 1);
      setFormValues([
        ...arrayCopy,
        {
          index,
          partNumber,
          serialNumber,
          quantity,
          product,
          type,
        },
      ]);
    } else {
      setFormValues((array) => [
        ...array,
        {
          index,
          partNumber,
          serialNumber,
          quantity,
          product,
          type,
        },
      ]);
    }
  };

  const onSerialNumberChange = (value) => {
    setFormError(false);
    setSerialNumber(value);
    updateData({ serialNumber: value, quantity });
  };

  const onQuantityChange = (value) => {
    setFormError(false);
    setQuantity(value);
    if (value > 1) {
      updateData({ serialNumber: "", quantity: value });
    } else {
      updateData({ serialNumber, quantity: value });
    }
  };

  useEffect(() => {
    updateData({ serialNumber, quantity });
  }, []);

  return (
    <>
      {isDesktopDisplay ? (
        <AddUnitFieldDesktop
          unit={unit}
          orderNumber={orderNumber}
          serialNumber={serialNumber}
          formError={formError}
          onSerialNumberChange={onSerialNumberChange}
          quantity={quantity}
          onQuantityChange={onQuantityChange}
          removeItem={removeItem}
        />
      ) : (
        <AddUnitFieldMobile
          unit={unit}
          orderNumber={orderNumber}
          serialNumber={serialNumber}
          formError={formError}
          onSerialNumberChange={onSerialNumberChange}
          quantity={quantity}
          onQuantityChange={onQuantityChange}
          removeItem={removeItem}
        />
      )}
    </>
  );
};

export default AddUnitField;
