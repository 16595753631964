/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useState } from "react";
import { Box, Typography, Collapse } from "@mui/material";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import background from "../../static/photos/background.jpg";
import LoginForm from "./LoginForm";
import useMediaQ from "../../hooks/useMediaQ";

const LoginPanel = () => {
  const [isLoginPanelShown, setIsLoginPanelShown] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { isDesktopDisplay } = useMediaQ();

  useEffect(() => {
    if (!isLoginPanelShown) {
      const timeout = setTimeout(() => setIsLoginPanelShown(true), 350);

      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <>
      {isLoggedIn ? (
        <Navigate to="/" />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
            background: `linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 0",
            backgroundSize: "cover",
            position: "relative",
            ["@media (max-width:767px)"]: {
              width: "100vw",
            },
          }}
        >
          {isDesktopDisplay && (
            <Box
              sx={{
                position: "absolute",
                bottom: "3em",
                right: "5%",
                bgcolor: "rgba(225, 225, 225, 0.5)",
              }}
            >
              <Typography sx={{ fontSize: "1.2em" }}>Wersja 1.0.0.15 PROD</Typography>
              <Typography sx={{ fontWeight: 700, color: "red" }}>
                Ostatnia modyfikacja: 27.11.2023r.
              </Typography>
            </Box>
          )}
          <Collapse in={isLoginPanelShown}>
            <Box>
              <LoginForm />
            </Box>
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default LoginPanel;
