/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import CompactDataDisplay from "../../../../CompactDataDisplay/CompactDataDisplay";

const UserPermissionItemMobile = ({ orderNumber, permission }) => {
  const { name, description } = permission;
  const data = [
    { title: "Nazwa:", value: name },
    { title: "Opis:", value: description },
  ];

  return (
    <CompactDataDisplay
      title={`Uprawnienie #${orderNumber}`}
      data={data}
      actions={<></>}
    />
  );
};

export default UserPermissionItemMobile;
