/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStorage, getPaginatedStorages } from "../../../slices/storageSlice";
import { moveUnits } from "../../../slices/unitSlice";
import { useNavigate } from "react-router-dom";
import SearchDropdown from "../../SearchDropdown/SearchDropdown";
import PageHeader from "../../PageHeader/PageHeader";
import {
  ArrowUpward,
  CalendarViewMonth,
  KeyboardArrowLeft,
  Warehouse,
} from "@mui/icons-material";
import AvailableProducts from "../AvailableProducts/AvailableProducts";
import AddedUnits from "../AddedUnits/AddedUnits";
import BlankSpace from "../../BlankSpace/BlankSpace";
import PageWrapper from "../../PageWrapper/PageWrapper";
import {
  DetailsButton,
  ReturnButton,
} from "../../FunctionalButtons/FunctionalButtons";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import authService from "../../../auth/authService";
import ReturnConfirmationModal from "../../ReturnConfirmationModal/ReturnConfirmationModal";

const MoveUnits = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState([]);
  const [destinationStorage, setDestinationStorage] = useState("");
  const { chosenStorage } = useSelector((slice) => slice.storage);
  const [sorterField, setSorterField] = useState("");
  const [sortingField, setSortingField] = useState("");
  const [
    isReturnConfirmationModalVisible,
    setIsReturnConfirmationModalVisible,
  ] = useState(false);
  const { user_id: userId, role } = authService.getDecodedAccessToken();

  const sortField = (a, b) => {
    let value = 0;
    if (!isNaN(a[sorterField]) && !isNaN(b[sorterField])) {
      value = a[sorterField] - b[sorterField];
    } else {
      value = a[sorterField]
        .toString()
        .localeCompare(b[sorterField].toString());
    }
    if (sortingField === "descending") value *= -1;
    return value;
  };

  const getDataStorage = useCallback(async () => {
    await dispatch(getStorage());
  }, []);

  useEffect(() => {
    getDataStorage();
  }, []);

  const formSubmit = async () => {
    if (formValues.length > 0) {
      const data = {
        destinationStorage: destinationStorage.id,
        units: formValues,
        sender: userId,
      };
      await dispatch(moveUnits({ storageId: chosenStorage.id, data }));
      if (role === "service_technician")
        navigate(`/storages/${chosenStorage.id}`);
      else {
        navigate("/main-storage");
      }
    }
  };

  const removeItem = (index) => {
    const arrayCopy = [...formValues];
    const findingIndex = arrayCopy.findIndex((unit) => unit.index === index);
    arrayCopy.splice(findingIndex, 1);
    setFormValues(arrayCopy);
  };

  return (
    <>
      <PageWrapper width="90%">
        <PageHeader
          icon={<CalendarViewMonth />}
          titleText="Tworzenie przesyłki"
          onRightComponent={
            <ReturnButton
              startIcon={<KeyboardArrowLeft />}
              text="Powrót"
              onClick={() => setIsReturnConfirmationModalVisible(true)}
            />
          }
        >
          <DetailsButton
            text="Utwórz"
            onClick={() => formSubmit()}
            disabled={formValues.length === 0 || destinationStorage === ""}
            startIcon={<ArrowUpward />}
            clickLock
          />
        </PageHeader>
        <BlankSpace />
        <SectionWrapper>
          <PageHeader
            titleText="Magazyn docelowy"
            icon={<Warehouse />}
            size="medium"
          />
          <SearchDropdown
            collectionName="storages"
            sliceName="storage"
            getDataFunction={getPaginatedStorages}
            placeholder="Magazyn docelowy"
            propertyToDisplay="name"
            setFunction={(item) => {
              setDestinationStorage(item);
            }}
            restrictionFunction={(item) =>
              (role !== "service_technician" && item.id !== chosenStorage.id) ||
              (role === "service_technician" && item.type === "main")
            }
            propsWidth="100%"
            isSearchingValueObjectProps={true}
          />
        </SectionWrapper>
        <BlankSpace />
        <AvailableProducts
          chosenStorage={chosenStorage}
          setFormValues={setFormValues}
          status="GOOD"
        />
        <BlankSpace />
        <AddedUnits
          formValues={formValues}
          sortField={sortField}
          removeItem={removeItem}
          formSubmit={formSubmit}
          destinationStorage={destinationStorage}
        />
      </PageWrapper>
      <ReturnConfirmationModal
        isOpen={isReturnConfirmationModalVisible}
        handleClose={() => setIsReturnConfirmationModalVisible(false)}
        passFunction={() => navigate(-1)}
      />
    </>
  );
};

export default MoveUnits;
