/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCommissionMigratedUnits,
  clearMigratedUnits,
} from "../../slices/commissionSlice";
import UnitMigrationHistoryItem from "./UnitsMigrationHistoryItem/UnitMigrationHistoryItem";
import SectionWrapper from "../SectionWrapper/SectionWrapper";
import PageHeader from "../PageHeader/PageHeader";
import { HomeRepairService, Info } from "@mui/icons-material";
import Loading from "../Loading/Loading";
import EmptyListError from "../EmptyListError/EmptyListError";

const UnitsMigrationHistory = ({
  commissionId = "",
  taskData = null,
  refresh,
}) => {
  const dispatch = useDispatch();
  const [isDataLoading, setIsDataLoading] = useState(false);

  const { migratedUnits } = useSelector((slice) => slice.commission);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getCommissionMigratedUnits(commissionId));
    setIsDataLoading(false);
  }, [commissionId, refresh]);

  useEffect(() => {
    if (commissionId) getData();
  }, [getData]);

  useEffect(() => {
    return () => dispatch(clearMigratedUnits());
  }, []);

  if (isDataLoading) return <Loading />;

  return (
    <SectionWrapper>
      <PageHeader
        titleText={
          taskData
            ? "Zestawienie migracji części w zadaniu"
            : "Zestawienie migracji części w zleceniu"
        }
        icon={<HomeRepairService />}
        size="medium"
      />
      <>
        {migratedUnits !== null && migratedUnits.length > 0 ? (
          <>
            {migratedUnits.map((workSet, index) => (
              <UnitMigrationHistoryItem
                workSet={workSet}
                key={index}
                taskData={taskData}
              />
            ))}
          </>
        ) : (
          <>
            {!taskData && (
              <EmptyListError
                message={"Brak migracji jednostek w zleceniu"}
                icon={<Info />}
              />
            )}
          </>
        )}
      </>
      <>
        {taskData ? (
          <>
            <UnitMigrationHistoryItem workSet={null} taskData={taskData} />
          </>
        ) : (
          <>
            {migratedUnits === null && (
              <EmptyListError
                message="Brak migracji jednostek w zadaniu"
                icon={<Info />}
              />
            )}
          </>
        )}
      </>
    </SectionWrapper>
  );
};

export default UnitsMigrationHistory;
