/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, TextField, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading/Loading";
import { useTheme } from "@mui/material/styles";

const globalWidth = "100%";

const SearchDropdown = ({
  collectionName,
  sliceName,
  getDataFunction,
  setFunction,
  outerValue = "",
  placeholder,
  propertyToDisplay,
  propsWidth = "auto",
  restrictionFunction = null,
  isSearchingValueObjectProps = false,
  clearFunction = null,
  error = false,
  helperText = "",
  errorMessage = "",
  errorFunction = null,
}) => {
  const dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const collection = useSelector((slice) => slice[sliceName])[collectionName];
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);
  const searchingRef = useRef(outerValue);
  const [searchingValue, setSearchingValue] = useState(
    outerValue ? outerValue : ""
  );

  const [displaySearchingValue, setDisplaySearchingValue] =
    useState(outerValue);
  // eslint-disable-next-line no-unused-vars
  const [isValueSet, setIsValueSet] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const changeSearchingValue = (value) => {
    setDisplaySearchingValue(value);
    searchingRef.current = value;
    setFunction("");
    setIsValueSet(false);
    setTimeout(() => setSearchingValue(searchingRef.current), 1500);
  };

  const getData = useCallback(async () => {
    if (areOptionsVisible) {
      setIsDataLoading(true);
      if (!isSearchingValueObjectProps)
        await dispatch(getDataFunction(searchingValue));
      else await dispatch(getDataFunction({ searchingValue }));
      setIsDataLoading(false);
    }
  }, [searchingValue, areOptionsVisible]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (clearFunction && typeof clearFunction === "function") {
      return () => {
        dispatch(clearFunction());
      };
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     collection !== null &&
  //     collection.filter((item) =>
  //       restrictionFunction ? restrictionFunction(item) : true
  //     ).length === 1
  //   ) {
  //     clickOption(
  //       collection.filter((item) =>
  //         restrictionFunction ? restrictionFunction(item) : true
  //       )[0]
  //     );
  //   }
  // }, [collection]);

  const clickOption = (item) => {
    changeSearchingValue(item[propertyToDisplay]);
    setFunction(item);
    setAreOptionsVisible(false);
    setIsValueSet(true);
  };

  const setErrorMessage = () => {
    if (errorFunction) {
      dispatch(errorFunction(errorMessage));
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: propsWidth !== "auto" ? propsWidth : globalWidth,
        [breakpoints.down("laptop")]: {
          maxWidth: "100%",
          margin: "0 auto",
        },
      }}
    >
      <TextField
        value={displaySearchingValue ? displaySearchingValue : ""}
        onChange={(e) => changeSearchingValue(e.target.value)}
        onFocus={() => setAreOptionsVisible(true)}
        onBlur={() => window.setTimeout(() => setAreOptionsVisible(false), 200)}
        sx={{ width: "100%" }}
        placeholder={placeholder}
        error={error}
        helperText={error && helperText}
        autoComplete="new-password"
        label={placeholder}
      />
      <Box
        sx={{
          position: "absolute",
          bgcolor: "white",
          zIndex: "9999",
          opacity: "1",
          width: "100%",
          overflowX: "hidden",
        }}
      >
        {isDataLoading && areOptionsVisible && (
          <Box
            sx={{
              height: "3rem",
            }}
          >
            <Loading size="small" />
          </Box>
        )}
        <Box
          sx={{ overflowY: "auto", overflowX: "hidden", maxHeight: "10rem" }}
        >
          {!isDataLoading &&
            collection !== null &&
            areOptionsVisible &&
            !isValueSet &&
            collection
              .filter((item) =>
                restrictionFunction ? restrictionFunction(item) : true
              )
              .map((item, index) => (
                <MenuItem
                  key={index}
                  value={item[propertyToDisplay]}
                  onClick={
                    !item.isDisabled
                      ? () => clickOption(item)
                      : () => setErrorMessage()
                  }
                  sx={{
                    border: "0.0625em solid #CCC",
                    height: "2.5rem",
                    overflowX: "auto",
                    "&:hover": {
                      color: "#BBB",
                    },
                  }}
                >
                  {item[propertyToDisplay]}
                </MenuItem>
              ))}
          {!isDataLoading &&
            areOptionsVisible &&
            collection !== null &&
            collection.length === 0 && (
              <MenuItem
                key={0}
                value=""
                sx={{
                  border: "0.0625em solid black",
                  height: "2em",
                }}
                disabled
              >
                Brak danych do wyświetlenia
              </MenuItem>
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchDropdown;
