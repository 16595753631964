/* eslint-disable react/prop-types */
import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomChip from "../../../CustomChip/CustomChip";
import CompactDataDisplay from "../../../CompactDataDisplay/CompactDataDisplay";
import { unitStatusChipStyles } from "../../../../static/static";
import EditUnit from "../../../UnitForms/EditUnitForm/EditUnit";
import DeleteUnit from "../../../UnitForms/DeleteUnitForm/DeleteUnit";

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
  flexWrap: "wrap",
}));

const UnitItemMobile = ({ orderNumber, unit, setRefresh }) => {
  const {
    id,
    product: { partNumber, name, category, producer },
    serialNumber,
    status,
    quantity,
  } = unit;

  const data = [
    { title: "PartNumber:", value: partNumber },
    { title: "Nazwa:", value: name },
    { title: "Nr seryjny:", value: serialNumber },
    { title: "Kategoria:", value: category ? category.name : "" },
    { title: "Producent:", value: producer ? producer.name : "" },
    {
      title: "Status:",
      value: (
        <CustomChip
          label={status}
          sx={unitStatusChipStyles(status, "")}
          width="100%"
        />
      ),
    },

    { title: "Liczba:", value: quantity },
  ];
  return (
    <CompactDataDisplay
      title={`Produkt #${orderNumber}`}
      data={data}
      actions={
        <ButtonWrapper sx={{ gap: "0.5rem" }}>
          <EditUnit unitId={id} setRefresh={setRefresh} />
          <DeleteUnit unitId={id} setRefresh={setRefresh} />
        </ButtonWrapper>
      }
    />
  );
};

export default UnitItemMobile;
