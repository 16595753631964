/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearStorage, getMainStorage } from "../../slices/storageSlice";
import { increaseUnitsState } from "../../slices/unitSlice";
import { useNavigate } from "react-router-dom";
import BlankSpace from "../BlankSpace/BlankSpace";
import AvailableUnits from "./AvailableUnits/AvailableUnits";
import AddedUnits from "./AddedUnits/AddedUnits";
import EmptyListError from "../EmptyListError/EmptyListError";
import {
  ArrowCircleUp,
  Warning,
  ArrowOutward,
} from "@mui/icons-material";
import PageHeader from "../PageHeader/PageHeader";
import { DetailsButton } from "../FunctionalButtons/FunctionalButtons";
import Loading from "../Loading/Loading";
import PageWrapper from "../PageWrapper/PageWrapper";
import ReturnButton from "../ReturnButton/ReturnButton";

const sortElements = (a, b) => {
  if (a.index < b.index) return -1;
  if (a.index > b.index) return 1;
  return 0;
};

const AddUnits = () => {
  const [formValues, setFormValues] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [formError, setFormError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chosenStorage, chosenStorageId } = useSelector(
    (slice) => slice.storage
  );
  const [isDataLoading, setIsDataLoading] = useState(false);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getMainStorage());
    setIsDataLoading(false);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => dispatch(clearStorage());
  }, []);

  const addItem = useCallback(({ product, partNumber, name, type }) => {
    const findingIndex = formValues.findIndex(
      (unit) => unit.partNumber === partNumber && unit.type === "Numerical"
    );
    if (findingIndex === -1) {
      const findingIndexSelectedUnits = selectedUnits
        .sort(sortElements)
        .reverse()
        .findIndex((unit) => unit.partNumber === partNumber);
      if (findingIndexSelectedUnits !== -1) {
        const arrayCopy = [...selectedUnits.sort(sortElements).reverse()];
        let findingElement = arrayCopy[findingIndexSelectedUnits];
        findingElement = { ...findingElement, quantityLock: true };
        arrayCopy.splice(findingIndexSelectedUnits, 1, findingElement);
        setSelectedUnits([
          ...arrayCopy.sort(sortElements),
          {
            index: arrayCopy[arrayCopy.length - 1].index + 1,
            partNumber,
            name,
            quantityLock: true,
            product,
            type,
          },
        ]);
      } else {
        setSelectedUnits((state) => [
          ...state.sort(sortElements),
          {
            index:
              selectedUnits.length > 0
                ? selectedUnits[selectedUnits.length - 1].index + 1
                : 0,
            partNumber,
            name,
            quantityLock: false,
            product,
            type,
          },
        ]);
      }
    }
  }, []);

  const removeItem = ({ id, partNumber }) => {
    const findingIndexSelectedUnits = selectedUnits.findIndex(
      (unit) => unit.index === id
    );
    const findingIndexFormValues = formValues.findIndex(
      (unit) => unit.index === id
    );
    const copySelectedUnits = [...selectedUnits];
    const copyFormValues = [...formValues];
    copySelectedUnits.splice(findingIndexSelectedUnits, 1);
    copyFormValues.splice(findingIndexFormValues, 1);

    const checkArray = copySelectedUnits.filter(
      (unit) => unit.partNumber === partNumber
    );
    if (checkArray.length === 1) {
      const findingElementIndex = copySelectedUnits.findIndex(
        (unit) => unit.partNumber === partNumber
      );
      let findingElement = checkArray[0];
      findingElement = { ...findingElement, quantityLock: false };
      copySelectedUnits.splice(findingElementIndex, 1, findingElement);
    }
    setSelectedUnits(copySelectedUnits);
    setFormValues(copyFormValues);
  };

  const sendUnits = useCallback(async () => {
    if (formValues.length === 0) {
      return;
    }
    const errorIndex = formValues.findIndex(
      (unit) =>
        unit.quantity === 1 &&
        unit.serialNumber.length === 0 &&
        unit.type === "Quantitative"
    );
    if (errorIndex === -1) {
      await dispatch(
        increaseUnitsState({ storageId: chosenStorageId, data: formValues })
      );
      navigate("/main-storage");
    } else {
      setFormError(true);
    }
  }, [formValues]);

  const [buttonLock, setButtonLock] = useState(false);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (keyCode === 13 && !buttonLock) {
        sendUnits();
        setButtonLock(true);
        setTimeout(() => setButtonLock(false), 5000);
      }
    },
    [sendUnits, buttonLock]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  if (isDataLoading) return <Loading />;

  return (
    <>
      {chosenStorage ? (
        <PageWrapper width="70%">
          <PageHeader
            icon={<ArrowCircleUp />}
            titleText="Zwiększanie stanu magazynowego"
            onRightComponent={<ReturnButton />}
          >
            <DetailsButton
              startIcon={<ArrowOutward />}
              text="Zatwierdź"
              onClick={() => sendUnits()}
              disabled={selectedUnits.length === 0 || buttonLock}
              clickLock
            />
          </PageHeader>
          <BlankSpace />
          <AvailableUnits addItem={addItem} />
          <BlankSpace />
          <AddedUnits
            removeItem={removeItem}
            formValues={formValues}
            setFormValues={setFormValues}
            formError={formError}
            setFormError={setFormError}
            selectedUnits={selectedUnits}
            sendUnits={sendUnits}
          />
        </PageWrapper>
      ) : (
        <EmptyListError icon={<Warning />} message="Brak magazynu głównego" />
      )}
    </>
  );
};

export default AddUnits;
