import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const RequireAuth = () => {
  const { isLoggedIn } = useSelector(state => state.auth);
  let location = useLocation();

  if (!isLoggedIn) return <Navigate to="login" state={{ from: location }} />;
  
  
  return <Outlet/>;
};

export default RequireAuth;
