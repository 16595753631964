/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box, InputLabel, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import {
  executeApprovalTask,
  declineApprovalTask,
} from "../../../slices/taskSlice";
import { Check, Clear } from "@mui/icons-material";

import authService from "../../../auth/authService";
import CustomDateInput from "../../CustomDateInput/CustomDateInput";
import ReactDatePicker from "react-datepicker";
import AcceptButton from "../../AcceptButton/AcceptButton";
import DeleteButton from "../../DeleteButton/DeleteButton";
import EditButton from "../../EditButton/EditButton";
import TaskExecutionCompactData from "../../TaskView/TaskExecution/TaskExecutionCompactData";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
} from "../../ObjectDetailsElements/ObjectDetailsElements";
import { useTheme } from "@emotion/react";
import { onlyDateFormatView } from "../../../static/functions";

const Wrapper = styled(Box)(({ theme }) => ({
  height: "40rem",
  width: "31.25rem",
  overflowY: "auto",
  padding: "0.5rem",
  [theme.breakpoints.down("laptop")]: {
    width: "25rem",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "16rem",
  },
}));

const ContentWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "80%",
  margin: "1.875em auto 0 auto",
  gap: "0.625em",
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "1.875rem",
  marginTop: "1rem",
  [theme.breakpoints.down("laptop")]: {
    gap: "0.5rem",
  },
}));

const CustomDataBox = ({ children }) => {
  const { breakpoints } = useTheme();
  return (
    <DataBox
      sx={{
        flexBasis: "100%",
        [breakpoints.down("desktop")]: {
          flexBasis: "100%",
        },
      }}
    >
      {children}
    </DataBox>
  );
};


const ApprovalModal = ({
  taskId,
  setRefresh,
  setIsApprovalModalVisible,
  task,
}) => {
  const dispatch = useDispatch();
  const [proposedDate, setProposedDate] = useState(new Date(task.planningExecutionDate));
  const [description, setDescription] = useState("");

  const executeApprovalTaskInner = async () => {
    await dispatch(
      executeApprovalTask({
        taskId,
        planningExecutionDate: proposedDate.getTime(),
        description,
        executor: authService.getDecodedAccessToken().user_id,
      })
    );
    setIsApprovalModalVisible(false);
    setRefresh((state) => !state);
  };

  const declineApprovalTaskInner = async () => {
    await dispatch(
      declineApprovalTask({
        taskId,
        description,
        executor: authService.getDecodedAccessToken().user_id,
      })
    );
    setIsApprovalModalVisible(false);
    setRefresh((state) => !state);
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <Typography
          sx={{
            fontSize: "1.5rem",
            width: "100%",
            textAlign: "center",
          }}
        >
          Ustal termin wizyty serwisowej lub odrzuć zadanie
        </Typography>
        <TaskExecutionCompactData task={task} />
        <InfoWrapper>
          <CustomDataBox>
            <DataTitle>Data zaproponowana przez kwalifikatora:</DataTitle>
            <Typography>
              {onlyDateFormatView(task.planningExecutionDate)}
            </Typography>
          </CustomDataBox>
        </InfoWrapper>
        <InputLabel
          id="province-select-label"
          sx={{
            alignSelf: "start",
            fontSize: "1.2rem",
          }}
        >
          Data wizyty serwisowej
        </InputLabel>
        <ReactDatePicker
          selected={proposedDate}
          onChange={(date) => setProposedDate(date)}
          startDate={proposedDate}
          minDate={new Date()}
          dateFormat="yyyy-MM-dd"
          customInput={<CustomDateInput />}
          withPortal
        />
        <TextField
          label="Opis decyzji (wymagane, w przypadku odrzucenia)"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={10}
          sx={{
            width: "100%",
          }}
        />
        <ButtonWrapper>
          <AcceptButton
            onClick={() => executeApprovalTaskInner()}
            startIcon={<Check />}
            text="Przyjmij"
            disabled={proposedDate === ""}
          />
          <DeleteButton
            onClick={() => declineApprovalTaskInner()}
            startIcon={<Clear />}
            disabled={description === ""}
            text="Odrzuć"
          />
          <EditButton
            onClick={() => setIsApprovalModalVisible(false)}
            startIcon={<Clear />}
            text="Anuluj"
          />
        </ButtonWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ApprovalModal;
