/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AcceptButton from "../../AcceptButton/AcceptButton";
import EditButton from "../../EditButton/EditButton";
import { useDispatch, useSelector } from "react-redux";
import { clearUnit, getUnit, updateUnit } from "../../../slices/unitSlice";
import { Clear } from "@mui/icons-material";

const ContentWrapper = styled(Box)(() => ({}));

// eslint-disable-next-line react/prop-types
const EditUnitForm = ({
  unitId,
  isUnitEditModalOpen,
  setIsUnitEditModalOpen,
  setRefresh,
}) => {
  const [quantity, setQuantity] = useState(0);
  const [serialNumber, setSerialNumber] = useState("");
  const dispatch = useDispatch();
  const { chosenUnit } = useSelector((slice) => slice.unit);

  console.log("chosenUnit", chosenUnit);

  const getData = useCallback(async () => {
    await dispatch(getUnit(unitId));
  }, [unitId]);

  const editUnit = useCallback(async () => {
    const data = { quantity, serialNumber };
    await dispatch(updateUnit({ data, unitId }));
    setIsUnitEditModalOpen(false);
    setRefresh(state => !state);
  }, [quantity, serialNumber]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (chosenUnit !== null) {
      setQuantity(chosenUnit.quantity);
      setSerialNumber(chosenUnit.serialNumber);
    }
  }, [chosenUnit]);

  useEffect(() => {
    return () => dispatch(clearUnit());
  }, []);

  return (
    <Dialog open={isUnitEditModalOpen} onClose={() => setIsUnitEditModalOpen()}>
      <Box sx={{ maxWidth: "20rem" }}>
        <DialogTitle>Edycja jednostki produktu</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Dokonywanie bezpośrednich zmian może powodować niezgodności w
            stanach magazynowych. Upewnij się w poprawności podawanych wartości.
          </DialogContentText>
          <ContentWrapper sx={{ marginTop: "1rem" }}>
            <TextField
              label="Liczba"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              size="small"
              variant="standard"
              disabled={
                chosenUnit && chosenUnit.product.type === "Quantitative"
              }
              InputProps={{ inputProps: { min: 1 } }}
              type="number"
              fullWidth
            />
            {chosenUnit && chosenUnit.product.type === "Quantitative" && (
              <TextField
                label="Nr seryjny"
                value={serialNumber}
                onChange={(e) => setSerialNumber(e.target.value)}
                size="small"
                variant="standard"
                fullWidth
              />
            )}
          </ContentWrapper>
        </DialogContent>
        <DialogActions sx={{ gap: "0.5rem" }}>
          <AcceptButton onClick={() => editUnit()} />
          <EditButton
            onClick={() => setIsUnitEditModalOpen(false)}
            text="Anuluj"
            startIcon={<Clear />}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditUnitForm;
