/* eslint-disable react/prop-types */
import { Button, Box} from "@mui/material";
import { styled} from "@mui/material/styles";
import React from "react";

const CustomButton = styled(Button)(() => ({
  backgroundColor: "white",
  color: "black",
  fontSize: "0.75rem",
  minHeight: '2.5rem',
  "&:hover": {
    backgroundColor: "white",
    borderBottom: "0.0625rem solid rgb(27,150,250)",
  },
}));

const ChoosingButton = ({ onClick, name, tasksKindQuantity = null }) => {
  return (
    <CustomButton onClick={() => onClick()} variant="contained">
      {name}
      {tasksKindQuantity !== null && (
        <Box
          component="span"
          sx={{
            display: "block",
            marginLeft: "0.1rem",
            color: tasksKindQuantity * 1 > 0 ? "red" : "green",
          }}
        >
          {`(${tasksKindQuantity})`}
        </Box>
      )}
    </CustomButton>
  );
};

export default ChoosingButton;
