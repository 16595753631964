/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react";
import { Box, Typography, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Check, Clear, Doorbell, Info, Settings } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateNotifications } from "../../../slices/permissionSlice";
import { dateFormatView } from "../../../static/functions";
import { useEffect } from "react";
import { EditButton } from "../../FunctionalButtons/FunctionalButtons";
import EditButton2 from "../../EditButton/EditButton";
import PageHeader from "../../PageHeader/PageHeader";
import AcceptButton from "../../AcceptButton/AcceptButton";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const RowWrapper = styled(Box)(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "15rem 10rem 10rem 10rem 10rem 10rem",
  display: "flex",
  gap: "5rem",
  flexWrap: "wrap",
  alignItems: "center",
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
  [theme.breakpoints.down("tablet")]: {
    gap: "0.5rem",
  },
}));

const RowTitle = styled(Typography)(({ theme }) => ({
  flexBasis: "30%",
  [theme.breakpoints.down("tablet")]: {
    flexBasis: "100%",
  },
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "1rem",
  marginTop: "1rem",
}));

const UserNotificationsPanel = ({
  userId,
  userNotifications,
  isPermissionConfigurationOpened,
  setIsNotificationConfigurationOpened,
  isNotificationConfigurationOpened,
  setRefresh,
}) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((slice) => slice.auth);

  const [currentNotificationsSetup, setCurrentNotificationsSetup] = useState({
    commissionCreation: userNotifications.commissionCreation,
    commissionStatusChange: userNotifications.commissionStatusChange,
    commissionAssignmentChange: userNotifications.commissionAssignmentChange,
    requestCreation: userNotifications.requestCreation,
    requestChange: userNotifications.requestChange,
  });
  const initialState = useRef();
  initialState.current = {
    commissionCreation: userNotifications.commissionCreation,
    commissionStatusChange: userNotifications.commissionStatusChange,
    commissionAssignmentChange: userNotifications.commissionAssignmentChange,
    requestCreation: userNotifications.requestCreation,
    requestChange: userNotifications.requestChange,
  };

  useEffect(() => {
    setCurrentNotificationsSetup({
      commissionCreation: userNotifications.commissionCreation,
      commissionStatusChange: userNotifications.commissionStatusChange,
      commissionAssignmentChange: userNotifications.commissionAssignmentChange,
      requestCreation: userNotifications.requestCreation,
      requestChange: userNotifications.requestChange,
    });
  }, [userId, userNotifications]);

  const changeNotifications = async () => {
    const data = {
      ...currentNotificationsSetup,
      user: userId,
      modifier: +userData.user_id,
    };
    await dispatch(updateNotifications(data));
    setIsNotificationConfigurationOpened(false);
    setRefresh((state) => !state);
  };

  return (
    <SectionWrapper>
      <PageHeader
        titleText="Notyfikacje"
        icon={<Doorbell />}
        size="medium"
        onRightComponent={
          <EditButton
            disabled={
              isNotificationConfigurationOpened ||
              isPermissionConfigurationOpened
            }
            onClick={() => setIsNotificationConfigurationOpened(true)}
            startIcon={<Settings />}
          />
        }
      />
      <>
        <RowWrapper>
          <RowTitle sx={{ fontWeight: 700 }}>Funkcja</RowTitle>
          <Typography sx={{ fontWeight: 700 }}>Wyłączone</Typography>
          <Typography sx={{ fontWeight: 700 }}>SMS</Typography>
          <Typography sx={{ fontWeight: 700 }}>Email</Typography>
          <Typography sx={{ fontWeight: 700 }}>SMS+Email</Typography>
        </RowWrapper>
        <RowWrapper>
          <RowTitle>Utworzenie zlecenia</RowTitle>
          <Switch
            checked={
              currentNotificationsSetup.commissionCreation === "disabled"
            }
            onClick={() => {
              if (currentNotificationsSetup.commissionCreation !== "disabled") {
                setCurrentNotificationsSetup((state) => {
                  return { ...state, commissionCreation: "disabled" };
                });
              }
            }}
            disabled={
              currentNotificationsSetup.commissionCreation === "disabled" ||
              !isNotificationConfigurationOpened
            }
          />
          <Switch
            checked={currentNotificationsSetup.commissionCreation === "sms"}
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, commissionCreation: "sms" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
          <Switch
            checked={currentNotificationsSetup.commissionCreation === "email"}
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, commissionCreation: "email" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
          <Switch
            checked={currentNotificationsSetup.commissionCreation === "both"}
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, commissionCreation: "both" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
        </RowWrapper>
        <RowWrapper>
          <RowTitle>Zmiana statusu zlecenia</RowTitle>
          <Switch
            checked={
              currentNotificationsSetup.commissionStatusChange === "disabled"
            }
            onClick={() => {
              if (
                currentNotificationsSetup.commissionStatusChange !== "disabled"
              ) {
                setCurrentNotificationsSetup((state) => {
                  return { ...state, commissionStatusChange: "disabled" };
                });
              }
            }}
            disabled={
              currentNotificationsSetup.commissionStatusChange === "disabled" ||
              !isNotificationConfigurationOpened
            }
          />
          <Switch
            checked={currentNotificationsSetup.commissionStatusChange === "sms"}
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, commissionStatusChange: "sms" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
          <Switch
            checked={
              currentNotificationsSetup.commissionStatusChange === "email"
            }
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, commissionStatusChange: "email" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
          <Switch
            checked={
              currentNotificationsSetup.commissionStatusChange === "both"
            }
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, commissionStatusChange: "both" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
        </RowWrapper>
        <RowWrapper>
          <RowTitle>Zmiana przypisania w zleceniu</RowTitle>
          <Switch
            checked={
              currentNotificationsSetup.commissionAssignmentChange ===
              "disabled"
            }
            onClick={() => {
              if (
                currentNotificationsSetup.commissionAssignmentChange !==
                "disabled"
              ) {
                setCurrentNotificationsSetup((state) => {
                  return { ...state, commissionAssignmentChange: "disabled" };
                });
              }
            }}
            disabled={
              currentNotificationsSetup.commissionAssignmentChange ===
                "disabled" || !isNotificationConfigurationOpened
            }
          />
          <Switch
            checked={
              currentNotificationsSetup.commissionAssignmentChange === "sms"
            }
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, commissionAssignmentChange: "sms" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
          <Switch
            checked={
              currentNotificationsSetup.commissionAssignmentChange === "email"
            }
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, commissionAssignmentChange: "email" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
          <Switch
            checked={
              currentNotificationsSetup.commissionAssignmentChange === "both"
            }
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, commissionAssignmentChange: "both" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
        </RowWrapper>
        <RowWrapper>
          <RowTitle>Utworzenie zapotrzebowania</RowTitle>
          <Switch
            checked={currentNotificationsSetup.requestCreation === "disabled"}
            onClick={() => {
              if (currentNotificationsSetup.requestCreation !== "disabled") {
                setCurrentNotificationsSetup((state) => {
                  return { ...state, requestCreation: "disabled" };
                });
              }
            }}
            disabled={
              currentNotificationsSetup.requestCreation === "disabled" ||
              !isNotificationConfigurationOpened
            }
          />
          <Switch
            checked={currentNotificationsSetup.requestCreation === "sms"}
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, requestCreation: "sms" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
          <Switch
            checked={currentNotificationsSetup.requestCreation === "email"}
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, requestCreation: "email" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
          <Switch
            checked={currentNotificationsSetup.requestCreation === "both"}
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, requestCreation: "both" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
        </RowWrapper>
        <RowWrapper>
          <RowTitle>Zmiana statusu zapotrzebowania</RowTitle>
          <Switch
            checked={currentNotificationsSetup.requestChange === "disabled"}
            onClick={() => {
              if (currentNotificationsSetup.requestChange !== "disabled") {
                setCurrentNotificationsSetup((state) => {
                  return { ...state, requestChange: "disabled" };
                });
              }
            }}
            disabled={
              currentNotificationsSetup.requestChange === "disabled" ||
              !isNotificationConfigurationOpened
            }
          />
          <Switch
            checked={currentNotificationsSetup.requestChange === "sms"}
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, requestChange: "sms" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
          <Switch
            checked={currentNotificationsSetup.requestChange === "email"}
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, requestChange: "email" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
          <Switch
            checked={currentNotificationsSetup.requestChange === "both"}
            onClick={() =>
              setCurrentNotificationsSetup((state) => {
                return { ...state, requestChange: "both" };
              })
            }
            disabled={!isNotificationConfigurationOpened}
          />
        </RowWrapper>
        {userNotifications.lastModifiedBy !== null && (
          <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
            <Box sx={{ display: "flex", gap: "0.2rem" }}>
              <Info sx={{ fontSize: "0.9rem" }} />
              <Typography sx={{ fontSize: "0.7rem" }}>
                Ostatnio modyfikowane przez
              </Typography>
              <Typography sx={{ fontSize: "0.7rem", fontWeight: 700 }}>
                {userNotifications.lastModifiedBy.username}
              </Typography>
              <Typography sx={{ fontSize: "0.7rem" }}>
                {`o ${dateFormatView(userNotifications.lastModificationDate)}`}
              </Typography>
            </Box>
          </Box>
        )}
        {isNotificationConfigurationOpened && (
          <ButtonWrapper>
            <AcceptButton
              text="Zmień"
              onClick={() => changeNotifications()}
              startIcon={<Check />}
            />
            <EditButton2
              startIcon={<Clear />}
              onClick={() => {
                setIsNotificationConfigurationOpened(false);
                setCurrentNotificationsSetup(initialState.current);
              }}
              text="Anuluj"
            />
          </ButtonWrapper>
        )}
      </>
    </SectionWrapper>
  );
};

export default UserNotificationsPanel;
