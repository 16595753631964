/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Search } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import SelectChipsItem from "./SelectChipsItem";
import Loading from "../../Loading/Loading";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
}));

const CollectionWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  gap: "0.3rem",
}));

const SelectChips = ({
  addChip,
  selectedChips,
  sliceName,
  collectionName,
  getDataFunction,
  cleanDataFunction,
}) => {
  const dispatch = useDispatch();
  const collection = useSelector((slice) => slice[sliceName])[collectionName];
  const [searchingValue, setSearchingValue] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(false);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getDataFunction(searchingValue));
    setIsDataLoading(false);
  }, [searchingValue]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => dispatch(cleanDataFunction());
  }, []);

  return (
    <Wrapper>
      <TextField
        value={searchingValue}
        onChange={(e) => setSearchingValue(e.target.value)}
        label="wyszukiwanie"
        placeholder="szukaj..."
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <CollectionWrapper>
        {isDataLoading ? (
          <Loading size="small" width="10%" />
        ) : (
          collection !== null &&
          collection
            .filter((item) => {
              const findingIndex = selectedChips.findIndex(
                (chip) => chip.id === item.id
              );
              if (findingIndex !== -1) return false;
              return true;
            })
            .map((item, key) => (
              <SelectChipsItem key={key} addChip={addChip} itemData={item} />
            ))
        )}
      </CollectionWrapper>
    </Wrapper>
  );
};

export default SelectChips;
