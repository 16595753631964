/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCategories,
  getCategoriesCount,
  getPaginatedCategories,
} from "../../../slices/categorySlice";
import CategoryItem from "../CategoryItem/CategoryItem";
import CategoryForm from "../../Category/CategoryForm/CategoryForm";
import Pagination from "../../Pagination/Pagination";
import { DEFAULT_DISPLAY_ITEMS_QUANTITY } from "../../../static/static";
import { Add, Category, Warning } from "@mui/icons-material";
import Loading from "../../Loading/Loading";
import PageHeader from "../../PageHeader/PageHeader";
import { DetailsButton } from "../../FunctionalButtons/FunctionalButtons";
import RefreshButton from "../../RefreshButton/RefreshButton";
import BlankSpace from "../../BlankSpace/BlankSpace";
import CustomSearchField from "../../CustomSearchField/CustomSearchField";
import TableHeader from "../../TableHeader/TableHeader";
import { categoriesListHeaders } from "../../../static/tablesHeaders";
import EmptyListError from "../../EmptyListError/EmptyListError";
import PageWrapper from "../../PageWrapper/PageWrapper";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import AdditionalFunctionalitiesWrapper from "../../AdditionalFunctionalitiesWrapper/AdditionalFunctionalitiesWrapper";
import useMediaQ from "../../../hooks/useMediaQ";
import MobileDataSorter from "../../MobileDataSorter/MobileDataSorter";
import TableWrapper from "../../TableWrapper/TableWrapper";
import ButtonWrapper from "../../ButtonWrapper/ButtonWrapper";
import ReturnButton from "../../ReturnButton/ReturnButton";

const CategoriesList = ({ refresh, setRefresh }) => {
  const dispatch = useDispatch();
  const searchingRef = useRef("");
  const { categories, categoriesCount } = useSelector(
    (slice) => slice.category
  );
  const [sorter, setSorter] = useState("name");
  const [sorting, setSorting] = useState("ascending");
  const [searchingValue, setSearchingValue] = useState("");
  const [displaySearchingValue, setDisplaySearchingValue] = useState("");
  const [isAddCategoryFormVisible, setAddCategoryFormVisible] = useState(false);
  const [leftIndex, setLeftIndex] = useState(0);
  const [rightIndex, setRightIndex] = useState(DEFAULT_DISPLAY_ITEMS_QUANTITY);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { isPhoneDisplay, isTabletDisplay } = useMediaQ();

  const loadDataCount = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(
      getCategoriesCount({
        searchingValue,
      })
    );
  }, [refresh, searchingValue]);

  const loadData = useCallback(async () => {
    await dispatch(
      getPaginatedCategories({
        leftIndex,
        rightIndex,
        sortingParam: sorter,
        sortingOrder: sorting,
        searchingValue,
      })
    );
    setIsDataLoading(false);
  }, [refresh, leftIndex, rightIndex, sorter, sorting, searchingValue]);

  useEffect(() => {
    loadDataCount();
  }, [loadDataCount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => dispatch(clearCategories());
  }, []);

  const closeAddForm = () => {
    setAddCategoryFormVisible(false);
  };

  return (
    <PageWrapper
      width={isPhoneDisplay ? "90%" : isTabletDisplay ? "80%" : "50%"}
    >
      <PageHeader
        titleText="Kategorie"
        icon={<Category />}
        onRightComponent={
          <ButtonWrapper>
            <DetailsButton
              startIcon={<Add />}
              text="Dodaj kategorię"
              onClick={() => setAddCategoryFormVisible(true)}
            />
            <ReturnButton />
          </ButtonWrapper>
        }
      >
        <RefreshButton onClick={() => setRefresh((state) => !state)} />
      </PageHeader>
      <BlankSpace />
      <SectionWrapper>
        <AdditionalFunctionalitiesWrapper>
          <CustomSearchField
            displaySearchingValue={displaySearchingValue}
            setDisplaySearchingValue={setDisplaySearchingValue}
            searchingValue={searchingValue}
            setSearchingValue={setSearchingValue}
            searchingRef={searchingRef}
            placeholder="szukaj kategorii..."
          />
          <MobileDataSorter
            tableHeader={categoriesListHeaders}
            setOuterSorter={setSorter}
            setOuterSorting={setSorting}
            defaultSorter="name"
          />
        </AdditionalFunctionalitiesWrapper>
        <TableWrapper>
          <TableHeader
            headers={categoriesListHeaders}
            setSorter={setSorter}
            setSorting={setSorting}
            gap="1%"
          />
          <Pagination
            collectionSize={categoriesCount}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            setLeftIndex={setLeftIndex}
            setRightIndex={setRightIndex}
          >
            {isDataLoading ? (
              <Loading />
            ) : (
              <>
                {categories !== null &&
                  categories.map((category, id) => (
                    <CategoryItem
                      orderNumber={leftIndex + id + 1}
                      key={category.id}
                      category={category}
                      setRefresh={setRefresh}
                    />
                  ))}
              </>
            )}
            {categories !== null && categories.length === 0 && (
              <EmptyListError icon={<Warning />} message="Brak kategorii" />
            )}
          </Pagination>
        </TableWrapper>

        <Dialog open={isAddCategoryFormVisible} onClose={() => closeAddForm()}>
          <CategoryForm
            closeAddForm={() => closeAddForm()}
            setRefresh={() => setRefresh((state) => !state)}
          />
        </Dialog>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default CategoriesList;
