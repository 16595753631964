export const requestSummaryViewHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Data utworzenia",
    sorter: "creationDate",
    disabled: false,
    columnSize: "15%",
  },
  {
    id: 3,
    headerName: "Temat",
    sorter: "topic",
    disabled: false,
    columnSize: "20%"
  },
  {
    id: 4,
    headerName: "Nazwa magazynu",
    sorter: "storage",
    disabled: false,
    columnSize: "20%",
  },
  {
    id: 5,
    headerName: "Nadawca",
    sorter: "user",
    disabled: false,
    columnSize: "15%",
  },
  {
    id: 6,
    headerName: "Status",
    sorter: "status",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 7,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const tasksSummaryHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "3%"
  },
  {
    id: 2,
    headerName: "Nr ref zlecenia",
    sorter: "commissionId",
    disabled: false,
    columnSize: "8%",
  },
  {
    id: 3,
    headerName: "Planowana data realizacji",
    sorter: "planningExecutionDate",
    disabled: false,
    columnSize: "15%"
  },
  {
    id: 4,
    headerName: "Data utworzenia",
    sorter: "creationDate",
    disabled: false,
    columnSize: "15%",
  },
  {
    id: 5,
    headerName: "Proponowany serwisant",
    sorter: "proposedExecutor",
    disabled: false,
    columnSize: "12%",
  },
  {
    id: 5,
    headerName: "Serwisant",
    sorter: "assignedExecutor",
    disabled: false,
    columnSize: "12%",
  },
  {
    id: 6,
    headerName: "Status",
    sorter: "status",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 7,
    headerName: "Typ",
    sorter: "type",
    disabled: true,
    columnSize: "10%",
  },
  {
    id: 8,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const storagesListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "25%",
  },
  {
    id: 3,
    headerName: "Przypisany pracownik",
    sorter: "user",
    disabled: false,
    columnSize: "20%"
  },
  {
    id: 4,
    headerName: "Adres",
    sorter: "address",
    disabled: false,
    columnSize: "25%",
  },
  {
    id: 5,
    disabled: "blank",
    columnSize: "1fr",
  },
];


export const ShipmentsByDestinationHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "4%"
  },
  {
    id: 2,
    headerName: "Data wysłania",
    sorter: "creationDate",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 3,
    headerName: "Numer listu",
    sorter: "letterNumber",
    disabled: false,
    columnSize: "10%"
  },
  {
    id: 4,
    headerName: "Status",
    sorter: "status",
    disabled: false,
    columnSize: "12%",
  },
  {
    id: 5,
    headerName: "Zawartość przesyłki",
    disabled: "true",
    columnSize: "40%",
  },
  {
    id: 6,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const ShipmentsSummaryHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "3%"
  },
  {
    id: 2,
    headerName: "Data wysłania",
    sorter: "creationDate",
    disabled: false,
    columnSize: "8%",
  },
  {
    id: 3,
    headerName: "Numer listu",
    sorter: "letterNumber",
    disabled: false,
    columnSize: "10%"
  },
  {
    id: 4,
    headerName: "Status",
    sorter: "status",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 5,
    headerName: "Magazyn wychodzący",
    sorter: "",
    disabled: true,
    columnSize: "8%",
  }, {
    id: 6,
    headerName: "Magazyn przychodzący",
    sorter: "",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 7,
    headerName: "Zawartość przesyłki",
    disabled: "true",
    columnSize: "30%",
  },
  {
    id: 8,
    disabled: "blank",
    columnSize: "1fr",
  },
];


export const AvailableUnitsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "PartNumber",
    sorter: "pastNumber",
    disabled: false,
    columnSize: "20%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "25%"
  },
  {
    id: 4,
    headerName: "Kategoria",
    sorter: "category",
    disabled: false,
    columnSize: "10%"
  }, {
    id: 5,
    headerName: "Producent",
    sorter: "producer",
    disabled: false,
    columnSize: "10%"
  }, {
    id: 6,
    headerName: "Akt. liczba",
    sorter: "description",
    disabled: true,
    columnSize: "10%"
  },
  {
    id: 7,
    disabled: "blank",
    columnSize: "1fr",
  },
];


export const AddedUnitsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "PartNumber",
    sorter: "pastNumber",
    disabled: false,
    columnSize: "20%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "20%"
  },
  {
    id: 4,
    headerName: "Nr seryjny",
    sorter: "",
    disabled: true,
    columnSize: "15%"
  }, {
    id: 5,
    headerName: "Liczba sztuk",
    sorter: "",
    disabled: true,
    columnSize: "20%"
  },
  {
    id: 6,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const ShipmentsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "4rem"
  },
  {
    id: 2,
    headerName: "Data utworzenia",
    sorter: "creationDate",
    disabled: false,
    columnSize: "10rem",
  },
  {
    id: 3,
    headerName: "Data wysłania",
    sorter: "sendDate",
    disabled: false,
    columnSize: "10rem"
  },
  {
    id: 4,
    headerName: "Data otrzymania",
    sorter: "deliveredDate",
    disabled: false,
    columnSize: "10rem"
  }, {
    id: 5,
    headerName: "Nr listu",
    sorter: "letterNumber",
    disabled: false,
    columnSize: "10rem"
  },
  {
    id: 6,
    headerName: "Magazyn wysyłkowy",
    sorter: "baseStorage",
    disabled: false,
    columnSize: "14rem"
  },
  {
    id: 7,
    headerName: "Magazyn docelowy",
    sorter: "destinationStorage",
    disabled: false,
    columnSize: "14rem"
  },
  {
    id: 8,
    headerName: "Status",
    sorter: "status",
    disabled: true,
    columnSize: "8rem"
  },
  {
    id: 9,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const moveUnitsAvailableUnitsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "PartNumber",
    sorter: "partNumber",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "15%"
  },
  {
    id: 4,
    headerName: "Numer seryjny",
    sorter: "serialNumber",
    disabled: false,
    columnSize: "10%"
  }, {
    id: 5,
    headerName: "Kategoria",
    sorter: "serialNumber",
    disabled: false,
    columnSize: "10%"
  }, {
    id: 6,
    headerName: "Producent",
    sorter: "serialNumber",
    disabled: false,
    columnSize: "10%"
  }, {
    id: 7,
    headerName: "Status",
    sorter: "status",
    disabled: false,
    columnSize: "8%"
  },
  {
    id: 8,
    headerName: "Dostępne sztuki",
    sorter: "quantity",
    disabled: false,
    columnSize: "8%"
  },
  {
    id: 9,
    headerName: "Wybrana liczba",
    sorter: "destinationStorage",
    disabled: true,
    columnSize: "8%"
  }, {
    id: 10,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const moveUnitsAddedUnitsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "PartNumber",
    sorter: "partNumber",
    disabled: false,
    columnSize: "20%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "20%"
  },
  {
    id: 4,
    headerName: "Numer seryjny",
    sorter: "serialNumber",
    disabled: false,
    columnSize: "20%"
  }, {
    id: 5,
    headerName: "Status",
    sorter: "status",
    disabled: false,
    columnSize: "8%"
  },
  {
    id: 6,
    headerName: "Wybrana liczba",
    sorter: "chosenQuantity",
    disabled: true,
    columnSize: "5%"
  }, {
    id: 7,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const requestListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "",
    sorter: "",
    disabled: true,
    columnSize: "2%"
  },
  {
    id: 3,
    headerName: "Data wysłania",
    sorter: "creationDate",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 4,
    headerName: "Temat",
    sorter: "topic",
    disabled: false,
    columnSize: "25%"
  },
  {
    id: 5,
    headerName: "Magazyn docelowy",
    sorter: "destinationStorage",
    disabled: false,
    columnSize: "15%"
  }, {
    id: 6,
    headerName: "Nadawca",
    sorter: "sender",
    disabled: false,
    columnSize: "15%"
  },
  {
    id: 7,
    headerName: "Status",
    sorter: "status",
    disabled: true,
    columnSize: "8%"
  }, {
    id: 8,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const unitListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "4%"
  },
  {
    id: 2,
    headerName: "Part Number",
    sorter: "partNumber",
    disabled: false,
    columnSize: "15%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "10%"
  },
  {
    id: 4,
    headerName: "Numer seryjny",
    sorter: "serialNumber",
    disabled: false,
    columnSize: "10%"
  }, {
    id: 5,
    headerName: "Kategoria",
    sorter: "category",
    disabled: true,
    columnSize: "10%"
  }, {
    id: 6,
    headerName: "Producent",
    sorter: "producer",
    disabled: true,
    columnSize: "10%"
  }, {
    id: 7,
    headerName: "Status",
    sorter: "status",
    disabled: false,
    columnSize: "10%"
  },
  {
    id: 8,
    headerName: "Liczba",
    sorter: "quantity",
    disabled: true,
    columnSize: "10%"
  },
  {
    id: 9,
    headerName: "",
    sorter: "",
    disabled: true,
    columnSize: "1fr"
  }
];

export const productsListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Part Number",
    sorter: "partNumber",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "15%"
  },
  {
    id: 4,
    headerName: "Kategoria",
    sorter: "category",
    disabled: false,
    columnSize: "10%"
  }, {
    id: 5,
    headerName: "Producent",
    sorter: "producer",
    disabled: false,
    columnSize: "10%"
  },
  {
    id: 6,
    headerName: "Opis",
    sorter: "description",
    disabled: true,
    columnSize: "25%"
  },
  {
    id: 7,
    disabled: "blank",
    columnSize: "1fr",
  },
];


export const categoriesListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 3,
    headerName: "Opis",
    sorter: "description",
    disabled: false,
    columnSize: "50%"
  },
  {
    id: 4,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const producersListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "10%"
  },
  {
    id: 2,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "40%",
  },
  {
    id: 3,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const taskListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "10%"
  },
  {
    id: 2,
    headerName: "Kod RT",
    sorter: "locationCode",
    disabled: false,
    columnSize: "30%",
  },
  {
    id: 3,
    headerName: "Data utworzenia",
    sorter: "creationDate",
    disabled: false,
    columnSize: "25%",
  },
  {
    id: 4,
    headerName: "Status",
    sorter: "status",
    disabled: true,
    columnSize: "8rem",
  },
  {
    id: 5,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const historyTaskListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Kod RT",
    sorter: "locationCode",
    disabled: false,
    columnSize: "15%",
  },
  {
    id: 3,
    headerName: "Data utworzenia",
    sorter: "creationDate",
    disabled: false,
    columnSize: "15%",
  },
  {
    id: 4,
    headerName: "Data realizacji",
    sorter: "executionDate",
    disabled: false,
    columnSize: "15%",
  },
  {
    id: 5,
    headerName: "Status",
    sorter: "status",
    disabled: true,
    columnSize: "10%",
  },
  {
    id: 6,
    headerName: "Typ",
    sorter: "type",
    disabled: true,
    columnSize: "10%",
  },
  {
    id: 7,
    headerName: "Wykonawca",
    sorter: "",
    disabled: true,
    columnSize: "10rem",
  },
  {
    id: 8,
    disabled: "blank",
    columnSize: "8rem",
  },
];

export const commissionListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Nr ref",
    sorter: "id",
    disabled: false,
    columnSize: "5%",
  },
  {
    id: 3,
    headerName: "Nazwa firmy",
    sorter: "companyName",
    disabled: false,
    columnSize: "12%",
  },
  {
    id: 4,
    headerName: "Kod lokalizacji",
    sorter: "locationCode",
    disabled: false,
    columnSize: "12%",
  },
  {
    id: 5,
    headerName: "Data utworzenia",
    sorter: "creationDate",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 6,
    headerName: "Data zakończenia",
    sorter: "finishDate",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 7,
    headerName: "Status",
    sorter: "status",
    disabled: true,
    columnSize: "8%",
  },

  {
    id: 8,
    headerName: "Nazwa urządzenia",
    sorter: "deviceName",
    disabled: false,
    columnSize: "10%",
  },

  {
    id: 9,
    headerName: "Utworzone przez",
    sorter: "createdBy",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 10,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const commissionCodesListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "2rem"
  },
  {
    id: 2,
    headerName: "",
    disabled: true,
    columnSize: "2rem"
  },
  {
    id: 3,
    headerName: "Kod",
    sorter: "id",
    disabled: true,
    columnSize: "4rem"
  },
  {
    id: 4,
    headerName: "Nazwa",
    sorter: "partNumber",
    disabled: true,
    columnSize: "15rem",
  },
  {
    id: 5,
    headerName: "Opis kodu",
    sorter: "partNumber",
    disabled: true,
    columnSize: "25rem",
  },
  {
    id: 6,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const commissionTasksViewHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "2%"
  },
  {
    id: 2,
    headerName: "Data utworzenia",
    sorter: "",
    disabled: true,
    columnSize: "10%",
  },
  {
    id: 3,
    headerName: "Typ",
    sorter: "",
    disabled: true,
    columnSize: "7%"
  },
  {
    id: 4,
    headerName: "Status",
    sorter: "",
    disabled: true,
    columnSize: "7%",
  },
  {
    id: 5,
    headerName: "Data plan. real.",
    sorter: "",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 6,
    headerName: "Data realizacji",
    sorter: "",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 7,
    headerName: "Proponowany wykonawca",
    sorter: "",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 8,
    headerName: "Przypisany pracownik",
    sorter: "",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 9,
    headerName: "Wykonawca",
    sorter: "",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 10,
    headerName: "Opis decyzji",
    sorter: "",
    disabled: true,
    columnSize: "14%",
  },
  {
    id: 11,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const taskCommentsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Data utworzenia",
    sorter: "",
    disabled: true,
    columnSize: "10%",
  },
  {
    id: 3,
    headerName: "Autor",
    sorter: "",
    disabled: true,
    columnSize: "10%",
  },
  {
    id: 4,
    headerName: "Treść",
    sorter: "",
    disabled: true,
    columnSize: "60%",
  },
  {
    id: 5,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const usersListHeadersV1 = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "ID",
    sorter: "id",
    disabled: false,
    columnSize: "5%",
  },
  {
    id: 3,
    headerName: "Login",
    sorter: "username",
    disabled: false,
    columnSize: "10%"
  },
  {
    id: 4,
    headerName: "Imię",
    sorter: "name",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 5,
    headerName: "Nazwisko",
    sorter: "surname",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 6,
    headerName: "Rola",
    sorter: "role",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 7,
    headerName: "Źródło",
    sorter: "source",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 8,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const usersListHeadersV2 = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "ID",
    sorter: "id",
    disabled: false,
    columnSize: "5%",
  },
  {
    id: 3,
    headerName: "Login",
    sorter: "username",
    disabled: false,
    columnSize: "10%"
  },
  {
    id: 4,
    headerName: "Imię",
    sorter: "name",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 5,
    headerName: "Nazwisko",
    sorter: "surname",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 6,
    headerName: "Rola",
    sorter: "role",
    disabled: true,
    columnSize: "15%",
  },
  {
    id: 7,
    headerName: "Źródło",
    sorter: "source",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 8,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const companiesListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "ID",
    sorter: "id",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "20%"
  },
  {
    id: 4,
    headerName: "Adres",
    sorter: "address",
    disabled: false,
    columnSize: "30%",
  },
  {
    id: 5,
    headerName: "Przypisany użytkownik",
    sorter: "assignedUser",
    disabled: false,
    columnSize: "15%",
  },
  {
    id: 6,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const locationsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Kod",
    sorter: "code",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "20%"
  },
  {
    id: 4,
    headerName: "Adres",
    sorter: "address",
    disabled: false,
    columnSize: "35%",
  },
  {
    id: 5,
    headerName: "Liczba urządzeń",
    sorter: "",
    disabled: true,
    columnSize: "10%",
  },
  {
    id: 6,
    headerName: "Synch.",
    sorter: "",
    disabled: true,
    columnSize: "5%",
  },
  {
    id: 7,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const locationDevicesViewHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "10%"
  },
  {
    id: 2,
    headerName: "Nr seryjny",
    sorter: "code",
    disabled: false,
    columnSize: "20%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "20%"
  },
  {
    id: 4,
    headerName: "Typ",
    sorter: "type",
    disabled: false,
    columnSize: "20%",
  },
  {
    id: 5,
    headerName: "Synchronizacja",
    sorter: "",
    disabled: true,
    columnSize: "10%",
  },
  {
    id: 6,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const shipmentUnitsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Part Number",
    sorter: "partNumber",
    disabled: true,
    columnSize: "20%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: true,
    columnSize: "20%"
  },
  {
    id: 4,
    headerName: "Nr seryjny",
    sorter: "serialNumber",
    disabled: false,
    columnSize: "20%",
  },
  {
    id: 5,
    headerName: "Status",
    sorter: "status",
    disabled: false,
    columnSize: "15%",
  },
  {
    id: 6,
    headerName: "Liczba",
    sorter: "quantity",
    disabled: false,
    columnSize: "10%",
  }
];


export const workModalAvailableUnitsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "4%"
  },
  {
    id: 2,
    headerName: "Part Number",
    sorter: "partNumber",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: true,
    columnSize: "8%"
  },
  {
    id: 4,
    headerName: "Kategoria",
    sorter: "category",
    disabled: true,
    columnSize: "10%"
  },
  {
    id: 5,
    headerName: "Producent",
    sorter: "producer",
    disabled: true,
    columnSize: "10%"
  },
  {
    id: 6,
    headerName: "Nr seryjny",
    sorter: "serialNumber",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 7,
    headerName: "Status",
    sorter: "status",
    disabled: false,
    columnSize: "8%",
  },
  {
    id: 8,
    headerName: "Liczba",
    sorter: "quantity",
    disabled: false,
    columnSize: "5%",
  },
  {
    id: 9,
    headerName: "Wybrana liczba",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 10,
    headerName: "Liczba zwróconych",
    disabled: true,
    columnSize: "8%",
  },
  {
    id: 11,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const workModalMountedUnitsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Part Number",
    sorter: "partNumber",
    disabled: true,
    columnSize: "10%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: true,
    columnSize: "10%"
  },
  {
    id: 4,
    headerName: "Nr seryjny",
    sorter: "serialNumber",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 5,
    headerName: "Status",
    sorter: "status",
    disabled: false,
    columnSize: "8%",
  },
  {
    id: 6,
    headerName: "Liczba",
    sorter: "quantity",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 7,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const workModalDOAUnitsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Part Number",
    sorter: "partNumber",
    disabled: true,
    columnSize: "10%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: true,
    columnSize: "10%"
  },
  {
    id: 4,
    headerName: "Nr seryjny",
    sorter: "serialNumber",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 5,
    headerName: "Status",
    sorter: "status",
    disabled: false,
    columnSize: "8%",
  },
  {
    id: 6,
    headerName: "Liczba",
    sorter: "quantity",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 7,
    disabled: "blank",
    columnSize: "1fr",
  },
];

export const workModalUnmountedUnitsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Part Number",
    sorter: "partNumber",
    disabled: true,
    columnSize: "10%",
  },
  {
    id: 4,
    headerName: "Nr seryjny",
    sorter: "serialNumber",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 5,
    headerName: "Status",
    sorter: "status",
    disabled: false,
    columnSize: "8%",
  },
  {
    id: 6,
    headerName: "Wybrana liczba",
    sorter: "quantity",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 7,
    disabled: "blank",
    columnSize: "1fr",
  },
];


export const utilizationReportsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Data utworzenia",
    sorter: "creationDate",
    disabled: false,
    columnSize: "38%",
  },
  {
    id: 3,
    headerName: "Utworzył",
    sorter: "createdBy",
    disabled: false,
    columnSize: "38%"
  },
  {
    id: 4,
    disabled: "blank",
    columnSize: "1fr",
  },
];


export const shipmentInventoryHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Nazwa",
    sorter: "name",
    disabled: true,
    columnSize: "15%",
  },
  {
    id: 3,
    headerName: "PartNumber",
    sorter: "partNumber",
    disabled: true,
    columnSize: "15%"
  },
  {
    id: 4,
    headerName: "Nr ser.",
    sorter: "serialNumber",
    disabled: true,
    columnSize: "15%"
  }, {
    id: 5,
    headerName: "Liczba",
    sorter: "quantity",
    disabled: true,
    columnSize: "10%"
  },
  {
    id: 6,
    headerName: "Status",
    sorter: "status",
    disabled: true,
    columnSize: "10%"
  },
  {
    id: 7,
    disabled: "blank",
    columnSize: "1fr",
  },
];


export const userPermissionsHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "4rem"
  },
  {
    id: 2,
    headerName: "Nazwa",
    sorter: "creationDate",
    disabled: false,
    columnSize: "15rem",
  },
  {
    id: 3,
    headerName: "Opis",
    sorter: "letterNumber",
    disabled: false,
    columnSize: "30rem"
  },
];


export const storageReportsListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "2%"
  },
  {
    id: 1.5,
    headerName: "",
    sorter: "",
    disabled: true,
    columnSize: "3%"
  },
  {
    id: 2,
    headerName: "Data utworzenia",
    sorter: "creationDate",
    disabled: false,
    columnSize: "10%",
  },
  {
    id: 3,
    headerName: "Typ operacji",
    sorter: "operationType",
    disabled: false,
    columnSize: "8%"
  },
  {
    id: 4,
    headerName: "Użytkownik",
    sorter: "user",
    disabled: false,
    columnSize: "10%"
  },
  {
    id: 5,
    headerName: "Magazyn bazowy",
    sorter: "baseStorage",
    disabled: false,
    columnSize: "10%"
  },
  {
    id: 6,
    headerName: "Przesyłka",
    sorter: "shipment",
    disabled: false,
    columnSize: "10%"
  },
  {
    id: 7,
    headerName: "Magazyn docelowy",
    sorter: "destinationStorage",
    disabled: false,
    columnSize: "10%"
  },
  {
    id: 8,
    headerName: "Raport utylizacyjny",
    sorter: "utilizationReport",
    disabled: false,
    columnSize: "8%"
  },
  {
    id: 9,
    headerName: "Zadanie",
    sorter: "task",
    disabled: false,
    columnSize: "8%"
  },
  {
    id: 4,
    disabled: "blank",
    columnSize: "1fr",
  },
];


export const storageReportUnitListHeaders = [{
    id: 1,
    headerName: "Lp. ",
    sorter: "",
    disabled: true,
    columnSize: "5%"
  },
  {
    id: 2,
    headerName: "Part Number",
    sorter: "partNumber",
    disabled: false,
    columnSize: "15%",
  },
  {
    id: 3,
    headerName: "Nazwa",
    sorter: "name",
    disabled: false,
    columnSize: "12%"
  },
  {
    id: 4,
    headerName: "Numer seryjny",
    sorter: "serialNumber",
    disabled: false,
    columnSize: "12%"
  }, {
    id: 5,
    headerName: "Kategoria",
    sorter: "category",
    disabled: false,
    columnSize: "12%"
  }, {
    id: 6,
    headerName: "Producent",
    sorter: "producer",
    disabled: false,
    columnSize: "12%"
  }, {
    id: 7,
    headerName: "Status",
    sorter: "status",
    disabled: false,
    columnSize: "15%"
  },
  {
    id: 8,
    headerName: "Liczba",
    sorter: "quantity",
    disabled: true,
    columnSize: "10%"
  }
];