/* eslint-disable react/prop-types */
import React from "react";
import { Typography } from "@mui/material";
import { Article, Autorenew } from "@mui/icons-material";
import { dateFormatView } from "../../../static/functions";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../PageHeader/PageHeader";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
import {
  DataBox,
  DataTitle,
  InfoWrapper,
  SpecialDataBox,
} from "../../ObjectDetailsElements/ObjectDetailsElements";

const DeviceDetails = ({ chosenDevice }) => {
  const navigate = useNavigate();

  return (
    <SectionWrapper>
      <PageHeader icon={<Article />} titleText="Dane" size="medium" />
      <InfoWrapper>
        <DataBox>
          <DataTitle>Id:</DataTitle>
          <Typography>{chosenDevice.id}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Nazwa:</DataTitle>
          <Typography>{chosenDevice.name}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Lokalizacja:</DataTitle>
          <Typography
            onClick={() => navigate(`/locations/${chosenDevice.location.code}`)}
            sx={{ textDecoration: "underline" }}
          >
            {`[${chosenDevice.location.code}] ${chosenDevice.location.name}`}
          </Typography>
          <Typography>{chosenDevice.description}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Data utworzenia:</DataTitle>
          <Typography>{dateFormatView(chosenDevice.creationDate)}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Zmodyfikowane przez:</DataTitle>
          <Typography>
            {chosenDevice.lastModifiedBy !== null
              ? `${chosenDevice.lastModifiedBy.name} ${chosenDevice.lastModifiedBy.surname}`
              : "Brak"}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Utworzył:</DataTitle>
          <Typography>
            {chosenDevice.createdBy
              ? `${chosenDevice.createdBy.name} ${chosenDevice.createdBy.surname}`
              : "Brak"}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Data ostatniej mod.:</DataTitle>
          <Typography>
            {chosenDevice.lastModificationDate
              ? dateFormatView(chosenDevice.lastModificationDate)
              : "Brak"}
          </Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Nr seryjny:</DataTitle>
          <Typography>{dateFormatView(chosenDevice.serialNumber)}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Typ:</DataTitle>
          <Typography>{chosenDevice.type}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Orientacja:</DataTitle>
          <Typography>{chosenDevice.orientation}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Czy zsynchronizowane:</DataTitle>
          {chosenDevice.isSynchronized ? (
            <Autorenew
              sx={{
                color: "darkgreen",
              }}
            />
          ) : (
            <Autorenew
              sx={{
                color: "red",
              }}
            />
          )}
        </DataBox>
        <SpecialDataBox>
          <DataTitle>Opis:</DataTitle>
          <Typography>{chosenDevice.description}</Typography>
        </SpecialDataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default DeviceDetails;
