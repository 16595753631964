import React from "react";
import PageWrapper from "../PageWrapper/PageWrapper";
import PageHeader from "../PageHeader/PageHeader";
import { AccessTime } from "@mui/icons-material";
import SectionWrapper from "../SectionWrapper/SectionWrapper";
import ChangeLog from "./ChangeLog";
import BlankSpace from "../BlankSpace/BlankSpace";
import { ReturnButton } from "../FunctionalButtons/FunctionalButtons";
import { useNavigate } from "react-router-dom";
import useMediaQ from "../../hooks/useMediaQ";

const ChangeLogPage = () => {
  const navigate = useNavigate();
  const { isPhoneDisplay, isTabletDisplay } = useMediaQ();
  return (
    <PageWrapper
      width={isPhoneDisplay ? "90%" : isTabletDisplay ? "80%" : "40%"}
    >
      <PageHeader
        icon={<AccessTime />}
        titleText={"ChangeLog"}
        onRightComponent={<ReturnButton onClick={() => navigate(-1)} />}
      />
      <BlankSpace />
      <SectionWrapper>
        <ChangeLog />
      </SectionWrapper>
    </PageWrapper>
  );
};

export default ChangeLogPage;
