/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Box, Alert, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearLoggingError } from "../../slices/authSlice";
import {
  clearStorageErrors,
  clearStorageInfo,
} from "../../slices/storageSlice";
import { keyframes } from "@emotion/react";
import { clearCompaniesError } from "../../slices/companySlice";
import { clearUnitError, clearUnitInfo } from "../../slices/unitSlice";
import { clearDeviceInfo } from "../../slices/deviceSlice";
import { clearErrors } from "../../slices/commissionSlice";
import {
  clearShipmentError,
  clearShipmentInfo,
} from "../../slices/shipmentSlice";
import { clearUserError } from "../../slices/userSlice";

const fadeIn = keyframes({
  "0%": { opacity: 0 },
  "10%": { opacity: 1 },
  "90%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const CustomAlert = styled(Alert)(({ theme }) => ({
  position: "fixed",
  bottom: "4%",
  right: "2%",
  width: "40%",
  minHeight: "3rem",
  animation: `${fadeIn} 5s backwards`,
  [theme.breakpoints.down("laptop")]: {
    width: "70%",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "90%",
    bottom: "0%",
    right: "0.5%",
  },
}));

const UserInformationSystem = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loggingError, isLoggedIn } = useSelector((slice) => slice.auth);
  const { storageError, storageInfo } = useSelector((slice) => slice.storage);
  const { companyError } = useSelector((slice) => slice.company);
  const { errors: commissionErrors } = useSelector((slice) => slice.commission);
  const [tokenExpiredError, setTokenExpiredError] = useState(
    localStorage.getItem("tokenExpiredError")
  );
  const { unitInfo, unitError } = useSelector((slice) => slice.unit);
  const { deviceInfo } = useSelector((slice) => slice.device);
  const { shipmentInfo, shipmentError } = useSelector(
    (slice) => slice.shipment
  );
  const { userInfo, userError } = useSelector((slice) => slice.user);

  const clearState = () => {
    dispatch(clearLoggingError());
    dispatch(clearStorageErrors());
    dispatch(clearCompaniesError());
    dispatch(clearUnitInfo());
    dispatch(clearDeviceInfo());
    dispatch(clearUnitError());
    dispatch(clearErrors());
    dispatch(clearStorageInfo());
    dispatch(clearShipmentInfo());
    dispatch(clearShipmentError());
    dispatch(clearUserError());
    localStorage.removeItem("tokenExpiredError");
    setTokenExpiredError(null);
  };

  useEffect(() => {
    setTokenExpiredError(localStorage.getItem("tokenExpiredError"));
  }, [isLoggedIn]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (storageError !== null) {
        if (storageError === "brak magazynu głównego") {
          navigate("/storage-add");
        } else {
          navigate("/storages");
        }
      }
      if (companyError !== null) {
        navigate("/companies");
      }
      clearState();
    }, 5000);

    return () => clearTimeout(timer);
  }, [
    loggingError,
    storageError,
    companyError,
    unitError,
    tokenExpiredError,
    commissionErrors.commission,
    commissionErrors.commissionForm,
    unitInfo,
    deviceInfo,
    storageInfo,
    shipmentError,
    shipmentInfo,
    userInfo,
    userError,
  ]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {children}
      <>
        {(loggingError ||
          storageError ||
          tokenExpiredError ||
          storageError ||
          companyError ||
          unitError ||
          commissionErrors.commission ||
          commissionErrors.commissionForm ||
          userInfo ||
          userError) && (
          <CustomAlert
            variant="outlined"
            severity="error"
            sx={{
              bgcolor: "#ffcccb",
            }}
          >
            {loggingError && (
              <Typography>{`Błąd - ${loggingError}`}</Typography>
            )}
            {tokenExpiredError && (
              <Typography>{`Błąd - ${tokenExpiredError}`}</Typography>
            )}
            {storageError && (
              <Typography>{`Błąd - ${storageError}`}</Typography>
            )}
            {companyError && (
              <Typography>{`Błąd - ${companyError}`}</Typography>
            )}
            {unitError && <Typography>{`Błąd - ${unitError}`}</Typography>}
            {commissionErrors.commission && (
              <Typography>{`Błąd - ${commissionErrors.commission}`}</Typography>
            )}
            {commissionErrors.commissionForm && (
              <Typography>{`Błąd - ${commissionErrors.commissionForm}`}</Typography>
            )}
            {userError && <Typography>{`Błąd - ${userError}`}</Typography>}
          </CustomAlert>
        )}
        {(unitInfo || deviceInfo || storageInfo || shipmentInfo) && (
          <CustomAlert
            variant="outlined"
            severity="success"
            sx={{
              bgcolor: "#86de7a",
            }}
          >
            {unitInfo && <Typography>{unitInfo}</Typography>}
            {deviceInfo && <Typography>{deviceInfo}</Typography>}
            {storageInfo && <Typography>{storageInfo}</Typography>}
            {shipmentInfo && <Typography>{shipmentInfo}</Typography>}
          </CustomAlert>
        )}
      </>
    </Box>
  );
};

export default UserInformationSystem;
