import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { roles } from "../../static/static";
import { Refresh, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import authService from "../../auth/authService";
import { getUserWithSave } from "../../slices/userSlice";
import { setUserData } from "../../slices/authSlice";

const ButtonBox = styled(Box)(() => ({
  position: "absolute",
  top: "15%",
  left: "20%",
  display: "flex",
  flexDirection: "column",
}));

const SettingsButton = () => {
  const {
    userData: { user_id },
  } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  return (
    <Tooltip title="ustawienia użytkownika">
      <IconButton
        sx={{
          color: "white",
          padding: "0.25rem",
        }}
        onClick={() => navigate(`/user-management/${user_id}`)}
      >
        <Settings />
      </IconButton>
    </Tooltip>
  );
};

const ReloadUserButton = () => {
  const dispatch = useDispatch();
  const loginReload = useCallback(async () => {
    const { user_id: userId } = authService.getDecodedAccessToken();
    await dispatch(getUserWithSave(userId));
    dispatch(setUserData());
  }, []);

  return (
    <Tooltip title="przeładuj użytkownika">
      <IconButton
        sx={{
          color: "white",
          padding: "0.25rem",
        }}
        onClick={() => loginReload()}
      >
        <Refresh />
      </IconButton>
    </Tooltip>
  );
};

const UserProfileWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "white",
  position: "relative",
}));

const CustomAvatar = styled(Avatar)(() => ({
  height: "4.375rem",
  width: "4.375rem",
  marginTop: "1.25rem",
}));

const CustomUserData = styled(Typography)(() => ({
  fontWeight: "700",
  fontSize: "1.125rem",
  marginTop: "0.3125rem",
  wordBreak: "break-word",
}));

const UserProfile = () => {
  const { userData } = useSelector((state) => state.auth);
  const { name, surname, role, permissions } = userData;

  return (
    <>
      {userData ? (
        <UserProfileWrapper>
          <CustomAvatar>{`${name[0]}${surname[0]}`}</CustomAvatar>
          <ButtonBox>
            <SettingsButton />
            <ReloadUserButton />
          </ButtonBox>
          <CustomUserData>{`${name} ${surname}`}</CustomUserData>
          <Typography>
            {`${roles[role]}${permissions && permissions.length > 0 ? "+" : ""}`}
          </Typography>
        </UserProfileWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default UserProfile;
