/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Dialog } from "@mui/material";
import TaskCommentModal from "../../TaskCommentModal/TaskCommentModal";
import TaskCommentsItemDesktop from "./TaskCommentsItemVersions/TaskCommentsItemDesktop";
import useMediaQ from "../../../../hooks/useMediaQ";
import TaskCommentsItemMobile from "./TaskCommentsItemVersions/TaskCommentsItemMobile";

const TaskCommentsItem = ({ comment, orderNumber }) => {
  const { isDesktopDisplay } = useMediaQ();
  const {
    id: taskCommentId,
    task: taskId,
  } = comment;
  const [isAddEditTaskCommentVisible, setIsAddEditTaskCommentVisible] =
    useState(false);

  return (
    <>
      {isDesktopDisplay ? (
        <TaskCommentsItemDesktop
          orderNumber={orderNumber}
          comment={comment}
          setIsAddEditTaskCommentVisible={setIsAddEditTaskCommentVisible}
        />
      ) : (
        <TaskCommentsItemMobile
          comment={comment}
          setIsAddEditTaskCommentVisible={setIsAddEditTaskCommentVisible}
        />
      )}
      <Dialog
        open={isAddEditTaskCommentVisible}
        onClose={() => setIsAddEditTaskCommentVisible(false)}
      >
        <TaskCommentModal
          taskId={taskId}
          type="edit"
          taskCommentId={taskCommentId}
          closeModal={() => setIsAddEditTaskCommentVisible(false)}
        />
      </Dialog>
    </>
  );
};

export default TaskCommentsItem;
