/* eslint-disable react/prop-types */
import React from 'react';
import TableDataGridWrapper from '../../../TableGridWrapper/TableDataGridWrapper';
import { AddedUnitsHeaders } from '../../../../static/tablesHeaders';
import { TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteButton from '../../../DeleteButton/DeleteButton';

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));


const AddUnitFieldDesktop = ({
  unit,
  orderNumber,
  serialNumber,
  formError,
  onSerialNumberChange,
  quantity,
  onQuantityChange,
  removeItem,
}) => {
  const { partNumber, name, quantityLock, type } = unit;

  return (
    <TableDataGridWrapper headers={AddedUnitsHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{partNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <TextField
        value={serialNumber}
        disabled={type === "Numerical"}
        error={formError && type === "Quantitative"}
        onChange={(e) => onSerialNumberChange(e.target.value)}
        variant="standard"
        size="small"
      />
      <TextField
        value={quantity}
        type="number"
        disabled={quantityLock || type === "Quantitative"}
        InputProps={{ inputProps: { min: 1, style: { textAlign: "center" } } }}
        onChange={(e) => onQuantityChange(e.target.value)}
        variant="standard"
        size="small"
      />
      <DeleteButton onClick={() => removeItem()} />
    </TableDataGridWrapper>
  );
};

export default AddUnitFieldDesktop;