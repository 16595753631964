/* eslint-disable react/prop-types */
import { Description } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import XLSX from "xlsx";
import { dateFormatView } from "../../../static/functions";

const TaskReportDownloader = ({ tasks }) => {
 
  const downloadReport = () => {
    const rows = tasks.map((task, id) => {
      const taskLocationLinkedAddress = task.location.address.linkedVersion;

      return {
        lp: id + 1,
        companyName: task.location.company.name,
        locationCode: task.location.code,
        locationName: task.location.name,
        address: taskLocationLinkedAddress
          ? taskLocationLinkedAddress
          : `${task.location.address.street}, ${task.location.address.city}, ${task.location.address.province}, ${task.location.address.postcode}, ${task.location.address.country}`,
        province: taskLocationLinkedAddress
          ? taskLocationLinkedAddress.split(",")[
              taskLocationLinkedAddress.split(",").length - 3
            ]
          : task.location.address.province,
        creationDate: dateFormatView(task.creationDate),
        planningExecutionDate: task.planningExecutionDate
          ? dateFormatView(task.planningExecutionDate)
          : "",
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Lista zadań");
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "Lp.",
          "Firma",
          "Kod lokalizacji",
          "Nazwa lokalizacji",
          "Adres",
          "Województwo",
          "Data utworzenia",
          "Planowana data realizacji",
        ],
      ],
      {
        origin: "A1",
      }
    );
    XLSX.writeFile(
      workbook,
      `Lista_zadań_${new Date().toISOString().slice(0, 10)}.xlsx`,
      { compression: true }
    );
  };

  return (
    <Box
      sx={{
        flexBasis: "15%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Tooltip title="Pobierz raport" sx={{ alignSelf: "flex-end" }}>
        <IconButton>
          <Description
            onClick={() => downloadReport()}
            sx={{ fontSize: "2.5rem" }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default TaskReportDownloader;
