/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Description } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useCallback } from "react";
import XLSX from "xlsx";
import { dateFormatView } from "../../static/functions";
import { storageReportOperationType } from "../../static/static";

const StorageReportsDownloader = ({
  type = "single",
  storageReports,
  fontSize = "1.5rem",
}) => {
  const downloadReports = useCallback(() => {
    const workbook = XLSX.utils.book_new();

    storageReports.forEach((storageReport) => {
      const {
        id,
        creationDate,
        operationType,
        convertedUnits,
        baseStorage,
        destinationStorage,
        shipment,
        task,
        user,
        utilizationReport,
      } = storageReport;

      const worksheet = XLSX.utils.aoa_to_sheet([
        [`Raport:`, id],
        ["Data utworzenia:", dateFormatView(creationDate)],
        ["Typ operacji:", storageReportOperationType[operationType]],
        ["Użytkownik:", `${user.name} ${user.surname}`],
        ["Magazyn bazowy:", `${baseStorage ? baseStorage.name : ""}`],
        [
          "Magazyn docelowy:",
          `${destinationStorage ? destinationStorage.name : ""}`,
        ],
        ["Przesyłka:", `${shipment ? shipment.id : ""}`],
        ["Zadanie:", `${task ? task.id : ""}`],
        [
          "Raport utylizacyjny:",
          `${utilizationReport ? utilizationReport.id : ""}`,
        ],
        [],
      ]);

      XLSX.utils.sheet_add_aoa(worksheet, [[], ["JEDNOSTKI"]], {
        origin: "A10",
      });

      const rowsUnitsHeaders = {
        productName: "Produkt",
        partNumber: "PartNumber",
        categoryName: "Kategoria",
        producerName: "Producent",
        serialNumber: "Nr seryjny",
        status: "Status",
        quantity: "Liczba",
      };
      const rowsUnits = convertedUnits.map((unit) => ({
        productName: unit.productName,
        partNumber: unit.partNumber,
        categoryName: unit.categoryName,
        producerName: unit.producerName,
        serialNumber: unit.serialNumber,
        status: unit.status,
        quantity: unit.quantity,
      }));
      const totalRows = [rowsUnitsHeaders, ...rowsUnits];
      XLSX.utils.sheet_add_json(worksheet, totalRows, {
        origin: "A13",
        skipHeader: 1,
      });
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `Raport ${id.substring(0, 4)}`
      );
    });
    XLSX.writeFile(
      workbook,
      `Raporty magazynowe_${new Date().toISOString().slice(0, 10)}.xlsx`,
      { compression: true }
    );
  }, [storageReports]);

  return (
    <>
      {storageReports && (
        <Box
          sx={{
            flexBasis: "5%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tooltip
            title={
              storageReports.length === 0
                ? "Zaznacz wybrane raporty"
                : type === "single"
                ? "Pobierz raport magazynowe"
                : "Pobierz raporty magazynowe"
            }
            sx={{ alignSelf: "flex-end" }}
          >
            <Box>
              <IconButton
                onClick={() => downloadReports()}
                disabled={storageReports.length === 0}
              >
                <Description sx={{ fontSize }} />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      )}
    </>
  );
};

export default StorageReportsDownloader;
