/* eslint-disable react/prop-types */
import React from "react";
import SectionWrapper from "../SectionWrapper/SectionWrapper";
import PageHeader from "../PageHeader/PageHeader";
import { Article } from "@mui/icons-material";
import {
  InfoWrapper,
  DataBox,
  DataTitle,
  SpecialDataBox,
} from "../ObjectDetailsElements/ObjectDetailsElements";
import { dateFormatView } from "../../static/functions";
import { Typography } from "@mui/material";
import { shipmentStatus, shipmentStatusChipStyles } from "../../static/static";
import CustomChip from "../CustomChip/CustomChip";
import { Link } from "react-router-dom";

const ShipmentInfo = ({ shipment }) => {
  const {
    creationDate,
    sendDate,
    deliveredDate,
    letterNumber,
    baseStorage,
    destinationStorage,
    status,
    sender,
    receiver,
    comments,
    parentShipment,
  } = shipment;

  return (
    <SectionWrapper>
      <PageHeader icon={<Article />} titleText="Dane" size="medium" />
      <InfoWrapper>
        <DataBox>
          <DataTitle>Data utworzenia:</DataTitle>
          <Typography>{dateFormatView(creationDate)}</Typography>
        </DataBox>
        {status !== "Created" && (
          <DataBox>
            <DataTitle>Data wysłania:</DataTitle>
            <Typography>{dateFormatView(sendDate)}</Typography>
          </DataBox>
        )}
        {status === "Delivered" && (
          <DataBox>
            <DataTitle>Data dostarczenia:</DataTitle>
            <Typography>{dateFormatView(deliveredDate)}</Typography>
          </DataBox>
        )}
        <DataBox>
          <DataTitle>Numer listu przewozowego:</DataTitle>
          <Typography>{letterNumber}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Magazyn wysyłkowy:</DataTitle>
          <Typography>{baseStorage.name}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Magazyn docelowy:</DataTitle>
          <Typography>{destinationStorage.name}</Typography>
        </DataBox>
        <DataBox>
          <DataTitle>Nadawca:</DataTitle>
          <Typography>{`${sender.name} ${sender.surname}`}</Typography>
        </DataBox>
        {receiver && (
          <DataBox>
            <DataTitle>Odbiorca:</DataTitle>
            <Typography>{`${receiver.name} ${receiver.surname}`}</Typography>
          </DataBox>
        )}
        <DataBox>
          <DataTitle>Status:</DataTitle>
          <CustomChip
            label={shipmentStatus[status]}
            sx={shipmentStatusChipStyles(status, "")}
            width="50%"
          />
        </DataBox>
        {status === "To Explain" && (
          <DataBox>
            <DataTitle>Dotyczy przesyłki:</DataTitle>
            <Link
              to={`/shipments/${parentShipment}`}
              style={{ color: "black", textDecoration: "none" }}
            >
              {parentShipment}
            </Link>
          </DataBox>
        )}
        <SpecialDataBox>
          <DataTitle>Uwagi:</DataTitle>
          <Typography>{comments}</Typography>
        </SpecialDataBox>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default ShipmentInfo;
