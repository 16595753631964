/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { Box, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import PageHeader from "../../PageHeader/PageHeader";
import { QuestionMark } from "@mui/icons-material";
import AcceptButton from "../../AcceptButton/AcceptButton";
import CloseButton from "../../CloseButton/CloseButton";
import { useDispatch } from "react-redux";
import { explainShipment } from "../../../slices/shipmentSlice";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "12rem",
  width: "30rem",
  gap: "1rem",
  [theme.breakpoints.down("tablet")]: {
    width: "90%",
    padding: "1rem",
  },
}));

const InnerBox = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
  marginTop: "0.5rem",
}));

const ExplainShipment = ({
  shipmentId,
  isExplainShipmentModalVisible,
  setIsExplainShipmentModalVisible,
  setRefresh,
}) => {
  const dispatch = useDispatch();
  const explainInnerShipment = useCallback(async () => {
    await dispatch(explainShipment(shipmentId));
    setIsExplainShipmentModalVisible(false);
    setRefresh((state) => !state);
  }, [shipmentId]);

  return (
    <Dialog
      open={isExplainShipmentModalVisible}
      onClose={() => setIsExplainShipmentModalVisible(false)}
    >
      <CustomBox>
        <PageHeader
          titleText="Czy potwierdzasz, że wymienione pozycje znajdują się w magazynie"
          size="medium"
          icon={<QuestionMark />}
        />
        <InnerBox>
          <AcceptButton onClick={() => explainInnerShipment()} />
          <CloseButton
            onClick={() => setIsExplainShipmentModalVisible(false)}
            text="Zamknij"
          />
        </InnerBox>
      </CustomBox>
    </Dialog>
  );
};

export default ExplainShipment;
