/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { clearTasksCharts, getChartTasks } from "../../../../slices/taskSlice";
import { BarChart, CallMade } from "@mui/icons-material";
import { DetailsButton } from "../../../FunctionalButtons/FunctionalButtons";
import { useNavigate } from "react-router-dom";
import Chart from "./Chart";
import EmptyListError from "../../../EmptyListError/EmptyListError";
import PageHeader from "../../../PageHeader/PageHeader";
import SectionWrapper from "../../../SectionWrapper/SectionWrapper";

const YEAR_START = 2023;

const tasksChartsData = [
  {
    id: 1,
    taskType: "Qualification",
    chartLabel: "Kwalifikacyjne",
    chartWarningLabel: "Brak tasków kwalifikacyjnych",
  },
  {
    id: 2,
    taskType: "Assignment",
    chartLabel: "Przypisania",
    chartWarningLabel: "Brak tasków przypisania",
  },
  {
    id: 3,
    taskType: "Approval",
    chartLabel: "Do akceptacji",
    chartWarningLabel: "Brak tasków do zaakceptowania",
  },
  {
    id: 4,
    taskType: "Work",
    chartLabel: "Wykonawcze",
    chartWarningLabel: "Brak tasków do wykonania",
  },
  {
    id: 5,
    taskType: "Verification",
    chartLabel: "Weryfikacyjne",
    chartWarningLabel: "Brak tasków weryfikacyjnych",
  },
];

const ChartsWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  margin: "1.5rem auto 0",
}));

const YearSelectionWrapper = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "4.375rem 1fr",
}));

const MonthSelectionWrapper = styled(Box)(() => ({
  marginTop: "0.625em",
  display: "grid",
  gridTemplateColumns: "4.375rem 1fr",
}));

const ClearYearButton = ({ children, chosenYear, onClick }) => (
  <Button
    onClick={onClick}
    sx={
      chosenYear === ""
        ? {
            padding: "0",
            bgcolor: "#CCC",
            color: "black",
            "&:hover": {
              bgcolor: "#CCC",
              color: "black",
            },
          }
        : {
            padding: "0",
          }
    }
  >
    {children}
  </Button>
);

const YearButton = ({ children, chosenYear, year, onClick }) => (
  <Button
    onClick={onClick}
    sx={
      chosenYear == year
        ? {
            padding: "0",
            bgcolor: "blue",
            color: "white",
            "&:hover": {
              bgcolor: "blue",
              color: "white",
            },
          }
        : {
            padding: "0",
          }
    }
  >
    {children}
  </Button>
);

const ClearMonthButton = ({ onClick, children, chosenMonth }) => (
  <Button
    onClick={onClick}
    sx={
      chosenMonth === ""
        ? {
            padding: "0",
            bgcolor: "#CCC",
            color: "black",
            "&:hover": {
              bgcolor: "#CCC",
              color: "black",
            },
          }
        : {
            padding: "0",
          }
    }
  >
    {children}
  </Button>
);

const MonthButton = ({ children, chosenMonth, month, onClick, disabled }) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    sx={
      chosenMonth * 1 === month * 1
        ? {
            padding: "0",
            bgcolor: "blue",
            color: "white",
            "&:hover": {
              bgcolor: "blue",
              color: "white",
            },
          }
        : {
            padding: "0",
          }
    }
  >
    {children}
  </Button>
);

const YearSelector = ({ setChosenYear, setChosenMonth, chosenYear, years }) => {
  return (
    <YearSelectionWrapper>
      <Typography>Rok:</Typography>
      <Box>
        <ClearYearButton
          onClick={() => {
            setChosenYear("");
            setChosenMonth("");
          }}
          chosenYear={chosenYear}
        >
          -
        </ClearYearButton>
        {years.map((year) => (
          <YearButton
            key={year}
            onClick={() => setChosenYear(`${year}`)}
            chosenYear={chosenYear}
            year={year}
          >
            {year}
          </YearButton>
        ))}
      </Box>
    </YearSelectionWrapper>
  );
};

const MonthSelector = ({ setChosenMonth, chosenMonth, chosenYear, months }) => {
  return (
    <MonthSelectionWrapper>
      <Typography>Miesiąc:</Typography>
      <Box>
        <ClearMonthButton
          onClick={() => setChosenMonth("")}
          chosenMonth={chosenMonth}
        >
          -
        </ClearMonthButton>
        {months.map((month) => (
          <MonthButton
            key={month}
            onClick={() => setChosenMonth(`${month}`)}
            disabled={chosenYear === ""}
            chosenMonth={chosenMonth}
            month={month}
          >
            {month}
          </MonthButton>
        ))}
      </Box>
    </MonthSelectionWrapper>
  );
};

const TaskCharts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [chosenYear, setChosenYear] = useState("");
  const [chosenMonth, setChosenMonth] = useState("");

  const years = useMemo(() => {
    const array = [];
    for (let i = YEAR_START; i <= new Date().getFullYear(); i++) {
      array.push(i);
    }
    return array;
  }, []);

  const months = useMemo(
    () =>
      [...Array.from({ length: 12 }, (_, i) => i + 1)].filter((month) => {
        if (
          !chosenYear ||
          (chosenYear && chosenYear < new Date().getFullYear())
        )
          return true;
        else {
          return month <= new Date().getMonth() + 1;
        }
      }),
    []
  );

  const { chartTasks: tasks } = useSelector((slice) => slice.task);

  const getData = useCallback(async () => {
    await dispatch(getChartTasks());
  }, []);

  useEffect(() => {
    getData();
    return () => dispatch(clearTasksCharts());
  }, [getData]);

  return (
    <SectionWrapper>
      <PageHeader
        icon={<BarChart />}
        titleText="Wykresy dot. zadań"
        size="medium"
        onRightComponent={
          <DetailsButton
            onClick={() => navigate("/tasks")}
            text="Lista aktualnych zadań"
            startIcon={<CallMade />}
          />
        }
      />
      <>
        <YearSelector
          setChosenYear={setChosenYear}
          setChosenMonth={setChosenMonth}
          chosenYear={chosenYear}
          years={years}
        />
        <MonthSelector
          setChosenMonth={setChosenMonth}
          chosenMonth={chosenMonth}
          chosenYear={chosenYear}
          months={months}
        />
      </>
      {tasks !== null && tasks.length > 0 ? (
        <ChartsWrapper>
          {tasksChartsData.map(
            ({ id, taskType, chartLabel, chartWarningLabel }) => (
              <Chart
                key={id}
                tasks={tasks}
                taskType={taskType}
                chosenYear={chosenYear}
                chosenMonth={chosenMonth}
                chartLabel={chartLabel}
                chartWarningLabel={chartWarningLabel}
              />
            )
          )}
        </ChartsWrapper>
      ) : (
        <EmptyListError message="Brak tasków" />
      )}
    </SectionWrapper>
  );
};

export default TaskCharts;
