/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Add, Clear, Edit } from "@mui/icons-material";
import {
  addCommissionCode,
  clearChosenCode,
  getCommissionCode,
  updateCommissionCode,
} from "../../../slices/commissionSlice";
import PageHeader from "../../PageHeader/PageHeader";
import AcceptButton from "../../AcceptButton/AcceptButton";
import EditButton from "../../EditButton/EditButton";
import BlankSpace from "../../BlankSpace/BlankSpace";

const Wrapper = styled(Box)(({ theme }) => ({
  height: "23rem",
  width: "25rem",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("laptop")]: {
    height: "23rem",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "18rem",
  },
}));

const CustomTextField = styled(TextField)(() => ({
  width: "95%",
}));

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "0.5rem",
  alignItems: "center",
  marginTop: "0.5rem",
}));

const CommissionCodeForm = ({
  type,
  closeModal,
  setRefresh,
  mode = "add",
  codeId = "",
}) => {
  const dispatch = useDispatch();
  const { userData: authUserData } = useSelector((slice) => slice.auth);
  const { commissionChosenCode } = useSelector((slice) => slice.commission);
  const [data, setData] = useState({ name: "", type: type, description: "" });

  const getData = useCallback(async () => {
    await dispatch(getCommissionCode(codeId));
  }, [codeId]);

  useEffect(() => {
    if (mode === "edit" && commissionChosenCode === null) {
      getData();
    }
  }, [getData, mode]);

  useEffect(() => {
    if (commissionChosenCode !== null) {
      setData({
        name: commissionChosenCode.name,
        description: commissionChosenCode.description,
      });
    }
  }, [commissionChosenCode]);

  useEffect(() => {
    return () => dispatch(clearChosenCode());
  }, [commissionChosenCode]);

  const sendData = async () => {
    const transformedData = { ...data, createdBy: authUserData.user_id };
    await dispatch(addCommissionCode(transformedData));
    closeModal();
    setRefresh();
  };

  const updateData = async () => {
    const transformedData = { ...data, modifier: authUserData.user_id };
    await dispatch(updateCommissionCode({ data: transformedData, codeId }));
    closeModal();
    setRefresh();
  };

  return (
    <Wrapper>
      {mode === "add" ? (
        <PageHeader
          titleText={
            type === "open"
              ? "Dodaj kod otwarcia zlecenia"
              : "Dodaj kod zamknięcia zlecenia"
          }
          icon={<Add />}
          size="medium"
          onRightComponent={<></>}
        />
      ) : (
        <PageHeader
          titleText={
            type === "open"
              ? "Edytuj kod otwarcia zlecenia"
              : "Edytuj kod zamknięcia zlecenia"
          }
          icon={<Edit />}
          size="medium"
          onRightComponent={<></>}
        />
      )}
      <BlankSpace />
      <CustomTextField
        label="Nazwa"
        value={data.name}
        onChange={(e) =>
          setData((state) => {
            return { ...state, name: e.target.value };
          })
        }
      />
      <CustomTextField
        label="Opis"
        value={data.description}
        onChange={(e) =>
          setData((state) => {
            return { ...state, description: e.target.value };
          })
        }
        multiline
        minRows={4}
      />
      <ButtonWrapper>
        {mode === "add" ? (
          <AcceptButton
            text="Dodaj"
            startIcon={<Add />}
            onClick={() => sendData()}
          />
        ) : (
          <EditButton onClick={() => updateData()} />
        )}
        <EditButton
          text="Anuluj"
          onClick={() => closeModal()}
          startIcon={<Clear />}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default CommissionCodeForm;
