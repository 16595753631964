export const StorageNotFoundClasses = {
  outerWrapper: {
    position: "relative",
    width: "100%",
    height: "100vh"
  },
  innerWrapper: (storageError) => {
    if (storageError === "brak magazynu głównego") {
      return {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1em",
        color: "red",
        border: "0.0625em solid red",
        padding: "1.25em",
      };
    } else if (storageError === "błędny id magazynu") {
      return {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        gap: "1em",
        color: "red",
      };
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: "1em"
  },
  iconSize: {
    height: "3em",
    width: "3em"
  },
  headerTextSize: {
    fontSize: "1.5em"
  }
};