/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { generatePin } from "../../../../slices/companySlice";
import { IntegrationFormClasses } from "./IntegrationForm.style";
import PageHeader from "../../../PageHeader/PageHeader";
import { Add, Clear } from "@mui/icons-material";
import AcceptButton from "../../../AcceptButton/AcceptButton";
import EditButton from "../../../EditButton/EditButton";

const IntegrationForm = ({ companyId, closeForm }) => {
  const dispatch = useDispatch();
  const [appName, setAppName] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [pinDurationTime, setPinDurationTime] = useState(1);
  const [tokenDurationTime, setTokenDurationTime] = useState(30);

  const createPin = async () => {
    const data = {
      companyId,
      appName,
      pinDurationTime: pinDurationTime * 60 * 1000,
      tokenDurationTime: tokenDurationTime * 60 * 60 * 24 * 1000,
    };
    setIsSent(true);
    await dispatch(generatePin(data));
    closeForm();
  };

  return (
    <Box sx={IntegrationFormClasses.wrapper}>
      <PageHeader
        titleText="Tworzenie integracji"
        icon={<Add />}
        size="medium"
        onRightComponent={<></>}
      />
      <TextField
        label="Nazwa aplikacji"
        value={appName}
        onChange={(e) => setAppName(e.target.value)}
        size="small"
        sx={IntegrationFormClasses.textFieldProps}
      />
      <Box sx={IntegrationFormClasses.selectProps}>
        <Typography>Czas ważności pinu:</Typography>
        <Select
          value={pinDurationTime}
          onChange={(e) => setPinDurationTime(e.target.value)}
          size="small"
        >
          <MenuItem value={1}>1 minuta</MenuItem>
          <MenuItem value={2}>2 minuty</MenuItem>
          <MenuItem value={3}>3 minuty</MenuItem>
          <MenuItem value={4}>4 minuty</MenuItem>
          <MenuItem value={5}>5 minut</MenuItem>
          <MenuItem value={10}>10 minut</MenuItem>
        </Select>
      </Box>
      <Box sx={IntegrationFormClasses.selectProps}>
        <Typography>Czas ważności tokenów:</Typography>
        <Select
          value={tokenDurationTime}
          onChange={(e) => setTokenDurationTime(e.target.value)}
          size="small"
        >
          <MenuItem value={30}>30 dni</MenuItem>
          <MenuItem value={60}>60 dni</MenuItem>
          <MenuItem value={90}>90 dni</MenuItem>
          <MenuItem value={120}>120 dni</MenuItem>
          <MenuItem value={150}>150 dni</MenuItem>
          <MenuItem value={180}>180 dni</MenuItem>
        </Select>
      </Box>
      <Box sx={IntegrationFormClasses.buttonWrapper}>
        <AcceptButton
          disabled={appName.length === 0 || isSent}
          onClick={() => createPin()}
          text="Generuj"
        />
        <EditButton
          onClick={() => closeForm()}
          startIcon={<Clear />}
          text="Anuluj"
        />
      </Box>
    </Box>
  );
};

export default IntegrationForm;
