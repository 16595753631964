/* eslint-disable react/prop-types */
import React from "react";
import TableDataGridWrapper from "../../../../TableGridWrapper/TableDataGridWrapper";
import { locationsHeaders } from "../../../../../static/tablesHeaders";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LongerTextModal from "../../../../LongerTextModal/LongerTextModal";
import { Autorenew } from "@mui/icons-material";
import DetailsButton from "../../../../DetailsButton/DetailsButton";
import GeoLocationIcon from "../../../../GeoLocation/GeoLocationIcon";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const LocationItemDesktop = ({ orderNumber, location }) => {
  const navigate = useNavigate();
  const {
    code,
    name,
    address: { linkedVersion },
    devicesCount,
    isSynchronized,
  } = location;

  const navigateToLocation = () => {
    navigate(`/locations/${code}`);
  };

  return (
    <TableDataGridWrapper headers={locationsHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <LongerTextModal
        text={code}
        quantity={8}
        styles={{ fontSize: "0.875rem" }}
      />
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <Box sx={{display: 'flex'}}>
        <LongerTextModal
          text={linkedVersion}
          quantity={80}
          styles={{ fontSize: "0.875rem" }}
        />
        <GeoLocationIcon query={linkedVersion} />
      </Box>

      <CustomDataDisplay>{devicesCount}</CustomDataDisplay>
      {isSynchronized ? (
        <Autorenew
          sx={{
            color: "darkgreen",
          }}
        />
      ) : (
        <Autorenew
          sx={{
            color: "red",
          }}
        />
      )}
      <DetailsButton onClick={() => navigateToLocation()} width="80%"/>
    </TableDataGridWrapper>
  );
};

export default LocationItemDesktop;
