/* eslint-disable react/prop-types */
import React from "react";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { shipmentUnitsHeaders } from "../../../../static/tablesHeaders";
import { Typography } from "@mui/material";
import CustomChip from "../../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../../static/static";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const ShipmentUnitItemDesktop = ({ orderNumber, shipmentUnit }) => {
  const {
    product: { partNumber, name },
    serialNumber,
    status,
    quantity,
  } = shipmentUnit;

  return (
    <TableDataGridWrapper headers={shipmentUnitsHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{partNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <CustomDataDisplay>
        {serialNumber ? serialNumber : "Brak"}
      </CustomDataDisplay>
      <CustomChip
        label={status}
        sx={unitStatusChipStyles(status, "")}
        width="50%"
      />
      <CustomDataDisplay>{quantity}</CustomDataDisplay>
    </TableDataGridWrapper>
  );
};

export default ShipmentUnitItemDesktop;
