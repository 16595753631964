/* eslint-disable react/prop-types */
import React from "react";
import { Box, Typography } from "@mui/material";
import { Add, WarehouseOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DetailsButton from "../DetailsButton/DetailsButton";
import { StorageNotFoundClasses } from "./StorageNotFound.style";

const StorageNotFound = ({ storageError }) => {
  const navigate = useNavigate();

  return (
    <>
      {storageError === "brak magazynu głównego" && (
        <Box sx={StorageNotFoundClasses.outerWrapper}>
          <Box sx={StorageNotFoundClasses.innerWrapper(storageError)}>
            <Box sx={StorageNotFoundClasses.header}>
              <WarehouseOutlined sx={StorageNotFoundClasses.iconSize} />
              <Typography sx={StorageNotFoundClasses.headerTextSize}>
                Brak magazynu głównego
              </Typography>
            </Box>
            <DetailsButton
              onClick={() => navigate("/storage-add")}
              startIcon={<Add />}
              text="Utwórz magazyn główny"
            />
          </Box>
        </Box>
      )}
      {storageError === "błędny id magazynu" && (
        <Box sx={StorageNotFoundClasses.outerWrapper}>
          <Box sx={StorageNotFoundClasses.innerWrapper(storageError)}>
            <WarehouseOutlined sx={StorageNotFoundClasses.iconSize} />
            <Typography sx={StorageNotFoundClasses.headerTextSize}>
              Nie ma takiego magazynu
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default StorageNotFound;
