/* eslint-disable react/prop-types */
import React from "react";
import CompactDataDisplay from "../../../../CompactDataDisplay/CompactDataDisplay";
import DetailsButton from "../../../../DetailsButton/DetailsButton";
import { dateFormatView } from "../../../../../static/functions";
import { styled } from "@mui/material/styles";
import CustomChip from "../../../../CustomChip/CustomChip";
import {
  taskStatus,
  taskStatusChipStyles,
  taskType,
  taskTypeChipStyles,
} from "../../../../../static/static";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import TaskExecution from "../../../../TaskView/TaskExecution/TaskExecution";

const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "0.1rem 0.8rem",
  justifyContent: "center",
}));

const TaskSummaryItemMobile = ({ task, setRefresh, chosenUser }) => {
  const navigate = useNavigate();
  const {
    id,
    commission,
    creationDate,
    proposedExecutor,
    assignedExecutor,
    planningExecutionDate,
    status,
    type,
  } = task;

  const data = [
    { title: "Nr ref zlecenia:", value: commission.id },
    {
      title: "Plan. data real.:",
      value: planningExecutionDate
        ? dateFormatView(planningExecutionDate)
        : "Brak/Nie podano",
      sx:
        new Date(planningExecutionDate).getDate() < new Date().getDate()
          ? { color: "red" }
          : {},
    },
    { title: "Data utworzenia:", value: dateFormatView(creationDate) },
    {
      title: "Planowany wykonawca:",
      value: proposedExecutor
        ? `${proposedExecutor.name} ${proposedExecutor.surname}`
        : "Brak",
    },
    {
      title: "Serwisant:",
      value: assignedExecutor
        ? `${assignedExecutor.name} ${assignedExecutor.surname}`
        : "Brak",
    },
    {
      title: "Status:",
      value: (
        <CustomChip
          label={taskStatus[status]}
          sx={taskStatusChipStyles(status, "")}
          width="100%"
        />
      ),
    },
    {
      title: "Typ:",
      value: (
        <CustomChip
          label={taskType[type]}
          sx={taskTypeChipStyles(type, "")}
          width="100%"
        />
      ),
    },
  ];

  return (
    <CompactDataDisplay
      title={`Zadanie #${id}`}
      data={data}
      actions={
        <ButtonWrapper>
          <DetailsButton
            onClick={() => navigate(`/tasks/${id}`)}
            styles={{ width: "auto" }}
          />
          <TaskExecution
            task={task}
            setRefresh={setRefresh}
            chosenUser={chosenUser}
          />
        </ButtonWrapper>
      }
    />
  );
};

export default TaskSummaryItemMobile;
