/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Typography,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
} from "@mui/material";
import QualificationFinalModal from "./QualificationFinalModal";
import { Clear } from "@mui/icons-material";
import authService from "../../../auth/authService";
import TaskExecutionCompactData from "../../TaskView/TaskExecution/TaskExecutionCompactData";
import EditButton from "../../EditButton/EditButton";
import AcceptButton from "../../AcceptButton/AcceptButton";

const Wrapper = styled(Box)(({ theme }) => ({
  height: "40rem",
  width: "31.25rem",
  overflowY: "auto",
  padding: "0.5rem",
  [theme.breakpoints.down("laptop")]: {
    width: "25rem",
  },
  [theme.breakpoints.down("tablet")]: {
    width: "16rem",
  },
}));

const ContentWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "80%",
  margin: "1.875em auto 0 auto",
  gap: "0.625em",
}));

const CustomTextField = styled(TextField)(() => ({
  width: "100%",
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "1.875rem",
  marginTop: "1rem",
  [theme.breakpoints.down("laptop")]: {
    gap: "0.5rem",
  },
}));

const QualificationModal = ({
  taskId,
  task,
  setIsQualificationModalVisible,
  setRefresh,
}) => {
  const [commissionType, setCommissionType] = useState("Stationary Fix");
  const [description, setDescription] = useState("");
  const [isFinalModalVisible, setIsFinalModalVisible] = useState(false);
  const [isCommissionTypeError, setIsCommissionTypeError] = useState(false);

  const openModal = () => {
    if (commissionType === "") {
      setIsCommissionTypeError(true);
    } else {
      setIsFinalModalVisible(true);
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <Typography
          sx={{
            fontSize: "1.875rem",
            width: "100%",
            textAlign: "center",
          }}
        >
          Wybierz typ zlecenia
        </Typography>
        <Typography
          sx={{ fontSize: "0.8rem", marginBottom: "0.75rem", color: "red" }}
        >
          Pola oznaczone * są obowiązkowe
        </Typography>
        <TaskExecutionCompactData task={task} />
        <InputLabel
          id="demo-simple-select-label"
          sx={{ alignSelf: "start", fontSize: "1.2rem" }}
        >
          Typ zlecenia*
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={commissionType}
          onChange={(e) => {
            setCommissionType(e.target.value);
            setIsCommissionTypeError(false);
          }}
          error={isCommissionTypeError}
          sx={{
            width: "100%",
          }}
          placeholder="Wybierz typ naprawy"
        >
          <MenuItem value="Online Fix">Naprawa zdalna</MenuItem>
          <MenuItem value="Stationary Fix">Naprawa na miejscu</MenuItem>
        </Select>
        {isCommissionTypeError && (
          <Typography sx={{ color: "red", fontSize: "0.75em", gap: 0 }}>
            Pole nie może być puste
          </Typography>
        )}
        <CustomTextField
          label="Opis decyzji*"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={10}
        />
        <ButtonWrapper>
          <AcceptButton
            onClick={() => openModal()}
            disabled={commissionType == "" || description == ""}
            text="Zatwierdź"
          />
          <EditButton
            onClick={() => setIsQualificationModalVisible(false)}
            startIcon={<Clear />}
            text="Anuluj"
          />
        </ButtonWrapper>
        {isFinalModalVisible && (
          <Dialog
            open={isFinalModalVisible}
            onClose={() => setIsFinalModalVisible(false)}
          >
            <QualificationFinalModal
              data={{
                taskId,
                qualifier: commissionType,
                description,
                executor: authService.getDecodedAccessToken().user_id,
              }}
              setIsFinalModal={setIsFinalModalVisible}
              setIsQualificationModalVisible={setIsQualificationModalVisible}
              setRefresh={setRefresh}
            />
          </Dialog>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default QualificationModal;
