/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, Slide, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useMediaQ from "../../hooks/useMediaQ";
import { closeMenu } from "../../slices/mediaSlice";

const ExtendableMenu = ({ name, icon, subPaths }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const { isPhoneDisplay, isTabletDisplay } = useMediaQ();

  return (
    <Box ref={containerRef} sx={{ overflow: "hidden" }}>
      <Button
        sx={{
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          height: "4.5em",
          gap: "1em",
          width: "100%",
          minWidth: "12.5vw",
          bgcolor: "#082032",
          color: "rgb(27,150,250)",
          transition: "1s",
          "&:hover": {
            bgcolor: "#2C394B",
          },
          ["@media (max-width:767px)"]: {
            fontSize: "0.7em",
            height: "5em",
          },
          ["@media (max-width:1199px)"]: {
            fontSize: "0.85em",
            height: "5em",
          },
        }}
        onClick={() => setIsSubMenuVisible((state) => !state)}
      >
        {icon}
        <Typography
          sx={{ textAlign: "left", textTransform: "none", width: "80%" }}
        >
          {name}
        </Typography>
        {!isSubMenuVisible ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
      </Button>
      <Slide
        direction="down"
        in={isSubMenuVisible}
        container={containerRef.current}
      >
        <Box>
          {subPaths.map(({ path, name, icon }, index) => (
            <Button
              key={index}
              onClick={() => {
                navigate(path);
                if (isPhoneDisplay || isTabletDisplay) {
                  dispatch(closeMenu());
                }
              }}
              sx={{
                zIndex: 1,
                display: !isSubMenuVisible ? "none" : "flex",
                alignItems: "center",
                justifyContent: "left",
                height: "3.5em",
                width: "100%",
                minWidth: "12.5vw",
                gap: "1em",
                paddingLeft: "25px",
                bgcolor: "#031421",
                color: "rgb(27,150,250)",
                transition: "1s",
                "&:hover": {
                  bgcolor: "#2C394B",
                },
                ["@media (max-width:767px)"]: {
                  fontSize: "0.7em",
                  height: "5em",
                },
                ["@media (max-width:1199px)"]: {
                  fontSize: "0.85em",
                  height: "5em",
                },
              }}
            >
              {icon}
              <Typography
                sx={{
                  textAlign: "left",
                  textTransform: "none",
                  fontSize: "0.8em",
                }}
              >
                {name}
              </Typography>
            </Button>
          ))}
        </Box>
      </Slide>
    </Box>
  );
};

export default ExtendableMenu;
