/* eslint-disable react/prop-types */
import React from "react";
import UnmountedUnitsItemDesktop from "./UnmountedUnitsItemVersions/UnmountedUnitsItemDesktop";
import UnmountedUnitsItemMobile from "./UnmountedUnitsItemVersions/UnmountedUnitsItemMobile";
import useMediaQ from "../../../hooks/useMediaQ";

const UnmountedUnitsItem = ({orderNumber, unit, removeBADItem }) => {
  const { isDesktopDisplay } = useMediaQ();
  const { index } = unit;

  const deleteElement = () => {
    removeBADItem(index);
  };

  return (
    <>
      {isDesktopDisplay ? (
        <UnmountedUnitsItemDesktop
          orderNumber={orderNumber}
          unit={unit}
          deleteElement={deleteElement}
        />
      ) : (
        <UnmountedUnitsItemMobile
          orderNumber={orderNumber}
          unit={unit}
          deleteElement={deleteElement}
        />
      )}
    </>
  );
};

export default UnmountedUnitsItem;
