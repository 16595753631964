/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import useMediaQ from "../../../../../hooks/useMediaQ";
import CommissionTaskItemDesktop from "./CommissionTaskItemVersions/CommissionTaskItemDesktop";
import CommissionTaskItemMobile from "./CommissionTaskItemVersions/CommissionTaskItemMobile";

const CommissionTaskItem = ({ data, orderNumber, chosenUser, setRefresh }) => {
  const { isDesktopDisplay } = useMediaQ();

  return (
    <>
      {isDesktopDisplay ? (
        <CommissionTaskItemDesktop
          orderNumber={orderNumber}
          task={data}
          chosenUser={chosenUser}
          setRefresh={setRefresh}
        />
      ) : (
        <CommissionTaskItemMobile
          task={data}
          chosenUser={chosenUser}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default CommissionTaskItem;
