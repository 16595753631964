/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import CommissionDetails from "../CommissionDetails/CommissionDetails";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CommissionTaskView from "./CommissionsTasks/CommissionTasksView/CommissionTaskView";
import DocumentationsView from "../DocumentationsView/DocumentationsView/DocumentationsView";
import {
  clearCommissionData,
  getCommission,
} from "../../../slices/commissionSlice";
import { InventoryOutlined, KeyboardArrowLeft } from "@mui/icons-material";
import Loading from "../../Loading/Loading";
import PageHeader from "../../PageHeader/PageHeader";
import RefreshButton from "../../RefreshButton/RefreshButton";
import BlankSpace from "../../BlankSpace/BlankSpace";
import EmptyListError from "../../EmptyListError/EmptyListError";
import {
  DetailsButton,
  EditButton,
} from "../../FunctionalButtons/FunctionalButtons";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../../PageWrapper/PageWrapper";
import DeviceDetails from "../CommissionDetails/DeviceDetails";
import { clearUser, getUser } from "../../../slices/userSlice";
import UnitsMigrationHistory from "../../UnitsMigrationHistory/UnitsMigrationHistory";

const Commission = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { commissionId } = useParams();
  const { chosenCommission } = useSelector((slice) => slice.commission);
  const { chosenUser } = useSelector((state) => state.user);
  const [refresh, setRefresh] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const {
    userData: { user_id: userId },
  } = useSelector((state) => state.auth);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    await dispatch(getUser(userId));
    await dispatch(getCommission(commissionId));
    setIsDataLoading(false);
  }, [commissionId, refresh]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => {
      dispatch(clearUser());
      dispatch(clearCommissionData());
    };
  }, []);

  if (isDataLoading) {
    return <Loading />;
  }

  return (
    <>
      {chosenCommission !== null ? (
        <PageWrapper width="95%">
          <PageHeader
            icon={<InventoryOutlined />}
            titleText="Zlecenie"
            onRightComponent={
              <DetailsButton
                onClick={() => navigate("/commissions")}
                text="Powrót"
                startIcon={<KeyboardArrowLeft />}
              />
            }
          >
            <RefreshButton onClick={() => setRefresh((state) => !state)} />
            <EditButton onClick={() => {}} />
          </PageHeader>
          <BlankSpace />
          <CommissionDetails
            device={chosenCommission.device}
            company={chosenCommission.company}
            commission={{
              id: chosenCommission.id,
              faultDescription: chosenCommission.faultDescription,
              fixDescription: chosenCommission.fixDescription,
              creationDate: chosenCommission.creationDate,
              finishDate: chosenCommission.finishDate,
              status: chosenCommission.status,
              type: chosenCommission.type,
              openCode: chosenCommission.openCode,
            }}
          />
          <BlankSpace />
          <DeviceDetails
            device={chosenCommission.device}
            company={chosenCommission.company}
          />
          <BlankSpace />
          <UnitsMigrationHistory
            commissionId={chosenCommission.id}
            refresh={refresh}
          />
          <BlankSpace />
          <DocumentationsView
            refresh={refresh}
            setRefresh={setRefresh}
            documentation={chosenCommission.documentation}
            commissionId={chosenCommission.id}
          />
          <BlankSpace />
          <CommissionTaskView
            refresh={refresh}
            setRefresh={setRefresh}
            tasks={chosenCommission.tasks}
            chosenUser={chosenUser}
          />
        </PageWrapper>
      ) : (
        <EmptyListError message="Brak wybranego zlecenia" />
      )}
    </>
  );
};

export default Commission;
