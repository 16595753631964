/* eslint-disable react/prop-types */
import React from 'react';
import SectionWrapper from '../../SectionWrapper/SectionWrapper';
import { Box } from '@mui/material';
import { styled } from "@mui/material/styles";
import TableHeader from '../../TableHeader/TableHeader';
import { workModalDOAUnitsHeaders } from '../../../static/tablesHeaders';
import EmptyListError from '../../EmptyListError/EmptyListError';
import { CancelPresentation, Warning } from '@mui/icons-material';
import DOAUnitItem from './DOAUnitItem';
import PageHeader from '../../PageHeader/PageHeader';
import TableWrapper from '../../TableWrapper/TableWrapper';

const UnitsWrapper = styled(Box)(() => ({
  maxHight: "15.625rem",
  overflowY: "auto",
}));

const DOAUnits = ({
  setSorter,
  setSorting,
  fatalProducts,
  sorter,
  sort,
  removeDOAItem,
}) => {
  return (
    <SectionWrapper>
      <PageHeader
        titleText="Uszkodzone komponenty"
        size="medium"
        icon={<CancelPresentation />}
      />
      <TableWrapper>
        <TableHeader
          headers={workModalDOAUnitsHeaders}
          setSorter={setSorter}
          setSorting={setSorting}
          gap="1%"
        />
        <UnitsWrapper>
          {fatalProducts.length > 0 ? (
            <>
              {fatalProducts
                .sort(
                  sorter["doa"] !== "" ? (a, b) => sort(a, b, "doa") : undefined
                )
                .map((unit, index) => (
                  <DOAUnitItem
                    unit={unit}
                    key={index}
                    orderNumber={index + 1}
                    removeDOAItem={removeDOAItem}
                  />
                ))}
            </>
          ) : (
            <EmptyListError
              message="Brak uszkodzonych jednostek"
              icon={<Warning />}
            />
          )}
        </UnitsWrapper>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default DOAUnits;