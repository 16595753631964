/* eslint-disable react/prop-types */
import React from "react";
import { styled } from "@mui/material/styles";
import TableDataGridWrapper from "../../../TableGridWrapper/TableDataGridWrapper";
import { workModalDOAUnitsHeaders } from "../../../../static/tablesHeaders";
import { Typography } from "@mui/material";
import DeleteButton from "../../../DeleteButton/DeleteButton";
import CustomChip from "../../../CustomChip/CustomChip";
import { unitStatusChipStyles } from "../../../../static/static";

const CustomDataDisplay = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

const DOAUnitItemDesktop = ({ orderNumber, unit, deleteElement }) => {
  const { partNumber, status, name, serialNumber, returnQuantity } = unit;

  return (
    <TableDataGridWrapper headers={workModalDOAUnitsHeaders} gap="1%">
      <CustomDataDisplay>{orderNumber}</CustomDataDisplay>
      <CustomDataDisplay>{partNumber}</CustomDataDisplay>
      <CustomDataDisplay>{name}</CustomDataDisplay>
      <CustomDataDisplay>{serialNumber}</CustomDataDisplay>
      <CustomChip label={status} sx={unitStatusChipStyles(status, "")} />
      <CustomDataDisplay>{returnQuantity}</CustomDataDisplay>
      <DeleteButton
        onClick={() => deleteElement()}
        variant="contained"
        text="usuń"
      />
    </TableDataGridWrapper>
  );
};

export default DOAUnitItemDesktop;
